import { Box, IconButton, Paper, Typography } from "@mui/material"
import { ArrowBack, ArrowForward } from "@mui/icons-material"

/**
 * Component for navigating across Micro-Learning preview pages
 * @param props - Component props
 */
const PreviewPagination = (props: any) => {
  const { count, page, onChange } = props

  return (
    <Paper
      elevation={3}
      sx={{
        /**
         * Calculate border radius based on theme spacing
         * @param theme - MUI theme object
         */
        borderRadius: (theme: any) => theme.spacing(20),
        /**
         * Set width based on theme spacing
         * @param theme - MUI theme object
         */
        width: (theme: any) => theme.spacing(20),
      }}
    >
      <Box
        data-cy="microlearning-preview-pagination"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={1}
      >
        <IconButton disabled={page === 1} onClick={() => onChange(page - 1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="body1">
          {page} / {count}
        </Typography>
        <IconButton disabled={page >= count} onClick={() => onChange(page + 1)}>
          <ArrowForward />
        </IconButton>
      </Box>
    </Paper>
  )
}

export default PreviewPagination
