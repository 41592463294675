import { Card as MUICard, CardActions } from "@mui/material"
import styled from "@emotion/styled"
import { get } from "lodash/fp"

const selectColor = (theme, color) =>
  get([color, "main"], theme.palette) ?? get(color, theme.palette) ?? color

const Card = styled(MUICard)(({ theme, color: colorProp }) => {
  const color = selectColor(theme, colorProp)
  return {
    flexShrink: 0,
    cursor: "pointer",
    borderLeft: `${theme.shape.borderRadius}px solid ${color}`,
    "&:hover": {
      boxShadow: theme.shadows[3],
      borderColor: color,
    },
  }
})

const Remark = ({ color, children, onClick, actions, id }) => {
  return (
    <Card id={id} variant="outlined" color={color} onClick={onClick}>
      {children}
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  )
}
export default Remark
