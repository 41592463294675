import axiosInstance from "../../../api/axios"

/**
 * Send a request with a list of item IDs to permanently delete
 * @param items - IDs of items to delete
 */
const hardDeleteItems = (items: string[]) =>
  axiosInstance
    .post("/courses/delete/", { items })
    .then((response) => response.data)

export default hardDeleteItems
