import { useCallback, useEffect, useState } from "react"
import { unionBy } from "lodash"
import { useAPI } from "../contexts/api"
import { useDispatch, useSelector } from "react-redux"
import selectUploadJobs from "../store/jobs/selectUploadJobs"
import useUploadCompletions from "./useUploadCompletions"
import jobsSlice from "../store/jobs/jobsSlice"
import { useSnackbar } from "notistack"
import uploadCourseThumbnail from "../features/welcome/api/uploadCourseThumbnail.ts"
import trashCourses from "../features/welcome/api/trashCourses"
import useCourseImportApi from "../features/createCourse/hooks/useCourseImportApi"

/**
 * Map documents in the process of uploading and return them as normalized course objects
 *
 * @param uploadsInProgress Current jobs running for in-progress uploads
 * @returns {*[]} array in progress uploads mapped to objects used by CourseItem component
 */
const asVirtualDocuments = (uploadsInProgress) =>
  uploadsInProgress.map((upload) => ({
    id: upload.async_id,
    title: upload.data.currentFilename,
    isLoading: true,
  }))

/**
 * The `useCourse` hook returns the state of a promise from a call to the getCourses endpoint,
 * as well as a callback function for deleting a course by its ID
 */
const useCourses = () => {
  // retrieve current async jobs from redux store
  const dispatch = useDispatch()
  const uploadsInProgress = useSelector(selectUploadJobs)
  const { enqueueSnackbar } = useSnackbar()

  const api = useAPI()
  const { cancelImport } = useCourseImportApi()

  const fetchCourses = useCallback(
    () => api.getCourses().then((res) => res.data),
    [api]
  )

  const [result, setResult] = useState({
    documents: [],
    error: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [checkedCourses, setCheckedCourses] = useState([])

  /**
   * Send a request to the server to delete a course, then refresh the UI
   * @param id - ID of course to delete
   */
  const deleteCourse = async (id) => {
    setIsLoading(true)
    return api
      .deleteCourse(id)
      .then((res) => {
        setIsLoading(false)
        return updateCourses()
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error)
      })
  }

  /**
   * Send a request to duplicate a given course
   * @param id - ID of course to duplicate
   */
  const duplicateCourse = async (id) => {
    return api.duplicateCourse(id).then(updateCourses).catch(console.log)
  }

  /**
   * Send request to move courses with the provided IDs to the trash
   * @param ids - List of IDs of courses to trash
   */
  const moveCoursesToTrash = async (ids) => {
    return trashCourses(ids).then(updateCourses).catch(console.log)
  }
  /**
   * Dispatch an action and request to cease an in-progress course upload
   * @param id - ID of course to cancel the creation of
   */
  const cancelCourseCreation = async (id) => {
    // Mark it as canceled before asking the server to update
    // the job list, otherwise it appears in the list briefly
    dispatch(jobsSlice.actions.jobCanceled({ async_id: id }))

    setIsLoading(true)
    try {
      await cancelImport(id)
      updateCourses()
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Update the state with course data from the server
   * @param documents - Retrieved list of courses to populate the state with
   */
  const setData = (documents) => {
    return setResult((result) => ({ ...result, documents, error: null }))
  }
  /**
   * Update the state with an error message
   * @param error - Error from server to populate the state with
   */
  const setError = (error) => setResult((result) => ({ ...result, error }))

  const updateCourses = useCallback(() => {
    setIsLoading(true)
    return fetchCourses()
      .then((data) => {
        setIsLoading(false)
        return setData(data.items)
      })
      .catch((error) => {
        setIsLoading(false)
        return setError(error)
      })
  }, [fetchCourses])

  const saveCustomCourseImage = useCallback(
    (id, image) => {
      return uploadCourseThumbnail({
        image: image.file,
        id,
      }).then(() => {
        enqueueSnackbar("Course image updated.", { variant: "success" })
        return updateCourses()
      })
    },
    [enqueueSnackbar, updateCourses]
  )

  const mergeDocuments = useCallback(
    (documents) => {
      return setResult((result) => ({
        ...result,
        documents: unionBy(
          documents,
          result?.documents || [],
          (document) => document.id
        ),
      }))
    },
    [setResult]
  )

  useEffect(() => {
    updateCourses()
  }, [updateCourses])

  useUploadCompletions((uploads) => {
    mergeDocuments(
      uploads.map((upload) => ({
        id: upload.data.course_id,
        title: upload.data.title,
        isLoading: false,
        status: "Completed",
        last_update: upload.data.last_update,
      }))
    )
  })

  // return { ...result, deleteCourse }
  return {
    ...result,
    // documents: concatenateDocuments(uploadsInProgress, result.documents),
    documents: [...asVirtualDocuments(uploadsInProgress), ...result.documents],
    deleteCourse,
    duplicateCourse,
    moveCoursesToTrash,
    cancelCourseCreation,
    isLoading,
    saveCustomCourseImage,
    checkedCourses,
    setCheckedCourses,
    updateCourses,
  }
}

export default useCourses
