import axiosInstance from "../../../api/axios"

/**
 * Send a request to create a new Learning Path.
 *
 * @param arg
 * @returns {Promise<unknown>|Promise<AxiosResponse<any>>}
 */
const createLearningPath = (arg) => {
  const { title, templateId } = arg

  return axiosInstance
    .post(`/paths`, {
      title,
      templateId,
    })
    .then((response) => response.data)
}

export default createLearningPath
