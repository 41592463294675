import { useEffect, useRef } from "react"

type Service = {
  saveCourse: (options: { keepalive: boolean }) => Promise<void>
}

type CourseState = {
  html: string
  isDirty: boolean
}

/**
 * The maximum amount of html content we can send after session close.
 */
const SIZE_LIMIT_FOR_KEEPALIVE = 64000 - 500 // 64kb minus some buffer

/** Indicates we a operating within a firefox browser. */
const isFirefox = navigator.userAgent.toLowerCase().includes("firefox")

/**
 * Ensures the course content is saved before user exit.
 *
 * An automatic save will be triggered if when the user navigates to a new page,
 * switches tabs, closes the tab, minimizes or closes the browser.
 *
 * Firefox does not support the latest fetch APIs so we present a popup to warn
 * them about unsaved changes before leaving the page.
 *
 * @param state The current state of the course document.
 * @param service An interface for performing course operations.
 */
export function useSaveOnExit(state: CourseState, service: Service) {
  const serviceRef = useRef<Service>(service)
  const stateRef = useRef<CourseState>(state)

  useEffect(() => {
    serviceRef.current = service
  }, [service])

  useEffect(() => {
    stateRef.current = state
  }, [state])

  useEffect(() => {
    return preventExitWhile(() => {
      const state = stateRef.current

      const shouldPreventExit = state.isDirty && !canSaveAfterClose(state)
      return shouldPreventExit
    })
  }, [])

  useEffect(() => {
    if (isFirefox) {
      return
    }

    return listenForDocumentHide(() => {
      const state = stateRef.current

      const shouldSaveAfterClose = state.isDirty && canSaveAfterClose(state)
      if (!shouldSaveAfterClose) {
        return
      }

      serviceRef.current.saveCourse({ keepalive: true })
    })
  }, [])
}

export default useSaveOnExit

/**
 * Determine if we are able to perform a save request after closing the session.
 *
 * @param state The current course state.
 */
function canSaveAfterClose(state: CourseState) {
  return !isFirefox && sizeInBytes(state.html) <= SIZE_LIMIT_FOR_KEEPALIVE
}

/**
 * Calculate the size in bytes for a given text.
 * @param data Some amount of text data.
 */
function sizeInBytes(data: string) {
  return new Blob([data]).size
}

type DocumentHideListener = () => void

/**
 * Registers a listener function to be invoked whenever the page becomes hidden.
 *
 * Specifically the function will be triggered when the user navigates to a new
 * page switches tabs, closes the tab, minimizes or closes the browser.
 *
 * @param listener The listener function.
 * @returns A function that deregisters the listener when invoked.
 */
function listenForDocumentHide(listener: DocumentHideListener) {
  /** Handles visibilty change events. */
  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      listener()
    }
  }

  window.addEventListener("visibilitychange", handleVisibilityChange)
  return () =>
    window.removeEventListener("visibilitychange", handleVisibilityChange)
}

/**
 * Prevents page exit while the given predicate continues to return true.
 *
 * @param predicate A function that indicates whether the page can be safely closed.
 * @returns A function that deregisters the listener when invoked.
 */
const preventExitWhile = (predicate: () => boolean) => {
  /**
   * Handles before unload events.
   * @param e The "before unload" event.
   */
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (predicate()) {
      e.preventDefault()
      e.returnValue = true
    }
  }
  window.addEventListener("beforeunload", handleBeforeUnload)
  return () => window.removeEventListener("beforeunload", handleBeforeUnload)
}
