import {
  Box,
  Typography,
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
} from "@mui/material"
import ConfirmDeleteImage from "../../../assets/images/confirm-delete.svg"

import { Description, FolderOpen } from "@mui/icons-material"
import ConfirmDialog from "../../../components/widgets/ConfirmDialog"
import useTrashItemsPreview from "../hooks/useTrashItemsPreview"

type ConfirmTrashDialogProps = {
  itemsToTrash: any[]
  setConfirmingTrash: (arg: any) => void
  onTrashConfirmed: (itemsToTrash: any[]) => void
}

type TrashPreviewItemProps = {
  item: any
  nested?: boolean
}

/**
 * Component for displaying each item in trash preview
 *
 * @param props - Component props
 */
const TrashPreviewItem = (props: TrashPreviewItemProps) => {
  const { item, nested = false } = props
  return (
    <ListItem sx={{ paddingLeft: nested ? 4 : null }}>
      <ListItemIcon
        sx={{
          /**
           * Shorten width to keep look compact
           * @param theme - MUI theme
           */
          minWidth: (theme) => theme.spacing(4),
        }}
      >
        {item.item_type === "folder" ? (
          <FolderOpen fontSize="small" />
        ) : (
          <Description fontSize="small" />
        )}
      </ListItemIcon>
      <ListItemText>{item.title}</ListItemText>
    </ListItem>
  )
}

/**
 * Component for rendering dialog to confirm trashing a course
 * @param props - Component props
 */
export const ConfirmTrashDialog = (props: ConfirmTrashDialogProps) => {
  const { itemsToTrash, setConfirmingTrash, onTrashConfirmed } = props

  const { trashPreviewItems, previewContainsChildren, message } =
    useTrashItemsPreview(itemsToTrash)

  return (
    <ConfirmDialog
      data-cy="Microlearning-move-items-to-trash"
      title={"Move Items To Trash?"}
      message={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            component="img"
            sx={{ width: "240px", alignSelf: "center", mb: 4 }}
            src={ConfirmDeleteImage}
            alt="Move to Trash"
          />
          {trashPreviewItems.length > 1 || previewContainsChildren ? (
            <>
              <Typography variant="body1">{message}</Typography>
              <List
                dense
                sx={{
                  position: "relative",
                  overflow: "auto",
                  maxHeight: "12rem",
                }}
              >
                {trashPreviewItems.map((item: any) => {
                  return (
                    <>
                      <TrashPreviewItem item={item} />
                      {item.children &&
                        item.children.map((child: any) => (
                          <TrashPreviewItem item={child} nested />
                        ))}
                    </>
                  )
                })}
              </List>
            </>
          ) : (
            <Typography variant="body1" align="justify">
              {message}
            </Typography>
          )}
        </Box>
      }
      cancelText={"Cancel"}
      confirmText={"Move to Trash"}
      onCancel={(e) => {
        e.preventDefault()
        setConfirmingTrash(null)
      }}
      onConfirm={async (e) => {
        e.preventDefault()
        onTrashConfirmed(itemsToTrash)
        setConfirmingTrash(null)
      }}
    />
  )
}

export default ConfirmTrashDialog
