import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material"
import { ArrowBack, ArrowForward, LinkOff, MoreVert } from "@mui/icons-material"
import React, { useState } from "react"
import RemoveFromPathDialog from "../learningPaths/dialogs/RemoveFromPathDialog"

export const directionEnum = {
  RIGHT: "RIGHT",
  LEFT: "LEFT",
}

/**
 *
 * @param props
 */
const CourseLearningPathMenu = (props) => {
  const { path, course, onShift, removeFromPath, index } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [removeFromPathDialogVisible, setRemoveFromPathDialogVisible] =
    useState(false)

  /**
   *
   * @param event
   */
  const handleMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  /**
   *
   * @param event
   */
  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const shiftLeftVisible = index !== 0
  const shiftRightVisible = index !== path.courses.length - 1

  return (
    <>
      <Tooltip placement="top" arrow title="Course Actions">
        <IconButton
          onClick={handleMenu}
          sx={(theme) => ({
            boxShadow: theme.shadows[2],
            backgroundColor: theme.palette.lexWhite,

            "&:hover": {
              backgroundColor: theme.palette.neutral.light,
            },
          })}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        open={open}
        onClick={(e) => e.stopPropagation()}
        onClose={handleClose}
        anchorEl={anchorEl}
        data-cy="course-actions"
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setRemoveFromPathDialogVisible(true)
          }}
        >
          <ListItemIcon>
            <LinkOff fontSize="small" />
          </ListItemIcon>
          <Typography>Remove from Learning Path</Typography>
        </MenuItem>
        {shiftRightVisible && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onShift(directionEnum.RIGHT)
              handleClose(e)
            }}
          >
            <ListItemIcon>
              <ArrowForward fontSize="small" />
            </ListItemIcon>
            <Typography>Move Right</Typography>
          </MenuItem>
        )}
        {shiftLeftVisible && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onShift(directionEnum.LEFT)
              handleClose(e)
            }}
          >
            <ListItemIcon>
              <ArrowBack fontSize="small" />
            </ListItemIcon>
            <Typography>Move Left</Typography>
          </MenuItem>
        )}
      </Menu>
      {removeFromPathDialogVisible && (
        <RemoveFromPathDialog
          path={path}
          course={course}
          onConfirm={(e) => {
            e.preventDefault()
            e.stopPropagation()
            removeFromPath(path, course)
            setRemoveFromPathDialogVisible(false)
            handleClose(e)
          }}
          onCancel={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setRemoveFromPathDialogVisible(false)
            handleClose(e)
          }}
          onClose
        />
      )}
    </>
  )
}

export default CourseLearningPathMenu
