import axios from "../../../api/axios"
import { OrganizationStrategy } from "../utils/constants"

type SaveMicroLearningCourseData = {
  courseId: string
  organizeBy: OrganizationStrategy
  condensePercent: number
  checkedOptions: any[]
  optionList: any[]
  createPath: boolean
}

/**
 * Send request to save micro-learning config as set of new courses.
 * @param data - Object containing parameters for Microlearning
 */
const saveMicroLearningCourses = (data: SaveMicroLearningCourseData) => {
  return axios.post("/microlearning/save", data)
}

export default saveMicroLearningCourses
