import ExportScreen from "../features/export/ExportScreen"

/**
 * Wrapping element for both versions of Export Screen, determined by branding
 * feature flag
 */
const ExportPage = () => {
  return <ExportScreen />
}

export default ExportPage
