import { Box, Button, Chip, Collapse, Stack } from "@mui/material"
import {
  SortKey,
  sortList,
  sortOptions,
} from "../../../welcome/hooks/useCoursesDisplay"
import SearchBar from "../../components/SearchBar"
import SortByControl from "../../components/SortByControl"
import ListContainer from "../../itemList/ListContainer"
import PageLayout from "../../layouts/PageLayout"
import { debounce, map } from "lodash/fp"
import { useState } from "react"
import useTrash from "../../../trash/hooks/useTrash"
import TrashedItemList from "./components/TrashedItemList"
import {
  ConfirmDeletedDialog,
  filterDocuments as filterItems,
} from "../../../trash/TrashScreen"
import { Item } from "../../../homeNavigation/types"
const SEARCH_DEBOUNCE_TIME = 200

/**
 * Page to display courses and folders that were trashed
 * @param props - Component props
 * @param props.location - Router location, should be "/trash"
 */
const TrashPage = (props: { location: any }) => {
  const { location } = props
  const [query, setQuery] = useState("")
  const [checkedItems, setCheckedItems] = useState<string[]>([])

  const [sortBy, setSortBy] = useState<SortKey>("last_update")
  const [confirmingDelete, setConfirmingDelete] = useState<Item[] | null>(null) // null or a course object

  const {
    documents: totalItems,
    deleteCourses,
    restoreCourses,
    ...otherTrashProps
  } = useTrash()

  const onSearchInputChange = debounce(SEARCH_DEBOUNCE_TIME, (query) => {
    setQuery(query)
  })

  /**
   * Mount dialog with all the items selected for hard deletion
   * @param items - List of directory items to permanently delete
   */
  const handleDeleteSelected = (items: Item[]) => setConfirmingDelete(items)

  /**
   * Restore items (remove from trash back to main menu)
   * @param ids - List of item ids to restore
   */
  const handleRestoreSelected = (ids: string[]) => restoreCourses(ids)

  const items = sortList(sortBy, filterItems(query, totalItems))

  return (
    <PageLayout navRail location={location}>
      <ListContainer
        title="Trash"
        searchBar={
          <SearchBar
            displaySearchResults={query !== ""}
            onSearchInputChange={onSearchInputChange}
            onClearSearch={() => setQuery("")}
          />
        }
        controls={
          <Box display="flex" alignItems="center" marginLeft="auto">
            <SortByControl
              options={sortOptions}
              sortBy={sortBy}
              handleSelectSortOption={(option: SortKey) => setSortBy(option)}
            />
          </Box>
        }
      >
        <>
          <Collapse in={checkedItems.length > 0}>
            <Stack
              direction="row"
              paddingX={3}
              gap={1}
              marginBottom={1}
              justifyContent="space-between"
            >
              <Stack direction="row" gap={1} alignItems="center">
                <Chip
                  clickable
                  variant="outlined"
                  label={`Restore selected`}
                  onClick={() => {
                    handleRestoreSelected(checkedItems)
                    setCheckedItems([])
                  }}
                />
                <Chip
                  color="error"
                  clickable
                  variant="outlined"
                  label={`Delete selected`}
                  onClick={() => {
                    const itemsToDelete = totalItems.filter((item: Item) =>
                      checkedItems.includes(item.id)
                    )
                    setConfirmingDelete(itemsToDelete)
                  }}
                />
              </Stack>

              <Stack direction="row">
                <Button
                  size="small"
                  onClick={() => {
                    setCheckedItems(map("id", items))
                  }}
                >
                  Select all
                </Button>
                <Button size="small" onClick={() => setCheckedItems([])}>
                  Deselect all
                </Button>
              </Stack>
            </Stack>
          </Collapse>
          <TrashedItemList
            items={items}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            showDeleteConfirmation={(item: Item) =>
              handleDeleteSelected([item])
            }
            displaySearchResults={query !== ""}
            restoreCourse={(id: string) => {
              handleRestoreSelected([id])
              setCheckedItems([])
            }}
            {...otherTrashProps}
          />
        </>
      </ListContainer>
      {confirmingDelete && (
        <ConfirmDeletedDialog
          confirmingDelete={confirmingDelete}
          setConfirmingDelete={setConfirmingDelete}
          onDeleteConfirmed={(items: Item[]) => {
            deleteCourses(items.map((item) => item.id))
            setCheckedItems([])
          }}
        />
      )}
    </PageLayout>
  )
}

export default TrashPage
