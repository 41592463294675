import { dropRightWhile, last, uniqueId } from "lodash"
import { getHeadings } from "../../../utilities/domUtils"

/**
 * An item within an outline tree.
 *
 * @typedef {{
 *  id: string,
 *  title: string,
 *  $el: Element,
 *  children: OutlineItem[]
 * }} OutlineItem
 */

/**
 * Create an outline item from a given element.
 *
 * @type {($element: Element) => OutlineItem}
 */
const makeItemFromElement = ($element) => ({
  id: $element.id ? `outline-${$element.id}` : uniqueId("outline-"),
  title: $element.textContent,
  $el: $element,
  children: [],
})

/**
 * Create a heading outline for a given container element.
 *
 * @param {Element} $container An element containing headings.
 * @returns {OutlineItem[]} The root of the outline tree.
 */
const getOutline = ($container) => {
  /** @type {{ children: OutlineItem[] }} */
  const root = { children: [] }

  /**
   * Accumulate an outline item into a tree structure.
   *
   * A stack containing the all of the candidate ancestor items is given, the first item of which
   * contains the root of the outline tree.
   *
   * The top of the stack is discarded until the direct parent for the given outline is identified.
   * Then the new item is added as a child and pushed onto the stack.
   *
   * @type {(stack: OutlineItem[], item: OutlineItem) => OutlineItem[]}
   */
  const accumulate = (stack, item) => {
    stack = dropRightWhile(
      stack,
      (stackItem) =>
        stackItem !== root && stackItem.$el.tagName >= item.$el.tagName
    )

    const parentItem = last(stack)
    parentItem.children.push(item)
    stack.push(item)

    return stack
  }

  return getHeadings($container).reduce(
    (stack, $heading) => accumulate(stack, makeItemFromElement($heading)),
    [root]
  )[0].children
}

export default getOutline
