import React, { useState } from "react"
import {
  Delete,
  Edit,
  GetApp,
  LibraryBooks,
  History,
  ContentCopy,
  Share,
  MoreVert,
  AddAPhoto,
  DriveFileMove,
} from "@mui/icons-material"
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"
import makeShareSettingsHref from "../../utilities/makeShareSettingsURL"
import { GiveCourseIcon } from "../../assets/icons/customIcons"

/**
 * Component for Context menu that for tiles within the CoursesDisplay
 * @param props - Component props
 */
const CourseContextMenu = (props) => {
  const {
    document,
    onTrash,
    onDuplicate,
    onUploadImage,
    onMove,
    onRenameFolder,
    isList,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  /**
   * Handler for opening menu popup
   * @param event - Click event
   */
  const handleMenu = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  /**
   * Handler for closing menu popup
   * @param event - Click event
   */
  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const isFolder = document.item_type === "folder"

  return (
    <>
      <Tooltip
        placement="top"
        arrow
        title={!isFolder ? "Course Actions" : "Folder Actions"}
      >
        <IconButton
          onClick={handleMenu}
          sx={(theme) => ({
            ...(!isList && {
              boxShadow: theme.shadows[2],
              backgroundColor: theme.palette.lexWhite,
            }),
            "&:hover": {
              backgroundColor: theme.palette.neutral.light,
            },
          })}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        open={open}
        onClick={(e) => e.stopPropagation()}
        onClose={handleClose}
        anchorEl={anchorEl}
        data-cy="course-actions"
      >
        {isFolder ? (
          <>
            <MenuItem
              onClick={(e) => {
                e.preventDefault()
                handleClose(e)
                onRenameFolder(document)
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <Typography>Rename</Typography>
            </MenuItem>
          </>
        ) : (
          <>
            {" "}
            <MenuItem
              component={Link}
              to={{
                pathname: "/editor",
                search: `?course=${document.id}`,
                state: { course: document },
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <Typography>Edit</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.preventDefault()
                handleClose(e)
                onMove([document.id])
              }}
            >
              <ListItemIcon>
                <DriveFileMove fontSize="small" />
              </ListItemIcon>
              <Typography>Move to Folder</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleClose(e)
                onDuplicate(document)
              }}
            >
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <Typography>Duplicate Course</Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/history/?page=0&limit=20&filterCourse=${document.id}&back=home`}
            >
              <ListItemIcon>
                <History fontSize="small" />
              </ListItemIcon>
              <Typography>Course History</Typography>
            </MenuItem>
            <MenuItem component={Link} to={`/export/?course=${document.id}`}>
              <ListItemIcon>
                <GetApp fontSize="small" />
              </ListItemIcon>
              <Typography>Export Course</Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to={{
                pathname: `/microlearning`,
                search: `?course=${document.id}`,
                state: { course: document },
              }}
            >
              <ListItemIcon>
                <LibraryBooks fontSize="small" />
              </ListItemIcon>
              <Typography>Create Microlearning</Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              replace={true}
              to={makeShareSettingsHref(document, {
                modal: true,
              })}
              onClick={handleClose}
            >
              <ListItemIcon>
                <Share fontSize="small" />
              </ListItemIcon>
              <Typography>Share</Typography>
            </MenuItem>
            <MenuItem component={Link} to={`/give/?course=${document.id}`}>
              <ListItemIcon>
                <GiveCourseIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Give Course</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.preventDefault()
                handleClose(e)
                onUploadImage(document)
              }}
            >
              <ListItemIcon>
                <AddAPhoto fontSize="small" />
              </ListItemIcon>
              <Typography>Edit Course Thumbnail</Typography>
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            handleClose(e)
            onTrash([document.id])
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <Typography>Move to Trash</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default CourseContextMenu
