import { Box, IconButton, Tooltip } from "@mui/material"
import { Apps, Reorder } from "@mui/icons-material"
import React from "react"

/**
 * Component for icon button switching between grid and list layout for courses
 *
 * @param props - Component props
 * @param props.isGridView - Boolean for either Grid or List view mode
 * @param props.toggleView - Callback to switch between grid and list in parent
 */
const CoursesViewToggle = ({ isGridView, toggleView }) => {
  return (
    <Box>
      <Tooltip
        placement="bottom"
        arrow
        title={!isGridView ? "Grid view" : "List view"}
      >
        <IconButton onClick={toggleView}>
          {!isGridView ? <Apps /> : <Reorder />}
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default CoursesViewToggle
