import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material"
import Button from "@mui/material/Button"
import { Close } from "@mui/icons-material"
import { useState } from "react"
import useGuestEmail from "./useGuestEmail"

const GuestEmailDialog = (props) => {
  const { onClose: onCloseProp, open: openProp, ...otherProps } = props

  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)

  const { setGuestEmail, open, dispatchPendingAction, dismissPromptForEmail } =
    useGuestEmail()

  const handleSubmit = () => {
    if (email === "") {
      setError("Email cannot be blank.")
      return
    }
    if (!validateEmail(email)) {
      setError("Email is not valid.")
      return
    }

    setGuestEmail(email)

    dispatchPendingAction()
    onClose()
  }

  const onClose = () => {
    setError(null)
    setEmail("")

    dismissPromptForEmail()

    onCloseProp && onCloseProp()
  }

  return (
    <Dialog
      fullWidth
      open={open || openProp}
      onClose={onClose}
      maxWidth="sm"
      {...otherProps}
    >
      <DialogTitle>Your Email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter your email address to comment
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <Close />
          </IconButton>
        </DialogContentText>
        <TextField
          error={error != null}
          helperText={error}
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onInput={(e) => {
            e.preventDefault()
            setEmail(e.target.value)
          }}
        />
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleSubmit}>Next</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default GuestEmailDialog

/** Validate an email address (from https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript) */
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}
