/**
 * Create feature decisions given a set of feature flags.
 *
 * @param {{ isEnabled: (name:str) => boolean }} features
 */
const createFeatureDecisions = (features) => {
  return {
    includeCookiesInRequest: () => features.isEnabled("server-side-cookies"),
    includeEditorChangeTracking: () => features.isEnabled("track-changes"),
    useServerSideCookies: () => features.isEnabled("server-side-cookies"),
    enableBrand: () => features.isEnabled("brand-ux"),
    enableInstructionsDetector: () =>
      features.isEnabled("enable-intructions-detector"),
    audioFileUpload: () => features.isEnabled("audio-file-upload"),
    trueFalseQuestions: () => features.isEnabled("true-false-questions"),
    fillInTheBlankQuestions: () =>
      features.isEnabled("fill-in-the-blank-questions"),
  }
}

export default createFeatureDecisions
