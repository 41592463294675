import { createSelector } from "reselect"
import { selectAllJobs } from "./jobsEntityAdaptor"

/**
 * Determine if there are any stored async jobs pending completion.
 * @type {(state: any) => boolean}
 */
const selectHasPendingJobs = createSelector(
  selectAllJobs,
  (jobs) =>
    !!jobs.length &&
    jobs.some(
      ({ status }) =>
        status !== "complete" && status !== "failed" && status !== "canceled"
    )
)

export default selectHasPendingJobs
