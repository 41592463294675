import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Popper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import fetchUserConfiguration from "../quick-insert/api/fetchUserConfiguration"
import { Star, StarOutline } from "@mui/icons-material"
import { yellow } from "@mui/material/colors"
import saveCustomInstructionOptions from "./api/saveCustomInstructionOptions"
import { RewriteOptions } from "./RewriteOptions"
import { debounce } from "lodash"

const SAVE_FAVOURITES_DEBOUNCE_TIME = 1000

type CustomInstructionCategory = "recents" | "favourites" | "defaults"

type InstructionOption = {
  title: string
  starred: boolean
  category: CustomInstructionCategory
  categoryLabel: string
}

type CustomInstructionOptions = {
  [name in CustomInstructionCategory]: string[]
}

/**
 * Extract all instructions from each category and place them in a single list that can
 * be parsed by the Autocomplete component
 *
 * In the event of duplicates (ie a favourited option is also recent), the list will be
 * rendered as follows:
 *
 * 1 - Favourited options
 * 2 - Recent options
 * 3 - Default options.
 *
 * If an option already appears in a category of higher order, it will be removed from its
 * other category
 *
 * @param customInstructionOptions - Map of custom instructions based on category
 */
const flattenCustomInstructions = (
  customInstructionOptions: CustomInstructionOptions
): InstructionOption[] => {
  const { favourites, recents, defaults } = customInstructionOptions

  const favouriteOptions: InstructionOption[] = favourites.map(
    (title: string) => ({
      title,
      starred: true,
      category: "favourites",
      categoryLabel: "Favourites",
    })
  )

  const recentOptions: InstructionOption[] = recents
    .filter((title) => ![...favourites].includes(title)) // Don't include options already in favourites
    .map((title: string) => ({
      title,
      starred: false,
      category: "recents",
      categoryLabel: "Recently Used",
    }))

  const defaultOptions: InstructionOption[] = defaults
    .filter((title) => ![...recents, ...favourites].includes(title)) // Don't include options already in recents or favourites
    .map((title) => ({
      title,
      starred: false,
      category: "defaults",
      categoryLabel: "Sample Instructions",
    }))

  return [...favouriteOptions, ...recentOptions, ...defaultOptions]
}

/**
 * Send debounced request to update favourited items when an item is starred/unstarred
 */
const updateCustomInstructionFavourites = debounce(
  (favourites: string[]) => saveCustomInstructionOptions({ favourites }),
  SAVE_FAVOURITES_DEBOUNCE_TIME
)

/**
 * Custom Instructions for the rewrite options
 * @param props - The props
 * @param props.rewriteOptions - The rewrite options
 * @param props.onRewriteOptionsChange - The function to change the rewrite options
 * @param props.menuContainer - The container for the menu
 */
const RewriteCustomInstructions = (props: {
  rewriteOptions: RewriteOptions
  onRewriteOptionsChange: (options: RewriteOptions) => void
  menuContainer: Element
}) => {
  const { rewriteOptions, onRewriteOptionsChange, menuContainer } = props
  const [customInstructionOptions, setCustomInstructionOptions] =
    useState<CustomInstructionOptions>({
      recents: [],
      favourites: [],
      defaults: sampleCustomInstructions,
    })

  const [pendingCustomInstructions, setPendingCustomInstructions] = useState(
    rewriteOptions.customInstructions
  )

  /**
   * Either favourite or un-favourite selected option
   * @param option - Option that the star button was selected for
   */
  const onStarItem = (option: InstructionOption) => {
    const { title, starred } = option
    const updatedStarValue = !starred

    const updatedOptions: CustomInstructionOptions = {
      ...customInstructionOptions,
      favourites: updatedStarValue
        ? [...customInstructionOptions.favourites, title]
        : customInstructionOptions.favourites.filter((item) => item !== title),
    }

    /**
     * Update the custom instructions map
     */
    setCustomInstructionOptions(updatedOptions)

    /**
     * Send request to server to persist updated configuration
     */
    updateCustomInstructionFavourites(updatedOptions.favourites)
  }

  useEffect(() => {
    fetchUserConfiguration().then((response: any) => {
      console.log(response.data)

      const { custom_instruction_favourites, custom_instruction_recents } =
        response.data

      setCustomInstructionOptions((prev) => ({
        ...prev,
        recents: custom_instruction_recents,
        favourites: custom_instruction_favourites,
      }))
    })
  }, [])

  /**
   * Memoize options to we only compute them when the instruction map has been modified.
   */
  const options = useMemo(
    () => flattenCustomInstructions(customInstructionOptions),
    [customInstructionOptions]
  )

  return (
    <>
      <Autocomplete
        sx={{
          flexGrow: 1,
        }}
        size="small"
        freeSolo
        options={options}
        PopperComponent={({ children, ...props }) => (
          <Popper {...props} container={menuContainer}>
            {children}
          </Popper>
        )}
        groupBy={(option) => option.categoryLabel}
        inputValue={pendingCustomInstructions}
        onInputChange={(event, newInputValue) => {
          setPendingCustomInstructions(newInputValue)
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            // Apply custom instructions
            onRewriteOptionsChange({
              ...rewriteOptions,
              customInstructions: pendingCustomInstructions,
            })
          }
        }}
        getOptionLabel={(option: any) => option.title}
        isOptionEqualToValue={(
          option: InstructionOption,
          value: InstructionOption
        ) => {
          return option.title === value.title
        }}
        renderInput={(params) => (
          <TextField {...params} label="Custom Instructions" />
        )}
        renderOption={(props: any, option) => {
          const { key, ...optionProps } = props

          const starred = option.starred

          return (
            <Stack
              direction="row"
              component="li"
              {...optionProps}
              justifyContent="space-between"
            >
              <Box flex="1 1 0">{option.title}</Box>
              <Tooltip title={starred ? "Starred" : "Not starred"}>
                <IconButton
                  aria-label="star"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onStarItem(option)
                  }}
                >
                  {starred ? (
                    <Star sx={{ color: yellow[700] }} />
                  ) : (
                    <StarOutline />
                  )}
                </IconButton>
              </Tooltip>
            </Stack>
          )
        }}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={
          pendingCustomInstructions === rewriteOptions.customInstructions
        }
        onClick={() => {
          // Apply custom instructions
          onRewriteOptionsChange({
            ...rewriteOptions,
            customInstructions: pendingCustomInstructions,
          })
        }}
      >
        Apply
      </Button>
    </>
  )
}

/** Sample custom instructions */
const sampleCustomInstructions = [
  "Frame content around learner objectives",
  "Insert FAQ-style elements for clarity",
  "Add cultural sensitivity to examples",
  "Highlight key takeaways from the section",
  "Enhance storytelling elements",
  "Create scenarios for practical application",
  "Customize for a specific target audience",
  "Infuse a touch of humor and anecdotes",
  "Enhance inclusivity and diversity references",
  "Adjust content for global audiences",
]

export default RewriteCustomInstructions
