/**
 * A method called to stop listening for mutation events.
 * @typedef {() => void} MutationObserverUnsubscribe
 */

/**
 * Observe DOM mutations within a given node.
 *
 * Mutations of the DOM within the container will trigger the given callback. An
 * unsubscribe function that will disconnect the observer.
 *
 * This is a convenience wrapper around the [MutationObserver][1] interface.
 *
 * [1]: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 *
 * @param {Node} container
 * @param {MutationCallback} callback
 * @param {?MutationObserverInit} options
 * @returns {MutationObserverUnsubscribe}
 */
const observeMutations = (container, callback, options) => {
  const observer = new MutationObserver(callback)

  observer.observe(container, options)

  const unsubscribe = () => {
    observer.disconnect()
  }

  return unsubscribe
}

export default observeMutations
