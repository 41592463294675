import {
  useSensor,
  MouseSensor,
  PointerSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core"
import { useState } from "react"

const DISTANCE_BEFORE_DRAG_PIXELS = 8 // pixels

/**
 * Calibrate drag and drop sensors to strike a sensible balance
 * so that both clicking the item to select it, or to hold down to drag
 * are both intuitive.
 */
const sensorConfig = {
  activationConstraint: { distance: DISTANCE_BEFORE_DRAG_PIXELS },
}

/**
 * Hook to set up sensors for use with draggable items
 *
 * @param handleMoveItems - Function to perform move item operation
 */
const useDragDropItems = (handleMoveItems: any) => {
  const mouseSensor = useSensor(MouseSensor, sensorConfig)
  const pointerSensor = useSensor(PointerSensor, sensorConfig)

  const sensors = useSensors(mouseSensor, pointerSensor)

  const [isDragging, setIsDragging] = useState(false)

  /**
   * Callback when a drag begins on an item
   */
  const onDragStart = () => setIsDragging(true)

  /**
   * Callback when a drag ends on an item
   * @param event - Drag end event
   */
  const onDragEnd = (event: DragEndEvent) => {
    setIsDragging(false)
    const { over, active } = event

    // If not over a droppable, return
    if (!over) {
      return
    }

    const destinationID = over.data.current?.id

    handleMoveItems(
      [active.data.current],
      destinationID === "__ALL__" ? null : over.data.current
    )
  }

  return { sensors, isDragging, onDragStart, onDragEnd }
}

export default useDragDropItems
