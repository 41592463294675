import { useState, useEffect } from "react"
import { getCourses } from "../../../api"

type PrerequisiteOptionsState = {
  loading: boolean
  options: string[]
  error: Error | null
}

/**
 * Provides a list of prerequesite options for a given course.
 *
 * @param courseId The course ID.
 */
const usePrerequisiteOptions = (courseId: string) => {
  const [state, setState] = useState({
    loading: true,
    options: [],
    error: null,
  } as PrerequisiteOptionsState)

  useEffect(() => {
    if (!courseId) {
      return
    }

    setState({ options: [], loading: true, error: null })
    fetchPrequisiteOptions(courseId)
      .then((options) => setState({ loading: false, options, error: null }))
      .catch((error) => () => {
        console.error(error)
        setState({ options: [], loading: false, error })
      })
  }, [courseId])

  return state
}

export default usePrerequisiteOptions

type Course = { id: string; title: string }

/**
 * Fetches the list of potential prereqiusites for a given course.
 *
 * @param courseId The course ID.
 */
const fetchPrequisiteOptions = async (courseId: string) => {
  const response = await getCourses()
  const data = (response.data.items || []) as Course[]

  return data
    .filter((course) => course.id !== courseId)
    .map((course) => course.title)
}
