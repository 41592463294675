import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react"

type FormData = { [field: string]: string }
type ContextValue = [FormData, Dispatch<SetStateAction<FormData>>]

const Context = createContext<ContextValue>([{}, () => {}])

/**
 * Persists common form state across multiple views.
 * @param props The component props.
 */
export function FormPersistenceProvider(props: PropsWithChildren<{}>) {
  const value = useState<FormData>({})

  return <Context.Provider value={value} {...props} />
}

/**
 * Accesses persisted form field values.
 *
 * @param name The field name.
 * @returns The current value and a dispatcher for updating the value.
 */
export function useFieldValues(name: string): [string, Dispatch<string>] {
  const [state, setState] = useContext(Context)

  const setValue = useCallback(
    (value) => {
      setState((data) => ({ ...data, [name]: value }))
    },
    [name, setState]
  )

  return [state[name], setValue]
}
