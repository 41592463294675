import { useDispatch } from "react-redux"
import { useAPI } from "../../../contexts/api"
import trashCourses from "../../welcome/api/trashCourses"
import jobsSlice from "../../../store/jobs/jobsSlice"
import { useCallback } from "react"
import uploadCourseThumbnail from "../../welcome/api/uploadCourseThumbnail"
import { useSnackbar } from "notistack"
import useCourseImportApi from "../../createCourse/hooks/useCourseImportApi"

/**
 * Hook for handling interactions on course screen
 * @param updateCourses - Function to re-fetch courses
 */
const useCourseActions = (updateCourses: () => void) => {
  const api = useAPI()
  // retrieve current async jobs from redux store
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { cancelImport } = useCourseImportApi()

  /**
   * Calls the duplicateCourse callback function returned by the useCourses hook
   * @param document - Course object
   */
  const duplicateCourse = async (document: any) => {
    return api
      .duplicateCourse(document.id)
      .then(updateCourses)
      .catch(console.log)
  }

  /**
   * Send request to move courses with the provided IDs to the trash
   * @param ids - List of IDs of courses to trash
   */
  const moveCoursesToTrash = async (ids: string[]) => {
    return trashCourses(ids).then(updateCourses).catch(console.log)
  }

  /**
   * Dispatch an action and request to cease an in-progress course upload
   * @param id - ID of course to cancel the creation of
   */
  const cancelCourseCreation = async (id: string) => {
    // Mark it as canceled before asking the server to update
    // the job list, otherwise it appears in the list briefly
    dispatch(jobsSlice.actions.jobCanceled({ async_id: id }))

    await cancelImport(id)
    updateCourses()
  }

  const saveCustomCourseImage = useCallback(
    (id, image) => {
      return uploadCourseThumbnail({
        image: image.file,
        id,
      }).then(() => {
        enqueueSnackbar("Course image updated.", { variant: "success" })
        return updateCourses()
      })
    },
    [enqueueSnackbar, updateCourses]
  )

  return {
    duplicateCourse,
    moveCoursesToTrash,
    cancelCourseCreation,
    saveCustomCourseImage,
  }
}

export default useCourseActions
