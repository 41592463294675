import { throttle } from "lodash"
import { omit, uniqueId } from "lodash/fp"
import { useEffect, useRef, useState } from "react"
import getDocumentStats from "../utils/getDocumentStats"
import observeMutations from "../utils/observeMutations"

/**
 * @typedef {import("../utils/getDocumentStats").DocumentStats} DocumentStats
 * @typedef {import("luxon").Duration} Duration
 *
 * @typedef {DocumentStats & { duration?: Promise<Duration>}} DocumentStatsState
 */

/** The minimum amout of time to wait between stats updates. */
const THROTTLE_UPDATE = 20 // milliseconds

/**
 * The initial document stats.
 * @type {DocumentStatsState}
 */
const INITIAL_STATE = {
  wordCount: 0,
  sectionCount: 0,
  imageCount: 0,
  questionCount: 0,
  videoCount: 0,
}

/**
 * Use collected reading stats for a document or document section.
 *
 * @type {(container?: Element, section?: Element) => DocumentStatsState}
 */
const useDocumentStats = (container, section) => {
  const sectionRef = useRef(section)
  const [state, setState] = useState(INITIAL_STATE)

  // Update the stats whenever container or section change.
  useEffect(() => {
    sectionRef.current = section
    updateDocumentStats(container, section, setState)
  }, [container, section])

  // Update the stats whenever elements in the container is modified.
  useEffect(() => {
    if (!container) {
      return
    }

    return observeMutations(
      container,
      throttle(() => {
        updateDocumentStats(container, sectionRef.current, setState)
      }, THROTTLE_UPDATE),
      {
        childList: true,
        characterData: true,
        subtree: true,
      }
    )
  }, [container])

  return state
}

export default useDocumentStats

/**
 * Update the collected reading stats for a document or document section.
 *
 * @param {Element?} container
 * @param {Element?} section
 * @param {import("react").Dispatch<import("react").SetStateAction<DocumentStatsState>>} setState
 */
const updateDocumentStats = (container, section = container, setState) => {
  if (!container || !container.contains(section)) {
    return
  }

  const stats = getDocumentStats(container, section)
  const transationId = uniqueId("update-section-stats")

  setState({ ...omit(["duration"], stats), transationId })
  stats.duration?.then((duration) => {
    setState((state) =>
      transationId === state.transationId ? { ...state, duration } : state
    )
  })
}
