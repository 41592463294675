import { Box, Button, Switch, Typography } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { useState } from "react"
import PreviewIcon from "@mui/icons-material/Preview"
import { DownloadTranslationSheetButton } from "./DownloadTranslationSheetButton"
import { UploadTranslationSheetButton } from "./UploadTranslationSheetButton"
import { TranslationPreviewModal } from "./TranslationPreviewModal"
import { availableTranslationLocales, localeNames } from "./translationLocales"

/**
 * A component for controlling the translation of the course
 * @param props - The props for the component
 * @param props.courseId - The ID of the course
 * @param props.courseHtml - The HTML content of the course
 * @param props.summaryHtml - The HTML content of the course summary
 * @param props.translationLanguageCode - The language code of the translation
 * @param props.handleChangeTranslationLanguageCode - The function to call when the translation language code changes
 */
export function TranslationControls(props: {
  courseId: string
  courseHtml: string
  summaryHtml: string
  translationLanguageCode: string | null
  handleChangeTranslationLanguageCode: (languageCode: string | null) => void
}) {
  const {
    courseId,
    courseHtml,
    summaryHtml,
    translationLanguageCode,
    handleChangeTranslationLanguageCode,
  } = props

  const [previewModalOpen, setPreviewModalOpen] = useState(false)

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="body1" fontWeight="bold">
        Translate Exported Course
      </Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <Switch
          checked={translationLanguageCode != null}
          onChange={(e) => {
            handleChangeTranslationLanguageCode(e.target.checked ? "fr" : null)
          }}
        />
        <Typography variant="body2" sx={{ ml: 1 }}>
          Our AI will automatically translate this export to another language
          for you.
        </Typography>
      </Box>

      {translationLanguageCode != null && (
        <>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select
              labelId="language-select-label"
              value={translationLanguageCode}
              onChange={(e) =>
                handleChangeTranslationLanguageCode(e.target.value || null)
              }
              label="Language"
            >
              {availableTranslationLocales.map((locale) => (
                <MenuItem key={locale} value={locale}>
                  {localeNames[locale]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {translationLanguageCode && (
            <Box display="flex" gap={2}>
              <DownloadTranslationSheetButton
                courseId={courseId}
                language={translationLanguageCode}
                courseHtml={courseHtml}
                summaryHtml={summaryHtml}
              />
              <UploadTranslationSheetButton
                courseId={courseId}
                language={translationLanguageCode}
              />
              <Button
                variant="outlined"
                onClick={() => setPreviewModalOpen(true)}
                disabled={!courseHtml}
                startIcon={<PreviewIcon />}
              >
                Preview Translation
              </Button>
            </Box>
          )}
          {previewModalOpen && translationLanguageCode && (
            <TranslationPreviewModal
              onClose={() => setPreviewModalOpen(false)}
              courseId={courseId}
              courseHtml={courseHtml}
              language={translationLanguageCode}
            />
          )}
        </>
      )}
    </Box>
  )
}
