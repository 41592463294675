import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material"
import { motion } from "framer-motion"

export type ButtonProps = MuiButtonProps<typeof motion.button>

/**
 * Renders a button with motion properties.
 * @param props The component props.
 */
export function Button(props: ButtonProps) {
  return <MuiButton component={motion.button} {...props} />
}

export default Button
