import { Box, MenuItem, MenuList, Paper } from "@mui/material"
import "./quick-insert.css"
import { QuickInsertContext, QuickInsertItem } from "./quickInsertItems"
import useQuickInsert from "./hooks/useQuickInsert"
import QuickInsertMenuItem from "./QuickInsertMenuItem"

/**
 * Quick insert menu. Used by setupQuickInsertMenu
 * @param props - Component props
 * @param props.insertContext - Object containing context of where insertiton will occur
 * @param props.closeMenu - Callback to unmount this component
 * @param props.quickInsertItems - Total list of possible insert options
 * @param props.enableFavourites - Feature flag to display favourites
 */
export function QuickInsertMenu(props: {
  /** Context for the insertion */
  insertContext: QuickInsertContext
  /** Called to close the menu */
  closeMenu: (starredItems?: string[]) => void
  /** Items to appear in the menu */
  quickInsertItems: QuickInsertItem[]
  /** Feature flag to allow starred items */
  enableFavourites?: boolean
}) {
  const { closeMenu, quickInsertItems, insertContext, enableFavourites } = props

  const {
    direction,
    anchorElement,
    starredItems,
    updateStarredItems,
    normalItems,
    searchText,
    saveAndClose,
    menuRef,
  } = useQuickInsert(
    insertContext,
    closeMenu,
    quickInsertItems,
    enableFavourites
  )

  return (
    <Box
      sx={{
        position: "absolute",
        left: anchorElement.offsetLeft,
        top: anchorElement.offsetTop,
        zIndex: 1000,
      }}
    >
      <Paper
        sx={{
          top: direction === "down" ? anchorElement.offsetHeight : undefined,
          bottom: direction === "up" ? 0 : undefined,
          position: "absolute",
          maxHeight: "50vh",
          overflow: "auto",
        }}
        elevation={5}
      >
        <MenuList ref={menuRef} dense>
          {starredItems.length === 0 && normalItems.length === 0 && (
            <MenuItem disabled>No results for "{searchText}"</MenuItem>
          )}
          {starredItems.length > 0 &&
            starredItems.map((item, index) => (
              <QuickInsertMenuItem
                key={item.id}
                item={item}
                onInsert={() => {
                  item.onInsert(insertContext)
                  saveAndClose()
                }}
                starred
                onStar={() => {
                  updateStarredItems(item.id, false)
                }}
                enableFavourites={enableFavourites}
                divider={starredItems.length === index + 1}
              />
            ))}

          {normalItems.map((item: QuickInsertItem) => (
            <QuickInsertMenuItem
              key={item.id}
              item={item}
              onInsert={() => {
                item.onInsert(insertContext)
                saveAndClose()
              }}
              onStar={() => {
                updateStarredItems(item.id, true)
              }}
              enableFavourites={enableFavourites}
            />
          ))}
        </MenuList>
      </Paper>
    </Box>
  )
}
