import { DEFAULT_POLL_INTERVAL } from "../utils/constants"
import * as api from "../../../api"
import { asyncStatusMessage } from "../../export/utils/contants"

/**
 * Wait on an async process of a given ID until completion or failure
 * @param jobId - ID of async job currently processing
 * @param options - Object with optional params
 */
const waitForJob = (jobId: string, options: any) => {
  const {
    completeStatus = "complete",
    failedStatus = "failed",
    interval = DEFAULT_POLL_INTERVAL,
  } = options

  return new Promise((resolve, reject) => {
    /**
     * Periodically poll the server for what stage the async job is at
     */
    const checkJob = async () => {
      try {
        const { data: job } = await api.checkAsyncJob(jobId)
        const { status, data } = job

        if (status === completeStatus) {
          return resolve(data)
        }

        if (status === failedStatus) {
          // @ts-ignore
          return reject(new Error(asyncStatusMessage[status]))
        }
      } catch (err) {
        /* you may want some retry logic here */
        return reject(new Error("Error checking async job status."))
      }

      setTimeout(() => {
        checkJob()
      }, interval)
    }

    checkJob()
  })
}

export default waitForJob
