export enum OrganizationStrategy {
  FullDocument = "FULL_DOCUMENT",
  Section = "SECTION",
  Concept = "CONCEPT",
}

export const HEADING_LEVELS = [
  {
    value: "H1",
    label: "Heading 1",
  },
  {
    value: "H2",
    label: "Heading 2",
  },
  {
    value: "H3",
    label: "Heading 3",
  },
  {
    value: "H4",
    label: "Heading 4",
  },
  {
    value: "H5",
    label: "Heading 5",
  },
  {
    value: "H6",
    label: "Heading 6",
  },
]

export const FORMAT_OPTIONS = [
  { value: "docx", label: "Word (.docx)" }, // First in the list is the default
  { value: "html", label: "HTML" },
]

//the server side enumerations
export const microlearningAsyncStatusEnum = {
  START: "start",
  FETCH_COURSE: "fetch_course",
  CREATE_EXPORT_ASSETS: "create_export_assets",
  PUBLISH_URL: "publish_url",
  COMPLETE: "complete",
  FAILED_NO_RETRY: "failed",
}

/**
 * Concept Microlearning enumerations and labels
 */
export const mlGetConceptStatus = {
  START: "start",
  GET_CONCEPTS: "get_concepts",
  COMPLETE: "complete",
  FAILED_NO_RETRY: "failed",
}

export const mlGetConceptStatuslabels = {
  [mlGetConceptStatus.START]: "Starting search for microlearning concepts.",
  [mlGetConceptStatus.GET_CONCEPTS]: "Searching for concepts.",
  [mlGetConceptStatus.COMPLETE]: "",
  [mlGetConceptStatus.FAILED_NO_RETRY]: "Concept processing failed.",
}

export const CONCEPT_STATUS_ERROR = 0
// const CONCEPT_STATUS_SUCCESS = 1
export const CONCEPT_STATUS_TOO_MANY_CONCEPTS = 2

export const DEFAULT_POLL_INTERVAL = 1000
