import axiosGlobal from "./axios"
import config from "../config"
import * as api from "../api"
import { uuidv4 } from "../utilities/domUtils"

const useDevUpload =
  config.apiUrl?.includes("localhost") ||
  config.apiUrl?.includes("dev.learnexperts")

/**
 * @typedef {import("axios").AxiosInstance} AxiosInstance
 *
 * @typedef {object} UploadCourseDocumentConfig
 * @property {AxiosInstance} axios Thsd.
 */

/**
 * Upload a document for course creation.
 *
 * @param options Upload options
 * @returns The async job for the upload process.
 */
const uploadCourseDocumentsToS3 = async (options = {}) => {
  const {
    applyTemplates,
    selectedItems,
    learningPath,
    courseID,
    axios = axiosGlobal,
    setUploadProgress,
    rolloutServerlessImportService,
    rolloutServerlessImportAPI,
    tenantId,
    uploadJobId,
  } = options

  const { data, uploadFileLookup } = prepareFileUploadData(
    selectedItems,
    applyTemplates,
    learningPath,
    courseID,
    tenantId,
    uploadJobId,
    null
  )

  const uploadProgressList = Object.values(uploadFileLookup).sort(
    (a, b) => a.index - b.index
  )

  /**
   * Return a callback to track an individual upload's progress
   * @param fileInfo file object for the upload
   */
  const makeUploadConfig = (fileInfo) => {
    /**
     * Callback to track a file upload
     * @param progressEvent Browser progress event for file uploads
     */
    const onUploadProgress = (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      if (setUploadProgress) {
        fileInfo.percentCompleted = percentCompleted
        setUploadProgress([...uploadProgressList])
      }
    }

    return { onUploadProgress }
  }

  let result
  if (rolloutServerlessImportAPI) {
    data.append("tenant_id", tenantId)
    result = await api.startImport(data, true)
  } else {
    result = await axios.post("/documents/", data)
  }

  const resultData = result.data

  if (resultData.file_list == null) {
    resultData.file_list = resultData.file_info
  }

  const uploads = []
  for (const uploadItem of resultData.file_list) {
    for (const uploadFile of uploadItem.files) {
      const fileInfo = uploadFileLookup[uploadFile.index]

      if (fileInfo.file == null) {
        // file was already uploaded
        continue
      }

      const uploadUrl = uploadFile.upload_url

      const headers = {
        "Content-Type": fileInfo.file.type,
      }

      if (uploadItem.transcribeMode) {
        headers["x-amz-meta-transcribe-mode"] = uploadItem.transcribeMode
      }

      const options = {
        headers,
        ...makeUploadConfig(fileInfo),
      }

      uploads.push(
        api.uploadImportFileWithRetry(uploadUrl, fileInfo.file, options)
      )
    }
  }

  await Promise.all(uploads)

  if (rolloutServerlessImportAPI) {
    const result = await api.importFilesUploaded(resultData.async_id)
    if (result.status !== 200) {
      console.error(`Error importing files: ${result.status}`)
      throw new Error(`Error importing files: ${result.status}`)
    }
  } else {
    await axios.put(`/documents/${resultData.async_id}`)
  }

  return result.data
}

/**
 * Get headings for import files
 * @param itemList - list of upload items
 * @param applyTemplates - upload option
 * @param learningPath - upload option
 * @param tenantId - the user
 * @param courseID - upload option
 * @param uploadJobId - continuing job id
 * @param fileInfo - info to get hedaings for
 * @param axios - optional axios
 */
export const getImportFileHeadings = async (
  itemList,
  applyTemplates,
  learningPath,
  tenantId,
  courseID,
  uploadJobId,
  fileInfo,
  axios = axiosGlobal
) => {
  const { data, uploadFileLookup } = prepareFileUploadData(
    itemList,
    applyTemplates,
    learningPath,
    courseID,
    tenantId,
    uploadJobId,
    fileInfo.files
  )

  const { data: resultData } = await api.startImport(data, false)
  // in the response, file_info is a list of sources with files that have import_urls set
  // We want to upload the ones we care about for this header request
  // which is for the source passed in as fileInfo

  const sourceIndex = itemList.indexOf(fileInfo)
  const source = resultData.file_info[sourceIndex]
  const uploads = []

  for (const uploadFile of source.files) {
    const uploadFileInfo = uploadFileLookup[uploadFile.index]

    if (uploadFileInfo.file != null) {
      const options = {
        headers: {
          "Content-Type": uploadFileInfo.file.type,
        },
      }

      uploads.push(
        axios.put(uploadFile.upload_url, uploadFileInfo.file, options)
      )
    }
  }

  await Promise.all(uploads)
  const jobId = resultData.job_id

  const { headings } = await api.fetchImportSourceHeadings(
    jobId,
    source.source_id
  )

  return { headings, jobId }
}

/**
 * Make request data for a file upload
 * @param selectedItems - checked upload files
 * @param applyTemplates - upload option
 * @param learningPath - upload option
 * @param courseID - upload option
 * @param tenantId - the user
 * @param uploadJobId - continuing job id
 * @param uploadFiles - files to upload
 */
const prepareFileUploadData = (
  selectedItems,
  applyTemplates,
  learningPath,
  courseID,
  tenantId,
  uploadJobId,
  uploadFiles
) => {
  const uploadFileLookup = {}
  const data = new FormData()

  if (uploadFiles == null) {
    uploadFiles = selectedItems.flatMap((item) => item.files)
  }

  for (const uploadFile of uploadFiles) {
    // Add a file id so that if this is uploaded for headings,
    // we don't try to upload again
    if (uploadFile.file_id == null) {
      const fileName = uploadFile.file?.name ?? uploadFile.name
      const fileExtension = fileName.split(".").pop().toLowerCase()
      uploadFile.file_id = `${uuidv4()}.${fileExtension}`
    }

    uploadFileLookup[uploadFile.index] = {
      file: uploadFile.file,
      name: uploadFile.name,
      index: uploadFile.index,
      percentCompleted: uploadFile.file == null ? 100 : 0,
    }

    delete uploadFile.file
  }

  data.append("apply_templates", applyTemplates)
  data.append("use_direct_s3_uploads", true)
  data.append("file_info", JSON.stringify(selectedItems))
  if (learningPath) {
    data.append("learning_path", learningPath)
  }
  if (courseID) {
    data.append("course_id", courseID)
  }
  if (uploadJobId) {
    data.append("job_id", uploadJobId)
  }
  data.append("tenant_id", tenantId)

  return { data, uploadFileLookup }
}

export default uploadCourseDocumentsToS3
