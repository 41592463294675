import { Box, Divider, Typography, styled } from "@mui/material"
import { ArrowForwardIos } from "@mui/icons-material"
import pluralize from "../outline/utils/pluralize"
import React, { useState } from "react"
import CourseTile from "../homeNavigation/components/gridLayout/CourseTile"
import CreateCourseTile from "../welcome/CreateCourseTile"
import LearningPathContextMenu from "./LearningPathContextMenu"
import CourseLearningPathMenu from "../welcome/CourseLearningPathMenu"
import DeleteLearningPathDialog from "./dialogs/DeleteLearningPathDialog"
import RenameLearningPathDialog from "./dialogs/RenameLearningPathDialog"
import AddToPathDialog from "./dialogs/AddToPathDialog"
import { ScrollMenu } from "react-horizontal-scrolling-menu"
import { LeftArrow, RightArrow } from "./Arrows"

const ScrollMenuWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  " .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar": {
    display: "none",
  },

  ".react-horizontal-scrolling-menu--scroll-container": {
    scrollbarWidth: "none" /* Firefox */,
  },

  ".react-horizontal-scrolling-menu--arrow-right, .react-horizontal-scrolling-menu--arrow-left":
    {
      position: "absolute",
      top: theme.spacing(17),
      zIndex: theme.zIndex.drawer - 100,
    },

  ".react-horizontal-scrolling-menu--arrow-right": {
    right: 0,
  },

  ".scroll-menu-wrapper, .react-horizontal-scrolling-menu--inner-wrapper, .scroll-menu-container":
    {
      marginTop: `-${theme.spacing(2)}`,
      paddingTop: theme.spacing(2),
      marginBottom: `-${theme.spacing(2)}`,
      paddingBottom: theme.spacing(2),
    },
}))

/**
 *
 * @param props
 */
const LearningPath = (props) => {
  const {
    deletePath,
    renamePath,
    addToPath,
    removeFromPath,
    shiftInPath,
    path,
    error,
  } = props
  const { title, courses, loading } = path
  const [renameDialogVisible, setRenameDialogVisible] = useState(false)
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [addDialogVisible, setAddDialogVisible] = useState(false)

  const [hovered, setHovered] = useState(false)

  /**
   *
   * @param e
   */
  const renameClicked = (e) => {
    e.preventDefault()
    setRenameDialogVisible(true)
  }

  /**
   *
   * @param e
   */
  const deleteClicked = (e) => {
    e.preventDefault()
    setDeleteDialogVisible(true)
  }

  const [anchorEl, setAnchorEl] = useState(null)

  /**
   *
   * @param e
   */
  const addToPathClicked = (e) => {
    e.preventDefault()
    setAddDialogVisible(true)
  }

  return (
    <>
      <Box
        sx={{ opacity: loading ? 0.5 : 1 }}
        width="100%"
        marginBottom={4}
        onMouseOver={() => {
          setHovered(true)
        }}
        onMouseLeave={() => {
          setHovered(false)
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h5">{title}</Typography>
          <Divider sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1 }} />
          <LearningPathContextMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            renameClicked={renameClicked}
            deleteClicked={deleteClicked}
          />
        </Box>
        <Typography variant="body2" gutterBottom>
          {pluralize("course", courses.length, true)}
        </Typography>
        <ScrollMenuWrapper className="wrapper-box">
          <ScrollMenu
            wrapperClassName="scroll-menu-wrapper"
            scrollContainerClassName="scroll-menu-container"
            itemClassName="scroll-menu-item"
            RightArrow={<RightArrow hovered={hovered} />}
            LeftArrow={<LeftArrow hovered={hovered} />}
          >
            {courses.map((course, index) => (
              <Box
                alignItems="center"
                marginBottom={2}
                display="flex"
                key={index}
              >
                <CourseTile
                  item={course}
                  menu={
                    <CourseLearningPathMenu
                      index={index}
                      path={path}
                      course={course}
                      onShift={(direction) =>
                        shiftInPath(path, course, direction)
                      }
                      removeFromPath={removeFromPath}
                    />
                  }
                />
                <ArrowForwardIos
                  fontSize="small"
                  sx={{ marginLeft: 2, marginRight: 2 }}
                />
              </Box>
            ))}
            <Box>
              <CreateCourseTile
                tooltip="Add course to Learning Path"
                onClick={addToPathClicked}
              />
            </Box>
          </ScrollMenu>
        </ScrollMenuWrapper>
      </Box>
      <DeleteLearningPathDialog
        path={path}
        open={deleteDialogVisible}
        onConfirm={(e) => {
          e.preventDefault()
          deletePath(path)
          setAnchorEl(null)
          setDeleteDialogVisible(false)
        }}
        onCancel={(e) => {
          e.preventDefault()
          setAnchorEl(null)
          setDeleteDialogVisible(false)
        }}
      />
      <RenameLearningPathDialog
        path={path}
        open={renameDialogVisible}
        onConfirm={(name) => {
          renamePath(path, name)
          setAnchorEl(null)
          setRenameDialogVisible(false)
        }}
        onCancel={() => {
          setAnchorEl(null)
          setRenameDialogVisible(false)
        }}
      />
      <AddToPathDialog
        path={path}
        open={addDialogVisible}
        onConfirm={(course) => {
          addToPath(path, course)
          setAddDialogVisible(false)
        }}
        onCancel={(e) => {
          e.preventDefault()
          setAnchorEl(null)
          setAddDialogVisible(false)
        }}
      />
    </>
  )
}

export default LearningPath
