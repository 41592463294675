import { MenuItem, Select } from "@mui/material"

/** Options for rewriting the text */
export interface RewriteOptions {
  /** Tone of the content */
  tone: "professional" | "casual" | "fun"
  /** Structure of the content */
  structure: "paragraphs" | "bullets" | "paragraphs_and_bullets" | "same"
  /** Length of the content */
  length: "shorter" | "same" | "longer" | "short" | "medium" | "long" // Last 3 are legacy!
  /** Grade level of the content */
  gradeLevel:
    | "K"
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "10"
    | "11"
    | "12"
    | "13"
    | "14"
    | "15"
    | "16"
    | "17"
  /** Custom instructions */
  customInstructions: string
}

/**
 * Tone of the content
 * @param props - The props
 * @param props.rewriteOptions - The rewrite options
 * @param props.onRewriteOptionsChange - The function to change the rewrite options
 * @param props.menuContainer - The container for the menu
 */
export function RewriteTone(props: {
  rewriteOptions: RewriteOptions
  onRewriteOptionsChange: (options: RewriteOptions) => void
  menuContainer: Element
}) {
  const { rewriteOptions, onRewriteOptionsChange, menuContainer } = props
  return (
    <Select
      label="Tone"
      value={rewriteOptions.tone}
      onChange={(event) => {
        const newTone = event.target.value as any
        onRewriteOptionsChange({
          ...rewriteOptions,
          tone: newTone,
        })
      }}
      MenuProps={{
        container: menuContainer,
      }}
    >
      <MenuItem value={"professional"}>Professional</MenuItem>
      <MenuItem value={"casual"}>Casual</MenuItem>
      <MenuItem value={"fun"}>Fun</MenuItem>
    </Select>
  )
}

/**
 * Rewrite options for the grade level
 * @param props - The props
 * @param props.rewriteOptions - The rewrite options
 * @param props.onRewriteOptionsChange - The function to change the rewrite options
 * @param props.menuContainer - The container for the menu
 */
export function RewriteGradeLevel(props: {
  rewriteOptions: RewriteOptions
  onRewriteOptionsChange: (options: RewriteOptions) => void
  menuContainer: Element
}) {
  const { rewriteOptions, onRewriteOptionsChange, menuContainer } = props
  return (
    <Select
      label="Reading Level"
      value={rewriteOptions.gradeLevel}
      onChange={(event) => {
        onRewriteOptionsChange({
          ...rewriteOptions,
          gradeLevel: event.target.value as any,
        })
      }}
      MenuProps={{
        container: menuContainer,
      }}
    >
      <MenuItem value={"K"}>Kindergarten</MenuItem>
      <MenuItem value={"1"}>1st Grade</MenuItem>
      <MenuItem value={"2"}>2nd Grade</MenuItem>
      <MenuItem value={"3"}>3rd Grade</MenuItem>
      <MenuItem value={"4"}>4th Grade</MenuItem>
      <MenuItem value={"5"}>5th Grade</MenuItem>
      <MenuItem value={"6"}>6th Grade</MenuItem>
      <MenuItem value={"7"}>7th Grade</MenuItem>
      <MenuItem value={"8"}>8th Grade</MenuItem>
      <MenuItem value={"9"}>9th Grade</MenuItem>
      <MenuItem value={"10"}>10th Grade</MenuItem>
      <MenuItem value={"11"}>11th Grade</MenuItem>
      <MenuItem value={"12"}>12th Grade</MenuItem>
      <MenuItem value={"13"}>1st Year University</MenuItem>
      <MenuItem value={"14"}>2nd Year University</MenuItem>
      <MenuItem value={"15"}>3rd Year University</MenuItem>
      <MenuItem value={"16"}>4th Year University</MenuItem>
      <MenuItem value={"17"}>Postgraduate</MenuItem>
    </Select>
  )
}

/**
 * Structure of the content
 * @param props - The props
 * @param props.rewriteOptions - The rewrite options
 * @param props.onRewriteOptionsChange - The function to change the rewrite options
 * @param props.sameEnabled - Whether the "same" option is enabled
 * @param props.menuContainer - The container for the menu
 */
export function RewriteStructure(props: {
  rewriteOptions: RewriteOptions
  onRewriteOptionsChange: (options: RewriteOptions) => void
  sameEnabled: boolean
  menuContainer: Element
}) {
  const { rewriteOptions, onRewriteOptionsChange, sameEnabled, menuContainer } =
    props
  return (
    <Select
      label="Structure"
      value={rewriteOptions.structure}
      onChange={(event) => {
        onRewriteOptionsChange({
          ...rewriteOptions,
          structure: event.target.value as any,
        })
      }}
      MenuProps={{
        container: menuContainer,
      }}
    >
      {sameEnabled && <MenuItem value={"same"}>Same</MenuItem>}
      <MenuItem value={"paragraphs"}>Paragraphs</MenuItem>
      <MenuItem value={"bullets"}>Bullets</MenuItem>
      <MenuItem value={"paragraphs_and_bullets"}>
        Paragraphs and Bullets
      </MenuItem>
    </Select>
  )
}

/**
 * Length of the content
 * @param props - The props
 * @param props.rewriteOptions - The rewrite options
 * @param props.onRewriteOptionsChange - The function to change the rewrite options
 * @param props.menuContainer - The container for the menu
 */
export function RewriteLength(props: {
  rewriteOptions: RewriteOptions
  onRewriteOptionsChange: (options: RewriteOptions) => void
  menuContainer: Element
}) {
  const { rewriteOptions, onRewriteOptionsChange, menuContainer } = props
  return (
    <Select
      label="Length"
      value={rewriteOptions.length}
      onChange={(event) => {
        onRewriteOptionsChange({
          ...rewriteOptions,
          length: event.target.value as any,
        })
      }}
      MenuProps={{
        container: menuContainer,
      }}
    >
      <MenuItem value={"shorter"}>Shorter</MenuItem>
      <MenuItem value={"same"}>Same</MenuItem>
      <MenuItem value={"longer"}>Longer</MenuItem>
    </Select>
  )
}

/**
 * Length of the content
 * @param props - The props
 * @param props.rewriteOptions - The rewrite options
 * @param props.onRewriteOptionsChange - The function to change the rewrite options
 * @param props.menuContainer - The container for the menu
 */
export function LegacyRewriteLength(props: {
  rewriteOptions: RewriteOptions
  onRewriteOptionsChange: (options: RewriteOptions) => void
  menuContainer: Element
}) {
  const { rewriteOptions, onRewriteOptionsChange, menuContainer } = props
  return (
    <Select
      label="Length"
      value={rewriteOptions.length}
      onChange={(event) => {
        onRewriteOptionsChange({
          ...rewriteOptions,
          length: event.target.value as any,
        })
      }}
      MenuProps={{
        container: menuContainer,
      }}
    >
      <MenuItem value={"short"}>Short</MenuItem>
      <MenuItem value={"medium"}>Medium</MenuItem>
      <MenuItem value={"long"}>Long</MenuItem>
    </Select>
  )
}
