/* eslint-disable jsdoc/require-jsdoc */
import { ReactNode } from "react"
import ImageIcon from "@mui/icons-material/Image"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import VideocamIcon from "@mui/icons-material/Videocam"
import List from "@mui/icons-material/List"
import GridOnIcon from "@mui/icons-material/GridOn"
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule"
import FlipIcon from "@mui/icons-material/Flip"
import ImageAspectRatioIcon from "@mui/icons-material/ImageAspectRatio"
import TabIcon from "@mui/icons-material/Tab"
import CallSplitIcon from "@mui/icons-material/CallSplit"
import SlideshowIcon from "@mui/icons-material/Slideshow"
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined"
import InfoIcon from "@mui/icons-material/Info"
import {
  findOwningSectionHeaderElement,
  findTemplateTypesInSection,
} from "../../utilities/smartTemplates"
import { createAddFlipCardGridDialog } from "../../custom-elements/flipCardGridCustomElement"
import { createAddLabeledImageDialog } from "../../custom-elements/labeledImageCustomElement"
import { createAddCalloutBoxDialog } from "../../custom-elements/calloutBoxCustomElement"
import { createInsertVideoDialog } from "../videoInsertion/InsertVideoDialog"
import { createInsertImageDialog } from "../insertImage/InsertImageDialog"
import { createAddTabsDialog } from "../../custom-elements/tabsCustomElement"
import { createAddCategoriesDialog } from "../../custom-elements/categoriesCustomElement"
import { createAddProcessDialog } from "../../custom-elements/processCustomElement"
import { UploadFile, VolumeUpOutlined } from "@mui/icons-material"
import { createAddSourceDocumentDialog } from "../importSourcesToEditor/addSourceDocumentCustomElement"
import { selectAfterText } from "../../utilities/domUtils"
import { createStyledListDialog } from "../../custom-elements/StyledList/styledListCustomElement"
import createIFrame from "../videoInsertion/utils/createIFrame"
import onCreateAudio from "../insertAudio/onCreateAudio"

/** Item to appear in the quick insert menu */
export interface QuickInsertItem {
  /** Title of the item */
  title: string
  /** String ID for storage and retrieval on server */
  id: string
  /** Description of the item */
  description?: string
  /** Keywords to match against for search */
  keywords?: string[]
  /** Icon to display */
  icon?: ReactNode
  /** Called when the item is selected */
  onInsert: (insertContext: QuickInsertContext) => void
  /** Called to determine if the item is available */
  isAvailable?: (insertContext: QuickInsertContext) => boolean
}

/** Context passed to the isAvailable function and onInsert
 * functions of a QuickInsertItem. Information about the insertion point.
 */
export interface QuickInsertContext {
  /** The element that the insertion point is in */
  anchorElement: HTMLElement

  /** The Froala editor */
  editor: any

  /** Inserts a smart template */
  insertSmartTemplate: (template: string, anchorElement: HTMLElement) => void

  /** Displays a React element within the tree
   * @param generator Called with onClose function that should be called
   * when the element closes itself
   */
  displayReactElement: (generator: (onClose: () => void) => ReactNode) => void
}

/** Creates items to appear in the quick insert menu
 * @param options Options
 * @param options.enableTabs True to enable tabs component
 * @param options.enableCategories True to enable categories component
 * @param options.enableProcess True to enable process component
 * @param options.enableTwoColumns True to enable two columns component
 * @param options.enableImport True to enable in-editor import
 * @param options.enableStyledLists True to enable styled lists
 * @param options.enableCalloutBoxes True to enable callout boxes
 * @param options.enableAudioPlayer True to enable audio insert
 * @param options.enableNewImageInsertDialog True to enable images from google drive
 * @returns The items to appear in the quick insert menu
 */
export function createQuickInsertItems(options: {
  enableTabs?: boolean
  enableCategories?: boolean
  enableProcess?: boolean
  enableTwoColumns?: boolean
  enableImport?: boolean
  enableStyledLists?: boolean
  enableCalloutBoxes?: boolean
  enableAudioPlayer?: boolean
  enableNewImageInsertDialog?: boolean
}): QuickInsertItem[] {
  const items: QuickInsertItem[] = [
    {
      title: "Heading 1",
      id: "heading_1",
      description: "Title heading",
      keywords: ["h1", "main"],
      icon: <div style={{ fontSize: 24, fontWeight: "bold" }}>H1</div>,
      onInsert: ({ anchorElement, editor }) => {
        // Insert heading before paragraph
        anchorElement.insertAdjacentHTML("beforebegin", "<h1>Heading 1</h1>")
        selectAfterText(
          anchorElement.previousElementSibling as HTMLElement,
          editor
        )
        anchorElement.remove()
        editor.undo.saveStep()
      },
    },
    {
      title: "Heading 2",
      id: "heading_2",
      description: "Main heading",
      keywords: ["h2"],
      icon: <div style={{ fontSize: 22, fontWeight: "bold" }}>H2</div>,
      onInsert: ({ anchorElement, editor }) => {
        // Insert heading before paragraph
        anchorElement.insertAdjacentHTML("beforebegin", "<h2>Heading 2</h2>")
        selectAfterText(
          anchorElement.previousElementSibling as HTMLElement,
          editor
        )
        anchorElement.remove()
        editor.undo.saveStep()
      },
    },
    {
      title: "Heading 3",
      id: "heading_3",
      description: "Sub heading",
      keywords: ["h3"],
      icon: <div style={{ fontSize: 20, fontWeight: "bold" }}>H3</div>,
      onInsert: ({ anchorElement, editor }) => {
        // Insert heading before paragraph
        anchorElement.insertAdjacentHTML("beforebegin", "<h3>Heading 3</h3>")
        selectAfterText(
          anchorElement.previousElementSibling as HTMLElement,
          editor
        )
        anchorElement.remove()
        editor.undo.saveStep()
      },
    },
    {
      title: "Bullet List",
      id: "bullet_list",
      description: "Bullet list",
      keywords: ["ul", "list"],
      icon: <FormatListBulletedIcon fontSize="inherit" />,
      onInsert: ({ anchorElement, editor }) => {
        // Insert list before paragraph
        anchorElement.insertAdjacentHTML(
          "beforebegin",
          "<ul><li><br/></li></ul>"
        )
        const newElement = anchorElement.previousElementSibling as HTMLElement

        // Remove paragraph
        anchorElement.remove()
        editor.undo.saveStep()

        // Put caret in list
        selectAfterText(newElement, editor)
      },
    },
    {
      title: "Numbered List",
      id: "numbered_list",
      description: "Numbered list",
      keywords: ["ol", "list"],
      icon: <FormatListNumberedIcon fontSize="inherit" />,
      onInsert: ({ anchorElement, editor }) => {
        // Insert list before paragraph
        anchorElement.insertAdjacentHTML(
          "beforebegin",
          "<ol><li><br/></li></ol>"
        )
        const newElement = anchorElement.previousElementSibling as HTMLElement

        // Remove paragraph
        anchorElement.remove()
        editor.undo.saveStep()

        // Put caret in list
        selectAfterText(newElement, editor)
      },
    },
    {
      title: "Image",
      id: "image",
      description: "Insert an image",
      keywords: ["image", "img"],
      icon: <ImageIcon fontSize="inherit" />,
      onInsert: ({ anchorElement, editor, displayReactElement }) => {
        if (options.enableNewImageInsertDialog) {
          anchorElement.innerHTML = "<br/>"
          displayReactElement((onClose) =>
            createInsertImageDialog((url) => {
              onClose()
              if (url) {
                selectAfterText(anchorElement, editor)
                anchorElement.innerHTML = "<br/>"
                editor.image.insert(url, true, { link: url }, null)
                const firstChild = anchorElement.firstChild
                if (
                  firstChild instanceof HTMLElement &&
                  firstChild.tagName === "BR"
                ) {
                  anchorElement.removeChild(firstChild)
                }
              }
            })
          )
        } else {
          // Create a hidden input element and click it to open the file picker
          const imageInput = document.createElement("input")
          imageInput.setAttribute("accept", "image/*")
          imageInput.setAttribute("name", "quickInsertImage")
          imageInput.setAttribute("style", "display: none;")
          imageInput.setAttribute("type", "file")
          document.body.appendChild(imageInput)

          imageInput.addEventListener("change", (event) => {
            const files = (event.target as HTMLInputElement).files
            if (files) {
              anchorElement.innerHTML = "<br/>"
              editor.image.upload(files)
            }
            imageInput.remove()
          })
          imageInput.click()
        }
      },
    },
    {
      title: "Video",
      id: "video",
      description: "Video Clip",
      keywords: ["video", "youtube"],
      icon: <VideocamIcon fontSize="inherit" />,
      onInsert: ({ anchorElement, editor, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          createInsertVideoDialog((url) => {
            onClose()
            if (url) {
              const videoIFrame = createIFrame(url)

              // Select the anchor element and insert the video
              selectAfterText(anchorElement, editor)
              anchorElement.insertAdjacentHTML("beforebegin", videoIFrame)

              // Reselect the text to keep cursor in place
              selectAfterText(anchorElement, editor)
            }
          })
        )
      },
    },
    {
      title: "Table",
      id: "table",
      description: "Table with cells",
      icon: <GridOnIcon fontSize="inherit" />,
      onInsert: ({ anchorElement, editor }) => {
        anchorElement.remove()
        editor.table.insert(2, 2)
      },
    },
    {
      title: "Horizontal Rule",
      id: "horizantal_rule",
      description: "Horizontal line to divide text",
      icon: <HorizontalRuleIcon fontSize="inherit" />,
      onInsert: ({ anchorElement, editor }) => {
        anchorElement.insertAdjacentHTML("beforebegin", "<hr/>")
        anchorElement.innerHTML = "<br/>"
      },
    },
    {
      title: "Learning Objectives",
      id: "learning_objectives",
      description: "Learning objectives section",
      icon: <div style={{ fontSize: 24 }}>L</div>,
      onInsert: ({ anchorElement, insertSmartTemplate }) => {
        anchorElement.innerHTML = "<br/>"
        insertSmartTemplate("learning_objectives", anchorElement)
      },
      isAvailable: ({ anchorElement }) => {
        return isTemplateAvailable("learning_objectives", anchorElement)
      },
    },
    {
      title: "Demonstration",
      id: "demonstration",
      description: "Demonstration section",
      icon: <div style={{ fontSize: 24 }}>D</div>,
      onInsert: ({ anchorElement, insertSmartTemplate }) => {
        anchorElement.innerHTML = "<br/>"
        insertSmartTemplate("demonstration", anchorElement)
      },
      isAvailable: ({ anchorElement }) => {
        return isTemplateAvailable("demonstration", anchorElement)
      },
    },
    {
      title: "Exercise",
      id: "exercise",
      description: "Exercise section",
      icon: <div style={{ fontSize: 24 }}>E</div>,
      onInsert: ({ anchorElement, insertSmartTemplate }) => {
        anchorElement.innerHTML = "<br/>"
        insertSmartTemplate("exercise", anchorElement)
      },
      isAvailable: ({ anchorElement }) => {
        return isTemplateAvailable("exercise", anchorElement)
      },
    },
    {
      title: "Test Questions",
      id: "test_questions",
      description: "Test questions section",
      icon: <div style={{ fontSize: 24, fontWeight: "bold" }}>?</div>,
      onInsert: ({ anchorElement, insertSmartTemplate }) => {
        anchorElement.innerHTML = "<br/>"
        insertSmartTemplate("test_question", anchorElement)
      },
      isAvailable: ({ anchorElement }) => {
        return isTemplateAvailable("test_question", anchorElement)
      },
    },
    {
      title: "Empty Section",
      id: "empty_section",
      description: "Empty section with templates",
      icon: <div style={{ fontSize: 24, fontWeight: "bold" }}>S</div>,
      onInsert: ({ anchorElement, insertSmartTemplate }) => {
        anchorElement.innerHTML = "<br/>"
        insertSmartTemplate("section", anchorElement)
      },
    },
    {
      title: "Flip Cards",
      id: "flip_cards",
      description: "Cards with answers on back",
      icon: <FlipIcon fontSize="inherit" />,
      onInsert: ({ editor, anchorElement, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          createAddFlipCardGridDialog((html) => {
            if (html) {
              // Add a paragraph after the grid so the user can type after it
              anchorElement.innerHTML = "<br/>"
              anchorElement.insertAdjacentHTML("beforebegin", html)
              editor.undo.saveStep()
              selectAfterText(anchorElement, editor)
            }
            onClose()
          })
        )
      },
    },
    {
      title: "Labeled Image",
      id: "labelled_image",
      description: "Image with hotspots and popups",
      icon: <ImageAspectRatioIcon fontSize="inherit" />,
      onInsert: ({ editor, anchorElement, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          createAddLabeledImageDialog((html) => {
            if (html) {
              // Add a paragraph after the image so the user can type after it
              anchorElement.innerHTML = "<br/>"
              anchorElement.insertAdjacentHTML("beforebegin", html)
              editor.undo.saveStep()
              selectAfterText(anchorElement, editor)
            }
            onClose()
          })
        )
      },
    },
  ]

  if (options.enableCalloutBoxes) {
    items.push({
      title: "Callout Box",
      id: "callout_box",
      description: "Highlighted box with icon",
      icon: <InfoIcon fontSize="inherit" />,
      onInsert: ({ editor, anchorElement, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          createAddCalloutBoxDialog((html) => {
            if (html) {
              // Add a paragraph after the image so the user can type after it
              anchorElement.innerHTML = "<br/>"
              anchorElement.insertAdjacentHTML("beforebegin", html)
              editor.undo.saveStep()
              selectAfterText(anchorElement, editor)
            }
            onClose()
          })
        )
      },
    })
  }

  if (options.enableStyledLists) {
    items.push({
      title: "Styled List",
      id: "styled_list",
      description: "Add list with styled bullets",
      keywords: ["styled", "list"],
      icon: <List fontSize="inherit" />,
      onInsert: ({ anchorElement, editor, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          createStyledListDialog((html) => {
            if (html) {
              anchorElement.insertAdjacentHTML("beforebegin", html)
              editor.undo.saveStep()
            }
            onClose()
          })
        )
      },
    })
  }

  if (options.enableImport) {
    items.push({
      title: "Import Source",
      id: "import_source",
      description: "Insert source document at location",
      keywords: ["import", "source"],
      icon: <UploadFile fontSize="inherit" />,
      onInsert: ({ anchorElement, editor, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          createAddSourceDocumentDialog((html) => {
            if (html) {
              anchorElement.insertAdjacentHTML("beforebegin", html)
              editor.undo.saveStep()
            }
            onClose()
          })
        )
      },
    })
  }

  if (options.enableTabs) {
    items.push({
      title: "Tabs",
      id: "tabs",
      description: "Tabs with content",
      icon: <TabIcon fontSize="inherit" />,
      onInsert: ({ editor, anchorElement, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          createAddTabsDialog((html) => {
            if (html) {
              // Add a paragraph after the grid so the user can type after it
              anchorElement.innerHTML = "<br/>"
              anchorElement.insertAdjacentHTML("beforebegin", html)
              editor.undo.saveStep()
              selectAfterText(anchorElement, editor)
            }
            onClose()
          })
        )
      },
    })
  }

  if (options.enableCategories) {
    items.push({
      title: "Categories",
      id: "categories",
      description: "Exercise sorting into categories",
      icon: (
        <CallSplitIcon
          fontSize="inherit"
          style={{ transform: "rotate(180deg)" }}
        />
      ),
      onInsert: ({ editor, anchorElement, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          createAddCategoriesDialog((html) => {
            if (html) {
              // Add a paragraph after the grid so the user can type after it
              anchorElement.innerHTML = "<br/>"
              anchorElement.insertAdjacentHTML("beforebegin", html)
              editor.undo.saveStep()
              selectAfterText(anchorElement, editor)
            }
            onClose()
          })
        )
      },
    })
  }

  if (options.enableProcess) {
    items.push({
      title: "Process",
      id: "process",
      description: "Process interaction",
      icon: <SlideshowIcon fontSize="inherit" />,
      onInsert: ({ editor, anchorElement, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          createAddProcessDialog((html) => {
            if (html) {
              // Add a paragraph after the grid so the user can type after it
              anchorElement.innerHTML = "<br/>"
              anchorElement.insertAdjacentHTML("beforebegin", html)
              editor.undo.saveStep()
              selectAfterText(anchorElement, editor)
            }
            onClose()
          })
        )
      },
    })
  }

  if (options.enableAudioPlayer) {
    items.push({
      title: "Audio",
      id: "audio",
      description: "Audio Clip",
      keywords: ["audio"],
      icon: <VolumeUpOutlined fontSize="inherit" />,
      onInsert: ({ anchorElement, editor, displayReactElement }) => {
        anchorElement.innerHTML = "<br/>"
        displayReactElement((onClose) =>
          onCreateAudio(anchorElement, onClose, editor)
        )
      },
    })
  }

  if (options.enableTwoColumns) {
    items.push({
      title: "Two Columns",
      id: "two_columns",
      description: "Two-column structure",
      icon: <VerticalSplitOutlinedIcon fontSize="inherit" />,
      onInsert: ({ anchorElement, editor }) => {
        // Insert table before paragraph
        anchorElement.insertAdjacentHTML(
          "beforebegin",
          '<table style="width: 100%;" data-component="borderless-table"><tr><td style="width: 50%; vertical-align: top;"><br/></td><td style="width: 50%; vertical-align: top;"><br/></td></tr></table>'
        )
        const newElement = anchorElement.previousElementSibling as HTMLElement

        // Remove paragraph
        anchorElement.remove()
        editor.undo.saveStep()

        // Put caret in first cell
        const firstCell = newElement.querySelector("td")
        if (firstCell) {
          selectAfterText(firstCell, editor)
        }
      },
    })
  }

  return items
}

/** Determine if a template type is available in the section containing the anchor element
 * (i.e. not already present in the section)
 * @param templateType The template type to check for
 * @param anchorElement The anchor element to check
 */
function isTemplateAvailable(templateType: string, anchorElement: HTMLElement) {
  const headerNode = findOwningSectionHeaderElement(
    document.getElementsByClassName("fr-element")[0] as HTMLElement,
    anchorElement
  )
  return (
    headerNode != null &&
    !findTemplateTypesInSection(headerNode).includes(templateType)
  )
}
