import ConfirmDialog from "../../../components/widgets/ConfirmDialog"
import {
  Box,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import React, { useState } from "react"

/**
 *
 * @param props
 */
const CreateFromTemplateDialog = (props) => {
  const { open, onConfirm, pathTemplates, onCancel } = props
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  return (
    <ConfirmDialog
      open={open}
      title="Create Learning Path"
      message={
        <Box>
          <DialogContentText>Choose a Learning Path Template</DialogContentText>
          <FormControl sx={{ marginBottom: 2 }} variant="standard" fullWidth>
            <InputLabel>Learning Path Template</InputLabel>
            <Select
              onChange={(e) => setSelectedTemplate(e.target.value)}
              label="Course"
              value={selectedTemplate ?? ""}
            >
              {pathTemplates.map((path) => (
                <MenuItem key={path.id} value={path}>
                  {path.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      }
      cancelText={"Cancel"}
      confirmText={"Create from Template"}
      onCancel={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onCancel()
        setSelectedTemplate(null)
      }}
      onConfirm={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onConfirm(selectedTemplate)
        setSelectedTemplate(null)
      }}
      onClose={onCancel}
    />
  )
}

export default CreateFromTemplateDialog
