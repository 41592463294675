import {
  Alert,
  CircularProgress,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Typography,
} from "@mui/material"
import ListContainer from "../../itemList/ListContainer"
import PageLayout from "../../layouts/PageLayout"
import { useState } from "react"
import useTranslationRules from "../../../translationRules/hooks/useTranslationRules"
import {
  availableTranslationLocales,
  localeNames,
} from "../../../export/translationLocales"
import { Translate } from "@mui/icons-material"
import { TranslationRulesTable } from "../../../translationRules/TranslationRulesTable"
import MenuControlButton from "../../components/MenuControlButton"
import { Prompt } from "react-router"

/**
 * Page for showing translatino rules
 * @param props - Component props
 * @param props.location - Router location
 */
const TranslationRulesPage = (props: { location: any }) => {
  const { location } = props

  const {
    isLoading,
    hasChanges,
    error,
    setError,
    translationRules,
    handleDataChange,
  } = useTranslationRules()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedLocale, setSelectedLocale] = useState<string>("fr")

  return (
    <PageLayout navRail location={location}>
      <Prompt
        when={hasChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <ListContainer
        title="User-Defined Translations"
        controls={
          <Stack>
            {error && (
              <Alert
                severity="error"
                onClose={() => setError(null)}
                sx={{ mb: 2 }}
              >
                {error}
              </Alert>
            )}
            <Typography variant="bodyMedium" paragraph>
              Here you can define common translations that you would like to
              automatically apply as part of any translated export. This might
              include proper product or business names, or just preferred
              language.
            </Typography>
            <MenuControlButton
              icon={<Translate />}
              onClick={(event: any) => setAnchorEl(event.currentTarget)}
            >
              {localeNames[selectedLocale]}
            </MenuControlButton>
            <Menu
              id="language-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {availableTranslationLocales.map((locale) => (
                <MenuItem
                  key={locale}
                  onClick={() => {
                    setSelectedLocale(locale)
                    setAnchorEl(null)
                  }}
                >
                  {localeNames[locale]}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        }
      >
        <Stack
          width="100%"
          sx={(theme: Theme) => ({
            backgroundColor: theme.scheme.onPrimary,
            overflowX: "hidden",
            overflow: "auto",
            flex: "1 1 0",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          })}
        >
          {isLoading && (
            <Stack flex="1 1 0" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          )}
          {translationRules && (
            <TranslationRulesTable
              data={translationRules}
              onDataChange={handleDataChange}
              selectedLocale={selectedLocale}
            />
          )}
        </Stack>
      </ListContainer>
    </PageLayout>
  )
}

export default TranslationRulesPage
