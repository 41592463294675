import { parseQueryString } from "../utilities/queryString"
import GiveCourseScreen from "../components/screens/GiveCourseScreen"
import { useFlag } from "../utilities/feature-management"

const GiveCoursePage = ({ location }) => {
  const useNewUX = useFlag("rollout-new-home-page-ux")

  const breadcrumbs = location?.state?.breadcrumbs ?? [
    {
      label: "Courses",
      href: "/",
    },
    ...(!useNewUX ? [{ label: "Tools", href: "/tools" }] : []),
  ]

  const { course } = parseQueryString(location.search)

  return <GiveCourseScreen course={course} breadcrumbs={breadcrumbs} />
}

export default GiveCoursePage
