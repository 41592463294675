import { BatchPrediction, Cached } from "@mui/icons-material"
import IconButton from "../../../components/atoms/IconButton"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { fetchUpdates, fetchUpdatesLegacy } from "../api"
import { useFlag } from "../../../utilities/feature-management"

const POLLING_INTERVAL = 10000 // 10 seconds

const loadingIcon = <Cached style={{ fontSize: 12 }} />

type SaveState = "saving" | "saved" | "unsaved" | "offline"

type Course = {
  id: string
  version: string
  title: string
  state: SaveState
}

export type UpdatesButtonProps = {
  course: Course
  useUpdateCount?: (course: Course) => { loading: boolean; count: number }
}

/**
 * Renders the link for accessing intelligent updates.
 * @param props The props.
 */
function UpdatesButtonBase(props: UpdatesButtonProps) {
  const { course, useUpdateCount = useServerlessUpdateCount } = props

  const { count, loading } = useUpdateCount(course)
  const badge = loading ? loadingIcon : count

  return (
    <IconButton
      component={Link}
      to={{
        pathname: "/intelligent-update",
        state: {
          id: course.id,
          title: course.title,
        },
      }}
      title="Intelligent Updates"
      Icon={BatchPrediction}
      badge={badge}
    />
  )
}

/**
 * Renders the link for accessing intelligent updates.
 * @param props The props.
 */
export default function UpdatesButton(props: UpdatesButtonProps) {
  const useUpdateCount = useFlag("rollout-serverless-intelligent-updates")
    ? useServerlessUpdateCount
    : useLegacyCount

  return <UpdatesButtonBase useUpdateCount={useUpdateCount} {...props} />
}

/**
 * Returns the current intelligent updates count.
 *
 * When a new course version is detection, we poll for intelligent updates until
 * the updates version matches.
 *
 * @param course The current course metadata.
 */
function useServerlessUpdateCount(course: Course) {
  const { id: courseId, version: courseVersion } = course
  const [state, setState] = useState<{ count: number; loading: boolean }>({
    count: 0,
    loading: true,
  })

  useEffect(() => {
    setState((state) => ({ ...state, loading: true }))

    let interval: ReturnType<typeof setInterval> | undefined = undefined

    /**
     * Retrieves the current updates state.
     */
    const fetch = async () => {
      const { courseVersion: updatesVersion, updates } = await fetchUpdates(
        courseId
      )
      if (updatesVersion && updatesVersion !== courseVersion) {
        return
      }

      setState({ count: updates.length, loading: false })
      clearInterval(interval)
    }

    interval = setInterval(fetch, POLLING_INTERVAL)
    fetch()

    return () => clearInterval(interval)
  }, [courseId, courseVersion])

  return state
}

/**
 * Returns the current intelligent updates count.
 *
 * Retrieves the latest intellent updates once the async save operations are all
 * complete.
 *
 * @param course The current course metadata.
 */
function useLegacyCount(course: Course) {
  const courseId = course.id
  const isPending = course.state !== "saved"

  const [state, setState] = useState<{ count: number; loading: boolean }>({
    count: 0,
    loading: true,
  })

  useEffect(() => {
    setState((state) => ({ ...state, loading: true }))

    // We only want to fetch new updates when eithe the course has changed or
    // we've transitioned to a saved state.
    if (isPending) {
      return
    }

    fetchUpdatesLegacy(courseId).then((response) => {
      setState({ count: response.updates.length, loading: false })
    })
  }, [courseId, isPending])

  return state
}
