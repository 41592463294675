/**
 * Utilities for working with URL parameters
 * Pure Javascript functions so that we don't have dependencies on entire libraries
 * for simple functions
 */

/**
 * Get the query parameters from a url search string. These are returns as an object.
 * e.g. {course: 'e01f3ef9-2fcf-4492-a0d8-055e1f4708bb'}
 *
 * @param {*} windowLocationSearch - windows.location.search
 * @returns
 */
export const parseQueryString = (windowLocationSearch) => {
  const urlSearchParams = new URLSearchParams(windowLocationSearch)
  const params = Object.fromEntries(urlSearchParams.entries())

  return params
}
