import React from "react"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import TrashedCoursesListItem from "./TrashedCoursesListItem"
import { styled } from "@mui/system"
import { CoursesListSkeleton } from "../homeNavigation/components/listLayout/CoursesList"

const Head = styled(TableHead)(({ theme }) => {
  return {
    fontSize: "1rem",
    backgroundColor: theme.palette.lexWhite,
  }
})

/**
 * TrashedCoursesList function
 * @param props props
 * @param props.filteredDocuments filteredDocuments
 * @param props.onDelete onDelete
 * @param props.onRestore onRestore
 * @param props.isLoading isLoading
 */
const TrashedCoursesList = (props: {
  filteredDocuments: any
  onDelete: any
  onRestore: any
  isLoading: any
}) => {
  const { filteredDocuments, onDelete, onRestore, isLoading } = props

  return (
    <TableContainer sx={{ overflow: "visible" }} component={Paper}>
      <Table aria-label="Courses Table">
        <Head>
          <TableRow>
            <TableCell>Course</TableCell>
            <TableCell>Trashed</TableCell>
          </TableRow>
        </Head>
        <TableBody>
          {!isLoading
            ? filteredDocuments.map(
                (document: { id: React.Key | null | undefined }) => (
                  <TrashedCoursesListItem
                    key={document.id}
                    document={document}
                    onDelete={onDelete}
                    onRestore={onRestore}
                  />
                )
              )
            : [...Array(6)].map((i, index) => (
                <CoursesListSkeleton key={index} />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TrashedCoursesList
