import React from "react"
import { Box, Typography } from "@mui/material"
import { ErrorOutline } from "@mui/icons-material"
import ErrorIcon from "../../assets/images/error_person1.svg"

const ErrorMessage = ({ title, subtitle, children }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box>
        <img style={{ width: "7.5rem" }} src={ErrorIcon} alt="Error" />
      </Box>
      <Box marginLeft={2}>
        <Box display="flex" alignItems="center" marginBottom={2}>
          <ErrorOutline fontSize="large" />
          <Box marginLeft={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
        </Box>
        <Typography gutterBottom variant="body1">
          {subtitle}
        </Typography>
        {children}
      </Box>
    </Box>
  )
}

export default ErrorMessage
