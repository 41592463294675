import axiosInstance from "../../../api/axios"

/**
 * Send a request with a list of item IDs to restore (remove from trash)
 * @param items - IDs of items to restore
 */
const restoreItems = (items: string[]) =>
  axiosInstance
    .post("/courses/restore/", { items })
    .then((response) => response.data)

export default restoreItems
