import { useEffect, useState } from "react"
import debounce from "lodash/fp/debounce"
import PageLayout from "../layouts/PageLayout"
import CoursesError from "../widgets/CoursesError"
import { CourseCancelUploadDialog } from "../widgets/CourseCancelUploadControl"
import { Box } from "@mui/material"
import WelcomeDisplay from "../../features/welcome/WelcomeDisplay"
import CoursesDisplay from "../../features/welcome/CoursesDisplay"
import LearningPathsDisplay from "../../features/learningPaths/LearningPathsDisplay"
import CoursesSearchBar from "../../features/welcome/CoursesSearchBar"
import AddCourseImageDialog from "../../features/welcome/AddCourseImageDialog"
import { map, without, union } from "lodash/fp"
import useDirectoryItems from "../../features/homeNavigation/hooks/useDirectoryItems"
import useCourseActions from "../../features/homeNavigation/hooks/useCourseActions"
import ConfirmTrashDialog from "../../features/homeNavigation/components/ConfirmTrashDialog"
import MoveItemDialog from "../../features/homeNavigation/components/MoveItemDialog"
import FolderTitleDialog from "../../features/homeNavigation/components/FolderTitleDialog"
import { DndContext } from "@dnd-kit/core"
import useWelcomeDisplay from "../../features/welcome/hooks/useWelcomeDisplay"
import useDragDropItems from "../../features/homeNavigation/hooks/useDragDropItems"
import useCourseImportApi from "../../features/createCourse/hooks/useCourseImportApi"
const SEARCH_DEBOUNCE_TIME = 500

/**
 * Component for display all courses belonging to a user
 * @param props - Component props
 */
const CoursesScreen = (props) => {
  const [cancelCreateDocument, setCancelCreateDocument] = useState(null)
  const [confirmingTrash, setConfirmingTrash] = useState(null) // null or array of course objects
  const [imageUploadCourse, setImageUploadCourse] = useState(null)
  const [movingCourses, setMovingCourses] = useState(null)
  const [creatingFolder, setCreatingFolder] = useState(false)
  const [renamingFolder, setRenamingFolder] = useState(null)

  const { cancelImport } = useCourseImportApi()

  const {
    refreshItems,
    items,
    error,
    handleMoveItems,
    directory,
    setCheckedItems,
    handleCreateDirectory,
    handleRenameDirectory,
    query,
    setQuery,
    ...otherItemProps
  } = useDirectoryItems()

  const { duplicateCourse, moveCoursesToTrash, saveCustomCourseImage } =
    useCourseActions(refreshItems)

  const { refreshLastUpdatedCourse, ...welcomeDisplayProps } =
    useWelcomeDisplay()

  const { sensors, isDragging, onDragStart, onDragEnd } =
    useDragDropItems(handleMoveItems)

  useEffect(() => {
    // close the cancel dialog if it is open for a failed or finished upload
    if (cancelCreateDocument) {
      const doc = items.find((doc) => doc.id === cancelCreateDocument.id)
      if (doc == null || !doc.isLoading) {
        setCancelCreateDocument(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  /**
   * Display confirmation dialog for cancelling an in-progress upload
   * @param document - Course in the middle of uploading to be cancelled
   */
  const onCancelUpload = async (document) => {
    setCancelCreateDocument(document)
  }

  /**
   * Hide dialog and call cancelCourseCreation function in hook
   * @param document - Course to be cancelled
   */
  const onConfirmCancelUpload = async (document) => {
    setCancelCreateDocument(null)
    if (document != null) {
      await cancelImport(document.id)
    }
  }

  /**
   * Display confirmation dialog for moving course to trash
   * @param ids - List of IDs of courses to trash
   */
  const onTrash = (ids) => {
    setConfirmingTrash(
      items.filter((item) => {
        return ids.includes(item.id)
      })
    )
  }

  /**
   * Display Edit Thumbnail dialog
   * @param course - Course to upload thumbnail image for
   */
  const onUploadImage = (course) => {
    setImageUploadCourse(course)
  }

  /**
   * Perform functionality to save uploaded image as course thumbnail
   * @param course - Course to add thumbnail image for
   * @param image - Image file to be saved to course model
   */
  const onSaveCourseImage = (course, image) => {
    saveCustomCourseImage(course.id, image).then(() => {
      setImageUploadCourse(null)
    })
  }

  const onSearchInputChange = debounce(SEARCH_DEBOUNCE_TIME, (query) => {
    setQuery(query)
  })

  const hasError = error != null
  return (
    <>
      <PageLayout fixed maxWidth="xl">
        <WelcomeDisplay {...welcomeDisplayProps} />
        <Box marginTop={6} marginBottom={6} display="flex">
          <CoursesSearchBar
            query={query}
            displaySearchResults={otherItemProps.displaySearchResults}
            onSearchInputChange={onSearchInputChange}
            onClearSearch={() => setQuery("")}
          />
        </Box>
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        >
          <CoursesDisplay
            onCancelUpload={onCancelUpload}
            onTrash={onTrash}
            onMove={(ids) =>
              setMovingCourses(
                items.filter((course) => ids.includes(course.id))
              )
            }
            onDuplicate={duplicateCourse}
            onUploadImage={onUploadImage}
            onCreateFolder={() => setCreatingFolder(true)}
            onRenameFolder={(item) => setRenamingFolder(item)}
            courses={items}
            hasError={hasError}
            query={query}
            directory={directory}
            isDragging={isDragging}
            setCheckedItems={setCheckedItems}
            handleCourseChecked={(event, id) =>
              setCheckedItems((prev) =>
                event.target.checked ? union(prev, [id]) : without([id], prev)
              )
            }
            {...otherItemProps}
          />
        </DndContext>

        <LearningPathsDisplay query={query} />
        {hasError && <CoursesError error={error} />}
      </PageLayout>
      {cancelCreateDocument && (
        <CourseCancelUploadDialog
          document={cancelCreateDocument}
          onConfirmCancelUpload={onConfirmCancelUpload}
        />
      )}
      {confirmingTrash && (
        <ConfirmTrashDialog
          itemsToTrash={confirmingTrash}
          setConfirmingTrash={setConfirmingTrash}
          onTrashConfirmed={async (courses) => {
            const ids = map("id", courses)
            await moveCoursesToTrash(ids)
            setCheckedItems((prev) => without(ids, prev))

            // Deleting a course could end up what is the most recently updated available, so refresh
            refreshLastUpdatedCourse()
          }}
        />
      )}
      {imageUploadCourse && (
        <AddCourseImageDialog
          course={imageUploadCourse}
          open={Boolean(imageUploadCourse)}
          onClose={(e) => setImageUploadCourse(null)}
          saveCustomCourseImage={onSaveCourseImage}
        />
      )}
      {movingCourses && (
        <MoveItemDialog
          itemsToMove={movingCourses}
          onConfirm={(items, destination) => {
            handleMoveItems(items, destination)
            setMovingCourses(null)
            setCheckedItems([])
          }}
          currentDirectory={directory}
          onCancel={() => setMovingCourses(null)}
        />
      )}
      {creatingFolder && (
        <FolderTitleDialog
          onConfirm={(title) => {
            handleCreateDirectory(title)
            setCreatingFolder(false)
          }}
          onCancel={() => setCreatingFolder(false)}
          titleText="Create Folder"
          contentText="Enter a name for the new folder"
          confirmText="Create"
        />
      )}
      {renamingFolder && (
        <FolderTitleDialog
          onConfirm={(newTitle) => {
            handleRenameDirectory(renamingFolder.id, newTitle)
            setRenamingFolder(null)
          }}
          onCancel={() => setRenamingFolder(null)}
          titleText="Rename Folder"
          contentText="Enter a new name for the folder"
          confirmText="Rename"
          initialText={renamingFolder.title}
        />
      )}
    </>
  )
}

export default CoursesScreen
