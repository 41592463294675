import { Box, Typography } from "@mui/material"
import { isoStringToRelativeTime } from "../../../utilities/transformers"

const CommentReply = ({ reply }) => {
  const {
    createdBy: { email },
    text,
    createdOn,
  } = reply

  return (
    <Box paddingTop={2}>
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "default",
        }}
        align="right"
        variant="subtitle1"
      >
        {email}
      </Typography>
      <Typography align="right" gutterBottom variant="body2">
        Added {isoStringToRelativeTime(createdOn)}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Typography gutterBottom align="left" variant="body2">
          "{text}"
        </Typography>
      </Box>
    </Box>
  )
}
export default CommentReply
