import React, { useContext, useEffect, useState } from "react"
import { VisibilityContext } from "react-horizontal-scrolling-menu"

/**
 * Hook to get visibility and direction of horizontal scroll menu.
 * @param direction
 */
const useScrollMenu = (direction) => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete,
    isLastItemVisible,
    scrollNext,
  } = useContext(VisibilityContext)

  const [active, setActive] = useState(
    direction === "right"
      ? visibleElements.length || !isLastItemVisible
      : initComplete && !isFirstItemVisible
  )

  useEffect(() => {
    if (visibleElements.length) {
      if (direction === "right") {
        setActive(!isLastItemVisible)
      } else {
        setActive(!isFirstItemVisible)
      }
    }
  }, [direction, isFirstItemVisible, isLastItemVisible, visibleElements])

  return { active, scrollPrev, scrollNext }
}

export default useScrollMenu
