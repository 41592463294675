import { Box } from "@mui/system"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material"
import {
  ArrowBack,
  ArrowForward,
  DeleteForever,
  DriveFileRenameOutline,
  ExpandSharp,
  MoreHoriz,
  UnfoldLess,
} from "@mui/icons-material"
import React, { useEffect, useState } from "react"
import useHeaderOperationsAccess from "./hooks/useHeaderOperationsAccess"
import DeleteIcon from "../../assets/images/confirm-delete.svg"
import { useActiveSection } from "./context/ActiveSectionProvider"
import {
  HEADERS_COLLAPSE_ALL,
  HEADERS_EXPAND_ALL,
} from "./hooks/useOutlineCollapse"
import useHeaderOperations from "./hooks/useHeaderOperations"
import { useSelector } from "react-redux"
import { selectCurrentSectionHeader } from "../../store/editor/selectors"

const outline_template_operations = [
  {
    label: "Add Learning Objectives",
    templateType: "learning_objectives",
  },
  {
    label: "Add Section",
    templateType: "section",
  },
  {
    label: "Add Demonstration",
    templateType: "demonstration",
  },
  {
    label: "Add Exercise",
    templateType: "exercise",
  },
  {
    label: "Add Test Questions",
    templateType: "test_question",
  },
]

/**
 * remove any html markup from the given string. will also replace &nbsp; with " "
 * TODO: come up with a system level approach for headings with images and other markup in them
 * @param {string} s the string to clean e.g. (<img src="a.png" width="37px" class="fr-draggable"> <em>Words</em>)
 *
 * @returns a cleaned string e.g. 'Words'
 */
const stripHTMLTags = (s) => {
  // return s.replace(/(<([^>]+)>)/gi, "").replaceAll("&nbsp;", " ")
  let doc = new DOMParser().parseFromString(s, "text/html")
  let cleanString = doc.body.textContent
    .replaceAll("&nbsp;", " ")
    .replace(/\s+/g, " ")
  //console.log(`"${s}" ==> "${cleanString}"`)
  return cleanString || ""
}

/**
 * take an html string and try to swap the text in it (e.g. after a rename)
 * will handle simple html markup (e.g. with images or simple elements)
 * will not attempt to transform strings where text crosses html elements
 * @param {*} html the html with the text to change
 * @param {*} text the original text to look for
 * @param {*} replacement the new text to replace the original text
 * @returns the transformed string if the transform succeeded, otherwise returns the original string
 */
function performTextTransformation(html, text, replacement) {
  let node = new DOMParser().parseFromString(html, "text/html").body
  let cleanHTML = node.innerHTML.replaceAll("&nbsp;", " ").replace(/\s+/g, " ")
  let newHtml = cleanHTML.replace(text, replacement)
  if (newHtml === cleanHTML) {
    console.log(
      "Warning: Could not replace text due to complex html in content."
    )
    return html
  } else {
    return newHtml
  }
}

const OutlineActions = (props) => {
  const {
    readOnly,
    handleAddTemplate,
    handleCollapse,
    hasCollapseAll,
    hasExpandAll,
    editor,
    updateDocument,
  } = props

  const currentSectionHeader = useSelector(selectCurrentSectionHeader)

  const activeSection = useActiveSection()
  const container = editor?.$el?.[0]

  const { addHeading, deleteSection, demote, promote, renameHeading } =
    useHeaderOperations(container, updateDocument)

  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = Boolean(anchorEl)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const [headingNameRequest, setHeadingNameRequest] = useState(null)

  const [headingName, setHeadingName] = useState("")
  //delete dialog properties
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("")

  const {
    canPromote,
    canDemote,
    canInsert,
    canInsertSubHeading,
    canDelete,
    canRename,
    canHaveTemplate,
    hasTemplates,
  } = useHeaderOperationsAccess(activeSection)

  const insertHeading = (name) => {
    addHeading(activeSection, name, headingNameRequest)
    setHeadingNameRequest(null)
    setHeadingName("")
  }

  const onRename = (name) => {
    renameHeading(activeSection, name)
    setHeadingNameRequest(null)
    setHeadingName("")
  }

  return (
    <>
      <Box flexGrow={1} />
      <Tooltip title="Outline Actions">
        <IconButton
          onClick={handleClick}
          data-cy="outline-actions"
          edge="start"
        >
          <MoreHoriz />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="outline-menu"
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            handleCollapse(HEADERS_EXPAND_ALL)
          }}
          disabled={!hasExpandAll}
        >
          <ListItemIcon>
            <ExpandSharp fontSize="small" />
          </ListItemIcon>
          Expand All
        </MenuItem>

        <MenuItem
          disabled={!hasCollapseAll}
          onClick={(e) => {
            e.preventDefault()
            handleCollapse(HEADERS_COLLAPSE_ALL)
          }}
        >
          <ListItemIcon>
            <UnfoldLess fontSize="small" />
          </ListItemIcon>
          Collapse All
        </MenuItem>
        {!readOnly && <Divider />}
        {!readOnly && [
          <MenuItem
            key="promote"
            onClick={() => promote(activeSection)}
            disabled={!canPromote}
          >
            <ListItemIcon>
              <ArrowBack fontSize="small" />
            </ListItemIcon>
            Promote
          </MenuItem>,
          <MenuItem
            key="demote"
            onClick={() => demote(activeSection)}
            disabled={!canDemote}
          >
            <ListItemIcon>
              <ArrowForward fontSize="small" />
            </ListItemIcon>
            Demote
          </MenuItem>,
          <MenuItem
            key="heading-before"
            disabled={!canInsert}
            onClick={() => setHeadingNameRequest("before")}
          >
            New Heading Before
          </MenuItem>,
          <MenuItem
            key="heading-after"
            disabled={!canInsert}
            onClick={() => setHeadingNameRequest("after")}
          >
            New Heading After
          </MenuItem>,
          <MenuItem
            key="new-subheading"
            disabled={!canInsertSubHeading}
            onClick={() => setHeadingNameRequest("sub")}
          >
            New Subheading
          </MenuItem>,
        ]}
        {!readOnly && <Divider />}
        {!readOnly &&
          outline_template_operations.map((item, i) => (
            <MenuItem
              key={`template-menu-${i}`}
              onClick={() => handleAddTemplate(item.templateType)}
              disabled={
                !canHaveTemplate || hasTemplates.includes(item.templateType)
              }
              data-cy={item.templateType}
            >
              {item.label}
            </MenuItem>
          ))}

        {!readOnly && <Divider />}
        {!readOnly && [
          <MenuItem
            key="heading-delete"
            disabled={!canDelete}
            onClick={() => {
              setDeleteDialogMessage(
                `Delete the selected section "${stripHTMLTags(
                  currentSectionHeader
                )}"? 
                        You will remove the heading and ALL contents in the section.`
              )
              setIsShowDeleteDialog(true)
            }}
          >
            <ListItemIcon>
              <DeleteForever fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>,
          <MenuItem
            key="heading-rename"
            disabled={!canRename}
            onClick={() => {
              setHeadingName(currentSectionHeader)
              setHeadingNameRequest("rename")
            }}
          >
            <ListItemIcon>
              <DriveFileRenameOutline fontSize="small" />
            </ListItemIcon>
            Rename
          </MenuItem>,
        ]}
      </Menu>
      <HeadingNameDialog
        onCancel={() => {
          setHeadingName("")
          setHeadingNameRequest(null)
        }}
        headingName={headingName}
        //specify the action to perform
        onConfirm={headingNameRequest === "rename" ? onRename : insertHeading}
        headingNameRequest={headingNameRequest}
      />
      <ConfirmDeleteDialog
        showDeleteDialog={isShowDeleteDialog}
        dialogTitle="Confirm Delete Section"
        dialogMessage={deleteDialogMessage}
        onConfirm={() => {
          deleteSection(activeSection)
          setIsShowDeleteDialog(false)
        }}
        onCancel={() => {
          setIsShowDeleteDialog(false)
        }}
      />
    </>
  )
}

const ConfirmDeleteDialog = (props) => {
  const { showDeleteDialog, onCancel, onConfirm, dialogTitle, dialogMessage } =
    props

  return (
    <Dialog open={showDeleteDialog} maxWidth="sm" fullWidth>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box>
          <img
            style={{ width: "12rem" }}
            src={DeleteIcon}
            alt="Delete Section"
          />
        </Box>
      </Box>

      <DialogContent>
        <DialogContentText>{dialogMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={() => onConfirm()}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const HeadingNameDialog = (props) => {
  const { onCancel, onConfirm, headingNameRequest, headingName } = props

  const [originalHeadingName, setOriginalHeadingName] = useState()
  const [newHeadingName, setNewHeadingName] = useState()
  useEffect(() => {
    setOriginalHeadingName(stripHTMLTags(headingName))
    setNewHeadingName(stripHTMLTags(headingName))
  }, [headingNameRequest, headingName])

  return (
    <Dialog open={headingNameRequest != null} maxWidth="sm" fullWidth>
      <DialogTitle>
        {headingNameRequest === "rename"
          ? "Rename Heading"
          : "Create New Heading"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Enter a name for the heading</DialogContentText>
        <TextField
          margin="dense"
          fullWidth
          variant="standard"
          value={newHeadingName}
          onInput={(e) => {
            e.preventDefault()
            setNewHeadingName(e.target.value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!newHeadingName || newHeadingName.length === 0}
          onClick={() => {
            let headingNameUpdate = performTextTransformation(
              headingName,
              originalHeadingName,
              newHeadingName
            )
            if (headingNameUpdate !== headingName) {
              onConfirm(headingNameUpdate)
            } else {
              if (headingName !== "") {
                console.log(
                  "Warning: Could not update the text. Try changing the heading in the editor."
                )
              }
              onCancel()
            }
          }}
        >
          {headingNameRequest === "rename" ? "Rename" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OutlineActions
