import { ItemsState } from "../types"
import { keys } from "lodash"

/**
 * Create a mapping between folder IDs and their titles
 * @param itemsState - Current stored items state
 */
const createFolderTitleMap = (itemsState: ItemsState) => {
  const map: { [key: string]: string } = {}

  keys(itemsState.entities).forEach((id) => {
    const item = itemsState.entities[id]
    if (item.item_type === "folder") {
      map[id] = item.title
    }
  })
  return map
}

export default createFolderTitleMap
