import { Visibility, VisibilityOff } from "@mui/icons-material"
import {
  FilledInput,
  FilledInputProps,
  FormControl,
  FormControlProps,
  FormHelperText,
  IconButton,
  IconButtonProps,
  InputAdornment,
  InputLabel,
} from "@mui/material"
import useId from "@mui/material/utils/useId"
import { motion } from "framer-motion"
import { useCallback, useState } from "react"
import useValidationError from "../hooks/useValidationError"

type PasswordFieldProps = {
  label?: string
  inputProps?: FilledInputProps
} & FormControlProps<typeof motion.div>

/**
 * Renders an field for handling password inputs.
 * @param props The component props.
 */
export function PasswordField(props: PasswordFieldProps) {
  const { label = "Password", inputProps = {}, ...otherProps } = props
  const { name = "password", ...otherInputProps } = inputProps

  const id = useId()
  const helperId = `${id}-helper`

  const [showPassword, toggleShowPassword] = useShowPassword()
  const error = useValidationError(name)

  return (
    <FormControl
      component={motion.div}
      layout="position"
      variant="filled"
      {...otherProps}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <FilledInput
        id={id}
        name={name}
        type={showPassword ? "text" : "password"}
        autoComplete="current-password"
        endAdornment={
          <InputAdornment position="end">
            <RevealPasswordButton
              onClick={toggleShowPassword}
              active={showPassword}
              edge="end"
            />
          </InputAdornment>
        }
        {...otherInputProps}
      />
      {error && <FormHelperText id={helperId}>{error}</FormHelperText>}
    </FormControl>
  )
}

/**
 * Creates a state for managing the password visibility.
 */
function useShowPassword() {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = useCallback(
    () => setShowPassword((show) => !show),
    []
  )

  return [showPassword, toggleShowPassword] as [boolean, () => void]
}

type RevealPasswordButtonProps = {
  /** Indicates the current password visibility. */
  active: boolean
} & IconButtonProps

/**
 * Renders a button for toggling password visibility.
 * @param props The component props.
 */
function RevealPasswordButton(props: RevealPasswordButtonProps) {
  const { active, ...otherProps } = props
  const Icon = active ? VisibilityOff : Visibility

  return (
    <IconButton
      aria-label="toggle password visibility"
      onMouseDown={(e) => e.preventDefault()}
      {...otherProps}
    >
      <Icon />
    </IconButton>
  )
}
