import { ItemsState, FetchItemsData, Item } from "../types"
import { omit } from "lodash"

/**
 * Hydrate the Items State with data obtained from the list courses response.
 * @param state - Current Items State
 * @param data - Data from list courses response
 * @param folder - Current folder we are in
 */
const normalizeItems = (
  state: ItemsState,
  data: FetchItemsData,
  folder: string
) => {
  const { items, folder: folderTitle } = data

  /**
   * We can obtain the title of the current directory if it is not known from
   * the state via the folderTitle property obtained from the response data.
   *
   * By storing this stubbed temporary entity, we can use a selector to get
   * the current folder title from the state.
   */
  const tempCurrentDirectoryEntity: Item = {
    title: folderTitle ?? "",
    id: folder,
    tenant_id: "",
    status: null,
    parent_directory: null,
    last_update: null,
    item_type: null,
    is_template: false,
  }

  // Remove the temporary entity with "__TEMP_ID__" from the state
  const updatedEntities = omit(state.entities, "__TEMP_ID__")

  return {
    ids: {
      ...state.ids,
      [folder ?? "__ALL__"]: items.map((item: any) => item.id),
    },
    entities: {
      ...(folderTitle ? { [folder]: tempCurrentDirectoryEntity } : {}),
      ...updatedEntities,
      ...items.reduce((acc: { [id: string]: Item }, item: any) => {
        acc[item.id] = item
        return acc
      }, {}),
    },
  }
}

export default normalizeItems
