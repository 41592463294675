import { createContext, useContext, useMemo } from "react"
import useProvideAuth from "../hooks/useProvideAuth"

export const AuthorizationContext = createContext({})

export const AuthorizationProvider = ({ children }) => {
  const auth = useProvideAuth()

  const authValue = useMemo(() => auth, [auth])
  return (
    <AuthorizationContext.Provider value={authValue}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export const useAuth = () => useContext(AuthorizationContext)
