import { isArray } from "lodash"
import { Comment } from "../remarks"

/**
 * Normalize the structure of a comment or set of comments returned from the server.
 */
function normalizeComment(data: Comment[]): Comment[]
function normalizeComment(data: Comment): Comment
function normalizeComment(data: Comment | Comment[]): Comment | Comment[] {
  if (isArray(data)) {
    return data.map(normalizeComment)
  }

  return {
    ...data,
    remarkType: "comment",
  }
}

export default normalizeComment
