import { add, isArray, memoize } from "lodash"
import { Duration } from "luxon"
import * as api from "../../../api"

/**
 * Timeout to load image in browser
 *
 * @type {number}
 */
const IMAGE_LOAD_TIMEOUT = 1000

/**
 * The number of images an average reader can view per minute.
 */
const AVERAGE_IMAGE_VIEWS_PER_MINUTE = 4

/**
 * Minimum square pixel size for an image to be deemed "Large"
 *
 * [LD-1300: It's possible this may need to be configured in the future based on
 * testing.]
 */
const MINIMUM_IMAGE_SIZE = 60000 // Square pixels

/**
 * The number of words an average reader can read per minute.
 */
const AVERAGE_WORDS_PER_MINUTE = 100

/**
 * The number of questions an average reader can read per minute.
 */
const AVERAGE_QUESTIONS_PER_MINUTE = 1

/**
 * Estimate the time required for someone to review a content set.
 *
 * @param {{ wordCount?: number, images?: Element[], videos?: Element[] }} content
 * @returns {Promise<Duration>}
 */
const estimateContentDuration = async (content) => {
  const {
    wordCount = 0,
    images = [],
    videos = [],
    questionCount = 0,
    questionWordCount = 0,
  } = content

  return Duration.fromObject({
    minutes:
      estimateReadingDuration(wordCount - questionWordCount) +
      estimateQuestionDuration(questionCount) +
      (await estimateImageDuration(images)) +
      (await estimateVideoDuration(videos)),
  })
}

export default estimateContentDuration

/**
 * Estimate the time required for someone to review a set of images.
 *
 * @param {Element[]} images
 * @returns {number} Estimated time in minutes.
 */
const estimateImageDuration = async (images) => {
  /**
   * Since we need to examine the dimensions of the images to determine if
   * they are large enough to count towards the time estimate, we need to first
   * wait until the browser has finished loading them.
   */
  const loadedImages = await retrieveLoadedImages(images)
  const largeImageCount = loadedImages.filter(
    (image) => image && image.width * image.height > MINIMUM_IMAGE_SIZE
  ).length
  return largeImageCount / AVERAGE_IMAGE_VIEWS_PER_MINUTE
}

/**
 * Return a copy of the images once they have have finished being loaded by
 * the browser.
 *
 * @param images
 * @returns {Promise<Awaited<unknown>[]>}
 */
const retrieveLoadedImages = async (images) => {
  return Promise.all(
    Array.from(images).map((img) => {
      if (img.complete) {
        return Promise.resolve(img)
      }
      return new Promise((resolve) => {
        img.addEventListener("load", () => resolve(img))
        img.addEventListener("error", () => resolve(false))

        setTimeout(resolve, IMAGE_LOAD_TIMEOUT, false)
      })
    })
  )
}

/**
 * Estimate the time required for someone to review some text.
 *
 * @param {number} wordCount
 * @returns {number} Estimated time in minutes.
 */
const estimateReadingDuration = (wordCount) =>
  wordCount / AVERAGE_WORDS_PER_MINUTE

/**
 * Estimate the time required for someone to review a set of videos.
 *
 * @param {Element|Element[]} videos
 * @returns {Promise<number>}
 */
const estimateVideoDuration = async (videos) => {
  if (isArray(videos)) {
    return Promise.all(videos.map(estimateVideoDuration)).then((durations) => {
      return durations.reduce(add, 0)
    })
  }

  return fetchVideoDuration(videos.src)
}
/**
 * Estimate the time required for someone to review a test question.
 *
 * @param questionCount
 * @returns {number}
 */
const estimateQuestionDuration = (questionCount) =>
  questionCount / AVERAGE_QUESTIONS_PER_MINUTE

/**
 * Fetches the duration (in minutes) for a given video source.
 *
 * If the video source is undefined or the video duration can not be queried, a
 * duration of zero is returned.
 *
 * The durations for each videos source are cached.
 *
 * @type {(url: str) => Promise<number>}
 */
const fetchVideoDuration = memoize(async (url) => {
  if (!url) {
    return 0
  }

  try {
    const { data: duration } = await api.getVideoDuration(url)
    return duration / 60
  } catch {
    return 0
  }
})
