import axiosInstance, { gateway } from "../../../api/axios"
import createPromiseQueue from "../../../utilities/createPromiseQueue"

const DEFAULT_QUEUE = createPromiseQueue()

type ApplyUpdatesArgs = {
  courseId: string
  updateIds: string[]
  targetCourseIds: string[]
}

/**
 * Apply the given intelligent updates.
 * @param args The application arguments.
 */
export default async function applyUpdates(args: ApplyUpdatesArgs) {
  await DEFAULT_QUEUE.add(async () =>
    gateway.post("/intelligent-updates/", {
      course_id: args.courseId,
      target_course_ids: args.targetCourseIds,
      update_ids: args.updateIds,
    })
  )
}

/**
 * Apply the given intelligent updates.
 * @param args The application arguments.
 */
export async function legacyApplyUpdates(args: ApplyUpdatesArgs) {
  const { courseId, updateIds, targetCourseIds } = args

  await DEFAULT_QUEUE.add(() =>
    axiosInstance.post("/intelligent_updates", {
      course: courseId,
      target_courses: targetCourseIds,
      updates: updateIds,
    })
  )
}
