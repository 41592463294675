import {
  Box,
  IconButton,
  Popover,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { Circle, Close } from "@mui/icons-material"
import { TwitterPicker } from "react-color"
import { useState } from "react"

/**
 * Configurable list of sensible default colours. Currently set to react-color
 * default for Twitter colour picker.
 */
const presetColours = [
  "#b46c4a",
  "#eabe6a",
  "#70ba9b",
  "#367452",
  "#81badf",
  "#3f6c93",
  "#697680",
  "#a95f65",
  "#e896a8",
  "#78588f",
]

/**
 * Component for choosing colour
 * @param props - Component props
 */
const ColourSelectionControl = (props: any) => {
  const { branding, onPropertyChange } = props

  const theme = useTheme()

  const defaultColour = theme.scheme.primary

  const colour = branding["colour"] ?? defaultColour
  const isDefault = colour === defaultColour
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        sx={(theme: Theme) => ({
          backgroundColor: theme.scheme.surfaceContainerHigh,
          borderRadius: "24px",
          padding: "4px",
        })}
      >
        <Tooltip arrow title="Select Branding Colour">
          <IconButton
            onClick={(event) =>
              setAnchorEl(anchorEl ? null : event.currentTarget)
            }
          >
            <Circle sx={{ color: colour, fontSize: 50 }} />
          </IconButton>
        </Tooltip>
        <Typography fontWeight="bold" variant="body1">
          Colour
          {isDefault && <> [Default]</>}
        </Typography>
        {!isDefault && (
          <Box marginLeft="auto">
            <Tooltip arrow title="Restore Default Colour">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  onPropertyChange("colour", null)
                }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <TwitterPicker
          color={colour}
          triangle="hide"
          colors={presetColours}
          onChangeComplete={(color) => {
            onPropertyChange("colour", color.hex)
          }}
        />
      </Popover>
    </>
  )
}

export default ColourSelectionControl
