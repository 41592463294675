import React, { useContext } from "react"
import { createContext } from "react"
import defaultFeaturesConfig from "../config/features.js"
import createFeatureDecisions from "../utilities/features/createFeatureDecisions"
import createFeatureRouter from "../utilities/features/createFeatureRouter"

/**
 * The context for features and associated decisions in the application.
 *
 * @type {React.Context<{ featureDecisions: ReturnType<typeof createFeatureDecisions> }>}
 */
export const FeaturesContext = createContext()

/**
 * Provides a set of features and associated decision in the application.
 */
export const FeaturesProvider = (props) => {
  const { children, featuresConfig } = props

  const features = createFeatureRouter(featuresConfig || defaultFeaturesConfig)
  const featureDecisions = createFeatureDecisions(features)

  return (
    <FeaturesContext.Provider value={{ features, featureDecisions }}>
      {children}
    </FeaturesContext.Provider>
  )
}

/**
 * Retrieve a set of features decisions from the a current feature context.
 */
export const useFeatureDecisions = () => {
  const { featureDecisions } = useContext(FeaturesContext)
  return featureDecisions
}
