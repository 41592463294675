import { ToggleButton, ToggleButtonGroup } from "@mui/lab"
import { Stack, Typography } from "@mui/material"
import { useFlag } from "../../../../../utilities/feature-management"

/**
 * Component for selected one of three sizes
 * @param props - Component props
 */
const SizeSelectionControl = (props: any) => {
  const { branding, onPropertyChange } = props

  const rolloutCustomStyles = useFlag("rollout-translate-courses-on-export")

  return (
    <Stack>
      <Typography gutterBottom variant="titleMedium">
        Font Size
      </Typography>
      <ToggleButtonGroup
        color="primary"
        fullWidth
        value={branding.size}
        exclusive
        onChange={(e: any) => {
          onPropertyChange("size", e.target.value)
        }}
        aria-label="Size"
      >
        <ToggleButton value="smaller">Smaller</ToggleButton>
        <ToggleButton value="normal">Normal</ToggleButton>
        <ToggleButton value="bigger">Bigger</ToggleButton>
        {rolloutCustomStyles && (
          <ToggleButton value="custom">Custom</ToggleButton>
        )}
      </ToggleButtonGroup>
    </Stack>
  )
}

export default SizeSelectionControl
