import createCache from "@emotion/cache"
import { createPortal } from "react-dom"
import { CacheProvider } from "@emotion/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { uuidv4 } from "../utilities/domUtils"

/** Defines a custom element in the Froala editor.
 * Custom elements replace certain blocks within the froala editor
 * with react controls that can be edited in the editor.
 *
 * Custom elements are rendered in the shadow DOM, so they don't interfere
 * with the Froala editor.
 *
 * Custom elements have a data-serialized attribute that contains the data
 * of the element in JSON format. This is used to preserve the element across
 * a cut-and-paste operation, as Froala doesn't preserve class, id or style
 * attributes and may otherwise mutate the element.
 *
 * However, if a transform is applied, the content of the element will be
 * mutated but the data-serialized attribute will not be updated.
 *
 * To distinguish between the two, the data-serialized attribute is only
 * prioritized if the class "custom-element" has been removed from the element,
 * as a result of a cut-and-paste operation. If the class "custom-element" is
 * still present, then the data-serialized attribute is ignored and the data
 * is extracted from the light-DOM representation of the element.
 *
 */
export interface CustomElementConfig<T> {
  /** CSS selector for the custom element. All elements matching
   * this selector will be replaced with the custom element
   */
  selector: string
  /**
   * Extract data from the light-DOM representation of the custom element
   * @param element The element to extract data from
   */
  getDataFromElement: (element: HTMLElement) => T
  /**
   * Update the light-DOM representation of the custom element from the data
   * @param element The element to update
   * @param data The data to update the element with
   */
  updateElementFromData: (element: HTMLElement, data: T) => void

  /** Render the custom element in the Froala editor. Will be rendered in the shadow DOM
   * @param options Options for rendering the custom element
   */
  renderView: (options: RenderViewOptions<T>) => React.ReactElement
}

/** Options that are passed to renderView */
export interface RenderViewOptions<T> {
  /** Data of the custom element, extracted from the light-DOM representation */
  data: T
  /** Callback to update the data of the custom element. If not present, then read-only */
  onDataChange?: (data: T) => void
  /** Root element of component in light DOM */
  element: HTMLElement
  /** Froala editor instance */
  editor: any
  /** Froala editor div */
  editorDiv: HTMLDivElement
  /** Wrapper for style and theme injection. Do not wrap modals or any other
   * components that need to be rendered outside of the shadow DOM
   */
  withStyles: (children: React.ReactElement) => React.ReactElement
  /** Is this in read-only state */
  readOnly: boolean
}

/** Props for the overall CustomElements component that must be mounted once */
export interface CustomElementsProps {
  /** Froala editor */
  editor: any
  /** Froala editor div */
  editorDiv: HTMLDivElement
  /** Custom element configs */
  configs: CustomElementConfig<any>[]
  /** Is this in read-only state */
  readOnly: boolean
}

/** Information about a custom element instance */
interface CustomElementInstance {
  /** Element */
  element: HTMLElement

  /** Unique ID of the instance (used for unique key on list) */
  id: string

  /** Config of the element */
  config: CustomElementConfig<any>
}

/**
 * Set up custom element handling for the entire editor. This only needs to be mounted once per editor.
 * It will set up all custom elements in the editor and handle updates to them.
 * @param props Options for setting up custom elements
 */
export function CustomElements(props: CustomElementsProps) {
  const { configs, editorDiv, editor, readOnly } = props

  const [customElementInstances, setCustomElementInstances] = useState<
    CustomElementInstance[]
  >([])

  useEffect(() => {
    const instances = [] as CustomElementInstance[]

    // Mount all instances of custom elements into the editorDiv
    for (const config of configs) {
      // Find all elements in div
      editorDiv.querySelectorAll(config.selector).forEach((el) => {
        instances.push({
          element: el as HTMLElement,
          id: uuidv4(),
          config,
        })
      })
    }
    setCustomElementInstances(instances)

    // Add mutation observer, looking for custom elements that are added or removed
    const observer = new MutationObserver((mutations) => {
      const removedNodes = [] as HTMLElement[]
      const addedInstances = [] as CustomElementInstance[]

      for (const mutation of mutations) {
        // Find added instances
        mutation.addedNodes.forEach((addedNode) => {
          if (addedNode instanceof HTMLElement) {
            for (const config of configs) {
              // Check if the added node itself matches the selector
              if (addedNode.matches(config.selector)) {
                addedInstances.push({
                  element: addedNode,
                  id: uuidv4(),
                  config,
                })
              }
              // Check if any child nodes match the selector
              addedNode
                .querySelectorAll(config.selector)
                .forEach((matchingNode) => {
                  addedInstances.push({
                    element: matchingNode as HTMLElement,
                    id: uuidv4(),
                    config,
                  })
                })
            }
          }
        })

        // Find removed instances
        mutation.removedNodes.forEach((removedNode) => {
          // If the removed node is an HTMLElement, add it and its subnodes to the removedNodes array
          if (removedNode instanceof HTMLElement) {
            // Check if the removed node itself has a shadowRoot
            if (removedNode.shadowRoot) {
              removedNodes.push(removedNode)
            }
            removedNode.querySelectorAll("*").forEach((subNode) => {
              if (subNode instanceof HTMLElement && subNode.shadowRoot) {
                removedNodes.push(subNode)
              }
            })
          }
        })
      }

      // Update instances
      setCustomElementInstances((instances) => {
        // Remove instances that were removed in the DOM
        const newInstances = instances.filter(
          (instance) => !removedNodes.includes(instance.element)
        )

        // Add instances that were added in the DOM
        newInstances.push(...addedInstances)

        return newInstances
      })
    })

    // Observe adds and removes
    observer.observe(editorDiv, {
      subtree: true,
      childList: true,
    })

    // Remove the observer when done
    return () => {
      observer.disconnect()
    }
  }, [configs, editorDiv])

  // Render instances
  return (
    <>
      {customElementInstances.map((instance) => {
        return (
          <CustomElement
            key={instance.id}
            editor={editor}
            editorDiv={editorDiv}
            instance={instance}
            readOnly={readOnly}
          />
        )
      })}
    </>
  )
}

/** Props for rendering a custom element */
interface CustomElementProps {
  /** Froala editor */
  editor: any
  /** Froala editor div */
  editorDiv: HTMLDivElement
  /** Custom element instance */
  instance: CustomElementInstance
  /** Is this in read-only state */
  readOnly: boolean
}

/**
 * Render a custom element in the Froala editor
 * @param props Options for rendering the custom element
 */
function CustomElement(props: CustomElementProps) {
  const { instance, editor, editorDiv, readOnly } = props

  const { element, config } = instance

  // Get data from element
  const [data, setData] = useState(() => {
    // Extract data, preferring data-serialized if present and
    // "custom-element" class is not present
    let data: any
    const serializedData = element.dataset.serialized
    if (serializedData && !element.classList.contains("custom-element")) {
      data = JSON.parse(serializedData)
    } else {
      data = config.getDataFromElement(element)
    }

    // Update element with serialized data
    element.dataset.serialized = JSON.stringify(data)

    // Ensure that element has "custom-element" class
    if (!element.classList.contains("custom-element")) {
      element.classList.add("custom-element")
    }

    return data
  })

  // Create shadow root for element
  const shadowRoot = useMemo(() => {
    // Return existing root if element already has one
    if (element.shadowRoot) {
      return element.shadowRoot
    }

    // Create shadow root
    const shadowRoot = element.attachShadow({ mode: "open" })

    // Prevent bubbling of Froala events
    shadowRoot.addEventListener("keydown", (e) => e.stopPropagation())
    shadowRoot.addEventListener("keyup", (e) => e.stopPropagation())
    shadowRoot.addEventListener("keypress", (e) => e.stopPropagation())

    return shadowRoot
  }, [element])

  // Set up react root, a div to mount within shadow root
  const reactRoot = useMemo(() => {
    // Add react root
    const reactRoot = document.createElement("div")
    shadowRoot.appendChild(reactRoot)

    return reactRoot
  }, [shadowRoot])

  // Create style cache (needed since in the Shadow DOM)
  const styleCache = useMemo(() => {
    // Create style cache root for CSS in Shadow DOM
    const emotionRoot = document.createElement("style")
    shadowRoot.appendChild(emotionRoot)

    const styleCache = createCache({
      key: "css",
      container: emotionRoot,
    })

    return styleCache
  }, [shadowRoot])

  /** Create style wrapper that the shadow DOM can use to render styles.
   * This should be called on all children of the custom element that are in the
   * shadow DOM.
   *
   * @param children Children to wrap
   */
  const withStyles = useCallback(
    (children: React.ReactElement) => {
      return <CacheProvider value={styleCache}>{children}</CacheProvider>
    },
    [styleCache]
  )

  /** Handle a change to data of a custom element, updating the element in light DOM, setting
   * the serialized data, and updating the editor state.
   * @param data New data
   */
  function onDataChange(data: any) {
    // Update element with new data
    config.updateElementFromData(element, data)

    // Update serialized data
    element.dataset.serialized = JSON.stringify(data)

    // Save editor contents
    editor.undo.saveStep()

    // Update state
    setData(data)
  }

  // Create view of custom element
  const view = config.renderView({
    data,
    onDataChange,
    editor,
    element,
    editorDiv,
    withStyles,
    readOnly,
  })

  // Mount the view in the shadow DOM
  return createPortal(view, reactRoot)
}

/** Remove temporary attributes from custom elements. Custom elements
 * have temporary attributes added to them to allow them to be rendered
 * in the Froala editor. This function removes those attributes.
 *
 * @param topElement Parent element of tree to clean
 */
export function removeTemporaryAttributes(topElement: HTMLElement) {
  topElement.querySelectorAll("[data-serialized]").forEach((element) => {
    // Remove temporary attributes
    element.removeAttribute("data-serialized")
  })
}
