import { useAuthenticator } from "@aws-amplify/ui-react"
import { fade } from "../../utils/animations"
import { EmailField, PasswordField } from "../fields"
import { useFormHandlers } from "../hooks/useFormHandlers"
import { Button, RemoteErrorMessage, SubmitButton } from "../shared"
import Form from "./Form"

/**
 * Displays the form for sign-in.
 */
export function SignInWithPassword() {
  const { handleChange, handleSubmit } = useFormHandlers()

  return (
    <Form method="post" onChange={handleChange} onSubmit={handleSubmit}>
      <EmailField />
      <PasswordField {...fade} />
      <RemoteErrorMessage />
      <Form.ActionBar>
        <SubmitButton label="Sign-in" />
        <ForgotPasswordLink />
      </Form.ActionBar>
    </Form>
  )
}

export default SignInWithPassword

/** Navigates to the forgot password page on click. */
function ForgotPasswordLink() {
  const { toResetPassword } = useAuthenticator((context) => [
    context.toResetPassword,
  ])

  return (
    <Button
      onClick={(e) => {
        e.preventDefault()
        toResetPassword()
      }}
      {...fade}
    >
      Forgot password?
    </Button>
  )
}
