import { MenuItem, Box, IconButton, Tooltip } from "@mui/material"
import { yellow } from "@mui/material/colors"
import { QuickInsertItem } from "./quickInsertItems"
import { Star, StarOutline } from "@mui/icons-material"

/**
 * A single item in the quick insert menu
 * @param props - Component props
 * @param props.item - The QuickInsertItem obect to render
 * @param props.onInsert - Callback when item is clicked
 * @param props.onStar - Callback for when Favourite icon is clicked
 * @param props.starred - Whether it is a favourited item
 * @param props.enableFavourites - Feature flag to display star option
 * @param props.divider - Renders divider at bottom of item
 * */
function QuickInsertMenuItem(props: {
  item: QuickInsertItem
  onInsert: () => void
  onStar: () => void
  starred?: boolean
  enableFavourites?: boolean
  divider?: boolean
}) {
  const { item, onInsert, onStar, starred, enableFavourites, divider } = props

  return (
    <MenuItem onClick={onInsert} disableGutters divider={divider}>
      <div className="quick-insert-menu-item">
        <div className="quick-insert-menu-item-icon">{item.icon}</div>
        <div className="quick-insert-menu-item-title">{item.title}</div>
        <div className="quick-insert-menu-item-description">
          {item.description}
        </div>
      </div>
      {enableFavourites && (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Tooltip title={starred ? "Starred" : "Not starred"}>
            <IconButton
              aria-label="star"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onStar()
              }}
            >
              {starred ? <Star sx={{ color: yellow[700] }} /> : <StarOutline />}
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </MenuItem>
  )
}

export default QuickInsertMenuItem
