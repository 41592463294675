import { Clear, SearchOutlined } from "@mui/icons-material"
import {
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  Stack,
  Theme,
} from "@mui/material"
import { useState } from "react"

type SearchBarProps = {
  onSearchInputChange: (value: string) => void
  displaySearchResults: boolean
  onClearSearch: () => void
}

/**
 * Renders a rounded search bar
 * @param props - Component props
 */
const SearchBar = (props: SearchBarProps) => {
  const { onSearchInputChange, displaySearchResults, onClearSearch } = props
  const [input, setInput] = useState("")

  return (
    <TextField
      sx={(theme: Theme) => ({
        maxWidth: theme.spacing(48),
        backgroundColor: theme.scheme.onPrimary,
        borderRadius: "40px",
        "& .MuiInput-underline:after": {
          borderBottomColor: theme.scheme.primary,
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "40px",
          paddingLeft: "12px",
          paddingRight: "12px",
          "& fieldset": {
            borderColor: theme.scheme.onPrimary,
          },
          "&:hover fieldset": {
            borderColor: theme.scheme.onPrimary,
          },
          "&.Mui-focused fieldset": {
            borderColor: theme.scheme.primary,
          },
        },
      })}
      variant="outlined"
      data-cy="courses-search-input"
      placeholder="Search"
      onChange={(e) => {
        const value = e.target.value
        onSearchInputChange(value)
        setInput(value)
      }}
      value={input}
      fullWidth
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="end">
              {displaySearchResults ? (
                <Tooltip arrow placement="top" title="Clear Search">
                  <IconButton
                    sx={{ margin: 0 }} // Setting margin to 0 lines up both Input Adornments
                    aria-label="Clear Search"
                    onClick={() => {
                      setInput("")
                      onClearSearch()
                    }}
                    edge="end"
                  >
                    <Clear />
                  </IconButton>
                </Tooltip>
              ) : (
                <Stack justifyContent="center" padding="8px">
                  <SearchOutlined />
                </Stack>
              )}
            </InputAdornment>
          </>
        ),
      }}
    />
  )
}

export default SearchBar
