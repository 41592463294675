import axios from "../../../api/axios"

/**
 * Obtain user config object from server
 */
const fetchUserConfiguration = () => {
  return axios.get(`/configuration`)
}

export default fetchUserConfiguration
