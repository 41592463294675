import React, { useState } from "react"
import { AddSourceDocumentDialog } from "./addSourceDocumentCustomElement"
import { Box, Button, Divider } from "@mui/material"
import { Add } from "@mui/icons-material"

interface BottomControlProps {
  /** Froala editor */
  editor: any
  /** Froala editor div */
  editorDiv: HTMLDivElement
}

/**
 * Component for control mounted at bottom of editor
 * @param props - Component props
 */
const BottomControl = (props: BottomControlProps) => {
  const { editor, editorDiv } = props
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  /**
   * Callback once source is done uploading and HTML is fetched from S3.
   * Insert the new HTML at the end of the document
   * @param html - New source HTML generated from import
   */
  const onInsert = (html: string) => {
    setDialogOpen(false)
    const childElement: any = editorDiv.firstChild
    childElement.insertAdjacentHTML("beforeend", html)
    editor.undo.saveStep()
  }

  return (
    <>
      {dialogOpen && (
        <AddSourceDocumentDialog
          onInsert={onInsert}
          onCancel={() => setDialogOpen(false)}
        />
      )}
      <Box marginBottom={4} width="100%" paddingLeft={4} paddingRight={4}>
        <Divider>
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => setDialogOpen(true)}
          >
            Import Source
          </Button>
        </Divider>
      </Box>
    </>
  )
}

export default BottomControl
