import { isArray } from "lodash"
import { Detection } from "../remarks"
import { ServerDetection } from "../actions/fetchDetections"

/**
 * Normalize the structure of a detection or set of detections returned from the server.
 *
 * @param {ServerDetection | ServerDetection[]} data - The detection or set of detections to normalize.
 * @returns {Detection | Detection[]} The normalized detection or set of detections.
 */
function normalizeDetection(data: ServerDetection[]): Detection[]
function normalizeDetection(data: ServerDetection): Detection
function normalizeDetection(
  data: ServerDetection | ServerDetection[]
): Detection | Detection[] {
  if (isArray(data)) {
    return data.map((data) => normalizeDetection(data))
  }

  return {
    id: data.id,
    rule: data.rule,
    type: data.type,
    detectionKey: data.detection_key,
    text: data.text,
    selectedIndex: data?.transformation?.options ? 0 : null,
    optionsVisible: false,
    transformation: data.transformation,
    remarkType: "detection",
    location: {
      path: data.path ?? [],
      offset: data.start_index,
      tag: data.type,
      text: data.text,
      numBlocks: data.num_blocks,
    },
  }
}

export default normalizeDetection
