import axiosInstance from "../../../api/axios"

/**
 *
 * @param arg
 * @returns {Promise<void>}
 */
const deleteLearningPath = async (arg) => {
  const { pathId } = arg

  await axiosInstance.delete(`/paths/${pathId}`)
}

export default deleteLearningPath
