import { isObject, mapValues } from "lodash/fp"
import { CheckCircle, DeleteForever } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, List, ListItem, Stack, Tooltip, Typography } from "@mui/material"
import PropTypes from "prop-types"
import withPreventDefault from "../../utilities/withPreventDefault"
import Diff from "../atoms/Diff"

const DiffLabel = (props) => (
  <Typography
    align="center"
    fontWeight="bold"
    backgroundColor="neutral.light"
    padding={1}
    {...props}
  />
)

export const states = {
  APPLYING: "applying",
  IGNORING: "ignoring",
  READY: "ready",
}

const templateColumns = (direction) => {
  if (isObject(direction)) {
    return mapValues(templateColumns, { xs: "column", ...direction })
  }

  if (direction === "row") {
    return "repeat(2, minmax(0, 1fr))"
  }

  return "minmax(0, 1fr)"
}

/**
 * Displays changes that can be applied to related courses.
 *
 * The user can evalute whether they want to apply or ignore the change for a
 * given set of course documents.
 */
const IntelligentUpdate = (props) => {
  const {
    before,
    after,
    targets = [],
    state,
    onApplyClick,
    onIgnoreClick,
    diffDirection = { xs: "column", sm: "row" },
  } = props

  const hasTargets = !!targets?.length
  const canUpdate = state === states.READY && hasTargets

  const targetsTooltip = hasTargets ? (
    <List dense sx={{ fontSize: "body1.fontSize" }}>
      {targets.map(({ id, title }) => (
        <ListItem key={id}>"{title}"</ListItem>
      ))}
    </List>
  ) : (
    ""
  )

  const targetsLabel = hasTargets
    ? `Applies to (${targets.length}): ${targets.map(
        ({ title }) => `"${title}"`
      )}.`
    : "Applies to none."

  return (
    <Stack spacing={2} data-cy="intelligent-update">
      <Stack direction="row" spacing={2} alignItems="center">
        <Tooltip title={targetsTooltip} placement="bottom-start">
          <Typography width="1px" flexGrow={1} noWrap>
            {targetsLabel}
          </Typography>
        </Tooltip>
        <LoadingButton
          loadingPosition="start"
          loading={state === states.APPLYING}
          disabled={!canUpdate}
          onClick={withPreventDefault(onApplyClick)}
          variant="outlined"
          color="success"
          startIcon={<CheckCircle />}
        >
          Update
        </LoadingButton>
        <LoadingButton
          loadingPosition="start"
          loading={state === states.IGNORING}
          disabled={state !== states.READY}
          onClick={withPreventDefault(onIgnoreClick)}
          variant="outlined"
          color="error"
          startIcon={<DeleteForever />}
        >
          Ignore
        </LoadingButton>
      </Stack>
      <Box
        display="grid"
        gridTemplateColumns={templateColumns(diffDirection)}
        gap={2}
        maxWidth="100%"
      >
        <Box>
          <DiffLabel>Before</DiffLabel>
          <Diff data-cy="before" changes={before} />
        </Box>
        <Box>
          <DiffLabel>{before ? "After" : "Changes"}</DiffLabel>
          <Diff data-cy="after" changes={after} />
        </Box>
      </Box>
    </Stack>
  )
}

IntelligentUpdate.states = states

IntelligentUpdate.propTypes = {
  /** The list of target course documents. */
  targets: PropTypes.array,
  /** A HTML string displaying the before state. */
  before: PropTypes.string,
  /** A HTML string displaying the after state. */
  after: PropTypes.string,
  /**
   * Handler for the update button.
   */
  onUpdateClick: PropTypes.func,
  /**
   * A handler for the ignore button.
   */
  onIgnoreClick: PropTypes.func,
}

export default IntelligentUpdate
