import axiosInstance from "../../../api/axios"

/**
 *
 * @param arg
 * @returns {Promise<void>}
 */
const removeCourseFromLearningPath = async (arg) => {
  const { pathId, courseId } = arg

  await axiosInstance.delete(`/paths/${pathId}/courses/${courseId}`)
}
export default removeCourseFromLearningPath
