import { Box, Typography } from "@mui/material"
import { ArrowRight } from "@mui/icons-material"
import pluralize from "../../outline/utils/pluralize"

type FolderNavBarProps = {
  folder: string
  handleBackClicked: () => void
  items: any[]
  displaySearchResults: boolean
}

/**
 * Breadcrumb item to appear in Directory Nav Bar
 * @param props - Component props
 * @param props.name - Name for breadcrumb
 * @param props.root - Indicates whether it is the first item
 * @param props.onClick - Click handler
 */
const FolderNavItem = (props: {
  name: string
  root?: boolean
  onClick?: () => void
}) => {
  const { name, root, onClick = () => null } = props

  return (
    <Box display="flex" alignItems="center">
      {!root && (
        <ArrowRight
          fontSize="small"
          sx={{ marginLeft: "0.25em", marginRight: "0.25em" }}
        />
      )}
      <Typography
        sx={{
          ...(root && {
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }),
        }}
        variant="body2"
        onClick={onClick}
      >
        {name}
      </Typography>
    </Box>
  )
}

/**
 * Nav for display current folder user is in
 * @param props - Component props
 */
const FolderNavBar = (props: FolderNavBarProps) => {
  const { folder, handleBackClicked, items, displaySearchResults } = props

  const coursesCount = items.filter(
    (item) => item.item_type === "course" || item.item_type == null
  ).length

  return (
    <Box
      display="flex"
      marginTop={2}
      marginBottom={2}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        {displaySearchResults ? (
          <Box display="flex" alignItems="center">
            <Typography variant="body2">Search Results</Typography>
          </Box>
        ) : (
          <>
            <FolderNavItem
              name="All Courses"
              onClick={handleBackClicked}
              root
            />
            {folder && <FolderNavItem name={folder} />}
          </>
        )}
      </Box>
      <Box>
        {items && (
          <Typography variant="body2">
            {pluralize("course", coursesCount, true)}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default FolderNavBar
