import { ArrowDropDown } from "@mui/icons-material"
import { alpha, Button, Theme } from "@mui/material"
import { ReactElement, ReactNode } from "react"

type MenuControlButtonProp = {
  icon?: ReactNode
  onClick: (event: any) => void
  children: ReactElement | string
}

/**
 * Reusable component for diplaying M3-style dropdown button
 * @param props - Component props
 */
const MenuControlButton = (props: MenuControlButtonProp) => {
  const { icon, onClick, children } = props

  return (
    <Button
      sx={(theme: Theme) => ({
        paddingX: "6px",
        height: "32px",
        color: theme.scheme.onSecondaryContainer,
        backgroundColor: theme.scheme.secondaryContainer,
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: alpha(theme.scheme.surfaceTint, 0.08),
        },
      })}
      aria-haspopup="true"
      endIcon={<ArrowDropDown />}
      startIcon={icon}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default MenuControlButton
