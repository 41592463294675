import { Add } from "@mui/icons-material"
import ListLayout from "../../itemList/ListContainer"
import PageLayout from "../../layouts/PageLayout"
import { Box, Button } from "@mui/material"
import useLearningPaths from "../../../learningPaths/hooks/useLearningPaths"
import { useMemo, useState } from "react"
import { sortList, sortOptions } from "../../../welcome/hooks/useCoursesDisplay"
import CreateFromTemplateDialog from "../../../learningPaths/dialogs/CreateFromTemplateDialog"
import CreateLearningPathDialog from "../../../learningPaths/dialogs/CreateLearningPathDialog"
import ExampleDialog from "../../../learningPaths/dialogs/ExampleDialog"
import filterLearningPaths from "../../../learningPaths/utils/filterLearningPaths"
import LearningPathsItemList from "./components/LearningPathsItemList"
import { debounce } from "lodash/fp"
import SortByControl from "../../components/SortByControl"
import SearchBar from "../../components/SearchBar"

const SEARCH_DEBOUNCE_TIME = 500

/**
 * Page to display all learning paths for a user
 * @param props - Component props
 * @param props.location - Route location
 */
const LearningPathsPage = (props: { location: any }) => {
  const { location } = props
  const {
    createPath,
    isLoading,
    pathTemplates,
    result: { learningPaths },
    sortBy,
    handleSelectSortOption,
    ...learningPathProps
  } = useLearningPaths()

  const [createEmptyDialogVisible, setCreateEmptyDialogVisible] =
    useState(false)
  const [createFromTemplateDialogVisible, setCreateFromTemplateDialogVisible] =
    useState(false)
  const [exampleDialogVisible, setExampleDialogVisible] = useState(false)
  const [query, setQuery] = useState("")

  const sortedPaths = useMemo(
    () => sortList(sortBy, learningPaths),
    [learningPaths, sortBy]
  )

  const filteredPaths = filterLearningPaths(sortedPaths, query)

  return (
    <>
      <PageLayout navRail location={location}>
        <ListLayout
          title="Learning Paths"
          searchBar={
            <SearchBar
              displaySearchResults={query !== ""}
              onSearchInputChange={debounce(SEARCH_DEBOUNCE_TIME, (query) => {
                setQuery(query)
              })}
              onClearSearch={() => setQuery("")}
            />
          }
          controls={
            <>
              <Button
                startIcon={<Add />}
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault()
                  setCreateEmptyDialogVisible(true)
                }}
              >
                Add path
              </Button>
              <Box display="flex" alignItems="center" marginLeft="auto">
                <SortByControl
                  options={sortOptions}
                  sortBy={sortBy}
                  handleSelectSortOption={handleSelectSortOption}
                />
              </Box>
            </>
          }
        >
          <LearningPathsItemList
            learningPaths={filteredPaths}
            loading={isLoading}
            displaySearchResults={query !== ""}
            {...learningPathProps}
          />
        </ListLayout>
      </PageLayout>
      <CreateLearningPathDialog
        open={createEmptyDialogVisible}
        onConfirm={(name: string) => {
          createPath({ title: name })
          setCreateEmptyDialogVisible(false)
        }}
        onCancel={() => {
          setCreateEmptyDialogVisible(false)
        }}
      />
      <CreateFromTemplateDialog
        open={createFromTemplateDialogVisible}
        pathTemplates={pathTemplates}
        onConfirm={(template: any) => {
          createPath({ templateId: template.id })
          setCreateFromTemplateDialogVisible(false)
        }}
        onCancel={() => {
          setCreateFromTemplateDialogVisible(false)
        }}
      />
      <ExampleDialog
        open={exampleDialogVisible}
        onCancel={(e: any) => {
          e.preventDefault()
          setExampleDialogVisible(false)
        }}
      />
    </>
  )
}

export default LearningPathsPage
