import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material"
import React from "react"
import CourseTile from "../../homeNavigation/components/gridLayout/CourseTile"
import { ArrowForwardIos, Close } from "@mui/icons-material"
import Button from "@mui/material/Button"

/**
 * Array of placeholder course data for our example
 */
const exampleCourses = [
  {
    title: "Introducing ACME Inc.",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
  {
    title: "Understanding Life at ACME Inc.",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
  {
    title: "Introducing the ACME Inc. Solution (Or Product)",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
  {
    title: "Understanding the ACME Inc. Go To Market Strategy",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
  {
    title: "Describing Important ACME IT Systems",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
  {
    title: "ACME Corporate Policies",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
  {
    title: "ACME Annual Compliance",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
  {
    title: "ACME's Commitment to Diversity, Equality, and Inclusion",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
  {
    title: "Understanding ACME Health and Safety",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
  {
    title: "Preventing Violence and Harassment in the Workplace",
    last_update: "2023-02-28T18:36:35.130461+00:00",
  },
]

/**
 * Dialog component for displaying a pre-set example Learning Path for users to
 * model their paths off of.
 * @param props - Functionality for opening and closing dialog
 */
const ExampleDialog = (props) => {
  const { onCancel, open } = props

  return (
    <Dialog onClose={onCancel} open={open} maxWidth="xl" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Learning Paths
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          This is a sample Learning Path for internal new employee onboarding.
          Learning Paths combine all the courses a learner for the role should
          complete and in the logical order they should be taken. You can
          combine the same courses in different paths and combinations to
          achieve different learning outcomes for learners in different roles.
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">
            Individual Contributor Onboarding (Example)
          </Typography>
          <Divider sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1 }} />
        </Box>
        <Typography variant="body2" gutterBottom>
          {exampleCourses.length} courses
        </Typography>
        <Box display="flex" flexWrap="wrap">
          {exampleCourses.map((course, index) => (
            <Box
              sx={{ pointerEvents: "none" }}
              alignItems="center"
              marginBottom={2}
              display="flex"
              key={index}
            >
              <CourseTile item={course} />
              {index !== exampleCourses.length - 1 && (
                <ArrowForwardIos
                  fontSize="small"
                  sx={{ marginLeft: 2, marginRight: 2 }}
                />
              )}
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="center">
          <Button size="large" onClick={onCancel} variant="contained">
            Got It
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ExampleDialog
