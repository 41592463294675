import React from "react"
import ShareCourseScreen from "../components/screens/ShareCourseScreen"
import useCourses from "../hooks/useCourses"
import { parseQueryString } from "../utilities/queryString"

const SharePage = (props) => {
  const { location } = props

  const breadcrumbs = location?.state?.breadcrumbs ?? [
    {
      label: "Courses",
      href: "/",
    },
    { label: "Tools", href: "/tools" },
  ]

  const { course } = parseQueryString(location.search)
  const { documents: courses } = useCourses()

  return (
    <ShareCourseScreen
      breadcrumbs={breadcrumbs}
      course={course}
      courseOptions={courses}
    />
  )
}

export default SharePage
