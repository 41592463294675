import React, { useEffect, useState } from "react"
import { Box, Button, Dialog, InputLabel, Select, Stack } from "@mui/material"
import CustomDateRange from "./CustomDateRange"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import {
  convertPresetToRange,
  datePresetEnum,
} from "../../utilities/transformers"

const CustomDateRangePicker = (props) => {
  const { start, end, showDialog, onDateRangePicked, onCancel } = props
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)
  const [presetRange, setPresetRange] = useState("")

  useEffect(() => {
    setStartDate(start)
    setEndDate(end)
  }, [start, end])

  const onOK = () => {
    if (endDate != null && startDate != null && startDate > endDate) {
      onDateRangePicked(endDate, startDate, presetRange)
    } else {
      onDateRangePicked(startDate, endDate, presetRange)
    }
    setPresetRange("")
  }

  const onDateRangeCancel = () => {
    setPresetRange("")
    setStartDate(start)
    setEndDate(end)
    onCancel()
  }

  const onStartChange = (date) => {
    setPresetRange("")
    setStartDate(date)
  }

  const onEndChange = (date) => {
    setPresetRange("")
    setEndDate(date)
  }

  const onPresetChange = (preset) => {
    setPresetRange(preset)

    const range = convertPresetToRange(preset)
    if (range != null) {
      setStartDate(range["start"])
      setEndDate(range["end"])
    } else {
      setStartDate(null)
      setEndDate(null)
    }
  }

  return (
    <Box>
      <Dialog maxWidth="lg" open={showDialog} onClose={onDateRangeCancel}>
        <CustomDateRange
          start={startDate}
          end={endDate}
          startChange={onStartChange}
          endChange={onEndChange}
        />
        <Divider />

        <Box
          padding={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormControl sx={{ minWidth: "12em" }}>
            <InputLabel id="demo-simple-select-label">Presets</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={presetRange}
              label="Preset"
              onChange={(event) => onPresetChange(event.target.value)}
            >
              <MenuItem value={datePresetEnum.TODAY}>Today</MenuItem>
              <MenuItem value={datePresetEnum.YESTERDAY}>Yesterday</MenuItem>
              <MenuItem value={datePresetEnum.THIS_WEEK}>This Week</MenuItem>
              <MenuItem value={datePresetEnum.LAST_WEEK}>Last Week</MenuItem>
              <MenuItem value={datePresetEnum.THIS_MONTH}>This Month</MenuItem>
              <MenuItem value={datePresetEnum.LAST_MONTH}>Last Month</MenuItem>
            </Select>
          </FormControl>
          <Stack direction="row" gap={2}>
            <Button onClick={() => onPresetChange("")}>Clear</Button>
            <Button onClick={() => onDateRangeCancel()}>Cancel</Button>
            <Button onClick={onOK} variant="contained">
              OK
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  )
}

export default CustomDateRangePicker
