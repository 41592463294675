import { ReactNode } from "react"
import { Box, ListItem, ListItemButton, ListItemIcon } from "@mui/material"
import { Add, Check } from "@mui/icons-material"

/**
 * Component for rendering ListItem that can be clicked to add it to the document.
 * @param props - Component props
 */
export const AddableItem = (props: AddableItemProps) => {
  const { children, added, onAdd } = props

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onAdd} disabled={added}>
        <ListItemIcon>
          {added ? <Check color="success" /> : <Add color="primary" />}
        </ListItemIcon>
        <Box flexGrow={1} flexShrink={1} width="100%">
          {children}
        </Box>
      </ListItemButton>
    </ListItem>
  )
}

/**
 * Component props for AddableItem.
 */
interface AddableItemProps {
  /** Children to render */
  children?: ReactNode
  /** True if item has been added */
  added: boolean
  /** Callback when item is added */
  onAdd: () => void
}
