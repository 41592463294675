import { Box, IconButton, Typography } from "@mui/material"
import { StyledListData } from "./styledListCustomElement"
import produce from "immer"
import { Delete } from "@mui/icons-material"
import FroalaEditor from "react-froala-wysiwyg"
import { minimalFroalaConfig } from "../minimalFroalaConfig"

/**
 * Override some style properties for Rich Text editor to fit better for Styled Lists
 */
const styledListFroalaConfig = {
  ...minimalFroalaConfig,
  height: "auto",
  heightMax: 100,
  toolbarButtons: {
    moreText: {
      buttons: [
        "undo",
        "redo",
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "textColor",
        "backgroundColor",
        "clearFormatting",
      ],
      buttonsVisible: 13,
    },
  },
}

interface StyledListItemEditProps {
  data: StyledListData
  onChange: (data: StyledListData) => void
  index: number
}

/**
 * Component for rendering an editable styled list item
 * @param props - Component props
 *
 */
const StyledListItemEdit = (props: StyledListItemEditProps) => {
  const { data, onChange, index } = props

  const listItem = data.listItems[index]

  const model = listItem.html

  return (
    <Box display="flex" mb={2} flexDirection="row" alignItems="center" gap={2}>
      <Box flexGrow={1}>
        <Typography gutterBottom variant="body1">
          <b>{"List Item " + (index + 1)}</b>
        </Typography>
        <FroalaEditor
          tag="textarea"
          config={styledListFroalaConfig}
          model={model}
          onModelChange={(model: string) => {
            onChange(
              produce(data, (draft) => {
                draft.listItems[index].html = model
              })
            )
          }}
        />
      </Box>

      <Box>
        <IconButton
          onClick={() => {
            onChange(
              produce(data, (draft) => {
                draft.listItems.splice(index, 1)
              })
            )
          }}
        >
          <Delete />
        </IconButton>
      </Box>
    </Box>
  )
}

export default StyledListItemEdit
