import {
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material"
import ActionLogRecord from "./ActionLogRecord"
import ActionLogHeadings from "./ActionLogHeadings"

type ActionLogProps = {
  records: any[]
  initialLoad: boolean
  onSelectRecord: (id: string) => void
}

/**
 * Component for rendering Action Log table
 * @param props - Component props
 */
const ActionLog = (props: ActionLogProps) => {
  const { records, initialLoad, onSelectRecord } = props

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table stickyHeader>
          <ActionLogHeadings />
          {records.length > 0 && (
            <TableBody>
              {records.map((record: any) => (
                <ActionLogRecord
                  record={record}
                  onSelectRecord={onSelectRecord}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {initialLoad && (
        <Stack flex="1 1 0" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
    </>
  )
}

export default ActionLog
