import axios from "../../../api/axios"

/**
 * Move a collection of courses to the trash
 *
 * @param ids - IDs of courses to move to trash
 */
const trashCourses = (ids: string[]) =>
  axios.post("/courses/trash/", {
    courses: ids,
  })

export default trashCourses
