import { useFlags } from "launchdarkly-react-client-sdk"
import { snakeCase } from "lodash/fp"

/** The prefix for generated flag environment keys. */
const ENVIRONMENT_KEY_PREFIX = "REACT_APP_FEATURE_"

type FromEnvironmentOptions<T> = {
  /** Used to parse a value from the environment. */
  parse?: (value: string) => T
}

type UseFlagOptions<T> = {
  /** Overrides the default environment key used to retreive a flag value. */
  environmentKey?: string
} & FromEnvironmentOptions<T>

/**
 * Check if a given feature is enabled.
 *
 * @param key A key identifying a feature-flag configuration.
 * @param options Additional options for flag retrieval.
 */
function useFlag<T = boolean>(
  key: string,
  options: UseFlagOptions<T> = {}
): T | null {
  const flags = useFlags()

  // While in development or test mode, flags may be retrieved from the
  // environment.
  if (process.env.NODE_ENV !== "production") {
    const { environmentKey = defaultEnvironmentKeyFor(key) } = options
    return fromEnvironment(environmentKey, options) ?? flags[key]
  }

  return flags[key]
}

export default useFlag

/**
 * Retrieve and parse a value from the environment.
 *
 * @param key The name of the flag.
 * @param options Additional options for retrieving that environment value.
 */
function fromEnvironment<T = boolean>(
  key: string,
  options: FromEnvironmentOptions<T>
): T | null {
  const { parse = parseBoolean } = options

  const rawValue = process.env?.[key]
  if (rawValue === undefined) {
    return null
  }

  return parse(rawValue) as T
}

/**
 * Get the default environment key for a given flag key.
 *
 * @param key The flag key.
 */
function defaultEnvironmentKeyFor(key: string) {
  return `${ENVIRONMENT_KEY_PREFIX}${snakeCase(key).toUpperCase()}`
}

/**
 * Parse a boolean value from a given string.
 *
 * @param value The input string.
 */
function parseBoolean(value: string) {
  return ["true", "1", "t"].includes(value.toLowerCase())
}
