import { throttle } from "lodash"
import { useEffect, useMemo, useRef, useState } from "react"
import findSectionStart from "../utils/findSectionStart"
import {} from "froala-editor"
import { isElementVisible } from "../../../utilities/domUtils"
import debounce from "lodash/fp/debounce"
import findVisibleSection from "../utils/findVisibleSection"
import findFirstHeading from "../utils/findFirstHeading"
import getCursorPosition from "../utils/getCursorPosition"

/** The throttle time between updating the cursor section. */
const THROTTLE_TIME = 100 // milliseconds

/** Debounce time before re-enabling the scroll listener*/
const SCROLL_ENABLE_DEBOUNCE = 200 // milliseconds

/**
 * Get the active section for a document.
 *
 * Any time the cursor is moved the active section will be reevaluated. Should
 * a cursor move occur outside of the container, the last section is maintained.
 *
 * @type {(container: ?Element) => ?Element}
 */
const useActiveSection = (container) => {
  const [activeSection, setActiveSection] = useState(null)
  const selectedSection = useRef()
  const scrollUpdateEnabled = useRef(true)

  const enableScrollUpdate = useMemo(
    () =>
      debounce(SCROLL_ENABLE_DEBOUNCE, () => {
        scrollUpdateEnabled.current = true
      }),
    [scrollUpdateEnabled]
  )

  // When component first mounts, set activeSection to first section
  useEffect(() => {
    if (!container) {
      return
    }
    const startSection = findFirstHeading({ container })
    setActiveSection(startSection)
  }, [container])

  useEffect(() => {
    if (!container) {
      return
    }

    /**
     * Handler for when a user performs a selection within the editor.
     *
     * Determines the section that the selection resides in, and updates the state to reflect that.
     *
     */
    const updateBySelection = throttle(() => {
      const selectedElement = getCursorPosition(container)
      const section = findSectionStart(selectedElement, { container })
      if (section) {
        scrollUpdateEnabled.current = false
        // Cache the current selection in a ref that we can reference when scrolling
        selectedSection.current = section
        setActiveSection(section)
      }
    }, THROTTLE_TIME)

    /**
     * Handler for when a scroll occurs within the editor
     *
     * If our current selection is visible, set the active section to be the selection.
     * Otherwise, determine the current section that is in view and update the active section to be that.
     *
     *
     */
    const updateByScroll = throttle(() => {
      // before we proceed, check if the selected section is in the viewport. if so, don't bother scrolling
      if (
        selectedSection.current &&
        isElementVisible(selectedSection.current)
      ) {
        setActiveSection(selectedSection.current)

        // Since we have our selected element in the viewport, the automated scroll is complete and can reenable
        // updating by scroll
        enableScrollUpdate()
        return
      }

      const section = findVisibleSection({ container })
      if (scrollUpdateEnabled.current === true) {
        // if the scroll update is enabled, proceed with updating the active section
        if (section) {
          setActiveSection(section)
        }
      }
    }, THROTTLE_TIME)

    updateBySelection()
    container.parentElement?.addEventListener("scroll", updateByScroll)
    document.addEventListener("selectionchange", updateBySelection)
    return () => {
      container.parentElement?.removeEventListener("scroll", updateByScroll)
      document.removeEventListener("selectionchange", updateBySelection)
    }
  }, [container, enableScrollUpdate])

  return activeSection
}

export default useActiveSection
