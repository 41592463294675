import {
  Add,
  Delete,
  Edit,
  DragHandle,
  Clear,
  Check,
  Replay,
} from "@mui/icons-material"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  lighten,
  Paper,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { CustomElementConfig, RenderViewOptions } from "./CustomElements"
import produce from "immer"
import _ from "lodash"
import { useStableCallback } from "../hooks/useStableCallback"
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core"
import { styled, keyframes } from "@mui/system"
import { useFitText } from "../hooks/useFitText"
import AccessibilityWarning from "../components/atoms/AccessibilityWarning"
import { useFlag } from "../utilities/feature-management"

/** CSS animation for fading out incorrect backgrounds*/
const fadeOutIncorrect = keyframes`
  0% {
    background-color: #f88;
  }
  100% {
    background-color: #eee;
  }
`

/** CSS animation for fading out correct backgrounds */
const fadeOutCorrect = keyframes`
  0% {
    background-color: #8f8;
  }
  100% {
    background-color: #eee;
  }
`

/** CSS animation for fading out icons */
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

/** Category state of the last drop/hover */
type CategoryState = "correct" | "incorrect" | "normal" | "hover"

/** Styled div that adds the background color that fades
 * depending on the state of the last drop/hover
 */
const Category = styled("div", {
  /** Determine whether to forward the prop
   * @param prop The prop to forward
   */
  shouldForwardProp: (prop) => prop !== "state",
})<{ state: CategoryState }>(({ state }) => {
  return {
    backgroundColor: state === "hover" ? "#bbb" : "#eee",
    animation:
      state === "correct"
        ? `${fadeOutCorrect} 1.5s forwards`
        : state === "incorrect"
        ? `${fadeOutIncorrect} 1.5s forwards`
        : undefined,
    padding: 20,
    margin: 10,
    height: 200,
    display: "grid",
    gridTemplateRows: "1fr",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 1,
    border: "dashed 1px #aaa",
    position: "relative",
  }
})

/** Styled div that adds the icon that fades out */
const Icon = styled("div")({
  animation: `${fadeOut} 1.5s forwards`,
})

/** Data representation of categories state. */
interface CategoriesData {
  categories: CategoryData[]

  /** Background color of tabs component */
  backgroundColor?: "primary"
}

/** Data representation of a single category. */
interface CategoryData {
  /** Label of the category */
  label: string

  /** Items of the category */
  items: string[]
}

/*
 * This represents a set of categories that can be sorted as an exercise.
 * The HTML representation is stored as a section element with a data-component="categories"
 * attribute. Each category is stored as a div with a data-component="category". Label is a <p> inside the
 * category div. Each item within a category is represented as an item in a list.
 *
 * Sample HTML:
 * <section data-component="categories">
 *  <div data-component="category">
 *   <p>Category 1</p>
 *   <ul>
 *    <li>Item 1</li>
 *    <li>Item 2</li>
 *    <!-- More items as needed -->
 *   </ul>
 *  </div>
 *  <div data-component="category">
 *   <p>Category 2</p>
 *   <ul>
 *    <li>Item 1</li>
 *    <li>Item 2</li>
 *    <!-- More items as needed -->
 *   </ul>
 *  </div>
 * </section>
 *
 */
export const categoriesCustomElement: CustomElementConfig<CategoriesData> = {
  selector: "[data-component='categories']",
  /**
   * Extract data from the light-DOM representation of the custom element
   * @param element The element to extract data from
   */
  getDataFromElement: (element) => {
    const categories = Array.from(
      element.querySelectorAll("[data-component='category']")
    ).map((category) => {
      const label = category.querySelector("p")?.innerHTML ?? ""
      const items = Array.from(category.querySelectorAll("li")).map(
        (item) => item.textContent ?? ""
      )
      return { label, items }
    })

    const backgroundColor = element.dataset.backgroundColor as
      | "primary"
      | undefined

    return { categories, backgroundColor }
  },
  /**
   * Update the light-DOM representation of the custom element from the data
   * @param element The element to update
   * @param data The data to update the element with
   */
  updateElementFromData: (element, data) => {
    // Save contents
    element.innerHTML = data.categories
      .map(
        (category) =>
          `<div data-component="category" data-cy="category-items"><p>` +
          _.escape(category.label) +
          `</p><ul data-cy="category-items-data">` +
          category.items.map((item) => `<li>${_.escape(item)}</li>`).join("") +
          `</ul></div>`
      )
      .join("")

    if (data.backgroundColor) {
      element.dataset.backgroundColor = data.backgroundColor
    } else {
      delete element.dataset.backgroundColor
    }
  },
  /**
   * Render the custom element in the editor
   * @param options Options for rendering the custom element
   * @returns The rendered custom element
   * @see CustomElementConfig
   */
  renderView: (options: RenderViewOptions<CategoriesData>) => {
    const { data, onDataChange, editor, element, withStyles, readOnly } =
      options
    return (
      <CategoriesCustomComponent
        editor={editor}
        data={data}
        onDataChange={onDataChange}
        element={element}
        withStyles={withStyles}
        readOnly={readOnly}
      />
    )
  },
}

/**
 * Renders the categories
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.element Root element of component in light DOM.
 * @param props.data The categories data.
 * @param props.onDataChange Callback to update the data.
 * @param props.withStyles Function to wrap children in styles.
 * @param props.readOnly Whether the editor is read-only.
 */
function CategoriesCustomComponent(props: {
  editor: any
  element: HTMLElement
  data: CategoriesData
  onDataChange?: (data: CategoriesData) => void
  withStyles: (children: React.ReactElement) => React.ReactElement
  readOnly: boolean
}) {
  const { data, onDataChange, readOnly } = props
  const [editorOpen, setEditorOpen] = useState(false)

  return (
    <>
      {props.withStyles(
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto auto",
            textAlign: "center",
          }}
        >
          <CategoriesDisplayComponent data={data} />
          {!readOnly && (
            <div key="buttons">
              <IconButton
                onClick={() => setEditorOpen(true)}
                data-cy="open-interactive-elements-editor"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  props.element.remove()
                  props.editor.undo.saveStep()
                }}
                data-cy="delete-interactive-elements-editor"
              >
                <Delete />
              </IconButton>
            </div>
          )}
        </Box>
      )}
      {editorOpen && (
        <CategoriesDialog
          initialData={data}
          onCancel={() => setEditorOpen(false)}
          onSave={(data) => {
            onDataChange!(data)
            setEditorOpen(false)
          }}
        />
      )}
    </>
  )
}

/** Categories component that can be sorted
 * @param props See below.
 * @param props.data Data to display.
 * @returns The rendered component.
 */
function CategoriesDisplayComponent(props: { data: CategoriesData }) {
  const { data } = props

  // Create cards (created from all items within categories)
  const cards = useMemo(() => {
    return data.categories.flatMap((category, categoryIndex) => {
      return category.items.map((item) => {
        return { categoryIndex, item }
      })
    })
  }, [data])

  // Keep track of current card index
  const [currentCardIndex, setCurrentCardIndex] = useState(0)

  // Keep track of number of successful (first time) sorts
  const [successCount, setSuccessCount] = useState(0)

  // Keep track of whether currently failing a sort (an inccorect sort was detected)
  const [failing, setFailing] = useState(false)

  // Keep track of the latest drag result
  const [dragResult, setDragResult] = useState<{
    categoryIndex: number
    result: "correct" | "incorrect"
  }>()

  // Create shuffled indexes of cards as state
  const [shuffledIndexes, setShuffledIndexes] = useState(
    _.shuffle(_.range(cards.length))
  )

  /** Shuffle the cards */
  const shuffle = useStableCallback(() => {
    setShuffledIndexes(_.shuffle(_.range(cards.length)))
  })

  // Reset shuffled indexes when data changes
  useEffect(() => {
    shuffle()
  }, [cards, shuffle])

  /** Reset the exercise */
  const resetExercise = useStableCallback(() => {
    setCurrentCardIndex(0)
    setSuccessCount(0)
    setFailing(false)
    shuffle()
    setDragResult(undefined)
  })

  /** Handle drag start */
  const handleDragStart = useStableCallback((event: DragStartEvent) => {
    setDragResult(undefined)
  })

  /** Handle drag end */
  const handleDragEnd = useStableCallback((event: DragEndEvent) => {
    const { over } = event

    // If not over a droppable, return
    if (!over) {
      return
    }

    const isCorrect =
      over.data.current!.index ===
      cards[shuffledIndexes[currentCardIndex]].categoryIndex

    // If correct, increment current card index
    if (isCorrect) {
      setCurrentCardIndex(currentCardIndex + 1)
      if (!failing) {
        setSuccessCount((successCount) => successCount + 1)
      }
      setFailing(false)
    } else {
      setFailing(true)
    }

    setDragResult({
      categoryIndex: over.data.current!.index,
      result: isCorrect ? "correct" : "incorrect",
    })
  })

  const currentCard = cards[shuffledIndexes[currentCardIndex]]
  const nextCard = cards[shuffledIndexes[currentCardIndex + 1]]

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div style={{ position: "relative", width: 200, height: 220 }}>
            {currentCard && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1,
                }}
              >
                <CardDraggable
                  cardIndex={currentCardIndex}
                  label={currentCard.item}
                  backgroundColor={data.backgroundColor}
                />
              </div>
            )}
            {nextCard && (
              <div style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
                <CardDraggable
                  cardIndex={currentCardIndex + 1}
                  label={nextCard.item}
                  disabled
                  backgroundColor={data.backgroundColor}
                />
              </div>
            )}
            {!currentCard && (
              <div>
                <h3>
                  {successCount}/{cards.length} Cards Correct
                </h3>
                <Button
                  onClick={() => {
                    resetExercise()
                  }}
                  startIcon={<Replay />}
                >
                  Restart
                </Button>
              </div>
            )}
          </div>
        </div>
        <Grid container spacing={2} justifyContent="center">
          {data.categories.map((category, categoryIndex) => (
            <Grid item xs={4} key={categoryIndex} justifyContent="center">
              <CategoryDroppable
                label={category.label}
                categoryIndex={categoryIndex}
                result={
                  dragResult?.categoryIndex === categoryIndex
                    ? dragResult.result
                    : undefined
                }
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </DndContext>
  )
}

/** Card that can be dragged
 * @param props See below.
 * @param props.cardIndex Index of the card.
 * @param props.label Label of the card.
 * @param props.disabled Whether the card is disabled and cannot be dragged.
 * @param props.backgroundColor Background color of the card.
 */
function CardDraggable(props: {
  cardIndex: number
  label: string
  disabled?: boolean
  backgroundColor?: "primary"
}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `draggable-${props.cardIndex}`,
    disabled: props.disabled,
  })

  const { fontSize, ref } = useFitText()

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined

  return (
    <Paper
      data-cy="category-draggable"
      sx={{
        m: 1,
        width: 200,
        height: 150,
        display: "flex",
        flexDirection: "column",
        gridTemplateRows: "1fr",
        justifyContent: "center",
        alignItems: "center",
        cursor: "grab",
        position: "relative",
        backgroundColor: (theme) =>
          props.backgroundColor === "primary"
            ? lighten(theme.palette.primary.main, 0.8)
            : "white",
      }}
      ref={setNodeRef}
      elevation={4}
      {...listeners}
      {...attributes}
      style={style}
    >
      <Box
        data-cy="category-label"
        ref={ref}
        sx={{
          p: 1,
          display: "flex",
          overflow: "hidden",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          fontSize,
        }}
      >
        {props.label}
      </Box>
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          color: "#aaa",
        }}
      >
        <DragHandle />
      </div>
    </Paper>
  )
}

/** Renders a single category that can be dropped on to.
 * @param props See below.
 * @param props.categoryIndex Index of the category.
 * @param props.label Label of the category.
 * @param props.result Result of the drop.
 */
function CategoryDroppable(props: {
  categoryIndex: number
  label: string
  result?: "correct" | "incorrect"
}) {
  const { isOver, setNodeRef } = useDroppable({
    id: `category-${props.categoryIndex}`,
    data: { index: props.categoryIndex },
  })

  // Determine state of card
  let state: CategoryState = "normal"
  if (isOver) {
    state = "hover"
  } else if (props.result === "correct") {
    state = "correct"
  } else if (props.result === "incorrect") {
    state = "incorrect"
  }

  return (
    <Category data-cy="category-dropzone" ref={setNodeRef} state={state}>
      <div
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.25em" }}
      >
        {props.label}
      </div>
      {props.result && (
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: 10,
            left: 0,
            right: 0,
            color: props.result === "correct" ? "green" : "red",
          }}
        >
          <Icon>{props.result === "correct" ? <Check /> : <Clear />}</Icon>
        </div>
      )}
    </Category>
  )
}

/**
 * Create dialog to add a categories component.
 * @param onClose Callback to close the dialog with the html to insert or null if cancelled.
 */
export function createAddCategoriesDialog(
  onClose: (html: string | null) => void
) {
  return (
    <CategoriesDialog
      onSave={(data) => {
        // Create a new section element
        const section = document.createElement("section")
        section.dataset.component = "categories"
        categoriesCustomElement.updateElementFromData(section, data)

        onClose(section.outerHTML)
      }}
      onCancel={() => onClose(null)}
      initialData={{
        categories: [
          {
            label: "Category 1",
            items: ["Item 1", "Item 2"],
          },
          {
            label: "Category 2",
            items: ["Item 3", "Item 4"],
          },
        ],
      }}
    />
  )
}

/**
 * Dialog to edit categories.
 * @param props See below.
 * @param props.initialData Initial data to display.
 * @param props.onCancel Callback when the dialog is cancelled.
 * @param props.onSave Callback when the dialog is saved.
 */
function CategoriesDialog(props: {
  initialData: CategoriesData
  onCancel: () => void
  onSave: (data: CategoriesData) => void
}) {
  const { initialData, onCancel, onSave } = props

  const enableBackground = useFlag("rollout-configurable-interaction-emphasis")

  const [data, setData] = useState<CategoriesData>(initialData)

  // Disable enforce focus to allow Froala image size editing to work
  return (
    <Dialog open={true} maxWidth="lg" fullWidth disableEnforceFocus>
      <DialogTitle>Edit Categories</DialogTitle>
      <DialogContent>
        {enableBackground && (
          <div key="background">
            <FormControlLabel
              control={
                <Switch
                  checked={data.backgroundColor === "primary"}
                  onChange={(event) => {
                    setData(
                      produce(data, (draft) => {
                        draft.backgroundColor = event.target.checked
                          ? "primary"
                          : undefined
                      })
                    )
                  }}
                />
              }
              label="Color Contrast"
            />
          </div>
        )}
        <Grid container spacing={2}>
          {data.categories.map((category, index) => (
            <Grid item xs={6}>
              <CategoryEditComponent
                key={index}
                categoriesData={data}
                onChange={(data) => setData(data)}
                index={index}
              />
            </Grid>
          ))}
        </Grid>
        <div key="add">
          <Button
            sx={{ mt: 1 }}
            onClick={() => {
              setData(
                produce(data, (draft) => {
                  draft.categories.push({
                    label: `Category ${draft.categories.length + 1}`,
                    items: [""],
                  })
                })
              )
            }}
            startIcon={<Add />}
          >
            Add Category
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <AccessibilityWarning />
        <Button key="cancel" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          key="save"
          color="primary"
          variant="contained"
          onClick={() => onSave(data)}
          disabled={data.categories.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/** Props for the CategoryEditComponent */
interface CategoryEditComponentProps {
  categoriesData: CategoriesData
  onChange: (data: CategoriesData) => void
  index: number
}

/**
 * Edit a single category.
 * @param props See below.
 */
function CategoryEditComponent(props: CategoryEditComponentProps) {
  const { categoriesData, onChange, index } = props

  const category = categoriesData.categories[index]

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Grid container gap={2} alignItems="center">
        <Grid item xs>
          <TextField
            label="Category Label"
            value={category.label}
            data-cy="category-editor-label"
            onChange={(e) => {
              onChange(
                produce(categoriesData, (draft) => {
                  draft.categories[index].label = e.target.value
                })
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item>
          <IconButton
            data-cy="editor-delete-button"
            sx={{ float: "right" }}
            onClick={() => {
              onChange(
                produce(categoriesData, (draft) => {
                  draft.categories.splice(index, 1)
                })
              )
            }}
          >
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
      <CategoryItemsComponent
        items={category.items}
        onChange={(items) => {
          onChange(
            produce(categoriesData, (draft) => {
              draft.categories[index].items = items
            })
          )
        }}
      />
    </Paper>
  )
}

/** Props for the CategoryItemsComponent. */
interface CategoryItemsComponentProps {
  /** Items */
  items: string[]

  /** Callback when the HTML is changed.
   * @param items The new items
   */
  onChange: (items: string[]) => void
}

/**
 * Edits a single category items
 *
 * @param props See above.
 */
function CategoryItemsComponent(props: CategoryItemsComponentProps) {
  const { items, onChange } = props

  return (
    <Box sx={{ mt: 2, ml: 2 }}>
      <Stack spacing={2} sx={{ mb: 1 }}>
        {items.map((item, index) => (
          <Grid container gap={2} alignItems="center">
            <Grid item xs>
              <TextField
                label="Item"
                value={item}
                size="small"
                data-cy="category-editor-item"
                onChange={(e) => {
                  onChange(
                    produce(items, (draft) => {
                      draft[index] = e.target.value
                    })
                  )
                }}
                fullWidth
              />
            </Grid>
            <Grid item>
              <IconButton
                data-cy="editor-delete-button"
                sx={{ float: "right" }}
                onClick={() => {
                  onChange(
                    produce(items, (draft) => {
                      draft.splice(index, 1)
                    })
                  )
                }}
              >
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Stack>
      <Button
        sx={{ mt: 1 }}
        onClick={() => {
          onChange(
            produce(items, (draft) => {
              draft.push("")
            })
          )
        }}
        startIcon={<Add />}
      >
        Add Item
      </Button>
    </Box>
  )
}
