import { UploadItem } from "../utilities/fileUpload"
import axiosGlobal from "./axios"

/**
 * @typedef {import("axios").AxiosInstance} AxiosInstance
 *
 * @typedef {object} UploadCourseDocumentConfig
 * @property {AxiosInstance} axios
 */

/**
 * Upload a document for course creation.
 *
 * @param uploadItem The upload item to upload.
 * @param axios The axios instance to use for the upload.
 *
 * @returns The async job for the upload process.
 */
const fetchDocumentHeadings = (
  uploadItem: UploadItem,
  axios = axiosGlobal
): Promise<
  { label: string; level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" }[]
> => {
  const data = new FormData()

  for (const uploadFile of uploadItem.files) {
    data.append("" + uploadFile.index, uploadFile.file as File, uploadFile.name)
  }

  data.append("file_info", JSON.stringify([uploadItem]))

  return axios.post("/documents/headings", data).then(({ data }) => data)
}

export default fetchDocumentHeadings
