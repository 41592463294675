import { createSelector } from "reselect"
import { selectAllJobs } from "./jobsEntityAdaptor"

const selectUploadJobs = createSelector(selectAllJobs, (jobs) => {
  return jobs.filter(
    (job) =>
      job.job_type === "upload" &&
      job.status !== "complete" &&
      job.status !== "canceled" &&
      job.status !== "failed"
  )
})

export const selectCompleteUploads = createSelector(selectAllJobs, (jobs) => {
  return jobs.filter(
    (job) => job.job_type === "upload" && job.status === "complete"
  )
})
export default selectUploadJobs
