/**
 * Configuration for LEAi app notifications.
 *
 * When updates to the async jobs are received, they are tested against the
 * matchers for each rule. If a match is found, a notification is generated from
 * the corresponding template.
 *
 * To dynamically configure the notifications, the template or any of its
 * properties may be defined as a function. This function takes job data then
 * job itself as arguments. The exact shape of the data provided may vary
 * between difference job types.
 *
 * @example
 * ```js
 * {
 *   matcher: { ... },
 *   template: {
 *     message: (data, job) => `Processing "${data.filename}": ${job.status}`,
 *   }
 * }
 * ```
 */

type Notifications = {
  rules: NotificationRule[]
}

type NotificationRule = {
  match: object
  exclude?: (job: any, context: any) => boolean
  template: Notification
}

type Notification = {
  variant: string
  message: string | ((data: any) => string)
  actions?: NotificationAction[]
  autoHideDuration?: number
}

type NotificationAction = {
  label: string
  routeTo?: (data: any) => string
  onClick?: Function
}

const notifications: Notifications = {
  /**
   * The set of rules tested against incoming Async Job updates.
   * @type {NotificationRule[]}
   */

  rules: [
    {
      match: { job_type: "upload", status: "complete" },
      // eslint-disable-next-line jsdoc/require-jsdoc
      exclude: (job, { location }) =>
        isMatchingCoursePage(job?.data?.course_id, location),
      template: {
        variant: "success",
        /** @param data - the job data */
        message: (data) => {
          if (data.filenames.length === 0) {
            return "Course created"
          } else if (data.filenames.length === 1) {
            return `Course created from "${data.filenames[0]}"`
          } else {
            return `Course created from ${data.filenames.length} sources`
          }
        },
        actions: [
          {
            label: "View",
            /** @param data - the job data */
            routeTo: (data: any) => `/editor?course=${data.course_id}`,
          },
        ],
      },
    },
    {
      match: { job_type: "upload", status: "failed" },
      template: {
        variant: "failure",
        /** @param data - the job data */
        message: (data) => {
          if (data.failed_filename != null && data.failed_filename !== "") {
            return `Course creation failed for "${data.failed_filename}"`
          } else {
            return `Course creation failed.`
          }
        },
      },
    },
    {
      match: { job_type: "upload", status: "complete" },
      /** @param job - the job */
      exclude: (job: any) => !job?.data?.warnings?.length,
      template: {
        variant: "warning",
        /** @param data - the job data */
        message: (data: any) =>
          data.warnings.map((warning: any) => {
            const truncatedFilename =
              warning.filename.length > 120
                ? warning.filename.substring(0, 120) + "..."
                : warning.filename
            return `We could not process the file "${truncatedFilename}". ${warning.message}`
          }),
        actions: [
          {
            label: "View History",
            /** @param data - the job data */
            routeTo: (data) => {
              const params = new URLSearchParams({
                page: "0",
                limit: "20",
                filterCourse: data.course_id,
                filterAction: "course_created",
                back: "edit",
                courseId: data.course_id,
                title: data.title,
              })
              return `/history?${params.toString()}`
            },
          },
        ],
        autoHideDuration: 10000, // leave warnings up for 10 seconds
      },
    },
  ],
}

export default notifications

/**
 * Return true if we are on teh course page
 * @param courseId course id
 * @param location browser location
 */
const isMatchingCoursePage = (courseId: string, location: any) =>
  location.pathname === "/editor" && location?.query?.course === courseId
