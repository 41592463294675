import "./addDragDropToFroala.css"

/**
 * Drag and drop functionality to re-arrange root blocks in Froala.
 * Shows a drag handle on the left side of each block when the mouse is over it.
 * When the drag handle is clicked, the block can be dragged around, and a blue
 * line will show where the block will be inserted when the mouse is released.
 *
 * If a header is dragged, all subsequent blocks until the next header of the
 * same or lower level will be dragged as well.
 *
 * @param editor The Froala editor instance
 * @param editorOuterDiv The outer div of the Froala editor (fr-wrapper)
 */
export function addDragDropToFroala(
  editor: any,
  editorOuterDiv: HTMLElement
): () => void {
  // Get the inner div of the Froala editor (fr-element)
  const editorInnerDiv = editorOuterDiv.querySelector(
    ".fr-element"
  ) as HTMLElement

  /** Drag handle that appears to the left when an element is hovered over, if visible */
  let dragHandle: HTMLElement | null = null

  /** Element that is draggable because it can be dragged and the mouse is over it */
  let draggableElement: HTMLElement | null = null

  /** Semi-opaque rectangle that appears overtop of the drag element when being dragged
   * to show that it is being dragged.
   */
  let coverupElement: HTMLElement | null = null

  /** Blue drag bar that appears when an element is being dragged, if visible */
  let dragbarElement: HTMLElement | null = null

  /** Elements that are actively being dragged */
  let dragElements: HTMLElement[] | null = null

  /** Element that the drag element is currently over, if any */
  let dragOverElement: HTMLElement | null = null

  /** Position of the drag element relative to the drag over element */
  let dragOverPosition: "before" | "after" | null = null

  /** Last mouse position of the drag in client X-position */
  let dragClientX: number | null = null

  /** Last mouse position of the drag in client Y-position */
  let dragClientY: number | null = null

  /**
   * Set/reset the current draggable element, adding a drag handle if draggable
   * @param elem The element to set as the drag element, or null to reset
   */
  function setDraggableElement(elem: HTMLElement | null) {
    // Do nothing if the element is already the drag element
    if (draggableElement === elem) return

    // Ignore smart template controls, as they can"t be dragged
    if (elem && elem.dataset.smartTemplateControl) return

    // Set the draggable element
    draggableElement = elem

    // Remove the drag handle if it exists
    if (dragHandle) {
      dragHandle.remove()
      dragHandle = null
    }

    // If an element is draggable, add the drag handle
    if (draggableElement) {
      // Create the drag handle
      dragHandle = document.createElement("div")

      // Add the mui icon manually
      dragHandle.innerHTML = `
        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DragIndicatorIcon">
          <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
        </svg>`
      dragHandle.classList.add("froala-drag-handle")

      // Set top to be halfway down the element
      dragHandle.style.top = `${
        draggableElement.offsetTop + draggableElement.offsetHeight / 2 + 3
      }px`
      editorOuterDiv.appendChild(dragHandle)
    }
  }

  /**
   * Handle mousedown events which start the drag
   * @param e The mouse event
   */
  function handleMousedown(e: MouseEvent) {
    // Check the drag handle is being clicked
    const isDragHandle = e.composedPath().some((elem) => {
      return elem instanceof HTMLElement && elem === dragHandle
    })

    // Return if not
    if (!isDragHandle) {
      return
    }

    // Do not process click
    e.preventDefault()
    e.stopPropagation()

    // Start dragging by creating list of drag elements. This is just the element
    // unless it is a heading, in which case it is the element and the next elements
    // until the next heading of a lower level.
    const draggableTagName = draggableElement!.tagName
    if (draggableTagName.match(/^H[1-6]$/)) {
      const headingLevel = parseInt(draggableTagName[1])
      dragElements = [draggableElement!]
      let nextElement = draggableElement!.nextElementSibling
      while (nextElement) {
        if (nextElement.tagName.match(/^H[1-6]$/)) {
          const nextHeadingLevel = parseInt(nextElement.tagName[1])
          if (nextHeadingLevel <= headingLevel) break
        }
        dragElements.push(nextElement as HTMLElement)
        nextElement = nextElement.nextElementSibling
      }
    } else {
      dragElements = [draggableElement!]
    }

    // Remove the drag handle
    dragHandle!.remove()
    dragHandle = null

    // Set the overall cursor to grabbing
    editorInnerDiv.style.cursor = "grabbing"

    // Create the dragbar element
    dragbarElement = document.createElement("div")
    dragbarElement.classList.add("froala-drag-bar")
    dragbarElement.style.top = `${dragElements[0].offsetTop}px`
    dragbarElement.style.left = `${dragElements[0].offsetLeft}px`
    dragbarElement.style.width = `${dragElements[0].offsetWidth}px`
    editorOuterDiv.appendChild(dragbarElement)

    // Create the coverup element that makes the drag element semi-transparent
    coverupElement = document.createElement("div")
    coverupElement.classList.add("froala-drag-coverup")
    coverupElement.style.top = `${dragElements[0].offsetTop}px`
    coverupElement.style.left = `${dragElements[0].offsetLeft}px`
    coverupElement.style.width = `${dragElements[0].offsetWidth}px`
    coverupElement.style.height = `${
      dragElements[dragElements.length - 1].offsetTop +
      dragElements[dragElements.length - 1].offsetHeight -
      dragElements[0].offsetTop
    }px`
    editorOuterDiv.appendChild(coverupElement)

    // Start by dragging over self
    dragOverElement = dragElements[0]
    dragOverPosition = "before"

    // Keep track of animation request to be able to cancel
    let dragAnimation: number | null = null

    /** Mouseup listener that ends the drag
     * @param e mouse event
     */
    const handleMouseup = (e: MouseEvent) => {
      // Remove dragbar and coverup elements
      dragbarElement!.remove()
      dragbarElement = null
      coverupElement!.remove()
      coverupElement = null

      // Reset cursor
      editorInnerDiv.style.cursor = "inherit"

      // Stop animation
      cancelAnimationFrame(dragAnimation!)

      // Remove mouseup listener
      document.removeEventListener("mouseup", handleMouseup)

      // Perform drag operation
      if (dragElements) {
        // Put combined HTML of drag element before or after drag over element
        if (dragOverElement && dragOverPosition) {
          if (dragOverPosition === "before") {
            dragOverElement.insertAdjacentHTML(
              "beforebegin",
              dragElements.map((elem) => elem.outerHTML).join("")
            )
          } else {
            dragOverElement.insertAdjacentHTML(
              "afterend",
              dragElements.map((elem) => elem.outerHTML).join("")
            )
          }
          // Remove original drag element
          dragElements.forEach((elem) => elem.remove())
        }
        editor.undo.saveStep()
        dragElements = null
      }
    }

    /** Repeated every animation frame to perform scrolling and update dragbar */
    const animateDrag = () => {
      // Repeat again
      dragAnimation = requestAnimationFrame(animateDrag)

      // Hide the dragbar if the mouse is not over the editor
      if (
        dragClientY == null ||
        dragClientY < editorOuterDiv.getBoundingClientRect().top ||
        dragClientY > editorOuterDiv.getBoundingClientRect().bottom ||
        dragClientX == null ||
        dragClientX < editorOuterDiv.getBoundingClientRect().left ||
        dragClientX > editorOuterDiv.getBoundingClientRect().right
      ) {
        dragbarElement!.style.display = "none"
        return
      }

      // Scroll the editor if the mouse is near the top or bottom of the editor
      const editorRect = editorOuterDiv.getBoundingClientRect()

      // Pixels per animation frame
      const scrollSpeed = 10
      if (dragClientY < editorRect.top + 50) {
        editorOuterDiv.scrollTop -= scrollSpeed
      } else if (dragClientY > editorRect.bottom - 50) {
        editorOuterDiv.scrollTop += scrollSpeed
      }

      // Get the root element that is being hovered over. Pick from the middle of the editor
      // so that element is selected
      let hoverElem = document.elementFromPoint(
        editorOuterDiv.getBoundingClientRect().left +
          editorOuterDiv.getBoundingClientRect().width / 2,
        dragClientY
      )
      while (hoverElem && hoverElem.parentElement !== editorInnerDiv) {
        hoverElem = hoverElem.parentElement
      }

      // If no hover element
      if (!hoverElem) {
        // Leave last element selected
        return
      }

      // If hovering over an element that is not being dragged
      if (
        hoverElem instanceof HTMLElement &&
        dragElements &&
        !dragElements.includes(hoverElem)
      ) {
        // Show the dragbar
        dragbarElement!.style.display = "block"
        dragOverElement = hoverElem

        // Determine if over the top or bottom half of the element
        dragOverPosition =
          dragClientY - dragOverElement.getBoundingClientRect().top <
          dragOverElement.offsetHeight / 2
            ? "before"
            : "after"
        dragbarElement!.style.top = `${
          dragOverElement.offsetTop +
          (dragOverPosition === "before" ? 0 : dragOverElement.offsetHeight)
        }px`
      }
    }

    // Add mouseup listener
    document.addEventListener("mouseup", handleMouseup)

    // Start animation
    dragAnimation = requestAnimationFrame(animateDrag)
  }

  /**
   * Handle mouse leaving the editor
   * @param e Mouse event
   */
  function handleMouseleave(e: MouseEvent) {
    // Update client position to null
    dragClientX = null
    dragClientY = null

    // No element being dragged (if not already dragging)
    if (!dragElements) {
      setDraggableElement(null)
    }
  }

  /**
   * Handle mouse moving over the editor
   * @param e Mouse event
   */
  function handleMousemove(e: MouseEvent) {
    // Update client position
    dragClientX = e.clientX
    dragClientY = e.clientY

    // If not dragging, update drag handle and drag element
    if (dragElements) {
      return
    }

    // Get the root element that is being hovered over
    let hoverElem = document.elementFromPoint(
      editorOuterDiv.getBoundingClientRect().left +
        editorOuterDiv.getBoundingClientRect().width / 2,
      dragClientY
    )
    while (hoverElem && hoverElem.parentElement !== editorInnerDiv) {
      hoverElem = hoverElem.parentElement
    }
    if (hoverElem instanceof HTMLElement) {
      setDraggableElement(hoverElem)
    }
  }

  // Add listeners
  editorOuterDiv.addEventListener("mousedown", handleMousedown)
  editorOuterDiv.addEventListener("mouseleave", handleMouseleave)
  editorOuterDiv.addEventListener("mousemove", handleMousemove)

  // Return a function to remove the event listeners
  return () => {
    editorOuterDiv.removeEventListener("mousedown", handleMousedown)
    editorOuterDiv.removeEventListener("mouseleave", handleMouseleave)
    editorOuterDiv.removeEventListener("mousemove", handleMousemove)
  }
}
