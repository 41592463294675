import { Redirect, useLocation } from "react-router"
import IntelligentUpdateScreen from "../components/screens/IntelligentUpdateScreen"

function IntelligentUpdatePage() {
  const location = useLocation()

  const course = {
    id: location.state.id,
    title: location.state.title,
  }

  return (
    <>
      {!course.id && <Redirect to="/" />}
      <IntelligentUpdateScreen course={course} />
    </>
  )
}

export default IntelligentUpdatePage
