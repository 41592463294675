import countWords from "./countWords"
import estimateContentDuration from "./estimateContentDuration"
import findBlockElements from "./findBlockElements"
import isHeading from "./isHeading"

const TEST_QUESTION_SELECTORS = [
  "multiple-choice-question",
  "true-false-question",
  "fill-in-the-blank-question",
  "matching-question",
]

const SELECTOR_VIDEO_ELEMENTS = [
  'iframe[src*="youtube.com"]',
  'iframe[src*="player.vimeo.com"]',
].join(",")

/**
 * @typedef {{
 *  sectionCount: number,
 *  wordCount: number,
 *  videoCount: number,
 *  imageCount: number,
 *  duration: Promise<import("luxon").Duration>
 * }} DocumentStats
 */

/**
 * Collect reading statistics for a document or section thereof.
 *
 * @param {Element} container The document container.
 * @param {Element} section An optional section of the document.
 * @returns {DocumentStats}
 */
const getDocumentStats = (container, section = container) => {
  let sectionCount = 0
  let wordCount = 0
  let questionCount = 0
  let questionWordCount = 0
  let videos = []
  let images = []

  for (const element of findBlockElements(container, section)) {
    if (isHeading(element) && element !== section) {
      sectionCount += 1
    }

    const elementWordCount = countWords(element)

    /**
     * If the tagName is SECTION and data-component attribute is a question type, we have traversed into a Test Question element.
     */
    if (
      element.tagName === "SECTION" &&
      TEST_QUESTION_SELECTORS.includes(element.dataset.component)
    ) {
      questionCount += 1
      questionWordCount += elementWordCount
    }
    wordCount += elementWordCount
    videos = [...videos, ...findVideoPlayers(element)]
    images = [...images, ...findImages(element)]
  }

  return {
    sectionCount,
    wordCount,
    questionCount,
    videoCount: videos.length,
    imageCount: images.length,
    duration: estimateContentDuration({
      wordCount,
      videos,
      images,
      questionCount,
      questionWordCount,
    }),
  }
}

export default getDocumentStats

/**
 * Find any videos within the given element.
 *
 * @param {Element} element
 * @returns {Element[]}
 */
const findVideoPlayers = (element) =>
  element.matches(SELECTOR_VIDEO_ELEMENTS)
    ? [element]
    : [...element.querySelectorAll(SELECTOR_VIDEO_ELEMENTS)]

/**
 * Find any images within the given element.
 *
 * @param {Element} element
 * @returns {Element[]}
 */
const findImages = (element) =>
  element.matches("img") ? [element] : [...element.querySelectorAll("img")]
