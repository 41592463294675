import { featureDecisions } from "../utilities/features"

/** Metadata of each type of detection. The detection types in this structure are not necessarily enabled.
 * See RemarksTypeFilter for the list of enabled detections in the filter.
 */
const detectionsConfig = {
  detections: {
    first_person: {
      title: "First Person",
      description: `
        Writing in the second or third person creates an appropriate sense of
        formality between the reader and the writer. Consider converting to
        second or third person.
      `,
    },
    passive_voice: {
      title: "Passive Voice",
      description: `
        Active voice makes your meaning clear and keeps sentences from becoming
        too complicated or wordy. This sentence uses passive voice; consider
        using active voice.
      `,
    },
    interactive_component: {
      title: "Interaction",
      description: `
        Interactions engage learners in different and interesting ways to help with retention. Consider transforming this text into an interaction.
     `,
    },
    ...(featureDecisions.enableInstructionsDetector() && {
      instruction: {
        title: "Instruction Text",
        description: `
        Instructions are better formatted as a table, bulleted or numbered list.
        Consider converting this text to a list of instructions.
     `,
      },
    }),
    ...{
      // LD-1219: Temporarily remove Too Much Text detection. Keep it commented out to be reintroduced later.
      // too_much_text: {
      //   title: "Too Much Text",
      //   description: `
      //   Research shows that visual media makes concepts more accessible to a
      //   person than text alone and helps with later recall. Consider adding
      //   media, tables or bulleted lists to break  up this portion of text.
      // `,
      // },
      task_based: {
        title: "Task-Based Heading",
        description: `
        Task-based headings make it clear what is contained in the section,
        which helps set the stage for the learner and makes navigation easier.
        Consider changing this heading to reflect the tasks covered in the
        section. The heading should include an action verb such as
        understanding, building, viewing, etc.
      `,
      },

      //LD-606 -Learning Objectives Comment out detection
      // learning_objective: {
      //   title: "Learning Objective",
      //   description: `
      //     Learning objectives are the skills or knowledge you want learner to leave with.
      //   `,
      // },
    },
  },

  //transform actions to suppress in the UI
  //e.g. '"task_based" will suppress the task_based transform
  transformBlacklist: [],
}

export default detectionsConfig
