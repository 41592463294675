import { ThemeProvider } from "@mui/material"
import muiTheme from "../../themes/theme"
import Authenticator from "./components/Authenticator"
import BrandMark from "./components/BrandMark"
import Layout from "./components/Layout"
import Terms from "./components/Terms"
import { extendTheme } from "./utils/theme"

const isProduction = process.env.NODE_ENV === "production"

/**
 * Provides sign-in and authentication functionality.
 */
function SignInScreen() {
  return (
    <ThemeProvider theme={extendTheme(muiTheme)}>
      <Layout>
        <BrandMark />
        <Authenticator />
        {isProduction && <Terms />}
      </Layout>
    </ThemeProvider>
  )
}

export default SignInScreen
