import { LDProvider, useLDClient } from "launchdarkly-react-client-sdk"
import { ReactNode } from "react"
import { CircularProgress, Box, Typography } from "@mui/material"

type FlagProviderProps = {
  // The wrapped react node.
  children: ReactNode
}

// The Launch-Darkly client-side access ID. Defaults to the QA environment key.
const CLIENT_SIDE_ID =
  process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID ?? "645ce67f6ad4e8130f9bcd25"

/**
 * Provides the current feature configuration context.
 *
 * The provider will listen for any updates to the remote configuration and
 * update the context as changes become available.
 *
 * @param props Props.
 */
function FlagProvider(props: FlagProviderProps) {
  return (
    <LDProvider
      clientSideID={CLIENT_SIDE_ID}
      options={{
        // Cache the latest flag configuration in local-storage to speed up
        // configuration on return visits. There may be some flicker once the
        // latest values are retrieved.
        bootstrap: "localStorage",
      }}
      reactOptions={{ useCamelCaseFlagKeys: false }}
    >
      <LaunchDarklyWrapper>{props.children}</LaunchDarklyWrapper>
    </LDProvider>
  )
}

function LaunchDarklyWrapper({ children }: { children: ReactNode }) {
  const ldClient = useLDClient()

  if (!ldClient) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: 16 }}>
          Loading...
        </Typography>
      </Box>
    )
  }

  return <>{children}</>
}

export default FlagProvider
