import axiosInstance from "../../../api/axios"
import { IEditorFont } from "../hooks/useBranding"

/**
 * Check if providd font URL points to the CDN for any of our environments
 * @param url - Font URL provided
 */
const isCustomFontURL = (url: string) => url.match(/cdn\..*\.learnexperts/)

/**
 * Given a font's name and URL, create a new font face and add it to the browser for use.
 * If the font URL points to our CDN, we can't simply load the URL into the FontFace
 * constructor, so it has to be fetched first using axios and loaded as a local URL
 * into the browser.
 * @param font - Font object to load into editor
 */
const loadFontFace = async (font: IEditorFont) => {
  const { name, url } = font
  if (!url || !name) {
    return
  }

  if (isCustomFontURL(url)) {
    try {
      /**
       * If the font is a custom one stored on our CDN, we need to manually fetch it
       * and create a local URL, then inject the local URL as a fontface
       */
      const response = await axiosInstance.get(url, {
        responseType: "blob",
      })
      const localUrl = URL.createObjectURL(response.data)
      const font = new FontFace(name, `url(${localUrl})`)
      document.fonts.add(font)
    } catch (error) {
      console.error("Error fetching font:", error)
    }
  } else {
    const font = new FontFace(name, `url(${url})`)
    document.fonts.add(font)
  }
}

export default loadFontFace
