import { CircularProgress, Stack, styled, Typography } from "@mui/material"
import Remark from "./Remark"
import Comment from "./Comment"
import useRemarks from "../../../hooks/useRemarks"
import AddCommentButton from "./AddCommentButton"
import PendingComment from "./PendingComment"
import useCommentsDispatch from "../../../hooks/useCommentsDispatch"
import Detection from "../Detection/Detection"
import DetectionActions from "../Detection/DetectionActions"
import { useHighlightLocation } from "../../../hooks/useHighlightLocation"
import useRemarksFilter, {
  useRemarksFilterValue,
} from "../../../hooks/useRemarksFilter"
import useScrollRemarksToPath from "../../../hooks/useScrollRemarksToPath"
import useRemarksCount from "../../../hooks/useRemarksCount"
import { memo } from "react"
import { useSelector } from "react-redux"
import { selectRemarksScrollLocked } from "../../../store/editor/selectors"
import useEditorDispatch from "../../../store/editor/useEditorDispatch"

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  flexGrow: 1,
  flexShrink: 1,
  overflowY: "auto",
}))

const CenterContent = styled(Stack)({
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  flexShrink: 1,
})

// TODO: the colour will determined by the detection control context toggling between resolved and unresolved
const CONTEXT_COLORS = {
  comment: () => "addition",
  detection: () => "primary",
}

const CONTEXT_RENDERERS = {
  comment: (item, readOnly) => (
    <Comment comment={item.id} readOnly={readOnly} />
  ),
  detection: (item) => <Detection detection={item.id} />,
  pendingComment: () => <PendingComment />,
}

const getItemColor = (item) => {
  const getColor = CONTEXT_COLORS[item.remarkType] ?? (() => null)
  return getColor(item)
}

const getItemActions = (item) => {
  if (item.remarkType === "detection") {
    return <DetectionActions detection={item.id} />
  }
}

const renderItem = (item, readOnly) => {
  const render = CONTEXT_RENDERERS[item.remarkType] ?? (() => null)
  return render(item, readOnly)
}

export const RemarkFilterCount = () => {
  const { loading, ids: remarks } = useRemarks()
  const { resolved: filterResolved } = useRemarksFilterValue()
  const numUnresolved = useRemarksCount()
  const { type } = useRemarksFilter()

  return (
    <CenterContent>
      {!loading && !filterResolved && type !== "__all" && remarks.length > 0 && (
        <Typography textAlign="center" color="text.secondary" variant="caption">
          {remarks.length}/{numUnresolved}
        </Typography>
      )}
    </CenterContent>
  )
}

const RemarkList = ({ readOnly }) => {
  const { ids: remarks, loading } = useRemarks()
  const numUnresolved = useRemarksCount()

  const { showPendingComment } = useCommentsDispatch()

  // Scroll to the path if it is set
  useScrollRemarksToPath(remarks)

  const { highlightLocation, highlightElement } = useHighlightLocation()

  const { resolved: filterResolved } = useRemarksFilterValue()

  return (
    <Container data-cy="remarks-list">
      <AddCommentButton
        onClick={() => showPendingComment()}
        variant="outlined"
      />
      {!loading && (
        <RemarkListContent
          remarks={remarks}
          numUnresolved={numUnresolved}
          filterResolved={filterResolved}
          highlightLocation={highlightLocation}
          readOnly={readOnly}
        />
      )}
      {loading && (
        <Stack flexGrow={1} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      )}
      {highlightElement}
    </Container>
  )
}

/** Memoized remark list contents that do not change when scrollpath changes */
const RemarkListContent = memo((props) => {
  const {
    remarks,
    filterResolved,
    highlightLocation,
    numUnresolved,
    readOnly,
  } = props

  const isEmpty = remarks.length === 0

  const remarksScrollLocked = useSelector(selectRemarksScrollLocked)
  const { setRemarksScrollLocked } = useEditorDispatch()

  return (
    <>
      {isEmpty && (
        <CenterContent>
          <Typography
            textAlign="center"
            color="text.secondary"
            variant="caption"
          >
            No detections.
          </Typography>
          {numUnresolved !== 0 && !filterResolved && (
            <Typography
              textAlign="center"
              color="text.secondary"
              variant="caption"
            >
              Expand your filters to see more.
            </Typography>
          )}
        </CenterContent>
      )}
      {[...remarks].map((item) => {
        return (
          <Remark
            key={item.id}
            id={item.id}
            color={filterResolved ? "text.secondary" : getItemColor(item)}
            actions={getItemActions(item)}
            onClick={() => {
              if (remarksScrollLocked === false) {
                setRemarksScrollLocked(true)
              }

              if (item.location) {
                highlightLocation(item.location)
              }
            }}
          >
            {renderItem(item, readOnly)}
          </Remark>
        )
      })}
    </>
  )
})

export default memo(RemarkList)
