import { Box } from "@mui/material"
import { Reorder } from "framer-motion"
import React, { useState } from "react"
import FileContainer from "./FileContainer"
import { styled } from "@mui/system"
import { UploadItem } from "../../utilities/fileUpload"

const StyledArea = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.lexWhite,
  width: "100%",
  border: `1px solid ${theme.palette.lexLightGrey}`,
  paddingLeft: 9,
  paddingRight: 9,
}))

interface FileUploadListProps {
  /** list of file names */
  fileNames: string[]
  /** upload items state */
  uploadItems: UploadItem[]
  /** upload items state */
  setUploadItems: (uploadItems: UploadItem[]) => void
  /** file names state */
  setFileNames: (fileNames: string[]) => void
  /** error state */
  setError: (error: string | null) => void

  onGetHeadings: (uploadItem: UploadItem, options: any) => any[]
}

/**
 * Displays all currently uploaded files and allows sorting and selection/deselection.
 * @param props props
 */
const FileUploadList = (props: FileUploadListProps) => {
  const {
    fileNames,
    uploadItems,
    setUploadItems,
    setFileNames,
    setError,
    onGetHeadings,
  } = props

  const [isDragging, setIsDragging] = useState(false)

  /**
   * Update name list on drop.
   * @param nameList - reorder list
   */
  const handleReorderEnd = (nameList: string[]): void => {
    // To prevent the drop from clicking the checkbox we wait for the drop and animation to finish.
    // It would be better if framer motion exposed start/end events, but it doesn't appear to.
    setIsDragging(true)
    updateFileNames(nameList)
    setTimeout(() => setIsDragging(false), 1000)
  }

  /**
   * Find the upload item by name.
   * @param fileName - the name of teh upload item to find
   */
  const findUploadItemByName = (fileName: string): UploadItem | undefined =>
    uploadItems.find((file) => file.displayName === fileName)

  /**
   * Swap a file with the next file.
   * @param index - the index of the file to swap
   */
  const swapFileWithNext = (index: number): void => {
    if (index < fileNames.length - 1 && index >= 0) {
      // Swap the file names in place
      ;[fileNames[index], fileNames[index + 1]] = [
        fileNames[index + 1],
        fileNames[index],
      ]

      setFileNames([...fileNames])
    }
  }

  /**
   * Update the list of file naes.
   * @param nameList - The updated list
   */
  const updateFileNames = (nameList: string[]): void =>
    setFileNames([...nameList])

  /**
   * Update the upload items (indicates that have been mutated directly)
   */
  const onUpdateUploadItems = (): void => {
    setUploadItems([...uploadItems])
  }

  /**
   * Move a file up, when an arrow key is used to move a file.
   * @param file - file to move
   */
  const onMoveUp = (file: string): void => {
    let index = fileNames.indexOf(file)
    swapFileWithNext(index - 1)
  }

  /**
   * Move a file down, when an arrow key is used to move a file.
   * @param file - file to move
   */
  const onMoveDown = (file: string): void => {
    let index = fileNames.indexOf(file)
    swapFileWithNext(index)
  }

  return (
    <StyledArea>
      <Reorder.Group
        axis="y"
        as="div"
        values={fileNames}
        onReorder={handleReorderEnd}
      >
        {fileNames.map((fileName, index) => (
          <Reorder.Item
            as="div"
            key={fileName}
            value={fileName}
            transition={{ duration: 0 }} // This prevents weird animations when headings open/close
          >
            <FileContainer
              onUpdateUploadItem={onUpdateUploadItems}
              onGetHeadings={onGetHeadings}
              uploadItem={findUploadItemByName(fileName)!}
              fileName={fileName}
              isDragging={isDragging}
              allowDrag={fileNames.length > 1}
              setError={setError}
              onMoveUp={onMoveUp}
              onMoveDown={onMoveDown}
              last={index === fileNames.length - 1}
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </StyledArea>
  )
}

export default FileUploadList
