import axiosInstance from "./axios"

/**
 * Retrieve all current branding configuration data for the user.
 */
const fetchBranding = () => {
  return axiosInstance.get(`/branding`).then((response) => response.data)
}

export default fetchBranding
