import {
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material"
import { motion } from "framer-motion"
import useValidationError from "../hooks/useValidationError"
import { useFieldValues } from "../contexts/FormPersistenceProvider"

/**
 * Renders an input field for user email.
 */
export function EmailField() {
  const error = useValidationError("username")
  const [value, setValue] = useFieldValues("username")

  return (
    <FormControl
      layoutId="username"
      error={!!error}
      component={motion.div}
      layout="position"
      variant="filled"
    >
      <InputLabel htmlFor="username">Email</InputLabel>
      <FilledInput
        id="username"
        name="username"
        autoComplete="username"
        aria-describedby={error ? "username-error" : undefined}
        value={value}
        onChange={(e) => {
          setValue(e.currentTarget.value)
        }}
      />
      {error && <FormHelperText id="username-error">{error}</FormHelperText>}
    </FormControl>
  )
}

export default EmailField
