import { generateEmbedSource } from "./urlUtils"
import { flushSync, render } from "react-dom"

/**
 * Generate string of HTML IFrame element containing the embed code for the provided URL
 * @param url - URL of video to insert
 */
const createIFrame = (url: string) => {
  const src = generateEmbedSource(url)

  if (!src) {
    return ""
  }
  const div = document.createElement("div")

  flushSync(() => {
    render(
      <span
        contentEditable="false"
        draggable="true"
        className="fr-video fr-deletable fr-draggable"
        style={{ display: "block", clear: "both", textAlign: "center" }}
      >
        <iframe
          src={src}
          title={url}
          width={640}
          height={360}
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </span>,
      div
    )
  })

  const iframeHTML = div.innerHTML
  return iframeHTML
}

export default createIFrame
