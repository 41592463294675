import { createInsertImageDialog } from "../features/insertImage/InsertImageDialog"
import Froalaeditor from "froala-editor"
import { selectAfterText } from "../utilities/domUtils"

// Define icon for our custom image insert button
Froalaeditor.DefineIcon("showImageDialog", {
  SVG_KEY: "insertImage",
})

/**
 * Register custom command to show Image Insert dialog instead of the built-in Froala one.
 * @param displayReactElement - Function to render edit Dialog box
 */
const registerImageCommands = (displayReactElement: any) => {
  Froalaeditor.RegisterCommand("showImageDialog", {
    title: "Insert Image",
    icon: "showImageDialog",
    focus: true,
    undo: true,
    shortcut: ["CMD+P", "CTRL+P"],
    callback: function () {
      const selection = this.selection.get() as any
      const anchorElement = selection.anchorNode
      anchorElement.innerHTML = "<br/>"

      displayReactElement((onClose: () => void) =>
        createInsertImageDialog((url: string | null) => {
          onClose()
          if (url) {
            // Use Froala's built-in method to insert the image
            selectAfterText(anchorElement, this)
            this.image.insert(url, true, { link: url }, null)

            const firstChild = anchorElement.firstChild

            // Remove the initial linebreak once the image is inserted
            if (firstChild && firstChild.tagName === "BR") {
              anchorElement.removeChild(firstChild)
            }
          }
        })
      )
    },
  })
}

export default registerImageCommands
