import { forwardRef, memo } from "react"
import FroalaEditor from "react-froala-wysiwyg"
import PropType from "prop-types"
import CircularProgress from "@mui/material/CircularProgress"

import defaultUseEditorConfig from "./useEditorConfig"
import { Stack } from "@mui/material"

import "froala-editor/css/froala_style.min.css"
import "froala-editor/css/froala_editor.pkgd.min.css"
import "froala-editor/js/plugins.pkgd.min.js"
import "froala-editor/css/third_party/image_tui.min.css"
import "froala-editor/js/third_party/image_tui.min.js"
import useEditorDispatch from "../../../store/editor/useEditorDispatch"
import EditorWrapper from "./EditorWrapper"
import { useFlag } from "../../../utilities/feature-management"

/**
 * Editor renders the interface for editing course documents.
 */
const Editor = memo(
  forwardRef((props, ref) => {
    const {
      model,
      readOnly,
      onModelChange,
      onInitialize,
      onDestroy,
      useEditorConfig,
      extraConfig,
      updateDocument,
      branding,
    } = props
    const config = useEditorConfig({
      ...extraConfig,
      onInitialize,
      onDestroy,
      readOnly,
      updateDocument,
      branding,
    })

    const enableBrandingInEditor = useFlag("rollout-branding-styles-in-editor")

    const loading =
      model === null || (enableBrandingInEditor && branding === null)

    const { setRemarksScrollLocked } = useEditorDispatch()

    return (
      <>
        {loading && (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Stack>
        )}
        {!loading && config && (
          <EditorWrapper
            branding={branding}
            onClick={() => setRemarksScrollLocked(false)}
          >
            <FroalaEditor
              id="froala-editor"
              tag="textarea"
              ref={ref}
              config={config}
              model={model}
              onModelChange={onModelChange}
            />
          </EditorWrapper>
        )}
      </>
    )
  })
)

Editor.propTypes = {
  /** The current document model. */
  model: PropType.string,
  /** Called when the editor is ready for the user. */
  onInitialize: PropType.func,
  /** Called when the editor is being destroyed. */
  onDestroy: PropType.func,
  /** Called when the document model changes. */
  onModelChange: PropType.func,
  /** Interface for injecting custom useEditorConfig hook.
   * @ignore
   */
  useEditorConfig: PropType.func.isRequired,
}

Editor.defaultProps = {
  model: null,
  useEditorConfig: defaultUseEditorConfig,
}

export default Editor
