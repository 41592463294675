import React from "react"
import TrashedCoursesGridItem from "./TrashedCoursesGridItem"
import { Grid } from "@mui/material"
import CoursesGridSkeleton from "../../components/widgets/CoursesGridSkeleton"

/**
 * TrashedCoursesGrid function
 * @param props props
 * @param props.filteredDocuments filteredDocuments
 * @param props.onDelete onDelete
 * @param props.onRestore onRestore
 * @param props.isLoading isLoading
 */
const TrashedCoursesGrid = (props: {
  filteredDocuments: any
  onDelete: any
  onRestore: any
  isLoading: any
}) => {
  const { filteredDocuments, onDelete, onRestore, isLoading } = props

  return (
    <Grid data-cy="courses-display" container spacing={8}>
      {!isLoading
        ? filteredDocuments.map(
            (document: { id: React.Key | null | undefined }) => {
              if (document.id != null) {
                return (
                  <TrashedCoursesGridItem
                    key={document.id}
                    document={document}
                    onDelete={onDelete}
                    onRestore={onRestore}
                  />
                )
              } else {
                console.log("WAAA", document)
                return ""
              }
            }
          )
        : [...Array(6)].map((i, index) => <CoursesGridSkeleton key={index} />)}
    </Grid>
  )
}

export default TrashedCoursesGrid
