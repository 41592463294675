import { useEffect, useMemo, useRef } from "react"
import { getScrollPane, scrollIntoView } from "../utilities/domUtils"
import debounce from "lodash/fp/debounce"
import useCommentsDispatch from "./useCommentsDispatch"
import { useSelector } from "react-redux"
import selectCommentsUser from "../store/remarks/selectors/selectCommentsUser"
import { selectPendingComment } from "../store/remarks/selectors/selectPendingComment"

const usePendingComment = () => {
  const debouncedScrollIntoView = useMemo(
    () => debounce(250, scrollIntoView),
    []
  )
  const { discardPendingComment, addComment } = useCommentsDispatch()
  const pendingComment = useSelector(selectPendingComment)
  const commentInputRef = useRef(null)

  // Whenever the pendingComment location changes, scroll to correct position
  useEffect(() => {
    // Re-focus onto the comment input
    if (commentInputRef.current) {
      commentInputRef.current.focus()
    }

    // once we have updated the state to show the pending comment, scroll so it is in view
    const elem = document.getElementById("pending")
    if (!elem) {
      return
    }
    // Determine scroll pane, looking up tree until body or overflow is found
    let scrollPane = getScrollPane(elem)
    // Perform scroll
    debouncedScrollIntoView(elem.getBoundingClientRect(), scrollPane)
  }, [pendingComment, debouncedScrollIntoView])

  return {
    discardPendingComment,
    addComment,
    email: useSelector(selectCommentsUser)?.email,
    commentInputRef,
  }
}

export default usePendingComment
