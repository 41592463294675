import { Stack } from "@mui/material"
import RailButton from "./RailButton"
import {
  ArticleOutlined,
  DashboardOutlined,
  DeleteOutline,
  FormatPaintOutlined,
  HelpCenterOutlined,
  Timeline,
  Translate,
} from "@mui/icons-material"
import LEAiLogo from "../../../assets/icons/LEAi_Logo_2024.svg"
import { useHistory } from "react-router"
import AccountButton from "./AccountButton"
import useViewPortHeight from "./hooks/useViewportHeight"

const EXPANDED_LAYOUT_BREAKPOINT = 700 // pixels

/**
 * Component for side mounted navigation rail
 * @param props - Component props
 */
const NavigationRail = (props: any) => {
  const { location } = props
  const currentPath = location.pathname ?? "/"
  const history = useHistory()
  const viewportHeight = useViewPortHeight()

  /**
   * Handler for when nav button is clicked
   * @param path - Path to push onto history
   * @param options - Flag to open route in new tab
   */
  const navigateTo = (path: string, options: any = {}) => {
    const { newTab = false } = options
    if (newTab) {
      const tab = window.open(path, "_blank") as any
      tab.focus()
      return
    }

    const search = path === "/history" ? "?page=0&limit=20" : ""
    history.push({ pathname: path, search })
  }

  const railButtonProps = {
    currentPath,
    navigateTo,
  }

  /**
   * If the viewport is over 700px, show all options.
   */
  const expandedLayout = viewportHeight >= EXPANDED_LAYOUT_BREAKPOINT

  return (
    <Stack
      paddingTop="8px"
      gap="12px"
      sx={{ width: "80px" }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack justifyContent="center" alignItems="center">
        <img
          src={LEAiLogo}
          alt="LEAi Logo"
          width="42"
          height="42"
          style={{
            cursor: "pointer",
          }}
          onClick={() => navigateTo("/")}
        />
      </Stack>
      <Stack gap="12px">
        <RailButton
          label="Courses"
          icon={<ArticleOutlined />}
          path="/"
          {...railButtonProps}
        />
        <RailButton
          label="Paths"
          icon={<Timeline />}
          path="/paths"
          {...railButtonProps}
        />
        <RailButton
          label="Branding"
          icon={<FormatPaintOutlined />}
          path="/branding"
          {...railButtonProps}
        />
        <RailButton
          label="Trash"
          icon={<DeleteOutline />}
          path="/trash"
          {...railButtonProps}
        />
        <RailButton
          label="History"
          icon={<DashboardOutlined />}
          path="/history"
          aliases={["/history-details"]}
          {...railButtonProps}
        />
        <RailButton
          label="Translations"
          icon={<Translate />}
          path="/translation-rules"
          {...railButtonProps}
        />
      </Stack>
      <Stack gap="12px">
        {expandedLayout && (
          <>
            <RailButton
              label="Help"
              icon={<HelpCenterOutlined />}
              path="/help"
              newTab
              {...railButtonProps}
            />
          </>
        )}
        <AccountButton
          includeAllOptions={!expandedLayout}
          navigateTo={navigateTo}
        />
      </Stack>
    </Stack>
  )
}

export default NavigationRail
