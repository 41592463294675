import { IconButton, Tooltip } from "@mui/material"
import AddCommentIcon from "@mui/icons-material/AddComment"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import { Box } from "@mui/material"
import { useContextualMenu } from "../../../hooks/useContextualMenu"
import useCommentsDispatch from "../../../hooks/useCommentsDispatch"
import { useFlag } from "../../../utilities/feature-management"

export interface ContextualMenuProps {
  editorRef: React.RefObject<HTMLElement>
  onAddComment: () => void
  onTransform: (rangeOrElem: Range | HTMLElement) => void
  readOnly: boolean
}

/**
 * Renders a contextual menu for adding comments and rewriting text.
 *
 * @param props - The properties passed to the component.
 * @param props.editorRef - The reference to the editor.
 * @param props.onAddComment - The function to call when a comment is added.
 * @param props.onTransform - The function to call when text is rewritten.
 * @returns
 */
export const ContextualMenu = (props: ContextualMenuProps) => {
  const { editorRef, onAddComment, onTransform, readOnly } = props
  const { location, coordinates, range, hoverElem, clearHover } =
    useContextualMenu(editorRef)
  const { showPendingComment } = useCommentsDispatch()

  const rewriteEnabled = useFlag("rollout-ai-rewrite-by-selection")

  if (!location || !coordinates) {
    return null
  }

  // Do not display menu for smart template controls
  if (!range && hoverElem && hoverElem.dataset.smartTemplateControl) {
    return null
  }

  // If neither hovering nor selecting, do not display the menu
  if (!range && !hoverElem) {
    return null
  }

  // Display the menu vertically if there is no range (i.e. the user is not selecting text)
  const displayVertical = range == null

  // Determine if transform is possible
  let transformPossible = range
    ? isRangeTransformable(range)
    : isElemTransformable(hoverElem!)

  // Exclude blocks that contain images
  if (hoverElem && hoverElem.querySelector("img")) {
    transformPossible = false
  }

  return (
    <Box
      sx={{
        position: "absolute",
        left: displayVertical
          ? coordinates.left
          : coordinates.left - (rewriteEnabled ? 40 : 0),
        top: displayVertical ? coordinates.top : coordinates.top,
        maxHeight: coordinates.maxHeight,
        zIndex: (theme) => theme.zIndex.drawer,
        cursor: "pointer",
        overflow: "hidden", // Hidden to allow clipping of the control at the bottom
        display: "flex",
        flexDirection: displayVertical ? "column" : "row",
        gap: "10px",
      }}
      id="contextual-menu-hover-box"
    >
      {!readOnly && rewriteEnabled && transformPossible && (
        <TransformCommentHoverButton
          onClick={() => {
            onTransform(range ?? hoverElem!)
            clearHover()
          }}
        />
      )}
      <AddCommentHoverButton
        onClick={() => {
          onAddComment()
          showPendingComment(location as any)
        }}
      />
    </Box>
  )
}

interface HoverButtonProps {
  onClick: () => void
}

/**
 * Renders a hover button for adding comments.
 *
 * @param props - The properties passed to the component.
 * @param props.onClick - The function to call when the button is clicked.
 */
const AddCommentHoverButton = ({ onClick }: HoverButtonProps) => {
  return (
    <Tooltip placement="top" title="Add comment">
      <IconButton
        onClick={onClick}
        color={"addition" as any}
        size="small"
        sx={{
          backgroundColor: "#f8f8f8",
          border: "1px solid #ececec",
          "&:hover": {
            backgroundColor: "#ececec",
            border: "1px solid #d0d0d0",
          },
        }}
      >
        <AddCommentIcon />
      </IconButton>
    </Tooltip>
  )
}

/**
 * Renders a hover button for transforming text.
 *
 * @param props - The properties passed to the component.
 * @param props.onClick - The function to call when the button is clicked.
 */
const TransformCommentHoverButton = ({ onClick }: HoverButtonProps) => {
  return (
    <Tooltip placement="top" title="Transform text">
      <IconButton
        onClick={onClick}
        color={"tertiary" as any}
        size="small"
        sx={{
          backgroundColor: "#f8f8f8",
          border: "1px solid #ececec",
          "&:hover": {
            backgroundColor: "#ececec",
            border: "1px solid #d0d0d0",
          },
        }}
      >
        <AutoAwesomeIcon />
      </IconButton>
    </Tooltip>
  )
}

/**
 * Checks if the range encompasses any untransformable elements such as tables,
 * images, video, or components with the dataset.component attribute.
 *
 * @param range - The range to check.
 * @returns True if the range is transformable, false otherwise.
 */
function isRangeTransformable(range: Range) {
  // Get start and end nodes.
  const startNode =
    range.startContainer.nodeType === Node.ELEMENT_NODE
      ? range.startContainer.childNodes[
          Math.min(
            range.startOffset,
            range.startContainer.childNodes.length - 1
          )
        ]
      : range.startContainer

  const endNode =
    range.endContainer.nodeType === Node.ELEMENT_NODE
      ? range.endContainer.childNodes[
          Math.min(range.endOffset, range.endContainer.childNodes.length - 1)
        ]
      : range.endContainer

  // Walk the tree from the start element to the end element
  let node: Node | null = startNode
  while (node) {
    if (node instanceof HTMLElement && !isElemTransformable(node)) {
      return false
    }
    if (node === endNode) {
      break
    }
    if (node.firstChild) {
      node = node.firstChild
    } else if (node.nextSibling) {
      node = node.nextSibling
    } else {
      while (node && !node.nextSibling) {
        node = node.parentNode
      }
      if (node) {
        node = node.nextSibling
      }
    }
  }

  return true
}

/**
 * Checks if the element is transformable.
 *
 * @param elem - The element to check.
 * @returns True if the element is transformable, false otherwise.
 */
function isElemTransformable(elem: HTMLElement): boolean {
  // Exclude untransformable elements
  for (const tag of ["table", "video", "iframe", "figure"]) {
    if (elem.querySelector(tag)) {
      return false
    }
  }

  // Exclude special components and templates from transform
  return (
    !elem.dataset.component &&
    !elem.dataset.template &&
    !elem.dataset.smartTemplateControl
  )
}
