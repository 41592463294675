import { Box, Container } from "@mui/material"
import MicrolearningForm from "./MicrolearningForm"
import MicrolearningPreview from "./MicrolearningPreview"
import useMicrolearning from "./hooks/useMicrolearning"
import { CustomerThemeProvider } from "../../contexts/customerTheme"

/**
 * Container screen for new Micro-Learning UX
 * @param props - Component props
 */
const MicrolearningScreen = (props: any) => {
  const { location } = props
  const course = location.state?.course ?? null

  const microlearning = useMicrolearning()
  const courseID = course?.id

  return (
    <CustomerThemeProvider courseID={courseID}>
      <Container fixed maxWidth="xl" sx={{ height: "100vh" }}>
        <Box display="flex">
          <MicrolearningForm course={course} {...microlearning} />
          <MicrolearningPreview {...microlearning} />
        </Box>
      </Container>
    </CustomerThemeProvider>
  )
}

export default MicrolearningScreen
