import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  Alert,
  Box,
} from "@mui/material"
import FroalaBaseEditor from "froala-editor"
import { useEffect, useRef, useState } from "react"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import { generateAltText } from "../../../api"
import { useFlag } from "../../../utilities/feature-management"

// Define icon in Froala for alt text
FroalaBaseEditor.DefineIcon("imageAltText", {
  SVG_KEY: "imageAltText",
})

/**
 * Popup for editing alt text of an image. Adds ability to autogenerate captions. Registers a button
 * in Froala that opens the popup.
 */
export function AutoAltTextPopup(props: {}) {
  // Editor that opened the popup
  const [editor, setEditor] = useState<any | null>(null)

  // Image that opened the popup
  const [image, setImage] = useState<HTMLImageElement | null>(null)

  // Current alt text that is being edited
  const [altText, setAltText] = useState<string>("")

  // Whether we are currently generating alt text
  const [generatingAltText, setGeneratingAltText] = useState<boolean>(false)

  // Error message from generating alt text
  const [error, setError] = useState<string | null>(null)

  // Reference to the image that opened the popup. Used to check if the image has changed
  const imageRef = useRef<HTMLImageElement | null>(null)

  const generateEnabled = useFlag("rollout-generate-alt-text")

  useEffect(() => {
    imageRef.current = image
  }, [image])

  useEffect(() => {
    // Register the new command in Froala (there is no way to unregister it)
    FroalaBaseEditor.RegisterCommand("imageAutoAlt", {
      title: "Alternate Text",
      icon: "imageAltText",
      focus: true,
      undo: true,
      callback: function () {
        // Hide the popup that Froala was showing
        this.popups.hide("image.edit")

        const image = (this.image.get() as any)[0]
        setEditor(this)
        setImage(image)

        const existingAltText = image.getAttribute("alt") ?? ""
        setAltText(existingAltText)
      },
    })
  }, [])

  /** Close the popup */
  const handlePopupClose = () => {
    setError(null)
    setEditor(null)
    setImage(null)
  }

  /** Save and close the popup */
  const handlePopupSave = () => {
    // Set the alt text
    image!.setAttribute("alt", altText)
    editor!.undo.saveStep()

    // Close the popup
    setError(null)
    setEditor(null)
    setImage(null)
  }

  /** Handle changes to the alt text
   * @param event The change event
   */
  const handleAltTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Replace newlines with spaces

    setAltText(event.target.value.replace(/\n/g, " "))
  }

  /** Handle autogenerating alt text */
  const handleAutogenerate = () => {
    setGeneratingAltText(true)
    setError(null)
    generateAltText(image!.getAttribute("src")!)
      .then((data) => {
        // If the image has changed since we started generating alt text, ignore
        if (imageRef.current !== image) return

        setAltText(data.alt_text.replace(/\n/g, " "))
        setGeneratingAltText(false)
        setError(null)
      })
      .catch((err) => {
        // If the image has changed since we started generating alt text, ignore
        if (imageRef.current !== image) return

        setError("Error generating alt text. Please try again later.")
        setGeneratingAltText(false)
      })
  }

  return (
    <Dialog
      open={image != null}
      onClose={handlePopupClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Edit Alternate Text</DialogTitle>
      <DialogContent>
        <TextField
          label={
            generateEnabled
              ? "Click Generate to create or type your own."
              : "Alternate Text"
          }
          error={error != null}
          helperText={error}
          type="text"
          multiline
          fullWidth
          sx={{ minWidth: "400px", mt: 1 }}
          value={altText}
          onChange={handleAltTextChange}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", ml: 1, mr: 1 }}>
        {generateEnabled ? (
          <Button
            onClick={handleAutogenerate}
            color="primary"
            disabled={generatingAltText}
            startIcon={
              generatingAltText ? (
                <CircularProgress size={20} />
              ) : (
                <AutoFixHighIcon />
              )
            }
          >
            {generatingAltText ? "Generating..." : "Generate"}
          </Button>
        ) : (
          <Box />
        )}
        <Box sx={{ flex: 1 }} />
        <Button onClick={handlePopupClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handlePopupSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
