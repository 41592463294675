import { getSectionElements } from "../../utilities/smartTemplates"

/** Gets the content of a section by ID.
 * @param root The root HTML element to search in.
 * @param sectionId The ID of the section to get.
 * @returns The HTML of the section.
 */
export function getContentById(root: HTMLElement, sectionId: string) {
  const section = root.querySelector(
    `h2[id='${sectionId}']`
  ) as HTMLElement | null
  if (!section) {
    throw new Error(`Section with ID ${sectionId} not found.`)
  }
  const sectionElements = [section, ...getSectionElements(section)]
  return sectionElements.map((element) => element.outerHTML).join("")
}

/**
 * Takes a target ID of an H2 section and a list of IDs of source H2 sections and puts the H2 sections to be part of the
 * target H2 section. Places them as demoted H3 sections (recursively demoting all child sections) immediately before the first
 * H3 smart template in the target section, or at the end of the target section if there are no H3 smart templates.
 *
 * A section encompasses the H2 and all subsequent lesser headers until the next H2 or the end of the document.
 *
 * @param root The HTML element containing the sections.
 * @param targetId The ID of the target H2 section.
 * @param sourceIds The IDs of the source H2 sections.
 * @returns The HTML of the target section.
 */
export function simpleMergeSimilarContent(
  root: HTMLElement,
  targetId: string,
  sourceIds: string[]
) {
  // Find the target H2 section
  const target = root.querySelector(
    `h2[id='${targetId}']`
  ) as HTMLElement | null
  if (!target) {
    throw new Error(`Target H2 section with ID ${targetId} not found.`)
  }

  // Find the first H3 section smart template section in the target
  const targetElements = [target, ...getSectionElements(target)]
  const firstTargetH3SmartTemplate = targetElements.find(
    (element) => element.tagName === "H3" && element.dataset.template
  )

  // Create output HTML
  let outputHtml = ""

  // Emit all elements up to the first H3 smart template
  for (const element of targetElements) {
    if (element === firstTargetH3SmartTemplate) {
      break
    }
    outputHtml += element.outerHTML
  }

  // For each source section, demote it and its children and move it to the target.
  for (const sourceId of sourceIds) {
    // Find the source H2 section
    let source = root.querySelector(
      `h2[id='${sourceId}']`
    ) as HTMLElement | null
    if (!source) {
      throw new Error(`Source H2 section with ID ${sourceId} not found.`)
    }

    // Get content of source section
    const sourceElements = [source, ...getSectionElements(source)]

    for (const element of sourceElements) {
      outputHtml += demoteHeading(element)
    }
  }

  // Emit all elements after the first H3 smart template
  let foundFirstH3SmartTemplate = false
  for (const element of targetElements) {
    if (element === firstTargetH3SmartTemplate) {
      foundFirstH3SmartTemplate = true
    }
    if (foundFirstH3SmartTemplate) {
      outputHtml += element.outerHTML
    }
  }

  return outputHtml
}

/** Change heading element from H2 to H3, H3 to H4, etc.
 * @param element The heading element to demote.
 * @returns new html tag
 */
function demoteHeading(element: HTMLElement): string {
  // If it's not a heading, just return the HTML element.
  if (!element.tagName.startsWith("H")) {
    return element.outerHTML
  }

  const newTag = `H${parseInt(element.tagName[1]) + 1}`
  if (newTag <= "H6") {
    const newHeading = document.createElement(newTag)
    newHeading.innerHTML = element.innerHTML
    Array.from(element.attributes).forEach((attr) =>
      newHeading.setAttribute(attr.name, attr.value)
    )
    return newHeading.outerHTML
  }
  return element.outerHTML
}

/** Replaces a target section and a list of source sections with replacement HTML
 * @param root The HTML element to alter.
 * @param targetId The ID of the target H2 section.
 * @param sourceIds The IDs of the source H2 sections.
 * @param replacementHtml The HTML to replace the target and source sections with.
 */
export function replaceSimilarContent(
  root: HTMLElement,
  targetId: string,
  sourceIds: string[],
  replacementHtml: string
) {
  // Find the target H2 section
  const target = root.querySelector(
    `h2[id='${targetId}']`
  ) as HTMLElement | null
  if (!target) {
    throw new Error(`Target H2 section with ID ${targetId} not found.`)
  }

  // Insert new content before the current section
  target.insertAdjacentHTML("beforebegin", replacementHtml!)

  // Remove all selected sections
  for (const sourceId of sourceIds) {
    // Find the source H2 section
    let source = root.querySelector(
      `h2[id='${sourceId}']`
    ) as HTMLElement | null
    if (!source) {
      throw new Error(`Source H2 section with ID ${sourceId} not found.`)
    }

    // Get content of section
    const sectionElements = [source, ...getSectionElements(source)]

    // Remove source section
    for (const element of sectionElements) {
      element.remove()
    }
  }

  // Remove target section
  const targetSectionElements = [target, ...getSectionElements(target)]
  for (const element of targetSectionElements) {
    element.remove()
  }
}
