import config from "../config"
import * as errors from "../utilities/errors"
import { stripTemporaryElements } from "../utilities/smartTemplates"
import getAuthenticationHeaders from "./utils/getAuthenticationHeaders"

type CourseId = string
type JobId = string

type SaveCourseData = {
  /** The course document as HTML. */
  html: string
  /** An estimated reading duration for the document. */
  duration?: number
}

type SaveCourseOptions = {
  /** When true, the request will attempt to complete even if the session has closed. */
  keepalive?: boolean
}

type SaveCourseResponse = {
  jobId: string
  version: string
}

/**
 * Requests a course save.
 *
 * @param id The identifier for the course.
 * @param data The save data.
 * @param options Options for the save request.
 * @returns The associated job ID.
 */
export async function saveCourse(
  id: CourseId,
  data: SaveCourseData,
  options: SaveCourseOptions = {}
): Promise<SaveCourseResponse> {
  const { keepalive = false } = options

  const response = await fetch(`${config.apiUrl}/courses/${id}/`, {
    method: "PUT",
    // This flag should ensure that save requests are completed in the
    // background after navigating away from the app.
    keepalive,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      ...(await getAuthenticationHeaders()),
    },
    body: JSON.stringify({
      body: stripTemporaryElements(data.html),
      duration: data.duration,
    }),
  })

  if (response.status !== 202) {
    throw new errors.OfflineError()
  }

  const { async_id: jobId, version_id: version } = await response.json()

  return { jobId, version }
}
