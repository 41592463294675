import { styled, Tooltip } from "@mui/material"
import { isEqual } from "lodash"
import React from "react"
import { useActiveSection } from "./context/ActiveSectionProvider"
import moveCursorTo from "./utils/moveCursorTo"
import { setRemarksScrollLocked } from "../../store/editor/editorSlice"
import { useDispatch } from "react-redux"

const OutlineItemContainer = styled("div")({
  display: "flex",
  alignItems: "center",
})

const OutlineItemLabel = styled("div", {
  shouldForwardProp: (propName) => !["active"].includes(propName),
})(
  {
    display: "block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    cursor: "pointer",
  },
  ({ active }) => ({ fontWeight: active ? "bold" : "normal" })
)

/**
 * Displays an outline item.
 *
 * @type {(props: OutlineItemProps) => JSX.Element}
 */
const OutlineItem = (props) => {
  const { item, collapseIcon } = props

  const activeSection = useActiveSection()
  const onClick = () => {
    moveCursorTo(item.$el)
  }
  const dispatch = useDispatch()

  return (
    <OutlineItemContainer
      onClick={() => dispatch(setRemarksScrollLocked(false))}
    >
      {collapseIcon &&
        React.cloneElement(collapseIcon, { className: "icon-container" })}
      <Tooltip title={item.title} arrow placement="right">
        <OutlineItemLabel active={activeSection === item.$el} onClick={onClick}>
          {item.title}
        </OutlineItemLabel>
      </Tooltip>
    </OutlineItemContainer>
  )
}

export default React.memo(OutlineItem, isEqual)
