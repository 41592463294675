import { Button, Divider, Stack, Theme, Typography } from "@mui/material"
import { useAuditHistoryDetails } from "../../../../hooks/useAuditHistory"
import { parseQueryString } from "../../../../utilities/queryString"
import PageLayout from "../../layouts/PageLayout"
import ListContainer from "../../itemList/ListContainer"
import ActionLogDetails from "./components/ActionLogDetails"
import { isoStringToRelativeTime } from "../../../../utilities/transformers"
import { ArrowBack } from "@mui/icons-material"
import { useHistory } from "react-router-dom"

/**
 * Page for showing audit logs and details
 * @param props - Component props
 * @param props.location - Router location
 */
const AuditDetailsPage = (props: { location: any }) => {
  const { location } = props

  const { isLoading, dataFilterAction, ...other } = useAuditHistoryDetails(
    parseQueryString(window.location.search)
  )
  const history = useHistory()

  const historyLog: any = other.historyLog

  const selectedAction = dataFilterAction.find(
    (action) => action.id === historyLog?.action_type
  )

  return (
    <PageLayout navRail location={location}>
      <ListContainer
        title="Action Details"
        controls={
          <>
            <Button
              variant="text"
              onClick={() => history.goBack()}
              startIcon={<ArrowBack />}
            >
              Back
            </Button>
          </>
        }
      >
        <Stack
          width="100%"
          padding="24px"
          sx={(theme: Theme) => ({
            backgroundColor: theme.scheme.onPrimary,
            overflowX: "hidden",
            overflow: "auto",
            flex: "1 1 0",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          })}
          gap={2}
        >
          <Stack>
            <Typography gutterBottom variant="titleMedium">
              {historyLog.description}
            </Typography>
            <Typography gutterBottom variant="bodyMedium">
              Performed by {historyLog.username}{" "}
              {isoStringToRelativeTime(historyLog.recorded_at)}
            </Typography>
          </Stack>

          <Divider />
          <Stack width="100%">
            <ActionLogDetails
              selectedAction={selectedAction}
              data={historyLog}
            />
          </Stack>
        </Stack>
      </ListContainer>
    </PageLayout>
  )
}

export default AuditDetailsPage
