import { Box, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import { ReactNode } from "react"

type ListItemContentProps = {
  avatar: ReactNode
  primaryText: string | ReactNode
  secondaryText?: string | ReactNode | null
  control?: ReactNode
  [key: string]: any // Additional props
}

/**
 * Component for a uniform layout for any type of list item
 * @param props - Component props
 */
const ListItemContent = (props: ListItemContentProps) => {
  const {
    avatar,
    primaryText,
    secondaryText = null,
    control,
    ...otherProps
  } = props

  return (
    <Box
      {...otherProps}
      display="flex"
      gap={2}
      alignItems="center"
      width="100%"
    >
      <ListItemAvatar
        sx={{
          height: "56px",
          width: "56px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {avatar}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            variant="body1"
          >
            {primaryText}
          </Typography>
        }
        secondary={secondaryText}
      />
      <Box marginLeft="auto">{control}</Box>
    </Box>
  )
}

export default ListItemContent
