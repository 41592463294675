import React from "react"
import { Box, Grid, Skeleton } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    height: theme.spacing(24),
    "& span": {
      // backgroundColor: theme.palette.lexGrey,
    },
  },
  visual: {
    minWidth: "40%",
    position: "relative",
  },
  details: {
    width: "100%",
    paddingTop: 0,
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  },
}))

const CoursesGridSkeleton = (props) => {
  const classes = useStyles()

  return (
    <Grid data-cy="loading-skeleton" item xs={12} sm={12} md={6} lg={4}>
      <Box className={classes.root}>
        <Box className={classes.visual}>
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: "4px",
              height: "100%",
            }}
          />
        </Box>
        <Box className={classes.details}>
          <Skeleton variant="text" sx={{ width: "100%" }} />
          <Skeleton variant="text" width={200} />
          <Skeleton variant="text" width={200} />
        </Box>
      </Box>
    </Grid>
  )
}

export default CoursesGridSkeleton
