import { CustomElementConfig, RenderViewOptions } from "../CustomElements"
import StyledList from "./StyledList"
import AddStyledListDialog from "./AddStyledListDialog"

export interface StyledListData {
  listType: "bullet" | "checkbox" | "number"
  listSize: "small" | "medium" | "large"
  listItems: StyledListItemData[]
}

export interface StyledListItemData {
  html: string
}

const initialData: StyledListData = {
  listType: "bullet",
  listSize: "large",
  listItems: [
    {
      html: "<p>List Item 1</p>",
    },
    {
      html: "<p>List Item 2</p>",
    },
  ],
}

/**
 * Custom element that renders a list styled by MUI with
 * different types of bullets. The HTML respresentation is denoted by a
 * section element with the data-component attribute as "styled-list". Within the section
 * is either an ordered or unordered list, depending on the data.listType property
 * provided. List items are all rendered as basic <li> elements.
 *
 * Sample HTML [Numbered List]:
 * <section data-component="styled-list" data-list-type="number"   data-list-size="large">
 * <ol>
 * <li>Ordered Item 1</li>
 * <li>Ordered Item 2</li>
 * </ol>
 * </section>
 *
 * Sample HTML [Bullet List]:
 * <section data-component="styled-list" data-list-type="bullet"   data-list-size="small">
 * <ul>
 * <li>Unordered Item 1</li>
 * <li>Unordered Item 2</li>
 * </ul>
 * </section>
 *
 */
export const styledListCustomElement: CustomElementConfig<StyledListData> = {
  selector: "[data-component='styled-list']",
  /**
   * Extract data from the light-DOM representation of the custom element
   * @param element - The element to extract data from
   */
  getDataFromElement: (element) => {
    const listType = element.getAttribute("data-list-type") || "bullet"
    const listSize = element.getAttribute("data-list-size") || "large"

    const list = element.querySelector(listType === "number" ? "ol" : "ul")
    const listChildren = list?.querySelectorAll("li") || []

    const listItems = Array.from(listChildren).map((listItem) => ({
      label: listItem.innerHTML,
      html: listItem.innerHTML,
    }))
    return { listSize, listType, listItems } as StyledListData
  },
  /**
   * Update the light-DOM representation of the Styled List from the data
   * @param element - Element to update
   * @param data - Data to update the element with
   */
  updateElementFromData: (element, data) => {
    const listItemsHTML = data.listItems
      .map((listItem) => `<li>${listItem.html}</li>`)
      .join("")

    // If list type is number, render as ordered list, otherwise render as unordered list
    element.innerHTML =
      data.listType === "number"
        ? `<ol>${listItemsHTML}</ol>`
        : `<ul>${listItemsHTML}</ul>`

    element.setAttribute("data-list-type", data.listType)
    element.setAttribute("data-list-size", data.listSize)
  },
  /**
   * Render the custom element in the editor
   * @param options - Options for rendering the custom element
   */
  renderView: (options: RenderViewOptions<StyledListData>) => {
    const { data, onDataChange, editor, element, withStyles, readOnly } =
      options

    return (
      <StyledList
        editor={editor}
        element={element}
        data={data}
        onDataChange={onDataChange}
        withStyles={withStyles}
        readOnly={readOnly}
      />
    )
  },
}

/**
 * Function to return component
 * @param onClose - Callback for tearing down dialog
 */
export const createStyledListDialog = (onClose: (data: any) => void) => (
  <AddStyledListDialog
    onSave={(data: any) => {
      const section = document.createElement("section")
      section.dataset.component = "styled-list"
      styledListCustomElement.updateElementFromData(section, data)
      onClose(section.outerHTML)
    }}
    onCancel={() => onClose(null)}
    initialData={initialData}
  />
)
