import { useEffect, useState } from "react"
import * as api from "../api"

export const states = {
  READY: "ready",
  SEARCHING: "searching",
  HAS_RESULTS: "has_results",
  NO_RESULTS: "no_results",
  REPLACING: "replacing",
  COMPLETE: "complete",
  ERROR: "error",
}

const useFind = () => {
  const resultPageSize = 10
  const [searchState, setSearchState] = useState(states.READY)
  const [searchText, setSearchText] = useState("")
  const [matchCase, setMatchCase] = useState(false)
  const [wholeWord, setWholeWord] = useState(false)

  const [replaceText, setReplaceText] = useState("")

  const [searchResult, setSearchResult] = useState(null)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [replaceNumCourses, setReplaceNumCourses] = useState(1)
  const [confirmingReplace, setConfirmingReplace] = useState(null)

  const [unFoundText, setUnfoundText] = useState("")
  const [completeMessage, setCompleteMessage] = useState("")
  const [warningMessage, setWarningMessage] = useState("")
  const [problemCourses, setProblemCourses] = useState([])

  const [findEnabled, setFindEnabled] = useState(false)

  useEffect(() => {
    setFindEnabled(
      searchState !== states.SEARCHING &&
        searchState !== states.REPLACING &&
        searchText.length >= 3
    )
  }, [searchText, searchState])

  useEffect(() => {
    // If our user changes any of the search criteria - text/case/whole word
    // then clear the search results
    setSearchResult(null)
  }, [searchText, matchCase, wholeWord])

  const onFindAll = () => {
    // Clear previous result and show progress while searching
    setSelectedCourses([])
    setSearchResult(null)
    setSearchState(states.SEARCHING)

    api
      .find({
        search_text: searchText,
        match_case: matchCase,
        whole_word: wholeWord,
        result_offset: 0,
        result_limit: resultPageSize,
      })
      .then(({ data }) => {
        if (data && data.total > 0) {
          setSearchState(states.HAS_RESULTS)
          setSelectedCourses(data.courses.map(({ course_id }) => course_id))
          setSearchResult(data)
        } else {
          setUnfoundText(searchText)
          setSearchState(states.NO_RESULTS)
        }
      })
      .catch((e) => {
        setSearchState(states.ERROR)
        console.error(e)
      })
  }

  const expectedResultsCount = (course, page) => {
    const resultOffset = resultPageSize * (page - 1)

    const remaining = course.total - resultOffset

    return Math.min(remaining, resultPageSize)
  }

  const onFindForCourse = (courseId, page) => {
    // page through a single course's results

    const resultOffset = resultPageSize * (page - 1)

    return api
      .find({
        search_text: searchText,
        match_case: matchCase,
        whole_word: wholeWord,
        course_ids: courseId,
        result_offset: resultOffset,
        result_limit: resultPageSize,
      })
      .then(({ data }) => {
        let updatedCourses
        if (data?.courses?.length > 0) {
          updatedCourses = searchResult.courses.map((course) =>
            course.course_id !== courseId ? course : data.courses[0]
          )
        } else {
          updatedCourses = searchResult.courses.filter(
            (course) => course.course_id !== courseId
          )
        }
        setSearchResult({
          ...searchResult,
          courses: updatedCourses,
        })
      })
      .catch((e) => {
        setSearchState(states.ERROR)
        console.error(e)
      })
  }

  const onReplace = (coursesInfo) => {
    // Show progress while replacing
    setSearchState(states.REPLACING)
    setReplaceNumCourses(coursesInfo.length)

    return api
      .replace({
        search_text: searchText,
        replace_text: replaceText,
        match_case: matchCase,
        whole_word: wholeWord,
        courses: coursesInfo,
      })
      .then(({ data }) => {
        checkAsyncJob(data.async_id, coursesInfo)
      })
      .catch((e) => {
        console.error(e)
        setSearchState(states.ERROR)
      })
  }

  const getCoursesByIds = (courseIds) => {
    return searchResult.courses.filter((course) =>
      courseIds.includes(course.course_id)
    )
  }

  const onReplaceAll = () => {
    const coursesInfo = getCoursesByIds(selectedCourses).map((course) => {
      return {
        course_id: course.course_id,
        course_title: course.course_title,
        total_found: course.total,
        selected_results: null,
      }
    })
    onReplace(coursesInfo)
  }

  const onReplaceOne = (course, selectedResults) => {
    const coursesInfo = {
      course_id: course.course_id,
      course_title: course.course_title,
      total_found: selectedResults.length,
      selected_results: selectedResults,
    }
    return onReplace([coursesInfo])
  }

  const onReplaceConfirmed = () => {
    const { course, selectedResults } = confirmingReplace
    if (course === "all") {
      onReplaceAll()
    } else {
      onReplaceOne(course, selectedResults)
    }
  }

  const confirmRequired = (courseIds) => {
    if (replaceText.trim().length === 0) {
      return "blankReplaceText"
    } else if (!matchCase) {
      const mixedCaseResults = getCoursesByIds(courseIds).find(
        (course) => course.has_mixed_case
      )
      if (mixedCaseResults != null) {
        return "mixedCase"
      }
    }
    return null
  }

  const onComplete = (data, coursesInfo) => {
    let total_found = coursesInfo.reduce((total, courseInfo) => {
      return total + courseInfo.total_found
    }, 0)
    let total_replaced = data.total_replaced

    let badCourses = []
    if (total_replaced !== total_found) {
      // get the courses that had a problem
      badCourses = data.courses
        .filter(
          (courseInfo) => courseInfo.total_replaced !== courseInfo.total_found
        )
        .map((courseInfo) => courseInfo.course_title)
    }

    setCompleteMessage("")
    setWarningMessage("")
    setProblemCourses(badCourses)

    if (total_replaced === 0) {
      setWarningMessage(
        `We were not able to find and replace the term '${searchText}'.`
      )
    } else if (total_replaced !== total_found) {
      setWarningMessage(
        `We were only able to find and replace ${total_replaced.toLocaleString()} of ${total_found.toLocaleString()} occurrence${
          total_found > 1 ? "s" : ""
        } of '${searchText}'.`
      )
    } else {
      // Show replace complete message
      const message = `Replaced ${total_replaced.toLocaleString()} occurence${
        total_replaced > 1 ? "s" : ""
      } of '${searchText}' with '${replaceText}' in ${
        coursesInfo.length === 1
          ? "one course."
          : coursesInfo.length + " courses."
      }`
      setCompleteMessage(message)
    }

    setSearchState(states.COMPLETE)

    // Reset UI to prep for the next search
    setSelectedCourses([])
    setReplaceText("")
    setSearchResult(null)
  }

  const checkAsyncJob = async (async_id, coursesInfo) => {
    try {
      let job = await api.checkAsyncJob(async_id)
      let asyncJob = job.data

      if (asyncJob.status === "complete") {
        console.log("complete")
        onComplete(asyncJob.data.replace_result, coursesInfo)
      } else if (asyncJob.status === "failed_no_retry") {
        console.log("failed_no_retry")
      } else {
        setTimeout(() => {
          checkAsyncJob(asyncJob.async_id, coursesInfo)
        }, 1000)
      }
    } catch (err) {
      console.log(err)
      setSearchState(states.ERROR)
    }
  }

  return {
    searchState,
    searchText,
    replaceText,
    findEnabled,
    searchResult,
    unFoundText,
    completeMessage,
    warningMessage,
    problemCourses,
    selectedCourses,
    replaceNumCourses,
    resultPageSize,
    confirmingReplace,
    confirmRequired,
    expectedResultsCount,
    setSelectedCourses,
    setSearchText,
    setMatchCase,
    setWholeWord,
    setReplaceText,
    setConfirmingReplace,
    onReplaceConfirmed,
    onReplaceAll,
    onReplaceOne,
    onFindAll,
    onFindForCourse,
  }
}

export default useFind
