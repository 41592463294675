import { createSlice } from "@reduxjs/toolkit"
import fetchJobUpdates from "./fetchJobUpdates"
import jobsEntityAdaptor from "./jobsEntityAdaptor"
import uploadCourseDocument from "./uploadCourseDocument"

/**
 * Stores the state of async jobs running on the server.
 */
const jobsSlice = createSlice({
  name: "jobs",
  initialState: jobsEntityAdaptor.getInitialState({ loading: "idle" }),
  reducers: {
    jobCanceled(state, action) {
      jobsEntityAdaptor.upsertOne(state, {
        async_id: action.payload.async_id,
        status: "canceled",
      })
    },
  },
  extraReducers: {
    [fetchJobUpdates.pending]: (state) => {
      state.loading = "pending"
    },
    [fetchJobUpdates.rejected]: (state, action) => {
      state.loading = "idle"
    },
    [fetchJobUpdates.fulfilled]: (state, action) => {
      state.loading = "idle"
      jobsEntityAdaptor.upsertMany(state, action.payload)
    },
    [uploadCourseDocument.fulfilled]: jobsEntityAdaptor.setOne,
  },
})

export default jobsSlice
