import { useEffect } from "react"
import { useStore } from "react-redux"
import fetchJobUpdates from "../store/jobs/fetchJobUpdates"
import selectHasPendingJobs from "../store/jobs/selectHasPendingJobs"
import { useAuth } from "../contexts/authorization"
import { useFlag } from "../utilities/feature-management"
const INTERVAL_TIME = 5000 // 5 seconds

/**
 * Watch for updates to pending jobs.
 *
 * On a regalar interval, `useWatchJobs` checks for updates to pending jobs. Once
 * all active jobs are no longer pending, it stops checking for updates.
 */
const useWatchJobs = () => {
  const store = useStore()
  const { route } = useAuth()

  const rolloutServerlessImportAPI = useFlag("rollout-serverless-import-api")

  useEffect(() => {
    if (route !== "authenticated") {
      // if we are not authenticated, don't proceed
      return
    }
    const { dispatch, getState } = store

    /**
     * * fetch job updates
     * @param opts - fetch opts
     */
    const fetch = (opts) => dispatch(fetchJobUpdates(opts))

    // Start the interval for fetching updates.
    const interval = setInterval(() => {
      const hasPending = selectHasPendingJobs(getState())

      if (!hasPending) {
        return
      }

      fetch({ rolloutServerlessImportAPI })
    }, INTERVAL_TIME)

    // Chech for new updates on return to session.

    fetch({ rolloutServerlessImportAPI })
    window.addEventListener("focus", fetch)

    return () => {
      clearInterval(interval)
      window.removeEventListener("focus", fetch)
    }
  }, [rolloutServerlessImportAPI, route, store])
}

export default useWatchJobs
