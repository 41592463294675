/**
 * Check if a given value is a heading element.
 * Container is needed because valid headers are either direct
 * children of the container or inside a root section tag.
 *
 * @param {any} value
 * @return {boolean}
 */
export const isHeadingElement = (value, container) =>
  value instanceof Element &&
  ["H1", "H2", "H3", "H4", "H5", "H6"].includes(value.tagName) &&
  (value.parentElement === container ||
    // null parent element means it was removed, so consider it a heading element
    // as its position was unknown
    value.parentElement == null ||
    (value.parentElement.tagName === "SECTION" &&
      value.parentElement.parentElement === container))

/**
 * Retrieve the previous element in document-order.
 *
 * @type {(node: Node) => ?Element}
 */
const previousElement = (node) => {
  if (!node.previousElementSibling) {
    return node.parentElement
  }

  let prev = node.previousElementSibling
  while (prev.lastElementChild) {
    prev = prev.lastElementChild
  }

  return prev
}

/**
 * Find the section start for any element.
 *
 * Typically the section start will be a H1-H6 heading element. However, if
 * there are no headings before the target element the returned value will be
 * the first element in the container.
 *
 * A heading element must be a direct child of a container or of a section
 * that is a direct child of the container. Headers in tables are not considered.
 *
 * By default, the container is scoped to the document body, but this can be
 * overridden in the options.
 *
 * @type {(element: Element, options: { container?: Element }) => ?Element}
 */
const findSectionStart = (element, options = {}) => {
  const { container = document.body } = options

  if (!container.contains(element)) {
    return null
  }

  let $previous
  while (
    !isHeadingElement(element, container) &&
    ($previous = previousElement(element)) &&
    $previous !== container
  ) {
    element = $previous
  }

  return element
}

export default findSectionStart
