/**
 * @typedef {import("./getOutline").OutlineItem} OutlineItem
 */

/**
 * @typedef {{
 *  found: boolean,
 *  nextSiblingItem: OutlineItem?,
 *  nextSiblingHeading: Element?,
 *  parentItem: OutlineItem?,
 *  parentHeading: Element?,
 * }} RelativeOutlinePosition
 */

/**
 * Find an item's relative position within a given outline.
 *
 * The items relative position is defined by is parent and next sibling. An
 * outline item will have no parent if it is not encompassed by another section.
 * An outline item will have no next sibling if its not followed by another
 * section with lower or equal level.
 *
 * @param {OutlineItem[]} outline
 * @param {OutlineItem} target
 * @returns {RelativeOutlinePosition}
 */
const findRelativeToOutline = (outline = [], target) => {
  // For each item on the outline level...
  for (const [index, item] of outline.entries()) {
    // ...check if the item is the target...
    if (item === target) {
      const nextSiblingItem = outline[index + 1] ?? null

      return {
        found: true,
        nextSiblingItem,
        nextSiblingHeading: nextSiblingItem?.$el ?? null,
        parentItem: null,
        parentHeading: null,
      }
    }

    //...otherwise check if any of the items children are the target.
    const result = findRelativeToOutline(item.children, target)
    if (result.found) {
      if (!result.nextSiblingItem) {
        const nextSiblingItem = outline[index + 1] ?? null
        result.nextSiblingItem = nextSiblingItem
        result.nextSiblingHeading = nextSiblingItem?.$el ?? null
      }

      if (!result.parentItem) {
        result.parentItem = item
        result.parentHeading = item.$el
      }
      return result
    }
  }

  // Return an null position if the item is not found on this level.
  return {
    found: false,
    nextSiblingItem: null,
    nextSiblingHeading: null,
    parentItem: null,
    parentHeading: null,
  }
}

export default findRelativeToOutline
