import { useEffect, useState } from "react"

/**
 * Hook to watch current height of viewport and update accordingly
 */
const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

  useEffect(() => {
    /**
     * Listener to monitor viewport height
     */
    const handleResize = () => {
      setViewportHeight(window.innerHeight)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return viewportHeight
}

export default useViewportHeight
