import { findSelectedElementByScrollPosition } from "../../../utilities/domUtils"
import findSectionStart from "./findSectionStart"

const findVisibleSection = (options = {}) => {
  const { container = document.body } = options

  // find the current element based on the scroll and position of viewport
  const { currentNode } = findSelectedElementByScrollPosition(container)

  // find the starting (heading) element of that section if it exists
  return findSectionStart(currentNode, { container })
}

export default findVisibleSection
