import * as api from "../../../api"
import waitForJob from "./waitForJob"
import { microlearningAsyncStatusEnum } from "../utils/constants"

/**
 * Submit an async job to generate concepts for a course
 * @param courseId - ID of course to obtain concepts for
 */
const fetchConcepts = async (courseId: string) => {
  const jobId = await api
    .getMicroLearningConcepts(courseId)
    .then(({ data }) => data.async_id)

  const conceptsResponse: any = await waitForJob(jobId, {
    completeStatus: microlearningAsyncStatusEnum.COMPLETE,
    failedStatus: microlearningAsyncStatusEnum.FAILED_NO_RETRY,
  })

  const { folder, filename } = conceptsResponse

  return await api.downloadJSON(folder, filename).then(({ data }) => data)
}

export default fetchConcepts
