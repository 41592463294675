import selectGuest from "./selectGuest"

/**
 *
 * @param {import("../..").AppState} state
 * @returns {boolean}
 */
const selectCommentsUser = (state) =>
  state.auth.currentUser || selectGuest(state.remarks)

export default selectCommentsUser
