import { useState } from "react"
import { RewritePopover } from "./RewritePopover"
import { TransformToComponentPopover } from "./TransformToComponentPopover"
import {
  SelectTransformTypePopover,
  TransformType,
} from "./SelectTransformTypePopover"
import { useTransformToComponentEnabled } from "./transformUtils"

/**
 * Renders a popover for transforming the selected content.
 * @param props - The properties passed to the component.
 * @param props.editor - The editor instance.
 * @param props.editorWrapper - The wrapper of the editor.
 * @param props.onClose - The callback for closing the popover. Called with true if the transformation was successful, false otherwise.
 * @param props.range - The range of the selected content.
 * @param props.transformType - The type of transform to use (optional). If not provided, the user will be prompted to select one.
 */
export function TransformPopover(props: {
  editor: any
  editorWrapper: HTMLElement
  onClose: (completed: boolean) => void
  range: Range
  transformType?: TransformType
}) {
  const { editor, editorWrapper, onClose, range } = props
  const transformToComponentEnabled = useTransformToComponentEnabled()

  // Default to rewrite if transform to component is not enabled
  const [transformType, setTransformType] = useState<TransformType | null>(
    transformToComponentEnabled ? props.transformType ?? null : "rewrite"
  )

  if (!transformType) {
    return (
      <SelectTransformTypePopover
        editorWrapper={editorWrapper}
        onSelect={setTransformType}
        onClose={() => {
          onClose(false)
        }}
      />
    )
  }

  if (transformType === "rewrite") {
    return (
      <RewritePopover
        editor={editor}
        editorWrapper={editorWrapper}
        onClose={onClose}
        range={range}
      />
    )
  } else {
    return (
      <TransformToComponentPopover
        editor={editor}
        editorWrapper={editorWrapper}
        onClose={onClose}
        range={range}
        transformType={transformType}
      />
    )
  }
}
