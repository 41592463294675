import { useEffect } from "react"

import registerAudioCommands from "./registerAudioCommands"
import registerVideoCommands from "./registerVideoCommands"
import registerImageCommands from "./registerImageCommands"
import registerFontSizeCommand from "./registerFontSizeCommand"
import registerFontFamilyCommand from "./registerFontFamilyCommand"

/**
 * Hook for registering custom Froala commands and buttons in our editor
 *
 * @param options - Option parameters for setting up commands
 */
const useRegisterFroalaCommands = (options: any) => {
  const { displayReactElement, branding } = options

  /**
   * Any custom Froala commands we need to register can be placed inside this useEffect
   */
  useEffect(() => {
    if (!branding) {
      return
    }
    registerFontSizeCommand()
    registerFontFamilyCommand(branding)
    registerAudioCommands(displayReactElement)
    registerVideoCommands(displayReactElement)
    registerImageCommands(displayReactElement)
  }, [branding, displayReactElement])
}

export default useRegisterFroalaCommands
