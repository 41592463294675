import React from "react"
import { withRouter } from "react-router-dom"
import { AppBar, Toolbar, Container, Link } from "@mui/material"
import AccountMenu from "../widgets/AccountMenu"

const PageNav = (props) => {
  const { fixed, maxWidth, isStatic } = props

  return (
    <>
      <AppBar
        position={isStatic ? "static" : "fixed"}
        sx={
          isStatic
            ? { backgroundColor: "white", boxShadow: "none" }
            : { backgroundColor: "lexWhite" }
        }
      >
        <Container fixed={fixed} maxWidth={maxWidth}>
          <Toolbar
            sx={{
              padding: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
              justifyContent: "space-between",
            }}
          >
            <Link href="/" underline="none" variant="h5">
              <img
                style={{ marginTop: "4px" }}
                src="/lex-icon-wordmark-557x202.png"
                alt="Learn Experts Logo"
                height="40px"
              />
            </Link>

            <AccountMenu size={32} edge="end" />
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar sx={{ mb: 4 }} />
    </>
  )
}

export default withRouter((props) => <PageNav {...props} />)
