import axiosGlobal from "./axios"
import uploadCourseDocumentsToS3 from "./uploadCourseDocumentsToS3"

/**
 * @typedef {import("axios").AxiosInstance} AxiosInstance
 *
 * @typedef {object} UploadCourseDocumentConfig
 * @property {AxiosInstance} axios Thsd.
 */

/**
 * Upload a document for course creation.
 *
 * @param options Options for upload
 * @returns The async job for the upload process.
 */
const uploadCourseDocument = (options = {}) => {
  if (options.rolloutDirectS3Upload) {
    return uploadCourseDocumentsToS3(options)
  } else {
    return uploadCourseDocumentToWeb(options)
  }
}

/**
 * Upload course document directly tp Web API, without going through S3
 *
 * @param options Options for upload
 */
const uploadCourseDocumentToWeb = (options = {}) => {
  const {
    applyTemplates,
    selectedItems,
    learningPath,
    courseID,
    axios = axiosGlobal,
    setUploadProgress,
  } = options

  const data = new FormData()
  for (const uploadItem of selectedItems) {
    for (const uploadFile of uploadItem.files) {
      data.append("" + uploadFile.index, uploadFile.file, uploadFile.name)
      delete uploadFile.file
    }
  }

  data.append("apply_templates", applyTemplates)
  data.append("file_info", JSON.stringify(selectedItems))
  if (learningPath) {
    data.append("learning_path", learningPath)
  }
  if (courseID) {
    data.append("course_id", courseID)
  }

  const axiosConfig = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      if (setUploadProgress) {
        if (percentCompleted === 100) {
          setUploadProgress([{ message: "Processing documents..." }])
        } else {
          setUploadProgress([
            { message: `Uploading documents (${percentCompleted}%)` },
          ])
        }
      }
    },
  }

  return axios.post("/documents/", data, axiosConfig).then(({ data }) => data)
}

export default uploadCourseDocument
