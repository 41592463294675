import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Alert,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import checkForValidAudioURL from "./checkForValidAudioURL"
import { useMixpanelAudioTracker } from "../../utilities/mixpanel"
import { useLocation } from "react-router-dom"

export type AudioData = {
  title: string
  url: string
}

type InsertAudioDialogProps = {
  initialData: AudioData
  onCancel: () => void
  onSave: (data: AudioData) => void
  editMode?: boolean
}

/**
 * Mount and Audio Clip dialog in the editor
 * @param onClose - Callback when dialog is closed
 * @param initialData - Data to display in form fields initially
 */
export const createInsertAudioDialog = (
  onClose: (data: any) => void,
  initialData: AudioData
) => {
  return (
    <InsertAudioDialog
      initialData={initialData}
      onSave={(data: any) => {
        onClose(data)
      }}
      onCancel={() => onClose(null)}
    />
  )
}

/**
 * Dialog to display for inserting audio clip
 * @param props - Component props
 */
const InsertAudioDialog = (props: InsertAudioDialogProps) => {
  const { initialData, onCancel, onSave, editMode } = props

  const [data, setData] = useState<AudioData>(initialData)
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)

  const { title, url } = data

  const insertDisabled = title === "" || url === ""

  useEffect(() => {
    if (url === "") {
      setInvalidMessage(null)
      return
    }
    const message = checkForValidAudioURL(url)
    setInvalidMessage(message)
  }, [url])

  const trackAudio = useMixpanelAudioTracker()

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  function useCourseId() {
    const query = useQuery()
    return query.get("course")
  }

  const courseId = useCourseId()

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>{editMode ? "Edit Audio" : "Insert Audio"}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <TextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            margin="dense"
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e: any) => {
              setData((prev) => ({
                ...prev,
                title: e.target.value,
              }))
            }}
            sx={{ flex: " 3 1 0" }}
          />
          <TextField
            margin="dense"
            label="Paste in an audio URL"
            variant="outlined"
            fullWidth
            value={url}
            onChange={(e: any) => {
              setData((prev) => ({
                ...prev,
                url: e.target.value,
              }))
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          ></Stack>
          {invalidMessage && <Alert severity="error">{invalidMessage}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={insertDisabled}
          color="primary"
          variant="contained"
          onClick={() => {
            const cleanedURL = data.url.trim().replace(/['"\s]/g, "")
            if (!editMode) {
              trackAudio(title, cleanedURL, courseId || "noCourseID")
            }
            onSave({
              ...data,
              url: cleanedURL,
            })
          }}
        >
          {editMode ? "Update" : "Insert"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InsertAudioDialog
