import { useSelector } from "react-redux"
import createUploadSelector from "../store/jobs/createUploadSelector"

/**
 * This hook retrieves the state for a specific upload.
 *
 * @param {string} id
 */
const useUploadByID = (id) => useSelector(createUploadSelector(id))

export default useUploadByID
