import { Stack, StackProps } from "@mui/material"
import { motion } from "framer-motion"

type FormProps = StackProps<typeof motion.form>

/**
 * Container for form fields.
 * @param props The component props.
 */
function Form(props: FormProps) {
  const { children, ...otherProps } = props

  return (
    <Stack
      layoutId="sign-in-form"
      layout="position"
      component={motion.form}
      gap={2}
      width="100%"
      {...otherProps}
    >
      {children}
    </Stack>
  )
}

export default Form

type ActionBarProps = StackProps<typeof motion.div>

/**
 * Container for form actions.
 * @param props The component props.
 */
function ActionBar(props: ActionBarProps) {
  const { children, ...otherProps } = props
  return (
    <Stack
      key="signin-form-actions"
      component={motion.div}
      layoutId="action-bar"
      direction="row-reverse"
      justifyContent="space-between"
      gap={1}
      {...otherProps}
    >
      {children}
    </Stack>
  )
}

Form.ActionBar = ActionBar
