// Adapter for react-window
import {
  createContext,
  forwardRef,
  HTMLAttributes,
  ReactChild,
  useContext,
  useEffect,
  useRef,
} from "react"
import { useTheme } from "@mui/styles"
import { Typography, useMediaQuery } from "@mui/material"
// @ts-ignore
import { VariableSizeList, ListChildComponentProps } from "react-window"

const OuterElementContext = createContext({})

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

/**
 * Get the ref to be used by the virtualized Listbox
 * @param data - All items in list
 */
const useResetCache = (data: any) => {
  const ref = useRef<VariableSizeList>(null)
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

/**
 * Function for displaying each row element. Attaches google font style to each
 * which will already be loaded in browser at this point. For the Recent Fonts
 * section, it will also apply a border style to separate it from the main list
 * @param props - Component props
 */
const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props
  const dataSet = data[index]

  const inlineStyle = {
    ...style,
    top: (style.top as number) + 8,
    ...(dataSet[1].bottom && {
      borderBottom: "1px solid black",
    }),
  }

  return (
    <li {...dataSet[0]} style={inlineStyle}>
      <Typography
        sx={{
          font: `1rem "${dataSet[1].name}"`,
        }}
      >
        {dataSet[1].name}
      </Typography>
    </li>
  )
}

/**
 * Component for displaying virtualized font list in Autocomplete component.
 * Modelled after approach seen here: https://codesandbox.io/s/wubkug?file=/demo.tsx
 *
 * Returns a VariableSizeList from react-window, wrapped in a context to make
 * scrolling through large font list more performant.
 */
const ListBoxComponent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: ReactChild[] = []
  ;(children as ReactChild[]).forEach(
    (item: ReactChild & { children?: ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const theme = useTheme()
  // @ts-ignore
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  /**
   * Return size of row for calculating spacing in list
   * @param child - Element to obtain size for
   */
  const getChildSize = (child: ReactChild) => {
    if (child.hasOwnProperty("group")) {
      return 48
    }

    return itemSize
  }

  /**
   * Calculate overall height of virtualized listbox
   */
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * 8}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: number) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

export default ListBoxComponent
