/**
 * Make a link for the share settings screen.
 *
 * @param {object?} course
 *    When provided the link will pre-select the course on the settings page.
 * @param {object?} config
 * @param {bool} config.modal
 *    When true, link to the dialog modal.
 * @returns
 */
const makeShareSettingsHref = (course, config = {}) => {
  const { modal = false } = config

  // To navigate to the dialog modal, we link to the current location with a new
  // state including the "share" flag.
  if (modal && course) {
    return (location) => ({
      ...location,
      state: { share: course.id },
    })
  }

  return (location) => {
    return {
      pathname: "/share",
      search: course ? `?course=${course.id}` : undefined,
      state: {
        // If we're coming from the course, we'll override the breadcrumbs to go
        // back to it.
        ...(location.pathname === "/editor" && {
          breadcrumbs: [
            {
              label: "Courses",
              href: "/",
            },
            {
              label: course.title,
              href: location,
            },
          ],
        }),
      },
    }
  }
}

export default makeShareSettingsHref
