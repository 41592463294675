import { styled } from "@mui/system"
import { Box } from "@mui/material"

/** Box for contents that should be styled with CSS to match the Froala editor */
export const FroalaCSSBox = styled(Box)(() => ({
  "& img": {
    maxWidth: "100%",
    position: "relative",
  },
  /** Fix image alignment outside of editor */
  "& img.fr-dib": {
    margin: "5px auto",
    display: "block",
    float: "none",
    verticalAlign: "top",
  },
  /** Fix image caption outside of editor */
  "& .fr-img-caption .fr-img-wrap": {
    padding: 0,
    margin: "auto",
    textAlign: "center",
    width: "100%",
  },
  "& .fr-img-caption .fr-img-wrap > span": {
    margin: "auto",
    display: "block",
    padding: "5px 5px 10px",
    fontSize: "14px",
    fontWeight: "initial",
    boxSizing: "border-box",
    opacity: 0.9,
    width: "100%",
    textAlign: "center",
  },
  /** Additional styles for rounded, shadow, and bordered images */
  "& img.fr-rounded, & .fr-img-caption.fr-rounded img": {
    borderRadius: "10px",
    backgroundClip: "padding-box",
  },
  "& img.fr-shadow, & .fr-img-caption.fr-shadow img": {
    boxShadow: "10px 10px 5px 0px #cccccc",
  },
  "& img.fr-bordered, & .fr-img-caption.fr-bordered img": {
    border: "solid 5px #ccc",
  },
  "& img.fr-bordered": {
    boxSizing: "content-box",
  },
  "& .fr-img-caption.fr-bordered img": {
    boxSizing: "border-box",
  },
}))
