import { useAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import SignInScreen from "../features/authentication/SignInScreen"

/** Renders the login page. */
const Login = () => {
  useAuthenticatedRedirect()
  return <SignInScreen />
}

export default Login

/** Redirect the authenticated users back to the application. */
const useAuthenticatedRedirect = () => {
  const { route } = useAuthenticator((context) => [context.route])
  const history = useHistory()

  useEffect(() => {
    if (route === "authenticated") {
      let from = history.location.state?.from || "/"
      history.replace(from ?? "/")
    }
  }, [route, history, history.location])
}
