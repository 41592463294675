import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import fetchFolders from "../api/fetchFolders"
import pluralize from "../../outline/utils/pluralize"

type MoveItemDialogProps = {
  onConfirm: (itemsToMove: any[], destination: string) => void
  onCancel: () => void
  itemsToMove: any[]
  currentDirectory: string | null
}

/**
 * Dialog for moving courses between folders
 * @param props - Component props
 */
const MoveItemDialog = (props: MoveItemDialogProps) => {
  const { itemsToMove, currentDirectory, onConfirm, onCancel } = props

  const [selectedFolder, setSelectedFolder] = useState<any | null>()
  const [availableFolders, setAvailableFolders] = useState<any[]>([])

  useEffect(() => {
    fetchFolders().then((data) => {
      setAvailableFolders(
        data.filter((folder: any) => folder.id !== currentDirectory)
      )
    })
  }, [currentDirectory, itemsToMove])

  return (
    <Dialog onClose={onCancel} open={true} maxWidth="sm" fullWidth>
      <DialogTitle>Move Items</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a destination folder for the selected items.
        </DialogContentText>
        <FormControl sx={{ marginBottom: 2 }} variant="standard" fullWidth>
          <InputLabel>Folder</InputLabel>
          <Select
            onChange={(e) => setSelectedFolder(e.target.value)}
            label="Course"
            value={selectedFolder}
          >
            {currentDirectory !== null && (
              <MenuItem value={"all_courses"}>All Courses</MenuItem>
            )}
            {availableFolders.map((folder) => (
              <MenuItem key={folder.id} value={folder}>
                {folder.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          {itemsToMove.length > 1 ? (
            <>
              <DialogContentText>
                The following items will be moved to this folder.
              </DialogContentText>
              <List
                dense
                sx={{
                  position: "relative",
                  overflow: "auto",
                  maxHeight: "8rem",
                }}
              >
                {itemsToMove.map((course: any) => (
                  <ListItem>
                    <ListItemText sx={{ margin: 0 }}>
                      {course.title}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <Typography variant="body1" align="justify">
              {`Move the selected course "${itemsToMove[0].title}"?`}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={selectedFolder == null}
          onClick={(e) => {
            e.preventDefault()
            const destination =
              selectedFolder === "all_courses" ? null : selectedFolder
            onConfirm(itemsToMove, destination)
          }}
        >
          Move {pluralize("item", itemsToMove.length, true)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MoveItemDialog
