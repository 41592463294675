// src/utilities/stringUtilities.ts

/**
 * Sanitizes and shortens a string to be safe for use as a filename.
 * It removes unsafe characters and limits the length to a maximum.
 *
 * @param {string} input The input string to sanitize and shorten.
 * @param {number} maxLength The maximum length of the output string.
 * @returns {string} The sanitized and shortened string.
 */
export const sanitizeAndShorten = (
  input: string,
  maxLength: number = 30
): string => {
  // replace any characters that are not letters, numbers, hyphens, or underscores
  let sanitized = input.replace(/[^a-zA-Z0-9-_]/g, "_")

  // Replace consecutive underscores with a single underscore
  sanitized = sanitized.replace(/_+/g, "_")

  // Shorten to the maximum length
  if (sanitized.length > maxLength) {
    sanitized = sanitized.substring(0, maxLength)
  }

  return sanitized
}
