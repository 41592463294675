import axiosGlobal from "./axios"

/**
 * Update the share settings for a given course.
 *
 * @param {string} courseID The ID of the course.
 * @param {object} settings
 * @param {bool} settings.isPublic
 *    When true, the course will be made publicly accessible.
 * @returns The updated course.
 */
const updateShareSettings = async (courseID, settings, config = {}) => {
  const { axios = axiosGlobal } = config

  const { data } = await axios.post(`/courses/${courseID}/share`, {
    is_public: settings.isPublic,
  })

  return data
}

export default updateShareSettings
