import {
  Alert,
  Box,
  Button,
  CircularProgress,
  List,
  Stack,
} from "@mui/material"
import { useEffect, useState } from "react"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import CloseIcon from "@mui/icons-material/Close"
import ReplayIcon from "@mui/icons-material/Replay"
import {
  getContentSectionHeading,
  getSectionElements,
  stripTemporaryElements,
} from "../../utilities/smartTemplates"
import * as api from "../../api"
import {
  SmartTemplateControlData,
  SmartTemplatePanelContainer,
} from "./smartTemplateControlCustomElement"
import {
  AddQuestionDialog,
  questionCustomElement,
} from "../questionCustomElement"
import AddIcon from "@mui/icons-material/Add"
import { CustomElements } from "../CustomElements"
import TestQuestionPanel from "../TestQuestionPanel"
import { useFlag } from "../../utilities/feature-management"
import { AddableItem } from "../AddableItem"

const customElementConfigs = [questionCustomElement]

/**
 * Smart template custom component for demonstrations, exercises, test questions and learning objectives.
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.element Root element of component in light DOM.
 * @param props.data The flip card data to render.
 * @param props.onDataChange Callback to update the data.
 * @param props.withStyles Function to wrap children in styles.
 * @param props.templateType Type of smart template (e.g. "demonstration")
 * @param props.namePlural Plural name of the smart template (e.g. Demonstrations)
 */
export function StandardSmartTemplateCustomComponent(props: {
  editor: any
  element: HTMLElement
  data: SmartTemplateControlData
  onDataChange?: (data: SmartTemplateControlData) => void
  withStyles: (children: React.ReactElement) => React.ReactElement
  templateType: string
  namePlural: string
}) {
  const {
    data,
    onDataChange,
    editor,
    element,
    withStyles,
    templateType,
    namePlural,
  } = props

  // True if the panel is open
  const [panelOpen, setPanelOpen] = useState(data.openPanelImmediately)

  // True if adding a question
  const [addingQuestion, setAddingQuestion] = useState(false)

  // Remove update-immediately from data if it is true as the panel is now open
  useEffect(() => {
    if (data.openPanelImmediately && onDataChange) {
      onDataChange({ ...data, openPanelImmediately: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const useNewTemplatePanel = useFlag("rollout-test-questions-user-control")

  return panelOpen ? (
    templateType === "test_question" && useNewTemplatePanel ? (
      <TestQuestionPanel
        editor={editor}
        element={element}
        onClose={() => {
          setPanelOpen(false)
        }}
        withStyles={withStyles}
      />
    ) : (
      <StandardSmartTemplatePanel
        editor={editor}
        element={element}
        onClose={() => {
          setPanelOpen(false)
        }}
        templateType={templateType}
        namePlural={namePlural}
        withStyles={withStyles}
      />
    )
  ) : (
    <>
      {addingQuestion && (
        <AddQuestionDialog
          editor={editor}
          smartTemplateElement={element}
          onClose={() => {
            setAddingQuestion(false)
          }}
        />
      )}
      {withStyles(
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            onClick={() => {
              setPanelOpen(true)
            }}
            size="small"
            startIcon={<AutoFixHighIcon fontSize="small" />}
          >
            Generate {props.namePlural}
          </Button>
          {templateType === "test_question" && (
            <Button
              variant="outlined"
              onClick={() => {
                setAddingQuestion(true)
              }}
              size="small"
              startIcon={<AddIcon fontSize="small" />}
            >
              Add Question
            </Button>
          )}
        </Stack>
      )}
    </>
  )
}

/**
 * Panel for smart template control
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.element Root element of component in light DOM.
 * @param props.onClose Callback to close the panel.
 * @param props.templateType Type of smart template (e.g. "demonstration")
 * @param props.namePlural Plural name of the smart template (e.g. Demonstrations)
 * @param props.withStyles Function to wrap children in styles.
 */
function StandardSmartTemplatePanel(props: {
  editor: any
  element: HTMLElement
  onClose: () => void
  templateType: string
  namePlural: string
  withStyles: (children: React.ReactElement) => React.ReactElement
}) {
  const { editor, element, onClose, templateType, namePlural, withStyles } =
    props

  // Outer div of editor
  const [outerDiv, setOuterDiv] = useState<HTMLDivElement | null>(null)

  // True if loading
  const [loading, setLoading] = useState(false)

  // List of items (html) to add
  const [items, setItems] = useState<string[]>([])

  // Server message
  const [message, setMessage] = useState("")

  // Indexes of items that have been added
  const [addedIndexes, setAddedIndexes] = useState<number[]>([])

  /** Generate items by calling server */
  async function generateItems() {
    try {
      setLoading(true)

      // Get template heading
      const smartTemplateId = element.dataset.smartTemplateId!
      const smartTemplateHeading = document.getElementById(smartTemplateId)

      if (!smartTemplateHeading) {
        setMessage(
          "Smart template heading not found. Smart templates must be inside a heading."
        )
        return
      }

      // Get content section heading
      const contentSectionHeading =
        getContentSectionHeading(smartTemplateHeading)

      if (!contentSectionHeading) {
        setMessage(
          "Content section not found. Smart templates must be inside a higher level heading."
        )
        return
      }

      let courseHtml = stripTemporaryElements(editor.html.get())

      const data = await api.createTemplate(
        templateType,
        courseHtml,
        contentSectionHeading.id,
        null
      )

      // Extract message from server response
      setMessage(data.data.message ?? "")

      // Reset added indexes
      setAddedIndexes([])

      // Get HTML
      const html = data.data.smart_template_html

      // Parse HTML to extract items
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, "text/html")

      if (templateType === "demonstration") {
        // Extract list items and convert to paragraphs
        const items = [...doc.querySelectorAll("li")].map(
          (li) => `<p>${li.innerHTML}</p>`
        )
        setItems(items)
      } else if (templateType === "exercise") {
        // Extract all but first paragraph
        const items = [...doc.querySelectorAll("p")]
          .slice(1)
          .map((p) => p.outerHTML)
        setItems(items)
      } else if (templateType === "test_question") {
        // Extract all sections
        const items = [...doc.querySelectorAll("section")].map(
          (section) => section.outerHTML
        )
        setItems(items)
      } else if (templateType === "learning_objectives") {
        // Extract all list items
        const items = [...doc.querySelectorAll("li")].map(
          (li) => `<p>${li.innerHTML}</p>`
        )
        setItems(items)
      }
    } catch (err) {
      setMessage(`Error generating ${namePlural}. Please try again.`)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    generateItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Add item to document below control
   * @param item The item to add.
   * @param index The index of the item in the list of items.
   */
  function handleAdd(item: string, index: number) {
    // Get template heading
    const smartTemplateId = element.dataset.smartTemplateId!
    const smartTemplateHeading = document.getElementById(smartTemplateId)!

    // Get existing smart template section elements
    const existingSectionElements = getSectionElements(smartTemplateHeading)

    // Learning objectives are added as list items to a list, creating a list if necessary
    if (templateType === "learning_objectives") {
      // Extract inner html of paragraph
      const innerHtml = item.match(/<p>(.*)<\/p>/)![1]

      const itemAsLI = `<li>${innerHtml}</li>`

      // Find existing list
      const existingList = existingSectionElements.find(
        (element) => element.tagName === "UL"
      )
      if (existingList) {
        // Add to end of existing list
        existingList.insertAdjacentHTML("beforeend", itemAsLI)
      } else {
        // True if learning objectives are for entire course
        const isForEntireCourse = smartTemplateHeading.tagName === "H2"

        // Create new list with lead-in
        const leadIn = isForEntireCourse
          ? `<p>In this course, you will learn to:</p>`
          : `<p>In this lesson, you will learn to:</p>`

        const list = document.createElement("ul")
        list.innerHTML = itemAsLI

        // Add to end of last element in section
        const lastSectionElement =
          existingSectionElements[existingSectionElements.length - 1]
        lastSectionElement.insertAdjacentElement("afterend", list)
        lastSectionElement.insertAdjacentHTML("afterend", leadIn)
      }
    } else {
      // Add to end of last element in section
      const lastSectionElement =
        existingSectionElements[existingSectionElements.length - 1]
      lastSectionElement.insertAdjacentHTML("afterend", item)
    }

    // Remove blank line if it is the last existing element
    const lastExistingElement =
      existingSectionElements[existingSectionElements.length - 1]
    if (lastExistingElement && lastExistingElement.innerHTML === "<br>") {
      lastExistingElement.remove()
    }

    // Add to list of added indexes
    setAddedIndexes((addedIndexes) => [...addedIndexes, index])

    // Save undo step in editor
    editor.undo.saveStep()
  }

  return withStyles(
    <SmartTemplatePanelContainer ref={setOuterDiv}>
      {outerDiv && (
        <CustomElements
          key="custom-elements"
          editor={null}
          editorDiv={outerDiv}
          configs={customElementConfigs}
          readOnly={true}
        />
      )}
      <Stack
        borderRadius={2}
        sx={{
          overflow: "hidden",
          padding: 0,
        }}
      >
        <Box key="content" sx={{ p: 1, height: "50vh", overflowY: "auto" }}>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {items.map((item, index) => (
                <AddableItem
                  key={index}
                  added={addedIndexes.includes(index)}
                  onAdd={handleAdd.bind(null, item, index)}
                >
                  <div dangerouslySetInnerHTML={{ __html: item }} />
                </AddableItem>
              ))}
            </List>
          )}
        </Box>
        {message !== "" && !loading && (
          <Alert severity="warning">{message}</Alert>
        )}
        <Stack
          direction="row"
          key="header"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            /**
             * Set background to be shade of MUI grey
             * @param theme - MUI theme
             */
            backgroundColor: (theme) => theme.palette.grey[200],
            p: 2,
          }}
        >
          {loading ? (
            <Button
              variant="text"
              onClick={() => {
                onClose()
              }}
              startIcon={<CloseIcon fontSize="small" />}
            >
              Cancel
            </Button>
          ) : (
            <Stack spacing={2} direction="row">
              {items.length > 0 && (
                <Button
                  variant="text"
                  onClick={() =>
                    items.forEach((item, index) => handleAdd(item, index))
                  }
                  startIcon={<AddIcon fontSize="small" />}
                >
                  Add All
                </Button>
              )}
              <Button
                variant="text"
                onClick={() => {
                  generateItems()
                }}
                startIcon={<ReplayIcon fontSize="small" />}
              >
                Regenerate
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  onClose()
                }}
                startIcon={<CloseIcon fontSize="small" />}
              >
                Close
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </SmartTemplatePanelContainer>
  )
}
