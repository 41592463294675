import { Button, Collapse, ListItemButton, Stack, Theme } from "@mui/material"
import { Item } from "../../../../homeNavigation/types"
import LearningPathItem from "./LearningPathItem"
import { getTitle } from "../../../../../utilities/transformers"
import { Add, Timeline } from "@mui/icons-material"
import { useState } from "react"
import LearningPathContextMenu from "../../../../learningPaths/LearningPathContextMenu"
import ListItemContent from "../../../itemList/ListItemContent"
import AvatarWrapper from "../../../itemList/AvatarWrapper"
import LearningPathActionDialogs from "./LearningPathActionDialogs"
import { DndContext } from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { useHistory } from "react-router"
import ListItemControlWrapper from "../../../itemList/ListItemControlWrapper"

/**
 * Component to display learning path and enclosed courses
 * @param props - Component props
 */
const LearningPath = (props: any) => {
  const { path, removeFromPath, reorderInPath, ...otherProps } = props
  const { title, courses, loading } = path
  const [anchorEl, setAnchorEl] = useState(null)
  const [renameDialogVisible, setRenameDialogVisible] = useState(false)
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [addDialogVisible, setAddDialogVisible] = useState(false)

  const [pathOpen, setPathOpen] = useState(false)

  const history = useHistory()

  const courseIDs = courses.map((course: Item) => course.id)

  const dialogProps = {
    renameDialogVisible,
    setRenameDialogVisible,
    deleteDialogVisible,
    setDeleteDialogVisible,
    addDialogVisible,
    setAddDialogVisible,
    setAnchorEl,
  }

  /**
   * When a path is dragged and dropped, invoke a reorder operation
   * @param event - Drag end event
   */
  const handleDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      reorderInPath(path, active, over)
    }
  }

  return (
    <>
      <ListItemButton
        onClick={() => setPathOpen((prev) => !prev)}
        disableRipple
        divider
      >
        <ListItemContent
          avatar={
            <AvatarWrapper variant="circle">
              <Timeline color="primary" />
            </AvatarWrapper>
          }
          primaryText={getTitle(title)}
          control={
            <LearningPathContextMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              renameClicked={(e: any) => {
                e.preventDefault()
                setRenameDialogVisible(true)
              }}
              deleteClicked={(e: any) => {
                e.preventDefault()
                setDeleteDialogVisible(true)
              }}
            />
          }
        />
      </ListItemButton>
      <Collapse in={pathOpen}>
        <Stack
          sx={(theme: Theme) => ({
            backgroundColor: theme.scheme.surfaceContainerLow,
            opacity: loading ? 0.5 : 1,
          })}
        >
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext
              items={courseIDs}
              strategy={verticalListSortingStrategy}
            >
              {courses.map((item: Item) => (
                <LearningPathItem
                  item={item}
                  onRemove={() => removeFromPath(path, item)}
                  onClick={() => history.push(`/editor/?course=${item.id}`)}
                />
              ))}
            </SortableContext>
          </DndContext>
          <ListItemControlWrapper>
            <Button
              startIcon={<Add />}
              onClick={(e: any) => {
                e.preventDefault()
                setAddDialogVisible(true)
              }}
              variant="text"
            >
              Link course
            </Button>
          </ListItemControlWrapper>
        </Stack>
      </Collapse>
      <LearningPathActionDialogs path={path} {...dialogProps} {...otherProps} />
    </>
  )
}

export default LearningPath
