/**
 * Convert the given duration in minutes to a string that includes hours and minutes
 *
 * 30 -> 30 minutes
 * 60 -> 1 hour
 * 200 -> 3 hours 20 minutes
 * 480 -> 8 hours
 * 481 -> 8 hours 1 minute
 * 1082 -> 18 hours 2 minutes
 *
 * @param {number} duration - number of minutes
 */
function formatDuration(duration) {
  if (duration < 1) {
    return "< 1 minute"
  }

  let minutesPerhour = 60
  let hours = Math.floor(duration / minutesPerhour)
  let minutes = duration % minutesPerhour

  let formatString = ""
  formatString += hours > 0 ? `${hours} ${hours > 1 ? "hours" : "hour"}` : ""
  formatString += formatString && minutes > 0 ? " " : ""
  formatString +=
    minutes > 0
      ? `${minutes.toFixed(0)} ${minutes.toFixed(0) > 1 ? "minutes" : "minute"}`
      : ""

  return formatString
}

export default formatDuration
