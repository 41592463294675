import ChevronLeft from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"
import PropTypes from "prop-types"
import {
  Divider,
  IconButton,
  Toolbar,
  Drawer as MuiDrawer,
  styled,
  Stack,
  Tooltip,
} from "@mui/material"

/** Override styles for the MUI Drawer. */
const DrawerBase = styled(MuiDrawer, {
  shouldForwardProp: (propName) => propName !== "permanentAbove",
})(
  ({ width }) => ({
    "& .MuiDrawer-paper": {
      width,
      maxWidth: width,
    },
  }),
  ({ theme, width, open, anchor, variant }) =>
    variant === "permanent" && {
      width: open ? width : theme.spacing(4),
      position: "relative",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
      ...(!open && {
        "& .MuiToolbar-root": {
          padding: theme.spacing(1),
        },
      }),
      "& .MuiDrawer-paper": {
        left: anchor === "right" ? 0 : "initial",
        right: anchor === "right" ? "initial" : 0,
        position: "absolute",
        width,
        ...(!open && {
          overflow: "clip",
        }),
      },
    }
)

/**
 * Drawer is a collapsible container for content at the sides of the screen.
 */
const Drawer = (props) => {
  const {
    anchor,
    name,
    open,
    openMobile,
    isMobile,
    children,
    onClose,
    width = "260px",
    permanentAbove = "md",
    toolbar,
    onToggle,
    ...otherProps
  } = props

  let CloseIcon, OpenIcon

  // Determine the direction to face the expand and retract arrow icons, based on the side of the screen the drawer is on
  if (anchor === "right") {
    CloseIcon = ChevronRight
    OpenIcon = ChevronLeft
  } else {
    CloseIcon = ChevronLeft
    OpenIcon = ChevronRight
  }

  const contents =
    // This is a bit of a strange check but it essentially ensures that we don't collapse the drawer while still rendering content within it
    (open && !openMobile && !isMobile) || (openMobile && isMobile) ? (
      <>
        <Toolbar />
        <Toolbar
          sx={{ flexDirection: anchor === "right" ? "row" : "row-reverse" }}
        >
          <Tooltip title={"Collapse " + name}>
            <IconButton
              onClick={onToggle}
              disableRipple
              edge={anchor === "right" ? "start" : "end"}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          {toolbar}
        </Toolbar>
        <Divider />
        <Stack
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            minHeight: 100,
            overflowY: "auto",
          }}
        >
          {children}
        </Stack>
      </>
    ) : (
      <>
        <Toolbar />
        <Toolbar
          sx={{
            flexDirection: anchor === "right" ? "row" : "row-reverse",
            padding: 0,
          }}
        >
          <Tooltip title={"Expand " + name}>
            <IconButton
              disableRipple
              onClick={onToggle}
              edge={anchor === "right" ? "start" : "end"}
            >
              <OpenIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </>
    )

  const drawerProps = {
    anchor,
    open,
    onClose,
    permanentAbove,
    width,
    ...otherProps,
  }

  return (
    <>
      {/*
        Keep both the temporary and permenant drawers mounted, but swap which is
        displayed above the specified breakpoint.
        (ref: https://mui.com/components/drawers/#responsive-drawer)
      */}
      <DrawerBase
        {...drawerProps}
        open={openMobile}
        variant="temporary"
        sx={{ display: { xs: "block", [permanentAbove]: "none" } }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {contents}
      </DrawerBase>
      <DrawerBase
        {...drawerProps}
        open={open && !openMobile}
        variant="permanent"
        sx={{
          //Set below the editor zIndex
          //https://mui.com/customization/z-index/
          zIndex: (theme) => theme.zIndex.drawer - 200,
        }}
      >
        {contents}
      </DrawerBase>
    </>
  )
}

Drawer.propTypes = {
  ...MuiDrawer.propTypes,
  permanentAbove: PropTypes.string,
  /**
   * Arranges extra elements above the drawer contents container.
   *
   * @example
   * ```jsx
   * <Drawer
   *   anchor="right"
   *   toolbar={
   *     <>
   *       <LearnAdvisorLogo />
   *       <IconButton>
   *         <FilterIcon />
   *       </IconButton>
   *     </>
   *   }
   *  >
   *    <!-- Drawer contents -->
   *  </Drawer>
   * ```
   */
  toolbar: PropTypes.node,
}

export default Drawer
