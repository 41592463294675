import { ListItem, Stack } from "@mui/material"
import { ReactNode } from "react"

/**
 * Renders any controls that should appear as a list item
 * @param props - Component props
 * @param props.children - React node to render within list item
 */
const ListItemControlWrapper = (props: { children: ReactNode }) => {
  const { children } = props
  return (
    <ListItem
      divider
      sx={{
        height: "72px",
      }}
    >
      <Stack
        width="100%"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Stack>
    </ListItem>
  )
}
export default ListItemControlWrapper
