import {
  addHeadingAfter,
  addHeadingBefore,
  removeSection,
  rename,
  shiftSection,
} from "../utils/headerOperations"

const useHeaderOperations = (container, updateDocument) => {
  const promote = ($element) => {
    shiftSection($element, { container, shiftAmount: -1 })
    updateDocument(container.innerHTML)
  }

  const demote = ($element) => {
    shiftSection($element, { container, shiftAmount: 1 })
    updateDocument(container.innerHTML)
  }

  const addHeading = ($element, title, position) => {
    if (position === "before") {
      addHeadingBefore($element, title)
    } else if (position === "after") {
      addHeadingAfter($element, title)
    } else if (position === "sub") {
      addHeadingAfter($element, title, { subheading: true })
    }
    updateDocument(container.innerHTML)
  }

  const deleteSection = ($element) => {
    removeSection($element, { container })
    updateDocument(container.innerHTML)
  }

  const renameHeading = ($element, text) => {
    rename($element, text)
    updateDocument(container.innerHTML)
  }

  return {
    addHeading,
    deleteSection,
    demote,
    promote,
    renameHeading,
  }
}

export default useHeaderOperations
