import axiosInstance from "../../../api/axios"

type RenameFolderArg = {
  id: string
  new_title: string
}

/**
 * Send request to rename folder
 * @param arg - ID and title for renaming folder
 */
const renameFolder = (arg: RenameFolderArg) => {
  const { id, new_title } = arg
  return axiosInstance
    .put(`/folders/${id}`, { new_title })
    .then((response) => response.data)
}

export default renameFolder
