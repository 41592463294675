import axios from "../../../api/axios"

/**
 * Send request to server to retrieve HTML preview for given page of Micro-Learning
 * @param arg - Object with Micro-Learning config parameters
 */
const previewMicroLearning = (arg: any) => {
  const {
    courseId,
    optionList,
    optionId,
    organizeBy,
    condensePercent,
    transformComponentsToText,
    transformMediaToText,
  } = arg

  return axios.post("/microlearning/preview", {
    courseId,
    organizeBy,
    optionId,
    optionList,
    condensePercent,
    transformComponentsToText,
    transformMediaToText,
  })
}

export default previewMicroLearning
