const DEFAULT_PLURAL_SUFFIX = "s"

/**
 * Pluralize a word given its count.
 *
 * @param {string} word A word in singular form.
 * @param {number} count A count.
 * @param {boolean} inclusive When `true`, the returned word is prefixed with
 *  the given count.
 * @returns {string}
 */
const pluralize = (word, count, inclusive = false) => {
  if (count !== 1) {
    word = word + DEFAULT_PLURAL_SUFFIX
  }

  return inclusive ? `${count} ${word}` : word
}

export default pluralize
