import axiosInstance from "../../../api/axios"

type CreateFolderArg = {
  title: string
}

/**
 * Send request to create new folder
 * @param arg - Contains title parameter for new folder
 */
const createFolder = (arg: CreateFolderArg) =>
  axiosInstance.post("/folders", arg).then((response) => response.data)

export default createFolder
