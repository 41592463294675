import axiosInstance from "../../../api/axios"

type MoveDirectoryItemArgs = {
  item_ids: string[]
  destination_folder: string | null
}

/**
 * Send request to move courses to different folder
 * @param args - IDs of items to move and ID of destination
 */
const moveDirectoryItem = (args: MoveDirectoryItemArgs) =>
  axiosInstance
    .post("/folders/move_items", args)
    .then((response) => response.data)

export default moveDirectoryItem
