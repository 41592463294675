import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../store"
import { setUser as setReduxUser } from "../store/auth"
import { useAuthenticator } from "@aws-amplify/ui-react"

const asUser = (authData) => {
  if (authData && authData.idToken && authData.idToken.payload) {
    let authName = authData.idToken.payload["cognito:username"]
    let authEmail = authData.idToken.payload["email"]
    let id = authData.idToken.payload["sub"]
    return {
      name: authName,
      email: authEmail,
      initials: authEmail?.slice(0, 2),
      id: id,
    }
  } else {
    return null
  }
}

const useProvideAuth = () => {
  const [user, setStateUser] = useState(null)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { user: amplifyUser, signOut: amplifySignOut } = useAuthenticator(
    (context) => [context.user]
  )

  const { route } = useAuthenticator((context) => [context.route])

  const setUser = useCallback(
    (user) => {
      setStateUser(user)
      dispatch(setReduxUser(user))
    },
    [dispatch]
  )

  // Update the context's user when the user is fetched from Cognito
  useEffect(() => {
    setUser(asUser(amplifyUser?.getSignInUserSession()))
  }, [amplifyUser, setUser])

  /**
   * Sign user out and redirect to login
   */
  const signOut = async () => {
    history.push("/login")
    amplifySignOut()
  }

  return {
    user,
    signOut,
    route,
  }
}

export default useProvideAuth
