import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  Menu,
  MenuItem,
} from "@mui/material"
import { Prompt } from "react-router-dom"
import PageLayout from "../../components/layouts/PageLayout"
import { TranslationRulesTable } from "./TranslationRulesTable"
import useTranslationRules from "./hooks/useTranslationRules"
import { Translate, ArrowDropDown } from "@mui/icons-material"
import {
  localeNames,
  availableTranslationLocales,
} from "../export/translationLocales"
import { useState } from "react"

/**
 * Screen for displaying and editing translation rules.
 */
export const TranslationRulesScreen = () => {
  const {
    isLoading,
    hasChanges,
    error,
    setError,
    translationRules,
    handleDataChange,
  } = useTranslationRules()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedLocale, setSelectedLocale] = useState<string>("fr")

  if (isLoading) {
    return (
      <PageLayout fixed maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </PageLayout>
    )
  }

  return (
    <PageLayout
      fixed
      maxWidth="lg"
      breadcrumbs={[
        { label: "Courses", href: "/" },
        { label: "Tools", href: "/tools" },
        { label: "User-Defined Translations" },
      ]}
    >
      <Prompt
        when={hasChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Box>
        <Typography gutterBottom variant="h4">
          User-Defined Translations
        </Typography>
        {error && (
          <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {translationRules && (
          <>
            <Typography variant="body1" paragraph>
              Here you can define common translations that you would like to
              automatically apply as part of any translated export. This might
              include proper product or business names, or just preferred
              language.
            </Typography>
            <Box sx={{ pb: 2, mb: 2, borderBottom: "1px solid #e0e0e0" }}>
              <Box sx={{ display: "inline-block", position: "relative" }}>
                <Button
                  aria-controls="language-menu"
                  aria-haspopup="true"
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  startIcon={<Translate />}
                  endIcon={<ArrowDropDown />}
                  variant="contained"
                >
                  {localeNames[selectedLocale]}
                </Button>
                <Menu
                  id="language-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {availableTranslationLocales.map((locale) => (
                    <MenuItem
                      key={locale}
                      onClick={() => {
                        setSelectedLocale(locale)
                        setAnchorEl(null)
                      }}
                    >
                      {localeNames[locale]}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
            <TranslationRulesTable
              data={translationRules}
              onDataChange={handleDataChange}
              selectedLocale={selectedLocale}
            />
          </>
        )}
      </Box>
    </PageLayout>
  )
}
