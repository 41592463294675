import { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectRemarksFilter, useAppDispatch } from "../store"
import { filterByType, filterToResolved } from "../store/remarks"

/** Use the current remarks filter state value. */
export const useRemarksFilterValue = () => useSelector(selectRemarksFilter)

/** Use the remarks filter actions. */
export const useRemarksFilterDispatch = () => {
  const dispatch = useAppDispatch()

  return useMemo(
    () => ({
      filterToResolved: (value) => dispatch(filterToResolved(value)),
      filterByType: (type) => dispatch(filterByType(type)),
    }),
    [dispatch]
  )
}

/** Use the remarks filter state. */
const useRemarksFilter = () => {
  return {
    ...useRemarksFilterValue(),
    ...useRemarksFilterDispatch(),
  }
}

export default useRemarksFilter
