import { includes } from "lodash/fp"
import detectionsConfig from "../../../config/detections"
import { getTransformationOptionLabel } from "../../../store/remarks/remarks"

/**
 * Get the React Node for the transformation that is currently selected for a given detection.
 *
 * @param {*} detection
 * @returns {?import("react").ReactNode}
 */
const getTransformation = (detection) => {
  const { rule, transformation, selectedIndex } = detection

  if (!transformation || includes(rule, detectionsConfig.transformBlacklist)) {
    return null
  }

  const { options, type } = transformation

  if (!options || !type || options.length === 0) {
    return null
  }

  const transformationContent = getTransformationOptionLabel(
    transformation,
    selectedIndex
  )

  return <span>{transformationContent}</span>
}

export default getTransformation
