import findRelativeToOutline from "./findRelativeToOutline"
import {
  findContentSectionElements,
  getSectionStart,
  shiftSectionRelativeTo,
} from "./headerOperations"

/** @typedef {import("./getOutline").OutlineItem} OutlineItem */

/**
 * Reorder the sections in a container given a reordered outline.
 *
 * @param {OutlineItem[]} outline The reordered outline.
 * @param {OutlineItem} item The outline item that was moved.
 * @param {Element} container The element containing all of the sections.
 */
const applyReorder = (outline, item, container) => {
  const { nextSiblingHeading, parentHeading } = findRelativeToOutline(
    outline,
    item
  )

  // The section is isolated in a DocumentFragment so that we can shift the
  // heading levels before moving it. Without the isolation, the order of
  // operations would have to change depending on whether we are promoting or
  // demoting the sections.
  const section = asFragment(...findContentSectionElements(item.$el, container))

  // Promote/demote the section in relation to its position in the outline.
  shiftSectionRelativeTo(
    section.firstElementChild,
    nextSiblingHeading,
    parentHeading
  )

  // Move the section before the next heading in the outline.
  insertBefore(
    container,
    getSectionStart(nextSiblingHeading, container),
    section
  )
}

export default applyReorder

/**
 * Create a DocumentFragment containing the given set of nodes.
 *
 * A DocumentFragement is used as a lightweight version of Document that stores
 * a segment of a document structure comprised of nodes just like a standard
 * document. The key difference is due to the fact that the document fragment
 * isn't part of the active document tree structure. Changes made to the
 * fragment don't affect the document.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/DocumentFragment Document Fragment \| MDN}
 *
 * @param  {...Node} nodes
 * @returns {DocumentFragment}
 */
const asFragment = (...nodes) => {
  const fragment = document.createDocumentFragment()
  fragment.replaceChildren(...nodes)
  return fragment
}

/**
 * Insert a given node before the targeted element in a container.
 *
 * If the target is undefined, the node is appended to the end of the container.
 *
 * @param {Element} container
 * @param {Element} target
 * @param {Node} node
 */
const insertBefore = (container, target, node) => {
  if (!target) {
    container.append(node)
    return
  }

  target.before(node)
}
