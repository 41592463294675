import { gateway } from "./axios"
import { IMPORT_API_ENDPOINT } from "./importEndpoint"

/**
 * Cancel an import job from the import service
 * @param jobId - job id
 */
const cancelImportJob = async (jobId: string) => {
  try {
    const resp = await gateway.delete<any>(`${IMPORT_API_ENDPOINT}${jobId}`, {
      baseURL: "/",
    })
    return resp.data
  } catch (err) {
    console.error(err)
    return null
  }
}

export default cancelImportJob
