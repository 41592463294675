import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  TextField,
  LinearProgress,
  Alert,
  Typography,
} from "@mui/material"
import { Upload as UploadIcon, Link as LinkIcon } from "@mui/icons-material"
import { ImageUpload } from "../../custom-elements/ImageUpload"
import { useState } from "react"
import { uploadFroalaImage } from "../../api"
import { useFlag } from "../../utilities/feature-management"
import useGoogleDrivePicker from "../createCourse/hooks/useGoogleDrivePicker"
import GoogleDriveIcon from "../../assets/google-drive.png"
import GoogleDriveAccessDialog from "../createCourse/GoogleDriveAccessDialog"
import {
  downloadDriveUploadItem,
  getGoogleDriveFilesFromUploadItems,
  UploadItem,
} from "../../utilities/fileUpload"
import { useGapi } from "../../contexts/GapiProvider"
import { imageMimeTypes } from "../../config"

/**
 * Create dialog to insert a image
 * @param onClose - Callback to close dialog
 */
export function createInsertImageDialog(
  /** Called when the dialog is closed with url or null if cancelled */
  onClose: (url: string | null) => void
) {
  return (
    <InsertImageDialog
      key="insert-image-dialog"
      onInsert={onClose}
      onCancel={() => onClose(null)}
    />
  )
}

// Define an interface for the props
interface InsertImageDialogProps {
  /** Called when the dialog is cancelled */
  onCancel: () => void

  /** Called when the dialog is saved
   * @param url The url of the image if applicable
   */
  onInsert: (url: string | null, file: any) => void
}

/**
 * Create dialog to insert a image
 * @param root0 - the props
 * @param root0.onCancel - cancel callback
 * @param root0.onInsert - insert callback
 */
export function InsertImageDialog({
  onCancel,
  onInsert,
}: InsertImageDialogProps) {
  const [activeTab, setActiveTab] = useState(0)
  const [imageUrl, setImageUrl] = useState("")
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<unknown | null>(null)

  const [
    googleDriveAccessPromptDialogOpen,
    setGoogleDriveAccessPromptDialogOpen,
  ] = useState(false)

  const { downloadDriveFile } = useGapi()

  const useGoogleDriveUpload = useFlag("rollout-google-drive-images-in-editor")

  /**
   * Called when google drive files are selected
   * @param uploadItems - selected items
   */
  const onGoogleDriveDocumentAccepted = async (uploadItems: UploadItem[]) => {
    let error = null
    setUploading(true)
    setError(null)

    try {
      error = await downloadFromGoogleDrive(uploadItems)
    } catch (ex) {
      error = ex
    } finally {
      setUploading(false)
    }

    if (error != null) {
      setError(error)
    }
  }

  /**
   * Download image from google drive
   * @param uploadItems - list of selections
   */
  const downloadFromGoogleDrive = async (
    uploadItems: UploadItem[]
  ): Promise<string | undefined> => {
    const fileObjects = getGoogleDriveFilesFromUploadItems(uploadItems)

    if (fileObjects.length > 0) {
      const uploadFile = fileObjects[0]

      let errorMessage = await downloadDriveUploadItem(
        uploadFile,
        downloadDriveFile
      )

      if (errorMessage != null) {
        // try a second time - google drive sometimes fails on the first try
        errorMessage = await downloadDriveUploadItem(
          uploadFile,
          downloadDriveFile
        )
      }

      if (errorMessage != null) {
        return errorMessage
      } else {
        const uploadedUrl = await uploadFroalaImage(uploadFile.file)
        onInsert(uploadedUrl, uploadFile.file)
      }
    }
  }

  /** Called on Allow access to Google Drive */
  const handleOkGoogleDriveAccessPromptDialog = () => {
    setGoogleDriveAccessPromptDialogOpen(false)
    onAccessPermitted()
  }

  const { openDrivePicker, onAccessPermitted } = useGoogleDrivePicker({
    onAccepted: onGoogleDriveDocumentAccepted,
    setGoogleDriveAccessPromptDialogOpen,
    imageOnly: true,
  })

  // eslint-disable-next-line jsdoc/require-param
  /** Switch Tabs */
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  // eslint-disable-next-line jsdoc/require-param
  /** Update uploading progress for file uploads */
  const onFileUploadingChange = (val: boolean) => {
    if (val) {
      setError(null)
    }
    setUploading(val)
  }

  /** Called for URL insert  */
  const handleUrlInsert = async () => {
    if (imageUrl.trim()) {
      setUploading(true)
      setError(null)
      try {
        // Upload the image URL to your server
        const imageFile = await downloadImageFromUrl(imageUrl)
        const uploadedUrl = await uploadFroalaImage(imageFile)
        onInsert(uploadedUrl, imageFile)
      } catch (error) {
        setError(error)
      } finally {
        setUploading(false)
      }
    }
  }

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="sm" key="insert-image-dialog">
        <DialogTitle>Choose Image</DialogTitle>
        <DialogContent>
          {uploading && <LinearProgress />}
          {error && (
            <Alert
              severity="error"
              onClose={() => setError(null)}
              sx={{ mb: 2 }}
            >
              {makeImageErrorMessage(error)}
            </Alert>
          )}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
          >
            <Tab
              key="upload"
              disabled={uploading}
              label={
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <UploadIcon sx={{ width: 20, height: 20 }} />
                  Upload
                </Box>
              }
            />
            <Tab
              key="url"
              disabled={uploading}
              label={
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <LinkIcon sx={{ width: 20, height: 20 }} />
                  URL
                </Box>
              }
            />
            {useGoogleDriveUpload && (
              <Tab
                key="google-drive"
                disabled={uploading}
                label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <img
                      src={GoogleDriveIcon}
                      alt=""
                      style={{ width: 20, height: 20 }}
                    />
                    Google Drive
                  </Box>
                }
              />
            )}
          </Tabs>

          {/* Upload Tab */}
          {activeTab === 0 && (
            <Box>
              <ImageUpload
                onChooseImage={onInsert}
                onUploadingChange={onFileUploadingChange}
                onError={(err) => setError(err)}
              />
            </Box>
          )}

          {/* URL Tab */}
          {activeTab === 1 && (
            <Box>
              <TextField
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                margin="dense"
                label="Paste in an image URL"
                fullWidth
                disabled={uploading}
                variant="outlined"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              />
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ mt: 1, display: "block" }}
              >
                The URL must refer to an image in one of these formats:{" "}
                {Object.values(imageMimeTypes).flat().join(", ")}
              </Typography>
            </Box>
          )}

          {/* Google Drive Tab */}
          {activeTab === 2 && (
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => openDrivePicker()}
              >
                Select from Google Drive
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              gap: 1,
              px: 2,
            }}
          >
            {activeTab === 2 && (
              <Typography
                variant="caption"
                sx={{ position: "absolute", left: 24, maxWidth: "280px" }}
              >
                Google Drive is a trademark of Google Inc. Use of this trademark
                is subject to Google Permissions.
              </Typography>
            )}
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
            {activeTab === 1 && (
              <Button
                onClick={handleUrlInsert}
                color="primary"
                variant="contained"
                disabled={uploading || !imageUrl.trim()}
              >
                OK
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <GoogleDriveAccessDialog
        open={googleDriveAccessPromptDialogOpen}
        onClose={() => setGoogleDriveAccessPromptDialogOpen(false)}
        onOk={handleOkGoogleDriveAccessPromptDialog}
      />
    </>
  )
}

/**
 * Download image from a URL - Use a proxy to avoid cors errors
 * @param url - the url
 */
async function downloadImageFromUrl(url: string): Promise<File> {
  // We use the froala proxy by default with other uploads
  // so should be OK to use it here
  const froalaProxyUrl = "https://cors-anywhere.froala.com/"

  const response = await fetch(froalaProxyUrl + url)
  if (!response.ok) {
    throw new Error("Failed to download image, Invalid URL")
  }
  const blob = await response.blob()
  const fileName = getFileNameFromUrl(url)
  return new File([blob], fileName, { type: blob.type })
}

/**
 * Get file name and extension from a url
 * @param url - the url
 */
function getFileNameFromUrl(url: string): string {
  const urlObject = new URL(url)
  const pathname = urlObject.pathname
  const extension = pathname.substring(pathname.lastIndexOf("."))
  const baseName = pathname.substring(
    pathname.lastIndexOf("/") + 1,
    pathname.lastIndexOf(".")
  )

  // Use a default name if the URL doesn't have a valid filename
  const fileName = baseName || "downloaded_image"

  return `${fileName}${extension}`
}

/**
 * Make a human readable error message
 * @param error - original error message
 */
const makeImageErrorMessage = (error: unknown): string => {
  console.error("Image load error", error)

  const errorMessage =
    error instanceof Error
      ? error.message
      : typeof error === "string"
      ? error
      : "Unknown error"

  const message = errorMessage.toUpperCase()

  if (message.includes("INVALID URL")) {
    return "Please check that the URL is correct and that the site allows public access to the image."
  } else {
    return "Looks like something went wrong. Please try again later, contact LearnExperts if the problem persists."
  }
}
