import axios from "../../../api/axios"

/**
 * Update favourited quick insert items
 * @param items - List of IDs for starred quick insert items
 */
const saveStarredItems = (items: string[]) => {
  return axios.post(`/configuration/favourites`, {
    favourites: items,
  })
}

export default saveStarredItems
