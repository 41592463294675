import axiosInstance from "../../../api/axios"

/**
 *
 * Performs a shift of the order of the courses in a Learning Path.
 *
 * Course ID represents the ID of the course we are shifting.
 * Target ID is the ID of the course that we will be inserting the moved course
 * in front of. If no target ID is provided, the course will be placed at the
 * end of the list.
 *
 * @param arg
 * @returns {Promise<AxiosResponse<any>>}
 */
const shiftCourseInLearningPath = (arg) => {
  const { pathId, courseId, targetId } = arg
  return axiosInstance
    .put(`/paths/${pathId}/courses/${courseId}`, { target: targetId })
    .then((response) => response.data)
}

export default shiftCourseInLearningPath
