import { useDroppable } from "@dnd-kit/core"
import { ArrowBack } from "@mui/icons-material"
import {
  Stack,
  Typography,
  ListItemAvatar,
  Box,
  ListItemButton,
  ListItemText,
  Theme,
} from "@mui/material"

type ExitDirectoryControlProps = {
  onClick: () => void
  isDragging: boolean
}

/**
 * Control to display when navigating inside folder to allow the user to return to All Courses
 * @param props - Component props
 */
const ExitDirectoryControl = (props: ExitDirectoryControlProps) => {
  const { onClick, isDragging } = props
  const { isOver, setNodeRef } = useDroppable({
    id: "__ALL__",
    data: {
      title: "All Courses",
      id: "__ALL__",
    },
  })

  return (
    <ListItemButton
      divider
      disableRipple
      onClick={onClick}
      ref={setNodeRef}
      sx={(theme: Theme) => ({
        height: "72px",
        cursor: "pointer",
        backgroundColor: isOver
          ? theme.scheme.primaryContainer
          : theme.scheme.onPrimary,
      })}
    >
      <Stack width="100%" gap={2} direction="row" alignItems="center">
        <ListItemAvatar>
          <Box
            height="56px"
            width="56px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ArrowBack />
          </Box>
        </ListItemAvatar>

        {!isDragging ? (
          <ListItemText primary="Back" />
        ) : (
          <Typography variant="body2">All Courses</Typography>
        )}
      </Stack>
    </ListItemButton>
  )
}

export default ExitDirectoryControl
