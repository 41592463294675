import { IconButton, ListItem, Theme, Tooltip } from "@mui/material"
import ListItemContent from "../../../itemList/ListItemContent"
import { DeleteOutline, Restore } from "@mui/icons-material"
import { union, without } from "lodash/fp"

import { getTitle, getTrashed } from "../../../../../utilities/transformers"
import DirectoryItemAvatar from "../../coursesPage/components/DirectoryItemAvatar"
import { Item } from "../../../../homeNavigation/types"

type TrashedItemProps = {
  item: Item
  checkedItems: string[]
  setCheckedItems: React.Dispatch<React.SetStateAction<string[]>>
  showDeleteConfirmation: (item: Item) => void
  restoreCourse: (id: string) => void
  [key: string]: any
}

/**
 * Displays a single trashed course or folder
 * @param props - Component props
 */
const TrashedItem = (props: TrashedItemProps) => {
  const {
    item,
    checkedItems,
    setCheckedItems,
    showDeleteConfirmation,
    restoreCourse,
  } = props

  return (
    <ListItem divider>
      <ListItemContent
        avatar={
          <DirectoryItemAvatar
            item={item}
            checkedItems={checkedItems}
            handleCourseChecked={(event: any, id: string) => {
              setCheckedItems((prev: string[]) =>
                event.target.checked ? union(prev, [id]) : without([id], prev)
              )
            }}
          />
        }
        primaryText={getTitle(item.title)}
        secondaryText={getTrashed(item.trash_date)}
        control={
          <>
            <Tooltip title="Restore">
              <IconButton onClick={() => restoreCourse(item.id)}>
                <Restore />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete permanently">
              <IconButton onClick={() => showDeleteConfirmation(item)}>
                <DeleteOutline
                  sx={(theme: Theme) => ({
                    color: theme.scheme.error,
                  })}
                />
              </IconButton>
            </Tooltip>
          </>
        }
      />
    </ListItem>
  )
}

export default TrashedItem
