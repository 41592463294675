import remarksAdaptor from "../remarksAdaptor"

export const selectPendingComment = (state) =>
  remarksAdaptor
    .getSelectors((state) => state.remarks)
    .selectById(
      state,
      remarksAdaptor.selectId({
        id: "pending",
        remarkType: "pendingComment",
      })
    )
