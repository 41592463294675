import { Close } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  useMediaQuery,
} from "@mui/material"
import { forwardRef } from "react"
import { useTheme, Theme } from "@mui/material/styles"
import { LmsCourse } from "../../api/fetchLmsCourse"
import CopyToClipboardField from "../atoms/CopyToClipboardField"

interface LmsExportReplaceDialogProps {
  open: boolean
  publishedCourse: LmsCourse | null
  onClose: () => void
  onReplace: () => void
  onCreateNew: () => void
}

/**
 * Prompt the user to replace or createa new course
 *
 * @param root0 - LmsExportReplaceDialogProps
 * @param root0.open - open the dialog
 * @param root0.publishedCourse - url of course to replace
 * @param root0.onClose - called when closed
 * @param root0.onReplace - called when replace clicked
 * @param root0.onCreateNew - called when new clicked
 */
const LmsExportReplaceDialog = ({
  open,
  onClose,
  onReplace,
  onCreateNew,
  publishedCourse,
}: LmsExportReplaceDialogProps) => {
  const fullScreen = useFullscreenDialog()

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      scroll={fullScreen ? "paper" : "body"}
      keepMounted
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>
        Replace or Create New Course
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          This course has already been published to Skilljar. Would you like to
          replace it or create a new course?
        </DialogContentText>
        {publishedCourse && (
          <>
            <DialogContentText sx={{ mb: 1 }}>
              Use this link to view your course:
            </DialogContentText>

            <CopyToClipboardField
              value={publishedCourse.url}
              fullWidth
              helperText="You must sign in to the Skilljar dashboard'"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={(e) => {
            e.preventDefault()
            onReplace()
            onClose()
          }}
          variant="contained"
          color="primary"
        >
          Replace Existing
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault()
            onCreateNew()
            onClose()
          }}
          variant="outlined"
          color="primary"
        >
          Create New
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LmsExportReplaceDialog.defaultProps = {
  open: false,
}

export default LmsExportReplaceDialog

/** Use the full screen when it's small */
const useFullscreenDialog = () => {
  const theme: Theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

const Transition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
