import { useMemo } from "react"
import { useAppDispatch } from ".."
import {
  setScrollPath,
  setCurrentSectionNumber,
  setCurrentSectionHeader,
  setRemarksScrollLocked,
} from "./editorSlice"

/**
 * Hook to expose actions available for dispatch to Editor state in Redux store
 */
const useEditorDispatch = () => {
  const dispatch = useAppDispatch()

  return useMemo(
    () => ({
      /**
       * Dispatch action to update remarksScrollLocked flag
       * @param value - True or false value of flag
       */
      setRemarksScrollLocked: (value: boolean) =>
        dispatch(setRemarksScrollLocked(value)),
      /**
       * Dispatch action to update scroll path of current editor selection
       * @param path - New scroll path based on location in editor
       */
      setScrollPath: (path: any) => dispatch(setScrollPath(path)),
      /**
       * Dispatch action to update currentSectionNumber
       * @param sectionNumber - Number representing new section
       */
      setCurrentSectionNumber: (sectionNumber: number) =>
        dispatch(setCurrentSectionNumber(sectionNumber)),
      /**
       * Dispatch action to update currentSectionHeader
       * @param sectionHeader - Element value of new section
       */
      setCurrentSectionHeader: (sectionHeader: any) =>
        dispatch(setCurrentSectionHeader(sectionHeader)),
    }),
    [dispatch]
  )
}

export default useEditorDispatch
