const AUDIO_PLAYER_BACKGROUND_COLOUR = "#f1f3f4" // colour matches baked-in audio element background

/**
 * Reusable CSS to style audio elements in editor
 */
const audioElementStyle = {
  ".audio-clickable": {
    maxWidth: "480px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: AUDIO_PLAYER_BACKGROUND_COLOUR,
    display: "flex",
    flexDirection: "column",
    padding: "8px 16px",
    borderRadius: "12px",
    "& audio::-webkit-media-controls-panel": {
      backgroundColor: AUDIO_PLAYER_BACKGROUND_COLOUR,
    },
    "& audio": {
      width: "100%",
    },
  },
}

export default audioElementStyle
