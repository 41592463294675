import { Alert, Box, Skeleton } from "@mui/material"
import React from "react"
import LinkCard from "./LinkCard"
import LEAiCard from "./LEAiCard"
import styled from "@emotion/styled"
import RedHatPrivacyNotice from "./RedHatPrivacyNotice"
import { useFlag } from "../../utilities/feature-management"

/**
 * Placeholder loading icon to display when fetching posts
 */
export const CardSkeleton = () => (
  <Skeleton
    sx={(theme) => ({ borderRadius: theme.spacing(2) })}
    variant="rectangular"
    height="100%"
    width="100%"
  />
)

/**
 * Wrapper for items that display as Cards in the WelcomeDisplay
 *
 */
const WelcomeItem = styled(Box)(({ theme, variant }) => {
  return {
    alignItems: "center",
    height: "100%",
    ...(variant === "link" && {
      width: theme.spacing(32),
      flexGrow: 1,
    }),
  }
})

/**
 * Component to display at top of home screen which contains Welcome messages,
 * rotating posts, and other configurable content
 *
 * @param props - Component props
 */
const WelcomeDisplay = (props) => {
  const {
    posts,
    error,
    loading,
    welcomeVisible,
    setWelcomeVisible,
    displayHelpMessage,
    lastEdited,
    loadingLastEdited,
  } = props

  const showRedhatNotice = useFlag("customize-redhat-privacy-notice")

  return (
    <>
      <Box
        display="flex"
        gap={2}
        marginBottom={4}
        sx={{
          /**
           * Set card height by MUI theme spacing
           * @param theme - MUI theme
           */
          height: (theme) => theme.spacing(26),
        }}
      >
        {welcomeVisible && !showRedhatNotice && (
          <LEAiCard
            displayHelpMessage={displayHelpMessage}
            onDismiss={() => setWelcomeVisible(false)}
            lastEdited={lastEdited}
            loadingLastEdited={loadingLastEdited}
          />
        )}
        {showRedhatNotice && <RedHatPrivacyNotice />}
        {loading && !error && (
          <>
            <WelcomeItem
              gutterRight
              variant="link"
              sx={{
                display: welcomeVisible ? { xs: "none", md: "flex" } : "flex",
              }}
            >
              <CardSkeleton />
            </WelcomeItem>
            <WelcomeItem
              variant="link"
              sx={{
                display: welcomeVisible
                  ? { xs: "none", xl: "flex" }
                  : { xs: "none", sm: "flex" },
              }}
            >
              <CardSkeleton />
            </WelcomeItem>
          </>
        )}
        {error && (
          <WelcomeItem
            variant="link"
            sx={{
              display: welcomeVisible ? { xs: "none", md: "flex" } : "flex",
            }}
          >
            <Alert variant="outlined" severity="error">
              We are experiencing issues retrieving the latest posts. Please
              refresh the page or contact LearnExperts if the problem persists.
            </Alert>
          </WelcomeItem>
        )}

        {posts.length > 0 && (
          <>
            <WelcomeItem
              gutterRight
              variant="link"
              sx={{
                display: welcomeVisible ? { xs: "none", md: "flex" } : "flex",
              }}
            >
              <LinkCard post={posts[0]} />
            </WelcomeItem>
            {posts[1] && (
              <WelcomeItem
                variant="link"
                sx={{
                  display: welcomeVisible
                    ? { xs: "none", xl: "flex" }
                    : { xs: "none", sm: "flex" },
                }}
              >
                <LinkCard post={posts[1]} />
              </WelcomeItem>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default WelcomeDisplay
