import { Box } from "@mui/material"
import { ReactNode } from "react"

type BlogPostContainerProps = {
  children: ReactNode
  [key: string]: any
}

/**
 * Wrapper for blog post items on welcome display
 * @param props - Component props
 */
const BlogPostContainer = (props: BlogPostContainerProps) => {
  const { children, ...otherProps } = props

  return (
    <Box flex="1 0 0" borderRadius="12px" display="flex" {...otherProps}>
      {children}
    </Box>
  )
}

export default BlogPostContainer
