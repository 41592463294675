import { Box } from "@mui/material"

/**
 * Image to accompany welcome message
 */
const WelcomeImage = () => {
  return (
    <Box
      height="100%"
      flex="0 1 200px"
      overflow="hidden"
      display={{ xs: "none", md: "flex" }} // hide on smaller viewports
      justifyContent="center"
      sx={{
        borderTopRightRadius: "10px 50%",
        borderBottomRightRadius: "80px 50%",
        backgroundImage: `url("/welcome_person1.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    ></Box>
  )
}

export default WelcomeImage
