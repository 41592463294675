import axiosInstance from "../../../api/axios"

/**
 * Send a request to server to retrieve a list of courses and folders
 * @param options - Options to include in request
 */
const fetchDirectoryItems = (options = {}) => {
  return axiosInstance
    .get(`/courses/`, { params: options })
    .then((response) => response.data)
}

export default fetchDirectoryItems
