import { createSelector } from "@reduxjs/toolkit"
import { RemarksState } from "../remarks"
import selectDetectionById from "./selectDetectionById"
import { isResolved } from "./selectFilteredRemarks"

/**
 * Select the review status for a remark.
 * @param state The current remarks state.
 * @param id The detection identifier.
 */
const selectReviewStatus = createSelector(
  [selectDetectionById, (state: RemarksState) => state.reviewedDetections],
  (detection, reviewedDetections) => {
    return !!(detection && isResolved(detection, reviewedDetections))
  }
)

export default selectReviewStatus
