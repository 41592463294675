import { gateway } from "./axios"
import { IMPORT_API_ENDPOINT } from "./importEndpoint"

/**
 * Sent import request to the import service
 * @param formData - Object with import details
 * @param activate - Boolean to determine if the import should be activated,
 * false while still just getting headers
 */
const startImport = async (formData: FormData, activate: boolean) => {
  const importItem = Object.fromEntries(formData.entries())
  importItem.file_info = JSON.parse(importItem.file_info as string)

  const resp = await gateway.post<any>(
    `${IMPORT_API_ENDPOINT}?activate=${activate}`,
    importItem,
    {
      baseURL: "/",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )

  return resp
}

export default startImport
