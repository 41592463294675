import { FormControlLabel, Switch } from "@mui/material"
import useRemarksFilter from "../../../hooks/useRemarksFilter"

/**
 * Control for filtering remarks by resolution.
 */
const RemarksResolutionFilter = () => {
  const { resolved, filterToResolved } = useRemarksFilter()

  const onChange = (event) => {
    filterToResolved(event.target.checked)
  }

  return (
    <FormControlLabel
      control={<Switch value={resolved} onChange={onChange} />}
      label="Reviewed"
    />
  )
}

export default RemarksResolutionFilter
