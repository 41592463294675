import axiosGlobal from "./axios"

/**
 * Create a SCORM preview from a zip export
 * @param courseId - the course
 * @param exportUrl - the exported zip
 * @param axios - axios
 */
const createExportPreview = async (
  courseId: string,
  exportUrl: string,
  axios = axiosGlobal
): Promise<string> => {
  const response = await axios.get(
    `/export/${courseId}/preview?export_file_url=${encodeURIComponent(
      exportUrl
    )}`
  )
  return response.data.export_url
}

export default createExportPreview
