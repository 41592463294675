import { constant, filter, isMatch, reduce } from "lodash/fp"
import { useCallback, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { selectAllJobs } from "../store/jobs/jobsEntityAdaptor"
import selectLatestJobUpdate from "../store/jobs/selectLatestJobUpdate"
import buildNotificationFromTemplate from "../utilities/buildNotificationFromTemplate"
import { parseQueryString } from "../utilities/queryString"

import NOTIFICATION_CONFIG from "../config/notifications"

/**
 * @typedef {object} AsyncJob
 * @typedef {object} Notification
 * @typedef {(state: any) => Notification[]} NotificationSelector
 * @typedef {(state: any) => AsyncJob[]} AsyncJobSelector
 */

/**
 * @typedef {object} Context
 * @property {object} location The current browser locations.
 */

/**
 * Create a notification selector.
 *
 * Each time the selector is invoked with an application state, it will return
 * a collection of notifications that have yet to be processed.
 *
 * @returns {NotificationSelector}
 */
const useNotificationsSelector = () => {
  const location = useLocation()

  /** @type {import("react").MutableRefObject<NotificationSelector>} */
  const selectNotificationsRef = useRef()

  /** @type {import("react").MutableRefObject<Context>} */
  const contextRef = useRef(null)

  // Rebuild the notifications context when any of the dependencies change.
  useEffect(() => {
    contextRef.current = {
      location: {
        ...location,
        query: parseQueryString(location.search),
      },
    }
  }, [location])

  // The selector should only be instantiated a render time, as it needs to
  // remember which notifications have already been processed across component
  // rerenders.
  useEffect(() => {
    const selectUpdates = createUpdateSelector()
    selectNotificationsRef.current = (state) =>
      asNotifications(selectUpdates(state), contextRef.current)
  }, [])

  return useCallback((state) => selectNotificationsRef.current(state), [])
}

export default useNotificationsSelector

/**
 * Create a selector for async job updates.
 *
 * Each time the selector is invoked, it will return any async jobs that have
 * been updated since the previous time it was invoked. The first invokation
 * will always return all async jobs available in the state.
 *
 * @returns {AsyncJobSelector}
 */
const createUpdateSelector = () => {
  let prevLatestUpdate = null
  const hasUpdated = (job) => job.last_update_datetime > prevLatestUpdate

  return (state) => {
    const latestUpdate = selectLatestJobUpdate(state)
    if (latestUpdate === prevLatestUpdate) {
      return []
    }

    const updates = filter(hasUpdated, selectAllJobs(state))

    prevLatestUpdate = latestUpdate

    return updates
  }
}

/**
 * Resolve notifications from a batch of async job updates.
 *
 * `asNotifications` tries to match job updates against each of the configured
 * notifications rules. For any matches it will template the notification from
 * the update and include it in the returned set.
 *
 * @param {AsnycJob[]} updates
 * @param {Context} context
 * @returns {Notification[]}
 */
const asNotifications = (updates, context) => {
  return reduce(
    (acc, config) => {
      const { match, template, exclude = constant(false) } = config
      const isNotificationMatch = (update) =>
        isMatch(match, update) && !exclude(update, context)

      return reduce(
        (acc, update) => {
          if (!isNotificationMatch(update)) {
            return acc
          }

          acc.push(
            buildNotificationFromTemplate(template, [update.data, update])
          )
          return acc
        },
        acc,
        updates
      )
    },
    [],
    NOTIFICATION_CONFIG.rules
  )
}
