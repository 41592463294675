import React from "react"
import CreateCourseScreen from "../features/createCourse/CreateCourseScreen"
/**
 *
 * @param root0
 * @param root0.location
 */
function CourseCreationPage({ location }) {
  const learningPath = location?.state?.learningPath

  return <CreateCourseScreen learningPath={learningPath} />
}

export default CourseCreationPage
