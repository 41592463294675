import detectionsConfig from "../../../config/detections"

/**
 * Get the title for a given detection rule.
 *
 * @param {*} detection
 * @returns {?string}
 */
const getDetectionTitle = (detection) =>
  detectionsConfig.detections?.[detection.rule]?.title ?? null

export default getDetectionTitle
