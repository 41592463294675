import { Grid } from "@mui/material"
import { formatDate } from "../../../../../utilities/transformers"
import LabelValuePair from "./LabelValuePair"
import HTMLDiff from "./HTMLDiff"

type DetailsData = {
  action_type: string
  details: any
  inlineView?: boolean
  onSelectRecord: () => void
}

/**
 * Render labels and values for all relevant data based on action type
 * @param data - Data to render
 */
const renderDetails = (data: DetailsData) => {
  const { action_type, details } = data
  switch (action_type) {
    case "login":
    case "logout":
      return (
        <>
          <LabelValuePair label="IP Address" value={details.ipAddress} />
          <LabelValuePair label="User Agent" value={details.userAgent} />
        </>
      )
    case "course_created":
    case "course_duplicated":
    case "course_shared":
    case "insert_course_content":
      return (
        <>
          <LabelValuePair
            label="Sources"
            value={details.sources.map(
              (source: string, index: any) => `${index ? ", " : ""} ${source}`
            )}
          />
          {details.warnings.length > 0 && (
            <LabelValuePair
              label="Warnings"
              value={details.warnings.map(
                (warning: any, index: any) =>
                  `${index ? ", " : ""} ${warning.message} (${
                    warning.filename
                  })`
              )}
            />
          )}
        </>
      )
    case "course_deleted":
    case "course_exported":
    case "course_microlearning_condense":
    case "course_microlearning_concept":
    case "course_microlearning_chunk":
      return (
        <>
          <LabelValuePair label="Source File(s)" value={details.sourceFile} />
        </>
      )
    case "course_saved":
      return (
        <>
          <LabelValuePair label="Source File(s)" value={details.sourceFile} />
          <LabelValuePair label="IP Address" value={details.ipAddress} />
          <LabelValuePair label="User Agent" value={details.userAgent} />
          <HTMLDiff {...data} />
        </>
      )
    case "course_transferred":
      return (
        <LabelValuePair label="Transferred to" value={details.transferred_to} />
      )
    case "course_published":
      return (
        <>
          <LabelValuePair label="Published Version" value={details.version} />
          <LabelValuePair label="Learner Link" value={details.publicUrl} />
        </>
      )
    case "path_created":
    case "path_deleted":
    case "path_add_courses":
    case "path_remove_courses":
    case "path_reorder_courses":
      return (
        <>
          <LabelValuePair
            label="Learning Path Title"
            value={details.path_title}
          />
          <LabelValuePair label="Learning Path ID" value={details.path_id} />
        </>
      )
    default:
      return null
  }
}

type ActionLogDetailsProps = {
  data: any
  selectedAction: any
  inlineView?: boolean
  onSelectRecord?: () => void
}

/**
 * Component for rendering details for a given action log
 * @param props - Component props
 */
const ActionLogDetails = (props: ActionLogDetailsProps) => {
  const { data, inlineView, selectedAction, onSelectRecord } = props
  const { details } = data

  return (
    <Grid container>
      <LabelValuePair
        label="Action"
        value={selectedAction != null ? selectedAction.label : ""}
      />
      <LabelValuePair label="Course" value={data.course_title} />
      <LabelValuePair label="Username" value={data.username} />
      <LabelValuePair label="Email" value={details.email} />
      <LabelValuePair label="Date" value={formatDate(data.recorded_at)} />
      <LabelValuePair label="Log ID" value={data.history_id} />
      {renderDetails({ ...data, onSelectRecord, inlineView })}
    </Grid>
  )
}

export default ActionLogDetails
