import { acceptedMimeTypes, audioMimeTypes } from "../config"
import * as api from "../api"
import { DownloadDriveFileCallback } from "../contexts/GapiProvider"

/**
 * A file with a path (dropzone creates these)
 */
interface FileWithPath extends File {
  path: string
}

export class UrlInfo {
  name: string
  type: string
  path: string

  constructor(name: string, type: string, path: string) {
    this.name = name
    this.type = type
    this.path = path
  }
}

/**
 * Information about a file on google drive
 */
interface GoogleDriveFileInfo {
  /** Id of the file. used to download from drive */
  id: string
  /** Name of the file */
  name: string
  /** Mime type of the file */
  type: string
  /** Mime type to export later */
  exportType: string | null
  /** Path of the file */
  path: string
}

interface UploadItemHeading {
  index: number
  selected: boolean
  label: string
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

/**
 * Helper classes for grouping dropped documents & folders on course creation.
 * A Drop can contain any combination of folders and single files.
 * HTML files are the only ones that can refer to other files so if we drop a folder
 * with an HTML file in the tree, accept all the other files in the tree.
 */

/** An upload item is a single file (pptx,docx,zip) or a
 * folder (containing an HTML file and supporting img/css files)
 */
export class UploadItem {
  source: string
  displayName: string
  type: string
  selected: boolean
  files: UploadFile[]
  errorMessage: string | null
  order: number

  /** Headings fetched from server for item */
  headings?: UploadItemHeading[]

  /** Whether headings are open in the UI */
  headingsOpen?: boolean

  /** Mode for transcribing audio/video documents.
   * raw - raw transcription
   * rewrite - rewrite transcription
   * headings - add headings only
   */
  transcribeMode: "raw" | "rewrite" | "headings" = "rewrite"

  /** Mode for rewriting documents.
   * original - leave as is
   * rewrite - rewrite as course content
   */
  rewriteMode: "original" | "rewrite" = "original"

  constructor(
    files: UploadFile[],
    name: string,
    type: string,
    source: "file-system" | "google-drive" | "web" = "file-system"
  ) {
    this.source = source

    if (name === "") {
      name = "folder"
    }
    this.displayName = name

    this.type = type
    this.selected = true
    this.files = files // files has one file for single file items
    this.errorMessage = null // used to track errors downloading from google drive

    // initial order depends on the order passed to us by OS the drop/file select
    this.order = Math.min(...files.map((f) => f.index))
  }
}

export class UploadFile {
  file: FileWithPath | GoogleDriveFileInfo | UrlInfo | File
  name: string
  type: string
  path: string
  exportType: string | null
  id: string | null
  index: number
  folderName: string | null
  basepath: string

  // A file to be uploaded, passed to us by the drop/file select control
  constructor(
    file: FileWithPath | GoogleDriveFileInfo | UrlInfo,
    index: number,
    folderName: string | null = null
  ) {
    this.file = file
    this.name = file.name
    this.type = file.type
    this.path = file.path
    this.exportType = null
    this.id = null

    if (!(file instanceof File) && !(file instanceof UrlInfo)) {
      this.exportType = file.exportType
      this.id = file.id
    }

    this.index = index // track the order of files
    this.folderName = folderName

    if (this.type === "") {
      this.type = extensionToMimeType(this.name)
    }

    if (this.type === "text/html" || this.type === "text/markdown") {
      this.basepath = UploadFile.getBasePath(this.path)
    } else {
      this.basepath = "/"
    }
  }

  static getBasePath(path: string) {
    // The first dir in the path is the basepath,
    // all files under a basepath are collected together
    if (path[0] === "/" || path[0] === "\\") {
      return path.split(path[0])[1]
    } else {
      return ""
    }
  }
}

export class UploadFileCollection {
  files: UploadFile[]
  rejects: FileWithPath[]
  folders: { [key: string]: UploadFile[] }
  rootItems: UploadItem[]

  /**
   * Creates an instance of UploadFileCollection.
   * Each item in the collection shows up in the select files list of the course create page.
   * @param acceptedFiles - The files that have been accepted for upload.
   * @param rejectedFiles - The files that have been rejected for upload.
   */
  constructor(acceptedFiles: FileWithPath[], rejectedFiles: FileWithPath[]) {
    this.files = []
    this.rejects = []
    this.folders = {}

    this.processAccepted(acceptedFiles)

    const topFolderFiles = this.folders[""]

    if (topFolderFiles != null) {
      // If there are html file directly dropped, treat all other html/markdown files as part of the same folder
      this.moveAllToTopFolder(rejectedFiles, topFolderFiles)
    } else {
      this.processRejected(rejectedFiles)
    }

    this.rootItems = this.collectAllTopLevel()
  }

  toJson() {
    return this.rootItems
  }

  processAccepted(acceptedFiles: FileWithPath[]) {
    // Process accepted files docx/pptx/html/zip
    // Track the base folders that have htnl files in "folders"
    for (const acceptedFile of acceptedFiles) {
      const uploadFile = this.addFile(acceptedFile)

      if (
        uploadFile.type === "text/html" ||
        uploadFile.type === "text/markdown"
      ) {
        uploadFile.folderName = uploadFile.basepath
        if (this.folders[uploadFile.folderName] === undefined) {
          this.folders[uploadFile.folderName] = [uploadFile]
        } else {
          this.folders[uploadFile.folderName].push(uploadFile)
        }
      }
    }
  }

  moveAllToTopFolder(rejectedFiles: FileWithPath[], folderFiles: UploadFile[]) {
    // move all HTML & supporting files to the top level
    // Done when the user selects them directly
    for (const file of this.files) {
      if (file.folderName != null && file.folderName !== "") {
        delete this.folders[file.folderName]
        file.folderName = ""
      }
    }

    for (const rejectedFile of rejectedFiles) {
      this.addRejectedFile(rejectedFile, "", folderFiles)
    }
  }

  processRejected(rejectedFiles: FileWithPath[]) {
    // Process rejected files - accept files in the same folder as an html file
    for (const rejectedFile of rejectedFiles) {
      const basepath = UploadFile.getBasePath(rejectedFile.path)
      const folderFiles = this.folders[basepath]
      if (folderFiles != null) {
        // A file in the same folder tree as an html file, probably an img or css file, accept it
        this.addRejectedFile(rejectedFile, basepath, folderFiles)
      } else {
        // No html file in the same folder tree as this file - reject it
        this.rejects.push(rejectedFile)
      }
    }
  }

  addFile(file: FileWithPath, folder: string | null = null) {
    // Add a file to our list, set its index & folder, if any
    let uploadFile = new UploadFile(file, this.files.length, folder)
    this.files.push(uploadFile)
    return uploadFile
  }

  addRejectedFile(
    rejectedFile: FileWithPath,
    folder: string,
    folderFiles: UploadFile[]
  ) {
    if (
      rejectedFile.type === "text/css" ||
      rejectedFile.type.startsWith("image/")
    ) {
      let file = this.addFile(rejectedFile, folder)
      folderFiles.push(file)
    }
  }

  collectAllTopLevel() {
    // Turn our file list into a list of UploadItems
    // group files by folder
    // sort by drop order
    // eliminate duplicates

    const topItems: UploadItem[] = []
    for (const file of this.files) {
      if (file.folderName === null) {
        const item = new UploadItem([file], file.name, file.type)
        topItems.push(item)
      }
    }

    for (const folderName in this.folders) {
      const files = this.folders[folderName]

      // If the folder has no images/video/html we can move MD files to the top level and ignore the folder
      // Otherwise we need to keep them in the folder so we can resolve refs from MD to images or
      // from HTML to images/css/other html
      const hasImageOrVideoOrHtml = files.some(
        (file) =>
          file.type.startsWith("image/") ||
          file.type.startsWith("video/") ||
          file.type === "text/html"
      )

      if (!hasImageOrVideoOrHtml) {
        // move all md files to the top
        delete this.folders[folderName]
        for (const fileItem of files) {
          if (fileItem.type === "text/markdown") {
            // TODO This mutates the fileItem, which is not ideal
            ;(fileItem.file as any).folderName = null
            fileItem.folderName = null

            const item = new UploadItem(
              [fileItem],
              fileItem.name,
              fileItem.type
            )

            topItems.push(item)
          }
        }
      } else {
        const item = new UploadItem(files, folderName, "folder")

        topItems.push(item)
      }
    }

    const sortedTopItems = topItems.sort((a, b) => a.order - b.order)

    const names: { [displayName: string]: true } = {}
    const uniqueItems: UploadItem[] = []

    for (const item of sortedTopItems) {
      if (names[item.displayName] !== true) {
        names[item.displayName] = true
        uniqueItems.push(item)
      }
    }

    return uniqueItems
  }
}

/**
 * Get the selected uploadItems from a list of selected file names.
 * Set index field of each uploadItem to be unique, as a multi-part file reference
 *
 * @param fileNames filenames to select
 * @param uploadItems list of uploadItems to select from
 *
 * @returns selected uploadItems
 */
export function getSelectedFiles(
  fileNames: string[],
  uploadItems: UploadItem[]
): UploadItem[] {
  const selectedItems = []
  let fileIndex = 0
  for (const fileName of fileNames) {
    const file = uploadItems.find((file) => file.displayName === fileName)
    if (file != null && file.selected !== false) {
      selectedItems.push(file)
      file.order = selectedItems.length

      // index is used to link to the multi-part file, make sure it is unique
      for (const fileItem of file.files) {
        fileItem.index = fileIndex
        fileIndex += 1
      }
    }
  }

  return selectedItems
}

/**
 * Download files from google drive and convert file content into a
 * form usable by multi-part file upload
 *
 * Return any UploadItems with download errors, files larger than 10mg, for example
 *
 * @param {UploadItem[]} selectedItems Selected Upload items
 * @param downloadDriveFile - Callback to do download
 * @returns {Promise<UploadItem[]>} selectedItems with any download errors
 */
export async function downloadRemoteFiles(
  selectedItems: UploadItem[],
  downloadDriveFile: DownloadDriveFileCallback
): Promise<UploadItem[]> {
  const errors: UploadItem[] = []

  for (const uploadItem of selectedItems) {
    if (uploadItem.source === "google-drive") {
      for (const uploadFile of uploadItem.files) {
        let errorMessage = await downloadDriveUploadItem(
          uploadFile,
          downloadDriveFile
        )

        if (errorMessage != null) {
          // try a second time - google drive sometimes fails on the first try
          errorMessage = await downloadDriveUploadItem(
            uploadFile,
            downloadDriveFile
          )
        }

        if (errorMessage != null) {
          uploadItem.errorMessage = errorMessage
          errors.push(uploadItem)
        }
      }
    } else if (uploadItem.source === "web") {
      for (const uploadFile of uploadItem.files) {
        let errorMessage = await downloadWebUploadItem(uploadFile)

        if (errorMessage != null) {
          uploadItem.errorMessage = errorMessage
          errors.push(uploadItem)
        }
      }
    }
  }

  return errors
}

/**
 * Download a file from google drive and convert file content into a
 * upload file
 * @param uploadFile - file to download
 * @param downloadDriveFile - function to download from drive
 * @returns error message or null
 */
export async function downloadDriveUploadItem(
  uploadFile: UploadFile,
  downloadDriveFile: DownloadDriveFileCallback
) {
  try {
    const gFile = await downloadDriveFile(
      uploadFile.id!,
      uploadFile.type,
      uploadFile.exportType
    )

    const blob = convertBinaryStringToBlob(gFile.body)
    const fileWithName = new File([blob], uploadFile.name, {
      type: uploadFile.file.type,
    })

    uploadFile.file = fileWithName
  } catch (e: any) {
    const message = e?.result?.error?.message ?? "Unable to read this file."
    return uploadFile.name + ":  " + message
  }

  return null
}

/**
 * Download a file from the web and convert file content into an
 * upload file
 * @param uploadFile - file to download
 * @returns error message or null
 */
async function downloadWebUploadItem(uploadFile: UploadFile) {
  try {
    const htmlContent = await downloadHtmlContent(uploadFile.path)
    const blob = new Blob([htmlContent], { type: "text/html" })
    const file = new File([blob], uploadFile.path, { type: "text/html" })
    ;(uploadFile as any).file = file
  } catch (error) {
    console.error("Error fetching HTML content:", error)
    return "Unable to download from the URL: " + uploadFile.name
  }

  return null
}

/**
 * Ensure the URL starts with https://.
 * If not, prepend http:// to the URL.
 * @param url - The URL to check and format.
 * @returns The formatted URL.
 */
function ensureHttp(url: string): string {
  url = url.replace(/^https?:\/\//, "")
  return `https://${url}`
}
/**
 * Fetch HTML content from a URL.
 * @param url - The URL to fetch.
 * @returns A promise that resolves to the HTML content as a string.
 */
async function downloadHtmlContent(url: string): Promise<string> {
  const formattedUrl = ensureHttp(url)

  const content = await api.fetchDocumentFromUrl(formattedUrl)

  if (content == null) {
    throw new Error("Unable to download")
  }

  return content
}

/**
 * Convert a binary string into a blob
 *
 * @param binaryString - string to convert
 * @returns {Blob}
 */
function convertBinaryStringToBlob(binaryString: string) {
  const binaryBytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; ++i) {
    binaryBytes[i] = binaryString.charCodeAt(i)
  }
  return new Blob([binaryBytes])
}

/**
 * Return the mime type based on the extension
 * @param filename - extension to check
 */
const extensionToMimeType = (filename: string) => {
  const extension = filename.slice(filename.lastIndexOf("."))

  const allMimeTypes: { [mimeType: string]: string[] } = {
    ...acceptedMimeTypes,
    ...audioMimeTypes,
  }

  for (const mimeType in allMimeTypes) {
    if (allMimeTypes[mimeType].includes(extension)) {
      return mimeType
    }
  }

  return ""
}

/**
 * Extract google drive files from a list of upload items
 * @param uploadItems - the list
 */
export function getGoogleDriveFilesFromUploadItems(
  uploadItems: UploadItem[]
): UploadFile[] {
  return uploadItems.flatMap((item) =>
    item.files.filter(
      (uploadFile) =>
        item.source === "google-drive" &&
        uploadFile.file &&
        "id" in uploadFile.file
    )
  )
}
