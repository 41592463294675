import { Delete, Edit } from "@mui/icons-material"
import { Box, IconButton, styled } from "@mui/material"
import { useMemo, useState } from "react"
import { CustomElementConfig, RenderViewOptions } from "./CustomElements"
import { QuestionDialog } from "./QuestionDialogPlainStem"
import _ from "lodash"
import { useFitText } from "../hooks/useFitText"

/*

**** This is just the old version of Question Custom Element before HTML stems. ****

*/

/** Data for a question */
interface QuestionDataBase {
  /** Stem is the question part. e.g. "Why is the sky blue?" */
  stem: string
}

/** Type of multiple-choice question that has a single correct answer */
export interface MultipleChoiceQuestionSingleAnswerData
  extends QuestionDataBase {
  type: "multiple-choice-single-answer"

  /** Choices are the possible answers. e.g. ["Because the sun is blue", "Because the sky is blue", "Because the grass is blue"] */
  choices: string[]

  /** 0-based index of correct choice */
  correctChoice: number
}

/** Type of multiple-choice question that has multiple correct answers */
export interface MultipleChoiceQuestionMultipleAnswerData
  extends QuestionDataBase {
  type: "multiple-choice-multiple-answer"

  /** Choices are the possible answers. e.g. ["Because the sun is blue", "Because the sky is blue", "Because the grass is blue"] */
  choices: string[]

  /** 0-based indexes of correct choices */
  correctChoices: number[]
}

export type MultipleChoiceQuestionData =
  | MultipleChoiceQuestionSingleAnswerData
  | MultipleChoiceQuestionMultipleAnswerData

/** Type of true/false question */
export interface TrueFalseQuestionData extends QuestionDataBase {
  type: "true-false"

  answer: "True" | "False"
}

/** Type of fill-in-the-blank question */
export interface FillInTheBlankQuestionData extends QuestionDataBase {
  type: "fill-in-the-blank"

  answer: string
}

/** Type of matching question. A matching question that has two lists of equal length.
 * Each item in the first list needs to be matched with its mate in the second list.
 * When presented, the two lists will be shuffled to make the matching more difficult.
 */
export interface MatchingQuestionData extends QuestionDataBase {
  type: "matching"

  pairs: MatchingQuestionPair[]
}

/** A pair of items in a matching question */
export interface MatchingQuestionPair {
  /** Item in first list */
  left: string
  /** Item in second list */
  right: string
}

export type QuestionData =
  | MultipleChoiceQuestionSingleAnswerData
  | MultipleChoiceQuestionMultipleAnswerData
  | TrueFalseQuestionData
  | FillInTheBlankQuestionData
  | MatchingQuestionData

/**
 *
 * Questions of various types.
 *
 * Multiple-choice with single answer:
 * The correct choice is stored in the data-correct-choice attribute of the section element
 * as a 0-based index of the correct choice.
 *
 * Multiple-choice with multiple answers:
 * If multiple choices are correct, then
 * the data-correct-choices attribute is used instead, with a comma-separated list of
 * 0-based indexes of the correct choices.
 *
 * Sample HTML for single-answer multiple-choice question:
 * ```html
 * <section data-component="multiple-choice-question" data-correct-choice="0">
 *  <p>What is the capital of France?</p>
 *  <ol type="a">
 *   <li>Paris</li>
 *   <li>London</li>
 *   <li>Madrid</li>
 *  </ol>
 *  <p>Answer: a</p>
 * </section>
 * ```
 *
 * Sample HTML for matching question:
 * ```html
 * <div data-component="matching-question">
 *   <p>Match the following countries with their capitals.</p>
 *   <ul>
 *     <li>France</li>
 *     <li>Germany</li>
 *     <li>Italy</li>
 *   </ul>
 *   <ul>
 *     <li>Paris</li>
 *     <li>Berlin</li>
 *     <li>Rome</li>
 *   </ul>
 * </div>
 * ```
 * @see lex-api: /docs/questions.md
 */
export const questionCustomElementPlainStem: CustomElementConfig<QuestionData> =
  {
    /** CSS selector for the custom element. All elements matching
     * this selector will be replaced with the custom element
     */
    selector:
      "[data-component='multiple-choice-question'],[data-component='true-false-question'],[data-component='fill-in-the-blank-question'],[data-component='matching-question']",
    /**
     * Extract data from the light-DOM representation of the custom element
     * @param element The element to extract data from
     */
    getDataFromElement: (element) => {
      const stemElement = element.querySelector("p,div") as
        | HTMLElement
        | undefined
      let stem = stemElement?.innerHTML.replace(/<br>/g, "\n") || ""
      stem = _.unescape(stem.replace(/<[^>]*>/g, "")) // Remove any remaining HTML tags and unescape special entities

      switch (element.dataset.component) {
        case "multiple-choice-question":
          const choices = Array.from(element.querySelectorAll("ol li")).map(
            (li) => li.textContent?.trim() || ""
          )

          if (element.dataset.correctChoices) {
            const correctChoices = element.dataset.correctChoices
              .split(",")
              .map((s) => parseInt(s))

            return {
              type: "multiple-choice-multiple-answer",
              stem,
              choices,
              correctChoices,
            }
          } else {
            const correctChoice = parseInt(element.dataset.correctChoice || "0")
            return {
              type: "multiple-choice-single-answer",
              stem,
              choices,
              correctChoice,
            }
          }
        case "true-false-question":
          // Answer is stored in data-answer attribute as "True" or "False"
          const answerTrueFalse = element.dataset.answer || "False"
          return {
            type: "true-false",
            stem,
            answer: answerTrueFalse as "True" | "False",
          }
        case "fill-in-the-blank-question":
          // Answer is stored in data-answer attribute
          const answerFillInTheBlank = element.dataset.answer!
          return {
            type: "fill-in-the-blank",
            stem,
            answer: answerFillInTheBlank,
          }
        case "matching-question":
          // Extract pairs from definition list
          const dlElement = element.querySelector("dl")
          if (dlElement) {
            const dtElements = Array.from(dlElement.querySelectorAll("dt"))
            const ddElements = Array.from(dlElement.querySelectorAll("dd"))
            const pairs = dtElements.map((dt, index) => ({
              left: dt.textContent?.trim() || "",
              right: ddElements[index]?.textContent?.trim() || "",
            }))
            return {
              type: "matching",
              stem,
              pairs,
            }
          } else {
            // Legacy: Extract pairs from two lists
            const lists = element.querySelectorAll("ul")
            if (lists.length === 2) {
              const leftItems = Array.from(lists[0].querySelectorAll("li")).map(
                (li) => li.textContent?.trim() || ""
              )
              const rightItems = Array.from(
                lists[1].querySelectorAll("li")
              ).map((li) => li.textContent?.trim() || "")
              const pairs = leftItems.map((left, index) => ({
                left,
                right: rightItems[index] || "",
              }))
              return {
                type: "matching",
                stem,
                pairs,
              }
            } else {
              // Legacy: Extract pairs from one list that has first left items then right items
              // This only occurred due to some html mangling that we cannot reproduce so far
              const listItems = Array.from(element.querySelectorAll("li")).map(
                (li) => li.textContent?.trim() || ""
              )
              const midPoint = Math.floor(listItems.length / 2)
              const leftItems = listItems.slice(0, midPoint)
              const rightItems = listItems.slice(midPoint)
              const pairs = leftItems.map((left, index) => ({
                left,
                right: rightItems[index] || "",
              }))
              return {
                type: "matching",
                stem,
                pairs,
              }
            }
          }
        default:
          throw new Error(`Unknown question type ${element.dataset.component}`)
      }
    },
    /**
     * Update the light-DOM representation of the custom element from the data
     * @param element The element to update
     * @param data The data to update the element with
     */
    updateElementFromData: (element, data) => {
      if (data.type === "multiple-choice-single-answer") {
        // Replace line breaks in stem with <br> HTML elements for multiple lines
        const formattedStem = _.escape(data.stem).replace(/\n/g, "<br>")

        // Save question contents
        element.innerHTML =
          `<p>${formattedStem}</p>` +
          `<ol type="a">` +
          data.choices
            .map((choice, index) => `<li>${_.escape(choice)}</li>`)
            .join("") +
          `</ol>` +
          `<p>Answer: ${String.fromCharCode(97 + data.correctChoice)}</p>`

        // Store answer in attribute
        element.dataset.component = "multiple-choice-question"
        element.dataset.correctChoice = String(data.correctChoice)
        delete element.dataset.correctChoices
      } else if (data.type === "multiple-choice-multiple-answer") {
        // Save question contents
        element.innerHTML =
          `<p>${_.escape(data.stem)}</p>` +
          `<ol type="a">` +
          data.choices
            .map((choice) => `<li>${_.escape(choice)}</li>`)
            .join("") +
          `</ol>` +
          `<p>Answer: ${data.correctChoices
            .map((choice) => String.fromCharCode(97 + choice))
            .join(", ")}</p>`

        // Store answer in attribute
        element.dataset.component = "multiple-choice-question"
        element.dataset.correctChoices = data.correctChoices.join(",")
        delete element.dataset.correctChoice
      } else if (data.type === "true-false") {
        // Save question contents
        element.innerHTML =
          `<p>${_.escape(data.stem)}</p>` +
          `<p>Answer: ${_.escape(data.answer)}</p>`

        // Store answer in attribute
        element.dataset.component = "true-false-question"
        element.dataset.answer = data.answer
      } else if (data.type === "fill-in-the-blank") {
        // Save question contents
        element.innerHTML =
          `<p>${_.escape(data.stem)}</p>` +
          `<p>Answer: ${_.escape(data.answer)}</p>`

        // Store answer in attribute
        element.dataset.component = "fill-in-the-blank-question"
        element.dataset.answer = data.answer
      } else if (data.type === "matching") {
        // Save question contents
        element.innerHTML =
          `<p>${_.escape(data.stem)}</p>` +
          `<dl>` +
          data.pairs
            .map(
              (pair) =>
                `<dt>${_.escape(pair.left)}</dt><dd>${_.escape(
                  pair.right
                )}</dd>`
            )
            .join("") +
          `</dl>`

        // Store answer in attribute
        element.dataset.component = "matching-question"
      } else {
        throw new Error(`Unknown question type ${(data as any).type}`)
      }
    },
    /** Render the custom element in the Froala editor. Will be rendered in the shadow DOM
     * @param options Options for rendering the custom element
     */
    renderView: (options: RenderViewOptions<QuestionData>) => {
      const { data, onDataChange, element, withStyles, editor, readOnly } =
        options
      return (
        <QuestionCustomComponent
          editor={editor}
          data={data}
          onDataChange={onDataChange}
          element={element}
          withStyles={withStyles}
          readOnly={readOnly}
        />
      )
    },
  }

/** Dialog to add a question
 * @param props Props
 * @param props.editor Froala editor
 * @param props.smartTemplateElement Element that was clicked to open dialog
 * @param props.onClose Callback to close dialog
 */
export function AddQuestionDialog(props: {
  editor: any
  smartTemplateElement: HTMLElement
  onClose: () => void
}) {
  const { editor, smartTemplateElement, onClose } = props

  return (
    <QuestionDialog
      onSave={(data) => {
        // Add question to editor
        const contentDiv = smartTemplateElement.closest(
          "[data-smart-template-control]"
        )!

        // Delete blank line if after content div
        const nextElement = contentDiv.nextElementSibling
        if (nextElement && nextElement.innerHTML === "<br>") {
          nextElement.remove()
        }

        // Create a new section element
        const section = document.createElement("section")
        section.dataset.component = "multiple-choice-question"
        questionCustomElementPlainStem.updateElementFromData(section, data)
        contentDiv.insertAdjacentHTML("afterend", section.outerHTML)

        // Save editor
        editor.undo.saveStep()

        onClose()
      }}
      onCancel={onClose}
      initialData={{
        type: "multiple-choice-single-answer",
        stem: "",
        choices: ["", "", "", ""],
        correctChoice: 0,
      }}
    />
  )
}

/** Props for QuestionCustomComponent */
interface QuestionCustomComponentProps {
  /** Froala editor */
  editor: any
  /** Root element of component in light DOM */
  element: HTMLElement
  /** Data for the question */
  data: QuestionData
  /** Callback to update the data */
  onDataChange?: (data: QuestionData) => void
  /** Function to wrap children in styles */
  withStyles: (children: React.ReactElement) => React.ReactElement
  /** Read-only */
  readOnly: boolean
}

/** Container for question that is styled */
const QuestionContainer = styled("div")(({ theme }) => ({
  marginTop: 5,
  marginBottom: 5,
  padding: 10,
  transition: theme.transitions.create(["background-color", "visibility"], {
    duration: theme.transitions.duration.shortest,
  }),
  "& button": {
    visibility: "hidden",
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    "& button": {
      visibility: "visible",
    },
  },
}))

/** Question react component in the Froala editor
 * @param props Props for component
 */
function QuestionCustomComponent(props: QuestionCustomComponentProps) {
  const { data, onDataChange, editor, element, readOnly } = props
  const [editorOpen, setEditorOpen] = useState(false)

  return (
    <>
      {props.withStyles(
        <QuestionContainer>
          {!readOnly && (
            <div key="buttons" style={{ float: "right" }}>
              <IconButton onClick={() => setEditorOpen(true)}>
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  element.remove()
                  editor.undo.saveStep()
                }}
              >
                <Delete />
              </IconButton>
            </div>
          )}
          <QuestionComponent data={data} />
        </QuestionContainer>
      )}
      {editorOpen && (
        <QuestionDialog
          initialData={data}
          onCancel={() => setEditorOpen(false)}
          onSave={(data) => {
            onDataChange!(data)
            setEditorOpen(false)
          }}
        />
      )}
    </>
  )
}

/**
 * Question react component in the preview
 * @param props Props for component
 * @param props.data Data for question
 */
export function QuestionComponent(props: { data: QuestionData }) {
  const { data } = props

  // Create a string of the correct answer as text
  const answerString = useMemo(() => {
    if (data.type === "multiple-choice-single-answer") {
      return String.fromCharCode(97 + data.correctChoice)
    } else if (data.type === "multiple-choice-multiple-answer") {
      return data.correctChoices
        .map((choice) => String.fromCharCode(97 + choice))
        .join(", ")
    } else if (data.type === "true-false") {
      return data.answer
    } else if (data.type === "fill-in-the-blank") {
      return data.answer
    } else if (data.type === "matching") {
      return ""
    } else {
      throw new Error(`Unknown question type ${(data as any).type}`)
    }
  }, [data])

  return (
    <div>
      <div key="stem">
        {data.stem.split("\n").map((item, index) => {
          return (
            <span key={index}>
              {index > 0 && <br />}
              {item}
            </span>
          )
        })}
      </div>
      <div style={{ clear: "both" }}></div>
      {(data.type === "multiple-choice-single-answer" ||
        data.type === "multiple-choice-multiple-answer") && (
        <ol key="choices" type="a">
          {data.choices.map((choice, index) => (
            <li key={index}>{choice}</li>
          ))}
        </ol>
      )}
      {data.type === "matching" && <MatchingQuestionAnswer data={data} />}
      {answerString && <div key="answer">Answer: {answerString}</div>}
    </div>
  )
}

/**
 * Styled component for the Chevron icon in the MatchingQuestionAnswer component.
 *
 * It should be placed inside a box with an exact height of 50 pixels.
 */
const MatchingQuestionChevron = styled("div")({
  position: "relative",
  display: "block",
  height: 56, // Height should be double border thickness
  "&::before, &::after": {
    position: "absolute",
    display: "block",
    content: '""',
    border: "28px solid transparent", // Adjust chevron size
  },
  "&::before": {
    left: 0,
    borderLeftColor: "#aaa", // Chevron Color
  },
  "&::after": {
    left: -2, // Adjust thickness
    borderLeftColor: "#fff", // Match chevron background colour
  },
})

/**
 * Styled component for the box containing a matching question item.
 */
const MatchingQuestionItemBox = styled(Box)(({ theme }) => ({
  flex: 1,
  border: "1px solid #aaa",
  backgroundColor: "#fff",
  height: 56,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  textAlign: "center",
  justifyContent: "center",
  padding: "5px",
  lineHeight: "1.2em",
}))

/**
 * Styled component for a matching question item.
 *
 * The font size is automatically adjusted to fit the text in the box.
 * @param props Props
 * @param props.position Position of item in the matching question
 * @param props.children Children
 */
function MatchingQuestionItem(props: {
  position: "left" | "right"
  children: React.ReactNode
}) {
  const { fontSize, ref } = useFitText()

  return (
    <MatchingQuestionItemBox
      ref={ref}
      style={{
        fontSize,
        // Leave space for the chevron
        paddingLeft: props.position === "right" ? 35 : 5,
      }}
    >
      {props.children}
    </MatchingQuestionItemBox>
  )
}

/**
 * Matching question answer.
 *
 * Displays as vertical list of pairs of items. Each item is a box with
 * a chevron in the middle with the left and right items on either side.
 *
 * @param props Props
 * @param props.data Data for question
 */
function MatchingQuestionAnswer(props: { data: MatchingQuestionData }) {
  const { data } = props
  return (
    <Box>
      {data.pairs.map((pair, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 1,
            position: "relative",
            height: 50,
          }}
        >
          <MatchingQuestionItem position="left">
            {pair.left}
          </MatchingQuestionItem>
          <MatchingQuestionChevron />
          <MatchingQuestionItem position="right">
            {pair.right}
          </MatchingQuestionItem>
        </Box>
      ))}
    </Box>
  )
}
