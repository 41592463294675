import React from "react"
import TrashScreen from "./TrashScreen"

/**
 * TrashedPage function
 * @param props props
 * @param props.location location
 */
const TrashPage = (props: { location: any }) => {
  const { location } = props

  const breadcrumbs = location?.state?.breadcrumbs ?? [
    { label: "Courses", href: "/" },
    { label: "Tools", href: "/tools" },
  ]

  const screenProps = {
    breadcrumbs: breadcrumbs,
  }
  return <TrashScreen {...screenProps} />
}

export default TrashPage
