import { Typography, Box, Button } from "@mui/material"
import { Prompt } from "react-router-dom"
import PageLayout from "../../components/layouts/PageLayout"
import BrandingForm from "./BrandingForm"
import useBranding from "./hooks/useBranding"

/**
 * Screen for displaying global branding configuration options for a
 * given user.
 */
const BrandingScreen = () => {
  const { saveBranding, pendingChanges, loading, ...branding } = useBranding()

  return (
    <PageLayout
      fixed
      maxWidth="md"
      breadcrumbs={[
        { label: "Courses", href: "/" },
        { label: "Tools", href: "/tools" },
        { label: "Customize Branding" },
      ]}
    >
      <Prompt
        when={pendingChanges > 0}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Box>
        <Typography gutterBottom variant="h4">
          Customize Branding
        </Typography>
        <BrandingForm {...branding} />
        {!loading && (
          <Box marginTop={4} display="flex" justifyContent="center">
            <Button
              size="large"
              variant="contained"
              disabled={!pendingChanges}
              onClick={saveBranding}
            >
              Save Branding
            </Button>
          </Box>
        )}
      </Box>
    </PageLayout>
  )
}

export default BrandingScreen
