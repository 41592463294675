/**
 * Map documents in the process of uploading and return them as normalized course objects
 *
 * @param uploadsInProgress Current jobs running for in-progress uploads
 * @returns {*[]} array in progress uploads mapped to objects used by CourseItem component
 */
const asVirtualDocuments = (uploadsInProgress: any[]) =>
  uploadsInProgress.map((upload) => ({
    id: upload.async_id,
    title: upload.data.currentFilename,
    isLoading: true,
  }))

export default asVirtualDocuments
