import { Typography } from "@mui/material"
import getDetectionDescription from "./getDetectionDescription"
import { useDetectionValue } from "../../../hooks/useDetection"

const DetectionTooltip = ({ detection: detectionId }) => {
  const detection = useDetectionValue(detectionId)

  return (
    <Typography variant="subtitle2">
      {getDetectionDescription(detection)}
    </Typography>
  )
}

export default DetectionTooltip
