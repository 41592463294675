/**
 * The locales that are available for translation.
 * Ensure that this list is in alphabetical order.
 */
export const availableTranslationLocales = [
  "pt-BR", // Brazilian Portuguese
  "zh-CN", // Chinese (Simplified)
  "fr", // French
  "de", // German
  "ja", // Japanese
  "ko", // Korean
  "es", // Spanish
]

/**
 * The names of the locales.
 */
export const localeNames: Record<string, string> = {
  en: "English",
  fr: "French",
  es: "Spanish",
  de: "German",
  it: "Italian",
  pt: "Portuguese",
  ru: "Russian",
  "zh-CN": "Chinese (Simplified)",
  ja: "Japanese",
  ko: "Korean",
  ar: "Arabic",
  hi: "Hindi",
  nl: "Dutch",
  sv: "Swedish",
  pl: "Polish",
  tr: "Turkish",
  vi: "Vietnamese",
  th: "Thai",
  id: "Indonesian",
  el: "Greek",
  he: "Hebrew",
  cs: "Czech",
  da: "Danish",
  fi: "Finnish",
  no: "Norwegian",
  hu: "Hungarian",
  ro: "Romanian",
  uk: "Ukrainian",
  bn: "Bengali",
  fa: "Persian",
  "pt-BR": "Brazilian Portuguese",
}
