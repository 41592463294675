import { Item } from "../../homeNavigation/types"

export type ResultState = {
  learningPaths: LearningPath[]
  error: string | null
}

export type LearningPath = any

/**
 * Add course to end of given learning path
 * @param prev - Existing Learning Path items state
 * @param path - Path to add course to end of
 * @param course - Desired course to add
 */
export const addVirtualCourseToPath = (
  prev: ResultState,
  path: any,
  course: Item
) => {
  const updatedPath = {
    ...path,
    courses: [...path.courses, course],
  }

  return asVirtualPathResult(prev, updatedPath)
}

/**
 * Remove course from Learning path virtually
 * @param prev Existing leearning path items state
 * @param path - Path to remove course from
 * @param course - Course to remove
 */
export const removeVirtualCourseFromPath = (
  prev: ResultState,
  path: any,
  course: Item
) => {
  const updatedPath = {
    ...path,
    courses: path.courses.filter((item: Item) => item.id !== course.id),
  }

  return asVirtualPathResult(prev, updatedPath)
}

/**
 * Reorder courses for a given learning path
 * @param prev - Existing Learning Path Items state
 * @param path - Path to modify courses for
 * @param reorderedIDs - Reordered course IDs for path
 */
export const reorderVirtualCourseInPath = (
  prev: ResultState,
  path: any,
  reorderedIDs: string[]
) => {
  const updatedPath = {
    ...path,
    courses: reorderedIDs
      .map((id: string) =>
        path.courses.find((course: Item) => course.id === id)
      )
      .filter(Boolean),
  }

  return asVirtualPathResult(prev, updatedPath)
}

/**
 * Updated the virtual result with the newly updated learning path
 * @param prev - Existing path data
 * @param updatedPath - Newly updated path
 */
const asVirtualPathResult = (prev: ResultState, updatedPath: LearningPath) => ({
  ...prev,
  learningPaths: [
    ...prev.learningPaths.map((previousPath) =>
      previousPath.path_id === updatedPath.path_id ? updatedPath : previousPath
    ),
  ],
})
