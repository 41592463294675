import { useEffect, useMemo, useState } from "react"
import { isBlockElement } from "../utilities/domUtils"
import debounce from "lodash/fp/debounce"
import {
  getLocationFromElement,
  getLocationFromRange,
} from "../utilities/remarkUtils"

const MOUSE_DEBOUNCE_TIME = 20

/** Hook that finds location for a contextual menu to appear
 * over the selected text and block elements that are hovered over.
 * @param editorRef - Ref object bound to editor
 */
export function useContextualMenu(editorRef: React.RefObject<any>) {
  // Stores the currently selected range
  const [range, setRange] = useState<Range | null>(null)

  // Stores the element that the mouse is currently hovering over
  const [hoverElem, setHoverElem] = useState<HTMLElement | null>(null)

  const handleSelectionChange = useMemo(
    () =>
      debounce(MOUSE_DEBOUNCE_TIME, () => {
        // select the editor element from the editor ref
        const editorElem = editorRef.current?.editor?.$el[0]
        if (!editorElem) {
          return
        }
        const selection = window.getSelection()
        if (!selection) {
          return
        }

        if (selection.rangeCount === 0) {
          setRange(null)
        } else {
          const range = selection.getRangeAt(0)
          if (range.collapsed) {
            setRange(null)
          } else {
            if (editorElem.contains(range.startContainer)) {
              setRange(range)
            } else {
              setRange(null)
            }
          }
        }
      }),
    [editorRef]
  )

  const handleMouseMove = useMemo(
    () =>
      debounce(MOUSE_DEBOUNCE_TIME, (ev) => {
        // Select the editor element from the editor ref
        const editorElem = editorRef.current?.editor?.$el[0]
        if (!editorElem) {
          setHoverElem(null)
          return
        }

        // Check if event occurred within editor
        if (!editorElem.contains(ev.target)) {
          // Check if over hover button
          const hoverButton = document.getElementById(
            "contextual-menu-hover-box"
          )
          if (hoverButton && hoverButton.contains(ev.target)) {
            // Do nothing as hover button should stay during hover
            return
          }

          // Outside of editor, hide hover
          setHoverElem(null)
          return
        }

        // Find parent block node
        let node = ev.target
        while (!isBlockElement(node) && node !== editorElem) {
          node = node.parentNode
        }

        // Hide comment button when outside the editor content
        if (node === editorElem) {
          setHoverElem(null)
          return
        }

        setHoverElem(node)
      }),
    [editorRef]
  )

  const coordinates = useMemo(() => {
    // Use the current selection for coordinates.
    const rect = range?.getClientRects()?.[0]
    if (rect) {
      return {
        top: rect.top - 45,
        left: rect.right - 30,
      }
    }

    // If there is no selection, use the hovered element.
    const editorElem = editorRef.current?.editor?.$el[0]
    const targetRect = hoverElem?.getBoundingClientRect()
    const editorRect = editorElem?.getBoundingClientRect()

    if (targetRect && editorRect) {
      // Get editor parent to get scrollable pane that contains
      // the editor element. This is needed to determine where the
      // bottom is so as not to position the hover button outside of this
      // and cause an extra scrollbar to be added
      const editorParentElem = editorElem.parentNode
      const bottom = editorParentElem.getBoundingClientRect().bottom

      // Top is just above the target rect
      const top = targetRect.top - 10
      return {
        top,
        left: targetRect.right,
        maxHeight: bottom - top,
      }
    }

    return null
  }, [editorRef, hoverElem, range])

  const location = useMemo(() => {
    // select the editor element from the editor ref
    const editorElem = editorRef.current?.editor?.$el[0]
    if (!editorElem || (!hoverElem && !range)) {
      return null
    } else {
      return range != null
        ? getLocationFromRange(range, editorElem)
        : getLocationFromElement(hoverElem!, editorElem)
    }
  }, [editorRef, hoverElem, range])

  // Add listener to editor element to detect mouse movement
  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("selectionchange", handleSelectionChange)

    return () => {
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("selectionchange", handleSelectionChange)
    }
  }, [handleMouseMove, handleSelectionChange])

  return {
    location,
    coordinates,
    hoverElem,
    range,
    clearHover: () => {
      setHoverElem(null)
      setRange(null)
    },
  }
}
