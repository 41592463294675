import { debounce } from "lodash"
import { useEffect, useMemo, useRef } from "react"
import {
  comparePaths,
  getScrollPane,
  isVisibleInContainer,
  scrollIntoView,
} from "../utilities/domUtils"

import { useSelector } from "react-redux"
import {
  selectRemarksScrollLocked,
  selectScrollPath,
} from "../store/editor/selectors"

/**
 * Prior to scrolling, check if any remarks are visible which have the same
 * location path as the newly updated scrollPath. If so, do not proceed with
 * performing a scroll.
 */
const checkCurrentPathVisible = (remarks, container, scrollPath) => {
  for (const remark of remarks) {
    const remarkPath = remark.location?.path ?? []
    // Result is 0 if match, -1 if remark's path is greater than scrollPath
    const result = comparePaths(remarkPath, scrollPath)

    /*
      If the remark's path is greater than the current scroll path, we did not
      find any remarks at the given location
     */
    if (result < 0) {
      return false
    }

    /*
      If the remark's path matches our scroll path and it is visible, return
      true indicating that a scroll should not proceed
     */
    if (
      result === 0 &&
      isVisibleInContainer(document.getElementById(remark.id), container)
    ) {
      return true
    }
  }
  return false
}

/** Hook to keep remarks scroll pane in sync with a path */
function useScrollRemarksToPath(remarks) {
  /** Debounced version of scrollIntoView. Delays long enough to not
   * be tripped up by smooth scrolling.
   */
  const debouncedScrollIntoView = useMemo(
    () => debounce(scrollIntoView, 250),
    []
  )

  const remarksScrollLocked = useSelector(selectRemarksScrollLocked)
  const scrollPath = useSelector(selectScrollPath)

  const remarksRef = useRef()
  const containerRef = useRef()

  useEffect(() => {
    // update the remarks ref every time the remarks list changes
    remarksRef.current = remarks
    containerRef.current = document.querySelector('[data-cy="remarks-list"]')
  }, [remarks])

  // Update scroll position to show appropriate remarks based
  // on the scroll path
  useEffect(() => {
    if (!scrollPath || !remarksRef.current) {
      return
    }

    if (remarksScrollLocked) {
      return
    }

    // Check if any remarks that match the scrollPath are visible
    if (
      checkCurrentPathVisible(
        remarksRef.current,
        containerRef.current,
        scrollPath
      )
    )
      return

    // Find first remark with a path greater or equal to scroll path, and scroll to that
    for (const remark of remarksRef.current) {
      const path = remark.location?.path ?? []
      if (comparePaths(path, scrollPath) <= 0) {
        // Get remark element
        const remarkElem = document.getElementById(remark.id)
        if (!remarkElem) {
          return
        }

        // Determine scroll pane, looking up tree until body or overflow is found
        let scrollPane = getScrollPane(remarkElem)

        // Perform scroll
        debouncedScrollIntoView(remarkElem.getBoundingClientRect(), scrollPane)
        return
      }
    }
  }, [scrollPath, debouncedScrollIntoView, remarksScrollLocked])
}

export default useScrollRemarksToPath
