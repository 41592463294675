import axios from "../../../api/axios"

type CustomInstructionOptionsArg = {
  favourites?: string[]
  recents?: string[]
}

/**
 * Send request to server to update list of custom instructions
 * @param options - New options to save
 */
const saveCustomInstructionOptions = (options: CustomInstructionOptionsArg) => {
  return axios.post(`/configuration/custom_instructions`, {
    options,
  })
}

export default saveCustomInstructionOptions
