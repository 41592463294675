import AddIcon from "@mui/icons-material/Add"
import { IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { selectAfterText } from "../utilities/domUtils"

/**
 * Props for paragraph inserter
 */
export interface ParagraphInserterProps {
  /** Froala editor instance */
  editor: any

  /** Editor wrapper element (fr-wrapper) */
  editorWrapper: HTMLElement
}

/**
 * Allows the user to insert a paragraph between two section elements in the editor. Adds a floating
 * button that appears when the mouse is over a section element that is immediately followed by
 * another section element.
 *
 * Without this, the user could not insert a paragraph between two sections as it is not a valid
 * cursor position.
 *
 * This React component can be mounted anywhere and it will render the button in the correct place.
 * It uses portals to render the button in the correct place.
 *
 * @param props Props
 */
export function ParagraphInserter(props: ParagraphInserterProps) {
  // Create div to mount react element to within the editor wrapper
  const [mountDiv, setMountDiv] = useState<HTMLElement>()

  useEffect(() => {
    const div = document.createElement("div")
    props.editorWrapper.appendChild(div)
    setMountDiv(div)

    return () => {
      // Check if already removed
      if (props.editorWrapper.contains(div)) {
        props.editorWrapper.removeChild(div)
      }
    }
  }, [props.editorWrapper])

  // Return portal to mount react element to
  return mountDiv
    ? createPortal(<ParagraphInserterButton {...props} />, mountDiv)
    : null
}

/** Size of range where insert can happen in pixels per side (top/bottom) */
const INSERT_RANGE_SIZE = 30

/** This renders the actual button that appears.
 * @param props Props
 */
function ParagraphInserterButton(props: ParagraphInserterProps) {
  const { editorWrapper } = props

  const [hoveredSection, setHoveredSection] = useState<HTMLElement | null>(null)

  // Get the content editable element of Froala
  const editorContentEditable = editorWrapper.querySelector(
    ".fr-element"
  ) as HTMLElement

  // Add mouse move listener to editor content editable
  useEffect(() => {
    const mouseMoveListener = (e: MouseEvent) => {
      // Get the element that the mouse is over
      let target = e.target as HTMLElement

      // Ignore if over the button
      if (target.classList.contains("paragraph-insert-button")) {
        return
      }

      // Move up tree until we find a section element or the editor content editable
      while (
        target &&
        target.tagName !== "SECTION" &&
        target !== editorContentEditable
      ) {
        target = target.parentElement as HTMLElement
      }

      if (!target || target === editorContentEditable) {
        setHoveredSection(null)
        return
      }

      // Get target rect
      const targetRect = target.getBoundingClientRect()

      // If within bottom 20 pixels of section, and next sibling is a section or nothing, then show button
      if (
        e.clientY > targetRect.bottom - INSERT_RANGE_SIZE &&
        ((target.nextElementSibling &&
          target.nextElementSibling.tagName === "SECTION") ||
          !target.nextElementSibling)
      ) {
        setHoveredSection(target)
        return
      }

      // If within top 20 pixels of section, and previous sibling is a section, then show button
      if (
        e.clientY < targetRect.top + INSERT_RANGE_SIZE &&
        target.previousElementSibling &&
        target.previousElementSibling.tagName === "SECTION"
      ) {
        setHoveredSection(target.previousElementSibling as HTMLElement)
        return
      }

      setHoveredSection(null)
    }

    // Add mouse move listener
    editorContentEditable.addEventListener("mousemove", mouseMoveListener)

    return () => {
      editorContentEditable.removeEventListener("mousemove", mouseMoveListener)
    }
  }, [editorContentEditable])

  // If no hovered section, then return null
  if (!hoveredSection) {
    return null
  }

  // Determine style of button to be a small circle between the two sections
  const buttonStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: 1000,
    top: `${hoveredSection.offsetTop + hoveredSection.offsetHeight}px`,
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    border: "solid 1px #AAA",
  }

  /** Handle button click */
  function onButtonClick() {
    if (!hoveredSection) {
      return
    }

    // Insert paragraph after hovered section
    hoveredSection.insertAdjacentHTML("afterend", "<p><br></p>")
    const newParagraph = hoveredSection.nextElementSibling as HTMLElement
    selectAfterText(newParagraph, props.editor)

    // Save step is required when manually inserting HTML
    props.editor.undo.saveStep()

    // Hide button
    setHoveredSection(null)
  }

  return (
    <IconButton
      aria-label="add"
      style={buttonStyle}
      className="paragraph-insert-button"
      size="small"
      onClick={onButtonClick}
    >
      <AddIcon fontSize="inherit" />
    </IconButton>
  )
}
