import { Auth } from "@aws-amplify/auth"

type AuthenticationHeaderOptions = {
  headerName?: string
}

/**
 * Retrieves the current authentication headers, if any.
 * @param options - AuthenticationHeaderOptions
 */
export async function getAuthenticationHeaders(
  options: AuthenticationHeaderOptions = {}
) {
  const { headerName = "X-Cognito-Authorization" } = options

  try {
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()
    return {
      [headerName]: `Bearer ${token}`,
    }
  } catch {
    return null
  }
}

export default getAuthenticationHeaders
