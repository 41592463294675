import { useSelector } from "react-redux"
import { selectCommentById } from "../store"
import useCommentsDispatch from "./useCommentsDispatch"

/**
 * @typedef {import("../store/remarks/remarks").Comment} Comment
 * @typedef {Comment & ReturnType<typeof useCommentDispatch>} CommentState */

/**
 * Access the current value of a comment.
 *
 * @param {string} id The ID for the comment.
 *
 *  Accepts either a primary ID (e.g. "951ef324-5bc4-43b7-9f9c-bd0926a8a7e4")
 *  or a composite remark ID (e.g. "comment#951ef324-5bc4-43b7-9f9c-bd0926a8a7e4").
 */
export const useCommentValue = (id) =>
  useSelector((state) => selectCommentById(state, id))

/**
 * Access the dispatch actions for manipulating the selected comment.
 *
 * @param {string} id
 */
export const useCommentDispatch = (id) => {
  const {
    editComment,
    deleteComment,
    resolveComment,
    replyToComment,
    editCommentReply,
    deleteCommentReply,
  } = useCommentsDispatch()

  return {
    /**
     * Update the text of the comment.
     * @param {string} text
     */
    edit: async (text) => editComment(id, text),
    /** Delete the current comment. */
    deleteComment: () => deleteComment(id),
    /** Mark the comment as resolved. */
    resolve: () => resolveComment(id),
    /** Reply to comment. */
    reply: (text) => replyToComment(id, text),
    /** Edit given comment reply. */
    editReply: (replyId, text) => editCommentReply(id, replyId, text),
    /** Delete given comment reply. */
    deleteReply: (replyId) => deleteCommentReply(id, replyId),
  }
}

/**
 * Access the state for an editor comment.
 *
 * The state includes controls for manipulating the comment (e.g. replying,
 * resolving).
 *
 * @param {string} id The ID for the comment.
 *
 *  Accepts either a primary ID (e.g. "951ef324-5bc4-43b7-9f9c-bd0926a8a7e4")
 *  or a composite remark ID (e.g. "comment#951ef324-5bc4-43b7-9f9c-bd0926a8a7e4").
 *
 * @return {CommentState}
 */
export const useComment = (id) => ({
  ...useCommentDispatch(id),
  ...useCommentValue(id),
})

export default useComment
