import { createAsyncThunk } from "@reduxjs/toolkit"
import selectCommentId from "../selectors/selectCommentId"

import selectCurrentCourse from "../selectors/selectCurrentCourse"
import selectGuest from "../selectors/selectGuest"
import selectServerId from "../selectors/selectServerId"

/** Action to edit a comment reply. */
export const editCommentReply = createAsyncThunk(
  "comments/reply/edit",
  async (arg, context) => {
    const {
      getState,
      extra: { api },
    } = context

    const state = getState()
    const { commentId, id, text } = arg

    return api.editCommentReply({
      commentId: selectServerId(commentId),
      courseId: selectCurrentCourse(state.remarks),
      id,
      text,
      as: selectGuest(state.remarks),
    })
  }
)

export default editCommentReply

/**
 * Build the reducer cases for editing a comment reply.
 *
 * @param {import("../remarks").RemarkActionReducerMapBuilder} builder
 */
export const buildCasesForEditCommentReply = (builder) => {
  builder.addCase(editCommentReply.fulfilled, (state, action) => {
    const { reply } = action.payload

    /** @type {import("@reduxjs/toolkit/node_modules/immer/dist/internal").WritableDraft<import("../remarks").Comment>} */
    const comment = state.entities[selectCommentId(reply.commentId)]
    if (!comment) {
      return
    }

    const index = comment.replies.findIndex(({ id }) => id === reply.id)
    if (index !== -1) {
      comment.replies[index] = reply
    }
  })
}
