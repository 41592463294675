import { Box, Typography } from "@mui/material"
import ItemList from "../../../itemList/ItemList"
import LearningPath from "./LearningPath"
import LearningPathSkeleton from "./LearningPathSkeleton"

/**
 * Component that renders list of all learning paths for a user
 * @param props - Component props
 */
const LearningPathsItemList = (props: any) => {
  const {
    learningPaths,
    query,
    displaySearchResults,
    loading,
    ...learningPathProps
  } = props

  const isEmpty = !loading && learningPaths.length === 0

  // When triggering loading skeletons, have it be the same number as however many visible courses there were before for consistency
  const numSkeletons = learningPaths.length > 0 ? learningPaths.length : 6
  return (
    <ItemList>
      {!loading
        ? learningPaths.map((path: any) => (
            <LearningPath
              key={path.path_id}
              path={path}
              {...learningPathProps}
            />
          ))
        : [...Array(numSkeletons)].map((i, index) => (
            <LearningPathSkeleton key={index} />
          ))}
      {isEmpty && (
        <Box
          marginTop={16}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body2">
            {displaySearchResults
              ? "No learning paths match your search."
              : `You don't have any Learning Paths yet. Click "Add path" to get started.`}
          </Typography>
        </Box>
      )}
    </ItemList>
  )
}

export default LearningPathsItemList
