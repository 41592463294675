import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { QuickInsertContext, QuickInsertItem } from "../quickInsertItems"
import fetchUserConfiguration from "../api/fetchUserConfiguration"
import { without } from "lodash"
import { selectAfterText } from "../../../utilities/domUtils"

/**
 * Hook for handling Quick Insert logic
 * @param insertContext - Object containing data on insert position
 * @param closeMenu - Function to close quick insert menu
 * @param quickInsertItems - List of items in menu
 * @param enableFavourites - Feature flag for starred items
 */
const useQuickInsert = (
  insertContext: QuickInsertContext,
  /**
   * If nothing is passed as an argument to closeMenu, the
   * list of starred items has not been modified, indicates
   * to the function that a save does not need to be triggered
   */
  closeMenu: (starredItems?: string[]) => void,
  quickInsertItems: QuickInsertItem[],
  enableFavourites?: boolean
) => {
  const { anchorElement, editor } = insertContext

  // Search text state
  const [searchText, setSearchText] = useState("")

  // Favourited list item options
  const [starredIDs, setStarredIDs] = useState<string[]>([])

  // Ref to track when changes have been made to the list of starred options
  const isModified = useRef<boolean>(false)

  // Ref for QuickInsert menu element
  const menuRef = useRef<any>(null)

  // Flag to track whether the menu currently has focus when open.
  const menuHasFocus = useRef<boolean>(false)

  // Upon mounting, fetch the starred Quick Insert options from the configuration endpoint
  useEffect(() => {
    fetchUserConfiguration()
      .then((response) => {
        const { favourites } = response.data
        setStarredIDs(favourites)
        isModified.current = false
      })
      .catch(() => {
        setStarredIDs([])
      })
  }, [])

  // Invoke callback to close menu, optionally passing the new list of starred items if modifed
  const saveAndClose = useCallback(() => {
    closeMenu(isModified.current ? starredIDs : undefined)
  }, [closeMenu, starredIDs])

  // Listen for selection changes
  useEffect(() => {
    /**
     * Handler for when a selection is changed in the editor.
     */
    const onSelectionChanged = () => {
      const selection = window.getSelection()
      if (!selection) {
        saveAndClose()
        return
      }

      if (anchorElement) {
        if (
          !selection.isCollapsed ||
          !selection.anchorNode ||
          !anchorElement.contains(selection.anchorNode)
        ) {
          // Close menu if selection is not a text selection with a collapsed range and anchor node is not the anchor element
          saveAndClose()
        }
      }
    }

    document.addEventListener("selectionchange", onSelectionChanged)
    return () => {
      document.removeEventListener("selectionchange", onSelectionChanged)
    }
  }, [anchorElement, saveAndClose])

  // Listen and intercept keydown events
  useEffect(() => {
    /**
     * Handler for logic on a keypress in the quick insert menu
     * @param event - Keydown event fired
     */
    const handleFocusSwitch = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        // By default, hitting escape would close the menu anyway, but we wish to also save the latest starred items while closing it.
        saveAndClose()
      }

      /**
       * The below conditional handles the switching of focus between the cursor in Froala,
       * positioned at the anchorNode, and the selectable MenuItems in the quick insert list.
       * In order to handle the ability to type to filter options, along with navigating the list
       * with arrow keys, this listener checks which key was entered and programatically swaps
       * focus based on the key pressed.
       */
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        // If arrow key was pressed, we want to have the Menu focused if it is not already.
        if (!menuHasFocus.current) {
          // Preventing default stops an initial half-scroll
          event.preventDefault()

          // If menu is not currently in focus, apply focus to the first Menu Item
          const firstMenuItem =
            menuRef.current.querySelector('[role="menuitem"]')
          if (firstMenuItem) {
            firstMenuItem?.focus()
            // Update flag so we know the menu is in focus
            menuHasFocus.current = true
          }
        }
      } else if (event.key !== "Enter") {
        /***
         *  If some other arrow key was pressed while the Menu is focused, we want to switch focus
         * back to cursor position (denoted by the anchorElement).
         * We can disregard Enter key events because they are implicitly handled by the MenuItem's onClick handler
         */

        if (menuHasFocus) {
          event.stopPropagation()
          // Position cursor to where it initially was
          selectAfterText(anchorElement as HTMLElement, editor)
          menuHasFocus.current = false
        }
      }
    }

    document.addEventListener("keydown", handleFocusSwitch, true)
    return () => {
      document.removeEventListener("keydown", handleFocusSwitch, true)
    }
  }, [anchorElement, editor, saveAndClose])

  // Close menu when too much text is typed
  useEffect(() => {
    if (searchText.length > 10) {
      saveAndClose()
    }
  }, [searchText, saveAndClose])

  // Update search text when anchor element content changes
  useEffect(() => {
    const observer = new MutationObserver(() => {
      setSearchText(anchorElement.textContent!.slice(1).replace("/", ""))
    })
    observer.observe(anchorElement, { characterData: true, subtree: true })
    return () => {
      observer.disconnect()
    }
  }, [anchorElement])

  /**
   * Filter initial list of available items, based on the contextual position in
   * the editor.
   */
  const availableItems = useMemo(() => {
    return quickInsertItems.filter((item) => {
      if (item.isAvailable && !item.isAvailable(insertContext)) {
        return false
      }
      return true
    })
  }, [insertContext, quickInsertItems])

  /**
   * Filter the overall available items based on search query. Any starred items are also
   * excluded, since they are rendered in a separate list.
   */
  const filteredItems = useMemo(() => {
    return availableItems.filter((item) => {
      if (!searchText) {
        return true
      }
      if (item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
        return true
      }
      if (
        (item.keywords || []).some(
          (keyword) =>
            keyword.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      ) {
        return true
      }
      return false
    })
  }, [availableItems, searchText])

  /**
   * Sort all filtered items based on whether they have been starred or not
   */
  const { starredItems, normalItems } = filteredItems.reduce(
    (acc, item: QuickInsertItem) => {
      if (enableFavourites && starredIDs.includes(item.id)) {
        acc.starredItems.push(item)
      } else {
        acc.normalItems.push(item)
      }
      return acc
    },
    {
      starredItems: [] as QuickInsertItem[],
      normalItems: [] as QuickInsertItem[],
    }
  )

  /**
   * Mark the list as modified with the ref, and either add or remove
   * the selection from the starred list state.
   * @param id - String id of the list option selected
   * @param starred - True if the user is favouriting it, false if un-favouriting it
   */
  const updateStarredItems = (id: string, starred: boolean) => {
    isModified.current = true
    setStarredIDs((prev) => (starred ? [...prev, id] : without(prev, id)))
    menuRef.current.focus()
  }

  // Determine direction of popup
  const direction = useMemo<"up" | "down">(() => {
    const rect = anchorElement.getBoundingClientRect()
    const editorRect = document.body.getBoundingClientRect()
    const bottomSpace = editorRect.height - rect.bottom
    const topSpace = rect.top
    return bottomSpace > topSpace ? "down" : "up"
  }, [anchorElement])

  return {
    direction,
    anchorElement,
    updateStarredItems,
    normalItems,
    starredItems,
    searchText,
    saveAndClose,
    menuRef,
  }
}

export default useQuickInsert
