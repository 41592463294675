import { ItemsState } from "../types"

/**
 * Modify the current items in the state to include any recently completed upload jobs
 * @param prev - Current items from state
 * @param uploads - List of newly completed uploads
 */
const addVirtualCompletedUploads = (prev: ItemsState, uploads: any[]) => {
  // Map the completed uploads list to a map of entities with ID as key
  const completedUploadEntities = uploads.reduce((acc, upload) => {
    acc[upload.data.course_id] = {
      title: upload.data.title,
      id: upload.data.course_id,
      isLoading: false,
      status: "Completed",
      last_update: upload.data.last_update,
      duration_minutes: upload.data.duration_minutes,
    }
    return acc
  }, {})

  // Map the completed uploads list to a simple list of IDs
  const completedUploadIDs = uploads.map(
    (item) => item.data.course_id as "string"
  )

  return {
    ...prev,
    ids: {
      ...prev.ids,
      // Uploads will always appear at root level, so append the new upload completions to "__ALL__" directory
      __ALL__: [...completedUploadIDs, ...prev.ids["__ALL__"]],
    },
    entities: {
      // Add completed uploads to entities map
      ...prev.entities,
      ...completedUploadEntities,
    },
  }
}

export default addVirtualCompletedUploads
