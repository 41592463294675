import { Description, FolderOutlined } from "@mui/icons-material"
import { Box, Fade, Checkbox } from "@mui/material"
import { useState } from "react"
import AvatarWrapper from "../../../itemList/AvatarWrapper"
import { Item } from "../../../../homeNavigation/types"

type ItemAvatarProps = {
  item: Item
  checkedItems: string[]
  handleCourseChecked: (event: any, id: string) => void
}

/**
 * Component to render avatar of directory item
 * @param props - Component props
 */
const DirectoryItemAvatar = (props: ItemAvatarProps) => {
  const { item, checkedItems, handleCourseChecked } = props

  const { id, item_type, image_url, custom_image_url } = item

  /**
   * URL to display defaults to user-uploaded, if it exists.
   * If not, fall back on URL of first image in document.
   */
  const url = custom_image_url ?? image_url

  const isFolder = item_type === "folder"
  const isChecked = checkedItems.includes(id)
  const isInSelectionMode = checkedItems.length > 0

  const [isHovered, setIsHovered] = useState(false)

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
    >
      <Fade in={!isInSelectionMode}>
        {/** Child of fade must be wrapped in div, see https://github.com/mui/material-ui/issues/27154#issuecomment-1117386458*/}
        <div style={{ width: "100%", height: "100%" }}>
          {isFolder ? (
            <AvatarWrapper variant="circle">
              <Fade in={!isHovered}>
                <FolderOutlined color="secondary" />
              </Fade>
            </AvatarWrapper>
          ) : (
            <AvatarWrapper
              variant="square"
              image={!isInSelectionMode && url ? `url(${url})` : null}
            >
              {!url && (
                <Fade in={!isHovered}>
                  <Description color="primary" />
                </Fade>
              )}
            </AvatarWrapper>
          )}
        </div>
      </Fade>
      <Fade in={isHovered || isInSelectionMode}>
        <Checkbox
          sx={{ position: "absolute" }}
          color="primary"
          onClick={(e) => e.stopPropagation()}
          checked={isChecked}
          onChange={(event: any) => handleCourseChecked(event, id)}
          inputProps={{ "aria-label": "Select Course" }}
        />
      </Fade>
    </Box>
  )
}

export default DirectoryItemAvatar
