import { createSelector } from "reselect"

const createStageSelector = (inputSelector, stages) => {
  let latestStage = null

  return createSelector(inputSelector, (job) => {
    if (job !== undefined) {
      const stage = stages[job.status]

      const jobInfo = {
        current: job.current_stage,
        total: job.num_stages,
      }
      if (job.data != null) {
        if (
          job.data.current_filename != null &&
          job.data.current_filename !== ""
        ) {
          jobInfo.currentFilename = job.data.current_filename
        }
        jobInfo.filenames = job.data.filenames
        jobInfo.failedFilename = job.data.failed_filename
        jobInfo.failedFileDetails = job.data.failure_details

        jobInfo.warnings = job.data.warnings
      }

      if (stage !== undefined) {
        latestStage = {
          ...stage,
          ...jobInfo,
        }
      }
    } else {
      latestStage = {
        ...stages["failed"],
      }
    }

    return latestStage
  })
}

export default createStageSelector
