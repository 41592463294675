import { Button, InputAdornment, styled, TextField } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback, useRef } from "react"
import PropTypes from "prop-types"

/**
 * Present a value that the user can copy to their clipboard.
 *
 * When clicking on the field, the entire text will be selected for easy
 * hotkey-based copying. Alternatively, the user can click on the "copy to
 * clipboard" button and the text will automatically be copied to their clipboard.
 */
const CopyToClipboardField = (props) => {
  const inputRef = useRef()
  const { disabled, value, ...otherProps } = props
  const { enqueueSnackbar } = useSnackbar()

  const onInputClick = useCallback(
    () => inputRef.current.setSelectionRange(0, value.length),
    [value]
  )

  const onCopyClick = useCallback(
    (event) => {
      event.preventDefault()
      navigator.clipboard.writeText(value).then(() => {
        enqueueSnackbar("Copied to Clipboard", { preventDuplicate: true })
      })
    },
    [enqueueSnackbar, value]
  )

  return (
    <CopyToClipboardTextField
      disabled={disabled}
      inputRef={inputRef}
      data-cy="public-link"
      readOnly
      size="small"
      value={value}
      {...otherProps}
      InputProps={{
        inputProps: { tabIndex: "-1" },
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={onCopyClick} disabled={disabled} size="small">
              Copy
            </Button>
          </InputAdornment>
        ),
        onClick: onInputClick,
      }}
    />
  )
}

CopyToClipboardField.propTypes = {
  /** When `true`, normal interactions will be disabled. */
  disabled: PropTypes.bool,
  /** Optional text to hint the fields usage. */
  helperText: PropTypes.string,
  /** When `true`, the input will take up the full width of its container. */
  fullWidth: PropTypes.bool,
  /** A value that the user can copy to their clipboard. */
  value: PropTypes.string,
}

CopyToClipboardField.defaultProps = {
  disabled: false,
  fullWidth: false,
  value: "",
}

export default CopyToClipboardField

const CopyToClipboardTextField = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-root": {
    paddingRight: theme.spacing(0.5),
  },
  ".MuiInputBase-input": {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}))
