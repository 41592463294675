import { useEffect, useState } from "react"
import { useFlag } from "../../../utilities/feature-management"

export const tabValueEnum = {
  EXPORT_SETTINGS: 0,
  ASSET_SETTINGS: 1,
  BRANDING: 2,
}

const { EXPORT_SETTINGS, ASSET_SETTINGS, BRANDING } = tabValueEnum

/**
 * Hook for displaying relevant tabs on export screen
 * @param format - Current export format
 */
const useExportTabs = (format: string) => {
  const [tabValue, setTabValue] = useState(0)
  const [availableTabs, setAvailableTabs] = useState<any[]>([])

  const useNewSettingsLayout = useFlag("rollout-new-export-settings-layout")

  // Reset to first tab if format changes
  useEffect(() => {
    const showAssetSettings = useNewSettingsLayout && format !== "video-script"

    const showExportSettings =
      !useNewSettingsLayout ||
      format.startsWith("lms") ||
      format.startsWith("scorm") ||
      format === "pptx" ||
      format === "epub" ||
      format === "video-script" ||
      format === "docx"

    const visibleTabs = [
      ...(showExportSettings
        ? [
            {
              value: EXPORT_SETTINGS,
              label: "Export Settings",
            },
          ]
        : []),
      ...(showAssetSettings
        ? [
            {
              value: ASSET_SETTINGS,
              label: "Course Asset Settings",
            },
          ]
        : []),
      ...(format !== "video-script"
        ? [
            {
              value: BRANDING,
              label: "Branding",
            },
          ]
        : []),
    ]
    setAvailableTabs(visibleTabs)
    setTabValue(visibleTabs[0].value)
  }, [format, useNewSettingsLayout])

  return {
    availableTabs,
    tabValue,
    setTabValue,
  }
}

export default useExportTabs
