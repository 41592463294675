import { isHeadingElement } from "./findSectionStart"
import { nextElement } from "./headerOperations"

/**
 * Locates first instance of a heading element in a given container. Returns null if no headings exist in container.
 * @param options
 * @returns {ChildNode}
 */
const findFirstHeading = (options = {}) => {
  const { container = document.body } = options
  let element = container.firstChild

  while (element && !isHeadingElement(element, container)) {
    element = nextElement(element, container)
  }
  return element
}

export default findFirstHeading
