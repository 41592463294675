import axios from "axios"
import config from "../config"
import { featureDecisions } from "../utilities/features"
import refreshCookies from "./refreshCookies"
import getAuthenticationHeaders from "./utils/getAuthenticationHeaders"
import axiosRetry from "axios-retry"

const RETRY_CONFIG = {
  /** The number of times to retry any given request. */
  retries: 3,
  /** The delay strategy between reties. */
  retryDelay: axiosRetry.exponentialDelay,
}

/**
 * Create an Axios instance configured to make authenticated requests.
 *
 * @param {import("axios").AxiosRequestConfig} options
 * @return {import("axios").AxiosInstance} An authenticated Axios instance.
 **/
export const createAuthenticatedInstance = (options) => {
  const instance = axios.create({
    baseURL: config.apiUrl,
    withCredentials: featureDecisions.useServerSideCookies(),
    ...options,
  })

  // Configure for automatic retries
  axiosRetry(instance, RETRY_CONFIG)

  // Add an authentication header to each request.
  instance.interceptors.request.use(async (config) => {
    config.headers = {
      ...config.headers,
      ...(await getAuthenticationHeaders()),
    }
    return config
  })

  // Refresh CloudFront cookies on each request.
  if (!featureDecisions.useServerSideCookies()) {
    instance.interceptors.request.use((config) => {
      if (config.url !== "/get_auth_cookies") {
        refreshCookies({ axios: instance }).catch((e) => {
          // No-op: If we can't refresh the cookies we still may need the config on a public route, so return anyway
        })
      }
      return config
    })
  }

  return instance
}

/**
 * Set up auth for api gateway calls
 */
function createGatewayInstance() {
  const instance = axios.create({
    baseURL: "/api/",
    withCredentials: featureDecisions.useServerSideCookies(),
  })

  // Configure for automatic retries
  axiosRetry(instance, RETRY_CONFIG)

  // Add an authentication header to each request.
  instance.interceptors.request.use(async (config) => {
    config.headers = {
      ...config.headers,
      ...(await getAuthenticationHeaders({ headerName: "Authorization" })),
    }
    return config
  })

  return instance
}

export const axiosInstance = createAuthenticatedInstance()

export const gateway = createGatewayInstance()

export default axiosInstance
