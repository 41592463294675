import { createContext, ReactNode } from "react"

/** Context interface for components within the EditorScreen to get access to the editor */
export interface EditorScreenContextType {
  /** The Froala editor */
  editor: any

  /** The editor container (fr-element) */
  editorContainer: HTMLElement | null

  /** Displays a React element within the tree
   * @param generator Called with onClose function that should be called
   * when the element closes itself
   */
  displayReactElement: (generator: (onClose: () => void) => ReactNode) => void
}

/** Context for components within the EditorScreen to get access to the editor */
export const EditorScreenContext = createContext<EditorScreenContextType>({
  editor: null,
  displayReactElement: () => {},
  editorContainer: null,
})
