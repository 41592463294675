import ConfirmDialog from "../../../components/widgets/ConfirmDialog"
import { Box, DialogContentText, TextField } from "@mui/material"
import React, { useState } from "react"

/**
 *
 * @param props
 */
const CreateLearningPathDialog = (props) => {
  const [pathName, setPathName] = useState("")

  const { open, onConfirm, onCancel } = props

  return (
    <ConfirmDialog
      open={open}
      title="Create Learning Path"
      message={
        <Box>
          <DialogContentText>
            Enter a name for your Learning Path
          </DialogContentText>
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            value={pathName}
            onInput={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setPathName(e.target.value)
            }}
          />
        </Box>
      }
      cancelText={"Cancel"}
      confirmText={"Create"}
      onCancel={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onCancel()
        setPathName("")
      }}
      onConfirm={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onConfirm(pathName)
        setPathName("")
      }}
      onClose={onCancel}
    />
  )
}

export default CreateLearningPathDialog
