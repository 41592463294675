import { Box, Link, Stack, styled, Typography } from "@mui/material"
import { isNumber } from "lodash"
import PropTypes from "prop-types"

import { useAuth } from "../../contexts/authorization"
import PageLayout from "../layouts/PageLayout"
import useRandomGraphic, { ERROR_GRAPHICS } from "../widgets/RandomErrorGraphic"

const Graphic = styled("img")({
  maxWidth: "300px",
  userSelect: "none",
  userDrag: "none",
})

/**
 * A screen displayed when we are unabled to fetch a course.
 */
const NotFoundScreen = (props) => {
  const { overrideGraphic, ...otherProps } = props

  const { user } = useAuth()
  const randomSrc = useRandomGraphic()
  const src = isNumber(overrideGraphic)
    ? ERROR_GRAPHICS[overrideGraphic]
    : randomSrc

  return (
    <PageLayout fixed maxWidth="md" {...otherProps}>
      <Stack
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "flex-start",
          alignContent: "center",
          gap: 2,
        }}
      >
        <Box flex="1 1 auto" minWidth="300px" width="300px">
          <Typography variant="h2" component="h1">
            Not Found
          </Typography>
          <Typography variant="h6" component="p" sx={{ paddingBottom: 4 }}>
            We searched high and low and were unable to find the course you were
            looking for.
          </Typography>
          <Typography>
            {user ? (
              <>
                Jump over to the <Link href="/">Home</Link> page to see a
                complete list of all your available courses.
              </>
            ) : (
              <>
                Maybe this course is not being shared anymore. Check with the
                document owner to see if they will grant you access again.
              </>
            )}
          </Typography>
        </Box>
        <Graphic src={src} alt="" draggable="false" />
      </Stack>
    </PageLayout>
  )
}

NotFoundScreen.propTypes = {
  /** Override the random graphic with a specific selection. */
  overrideGraphic: PropTypes.number,
}

export default NotFoundScreen
