import {
  ActionReducerMapBuilder,
  Draft,
  createAsyncThunk,
} from "@reduxjs/toolkit"
import { RemarksState } from "../remarks"
import axios from "../../../api/axios"
import { AppState } from "../.."
import selectDetectionById from "../selectors/selectDetectionById"

/**
 * Update the review state for a given detection.
 *
 * @param courseId The detection's course id.
 * @param detectionKey The identifier for the detection.
 * @param reviewed The current review state.
 */
export function updateReviewState(
  courseId: string,
  detectionKey: string,
  reviewed: boolean
) {
  return axios.post(
    `/documents/${courseId}/detections/${detectionKey}/review`,
    { reviewed: reviewed }
  )
}

/** Dismiss the given detection. */
export const dismiss = createAsyncThunk<
  void,
  { id: string },
  { state: AppState }
>("detections/dismiss", async ({ id }, { getState }) => {
  const state = getState().remarks
  const key = selectKeyForDetection(state, id)
  if (!state.currentCourse) {
    return
  }
  await updateReviewState(state.currentCourse, key, true)
})

/** Restore the given detection. */
export const restore = createAsyncThunk<
  void,
  { id: string },
  { state: AppState }
>("detections/restore", async ({ id }, { getState }) => {
  const state = getState().remarks
  const key = selectKeyForDetection(state, id)
  if (!state.currentCourse) {
    return
  }
  await updateReviewState(state.currentCourse, key, false)
})

/**
 * Build reducer cases for the review actions.
 * @param builder The case builder.
 */
export const buildCasesForDetectionsReview = (
  builder: ActionReducerMapBuilder<RemarksState>
) => {
  builder
    .addCase(dismiss.pending, (state, action) => {
      addToReviewedDetections(state, action.meta.arg.id)
    })
    .addCase(dismiss.rejected, (state, action) => {
      removeFromReviewedDetections(state, action.meta.arg.id)
    })
    .addCase(restore.pending, (state, action) => {
      removeFromReviewedDetections(state, action.meta.arg.id)
    })
    .addCase(restore.rejected, (state, action) => {
      addToReviewedDetections(state, action.meta.arg.id)
    })
}

/**
 * Select the review key for the detection.
 *
 * @param state The current remarks state.
 * @param id The detection identifier.
 */
const selectKeyForDetection = (state: RemarksState, id: string) =>
  selectDetectionById(state, id)?.detectionKey ?? id

/**
 * Add detection to reviewed detections.
 * @param state The current remarks state.
 * @param id The identifier for the detection.
 */
const addToReviewedDetections = (state: Draft<RemarksState>, id: string) => {
  const key = selectKeyForDetection(state, id)
  state.reviewedDetections[key] = true
}

/**
 * Remove deection from the reviewed detections.
 * @param state The current remarks state.
 * @param id The identifier for the detection.
 */
const removeFromReviewedDetections = (
  state: Draft<RemarksState>,
  id: string
) => {
  const key = selectKeyForDetection(state, id)
  delete state.reviewedDetections[key]
}
