import { AlertTitle, PaperProps, Stack, Typography } from "@mui/material"
import StyledPaper from "./StyledPaper"
import { Announcement as AnnouncementIcon } from "@mui/icons-material"

type RedHatPrivacyNoticeProps = PaperProps

/**
 * Renders privacy notive for Redhat users.
 * @param props The component props.
 */
export default function RedHatPrivacyNotice(props: RedHatPrivacyNoticeProps) {
  return (
    <StyledPaper elevation={0} {...props} sx={{ minWidth: 400 }}>
      <Stack direction={"row"} gap={2}>
        <AnnouncementIcon />
        <AlertTitle>Avoid using personal information</AlertTitle>
      </Stack>

      <Typography variant="body2">
        Redhat users must not add personal information to any course material.
        An exception may be made when uploading video content given a complete{" "}
        <a href="https://drive.google.com/file/d/1tj4JmovmgRwXlGppm7P1DlYGNEb17N2h/view?usp=sharing">
          release form
        </a>{" "}
        signed by all of the video contributors.
      </Typography>
    </StyledPaper>
  )
}
