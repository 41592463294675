import { useSnackbar } from "notistack"
import { useEffect } from "react"
import { useStore } from "react-redux"
import useNotificationsSelector from "./useNotificationSelector"

/**
 * Dispatch any notifications that might occur from a state change.
 *
 * While the host component is mounted, `useNotifier` subscribes to Redux state
 * updates. It then determines if the state change should result in one or more
 * user notifications.
 */
const useNotifier = () => {
  const store = useStore()
  const { enqueueSnackbar } = useSnackbar()
  const selectNotifications = useNotificationsSelector()

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const notifications = selectNotifications(store.getState())
      notifications.forEach((notification) => {
        const messages = Array.isArray(notification.message)
          ? notification.message
          : [notification.message]
        messages.forEach((message) => enqueueSnackbar(message, notification))
      })
    })

    return () => unsubscribe()
  }, [store, enqueueSnackbar, selectNotifications])
}

export default useNotifier
