import { useEffect, useRef } from "react"

const SCROLL_INTERVAL = 100
const SCROLL_DISTANCE = 100

const SCROLL_UP = -1
const SCROLL_DOWN = 1
const NO_SCROLL = 0

const useOutlineDragScroll = () => {
  /**
   * mouseDownRef tracks the state of the mouse across renders. We need this in order to
   * detect "Drags", or the time in between a mousedown and mouseup event
   */
  const mouseDownRef = useRef(false)

  /**
   * scrollRef refers to a timed interval which will be repeatedly invoked and removed
   * as the user causes mouse events. We will use this interval to perform an auto-scroll
   */
  const scrollRef = useRef(0)

  const outlineRef = useRef()

  /**
   * The stopScrolling function clears the active timed interval for the scrollRef
   */
  const stopScrolling = () => clearInterval(scrollRef.current)
  useEffect(() => {
    /**
     * Mouse up and Mouse down handlers. We store the current state of the mouse as a ref
     * We detect a "drag" as the period of time between these two events being handled
     */
    const mouseDown = () => (mouseDownRef.current = true)
    const mouseUp = () => {
      // As soon as the mouse is up, any active drag is disengaged, therefore we stop the continuous scroll function
      stopScrolling()

      // Also update the persisted state of the mouse
      mouseDownRef.current = false
    }

    window.document.addEventListener("mousedown", mouseDown)
    window.document.addEventListener("mouseup", mouseUp)

    return () => {
      window.document.removeEventListener("mousedown", mouseDown)
      window.document.removeEventListener("mouseup", mouseUp)
    }
  }, [])

  /**
   * This hook ensures that the continuous scroll function gets removed upon unmount
   */
  useEffect(() => {
    return () => stopScrolling()
  })

  /**
   * This handler gets fired when the mouse moves outside of the CourseOutline element
   *
   * @param e - Mouse event which we will extract the position of, determining a top or bottom scroll
   */
  const mouseExited = (e) => {
    // When the mouse moves outside the course outline container, this may indicate the user wishes to scroll
    // If the mouse is in its held-down position, we will fire the continuous scroll
    if (mouseDownRef.current === true) {
      outlineRef.current = window.document.getElementById("course-outline")
      const scrollDirection = getScrollDirection(e, outlineRef.current)

      scrollRef.current = setInterval(
        () => scroll(scrollDirection, outlineRef.current),
        SCROLL_INTERVAL
      )
    } else {
      stopScrolling()
    }
  }

  /**
   * If the mouse re-enters the selectable area of the course outline, it is apparent
   * that we no longer want to be continuously scrolling, so we remove the scroll function
   */
  const mouseEntered = () => stopScrolling()

  /**
   * Determines the direction we wish to scroll based on the coordinates of the cursor's
   * current position relative to the Course Outline container
   *
   * @param e - Mouse event fired from onMouseLeave
   * @param container - The DOM element relative to the mouse we are obtaining the size from
   * @returns {number} -
   */
  const getScrollDirection = (e, container) => {
    const { pageX, pageY } = e
    const { top, bottom, right, left } = container.getBoundingClientRect()
    if (pageY < top && pageX > left && pageX < right) {
      // case 1: cursor is above the container but within the horizontal bounds
      return SCROLL_UP
    } else if (pageY > bottom && pageX > left && pageX < right) {
      // case 2: cursor is below the container but within the horizontal bounds
      return SCROLL_DOWN
    } else {
      // case 3: the cursor is elsewhere and we do not trigger a scroll
      return NO_SCROLL
    }
  }

  /**
   * Scrolls the viewport of the container either up or down
   *
   * @param direction - Integer representation of direction, -1 for up, 1 for down
   * @param container - DOM element we wish to scroll through
   */
  const scroll = (direction, container) => {
    container?.scrollBy({
      top: direction * SCROLL_DISTANCE,
      behavior: "smooth",
    })
  }

  return {
    mouseExited,
    mouseEntered,
  }
}

export default useOutlineDragScroll
