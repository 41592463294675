import { last } from "lodash"

/**
 * Get the current cursor position.
 *
 * @return {?Node}
 */
const getCursorPosition = (container) => {
  const selection = window.getSelection()
  const focusNode = selection?.focusNode

  if (!focusNode) {
    return null
  }

  if (container && container === focusNode) {
    const children = [...focusNode.childNodes]
    return selection.focusOffset < children.length
      ? children[selection.focusOffset]
      : last(children)
  }

  return focusNode
}

export default getCursorPosition
