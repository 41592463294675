import axiosInstance from "./axios"

/**
 * @typedef {{
 *  id: string
 *  courseId: string
 * }} DeleteCommentArg
 */

/**
 * Delete a course comment.
 *
 * @param {DeleteCommentArg} arg
 */
const deleteComment = async (arg) => {
  const { id, courseId, as } = arg
  await axiosInstance.delete(`/courses/${courseId}/comments/${id}`, {
    params: { email: as?.email },
  })
}

export default deleteComment
