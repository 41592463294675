import { CommentEmail, StyledTextField } from "./Comment"
import {
  CardContent,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
} from "@mui/material"
import { AddComment, Close, Send } from "@mui/icons-material"
import { useState } from "react"
import usePendingComment from "../../../hooks/usePendingComment"

const PendingComment = ({ color = "addition" }) => {
  const [commentText, setCommentText] = useState("")
  const { discardPendingComment, addComment, email, commentInputRef } =
    usePendingComment()

  return (
    <CardContent data-cy="pending-comment">
      <Stack direction="row" alignItems="center" gap={1}>
        <AddComment fontSize="small" color={color} />
        <CommentEmail variant="subtitle1" color={color}>
          {email}
        </CommentEmail>
        <Tooltip title="Cancel comment">
          <IconButton
            sx={{ marginLeft: "auto" }}
            size="small"
            onClick={discardPendingComment}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Stack>
      <StyledTextField
        inputRef={commentInputRef}
        label="Add a comment"
        variant="standard"
        fullWidth
        color={color}
        onClick={(e) => e.stopPropagation()}
        onInput={(e) => {
          e.preventDefault()
          setCommentText(e.target.value)
        }}
        value={commentText}
        multiline
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-cy="submit-comment"
                onClick={() => addComment(commentText)}
                size="small"
              >
                <Send fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </CardContent>
  )
}

export default PendingComment
