import { useEffect, useRef } from "react"
import { useStore } from "react-redux"
import { selectCompleteUploads } from "../store/jobs/selectUploadJobs"
import { differenceBy } from "lodash"

const useUploadCompletions = (callback) => {
  const prevUploads = useRef(null)
  const callbackRef = useRef()
  const store = useStore()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const { getState } = store

    // Every time redux updates...
    const unsubscribe = store.subscribe(() => {
      if (!callbackRef.current) {
        return
      }

      // we will check for changes to the set of completed uploads
      const uploads = selectCompleteUploads(getState())
      if (!prevUploads.current) {
        prevUploads.current = uploads
      }

      if (uploads === prevUploads.current) {
        return
      }

      const newUploads = differenceBy(
        uploads,
        prevUploads.current,
        (upload) => upload.async_id
      )
      prevUploads.current = uploads

      callbackRef.current(newUploads)
    })

    return unsubscribe
  }, [store])
}

export default useUploadCompletions
