import { useDraggable } from "@dnd-kit/core"
import { TableRow } from "@mui/material"
import { WrapperProps } from "../types"
import { StyledCard } from "./gridLayout/CourseTile"

/**
 * Draggable wrapper for items of type course
 * @param props - Component props
 */
const CourseWrapper = (props: WrapperProps) => {
  const { item, children, variant, ...otherProps } = props

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: item.id,
      data: item,
    })

  // Transform style to display dragging
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined

  const Component = variant === "tile" ? StyledCard : TableRow

  const wrapperProps = {
    ...(variant !== "tile" ? { hover: true } : {}),
  }

  return (
    <Component
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      {...otherProps}
      {...wrapperProps}
      style={style}
      sx={(theme: any) => ({
        cursor: "pointer",
        zIndex: isDragging ? theme.zIndex.modal : 0,
        opacity: isDragging ? 0.5 : 1,
        transition: "opacity 0.5s ease-in-out",
        animation: isDragging ? "pulse 1s infinite alternate" : "none",
        "@keyframes pulse": {
          "0%": { opacity: 0.5 },
          "100%": { opacity: 1 },
        },
      })}
    >
      {children}
    </Component>
  )
}

export default CourseWrapper
