import waitForJob from "./waitForJob"
import { microlearningAsyncStatusEnum } from "../utils/constants"
import saveMicroLearningCourses from "../api/saveMicroLearningCourses"

/**
 * Submit async job to save micro-learning sections as new courses
 * @param data - Object containing config for micro-learning
 */
const submitSaveMicroLearningCourses = async (data: any) => {
  const jobId = await saveMicroLearningCourses(data).then(
    ({ data }) => data.async_id
  )

  const microLearningResponse: any = await waitForJob(jobId, {
    completeStatus: microlearningAsyncStatusEnum.COMPLETE,
    failedStatus: microlearningAsyncStatusEnum.FAILED_NO_RETRY,
  })

  return microLearningResponse
}

export default submitSaveMicroLearningCourses
