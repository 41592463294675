import { directionEnum } from "../../welcome/CourseLearningPathMenu"

/**
 * Determine the index location of the list element we wish to move our course
 * in front of.
 *
 * @param courses
 * @param courseId
 * @param direction
 * @returns {null|*}
 */
const getTargetIndex = (courses, courseId, direction) => {
  const courseIds = courses.map((course) => course.id)
  const courseIndex = courseIds.indexOf(courseId)

  if (
    (direction === directionEnum.RIGHT &&
      courseIndex >= courseIds.length - 1) ||
    (direction === directionEnum.LEFT && courseIndex === 0)
  ) {
    // Attempting to shift when the element is on far side of array already
    return null
  }

  /**
   * The amount by which we shift to find the target is defined as follows:
   * The shift operation inserts in front of the provided target, therefore to
   * find the target when moving right we look 2 places ahead in the array.
   * Shifting left is simpler, and we just look at the prior element in the array.
   */

  const indexShift = direction === directionEnum.RIGHT ? 2 : -1
  return courseIndex + indexShift
}

export default getTargetIndex
