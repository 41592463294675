import React, { useState, useEffect } from "react"
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import PropTypes from "prop-types"
import CourseCancelUploadControl from "../widgets/CourseCancelUploadControl"
import useCourses from "../../hooks/useCourses"
import ErrorMessage from "../widgets/ErrorMessage"
import UploadErrorCaption from "../../features/createCourse/UploadErrorCaption"

const TAKING_TOO_LONG_IN_SECONDS = 30

/**
 * Make a loading progress caption
 * @param stage - the stage
 */
export const formatStageCaption = (stage) => {
  if (stage == null) {
    return ""
  } else if (
    stage.current == null ||
    stage.total == null ||
    stage.total === 1
  ) {
    return stage.caption
  } else if (stage.currentFilename != null) {
    return `Stage ${stage.current} of ${stage.total}: Converting "${stage.currentFilename}" to Course`
  } else {
    return `Stage ${stage.current} of ${stage.total}: ${stage.caption}`
  }
}

/**
 * Displays the progress of the current course document upload.
 * @param props - Component props
 */
const CreationProgressScreen = (props) => {
  const { stage, onExit, courseId } = props

  const [complete, setComplete] = useState(false)
  const [takingTooLong, setTakingTooLong] = useState(false)
  const { cancelCourseCreation } = useCourses()

  useEffect(() => {
    let timeout = setTimeout(() => {
      timeout = null
      setTakingTooLong(true)
    }, TAKING_TOO_LONG_IN_SECONDS * 1000)
    return () => {
      if (timeout != null) {
        clearTimeout(timeout)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCancelUpload = async () => {
    await cancelCourseCreation(courseId)
    onExit()
  }

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "100vh",
          gap: 2,
        }}
        maxWidth="sm"
      >
        <Box textAlign="right">
          <Button
            color="primary"
            startIcon={<CloseIcon />}
            onClick={(e) => {
              e.preventDefault()
              setComplete(true)
              onExit()
            }}
          >
            Close
          </Button>
        </Box>
        {(stage == null || !stage.error) && (
          <>
            <Typography variant="h5" component="h1">
              Creating Your Course
            </Typography>
            <LinearProgress color="primary" />
            <Typography variant="caption" align="right" display="block">
              {formatStageCaption(stage)}
            </Typography>

            <Typography variant="body1" align="justify">
              LEAi™ is now creating your course.
            </Typography>

            {takingTooLong && (
              <Alert severity="info">
                <AlertTitle>This may take several minutes</AlertTitle>
                It is safe to return to the home screen, the course will be
                processed in the background, and you will be notified when your
                new course is ready.
              </Alert>
            )}
            {!takingTooLong && (
              <Typography variant="body1" align="justify">
                It is safe to return to the home screen now and continue
                working. The course will be processed in the background, and you
                will be notified when your new course is ready.
              </Typography>
            )}
          </>
        )}
        {stage != null && stage.error && (
          <ErrorMessage
            title="Oh no!"
            subtitle="A problem occurred while creating your new course."
          >
            <UploadErrorCaption stage={stage} />
          </ErrorMessage>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          {(stage == null || !stage.error) && (
            <CourseCancelUploadControl
              onCancel={onCancelUpload}
              complete={complete}
              courseId={courseId}
            />
          )}
        </Box>
      </Container>
    </>
  )
}

CreationProgressScreen.propTypes = {
  applyTemplates: PropTypes.bool,
  courseId: PropTypes.string,
  /**
   * Describes the current stage of the transformation.
   *
   * @param stage.current The current stage number.
   * @param stage.total The total number of stages in the process.
   * @param stage.caption A short caption describing the current stage.
   */
  stage: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
    caption: PropTypes.string.isRequired,
  }),
  /**
   * Called when the user clicks the exit button.
   */
  onExit: PropTypes.func,
}

export default CreationProgressScreen
