import { useMemo, useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Tooltip,
} from "@mui/material"
import { Add, Lock, LockOpen, DeleteOutline } from "@mui/icons-material"
import { TranslationRule, TranslationRuleData } from "./TranslationRuleData"
import produce from "immer"
import ConfirmDialog from "../../components/widgets/ConfirmDialog"

export interface TranslationRulesTableProps {
  data: TranslationRuleData
  onDataChange: (newData: TranslationRuleData) => void
  selectedLocale: string
}

/**
 * Table for displaying and editing translation rules.
 * @param props - The props for the TranslationRulesTable.
 * @returns A table for displaying and editing translation rules.
 */
export function TranslationRulesTable(props: TranslationRulesTableProps) {
  const { data, onDataChange, selectedLocale } = props

  const [confirmLock, setConfirmLock] = useState<{
    index: number
    rule: TranslationRule
  } | null>(null)

  /**
   * Handle the change of the original text for a translation rule.
   * @param ruleIndex - The index of the rule in the rules array.
   * @param value - The new original text.
   */
  const handleOriginalChange = (ruleIndex: number, value: string) => {
    onDataChange(
      produce(data, (draft) => {
        draft.rules[ruleIndex].original = value
        if (draft.rules[ruleIndex].locale === null) {
          draft.rules[ruleIndex].translated = value
        }
      })
    )
  }

  /**
   * Handle the change of the translated text for a translation rule.
   * @param ruleIndex - The index of the rule in the rules array.
   * @param value - The new translated text.
   */
  const handleTranslatedChange = (ruleIndex: number, value: string) => {
    onDataChange(
      produce(data, (draft) => {
        draft.rules[ruleIndex].translated = value
      })
    )
  }

  /**
   * Handle the addition of a new translation rule.
   */
  const handleAddRule = () => {
    const newRule: TranslationRule = {
      locale: selectedLocale,
      original: "",
      translated: "",
    }
    onDataChange({ rules: [...data.rules, newRule] })
  }

  /**
   * Handle the deletion of a translation rule.
   * @param index - The index of the rule in the rules array.
   */
  const handleDeleteRule = (index: number) => {
    const newRules = data.rules.filter((_, i) => i !== index)
    onDataChange({ rules: newRules })
  }

  /**
   * Handle the toggle of the lock for a translation rule.
   * @param index - The index of the rule in the rules array.
   */
  const handleToggleLock = (index: number) => {
    const rule = data.rules[index]
    if (
      rule.locale !== null &&
      rule.original !== rule.translated &&
      rule.original !== "" &&
      rule.translated !== ""
    ) {
      setConfirmLock({ index, rule })
    } else {
      applyLockToggle(index)
    }
  }

  /**
   * Apply the lock toggle for a translation rule.
   * @param index - The index of the rule in the rules array.
   */
  const applyLockToggle = (index: number) => {
    onDataChange(
      produce(data, (draft) => {
        draft.rules[index].translated = draft.rules[index].original
        draft.rules[index].locale =
          draft.rules[index].locale === null ? selectedLocale : null
      })
    )
  }

  /**
   * Get the filtered rules based on the selected locale.
   * @returns The filtered rules.
   */
  const filteredRulesAndIndexes = useMemo(() => {
    return data.rules
      .map((rule, index) => ({ rule, index }))
      .filter(
        ({ rule }) => rule.locale === selectedLocale || rule.locale === null
      )
  }, [data.rules, selectedLocale])

  return (
    <>
      {filteredRulesAndIndexes.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 7, mb: 3 }}>
          There are no translations for the selected language.
        </Typography>
      ) : (
        <Table sx={{ tableLayout: "fixed" }}>
          <TableBody>
            {filteredRulesAndIndexes.map(({ rule, index }) => (
              <TableRow
                key={index}
                sx={{
                  "& > * ": { border: "none" },
                }}
              >
                <TableCell>
                  <TextField
                    variant="filled"
                    value={rule.original}
                    onChange={(e) =>
                      handleOriginalChange(index, e.target.value)
                    }
                    fullWidth
                    label="Original"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="filled"
                    value={rule.translated}
                    onChange={(e) =>
                      handleTranslatedChange(index, e.target.value)
                    }
                    fullWidth
                    disabled={rule.locale === null}
                    label="Translation"
                  />
                </TableCell>
                <TableCell padding="checkbox">
                  <Tooltip
                    title={
                      rule.locale === null
                        ? "Unlock Translation"
                        : "Lock Translation for All Languages"
                    }
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleToggleLock(index)}
                      aria-label={
                        rule.locale === null
                          ? "Unlock Translation"
                          : "Lock Translation for All Languages"
                      }
                      color={rule.locale === null ? "primary" : "default"}
                    >
                      {rule.locale === null ? <Lock /> : <LockOpen />}
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell padding="checkbox">
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteRule(index)}
                    aria-label="Delete translation"
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Box display="flex" justifyContent="center">
        <Button
          onClick={handleAddRule}
          variant="contained"
          startIcon={<Add />}
          sx={{ mt: 2 }}
        >
          Add Translation
        </Button>
      </Box>
      {confirmLock && (
        <ConfirmDialog
          title="Confirm Lock Translation"
          message={
            <Typography>
              Locking this translation will overwrite the existing translation
              with the original text. Are you sure you want to proceed?
            </Typography>
          }
          confirmText="Lock"
          cancelText="Cancel"
          onCancel={() => setConfirmLock(null)}
          onConfirm={() => {
            if (confirmLock) {
              applyLockToggle(confirmLock.index)
              setConfirmLock(null)
            }
          }}
        />
      )}
    </>
  )
}
