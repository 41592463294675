import * as api from "../../api"
import { createAsyncThunk } from "@reduxjs/toolkit"
import selectLatestJobUpdate from "./selectLatestJobUpdate"

/**
 * Create action thunk to fetch job updates.
 *
 * @see https://redux-toolkit.js.org/api/createAsyncThunk
 */
const fetchJobUpdates = createAsyncThunk(
  "jobs/fetchUpdates",
  async (options, { getState }) => {
    const since = selectLatestJobUpdate(getState())
    return api.fetchAsyncJobs({ since, ...options })
  },
  {
    condition: (options, { getState }) => {
      const { loading } = getState().jobs

      // Skip the request if there is already one in progress
      if (loading === "pending") {
        return false
      }
    },
  }
)

export const { fulfilled, pending, rejected } = fetchJobUpdates
export default fetchJobUpdates
