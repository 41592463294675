import { isEqual, throttle } from "lodash"
import { useEffect, useState } from "react"
import getOutline from "../utils/getOutline"
import observeHeadings from "../utils/observeHeadings"

/**
 * @typedef {import("../utils/getOutline").OutlineItem} OutlineItem
 */

/** The throttle time between updating the outline. */
const THROTTLE_TIME = 100 // milliseconds

/**
 * The initial outline state.
 * @type {OutlineItem[]}
 */
const INITIAL_STATE = []

/**
 * Get the outline state for a given document.
 *
 * The outline state is maintained across any changes to the document.
 *
 * @type {(document: ?Element) => OutlineItem[]}
 */
const useOutlineItems = (document) => {
  const [outline, setOutline] = useState(INITIAL_STATE)

  useEffect(() => {
    if (!document) {
      return
    }

    const updateOutline = throttle(() => {
      const outline = getOutline(document)
      setOutline((prevOutline) =>
        isEqual(prevOutline, outline) ? prevOutline : outline
      )
    }, THROTTLE_TIME)

    updateOutline()
    return observeHeadings(document, updateOutline)
  }, [document])

  return outline
}

export default useOutlineItems
