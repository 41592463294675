import observeMutations from "./observeMutations"
import { isHeadingElement } from "./findSectionStart"

/**
 * Check if node, or any of its ancestors, is a heading
 * @param {Node} node
 * @param {HTMLElement} container
 */
const closestHeading = (node, container) => {
  while (node) {
    if (isHeadingElement(node, container)) {
      return node
    }
    node = node.parentElement
  }

  return null
}

/**
 * Check if a given mutation is a heading element mutation.
 *
 * @param {MutationRecord} mutation
 */
const isHeadingMutation = (mutation, container) =>
  [mutation.target, ...mutation.addedNodes, ...mutation.removedNodes].some(
    (node) => closestHeading(node, container)
  )

/**
 * Call the callback each time a heading is mutated in the container.
 *
 * @param {Element} container
 * @param {() => {}} callback
 * @returns
 */
const observeHeadings = (container, callback) => {
  return observeMutations(
    container,
    (mutations) => {
      if (
        !mutations.some((mutation) => isHeadingMutation(mutation, container))
      ) {
        return
      }

      callback()
    },
    {
      characterData: true,
      subtree: true,
      childList: true,
    }
  )
}

export default observeHeadings
