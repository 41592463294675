/**
 * Array of list option objects for ordered and unordered lists,
 * along with a unicode character to act as the pseudo element icon
 * prefixed to the option
 */
const listOptions = [
  {
    title: "Disc",
    command: "formatUL",
    content: '"\\2022"',
  },
  {
    title: "Circle",
    command: "formatUL",
    content: '"\\25E6"',
  },
  {
    title: "Square",
    command: "formatUL",
    content: '"\\25AA"',
  },
  {
    title: "Default",
    command: "formatUL",
    content: '"\\2022"',
  },
  {
    title: "Default",
    command: "formatOL",
    content: '"1."',
  },
  {
    title: "Lower Alpha",
    command: "formatOL",
    content: '"a."',
  },
  {
    title: "Lower Greek",
    command: "formatOL",
    content: '"α."',
  },
  {
    title: "Lower Roman",
    command: "formatOL",
    content: '"i."',
  },
  {
    title: "Upper Alpha",
    command: "formatOL",
    content: '"A."',
  },
  {
    title: "Upper Roman",
    command: "formatOL",
    content: '"I."',
  },
]

/**
 * Apply CSS styles to each list option, based on the preset listOptions
 * array.
 *
 * @param theme - MUI theme
 */
const withListOptionStyles = (theme: any) =>
  listOptions.reduce((styles, { title, command, content }) => {
    const selector = `.fr-command[data-cmd='${command}'][title='${title}']`

    return {
      ...styles,
      [selector]: {
        fontSize: "0.625rem",
        // Need to force override the padding due to baked-in froala styles
        padding: `${theme.spacing(2)} !important`,
        "&:before": {
          width: theme.spacing(4),
          fontSize: command === "formatUL" ? "3rem" : "1rem",
          marginRight: theme.spacing(1),
          content,
        },
      },
    }
  }, {})

export default withListOptionStyles
