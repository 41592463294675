import { Box, Button, Typography } from "@mui/material"
import ErrorMessage from "../../components/widgets/ErrorMessage"
import CloseIcon from "@mui/icons-material/Close"
import React from "react"

type ExportErrorMessageProps = {
  onExit: Function
}

/**
 * Component for displaying Export Error Message
 * @param props - Component props
 */
const ExportErrorMessage = (props: ExportErrorMessageProps) => {
  const { onExit } = props

  return (
    <>
      <Box textAlign="center" sx={{ mt: 4 }}>
        <Typography variant="h5" component="h1">
          Export Course
        </Typography>
      </Box>
      <ErrorMessage
        title="Oh no!"
        subtitle="A problem occurred while exporting your course."
      >
        <Typography variant="body2">
          Your course could not be exported. Please try again, contact
          LearnExperts if the problem persists
        </Typography>
      </ErrorMessage>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloseIcon />}
          onClick={(e) => {
            e.preventDefault()
            onExit()
          }}
        >
          Close
        </Button>
      </Box>
    </>
  )
}

export default ExportErrorMessage
