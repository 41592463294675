import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import ReplayIcon from "@mui/icons-material/Replay"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import {
  getSectionElements,
  stripTemporaryElements,
} from "../../utilities/smartTemplates"
import * as api from "../../api"
import { SmartTemplatePanelContainer } from "./smartTemplateControlCustomElement"
import ConfirmDialog from "../../components/widgets/ConfirmDialog"
import _ from "lodash"
import { useStableCallback } from "../../hooks/useStableCallback"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { useFlag } from "../../utilities/feature-management"
import { PreviewHtmlContent } from "./PreviewHtmlContent"
import {
  LegacyRewriteLength,
  RewriteGradeLevel,
  RewriteLength,
  RewriteOptions,
  RewriteStructure,
  RewriteTone,
} from "../../features/transform/RewriteOptions"
import { usePersistedState } from "../../hooks/usePersistedState"
import RewriteCustomInstructions from "../../features/transform/RewriteCustomInstructions"

/**
 * Panel for smart template control for rewriting content.
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.element Root element of component in light DOM.
 * @param props.onClose Callback to close the panel.
 * @param props.withStyles Function to wrap children in styles.
 */
export function RewriteSmartTemplatePanel(props: {
  editor: any
  element: HTMLElement
  onClose: () => void
  withStyles: (children: React.ReactElement) => React.ReactElement
}) {
  const { editor, element, onClose, withStyles } = props

  // Rewritten content
  const [rewrittenHtml, setRewrittenHtml] = useState<string>()

  // True if confirming replace
  const [confirmReplace, setConfirmReplace] = useState(false)

  // True if showing more options
  const [moreOptions, setMoreOptions] = useState(false)

  const [rewriteOptions, setRewriteOptions] = usePersistedState<RewriteOptions>(
    "rewrite_content_options_2024_07_19_section",
    {
      tone: "professional",
      structure: "paragraphs_and_bullets",
      length: "same",
      gradeLevel: "8",
      customInstructions: "",
    }
  )

  // Rewrite options of currently displayed content. Used
  // to determine if currently generating content
  const [displayedRewriteOptions, setDisplayedRewriteOptions] =
    useState<RewriteOptions>()

  // True if first call to generate content and should be immediate
  const firstGenerateContent = useRef(true)

  // True if configurable rewrite is enabled
  const enableConfigurableRewrite = useFlag("rollout-configurable-rewrite")

  // Latest rewrite options
  const latestRewriteOptions = useRef(rewriteOptions)
  latestRewriteOptions.current = rewriteOptions

  // Server message
  const [message, setMessage] = useState("")

  /** Generate rewritten content by calling server */
  const generateContent = useStableCallback(async function () {
    try {
      // Get template heading
      const smartTemplateId = element.dataset.smartTemplateId!

      let courseHtml = stripTemporaryElements(editor.html.get())

      const data = await api.createTemplate(
        "rewrite",
        courseHtml,
        null,
        smartTemplateId,
        {
          tone: rewriteOptions.tone,
          length: rewriteOptions.length,
          structure: rewriteOptions.structure,
          grade_level: rewriteOptions.gradeLevel,
          custom_instructions: rewriteOptions.customInstructions,
        }
      )

      // Check if rewrite options have changed since request was made
      if (latestRewriteOptions.current !== rewriteOptions) {
        return
      }

      // Extract message from server response
      setMessage(data.data.message ?? "")

      // Get HTML
      const html = data.data.smart_template_html
      setRewrittenHtml(html)

      setDisplayedRewriteOptions(rewriteOptions)
    } catch (err) {
      // If latest rewrite options have changed, don't show error
      if (latestRewriteOptions.current !== rewriteOptions) {
        return
      }
      setMessage(`Error rewriting content. Please try again.`)
      setDisplayedRewriteOptions(rewriteOptions)
    }
  })

  // Create debounced generate content function
  const debouncedGenerateContent = useMemo(() => {
    return _.debounce(generateContent, 750)
  }, [generateContent])

  // Generate rewritten content on load or if rewrite options change
  useEffect(() => {
    // Run immediately if not already generating content
    if (firstGenerateContent.current) {
      generateContent()
      firstGenerateContent.current = false
    } else {
      debouncedGenerateContent()
    }
  }, [rewriteOptions, debouncedGenerateContent, generateContent])

  /** Replace content with rewritten content */
  function replaceContent() {
    // Get template heading
    const smartTemplateId = element.dataset.smartTemplateId!
    const smartTemplateHeading = document.getElementById(smartTemplateId)!

    // Get existing smart template section elements
    const existingSectionElements = getSectionElements(smartTemplateHeading)

    // Get smart template headers within existing section elements that are at H3 level
    const existingSmartTemplateHeaders = existingSectionElements.filter(
      (element) =>
        element.getAttribute("data-template") != null &&
        element.nodeName === "H3"
    )

    // Get elements that make up the existing smart templates
    const existingSmartTemplateElements = _.flatten(
      existingSmartTemplateHeaders.map((element) => getSectionElements(element))
    )

    // Remove all section elements (after the heading and smart template control) that are not smart templates
    const elementsToRemove = existingSectionElements.filter(
      (element, index) => {
        // Don't remove the smart template control
        if (index < 1) {
          return false
        }
        // Don't remove smart template elements or their headers
        if (
          existingSmartTemplateElements.includes(element) ||
          existingSmartTemplateHeaders.includes(element)
        ) {
          return false
        }
        return true
      }
    )

    // Remove elements
    elementsToRemove.forEach((element) => element.remove())

    // Add new section elements after the smart template control
    existingSectionElements[0].insertAdjacentHTML("afterend", rewrittenHtml!)

    // Save undo step in editor
    editor.undo.saveStep()

    // Close panel
    onClose()
  }

  /** Append rewritten content to end of content */
  function appendContent() {
    // Get template heading
    const smartTemplateId = element.dataset.smartTemplateId!
    const smartTemplateHeading = document.getElementById(smartTemplateId)!

    // Get existing smart template section elements
    const existingSectionElements = getSectionElements(smartTemplateHeading)

    // Get smart template headers within existing section elements that are at H3 level
    const existingSmartTemplateHeaders = existingSectionElements.filter(
      (element) =>
        element.getAttribute("data-template") != null &&
        element.nodeName === "H3"
    )

    // Add new section elements to existing, placing them before the first smart template element,
    // otherwise at the end
    if (existingSmartTemplateHeaders.length > 0) {
      // Add to start of first section
      const firstSectionElement = existingSmartTemplateHeaders[0]
      firstSectionElement.insertAdjacentHTML("beforebegin", rewrittenHtml!)
    } else {
      // Add to end of last section
      const lastSectionElement =
        existingSectionElements[existingSectionElements.length - 1]
      lastSectionElement.insertAdjacentHTML("afterend", rewrittenHtml!)
    }

    // Save undo step in editor
    editor.undo.saveStep()

    // Close panel
    onClose()
  }

  const loading = displayedRewriteOptions !== rewriteOptions

  return (
    <>
      {confirmReplace && (
        <ConfirmDialog
          title="Confirm Replace"
          message="Are you sure you want to replace the contents of this section? (Smart templates in this section will be preserved)"
          confirmText="Replace"
          cancelText="Cancel"
          onConfirm={() => {
            setConfirmReplace(false)
            replaceContent()
          }}
          onCancel={() => setConfirmReplace(false)}
        />
      )}
      {withStyles(
        <SmartTemplatePanelContainer>
          <Stack gap={1}>
            <Box key="content" sx={{ p: 2 }}>
              <Box
                sx={{
                  p: 1,
                  overflowY: "auto",
                  height: "50vh",
                  // eslint-disable-next-line jsdoc/require-jsdoc
                  backgroundColor: (theme) => theme.palette.background.default,
                }}
              >
                {loading && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <CircularProgress />
                  </Box>
                )}
                {!loading && rewrittenHtml && (
                  <PreviewHtmlContent editor={editor} html={rewrittenHtml} />
                )}
              </Box>
            </Box>
            {message !== "" && !loading && (
              <Alert severity="warning">{message}</Alert>
            )}
            <Stack
              key="footer"
              sx={{
                /**
                 * Set background to be shade of MUI grey
                 * @param theme - MUI theme
                 */
                backgroundColor: (theme) => theme.palette.grey[200],
                p: 2,
                gap: 1,
              }}
            >
              <Stack
                direction="row"
                key="main_options"
                alignItems="center"
                justifyContent="space-between"
              >
                {enableConfigurableRewrite ? (
                  <Box
                    sx={{
                      p: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: 2,
                    }}
                  >
                    <FormControl size="small">
                      <InputLabel>Tone</InputLabel>
                      <RewriteTone
                        rewriteOptions={rewriteOptions}
                        onRewriteOptionsChange={setRewriteOptions}
                        menuContainer={element.shadowRoot!.firstElementChild!}
                      />
                    </FormControl>
                    <Button
                      onClick={() => setMoreOptions(!moreOptions)}
                      endIcon={moreOptions ? <ExpandLess /> : <ExpandMore />}
                    >
                      More Options
                    </Button>
                  </Box>
                ) : (
                  <div />
                )}
                {displayedRewriteOptions !== rewriteOptions ? (
                  <Button
                    variant="text"
                    onClick={() => {
                      onClose()
                    }}
                    startIcon={<CloseIcon fontSize="small" />}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Stack spacing={2} direction="row">
                    <Button
                      key="retry"
                      variant="text"
                      onClick={() => {
                        // Reset displayed rewrite options so that loading spinner is shown
                        setDisplayedRewriteOptions(undefined)
                        generateContent()
                      }}
                      startIcon={<ReplayIcon fontSize="small" />}
                    >
                      Regenerate
                    </Button>
                    <Button
                      key="confirm_append"
                      variant="text"
                      onClick={() => {
                        appendContent()
                      }}
                      startIcon={<AddIcon fontSize="small" />}
                    >
                      Append
                    </Button>
                    <Button
                      key="confirm_replace"
                      variant="text"
                      onClick={() => {
                        setConfirmReplace(true)
                      }}
                      startIcon={<SwapHorizIcon fontSize="small" />}
                    >
                      Replace
                    </Button>
                    <Button
                      key="discard"
                      variant="text"
                      onClick={() => {
                        onClose()
                      }}
                      startIcon={<DeleteIcon fontSize="small" />}
                    >
                      Discard
                    </Button>
                  </Stack>
                )}
              </Stack>
              {moreOptions && (
                <MoreRewriteOptions
                  rewriteOptions={rewriteOptions}
                  onRewriteOptions={setRewriteOptions}
                  menuContainer={element.shadowRoot!.firstElementChild!}
                />
              )}
            </Stack>
          </Stack>
        </SmartTemplatePanelContainer>
      )}
    </>
  )
}

/** Control to set more rewrite options
 * @param props See below.
 * @param props.rewriteOptions The rewrite options.
 * @param props.onRewriteOptions Callback to set the rewrite options.
 * @param props.menuContainer The menu container.
 */
function MoreRewriteOptions(props: {
  rewriteOptions: RewriteOptions
  onRewriteOptions: (options: RewriteOptions) => void
  menuContainer: Element
}) {
  const { rewriteOptions, onRewriteOptions, menuContainer } = props

  // True if new rewrite options are enabled
  const newRewriteOptionsEnabled =
    useFlag("rollout-improved-rewrite-options") ?? false

  // True if custom instructions are enabled
  const enableCustomInstructions =
    useFlag("rollout-custom-rewrite-prompt") ?? false

  return (
    <Box
      key="more_options"
      sx={{
        p: 1,
        display: "flex",
        justifyContent: "stretch",
        gap: 2,
      }}
    >
      <FormControl size="small">
        <InputLabel>Reading Level</InputLabel>
        <RewriteGradeLevel
          rewriteOptions={rewriteOptions}
          onRewriteOptionsChange={onRewriteOptions}
          menuContainer={menuContainer}
        />
      </FormControl>
      <FormControl size="small">
        <InputLabel>Structure</InputLabel>
        <RewriteStructure
          rewriteOptions={rewriteOptions}
          onRewriteOptionsChange={onRewriteOptions}
          sameEnabled={newRewriteOptionsEnabled}
          menuContainer={menuContainer}
        />
      </FormControl>
      <FormControl size="small">
        <InputLabel>Length</InputLabel>
        {newRewriteOptionsEnabled ? (
          <RewriteLength
            rewriteOptions={rewriteOptions}
            onRewriteOptionsChange={onRewriteOptions}
            menuContainer={menuContainer}
          />
        ) : (
          <LegacyRewriteLength
            rewriteOptions={rewriteOptions}
            onRewriteOptionsChange={onRewriteOptions}
            menuContainer={menuContainer}
          />
        )}
      </FormControl>
      {enableCustomInstructions && (
        <RewriteCustomInstructions
          rewriteOptions={rewriteOptions}
          onRewriteOptionsChange={onRewriteOptions}
          menuContainer={menuContainer}
        />
      )}
    </Box>
  )
}
