import { createEntityAdapter } from "@reduxjs/toolkit"

/** Exposes utility methods for managing async job state.  */
const jobsEntityAdaptor = createEntityAdapter({
  selectId: (model) => model.async_id,
})

export default jobsEntityAdaptor

const globalSelectors = jobsEntityAdaptor.getSelectors((state) => state.jobs)

/**
 * Select all stored async jobs.
 */
export const selectAllJobs = globalSelectors.selectAll

/**
 * Select a stored async job given its ID.
 */
export const selectJobById = globalSelectors.selectById
