/**
 * Prevent the default event for a wrapped event handler.
 *
 * @param {(event) => void} action The wrapped event handler.
 * @returns {(event) => void}
 */
const withPreventDefault = (action) => {
  return (event, ...args) => {
    event.preventDefault()
    return action(event, ...args)
  }
}

export default withPreventDefault
