import PropTypes from "prop-types"
import isPropValid from "@emotion/is-prop-valid"
import { styled } from "@mui/material/styles"

const DiffRoot = styled("div", {
  shouldForwardProp: isPropValid,
})(({ theme, selectors: { added = ".added", removed = ".removed" } = {} }) => ({
  overflow: "hidden",
  "& img": {
    display: "block",
    maxWidth: "100%",
  },
  table: {
    tableLayout: "fixed",
    maxWidth: "100%",
    width: "100%",
    ul: {
      padding: 0,
    },
  },
  "table, tr, td": {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    borderCollapse: "collapse",
  },
  td: {
    padding: theme.spacing(1),
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-words",
  },
  [`& ${added}`]: {
    color: theme.palette.addition.dark,
    backgroundColor: theme.palette.addition.light,
  },
  [`& ${removed}`]: {
    color: theme.palette.removal.main,
    textDecoration: "line-through",
  },
  [`& ${added}-img`]: {
    position: "relative",
    display: "inline-block",
    "&::after": {
      position: "absolute",
      content: '""',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: theme.palette.addition.main,
      opacity: 0.5,
    },
  },
  [`& ${removed}-img`]: {
    position: "relative",
    display: "inline-block",
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: theme.palette.removal.main,
      opacity: 0.5,
    },
  },
}))

/**
 * Highlights changes performed on a HTML document.
 *
 * Additions are highlighted in green and removal highlighted in red.
 */
const Diff = (props) => {
  const { changes, selectors, ...otherProps } = props

  return (
    <DiffRoot
      {...otherProps}
      selectors={selectors}
      dangerouslySetInnerHTML={{ __html: changes }}
    />
  )
}

Diff.propTypes = {
  /**
   * An HTML document marked up with changes.
   */
  changes: PropTypes.string.isRequired,
  /**
   * Overrides for CSS selectors identifying additions and removals.
   *
   * By default, Diff expects the changes to be marked by ".added" and
   * ".removed".
   */
  selectors: PropTypes.objectOf({
    added: PropTypes.string,
    removed: PropTypes.string,
  }),
}

export default Diff
