import { useAuthenticator } from "@aws-amplify/ui-react"
import Button, { ButtonProps } from "./Button"

/**
 * Render a button that navigates back to the sign-in page.
 * @param props The component props.
 */
export function BackToSignIn(props: ButtonProps) {
  const { children = "Back", ...otherProps } = props
  const { toSignIn } = useAuthenticator((context) => [context.toSignIn])

  return (
    <Button
      onClick={(e) => {
        e.preventDefault()
        toSignIn()
      }}
      {...otherProps}
    >
      {children}
    </Button>
  )
}

export default BackToSignIn
