/**
 * Create a dynamically configureable router for toggling feature flags.
 */
const createFeatureRouter = (featureConfig) => {
  return {
    setFeature: (featureName, isEnabled) => {
      featureConfig[featureName] = isEnabled
    },
    isEnabled: (featureName) => featureConfig[featureName],
  }
}

export default createFeatureRouter
