import { useCallback, useEffect, useState } from "react"
import * as api from "../../../api"
import { useAuth } from "../../../contexts/authorization"
import fetchLastUpdatedCourse from "../api/fetchLastUpdatedCourse"

/**
 *
 * Functionality for the Welcome Display UX on the Home screen
 */
const useWelcomeDisplay = () => {
  const [posts, setPosts] = useState([])
  const [welcomeVisible, setWelcomeVisible] = useState(true)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [displayHelpMessage, setDisplayHelpMessage] = useState(false)

  const [loadingLastEdited, setLoadingLastEdited] = useState(false)
  const [lastEdited, setLastEdited] = useState(null)

  const { user } = useAuth()

  /**
   * API call to the endpoint to retrieve today's posts
   * @type {function(): Promise<T>}
   */
  const fetchPosts = useCallback(
    () =>
      api.fetchPosts().then((data) => {
        return data
      }),
    []
  )

  /**
   * Determine whether to display the Staring Help messaged based on a composite of
   * the returningUser flag in localstorage and if any courses exist
   */
  useEffect(() => {
    if (!user || !user.id) {
      return
    }

    const returningUser =
      window.localStorage.getItem(`returningUser/${user.id}`) === "true"
    if (returningUser) {
      /**
       * If the returning user flag is true or the user has any current courses,
       * it can be determined that the help message should not appear.
       */
      window.localStorage.setItem(`returningUser/${user.id}`, "true")
      setDisplayHelpMessage(false)
    } else {
      setDisplayHelpMessage(true)
    }
  }, [user])

  /**
   * Send request to obtain most recently updated course. Sets last updated
   * to null if response is empty.
   */
  const refreshLastUpdatedCourse = useCallback(() => {
    setLoadingLastEdited(true)
    fetchLastUpdatedCourse().then((data) => {
      setLoadingLastEdited(false)
      if (data) {
        setLastEdited(data)
      } else {
        setLastEdited(null)
      }
    })
  }, [])

  /**
   * Retrieve the last edited course from server
   */
  useEffect(() => {
    refreshLastUpdatedCourse()
  }, [refreshLastUpdatedCourse])

  /**
   * Retrieve today's posts upon initially mounting
   */
  useEffect(() => {
    setLoading(true)
    fetchPosts()
      .then((data) => {
        setPosts(data)
        setLoading(false)
      })
      .catch(setError)
  }, [fetchPosts])

  return {
    posts,
    error,
    loading,
    welcomeVisible,
    setWelcomeVisible,
    displayHelpMessage,
    lastEdited,
    loadingLastEdited,
    refreshLastUpdatedCourse,
  }
}

export default useWelcomeDisplay
