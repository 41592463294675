import { configureStore as toolkitConfigureStore } from "@reduxjs/toolkit"
import remarks, { getRemarkSelectors } from "./remarks"
import editor from "./editor/editorSlice"
import jobsSlice from "./jobs/jobsSlice"
import { useDispatch } from "react-redux"
import * as api from "../api"
import auth from "./auth"

/** @typedef {typeof store.dispatch} AppDispatch  */

/** @typedef {ReturnType<typeof store.getState>} AppState */

/**
 * @template T
 *
 * @param {object} options
 * @param {any} options.preloadedState
 * @param {import("@reduxjs/toolkit").MiddlewareArray<T>} options.extraMiddleware
 */
export const configureStore = (options = {}) => {
  const { preloadedState, thunkExtra, extraMiddleware = [] } = options

  return toolkitConfigureStore({
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: { extraArgument: thunkExtra },
      }).concat(extraMiddleware)
    },
    reducer: {
      auth,
      jobs: jobsSlice.reducer,
      remarks,
      editor,
    },
  })
}

const store = configureStore({ thunkExtra: { api } })

export default store

/** @type {() => AppDispatch} */
export const useAppDispatch = useDispatch

export const {
  selectReviewStatus,
  selectCommentById,
  selectDetectionById,
  selectRemarksFilter,
  selectIsLoadingRemarks,
  selectRemarkIds,
  selectUnresolvedCount: selectUnresolvedRemarksCount,
} = getRemarkSelectors((state) => state.remarks)
