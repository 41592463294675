import {
  Add,
  Delete,
  Edit,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
} from "@mui/icons-material"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material"
import { SyntheticEvent, useCallback, useState } from "react"
import { CustomElementConfig } from "./CustomElements"
import produce from "immer"
import { ImageUpload } from "./ImageUpload"
import _ from "lodash"
import { AltTextEditor } from "./AltTextEditor"
import { InsertImageDialog } from "../features/insertImage/InsertImageDialog"
import { useFlag } from "../utilities/feature-management"

/** Horizontal alignment of the image */
export type ImageAlignment = "left" | "center" | "right"

/** Data for a labeled image */
export interface LabeledImageData {
  imageUrl: string

  /** Alt text for the image */
  imageAlt: string

  /** Labels that are displayed on the image */
  labels: LabelData[]

  /** Horizontal alignment of image. Default is center */
  alignment?: ImageAlignment

  /** Width of image in pixels. Default is natural width of the image */
  width?: number
}

/** Data for a single label */
export interface LabelData {
  /** x-position of label in pixels */
  x: number
  /** y-position of label in pixels */
  y: number
  /** Text of label */
  text: string
}

const placeholderImageUrl = "/img/No_Image_600x400.png"

/*
 * This is a custom element that renders a labeled image. Each label
 * has a text and a position. The HTML representation is stored as a
 * section element with a data-component="labeled-image" attribute.
 * Labels are stored within an ordered list. Each label is stored as
 * an li element with attributes "data-x" and "data-y" for the position
 * and a text node for the text.
 *
 * data-alignment is the alignment of the image. It can be "left", "center", or "right". Default is "center".
 *
 * data-width is the width of the image in pixels. Default is the natural width of the image.
 *
 * Sample HTML:
 * ```html
 * <section data-component="labeled-image" data-alignment="left">
 *   <img src="https://i.picsum.photos/id/434/800/600.jpg?hmac=eHyzyrvpUFXzFN4rWCQkdocV-P4mRGBnvSa9ithN13s" alt="alt text">
 *   <ol>
 *    <li data-x="100" data-y="200">Label 1</li>
 *    <li data-x="300" data-y="400">Label 2</li>
 *   </ol>
 * </section>
 * ```
 */
export const labeledImageCustomElement: CustomElementConfig<LabeledImageData> =
  {
    selector: "[data-component='labeled-image']",
    getDataFromElement: (el) => {
      let imageUrl = el.querySelector("img")?.getAttribute("src") || ""

      // Remove placeholder image if it exists
      if (imageUrl === placeholderImageUrl) {
        imageUrl = ""
      }

      const imageAlt = el.querySelector("img")?.getAttribute("alt") || ""
      const labels = Array.from(el.querySelectorAll("li") || []).map(
        (label) => {
          const x = parseInt(label.getAttribute("data-x") || "0")
          const y = parseInt(label.getAttribute("data-y") || "0")
          const text = label.textContent || ""
          return { x, y, text }
        }
      )
      const alignment = (el.getAttribute("data-alignment") ||
        "center") as ImageAlignment
      const width = parseInt(el.getAttribute("data-width") || "0") || undefined
      return { imageUrl, labels, alignment, width, imageAlt }
    },
    updateElementFromData: (el, data) => {
      // Save contents
      el.innerHTML =
        `<img src="${_.escape(data.imageUrl)}" alt="${_.escape(
          data.imageAlt ?? ""
        )}">` +
        `<ol data-cy="labeled-image-content">` +
        data.labels
          .map(
            (label) =>
              `<li data-x="${label.x}" data-y="${label.y}">` +
              _.escape(label.text) +
              `</li>`
          )
          .join("") +
        `</ol>`
      el.setAttribute("data-alignment", data.alignment || "center")
      if (data.width) {
        el.setAttribute("data-width", data.width.toString())
      } else {
        el.removeAttribute("data-width")
      }
    },
    renderView: ({ data, onDataChange, element, withStyles, readOnly }) => {
      return (
        <LabeledImageCustomComponent
          data={data}
          onDataChange={onDataChange}
          element={element}
          withStyles={withStyles}
          readOnly={readOnly}
        />
      )
    },
  }

/** Props for LabeledImageCustomComponent */
interface LabeledImageCustomComponentProps {
  /** Root element of component in light DOM */
  element: HTMLElement
  /** Data for the labeled image */
  data: LabeledImageData
  /** Callback to update the data */
  onDataChange?: (data: LabeledImageData) => void
  /** Function to wrap children in styles */
  withStyles: (children: React.ReactElement) => React.ReactElement
  readOnly: boolean
}

/** Renders a labeled image
 * @param props Props
 */
function LabeledImageCustomComponent(props: LabeledImageCustomComponentProps) {
  const { data, onDataChange, readOnly } = props
  const [editorOpen, setEditorOpen] = useState(false)

  return (
    <>
      <style>{`
.labeled-image {
  position: relative;
}
  
.labeled-image .buttons {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  z-index: 1;
}

.labeled-image:hover .buttons {
  visibility: visible;
}

.labeled-image-inner {
  display: inline-block;
}

      `}</style>
      {props.withStyles(
        <div
          className="labeled-image"
          style={{ textAlign: data.alignment || "center" }}
        >
          {!readOnly && (
            <div key="buttons" className="buttons">
              <IconButton
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.8) !important" }}
                onClick={() => setEditorOpen(true)}
                data-cy="open-interactive-elements-editor"
              >
                <Edit />
              </IconButton>
              <IconButton
                data-cy="delete-interactive-elements-editor"
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.8) !important" }}
                onClick={() => {
                  props.element.remove()
                }}
              >
                <Delete />
              </IconButton>
            </div>
          )}
          <div key="image" className="labeled-image-inner">
            <LabeledImageDisplay
              data={data}
              onDataChange={onDataChange}
              showPopups
              allowImageResizing
            />
          </div>
        </div>
      )}
      {editorOpen && (
        <LabeledImageDialog
          initialData={data}
          onCancel={() => setEditorOpen(false)}
          onSave={(data) => {
            onDataChange!(data)
            setEditorOpen(false)
          }}
        />
      )}
    </>
  )
}

/** Displays the image and labels.
 * If onDataChange is set and allowLabelDragging is true,
 * the user can drag the labels around.
 * If onDataChange is set and allowImageResizing is true,
 * the user can resize the image via a handle.
 *
 * @param props Props
 * @param props.data The data to render
 * @param props.onDataChange Callback to update the data
 * @param props.allowLabelDragging Whether to allow dragging of labels
 * @param props.allowImageResizing Whether to allow resizing of image
 * @param props.ignoreWidth Whether to ignore configured width
 * @param props.showPopups Whether to show popups when clicking on labels
 */
export function LabeledImageDisplay(props: {
  /** The data to render */
  data: LabeledImageData

  /** Callback to update the data */
  onDataChange?: (data: LabeledImageData) => void

  /** Whether to allow dragging of labels */
  allowLabelDragging?: boolean

  /** Whether to allow resizing of image */
  allowImageResizing?: boolean

  /** Whether to ignore configured width */
  ignoreWidth?: boolean

  /** Whether to show popups when clicking on labels */
  showPopups?: boolean
}) {
  const { data } = props

  // Scale of image
  const [scale, setScale] = useState(0)

  // True when image loaded
  const [imageLoaded, setImageLoaded] = useState(false)

  // Image current width (not natural width)
  const [imageWidth, setImageWidth] = useState(0)

  // Override width of image (if set) for dragging purposes. Prevents
  // making too many changes to the editor. 0 when not dragging.
  const [dragWidth, setDragWidth] = useState(0)

  // Currently popped label index
  const [poppedLabel, setPoppedLabel] = useState<number | null>(null)

  // Update scale when image resized
  const controlRef = useCallback((el: HTMLDivElement | null) => {
    if (el) {
      const observer = new ResizeObserver((entries) => {
        const img = entries[0].target as HTMLImageElement
        if (img.width === 0) return
        setScale(img.width / img.naturalWidth)
        setImageWidth(img.width)
      })
      observer.observe(el!.querySelector("img")!)
      return () => observer.disconnect()
    }
  }, [])

  /** Update scale when image loaded */
  const onLoad = useCallback((ev: SyntheticEvent<HTMLImageElement>) => {
    const img = ev.target as HTMLImageElement
    setImageLoaded(true)
    setScale(img.width / img.naturalWidth)
    setImageWidth(img.width)
  }, [])

  /**
   * Start dragging a label
   * @param ev - The MouseEvent object
   * @param label - The LabelData object
   * @param index - The index of the label
   */
  function startDragLabel(
    ev: React.MouseEvent<HTMLDivElement>,
    label: LabelData,
    index: number
  ) {
    ev.preventDefault()
    ev.stopPropagation()

    // Record start position
    const startX = ev.clientX
    const startY = ev.clientY
    const startLabel = { ...label }

    /**
     * Handles the mouse move event during label dragging
     * @param ev - The MouseEvent object
     */
    function onMouseMove(ev: MouseEvent) {
      const dx = ev.clientX - startX
      const dy = ev.clientY - startY
      const newLabel = {
        ...startLabel,
        x: startLabel.x + dx / scale,
        y: startLabel.y + dy / scale,
      }
      props.onDataChange!({
        ...data,
        labels: data.labels.map((l, i) => (i === index ? newLabel : l)),
      })
    }
    function onMouseUp() {
      window.removeEventListener("mousemove", onMouseMove)
      window.removeEventListener("mouseup", onMouseUp)
    }
    window.addEventListener("mousemove", onMouseMove)
    window.addEventListener("mouseup", onMouseUp)
  }

  /** Start dragging the label
   * @param ev - The MouseEvent object
   */
  function startDragResize(ev: React.MouseEvent<HTMLDivElement>) {
    ev.preventDefault()
    ev.stopPropagation()

    // Record start position
    const startX = ev.clientX

    /**
     * Listen for mouse move and mouse up
     * @param ev - The MouseEvent object
     */
    function onMouseMove(ev: MouseEvent) {
      // Set width for dragging purposes
      const dx = ev.clientX - startX
      const newWidth = Math.max(imageWidth + dx, 200)
      setDragWidth(newWidth)
    }

    /**
     * Handles the mouse up event
     * @param ev - The MouseEvent object
     */
    function onMouseUp(ev: MouseEvent) {
      // Finalize width change
      const dx = ev.clientX - startX
      const newWidth = Math.max(imageWidth + dx, 200)
      setDragWidth(0)
      props.onDataChange!({
        ...data,
        width: newWidth,
      })

      window.removeEventListener("mousemove", onMouseMove)
      window.removeEventListener("mouseup", onMouseUp)
    }
    window.addEventListener("mousemove", onMouseMove)
    window.addEventListener("mouseup", onMouseUp)
  }

  /** Render the labels */
  function renderLabels() {
    if (!imageLoaded) return null

    return data.labels.map((label, index) => (
      <div
        data-cy="labeled-image-label"
        key={index}
        className="labeled-image-control-label"
        style={{
          left: label.x * scale,
          top: label.y * scale,
          cursor:
            props.onDataChange && props.allowLabelDragging ? "move" : "pointer",
        }}
        onClick={() => setPoppedLabel(poppedLabel === index ? null : index)}
        onMouseDown={(ev) => {
          if (props.onDataChange && props.allowLabelDragging) {
            startDragLabel(ev, label, index)
          }
        }}
      >
        {index + 1}
      </div>
    ))
  }

  /** Render the popup if one is open */
  function renderPopup() {
    if (poppedLabel === null) return null

    const label = data.labels[poppedLabel]

    // Place tooltip in Shadow DOM to allow styling to work correctly
    return (
      <Tooltip
        arrow
        title={
          <Typography data-cy="labeled-image-tooltip" fontSize={16}>
            {label.text}
          </Typography>
        }
        PopperProps={{
          sx: {
            [`& .MuiTooltip-tooltip`]: {
              maxWidth: 500,
            },
          },
          disablePortal: true,
        }}
        open
      >
        <div
          style={{
            position: "absolute",
            // Make room for circle to show above the arrow
            top: label.y * scale + 5,
            left: label.x * scale,
          }}
        />
      </Tooltip>
    )
  }

  return (
    <>
      <style>{`

.labeled-image-control {
  position: relative;
  height: 100%;
  display: inline-block;
}

.labeled-image-control img {
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: auto !important;
}

.labeled-image-control-label {
  position: absolute;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  color: black;
  border-radius: 50%;
  border: 1px solid #666;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}

.labeled-image-resize {
  visibility: hidden;
  position: absolute;
  bottom: 2px;
  right: -4px;
  width: 8px;
  height: 8px;
  cursor: nwse-resize;
  background: rgba(3, 152, 246, 0.8);
}

.labeled-image:hover .labeled-image-resize {
  visibility: visible;
}

      `}</style>

      <div ref={controlRef} className="labeled-image-control">
        <img
          key="image"
          onLoad={onLoad}
          src={props.data.imageUrl || placeholderImageUrl}
          alt={props.data.imageAlt}
          onClick={() => setPoppedLabel(null)}
          style={{
            width: !props.ignoreWidth ? dragWidth || data.width : undefined,
          }}
        />
        {renderLabels()}
        {props.showPopups && renderPopup()}
        {props.onDataChange && props.allowImageResizing && imageWidth && (
          <div className="labeled-image-resize" onMouseDown={startDragResize} />
        )}
      </div>
    </>
  )
}

/** Create dialog to add a labeled image
 * @param onClose Called when the dialog is closed with html if created or null if cancelled
 */
export function createAddLabeledImageDialog(
  /** Called when the dialog is closed with html if created or null if cancelled */
  onClose: (html: string | null) => void
) {
  return (
    <LabeledImageDialog
      onSave={(data) => {
        // Create a new section element
        const section = document.createElement("section")
        section.dataset.component = "labeled-image"
        labeledImageCustomElement.updateElementFromData(section, data)

        onClose(section.outerHTML)
      }}
      onCancel={() => onClose(null)}
      initialData={{
        imageUrl: "",
        imageAlt: "",
        labels: [],
      }}
    />
  )
}

/** Create dialog to edit a labeled image
 * @param props Props
 * @param props.initialData The initial data to edit
 * @param props.onSave Called when the dialog is saved
 * @param props.onCancel Called when the dialog is cancelled
 */
function LabeledImageDialog(props: {
  /** The initial data to edit */
  initialData: LabeledImageData
  /** Called when the dialog is cancelled */
  onCancel: () => void
  /** Called when the dialog is saved */
  onSave: (data: LabeledImageData) => void
}) {
  const { initialData, onCancel, onSave } = props

  const [data, setData] = useState<LabeledImageData>(initialData)

  const enableNewInsertImageDialog = useFlag("rollout-new-insert-image-dialog")

  // If no image URL, show the InsertImageDialog instead
  if (!data.imageUrl && enableNewInsertImageDialog) {
    return (
      <InsertImageDialog
        onInsert={(imageUrl) => {
          if (imageUrl) {
            setData({ ...data, imageUrl })
          } else {
            onCancel()
          }
        }}
        onCancel={onCancel}
      />
    )
  }

  if (!data.imageUrl && !enableNewInsertImageDialog) {
    return (
      <Dialog open={true} maxWidth="md">
        <DialogTitle>Choose Image</DialogTitle>
        <DialogContent>
          <ImageUpload
            onChooseImage={(imageUrl, file) => {
              if (imageUrl) setData({ ...data, imageUrl })
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  /**
   * Handle align radio set
   * @param event - ignored
   * @param newAlignment - new setting
   */
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: ImageAlignment
  ) => {
    setData({ ...data, alignment: newAlignment })
  }

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Labels</DialogTitle>
      <DialogContent data-cy="labeled-image-editor">
        <LabeledImageDisplay
          data={data}
          onDataChange={(data) => setData(data)}
          allowLabelDragging
          ignoreWidth
        />

        <div key="caption">
          <Typography variant="caption">
            Drag labels around on image after adding them
          </Typography>
        </div>

        {data.labels.map((label, index) => (
          <div
            key={index}
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
              alignItems: "center",
            }}
          >
            <div style={{ width: 20, position: "relative" }}>
              <div className="labeled-image-control-label">{index + 1}</div>
            </div>
            <div style={{ paddingTop: 10 }}>
              <TextField
                data-cy="editor-label-textbox"
                sx={{ mb: 1 }}
                value={label.text}
                onChange={(e) => {
                  setData(
                    produce(data, (draft) => {
                      draft.labels[index].text = e.target.value
                    })
                  )
                }}
                fullWidth
              />
            </div>
            <IconButton
              data-cy="editor-delete-button"
              onClick={() => {
                setData(
                  produce(data, (draft) => {
                    draft.labels.splice(index, 1)
                  })
                )
              }}
            >
              <Delete />
            </IconButton>
          </div>
        ))}

        <Button
          sx={{ mt: 1 }}
          onClick={() => {
            setData(
              produce(data, (draft) => {
                // Add new label at top left but making
                // sure it doesn't overlap with other newly
                // added labels
                draft.labels.push({
                  text: "",
                  x: 50 + (data.labels.length % 10) * 30,
                  y: 50 + Math.floor(data.labels.length / 10) * 30,
                })
              })
            )
          }}
          startIcon={<Add />}
        >
          Add Label
        </Button>

        <Box data-cy="editor-alt-textbox" key="alt" sx={{ mt: 3 }}>
          <AltTextEditor
            imageUrl={data.imageUrl}
            altText={data.imageAlt}
            onChange={(imageAlt) => setData({ ...data, imageAlt })}
          />
        </Box>

        <Box key="alignment" sx={{ mt: 2 }}>
          <div>
            <b>Image Alignment</b>
          </div>
          <ToggleButtonGroup
            value={data.alignment || "center"}
            exclusive
            onChange={handleAlignment}
            size="small"
          >
            <ToggleButton
              data-cy="labeled-image-align-button"
              value="left"
              aria-label="left aligned"
            >
              <FormatAlignLeft /> Left
            </ToggleButton>
            <ToggleButton
              data-cy="labeled-image-align-button"
              value="center"
              aria-label="centered"
            >
              <FormatAlignCenter /> Center
            </ToggleButton>
            <ToggleButton
              data-cy="labeled-image-align-button"
              value="right"
              aria-label="right aligned"
            >
              <FormatAlignRight /> Right
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSave(data)}
          disabled={!data.imageUrl}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
