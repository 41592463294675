import {
  Add,
  Delete,
  Edit,
  ArrowBackIosNew,
  ArrowForwardIos,
  Replay,
  Check,
} from "@mui/icons-material"
import {
  lighten,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  PaginationItem,
  Paper,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import { useState, useMemo } from "react"
import { CustomElementConfig, RenderViewOptions } from "./CustomElements"
import produce from "immer"
import FroalaEditor from "react-froala-wysiwyg"
import _ from "lodash"
import useEditorConfig from "../components/widgets/Editor/useEditorConfig"
import { FroalaCSSBox } from "./FroalaCSSBox"
import { useFlag } from "../utilities/feature-management"
import audioElementStyle from "../features/insertAudio/audioElementStyle"

/* 
This component is a custom element that renders a process. A process has a series of steps, each with a title and content.
There is an optional introduction step and an optional summary step. The title is displayed at the top of the step.

The first step is the introduction step, which is optional. If present, it is displayed first. It has a button "Start" that the user
clicks to proceed to the next step.

The display is like a carousel, starting with the introduction step, then the series of steps, then the summary step.

Each step after the introduction shows a pagination control at the bottom of the step. The pagination control shows the current step number and the total number of steps, plus
a final checkmark entry to indicate the summary step.

The HTML representation is stored as a section element with a data-component="process"
attribute. Each step is stored as a div with a data-component="step".

The introduction and summary steps are special and have an additional attribute
data-step-type="introduction" or data-step-type="summary".

Sample HTML:

```html
<section data-component="process">
 <div data-component="step" data-step-type="introduction" data-title="Introduction">
  <p><img src="some-optional-image.jpg"></p>
  <p>Introduction content</p>
 </div>
 <div data-component="step" data-title="Step 1">
 <p>Step 1 <b>content</b></p>
 <p><img src="some-optional-image.jpg"></p>
 </div>
 <div data-component="step" data-step-type="summary" data-title="Summary">
  <p>Summary content</p>
 </div>
</section>
```

*/

/** Data representation of process state. */
interface ProcessData {
  introStep: StepData | null
  steps: StepData[]
  summaryStep: StepData | null

  /** Background color of process component */
  backgroundColor?: "primary"
}

/** Data representation of a single step. */
interface StepData {
  /** Title of the step. Appears in the step itself */
  title: string

  /** Content of the step in HTML */
  content: string
}

/*
 * This is a custom element that renders a process.
 */
export const processCustomElement: CustomElementConfig<ProcessData> = {
  selector: "[data-component='process']",
  /**
   * Extracts the data from the custom element to create a ProcessData object.
   * @param element - The custom element from which to extract data.
   * @returns A ProcessData object containing the data from the custom element.
   */
  getDataFromElement: (element) => {
    const introStep = element.querySelector("[data-step-type='introduction']")
    const summaryStep = element.querySelector("[data-step-type='summary']")
    const steps = Array.from(
      element.querySelectorAll("[data-component='step']:not([data-step-type])")
    )

    /**
     * Extracts the step data from a step element.
     * @param step - The step element from which to extract data.
     * @returns A StepData object containing the data from the step element.
     */
    const extractStepData = (step: Element) => {
      const title = (step as HTMLElement).dataset.title ?? ""
      const content = step.innerHTML
      return { title, content }
    }

    return {
      introStep: introStep ? extractStepData(introStep) : null,
      steps: steps.map(extractStepData),
      summaryStep: summaryStep ? extractStepData(summaryStep) : null,
      backgroundColor: (element as HTMLElement).dataset.backgroundColor as
        | "primary"
        | undefined,
    }
  },
  /**
   * Updates the custom element from the data.
   * @param element - The custom element to update.
   * @param data - The data to use to update the custom element.
   */
  updateElementFromData: (element, data) => {
    /** Create HTML for a single step
     * @param step The step to create HTML for
     * @param stepType The step type, or null if not a special step
     */
    const createStepHtml = (
      step: StepData,
      stepType: "introduction" | "summary" | null
    ) => `
      <div data-component="step" ${
        stepType != null ? `data-step-type="${stepType}" ` : " "
      }data-title="${_.escape(step.title)}">
        ${step.content}
      </div>  
    `

    element.innerHTML = `
      ${data.introStep ? createStepHtml(data.introStep, "introduction") : ""}
      ${data.steps.map((step) => createStepHtml(step, null)).join("")}
      ${data.summaryStep ? createStepHtml(data.summaryStep, "summary") : ""}
    `

    if (data.backgroundColor) {
      element.dataset.backgroundColor = data.backgroundColor
    } else {
      delete element.dataset.backgroundColor
    }
  },
  /**
   * Renders the custom element in the editor.
   * @param options - The options to use to render the custom element.
   * @returns The rendered component.
   * @param options.data The data to render.
   * @param options.onDataChange Callback to update the data.
   * @param options.editor The Froala editor.
   * @param options.element Root element of component in light DOM.
   * @param options.withStyles Function to wrap children in styles.
   * @param options.readOnly Whether the editor is read-only.
   * @returns The rendered component.
   */
  renderView: (options: RenderViewOptions<ProcessData>) => {
    const { data, onDataChange, editor, element, withStyles, readOnly } =
      options
    return (
      <ProcessCustomComponent
        editor={editor}
        data={data}
        onDataChange={onDataChange}
        element={element}
        withStyles={withStyles}
        readOnly={readOnly}
      />
    )
  },
}

/**
 * Renders the process
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.element Root element of component in light DOM.
 * @param props.data The process data to render.
 * @param props.onDataChange Callback to update the data.
 * @param props.withStyles Function to wrap children in styles.
 * @param props.readOnly Whether the editor is read-only.
 */
function ProcessCustomComponent(props: {
  editor: any
  element: HTMLElement
  data: ProcessData
  onDataChange?: (data: ProcessData) => void
  withStyles: (children: React.ReactElement) => React.ReactElement
  readOnly: boolean
}) {
  const { data, onDataChange, readOnly } = props
  const [editorOpen, setEditorOpen] = useState(false)

  return (
    <>
      {props.withStyles(
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            backgroundColor: (theme) => theme.palette.grey[100],
            ...audioElementStyle,
          }}
        >
          <ProcessDisplayComponent data={data} />
          {!readOnly && (
            <>
              <IconButton
                onClick={() => setEditorOpen(true)}
                data-cy="open-interactive-elements-editor"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  props.element.remove()
                  props.editor.undo.saveStep()
                }}
                data-cy="delete-interactive-elements-editor"
              >
                <Delete />
              </IconButton>
            </>
          )}
        </Box>
      )}
      {editorOpen && (
        <ProcessDialog
          initialData={data}
          onCancel={() => setEditorOpen(false)}
          onSave={(data) => {
            onDataChange!(data)
            setEditorOpen(false)
          }}
        />
      )}
    </>
  )
}

/** Process component with state of which step is selected
 * @param props See below.
 * @param props.data Data to display.
 * @returns The rendered component.
 */
function ProcessDisplayComponent(props: { data: ProcessData }) {
  const { data } = props

  // Create list of all steps
  const allSteps = useMemo(() => {
    let steps = [...data.steps]
    if (data.introStep) {
      steps.unshift(data.introStep)
    }
    if (data.summaryStep) {
      steps.push(data.summaryStep)
    }
    return steps
  }, [data])

  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const isIntroStep = data.introStep != null && currentStepIndex === 0
  const isSummaryStep =
    data.summaryStep != null && currentStepIndex === allSteps.length - 1

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
      data-cy="process-container"
    >
      {currentStepIndex > 0 && (
        <IconButton
          onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
          sx={{
            position: "absolute",
            left: "20px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: (theme) => theme.palette.grey[700],
            color: (theme) => theme.palette.grey[100],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[800],
            },
          }}
          data-cy="process-back"
        >
          <ArrowBackIosNew fontSize="large" />
        </IconButton>
      )}

      {currentStepIndex < allSteps.length - 1 && (
        <IconButton
          onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
          sx={{
            position: "absolute",
            right: "20px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: (theme) => theme.palette.grey[700],
            color: (theme) => theme.palette.grey[100],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[800],
            },
          }}
          data-cy="process-forward"
        >
          <ArrowForwardIos fontSize="large" />
        </IconButton>
      )}

      <Paper
        elevation={1}
        sx={{
          width: "70%",
          p: 2,
          m: 3,
          backgroundColor: (theme) =>
            data.backgroundColor === "primary"
              ? lighten(theme.palette.primary.main, 0.8)
              : "white",
        }}
      >
        {allSteps.map((step, index) => {
          return (
            <Box
              hidden={currentStepIndex !== index}
              data-cy="process-step-contents"
            >
              <StepContents step={step} />
            </Box>
          )
        })}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            alignItems: "center",
            pt: 2,
          }}
        >
          <div key="centered">
            {isIntroStep && (
              <Button
                onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                endIcon={<ArrowForwardIos />}
                data-cy="process-start-button"
              >
                Start
              </Button>
            )}
            {(currentStepIndex > 0 || !data.introStep) && (
              <>
                {data.steps.map((step, index) => (
                  <PaginationItem
                    key={index}
                    page={index + 1}
                    selected={
                      data.introStep != null
                        ? currentStepIndex === index + 1
                        : currentStepIndex === index
                    }
                    onClick={() =>
                      setCurrentStepIndex(
                        data.introStep != null ? index + 1 : index
                      )
                    }
                    data-cy="process-step-button"
                  />
                ))}
                {data.summaryStep && (
                  <PaginationItem
                    page={<Check />}
                    selected={currentStepIndex === allSteps.length - 1}
                    onClick={() => setCurrentStepIndex(allSteps.length - 1)}
                    data-cy="process-step-button"
                  />
                )}
              </>
            )}
          </div>
          <div key="right" style={{ position: "absolute", right: 0 }}>
            {isSummaryStep && (
              <Button
                onClick={() => setCurrentStepIndex(0)}
                startIcon={<Replay />}
                data-cy="process-restart-button"
              >
                Restart
              </Button>
            )}
          </div>
        </Box>
      </Paper>
    </Box>
  )
}

/**
 * Single step contents.
 *
 * Make any contenteditable elements read-only as captions should not be editable. These
 * are made editable by the Froala editor when loading the custom component underlying HTML.
 *
 * @param props See below.
 * @param props.step The step to render
 */
function StepContents(props: { step: StepData }) {
  const { step } = props

  return (
    <Box sx={{ pb: 2 }}>
      <h2 data-cy="process-title">{step.title}</h2>
      <FroalaCSSBox
        sx={{
          padding: "16px 32px 48px 32px",
          height: "25vh",
          overflowY: "auto",
          textAlign: "left",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: step.content.replace(/contenteditable="true"/g, ""),
          }}
          data-cy="process-content"
        />
      </FroalaCSSBox>
    </Box>
  )
}

/**
 * Create dialog to add a process.
 * @param onClose Callback to close the dialog with the html to insert or null if cancelled.
 */
export function createAddProcessDialog(onClose: (html: string | null) => void) {
  return (
    <ProcessDialog
      onSave={(data: ProcessData) => {
        // Create a new section element
        const section = document.createElement("section")
        section.dataset.component = "process"
        processCustomElement.updateElementFromData(section, data)

        onClose(section.outerHTML)
      }}
      onCancel={() => onClose(null)}
      initialData={{
        introStep: null,
        steps: [
          {
            title: "Step 1",
            content: "",
          },
          {
            title: "Step 2",
            content: "",
          },
        ],
        summaryStep: null,
      }}
    />
  )
}

/**
 * Dialog to edit a process.
 *
 * The dialog has a list of steps on the left. The selected step is shown on the right.
 * The selected step can be changed by clicking on a step in the list.
 *
 * @param props See below.
 * @param props.initialData Initial data to display.
 * @param props.onCancel Callback when the dialog is cancelled.
 * @param props.onSave Callback when the dialog is saved.
 */
function ProcessDialog(props: {
  initialData: ProcessData
  onCancel: () => void
  onSave: (data: ProcessData) => void
}) {
  const { initialData, onCancel, onSave } = props

  const [data, setData] = useState<ProcessData>(initialData)

  const enableBackground = useFlag("rollout-configurable-interaction-emphasis")

  // selectedStepIndex is used to determine which step is currently being edited.
  // It can have the following values:
  // - "introduction": The introduction step is being edited.
  // - "summary": The summary step is being edited.
  // - 0 to n-1: The step at the corresponding index in the steps array is being edited.
  const [selectedStepIndex, setSelectedStepIndex] = useState<
    number | "introduction" | "summary"
  >(data.introStep ? "introduction" : 0)

  /**
   * Handles the change of a step.
   * @param index - The index of the step to change. If "introduction", changes the introduction step. If "summary", changes the summary step.
   * @param stepData - The new data of the step.
   */
  const handleStepChange = (
    index: number | "introduction" | "summary",
    stepData: StepData
  ) => {
    setData(
      produce(data, (draft) => {
        if (index === "introduction") {
          draft.introStep = stepData
        } else if (index === "summary") {
          draft.summaryStep = stepData
        } else {
          draft.steps[index] = stepData
        }
      })
    )
  }

  /**
   * Handles the deletion of a step.
   * @param index - The index of the step to delete. If "introduction",
   * deletes the introduction step. If "summary", deletes the summary step.
   */
  const handleStepDelete = (index: number | "introduction" | "summary") => {
    setData(
      produce(data, (draft) => {
        if (index === "introduction") {
          draft.introStep = null
        } else if (index === "summary") {
          draft.summaryStep = null
        } else {
          draft.steps.splice(index, 1)
        }
      })
    )
    setSelectedStepIndex(0)
  }

  // Disable enforce focus to allow Froala image size editing to work
  return (
    <Dialog open={true} maxWidth="lg" fullWidth disableEnforceFocus>
      <DialogTitle key="dialogTitle">Edit Process</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="row" gap={5}>
          <Box sx={{ minWidth: "200px" }}>
            {enableBackground && (
              <div key="background">
                <FormControlLabel
                  control={
                    <Switch
                      checked={data.backgroundColor === "primary"}
                      onChange={(event) => {
                        setData(
                          produce(data, (draft) => {
                            draft.backgroundColor = event.target.checked
                              ? "primary"
                              : undefined
                          })
                        )
                      }}
                    />
                  }
                  label="Color Contrast"
                />
              </div>
            )}
            {!data.introStep && (
              <Button
                key="addIntroButton"
                sx={{ mt: 1 }}
                onClick={() => {
                  setData(
                    produce(data, (draft) => {
                      draft.introStep = {
                        title: `Introduction`,
                        content: "",
                      }
                    })
                  )
                }}
                startIcon={<Add />}
              >
                Add Introduction
              </Button>
            )}
            <List key="stepList">
              {data.introStep && (
                <ListItemButton
                  key="introStep"
                  selected={selectedStepIndex === "introduction"}
                  onClick={() => setSelectedStepIndex("introduction")}
                  data-cy="process-editor-step-button"
                >
                  <ListItemText primary="Introduction" />
                </ListItemButton>
              )}
              {data.steps.map((step, index) => (
                <ListItemButton
                  data-cy="process-editor-step-button"
                  key={`step-${index}`}
                  selected={selectedStepIndex === index}
                  onClick={() => setSelectedStepIndex(index)}
                >
                  <ListItemText primary={`Step ${index + 1}`} />
                </ListItemButton>
              ))}
              {data.summaryStep && (
                <ListItemButton
                  key="summaryStep"
                  selected={selectedStepIndex === "summary"}
                  onClick={() => setSelectedStepIndex("summary")}
                  data-cy="process-editor-step-button"
                >
                  <ListItemText primary="Summary" />
                </ListItemButton>
              )}
            </List>
            <div key="addStepDiv">
              <Button
                key="addStepButton"
                sx={{ mt: 1 }}
                onClick={() => {
                  setData(
                    produce(data, (draft) => {
                      draft.steps.push({
                        title: `Step ${draft.steps.length + 1}`,
                        content: "",
                      })
                    })
                  )
                  setSelectedStepIndex(data.steps.length)
                }}
                startIcon={<Add />}
              >
                Add Step
              </Button>
            </div>
            {!data.summaryStep && (
              <div key="addSummaryDiv">
                <Button
                  key="addSummaryButton"
                  sx={{ mt: 1 }}
                  onClick={() => {
                    setData(
                      produce(data, (draft) => {
                        draft.summaryStep = {
                          title: `Summary`,
                          content: "",
                        }
                      })
                    )
                  }}
                  startIcon={<Add />}
                >
                  Add Summary
                </Button>
              </div>
            )}
          </Box>
          <Box flexGrow={1}>
            {selectedStepIndex === "introduction" && data.introStep && (
              <StepEditComponent
                key="introduction"
                step={data.introStep}
                onChange={(stepData) =>
                  handleStepChange("introduction", stepData)
                }
                onDelete={() => handleStepDelete("introduction")}
                title="Introduction"
              />
            )}
            {selectedStepIndex !== "introduction" &&
              selectedStepIndex !== "summary" &&
              data.steps[selectedStepIndex] && (
                <StepEditComponent
                  key={`stepEdit-${selectedStepIndex}`}
                  step={data.steps[selectedStepIndex]}
                  onChange={(stepData) =>
                    handleStepChange(selectedStepIndex, stepData)
                  }
                  onDelete={() => handleStepDelete(selectedStepIndex)}
                  title={`Step ${selectedStepIndex + 1}`}
                />
              )}
            {selectedStepIndex === "summary" && data.summaryStep && (
              <StepEditComponent
                key="summary"
                step={data.summaryStep}
                onChange={(stepData) => handleStepChange("summary", stepData)}
                onDelete={() => handleStepDelete("summary")}
                title="Summary"
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button key="cancelButton" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          key="saveButton"
          color="primary"
          variant="contained"
          onClick={() => onSave(data)}
          disabled={data.steps.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/** Props for the StepEditComponent */
interface StepEditComponentProps {
  step: StepData
  title: string
  onChange: (data: StepData) => void
  onDelete: () => void
}

/**
 * Edit a single step.
 * @param props See below.
 */
function StepEditComponent(props: StepEditComponentProps) {
  const { step, onChange, onDelete, title } = props

  return (
    <div>
      <h3>
        {title}
        <IconButton
          sx={{ float: "right" }}
          onClick={() => {
            onDelete()
          }}
          data-cy="editor-delete-button"
        >
          <Delete />
        </IconButton>
      </h3>
      <Stack spacing={2}>
        <TextField
          label="Step Title"
          sx={{ mb: 1 }}
          value={step.title}
          data-cy="process-editor-step-title"
          onChange={(e) => {
            onChange(
              produce(step, (draft) => {
                draft.title = e.target.value
              })
            )
          }}
          fullWidth
        />
        <StepContentComponent
          content={step.content}
          onChange={(content) => {
            onChange(
              produce(step, (draft) => {
                draft.content = content
              })
            )
          }}
        />
      </Stack>
    </div>
  )
}

/** Props for the StepContentComponent. */
interface StepContentComponentProps {
  /** HTML to edit */
  content: string

  /** Callback when the HTML is changed.
   * @param content The new html data.
   */
  onChange: (content: string) => void
}

/**
 * Edits a single step content
 *
 * @param props See above.
 */
function StepContentComponent(props: StepContentComponentProps) {
  const { content, onChange } = props

  /** Handle change to model
   * @param model The new model
   */
  const onModelChange = (model: string) => {
    onChange(model)
  }

  // Get editor config
  const editorConfig = useEditorConfig({
    /** Empty function */
    onInitialize: () => {},
    /** Empty function */
    onDestroy: () => {},
  })

  const stepContentEditorConfig = useMemo(() => {
    if (!editorConfig) {
      return null
    }

    return produce(editorConfig, (draftConfig: any) => {
      // Set height to 500px of config
      draftConfig.height = 500

      // Fix z-index of modal
      draftConfig.zIndex = 2000
    })
  }, [editorConfig])

  if (!stepContentEditorConfig) {
    return null
  }

  return (
    // Set height to 600px to give room for editor and toolbar to display and prevent flicker when switching between pages
    <Box
      sx={{ height: "600px", ...audioElementStyle }}
      data-cy="process-editor-step-content"
    >
      <FroalaEditor
        tag="textarea"
        config={stepContentEditorConfig}
        model={content}
        onModelChange={onModelChange}
      />
    </Box>
  )
}
