import { sortList } from "../../welcome/hooks/useCoursesDisplay"
import selectItemsForFolder from "../selectors/selectItemsForFolder"
import { ItemsState, Item } from "../types"

/**
 * Modify existing items list to include a new virtual folder with the given title
 * @param prev - Current item list from state
 * @param sortBy - Item sorting method
 * @param newFolderTitle - Title of folder to be added to list
 */
const addVirtualFolder = (
  prev: ItemsState,
  sortBy: string,
  newFolderTitle: string
) => {
  // Get existing list of folder IDs at top level
  const existingList = selectItemsForFolder(prev, "__ALL__") ?? []

  // From the root list, find the index where it switches from folders to courses
  const courseIndex = existingList.findIndex(
    (item: Item) => item.item_type === "course"
  )

  // Split up courses and folders, since we know folders will always be first
  const folders = existingList.slice(0, courseIndex)
  const courses = existingList.slice(courseIndex)

  const tempID = "__TEMP_ID__" as string

  // Create a stubbed virtualized folder to appear in state
  const virtualizedFolder: Item = {
    item_type: "folder",
    title: newFolderTitle,
    id: tempID,
    tenant_id: "",
    status: null,
    parent_directory: null,
    last_update: null,
    is_template: false,
  }

  // Add the virtualized folder to the existing list of folders and sort them
  const virtualizedFolderList = sortList(sortBy, [
    virtualizedFolder,
    ...folders,
  ])

  const finalIDs = [...virtualizedFolderList, ...courses].map((item) => item.id)

  // Return state updated with new folder inserted and sorted into list
  return {
    ...prev,
    ids: {
      ...prev.ids,
      __ALL__: finalIDs,
    },
    entities: {
      ...prev.entities,
      [tempID]: virtualizedFolder,
    },
  }
}

export default addVirtualFolder
