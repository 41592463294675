import axiosInstance from "../../../api/axios"

/**
 * Fetch a list of available Learning Path Templates
 */
const fetchLearningPathTemplates = () => {
  return axiosInstance.get(`/paths/templates`).then((response) => response.data)
}

export default fetchLearningPathTemplates
