import styled from "@emotion/styled"
import { Paper } from "@mui/material"

/**
 * Wrapper for content that appears in the WelcomeDisplay
 *
 */
const StyledPaper = styled(Paper)(({ theme, graphic }) => {
  return {
    height: "100%",
    width: "100%",
    flex: "1 1",
    justifyContent: "center",
    alignSelf: "stretch",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    flexDirection: "column",
    ...(graphic && {
      background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("img/posts/${graphic}")`,
      backgroundSize: "cover",
      color: theme.palette.lexWhite,
    }),
  }
})

export default StyledPaper
