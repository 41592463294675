import { useAuthenticator } from "@aws-amplify/ui-react"
import { useMemo } from "react"

import {
  getErrors,
  translate,
} from "@aws-amplify/ui-react/node_modules/@aws-amplify/ui"

/**
 * Retrieve the first validation error for the given field name.
 * @param name The name of the target field.
 */
function useValidationError(name: string) {
  const { validationErrors } = useAuthenticator(({ validationErrors }) => [
    validationErrors,
  ])

  const errors = useMemo(
    () => getErrors(validationErrors[name]),
    [name, validationErrors]
  )

  const firstError = errors?.[0] || null

  return firstError && translate(firstError)
}

export default useValidationError
