import { CustomStyle } from "../../../../features/branding/BrandingStylesPicker"
import { IBranding } from "../../../../features/branding/hooks/useBranding"

/**
 * Scale base font size depending on smaller/normal/bigger definition
 * @param size - Branding size attribute
 */
const brandingFontScale = (size: string) => {
  if (size === "bigger") {
    return 1.25
  } else if (size === "smaller") {
    return 0.75
  }
  return 1
}

/**
 * For a given course branding configuration, convert them into valid
 * CSS attributes to be injected into the Froala Editor wrapper component
 *
 * @param branding - Branding configuration object for a course
 */
const withEditorBranding = (branding: IBranding | null) => {
  if (!branding) {
    return {}
  }

  const fontSizeFactor = brandingFontScale(branding.size)

  let bodyStyles = {
    fontFamily: branding.body.name ? `${branding.body.name}` : "",
    fontSize: `${fontSizeFactor * 1}rem`,
  }

  let customHeadingOverrides: any = {}

  /**
   * If the course branding configuration has custom styles defined,
   * iterate through them and map them to custom style overrides.
   */
  if (branding.size === "custom" && branding.styles) {
    branding.styles.forEach((level: CustomStyle) => {
      if (level.name === "body") {
        // For body styles, append them
        bodyStyles = {
          ...bodyStyles,
          ...level.style,
        }
      } else {
        customHeadingOverrides[level.name] = level.style
      }
    })
  }

  return {
    "& > *": {
      ...bodyStyles,
    },
    "h1, h2, h3, h4, h5, h6": {
      fontFamily: branding.heading.name ? `${branding.heading.name}` : "unset",
    },
    h1: {
      fontSize: `${fontSizeFactor * 2}rem`,
    },
    h2: {
      fontSize: `${fontSizeFactor * 1.5}rem`,
    },
    h3: {
      fontSize: `${fontSizeFactor * 1.17}rem`,
    },
    h4: {
      fontSize: `${fontSizeFactor * 1}rem`,
    },
    h5: {
      fontSize: `${fontSizeFactor * 0.83}rem`,
    },
    h6: {
      fontSize: `${fontSizeFactor * 0.67}rem`,
    },
    ...customHeadingOverrides,
  }
}

export default withEditorBranding
