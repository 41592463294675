import { Box, Typography, Paper, Stack, Skeleton } from "@mui/material"
import { CheckCircle, DeleteForever } from "@mui/icons-material"
import PageLayout from "../layouts/PageLayout"
import IntelligentUpdate from "../widgets/IntelligentUpdate"
import withPreventDefault from "../../utilities/withPreventDefault"
import CheckboxSelect from "../widgets/CheckboxSelect"
import { LoadingButton } from "@mui/lab"
import useIntelligentUpdates, {
  states,
} from "../../hooks/useIntelligentUpdates"
import {
  ButtonEvents,
  useMixpanelButtonTracker,
} from "../../utilities/mixpanel"

function IntelligentUpdateScreen(props) {
  const { course, useUpdates = useIntelligentUpdates } = props
  const {
    updates,
    state,

    apply,
    ignore,
    applyAll,
    ignoreAll,

    targets,
    selectedTargetIDs,
    selectTargets,
  } = useUpdates(course.id)

  const trackUpdateButtonClick = useMixpanelButtonTracker(
    ButtonEvents.ApplyIntelligentUpdate
  )
  const trackIgnoreButtonClick = useMixpanelButtonTracker(
    ButtonEvents.IgnoreIntelligentUpdate
  )

  return (
    <PageLayout
      fixed
      maxWidth="lg"
      breadcrumbs={[
        { label: "Courses", href: "/" },
        { label: `"${course.title}"`, href: `/editor?course=${course.id}` },
        { label: "Intelligent Update" },
      ]}
    >
      <Box
        sx={{
          display: "grid",
          gap: 4,
          minHeight: "100%",
          gridTemplate: {
            lg: `
          "description description description"
          "sidebar main main"
          "sidebar list list" 1fr / 1fr 1fr 1fr
          `,
            xs: `
          "description"
          "sidebar"
          "main"
          "list"
          `,
          },
        }}
      >
        <Box gridArea="description">
          <Typography variant="h4" component="h1" gutterBottom>
            Intelligent Update
          </Typography>
          <Typography variant="body1" align="justify">
            Select which changes you'd like to apply and the courses to apply
            them to.
          </Typography>
        </Box>

        <Box
          sx={{
            gridArea: "sidebar",
            padding: 3,
            pb: 2,
            backgroundColor: "neutral.light",
            borderRadius: 1,
            height: "100%",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }} gutterBottom>
            Choose the courses where these changes can be applied.
          </Typography>

          <CheckboxSelect
            options={targets}
            getKey={({ id }) => id}
            getLabel={({ title }) => title}
            getTooltip={({ title }) => title}
            loading={state === states.LOADING}
            selection={selectedTargetIDs}
            onSelectionChange={selectTargets}
          />
        </Box>

        <Box gridArea="main">
          <Typography variant="h5" component="h2" gutterBottom>
            All Updates
          </Typography>
          <Typography variant="body1" gutterBottom>
            Choose to update all the selected courses with all the applicable
            updates right now or make individual updates shown below.
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <LoadingButton
              data-cy="int-update-all"
              variant="outlined"
              color="success"
              startIcon={<CheckCircle />}
              loading={state === states.APPLYING}
              disabled={!updates.length || state !== states.READY}
              onClick={() => {
                withPreventDefault(applyAll)
                trackUpdateButtonClick()
              }}
            >
              Update all
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              color="error"
              startIcon={<DeleteForever />}
              loading={state === states.IGNORING}
              disabled={!updates.length || state !== states.READY}
              onClick={() => {
                withPreventDefault(ignoreAll)
                trackIgnoreButtonClick()
              }}
            >
              Ignore all
            </LoadingButton>
          </Stack>
        </Box>

        <Box gridArea="list">
          <Typography variant="h5" component="h2" gutterBottom>
            Individual Updates
          </Typography>

          <Stack spacing={2}>
            {state === states.LOADING && (
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: 2, height: 300 }}
              />
            )}
            {state === states.READY && !updates.length && (
              <Typography color="text.secondary" align="center" py={1}>
                No updates to apply.
              </Typography>
            )}
            {updates.map((update) => (
              <Paper key={update.id} variant="outlined" sx={{ p: 3 }}>
                <IntelligentUpdate
                  {...update}
                  state={state !== states.READY ? state : update.state}
                  onApplyClick={() => apply(update.id, update.targets)}
                  onIgnoreClick={() => ignore(update.id, update.targets)}
                  diffDirection={{ xs: "column", md: "row" }}
                />
              </Paper>
            ))}
          </Stack>
        </Box>
      </Box>
    </PageLayout>
  )
}

IntelligentUpdateScreen.states = states

export default IntelligentUpdateScreen
