import {
  argbFromHex,
  hexFromArgb,
  themeFromSourceColor,
} from "@material/material-color-utilities"

export interface SimpleColorTheme {
  baseColor: string
  mainColor: string
  mainTextColor: string
  backgroundColor: string
  backgroundTextColor: string
}
/**
 * Use a single color to make a material color theme and extract the parts
 * we need for exported assets.
 *
 * Would be better to do this server side, but at the moment material-color-utilities
 * is not available in python.
 *
 * @param baseColor RGB color to start with
 */
export function makeColorThemeFromColor(baseColor: string): SimpleColorTheme {
  // Get the color theme from a hex color

  const colorTheme = themeFromSourceColor(argbFromHex(baseColor))

  // Map the color theme into our simple export theme
  //console.log(JSON.stringify(theme, null, 2))

  const mainColor = hexFromArgb(colorTheme.schemes.light.primary)
  const mainTextColor = hexFromArgb(colorTheme.schemes.light.onPrimary)
  const backgroundColor = hexFromArgb(colorTheme.schemes.light.surfaceVariant)
  const backgroundTextColor = hexFromArgb(
    colorTheme.schemes.light.onSurfaceVariant
  )

  return {
    baseColor,
    mainColor,
    mainTextColor,
    backgroundColor,
    backgroundTextColor,
  }
}
