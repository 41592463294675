import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import React, { useState } from "react"
import useCourses from "../../../hooks/useCourses"
import { AddCircleOutline, PostAdd } from "@mui/icons-material"

/**.
 * Component for displaying options for adding a course to a given Learning Path
 *
 * @param props
 */
const AddToPathDialog = (props) => {
  const { path, open, onConfirm, onCancel } = props
  const { documents: allCourses } = useCourses()
  const [selectedCourse, setSelectedCourse] = useState(null)

  const coursesAlreadyInPath = path.courses.map((course) => course.id)
  const availableCourses = allCourses.filter(
    (course) => !coursesAlreadyInPath.includes(course.id)
  )

  return (
    <Dialog onClose={onCancel} open={open} maxWidth="md" fullWidth>
      <DialogTitle>Add Course To Learning Path</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between">
          <Box flexGrow={1}>
            <DialogContentText>
              Choose an existing course to add to "{path.title}"
            </DialogContentText>
            <FormControl sx={{ marginBottom: 2 }} variant="standard" fullWidth>
              <InputLabel>Course</InputLabel>
              <Select
                onChange={(e) => setSelectedCourse(e.target.value)}
                label="Course"
                value={selectedCourse ?? ""}
              >
                {availableCourses.map((course) => (
                  <MenuItem key={course.id} value={course}>
                    {course.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" justifyContent="center">
              <Button
                startIcon={<AddCircleOutline />}
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onConfirm(selectedCourse)
                  setSelectedCourse(null)
                }}
              >
                Add to Learning Path
              </Button>
            </Box>
          </Box>
          <Divider
            sx={{ marginLeft: 4, marginRight: 4 }}
            orientation="vertical"
            flexItem
          />
          <Box flexGrow={1}>
            <DialogContentText textAlign="center" marginBottom={2}>
              Or create a new course to be added to this path.
            </DialogContentText>
            <Box display="flex" justifyContent="center">
              <Button
                startIcon={<PostAdd />}
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onConfirm(null)
                }}
              >
                Create New Course
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddToPathDialog
