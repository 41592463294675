import React, {
  createContext,
  useContext,
  useEffect,
  ReactNode,
  useState,
} from "react"
import { gapi } from "gapi-script"
import config from "../config"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { useAuthenticator } from "@aws-amplify/ui-react"

// Define the context type
interface GapiContextType {
  downloadDriveFile: DownloadDriveFileCallback
  isGapiReady: boolean
  setOauthToken: (token: any) => void
  oauthToken: any
}

// Define a type for the downloadDriveFile function
export type DownloadDriveFileCallback = (
  fileId: string,
  mimeType: string,
  exportType: string | null
) => Promise<any>

// Create the context with a default value
const GapiContext = createContext<GapiContextType | undefined>(undefined)

interface GapiProviderProps {
  children: ReactNode
}

/**
 * Provider for Google apis
 * @param root0 - params
 * @param root0.children - children
 */
const GapiProvider: React.FC<GapiProviderProps> = ({ children }) => {
  const [gapiReady, setGapiReady] = useState(false)
  const [goauthReady, setGoauthReady] = useState(false)
  const [oauthToken, setOauthToken] = useState(null)

  const { user } = useAuthenticator((context) => [context.user])

  useEffect(() => {
    if (user == null) {
      // User is logged out, clear drive token
      setOauthToken(null)
    }
  }, [user])

  /** Return true when we're ready */
  const isGapiReady = gapiReady && goauthReady

  /** Initialize Google API  */
  const loadGapi = async () => {
    // load google js files
    await new Promise((res, rej) => {
      gapi.load("client:picker", { callback: res, onerror: rej })
    })

    // init the client
    await gapi.client.init({
      discoveryDocs: ["https://discovery.googleapis.com/$discovery/rest"],
    })

    // load drive
    await gapi.client.load("drive", "v3")

    setGapiReady(true)
  }

  /**
   * Convert and download a file from google drive
   * @param fileId Google file id
   * @param mimeType mime type of file
   * @param exportType mime type to convert to
   * @returns {Promise<response>} request response result
   */
  const downloadDriveFile = async (
    fileId: string,
    mimeType: string,
    exportType: string | null
  ): Promise<any> => {
    const driveApi = (gapi.client as any).drive
    if (exportType != null && mimeType !== exportType) {
      return driveApi.files.export({
        fileId: fileId,
        mimeType: exportType,
      })
    } else {
      return driveApi.files.get({
        fileId: fileId,
        alt: "media",
      })
    }
  }

  useEffect(() => {
    loadGapi().catch((err) => console.error("problem loading gapi", err))
  }, [])

  return (
    <GapiContext.Provider
      value={{ downloadDriveFile, isGapiReady, setOauthToken, oauthToken }}
    >
      <GoogleOAuthProvider
        clientId={config.googleApiCredentials.clientId!}
        onScriptLoadSuccess={() => setGoauthReady(true)}
      >
        {children}
      </GoogleOAuthProvider>
    </GapiContext.Provider>
  )
}

/** Custom hook to use GAPI context */
export const useGapi = (): GapiContextType => {
  const context = useContext(GapiContext)
  if (!context) {
    throw new Error("useGapi must be used within a GapiProvider")
  }
  return context
}

export default GapiProvider
