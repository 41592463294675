import { useAuthenticator } from "@aws-amplify/ui-react"
import { styled } from "@mui/material"
import { CodeField, PasswordField } from "../fields"
import { useFormHandlers } from "../hooks/useFormHandlers"
import {
  BackToSignIn,
  Button,
  RemoteErrorMessage,
  SubmitButton,
} from "../shared"
import Form from "./Form"

/** An spacer element that expands to fill flex containers. */
const Spacer = styled("div")({ flexGrow: 1 })

/**
 * Displays the form for confirming a password reset.
 */
export function ConfirmResetPassword() {
  const { handleChange, handleSubmit } = useFormHandlers()
  return (
    <Form method="post" onChange={handleChange} onSubmit={handleSubmit}>
      <CodeField />
      <PasswordField
        label="New Password"
        inputProps={{ name: "password", autoComplete: "new-password" }}
      />
      <PasswordField
        label="Confirm Password"
        inputProps={{ name: "confirm_password", autoComplete: "new-password" }}
      />
      <RemoteErrorMessage />
      <Form.ActionBar>
        <SubmitButton />
        <ResendCodeButton />
        <Spacer />
        <BackToSignIn />
      </Form.ActionBar>
    </Form>
  )
}

/**
 * Requests a new reset code for the current user.
 */
function ResendCodeButton() {
  const { resendCode } = useAuthenticator((context) => [context.resendCode])

  return (
    <Button
      onClick={(e) => {
        e.preventDefault()
        resendCode()
      }}
    >
      Resend Code
    </Button>
  )
}
