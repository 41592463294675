import isHeading from "./isHeading"

/**
 * Find all the block-level elements for a document or document section.
 *
 * When a section is specified, all of the block-level elements within the
 * section are returned. Otherwise, all block-level elements for the root
 * document are returned.
 *
 * @type {(root: Element, section?: Element) => Iterator<Element>}
 */
const findBlockElements = (root, section) => {
  const filter = makeBlockNodeFilter(root)
  const walker = document.createTreeWalker(
    root,
    NodeFilter.SHOW_ELEMENT,
    filter
  )

  let done = false
  walker.currentNode = section ?? root
  if (filter(walker.currentNode) !== NodeFilter.FILTER_ACCEPT) {
    done = !walker.nextNode()
  }

  return {
    next() {
      if (done) {
        return { done }
      }
      const value = walker.currentNode
      done = !walker.nextNode() || isEndOfSection(walker.currentNode, section)
      return { value, done: false }
    },
    [Symbol.iterator]() {
      return this
    },
  }
}

export default findBlockElements

/**
 * Check if an element is block-level with respect to a given container.
 *
 * @type {(element: Element, container: Element) => boolean}
 */
export const isBlockLevel = (element, container) =>
  element.parentNode === container ||
  element?.parentNode?.nodeName === "SECTION"

/**
 * Make a node filter for identifying block-level elements.
 *
 * @param {Element} root The root container for the elements.
 * @returns {NodeFilter} see: https://developer.mozilla.org/en-US/docs/Web/API/TreeWalker/filter
 */
const makeBlockNodeFilter = (root) => {
  return (element) => {
    // Skip any blacklisted blocks.
    if (isBlacklistedBlock(element)) {
      return NodeFilter.FILTER_REJECT
    }

    // Accept any remaining elements that are at a block-level.
    return isBlockLevel(element, root)
      ? NodeFilter.FILTER_ACCEPT
      : NodeFilter.FILTER_REJECT
  }
}

/**
 * Check if an block element has been blacklisted.
 *
 * Blacklisted blocks include virtual elements that are not part of the user
 * content (e.g. embedded controls or placeholders).
 *
 * @type {(node: Element) => boolean}
 */
const isBlacklistedBlock = (node) => {
  if (node instanceof HTMLElement) {
    // Smart template controls are not part of the user content and have data attribute of "data-smart-template-control"
    if (node.dataset.smartTemplateControl) {
      return true
    }
    if (node.classList.contains("placeholder")) {
      return true
    }
  }
  return false
}

/** Check if an element marks the end of a given section. */
const isEndOfSection = (element, section) =>
  section &&
  isHeading(section) &&
  element !== section &&
  isHeading(element) &&
  element.tagName <= section.tagName
