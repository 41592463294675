import { Auth, CognitoUser } from "@aws-amplify/auth"
import { Hub } from "@aws-amplify/core"
import { aws_config } from "./aws_config"
bootstrap()

/**
 * Bootstrap the authentication config.
 */
export function bootstrap() {
  const origin = window.location.origin

  Auth.configure({
    ...aws_config,
    oauth: {
      domain: "learnexperts.auth.ca-central-1.amazoncognito.com",
      redirectSignIn: `${origin}/login`,
      redirectSignOut: `${origin}/login`,
      responseType: "code",
    },
  })

  ensureConsistentSession()
}

/**
 * Ensure authentication is still valid when returning to stale sessions.
 */
async function ensureConsistentSession() {
  // Track authentication changes for the active session.
  let user = await getCurrentUserOrNull()
  Hub.listen("auth", async ({ payload }) => {
    if (["signIn", "signOut", "cognitoHostedUI"].includes(payload.event)) {
      user = await getCurrentUserOrNull()
    }
  })

  /** Ensure the previous user session is still active. Otherwise trigger a reload. */
  async function reloadIfSessionStale() {
    const isStaleAuthentication =
      user &&
      user.getUsername() !== (await getCurrentUserOrNull())?.getUsername()

    if (isStaleAuthentication) {
      window.location.reload()
    }
  }

  // When returning to stale session, check that the authentication is still valid.
  window.addEventListener("focus", reloadIfSessionStale)
  document.addEventListener("visibilitychange", async () => {
    if (document.visibilityState === "visible") {
      reloadIfSessionStale()
    }
  })
}

/**
 * Retrieve the user data for the active authentication session. Otherwise null.
 */
async function getCurrentUserOrNull(): Promise<CognitoUser | null> {
  try {
    return await Auth.currentAuthenticatedUser()
  } catch {
    return null
  }
}
