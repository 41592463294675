import React, { useState } from "react"
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material"
import { Abc, Delete, MoreVert } from "@mui/icons-material"

const LearningPathContextMenu = (props) => {
  const { anchorEl, setAnchorEl, renameClicked, deleteClicked } = props
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip placement="top" arrow title="Learning Path Actions">
        <IconButton aria-label="delete" onClick={handleMenu}>
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        open={open}
        onClick={(e) => e.stopPropagation()}
        onClose={handleClose}
        anchorEl={anchorEl}
        data-cy="learning-path-actions"
      >
        <MenuItem onClick={renameClicked}>
          <ListItemIcon>
            <Abc fontSize="small" />
          </ListItemIcon>
          <Typography>Rename Learning Path</Typography>
        </MenuItem>
        <MenuItem onClick={deleteClicked}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <Typography>Delete Learning Path</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default LearningPathContextMenu
