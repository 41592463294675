import { AppState } from ".."

/**
 * Select flag determining if remarks were the last item clicked
 * @param state - Redux state
 */
export const selectRemarksScrollLocked = (state: AppState) =>
  state.editor.remarksScrollLocked

/**
 * Select current scroll path of selection in editor
 * @param state - Redux state
 */
export const selectScrollPath = (state: AppState) => state.editor.scrollPath

/**
 * Select number of current active selection in editor
 * @param state - Redux state
 */
export const selectCurrentSectionNumber = (state: AppState) =>
  state.editor.currentSectionNumber

/**
 * Select heading element of active section
 * @param state - Redux state
 */
export const selectCurrentSectionHeader = (state: AppState) =>
  state.editor.currentSectionHeader
