import axiosInstance from "./axios"

/**
 * Add a comment to a course.
 *
 * @returns {Promise<Comment>}
 */
export const deleteCommentReply = (arg) => {
  const { id, courseId, commentId, as } = arg

  return axiosInstance
    .delete(`/courses/${courseId}/comments/${commentId}/replies/${id}`, null, {
      params: { email: as?.email },
    })
    .then((response) => response.data)
}

export default deleteCommentReply
