import { Button, Stack, TextField, Theme } from "@mui/material"
import { ReactNode, useEffect } from "react"
import { Autocomplete } from "@mui/lab"
import { IGoogleFont } from "../../../../branding/hooks/useBranding"
import ListBoxComponent from "../../../../branding/ListBoxComponent"
import loadFontFace from "../../../../branding/utils/loadFontFace"

/**
 * Component for uploading and previewing fonts
 * @param props - Component props
 */
const FontSelectionControl = (props: any) => {
  const {
    branding,
    variant = "heading",
    onPropertyChange,
    title,
    googleFonts,
    recentFonts,
    updateRecentFonts,
  } = props

  const selectedFont = branding[variant]

  /**
   * Upon mounting, check to see if we have an existing font file URL loaded into
   * the state. If so, create a custom font face using that file, load it into
   * the document, and apply that font style to the preview text, which will
   * be set to the the font's generated name in the state.
   */
  useEffect(() => {
    if (selectedFont) {
      loadFontFace(selectedFont)
    }
  }, [selectedFont])

  /**
   * Handle file upload of font. Create a URL to display it to the user and
   * store the file object itself in the branding.changes object in the state.
   *
   * @param event - File input event
   */
  const onAdd = (event: any) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const fontUrl = URL.createObjectURL(file)
      // For the text content of the font preview, grab whatever name has been given to the font file
      const fontName = file.name.split(".")[0]
      onPropertyChange(variant, { url: fontUrl, name: fontName }, file)
    }
  }

  const fontOptions = recentFonts[variant].concat(googleFonts)

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Autocomplete
        fullWidth
        sx={{
          "& input": {
            font: `1rem "${selectedFont.name}"`,
          },
        }}
        ListboxComponent={ListBoxComponent}
        onChange={(event: any, newValue: IGoogleFont) => {
          if (newValue == null) {
            onPropertyChange(variant, { url: null, name: null })
          } else {
            updateRecentFonts(newValue, variant)
            onPropertyChange(variant, newValue)
          }
        }}
        value={selectedFont}
        renderInput={(params) => (
          <TextField
            sx={(theme: Theme) => ({ color: "red" })}
            {...params}
            label={title}
          />
        )}
        getOptionLabel={(option) => {
          if (!option.name) {
            return ""
          }
          return option.name
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderOption={(props, option, state: any) =>
          [props, option, state.index] as ReactNode
        }
        options={fontOptions}
      />
      <Button variant="outlined" component="label">
        <input
          hidden
          type="file"
          accept=".ttf,.otf,.woff2,.woff"
          onChange={onAdd}
        />
        Upload
      </Button>
    </Stack>
  )
}

export default FontSelectionControl
