import { Button, Stack } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import JoinInnerIcon from "@mui/icons-material/JoinInner"
import AddIcon from "@mui/icons-material/Add"
import {
  addSmartTemplate,
  findTemplateTypesInSection,
} from "../../utilities/smartTemplates"
import { SmartTemplateControlData } from "./smartTemplateControlCustomElement"
import { SimilarSmartTemplatePanel } from "./SimilarSmartTemplatePanel"
import { RewriteSmartTemplatePanel } from "./RewriteSmartTemplatePanel"

/**
 * Smart template custom component for H2s that are not already demonstrations, exercises, etc.
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.element Root element of component in light DOM.
 * @param props.editorDiv The editor div.
 * @param props.data The flip card data to render.
 * @param props.onDataChange Callback to update the data.
 * @param props.withStyles Function to wrap children in styles.
 */
export function H2SmartTemplateCustomComponent(props: {
  editor: any
  element: HTMLElement
  editorDiv: HTMLDivElement
  data: SmartTemplateControlData
  onDataChange?: (data: SmartTemplateControlData) => void
  withStyles: (children: React.ReactElement) => React.ReactElement
}) {
  const { editor, element, editorDiv, withStyles } = props

  // Which panel is open if any
  const [panelOpen, setPanelOpen] = useState<"rewrite" | "similar_content">()

  // Get existing smart templates in section
  const existingSmartTemplates = useTemplateTypesInSection(editorDiv, element)

  // True if similar content feature is enabled
  const similarContentEnabled = true

  /** Adds a smart template of the given type
   * @param templateType The type of smart template to add e.g. "demonstration"
   */
  function addTemplate(templateType: string) {
    // Get section heading
    const smartTemplateId = element.dataset.smartTemplateId!
    const smartTemplateHeading = document.getElementById(smartTemplateId)!

    addSmartTemplate(editor, templateType, smartTemplateHeading)
  }

  if (panelOpen === "rewrite") {
    // withStyles is not wrapped here because we have a dialog which cannot be wrapped by withStyles
    return (
      <RewriteSmartTemplatePanel
        editor={editor}
        element={element}
        onClose={() => {
          setPanelOpen(undefined)
        }}
        withStyles={withStyles}
      />
    )
  }

  if (panelOpen === "similar_content") {
    // withStyles is not wrapped here because we have a dialog which cannot be wrapped by withStyles
    return (
      <SimilarSmartTemplatePanel
        editor={editor}
        element={element}
        onClose={() => {
          setPanelOpen(undefined)
        }}
        withStyles={withStyles}
      />
    )
  }

  return withStyles(
    <Stack direction="row" spacing={1}>
      <Button
        variant="outlined"
        onClick={() => {
          setPanelOpen("rewrite")
        }}
        startIcon={<AutoFixHighIcon fontSize="small" />}
        size="small"
      >
        Rewrite Section
      </Button>
      {similarContentEnabled && (
        <Button
          variant="outlined"
          onClick={() => {
            setPanelOpen("similar_content")
          }}
          startIcon={<JoinInnerIcon fontSize="small" />}
          size="small"
        >
          Similar Content
        </Button>
      )}
      {!existingSmartTemplates.includes("demonstration") && (
        <Button
          variant="outlined"
          onClick={() => {
            addTemplate("demonstration")
          }}
          startIcon={<AddIcon fontSize="small" />}
          size="small"
        >
          Add Demonstration
        </Button>
      )}
      {!existingSmartTemplates.includes("exercise") && (
        <Button
          variant="outlined"
          onClick={() => {
            addTemplate("exercise")
          }}
          startIcon={<AddIcon fontSize="small" />}
          size="small"
        >
          Add Exercise
        </Button>
      )}
      {!existingSmartTemplates.includes("test_question") && (
        <Button
          variant="outlined"
          onClick={() => {
            addTemplate("test_question")
          }}
          startIcon={<AddIcon fontSize="small" />}
          size="small"
        >
          Add Test Questions
        </Button>
      )}
    </Stack>
  )
}

/**
 * Gets template types within a content section.
 * Uses observer to detect changes to the section and updates the template types when they change.
 *
 * @param editorDiv The editor div
 * @param smartTemplateControlElement The smart template control element within the H2
 */
function useTemplateTypesInSection(
  editorDiv: HTMLElement,
  smartTemplateControlElement: HTMLElement
): string[] {
  const [templateTypes, setTemplateTypes] = useState<string[]>([])

  const updateTemplateTypes = useCallback(() => {
    // Get template heading
    const smartTemplateId = smartTemplateControlElement.dataset.smartTemplateId!
    const smartTemplateHeading = document.getElementById(smartTemplateId)!

    setTemplateTypes(findTemplateTypesInSection(smartTemplateHeading))
  }, [smartTemplateControlElement])

  useEffect(() => {
    // Add mutation observer, looking for data-template elements that are added or removed
    const observer = new MutationObserver((mutations) => {
      let changed = false

      for (const mutation of mutations) {
        // eslint-disable-next-line no-loop-func
        mutation.removedNodes.forEach((removedNode) => {
          if (removedNode instanceof HTMLElement) {
            if (removedNode.dataset.template != null) {
              changed = true
            }
          }
        })
        // eslint-disable-next-line no-loop-func
        mutation.addedNodes.forEach((addedNode) => {
          if (addedNode instanceof HTMLElement) {
            if (addedNode.dataset.template != null) {
              changed = true
            }
          }
        })
      }

      if (changed) {
        updateTemplateTypes()
      }
    })

    // Observe adds and removes
    observer.observe(editorDiv, {
      subtree: true,
      childList: true,
    })

    // Update once
    updateTemplateTypes()

    return () => {
      observer.disconnect()
    }
  }, [updateTemplateTypes, editorDiv])

  return templateTypes
}
