import FroalaEditor from "froala-editor"
import { castArray } from "lodash"

/**
 * Makes the handlers for managing paste interactions in Froala.
 */
function makePasteHandlers() {
  const store = makePastedIdStore()

  return {
    /** Performs clean up of the pasted HTML content.
     * @param clipboardHTML The pasted content.
     */
    "paste.beforeCleanup": function (
      this: FroalaEditor,
      clipboardHTML: string
    ) {
      return editAsHtml(clipboardHTML, (elements) => {
        stripPositionStyles(elements)
        stripDuplicateIds(this, elements)

        store.storePastedId(elements[0]?.id ?? "")
      })
    },
    /** Performs paste editor adjustments after the paste is completed. */
    "paste.after": function (this: FroalaEditor) {
      store.restorePastedId(this)
    },
  }
}

export default makePasteHandlers

/**
 * Strips IDs from the given elements if they already exist in the editor.
 *
 * @param editor A FroalaEditor instance.
 * @param $elements The set of elements to modify.
 */
function stripDuplicateIds(
  editor: FroalaEditor,
  $elements: HTMLElement | HTMLElement[]
) {
  const editorIds = Array.from(
    getEditorElement(editor).querySelectorAll("[id]")
  ).map(($el) => $el.id)

  castArray($elements).forEach(($el) => {
    if (editorIds.includes($el.id)) {
      $el.id = ""
    }
  })
}

/**
 * Strips any inline position styles from each element.
 *
 * This helps to avoid any strange behavior with absolute positioned elements.
 *
 * @param $elements The set of elements to modify.
 */
function stripPositionStyles($elements: HTMLElement | HTMLElement[]) {
  castArray($elements).forEach(($el) => {
    $el.style.position = ""
  })
}

/**
 * Creates a store for pasted element IDs.
 *
 * Froala has a habit of clobbering the first ID with "isPasted". This mechanism
 * preserves a given ID and restores it after the paste operation is complete.
 */
function makePastedIdStore() {
  /** The current ID being stored. */
  let storedId: string = ""

  /**
   * Stores a given element ID.
   * @param id The element ID.
   */
  function storePastedId(id: string) {
    storedId = id
  }

  /**
   * Replaces the ID of a pasted element with the stored one.
   * @param editor A Froala instance.
   */
  function restorePastedId(editor: FroalaEditor) {
    const $el = getEditorElement(editor).querySelector('[id="isPasted"]')
    if (!$el) {
      return
    }

    $el.id = storedId ?? ""
  }

  return { storePastedId, restorePastedId }
}

/**
 * Transforms an HTML string with the given update function.
 *
 * The update function is provided a hydrated set of HTML elements for
 * manipulating. Once the update is complete the results are returned as a
 * string.
 *
 * @param html An HTML string.
 * @param updateFn The update function.
 *
 * @returns The updated HTML content.
 */
function editAsHtml(html: string, updateFn: (elements: HTMLElement[]) => void) {
  const $html = document.createElement("html")
  $html.innerHTML = html

  const $body = $html.querySelector("body")
  if (!$body) {
    return ""
  }

  updateFn(Array.from($body.children) as HTMLElement[])

  return $body.innerHTML
}

/**
 * Retrieves the editor DOM element from a Froala instance.
 * @param instance A Froala editor instance.
 */
function getEditorElement(instance: FroalaEditor): HTMLElement {
  return instance.$el[0]
}
