import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import AutoFixHigh from "@mui/icons-material/AutoFixHigh"
import { generateAltText } from "../api"

/**
 * AltTextEditor is a component that generates alternative text for an image or allows the user to input their own.
 * @param props - The properties passed to the component. See below for details.
 * @param props.imageUrl - The URL of the image for which to generate alternative text.
 * @param props.altText - The alternative text to be used for the image.
 * @param props.onChange - A callback function that is invoked when the alternative text is changed.
 */
export function AltTextEditor(props: {
  imageUrl: string
  altText: string
  onChange: (altText: string) => void
}) {
  const { imageUrl, altText, onChange } = props

  const [generatingAltText, setGeneratingAltText] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const destroyedRef = useRef(false)
  useEffect(() => {
    return () => {
      destroyedRef.current = true
    }
  }, [])

  /** Handle autogenerating alt text */
  const handleAutogenerate = () => {
    setGeneratingAltText(true)
    setError(null)
    generateAltText(imageUrl)
      .then((data) => {
        if (destroyedRef.current) return
        onChange(data.alt_text.replace(/\n/g, " "))
        setError(null)
      })
      .catch((err) => {
        if (destroyedRef.current) return
        setError("Error generating alt text. Please try again later.")
      })
      .finally(() => {
        if (destroyedRef.current) return
        setGeneratingAltText(false)
      })
  }

  return (
    <>
      <TextField
        label="Alt Text"
        fullWidth
        multiline
        value={altText}
        error={error != null}
        helperText={error}
        onChange={(e) => {
          setError(null)
          onChange(e.target.value.replace(/\n/g, " "))
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                onClick={() => {
                  handleAutogenerate()
                }}
                startIcon={
                  generatingAltText ? (
                    <CircularProgress size={20} />
                  ) : (
                    <AutoFixHigh />
                  )
                }
              >
                {generatingAltText ? "Generating..." : "Generate"}
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}
