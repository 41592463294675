import Froalaeditor from "froala-editor"

/**
 * Default Froala fonts
 */
const defaultFonts = {
  "": "Default",
  "Arial,Helvetica,sans-serif": "Arial",
  "Georgia, serif": "Georgia",
  "Impact, Charcoal, sans-serif": "Impact",
  "Tahoma,Geneva,sans-serif": "Tahoma",
  "Times New Roman, Times, serif": "Times New Roman",
  "Verdana, Geneva, sans-serif": "Verdana",
} as any

Froalaeditor.DefineIcon("fontFamily", {
  NAME: "fontFamily",
  SVG_KEY: "fontFamily",
})

/**
 * Register a custom font family dropdown. This allows us to
 * inject our own branding-defined custom fonts for use throughtout the
 * editing process.
 * @param branding - User branding configuration
 */
const registerFontFamilyCommand = (branding: any) => {
  // Include custom editor fonts along with body and heading font for usage in editor
  const brandingFonts = [
    ...branding.editorFonts,
    branding.heading,
    branding.body,
  ]
    .filter((font: any) => font?.name && font?.url)
    .reduce(
      // Froala config needs fonts as key-value pairs
      (acc: any, font: any) => ({
        ...acc,
        [font.name]: font.name,
      }),
      {}
    )

  const allFonts = {
    ...defaultFonts,
    ...brandingFonts,
  }

  Froalaeditor.RegisterCommand("customFontFamily", {
    type: "dropdown",
    title: "Font Family",
    icon: "fontFamily",
    refreshAfterCallback: true,
    options: allFonts,
    displaySelection: function (e: any) {
      return e.opts.fontFamilySelection
    },
    defaultSelection: function (t: any) {
      return t.opts.fontFamilyDefaultSelection
    },
    // Renders dropdown, including all fonts styled respectively
    html: function () {
      let html = '<ul class="fr-dropdown-list" role="presentation">'
      const fonts = allFonts

      for (let family in fonts) {
        fonts.hasOwnProperty(family) &&
          (html +=
            '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="fontFamily" data-param1="'
              .concat(family, '" \n        style="font-family: ')
              .concat(family, '" title="')
              .concat(fonts[family], '">')
              .concat(fonts[family], "</a></li>"))
      }
      return (html += "</ul>")
    },
    callback: function (t, e) {
      this.fontFamily.apply(e)
    },
    // Callback on refresh.
    refresh: function (t) {
      this.fontFamily.refresh(t)
    },
    // Callback on dropdown show.
    refreshOnShow: function (t, e) {
      this.fontFamily.refreshOnShow(t, e)
    },
    plugin: "fontFamily",
  })
}

export default registerFontFamilyCommand
