import axios from "./axios"

export interface LmsCourse {
  id: string
  url: string
  title: string
}

/**
 * Get published course from an lms
 * @param lmsName - name of lms
 * @param courseId - course to get
 */
const fetchLmsCourse = async (
  lmsName: string,
  courseId: string
): Promise<LmsCourse | null> => {
  try {
    const response = await axios.get(`/publish/${lmsName}/${courseId}/`)

    if (response.status === 200) {
      return response.data
    } else {
      return null
    }
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return null
    }
    throw error
  }
}

export default fetchLmsCourse
