import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Box, Button, Modal, Paper, Typography } from "@mui/material"
import CheckCircle from "@mui/icons-material/CheckCircle"
import LEAiIcon from "../../assets/images/welcome_person1.svg"

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#fff",
    padding: "2em 2rem",
    maxHeight: "95vh",
    overflowY: "auto",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
}))

export const TransformationSummary = (props) => {
  const classes = useStyles()
  const { showApplyTransformation } = props

  const handleClose = () => {
    props.onClose()
  }

  const body = (
    <Paper
      id="transformationSummaryContainer"
      className={classes.paper}
      onClick={handleClose}
    >
      <Box sx={{ flexDirection: "column", display: "flex" }}>
        <Box display="flex" justifyContent="center" sx={{ p: 1, mb: 1 }}>
          <Typography variant="h5" component="h1" sx={{ mr: 4 }}>
            Your course is ready!
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{ ml: 4 }}
            onClick={handleClose}
          >
            Edit and View Course
          </Button>
        </Box>

        <Box justifyContent="center" display="flex">
          <Box
            sx={{
              /**
               * Set height based on theme spacing
               * @param theme - MUI theme
               */
              height: (theme) => theme.spacing(26),
              /**
               * Set width from theme spacing
               * @param theme - MUI theme
               */
              width: (theme) => theme.spacing(26),
              position: "relative",
              overflow: "hidden",
              borderRadius: "50%",
            }}
          >
            <Box component="img" src={LEAiIcon} alt="Your course is ready!" />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" p={1.5} m={0.6}>
          <Typography variant="h6" component="h3">
            Here's what LEAi™ did for you:
          </Typography>
        </Box>
        {showApplyTransformation && (
          <Box display="flex" justifyContent="row" p={0.5}>
            <Typography variant="subtitle1" className={classes.wrapIcon}>
              <CheckCircle sx={{ mt: 0, mr: 1, color: "primary.main" }} />
              <b>LEAi™</b>&nbsp;&nbsp;transformed the source content into a
              course using learning best practice
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="row" p={0.5}>
          <Typography variant="subtitle1" className={classes.wrapIcon}>
            <CheckCircle sx={{ mt: 0, mr: 1, color: "primary.main" }} />
            <b>LearnAdvisor™</b>&nbsp;&nbsp;identified improvements for your
            course and will continue to guide you
          </Typography>
        </Box>

        <Box display="flex" justifyContent="row" p={0.5}>
          <Typography variant="subtitle1" className={classes.wrapIcon}>
            <CheckCircle sx={{ mt: 0, mr: 1, color: "primary.main" }} />
            <b>LearnAnalyzer™</b>&nbsp;&nbsp;calculated the length of your
            course and will update as you edit
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      onClose={handleClose}
    >
      {body}
    </Modal>
  )
}
