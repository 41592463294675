import axiosInstance from "../../../api/axios"

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetchLearningPaths = () => {
  return axiosInstance.get(`/paths`).then((response) => response.data)
}

export default fetchLearningPaths
