import { useCallback, useEffect, useState } from "react"
import ShareSettingsContext from "./ShareSettingsContext"
import ShareSettingsDispatchContext from "./ShareSettingsDispatchContext"
import PropTypes from "prop-types"
import { loadCourse, updateShareSettings } from "../../api"

/**
 * Provides the current share settings for a selected course.
 */
const ShareSettingsProvider = (props) => {
  const { course: courseProp, children } = props

  const [settings, setSettings] = useState(fromCourse(courseProp))

  const toggleIsPublic = useCallback(() => {
    // Optimistically update the flag...
    setSettings((settings) => {
      // ...then perform the request to get the real result.
      updateShareSettings(settings.id, { isPublic: !settings.isPublic }).then(
        (course) => setSettings(fromCourse(course))
      )

      return { ...settings, isPublic: !settings.isPublic }
    })
  }, [])

  //
  useEffect(() => {
    if (courseProp) {
      const settings = fromCourse(courseProp)

      setSettings(settings)
      loadCourse(settings.id).then(({ data: course }) =>
        setSettings(fromCourse(course))
      )
    }
  }, [courseProp])

  const dispatch = { toggleIsPublic }

  return (
    <ShareSettingsDispatchContext.Provider value={dispatch}>
      <ShareSettingsContext.Provider value={settings}>
        {children}
      </ShareSettingsContext.Provider>
    </ShareSettingsDispatchContext.Provider>
  )
}

ShareSettingsContext.propTypes = {
  /**
   * The selected course.
   *
   * The share settings for the course will be controlable from this context.
   */
  course: PropTypes.string,
  /**
   * Some child elements.
   */
  children: PropTypes.node,
}

export default ShareSettingsProvider

/**
 * Create settings from a course or course ID.
 */
const fromCourse = (course) => {
  if (!course?.id) {
    return { id: course, isPublic: false }
  }

  return {
    id: course.id,
    isPublic: course?.isPublic ?? course.is_public,
    publicLink: course?.publicLink ?? course.public_access_url,
  }
}
