import { Box, Link, Typography, Skeleton } from "@mui/material"
import WelcomeIcon from "../../assets/images/welcome_person1.svg"
import styled from "@emotion/styled"
import StyledPaper from "./StyledPaper"
import Button from "@mui/material/Button"
import { useHistory } from "react-router-dom"
import { PostAdd } from "@mui/icons-material"

const Arrow = styled("div")(({ theme }) => {
  return {
    width: 0,
    height: 0,
    borderTop: "20px solid transparent",
    borderBottom: "20px solid transparent",
    borderRight: `40px solid ${theme.palette.primary.light}`,
  }
})

/**
 * Component for displaying LEAi avatar icon and "Welcome!" message
 *
 * @param props - Props for component
 * @returns {JSX.Element}
 */
const LEAiCard = (props) => {
  const { onDismiss, displayHelpMessage, lastEdited, loadingLastEdited } = props

  const history = useHistory()

  /**
   * Navigate to most recently edited course in editor
   *
   * @param e - Click event
   */
  const jumpToCourse = (e) => {
    e.preventDefault()
    history.push(`/editor/?course=${lastEdited.id}`, {
      course: lastEdited,
    })
  }

  return (
    <>
      <Box
        sx={{
          /**
           * Set height based on theme spacing
           * @param theme - MUI theme
           */
          height: (theme) => theme.spacing(26),
          flex: "0 0",
          display: { xs: "none", sm: "block" },
          /**
           * Set flex basis from theme spacing
           * @param theme - MUI theme
           */
          flexBasis: (theme) => theme.spacing(26),
          position: "relative",
          overflow: "hidden",
          borderRadius: "50%",
        }}
      >
        <Box component="img" src={WelcomeIcon} alt="Create Your First Course" />
      </Box>
      <Box flex="2 1 30%" display="flex" alignItems="center" height="100%">
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Arrow />
        </Box>
        <StyledPaper elevation={0}>
          {!loadingLastEdited ? (
            <>
              {!lastEdited ? (
                <Box marginBottom="auto">
                  <Typography gutterBottom variant="h5">
                    Welcome!
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    My name is LEAi. I am here to guide you through creating
                    best-practices learning material.
                  </Typography>
                  {displayHelpMessage ? (
                    <Typography gutterBottom variant="body1">
                      To get started with LEAi, visit our{" "}
                      <Link
                        sx={{
                          /**
                           * Set colour of link to be dark primary theme
                           * @param theme - MUI theme
                           */
                          color: (theme) => theme.palette.primary.dark,
                        }}
                        href="/help"
                      >
                        Help Hub
                      </Link>{" "}
                      and watch the onboarding videos.
                    </Typography>
                  ) : (
                    <Typography gutterBottom variant="body1">
                      To get started, create your first course.
                    </Typography>
                  )}
                  <Box
                    marginTop="auto"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      size="small"
                      sx={(theme) => ({
                        color: theme.palette.primary.dark,
                      })}
                      onClick={onDismiss}
                    >
                      Dismiss
                    </Button>
                    <Button
                      sx={{
                        marginLeft: 2,
                      }}
                      startIcon={<PostAdd />}
                      color="tertiary"
                      size="small"
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault()
                        history.push("/create-course")
                      }}
                    >
                      Add Course
                    </Button>
                  </Box>
                </Box>
              ) : (
                <>
                  <Typography gutterBottom variant="h5">
                    Welcome back!
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    Last time you were making edits to{" "}
                    <b>{lastEdited?.title}</b>. Would you like to continue where
                    you left off?
                  </Typography>
                  <Box
                    marginTop="auto"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      size="small"
                      sx={(theme) => ({
                        color: theme.palette.primary.dark,
                      })}
                      onClick={onDismiss}
                    >
                      Dismiss
                    </Button>
                    <Button
                      sx={{
                        marginLeft: 2,
                      }}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={jumpToCourse}
                    >
                      Jump To Course
                    </Button>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton width="50%" />
            </>
          )}
        </StyledPaper>
      </Box>
    </>
  )
}

export default LEAiCard
