import { useState } from "react"
import Button from "@mui/material/Button"
import { StyledTextField } from "./Comment"
import useCommentsDispatch from "../../../hooks/useCommentsDispatch"
import useComment from "../../../hooks/useComment"
import { Box } from "@mui/material"

const EditComment = ({ commentId, color, setEditMode }) => {
  const { text } = useComment(commentId)
  const { editComment } = useCommentsDispatch()

  const [editText, setEditText] = useState(text)
  const [editError, setEditError] = useState(null)

  const submitEdit = (e) => {
    e.preventDefault()
    if (editText === "") {
      setEditError("Comment cannot be blank.")
      return
    }
    editComment(commentId, editText)
    setEditMode(false)
  }

  const cancelEdit = (e) => {
    e.preventDefault()
    setEditText(text)
    setEditMode(false)
  }

  return (
    <Box data-cy="edit-comment">
      <StyledTextField
        fullWidth
        label="Edit Comment"
        variant="standard"
        color={color}
        multiline
        error={editError != null}
        helperText={editError}
        onClick={(e) => e.stopPropagation()}
        onInput={(e) => {
          e.preventDefault()
          setEditText(e.target.value)
        }}
        value={editText}
      />
      <Box marginTop={2} display="flex" justifyContent="space-between">
        <Button color={color} onClick={submitEdit}>
          Submit
        </Button>
        <Button color="neutral" onClick={cancelEdit}>
          Cancel
        </Button>
      </Box>
    </Box>
  )
}

export default EditComment
