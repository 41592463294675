import { Redirect, Route, useLocation } from "react-router-dom"
import { useAuthenticator } from "@aws-amplify/ui-react"

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const location = useLocation()
  const { route } = useAuthenticator((context) => [context.route])

  return (
    <Route
      {...otherProps}
      render={(routeProps) =>
        route === "authenticated" ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
