import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

import { SearchOutlined, Toc } from "@mui/icons-material"

/**
 * Sheet for testing new UX on components
 */
const AssetSheet = () => {
  return (
    <>
      <Stack padding={4} alignItems="start" gap={2}>
        <Typography variant="h1">Display Large</Typography>
        <Typography variant="h2">Display Medium</Typography>
        <Typography variant="h3">Display Small</Typography>

        <Button startIcon={<Toc />}>Label</Button>
        <Button startIcon={<Toc />} variant="contained">
          Label
        </Button>
        <Button variant="contained">Button</Button>
        <Button startIcon={<Toc />} variant="outlined" color="secondary">
          Label
        </Button>
        <p>Disabled</p>
        <Button disabled startIcon={<Toc />}>
          Label
        </Button>
        <Button disabled startIcon={<Toc />} variant="contained">
          Label
        </Button>
        <Button disabled startIcon={<Toc />} variant="outlined">
          Label
        </Button>
        <IconButton>
          <Toc />
        </IconButton>

        <TextField
          id="outlined-basic"
          label="Outlined"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Stack justifyContent="center" padding="8px">
                  <SearchOutlined />
                </Stack>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  )
}

export default AssetSheet
