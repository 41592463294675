import React from "react"
import { Box, TextField, Typography } from "@mui/material"
import { PickersDay, StaticDatePicker } from "@mui/x-date-pickers"
import { styled } from "@mui/material/styles"
import { DateTime } from "luxon"
import { useTheme } from "@emotion/react"
import Divider from "@mui/material/Divider"

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" &&
    prop !== "isFirstDay" &&
    prop !== "isLastDay" &&
    prop !== "isToday",
})(({ theme, dayIsBetween, isFirstDay, isLastDay, isToday }) => ({
  ...(dayIsBetween && {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "50%",
  }),
  ...(isToday && {}),
  ...((isFirstDay || isLastDay) && {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
}))

const CustomDateRange = (props) => {
  const { startChange, endChange } = props

  const start =
    props.start != null ? DateTime.fromISO(props.start).startOf("day") : null
  const end =
    props.end != null ? DateTime.fromISO(props.end).startOf("day") : null
  const theme = useTheme()

  const renderDayRange = (date, pickersDayProps, calendar) => {
    const dayIsBetween =
      start != null && end != null && start <= date && date <= end

    const isFirstDay = start != null && date.equals(start)
    const isLastDay = end != null && date.equals(end)

    const { outsideCurrentMonth } = pickersDayProps

    const roundedRight =
      isFirstDay ||
      (dayIsBetween &&
        (date.equals(date.startOf("week")) ||
          date.equals(date.startOf("month"))))

    const roundedLeft =
      isLastDay ||
      (dayIsBetween &&
        (date.equals(date.endOf("month").startOf("day")) ||
          date.equals(date.endOf("week").startOf("day"))))

    const isDisabled =
      (start != null && end != null && calendar === "start" && date > end) ||
      (start != null && end != null && calendar === "end" && date < start)

    const isToday = date.equals(DateTime.now().startOf("day"))

    return (
      <Box
        key={pickersDayProps.key}
        sx={{
          ...(dayIsBetween &&
            !outsideCurrentMonth && {
              borderRadius: 0,
              backgroundColor: theme.palette.primary.light,
            }),
          ...(roundedRight && {
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
          }),
          ...(roundedLeft && {
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
          }),
        }}
      >
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          disabled={isDisabled}
          dayIsBetween={dayIsBetween}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
          isToday={isToday}
        />
      </Box>
    )
  }

  return (
    <Box>
      <Box display="flex">
        <Box marginTop={3}>
          <Typography marginLeft={3} fontWeight="bold">
            Start
          </Typography>

          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            label="Start"
            openTo="day"
            value={start}
            onChange={(val) => startChange(val.startOf("day"))}
            renderDay={(date, selectedDates, pickersDayProps) =>
              renderDayRange(date, pickersDayProps, "start")
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Divider flexItem orientation="vertical">
          to
        </Divider>
        <Box marginTop={3}>
          <Typography marginLeft={3} fontWeight="bold">
            End
          </Typography>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            label="End"
            openTo="day"
            value={end}
            renderDay={(date, selectedDates, pickersDayProps) =>
              renderDayRange(date, pickersDayProps, "end")
            }
            onChange={(val) => endChange(val.endOf("day"))}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CustomDateRange
