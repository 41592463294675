import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { Edit } from "@mui/icons-material"

interface ImageUploadControlProps {
  title: string
  imageUrl: string | null
  maxWidth?: number
  onChange: (imageUrl: string | null, file: any) => void
}

/**
 * Component for uploading image files and previewing them
 *
 * @param props - Component props
 * @param props.title Dialog Title
 * @param props.imageUrl Starting image
 * @param props.maxWidth Width of drop box
 * @param props.onChange Callback when changed

 */
const OldImageUploadControl = ({
  title,
  imageUrl,
  maxWidth,
  onChange,
}: ImageUploadControlProps) => {
  /**
   * Handle file upload of image. Create a URL to display it to the user and
   * store the file object itself in the branding.changes object in the state.
   *
   * @param event - File input event
   */
  const onAdd = (event: any) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const imageUrl = URL.createObjectURL(file)
      onChange(imageUrl, file)
    }
  }

  return (
    <Stack flex="1 1 0">
      <Typography gutterBottom variant="titleMedium">
        {title}
      </Typography>
      <Box
        sx={(theme) => ({
          display: "flex",
          height: theme.spacing(24),
          padding: "24px",
          maxWidth: maxWidth != null ? theme.spacing(maxWidth) : "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "2px dashed",
          borderColor: theme.scheme.outlineVariant,
          color: "text.secondary",
          borderRadius: "24px",
          position: "relative",
        })}
      >
        {imageUrl ? (
          <>
            <Box
              gap={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img alt="not found" height="100rem" src={imageUrl} />
              <Button
                onClick={() => {
                  onChange(null, "null")
                }}
              >
                Remove
              </Button>
            </Box>
            <Tooltip title={"Edit image"}>
              <IconButton
                component="label"
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  right: "-10px",
                  color: "lexWhite",
                  backgroundColor: "primary.main",
                  "&:hover, &.Mui-focusVisible": {
                    backgroundColor: "primary.main",
                  },
                }}
              >
                <Edit />
                <input hidden type="file" accept="image/*" onChange={onAdd} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Button variant="contained" component="label">
            <input hidden type="file" accept="image/*" onChange={onAdd} />
            Choose file
          </Button>
        )}
      </Box>
    </Stack>
  )
}

export default OldImageUploadControl
