import { styled } from "@mui/material"
import MuiTextField from "@mui/material/TextField"
import PropTypes from "prop-types"
import { useCallback, useEffect, useState } from "react"

const TextField = styled(MuiTextField)(({ InputProps }) => ({
  ".MuiInput-root": {
    "&:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0)",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    },
  },
  ".MuiInput-input": {
    padding: "5px 0 4px",
    fontWeight: "500",
    ...(InputProps.readOnly && { cursor: "default" }),
  },
}))

const useInputState = (options) => {
  const { title, onTitleChange } = options

  const [value, setValue] = useState(title)
  useEffect(() => setValue(title), [title])

  const inputProps = {
    value,
    onChange: useCallback((event) => setValue(event.target.value), []),
    onBlur: useCallback(() => {
      if (title !== value) {
        onTitleChange(value)
      }
    }, [title, value, onTitleChange]),
    onKeyDown: useCallback((event) => {
      if (event.key === "Enter") {
        event.preventDefault()
        event.target.blur()
      }
    }, []),
  }

  return { inputProps }
}

/** TitleTextField displays an input for renaming course documents. */
const TitleTextField = (props) => {
  const { title, onTitleChange, readOnly, ...otherProps } = props
  const { inputProps } = useInputState({ title, onTitleChange })

  return (
    <TextField
      fullWidth
      data-cy="course-title-input"
      variant="standard"
      {...otherProps}
      {...inputProps}
      InputProps={{ readOnly, disableUnderline: readOnly }}
    />
  )
}

TitleTextField.propTypes = {
  title: PropTypes.string,
  onTitleChange: PropTypes.func,
}

TitleTextField.defaultProps = {
  title: "",
  onTitleChange: () => {},
}

export default TitleTextField
