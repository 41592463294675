import { createSelector } from "reselect"
import { selectAllJobs } from "./jobsEntityAdaptor"

/**
 * Select the timestamp for the latest job update recorded in the store.
 * @type {(state: any) => string}
 */
const selectLatestJobUpdate = createSelector(selectAllJobs, (jobs) => {
  if (jobs.length === 0) {
    return null
  }

  return jobs.reduce(
    (latest, job) =>
      latest < job.last_update_datetime ? job.last_update_datetime : latest,
    jobs[0].last_update_datetime
  )
})

export default selectLatestJobUpdate
