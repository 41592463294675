import remarksAdaptor from "../remarksAdaptor"

/**
 * Select the remark ID given the canonical ID of a detection.
 *
 * @param {string} id
 */
const selectDetectionId = (id) =>
  remarksAdaptor.selectId({ id, remarkType: "detection" })

export default selectDetectionId
