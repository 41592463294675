import PageLayout from "../layouts/PageLayout"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  InputAdornment,
  CircularProgress,
} from "@mui/material"
import { useState } from "react"
import Button from "@mui/material/Button"
import { useGiveCourse } from "../../hooks/useGiveCourse"

const ConfirmTransferDialog = ({ confirmGive, onClose, ...otherProps }) => {
  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} {...otherProps}>
      <DialogTitle>Confirm Change Ownership</DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>
          Do you want to permanently change ownership of this course?
        </DialogContentText>
        <Alert sx={{ marginTop: 2 }} severity="warning">
          When you make this change, you will no longer be able to view and edit
          this course.
        </Alert>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={confirmGive}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

const GiveCourseScreen = (props) => {
  const { breadcrumbs = [], course } = props

  const {
    email,
    isLoading,
    checkValidEmail,
    emailValid,
    giveCourse,
    helperText,
    courseOptions,
  } = useGiveCourse()

  const [selectedCourse, setSelectedCourse] = useState(course)
  const [emailInput, setEmailInput] = useState("")
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  /**
   * When the "Give" button is pressed, confirm the user's decision and make the
   * POST request to the backend
   */
  const handleGiveSubmit = (e) => {
    e.preventDefault()
    setConfirmDialogOpen(false)
    giveCourse(selectedCourse)
    setSelectedCourse(null)
    setEmailInput("")
  }

  // We only want the "Give" button clickable if the email is deemed valid
  const submitEnabled =
    emailValid && email !== "" && selectedCourse && !isLoading
  const error = !emailValid && email !== "" && !isLoading

  return (
    <PageLayout
      fixed
      maxWidth="md"
      breadcrumbs={[...breadcrumbs, { label: "Give Course" }]}
    >
      <Typography variant="h3" component="h1">
        Give Course
      </Typography>
      <Typography variant="body" gutterBottom>
        Permanently change the owner of this course to another user.
      </Typography>
      <FormControl sx={{ marginBottom: 5 }} variant="standard" fullWidth>
        <InputLabel>Course</InputLabel>
        <Select
          onChange={(e) => setSelectedCourse(e.target.value)}
          label="Course"
          value={selectedCourse ?? ""}
        >
          {courseOptions.map((course) => (
            <MenuItem key={course.id} value={course.id}>
              {course.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <TextField
          onBlur={(e) => checkValidEmail(e.target.value)}
          label="LEAi User"
          error={error}
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
          helperText={helperText}
          InputProps={{
            endAdornment: isLoading && (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <Alert sx={{ marginBottom: 2, marginTop: 2 }} severity="warning">
        When you make this change, you will no longer be able to view and edit
        this course.
      </Alert>
      <Box variant="contained" display="flex" justifyContent="center">
        <Button
          onClick={() => setConfirmDialogOpen(true)}
          variant="contained"
          disabled={!submitEnabled}
        >
          Give Course
        </Button>
      </Box>
      <ConfirmTransferDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        confirmGive={handleGiveSubmit}
      />
    </PageLayout>
  )
}

export default GiveCourseScreen
