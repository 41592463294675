import {
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material"
import useId from "@mui/material/utils/useId"
import { motion } from "framer-motion"
import { forwardRef } from "react"
import { IMaskInput } from "react-imask"
import useValidationError from "../hooks/useValidationError"

/**
 * Renders an input field for one-time-codes.
 */
export function CodeField() {
  const id = useId()
  const helperId = `${id}-helper`

  const error = useValidationError("confirmation_code")

  return (
    <FormControl
      error={!!error}
      component={motion.div}
      layout="position"
      variant="filled"
    >
      <InputLabel htmlFor={id}>Code</InputLabel>
      <FilledInput
        id={id}
        name="confirmation_code"
        autoComplete="one-time-code"
        inputComponent={MaskedInput as any}
        aria-describedby={error ? helperId : undefined}
      />
      {error && <FormHelperText id={helperId}>{error}</FormHelperText>}
    </FormControl>
  )
}

const MaskedInput = forwardRef<HTMLInputElement>((props, ref) => {
  return <IMaskInput mask="000000" inputRef={ref} overwrite {...props} />
})
