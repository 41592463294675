import { DateTime } from "luxon"
import axiosInstance from "./axios"

/**
 * @typedef {{
 *  id: string
 *  courseId: string
 *  as?: { email: string }
 * }} ResolveCommentArg
 */

/**
 * Mark a course comment as resolved.
 *
 * @param {ResolveCommentArg} arg
 */
const resolveComment = (arg) => {
  const { id, courseId, as } = arg

  return axiosInstance
    .post(`/courses/${courseId}/comments/${id}/resolve`, null, {
      params: { email: as?.email },
    })
    .then(() => ({
      id,
      changes: { resolvedOn: DateTime.now().toISO(), resolveBy: as },
    }))
}

export default resolveComment
