import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { keyBy, map } from "lodash/fp"
import * as api from "../features/intelligent-updates/api"
import { useFlag } from "../utilities/feature-management"

/** @typedef {string} ID */

/** @typedef {Object} IntelligentUpdate */

/** @typedef {Object} IntelligentUpdateTarget */

/** @typedef {Object.<UUID, IntelligentUpdate>} IntelligentUpdateCollection */

/** @typedef {Object.<UUID, IntelligentUpdateTarget>} TargetCollection */

/**
 * @typedef {Object} UseFetchIntelligentUpdatesResult
 * @property {ID[]} allTargetIDs
 * @property {ID[]} allUpdateIDs
 * @property {TargetCollection} targetsByID
 * @property {IntelligentUpdateCollection} updatesByID
 * @property {Error?} error
 * @property {boolean} loading
 */

const DEFAULT_DATA = {
  allUpdateIDs: [],
  updatesByID: {},
  allTargetIDs: [],
  targetsByID: {},
}

/**
 * Fetch the list of intelligent updates for a course.
 *
 * @returns {UseFetchIntelligentUpdatesResult}
 */
const useFetchIntelligentUpdates = (courseID) => {
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const fetchUpdates = useFlag("rollout-serverless-intelligent-updates")
    ? api.fetchUpdates
    : api.fetchUpdatesLegacy

  useEffect(() => {
    if (!courseID) {
      return
    }

    ;(async () => {
      try {
        const { updates, targets } = await fetchUpdates(courseID)

        setData({
          allUpdateIDs: map("id", updates),
          updatesByID: keyBy("id", updates),
          allTargetIDs: map("id", targets),
          targetsByID: keyBy("id", targets),
        })
      } catch (error) {
        setError(error)
      }
    })()
  }, [courseID, fetchUpdates])

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Could not retrieve intelligent updates.")
    }
  }, [error, enqueueSnackbar])

  return {
    ...DEFAULT_DATA,
    ...data,
    error,
    loading: !data && !error,
  }
}

export default useFetchIntelligentUpdates
