import { useEffect, useState } from "react"

/**
 * A TypeScript version of useState that persists the value to localStorage in the key specified.
 * @param key - The key to use when storing the value in localStorage.
 * @param defaultValue - The default value to use when the key is not found in localStorage.
 * @param overrides - An object containing values to override the persisted state with. Can also be a function that takes the current state and returns an object to override the persisted state with.
 * @returns A stateful value, and a function to update it.
 */
export function usePersistedState<T extends object>(
  key: string,
  defaultValue: T,
  overrides?: Partial<T> | ((value: T) => Partial<T>)
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(() => {
    const storedValue = window.localStorage.getItem(key)
    const value = storedValue !== null ? JSON.parse(storedValue) : null
    const mergedValue = { ...defaultValue, ...value }
    return typeof overrides === "function"
      ? { ...mergedValue, ...overrides(mergedValue) }
      : { ...mergedValue, ...overrides }
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, setState]
}
