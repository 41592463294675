import React from "react"
import { ThemeProvider } from "@mui/material"
import useCourseTheme from "../features/branding/hooks/useCourseTheme"

interface CustomerThemeProviderProps {
  courseID: string
  children: React.ReactNode
}

/**
 * Provider for customer theme context, allowing editor elements
 * to be styled with branding colours
 * @param props Component props
 * @param props.courseID Course to obtain branding
 * @param props.children Children consuming the Customer Theme context
 */
export const CustomerThemeProvider = ({
  children,
  courseID,
}: CustomerThemeProviderProps) => {
  const { theme } = useCourseTheme(courseID)
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
