import { DateTime } from "luxon"

export function getTitle(raw_title) {
  let title
  switch (raw_title) {
    case "COURSE TITLE PLACEHOLDER":
      title = "Untitled"
      break
    case "":
      title = "Untitled"
      break
    case null:
      title = "Untitled"
      break
    case undefined:
      title = "Untitled"
      break
    default:
      title = raw_title
      break
  }

  return title
}

export function getDate(document) {
  if (document.status !== "Completed" || document.last_update == null) {
    return ""
  }
  return formatDate(document.last_update)
}

export const getLastUpdated = (date) => {
  if (date == null) {
    return ""
  }
  return "Updated " + isoStringToRelativeTime(date)
}

/**
 * Get timeAgo with trashed label
 * @param date date timeAgo trashed
 */
export const getTrashed = (date) => {
  if (date == null) {
    return ""
  }
  return "Trashed " + isoStringToRelativeTime(date)
}

/**
 * Get timeAgo with no label
 * @param date date timeAgo trashed
 */
export const getTimeAgo = (date) => {
  if (date == null) {
    return ""
  }
  return isoStringToRelativeTime(date)
}

/**
 * Get ISO string to relative timeAgo
 * @param isoString date isoString
 */
export function isoStringToRelativeTime(isoString) {
  if (isoString == null) {
    return ""
  }

  const date = DateTime.fromISO(isoString)
  const { values } = DateTime.now().diff(date, ["days", "hours", "minutes"])

  const days = Math.round(values.days)
  const hours = Math.round(values.hours)
  const minutes = Math.round(values.minutes)

  if (days === 0 && hours <= 0 && minutes < 2) {
    return "just now"
  } else if (days > 30) {
    return date.toLocaleString()
  } else {
    return date.toRelative({ unit: ["days", "hours", "minutes"] })
  }
}

/**
 * Format a date string for with date and time
 * @param date - date to format
 */
export const formatDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
}

/**
 * Format a date string for a dated file name
 */
export const formatFileDate = () => {
  return DateTime.now().toFormat("yyyy-MM-dd")
}

export const datePresetEnum = {
  CUSTOM: "CUSTOM",
  TODAY: "TODAY",
  YESTERDAY: "YESTERDAY",
  THIS_WEEK: "THIS_WEEK",
  LAST_WEEK: "LAST_WEEK",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  ALL_TIME: "ALL_TIME",
}

/**
 *
 * Function to convert a string representation of a  date preset, such as TODAY or LAST_WEEK, into a usable filter object
 * which is passed to the useAuditHistory hook to be persisted and used for querying the backend for history records.
 *
 * Dates are tracked in the useAuditHistory hook using 3 properties:
 *      date: {The actual string name of the preset, it will be one of the datePresetEnums}
 *      filterSince: {A Luxon DateTime indicating the start date}
 *      filterBefore: {A Luxon DateTime indicating the end date}
 *
 * This function receives a string of the date preset and returns the usable filter object containing the 3 above properties
 *
 * If the current datetime was Dec 25, 2021 23:59:59, the following examples apply
 *
 * @example
 * convertPresetToRange("TODAY")
 * // returns
 *  {
 *   date: "TODAY",
 *   filterSince: {Luxon DateTime at 12/25/2021 at 0:00:00}
 *   filterBefore: {Luxon DateTime at 12/25/2021 at 23:59:59}
 * }
 *
 * @example
 * convertPresetToRange("YESTERDAY")
 * // returns
 *  {
 *   date: "YESTERDAY",
 *   filterSince: {Luxon DateTime at 12/24/2021 at 0:00:00}
 *   filterBefore: {Luxon DateTime at 12/24/2021 at 23:59:59}
 * }
 *
 * @example
 * convertPresetToRange("THIS_MONTH")
 * // returns
 *  {
 *   date: "THIS_MONTH",
 *   filterSince: {Luxon DateTime at 12/01/2021 at 0:00:00}
 *   filterBefore: {Luxon DateTime at 12/31/2021 at 23:59:59}
 * }
 *
 *
 * @param value - A string which is one of the enumerable date presets above (such as CUSTOM, ALL_TIME, or THIS_WEEK)
 * @returns {{date : string, filterSince: string, filterBefore: string}|  - An object with three properties which combine to filter by a specific range when fetching the history
 */
export const convertPresetToRange = (value) => {
  let before, since, last
  const current = DateTime.now()
  if (value != null && value !== "") {
    switch (value) {
      case datePresetEnum.TODAY:
        before = current

        // start date is the current date at 0:00:00
        since = current.startOf("day")
        break
      case datePresetEnum.THIS_WEEK:
        before = current

        // start date is the Monday of whatever current week it is
        since = current.startOf("week")
        break
      case datePresetEnum.THIS_MONTH:
        before = current

        // start date is the first day of whatever current month it is
        since = current.startOf("month")
        break
      case datePresetEnum.YESTERDAY:
        // get  DateTime object of exactly one day ago
        last = DateTime.now().minus({ days: 1 })

        // set the start of the interval to be the start of whatever the previous day was
        since = last.startOf("day")

        // set the end of the interval to be the same day at the very end
        before = since.endOf("day")
        break
      case datePresetEnum.LAST_WEEK:
        // get DateTime object of exactly one week ago
        last = DateTime.now().minus({ week: 1 })

        // set start of interval to be start of whatever the previous week was
        since = last.startOf("week")

        // set the end interval to be the following Monday
        before = since.endOf("week")

        break
      case datePresetEnum.LAST_MONTH:
        // get DateTime object of exactly one month ago
        last = DateTime.now().minus({ months: 1 })

        // set the start of the interval to be the first day of whatever last month was
        since = last.startOf("month")

        // set the end of the interval to be end of previous month
        before = since.endOf("month")
        break
      default:
        break
    }
    return {
      preset: value,
      end: before.toISO(),
      start: since.toISO(),
    }
  } else {
    return {
      preset: null,
      start: null,
      end: null,
    }
  }
}
/**
 *
 * Receives an object with all currently applied filters, and converts them into a URL query string for usage in sending requests to the backend
 *
 * @example
 * // returns URLSearchParams appearing as "?page=0&limit=20&filterActor=me&filterAction=course_saved"
 * convertObjectToQuery(
 * {
 *   filterAction: {label: 'Course saved', id: 'course_saved'},
 *   filterActor: {label: 'Just Me', id: 'me'},
 *   page: 0,
 *   limit: 20,
 * }, false)
 *
 *
 * @param criteria - Object containing all active filters being applied
 * @param isRequest - Whether this query is used for being sent to the backend as a request, or just being persisted in the URL router history
 * @returns {URLSearchParams} - Encoded URL search parameters which with all active filters with their respective values
 */
export const convertObjectToQuery = (criteria, isRequest) => {
  const params = new URLSearchParams({})

  for (const field in criteria) {
    const value = criteria[field]
    if (value != null) {
      switch (field) {
        case "page":
        case "limit":
        case "search":
        case "filterBefore":
        case "filterSince":
          if (value !== "") {
            params.set(field, value)
          }
          break
        case "back":
        case "title":
        case "courseId":
          if (!isRequest) {
            params.set(field, value)
          }
          break
        case "date":
          params.set("date", value)
          break
        default:
          // if we have selected ALL, it's the same as applying no filter to field
          if (value.id !== "ALL") {
            params.set(field, value.id)
          }
          break
      }
    }
  }
  return params
}
