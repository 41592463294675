import { PasswordField } from "../fields"
import { useFormHandlers } from "../hooks/useFormHandlers"
import { BackToSignIn, RemoteErrorMessage, SubmitButton } from "../shared"
import Form from "./Form"

/**
 * Displays the form and fields for forceing a new sign-in password.
 */
export function ForceNewPassword() {
  const { handleChange, handleSubmit } = useFormHandlers()

  return (
    <Form method="post" onChange={handleChange} onSubmit={handleSubmit}>
      <PasswordField
        label="New Password"
        inputProps={{ name: "password", autoComplete: "new-password" }}
      />
      <PasswordField
        label="Confirm Password"
        inputProps={{ name: "confirm_password", autoComplete: "new-password" }}
      />
      <RemoteErrorMessage />
      <Form.ActionBar>
        <SubmitButton />
        <BackToSignIn />
      </Form.ActionBar>
    </Form>
  )
}
