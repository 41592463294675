import { ItemsState } from "../types"

/**
 * Retrieve all entities for a given folder in order
 * @param state - Current state
 * @param folder - Folder to retrieve entities for
 */
const selectItemsForFolder = (state: ItemsState, folder: string) => {
  const { ids, entities } = state

  const list = ids[folder] ?? []

  return list.map((id) => entities[id])
}

export default selectItemsForFolder
