import { Button, Stack, Theme } from "@mui/material"
import ListContainer from "../../itemList/ListContainer"
import PageLayout from "../../layouts/PageLayout"
import useBranding from "../../../branding/hooks/useBranding"
import BrandingForm from "./components/BrandingForm"
import { SaveOutlined } from "@mui/icons-material"

/**
 * Page for showing branding options
 * @param props - Component props
 * @param props.location - Router location
 */
const BrandingPage = (props: { location: any }) => {
  const { location } = props
  const { saveBranding, pendingChanges, loading, ...branding } = useBranding()

  return (
    <PageLayout navRail location={location}>
      <ListContainer
        title="Branding"
        controls={
          <Button
            startIcon={<SaveOutlined />}
            onClick={saveBranding}
            variant="contained"
            disabled={!pendingChanges}
          >
            Save branding
          </Button>
        }
      >
        <Stack
          width="100%"
          padding="24px"
          sx={(theme: Theme) => ({
            backgroundColor: theme.scheme.onPrimary,
            overflowX: "hidden",
            overflow: "auto",
            flex: "1 1 0",
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          })}
        >
          <BrandingForm {...branding} />
        </Stack>
      </ListContainer>
    </PageLayout>
  )
}

export default BrandingPage
