import selectCommentsUser from "./selectCommentsUser"

/**
 *
 * @param {import("../..").AppState} state
 * @returns {boolean}
 */
const selectIsAuthorizedToComment = (state) => !!selectCommentsUser(state)

export default selectIsAuthorizedToComment
