import { alpha, styled } from "@mui/material"
import { Box } from "@mui/material"
import { ReactNode } from "react"
import ReactDOM from "react-dom"
import { TransformPopover } from "./TransformPopover"
import { correctRange } from "./transformUtils"
import { TransformType } from "./SelectTransformTypePopover"

/** Displays a popover with options to transform the selected content
 * @param options - The options
 * @param options.selection - The selected content
 * @param options.displayReactElement - Function to display a React element
 * @param options.editorContainer - The editor container (fr-element)
 * @param options.editor - The editor
 * @param options.transformType - Optional transform type to use
 * @param options.onClose - Function to call when the popover is closed.
 * Called with true if the rewrite/transformation was successful, false otherwise.
 */
export function transformSelection(options: {
  selection: Range | HTMLElement
  displayReactElement: (displayFunc: (onClose: () => void) => ReactNode) => void
  editorContainer: HTMLElement
  editor: any
  /** Optional transform type to use */
  transformType?: TransformType
  onClose?: (completed: boolean) => void
}) {
  const {
    selection,
    displayReactElement,
    editorContainer,
    editor,
    transformType,
    onClose,
  } = options

  // Create range from selection
  let range: Range

  if (selection instanceof Range) {
    range = selection
  } else {
    range = document.createRange()
    range.selectNodeContents(selection)
  }

  // Correct range
  range = correctRange(range, editorContainer)

  // Unselect selected content
  const sel = window.getSelection()
  if (sel) {
    sel.removeAllRanges()
  }

  // Get editor wrapper
  const editorWrapper = editorContainer.parentElement!

  // Get vertical offset of popover to be at the bottom of the range
  let verticalOffset = 0
  const rangeRect = range.getBoundingClientRect()
  const editorWrapperRect = editorWrapper.getBoundingClientRect()
  verticalOffset =
    rangeRect.bottom - editorWrapperRect.top + editorWrapper.scrollTop + 10

  // Get rectangles of the range relative to the editor wrapper
  const rects = mergeRectangles(
    Array.from(range.getClientRects()).map((rect) => {
      return {
        top: rect.top - editorWrapperRect.top + editorWrapper.scrollTop,
        left: rect.left - editorWrapperRect.left + editorWrapper.scrollLeft,
        width: rect.width,
        height: rect.height,
      }
    })
  )

  // Create portal
  displayReactElement((onCloseReactElement) => {
    return ReactDOM.createPortal(
      <>
        {Array.from(rects).map((rect, index) => (
          <HighlightedRectangle
            key={index}
            sx={{
              position: "absolute",
              top: rect.top + window.scrollY,
              left: rect.left + window.scrollX,
              width: rect.width,
              height: rect.height,
            }}
          />
        ))}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: editorWrapper.scrollHeight,
            backgroundColor: "transparent",
            zIndex: 9999,
          }}
          onClick={() => {
            onCloseReactElement()
            onClose?.(false)
          }}
        />
        <div
          style={{
            position: "absolute",
            top: verticalOffset,
            left: 20,
            right: 20,
            zIndex: 10000,
          }}
        >
          <TransformPopover
            editor={editor}
            editorWrapper={editorWrapper}
            onClose={(completed) => {
              onCloseReactElement()
              onClose?.(completed)
            }}
            range={range}
            transformType={transformType}
          />
        </div>
      </>,
      editorWrapper
    )
  })
}

/**
 * Merges overlapping and adjacent rectangles.
 * @param rectangles - The array of rectangles to merge.
 * @returns The merged array of rectangles.
 */
function mergeRectangles(
  rectangles: Array<{
    top: number
    left: number
    width: number
    height: number
  }>
) {
  if (rectangles.length === 0) return []

  // Sort rectangles by top and then by left
  rectangles.sort((a, b) => a.top - b.top || a.left - b.left)

  const merged = [rectangles[0]]

  for (let i = 1; i < rectangles.length; i++) {
    const last = merged[merged.length - 1]
    const current = rectangles[i]

    // Check if current rectangle overlaps or is adjacent to the last merged rectangle
    if (
      current.top <= last.top + last.height &&
      current.left <= last.left + last.width
    ) {
      // Merge the rectangles
      last.top = Math.min(last.top, current.top)
      last.left = Math.min(last.left, current.left)
      last.width =
        Math.max(last.left + last.width, current.left + current.width) -
        last.left
      last.height =
        Math.max(last.top + last.height, current.top + current.height) -
        last.top
    } else {
      merged.push(current)
    }
  }

  return merged
}

/**
 * Rectangle to show the selected range which will be rewritten.
 * Fades in.
 */
const HighlightedRectangle = styled(Box)(({ theme }) => ({
  zIndex: 9999,
  mixBlendMode: "multiply",
  animation: "fadeIn 0.3s ease-out forwards",
  backgroundColor: alpha((theme.palette as any).tertiary.main, 0.3),
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}))
