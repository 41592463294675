import React from "react"
import { Button, Tooltip, IconButton, Typography } from "@mui/material"
import PropTypes from "prop-types"
import ConfirmDialog from "./ConfirmDialog"
import { Cancel, DeleteForever } from "@mui/icons-material"

/**
 * Icon button to display when course is uploading
 * @param props - Component props
 */
export const CourseCancelUploadIconButton = (props) => {
  const { onCancel } = props

  return (
    <Tooltip placement="top" arrow title="Cancel upload">
      <IconButton
        color="error"
        variant="outlined"
        onClick={(e) => {
          e.preventDefault()
          onCancel()
        }}
      >
        <Cancel />
      </IconButton>
    </Tooltip>
  )
}

/**
 * Standard button with text for when course is uploading
 * @param props -Component props
 */
export const CourseCancelUploadButton = (props) => {
  const { onCancel } = props

  return (
    <Button
      color="error"
      variant="outlined"
      startIcon={<DeleteForever />}
      onClick={(e) => {
        e.preventDefault()
        onCancel()
      }}
    >
      Cancel
    </Button>
  )
}

export const CourseCancelUploadDialog = (props) => {
  const { onConfirmCancelUpload, document } = props

  return (
    <>
      {document && (
        <ConfirmDialog
          title={"Cancel Course Creation"}
          message={
            <Typography variant="body1" align="justify">
              {"Are you sure you want to cancel course creation?"}
            </Typography>
          }
          cancelText={"No, Continue"}
          confirmText={"Yes, Cancel"}
          onCancel={(e) => {
            e.preventDefault()
            onConfirmCancelUpload(null)
          }}
          onConfirm={(e) => {
            e.preventDefault()
            onConfirmCancelUpload(document)
          }}
        />
      )}
    </>
  )
}

const CourseCancelUploadControl = (props) => {
  const { onCancel, complete, courseId } = props
  const [confirmCancel, setConfirmCancel] = React.useState(false)
  const [canceled, setCanceled] = React.useState(false)

  const onConfirmCancelUpload = async (document) => {
    setConfirmCancel(false)
    if (document != null) {
      setCanceled(true)
      await onCancel()
    }
  }

  const onCancelButton = async () => {
    setConfirmCancel(true)
  }

  return (
    <>
      {!canceled && (
        <CourseCancelUploadButton onCancel={() => onCancelButton()} />
      )}
      {confirmCancel && !complete && (
        <CourseCancelUploadDialog
          document={{ id: courseId }}
          onConfirmCancelUpload={onConfirmCancelUpload}
        />
      )}
    </>
  )
}

CourseCancelUploadControl.propTypes = {
  courseId: PropTypes.string,
  complete: PropTypes.bool,
  onCancel: PropTypes.func,
}

export default CourseCancelUploadControl
