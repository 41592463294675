import { isArray, map, set, intersection } from "lodash/fp"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useState } from "react"
import useFetchIntelligentUpdates from "./useFetchIntelligentUpdates"
import * as api from "../features/intelligent-updates/api"
import { useFlag } from "../utilities/feature-management"

export const states = {
  LOADING: "loading",
  READY: "ready",
  APPLYING: "applying",
  DISMISSING: "dimissing",
  COMPLETE: "complete",
}

/**
 * Access intelligent update state.
 */
const useIntelligentUpdates = (courseID) => {
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState({ global: states.LOADING, byUpdate: {} })
  const [selectedTargetIDs, setSelectedTargetIDs] = useState([])

  const { allUpdateIDs, updatesByID, allTargetIDs, targetsByID, loading } =
    useFetchIntelligentUpdates(courseID)

  const applyUpdates = useFlag("rollout-serverless-intelligent-updates")
    ? api.applyUpdates
    : api.legacyApplyUpdates

  useEffect(() => {
    if (!loading) {
      setState(set(["global"], states.READY))
    }
  }, [loading])

  // Default selected targets to all available targets.
  useEffect(() => setSelectedTargetIDs(allTargetIDs), [allTargetIDs])

  /**
   * Get the current state for a given intelligent update.
   *
   * @param {string} id An update ID.
   * @returns {string}
   */
  const getUpdateState = useCallback(
    (id) => {
      const updateState = state.byUpdate?.[id] ?? states.READY
      return updateState === states.READY ? state.global : updateState
    },
    [state]
  )

  const setUpdateState = (value, id) =>
    setState(set(id ? ["byUpdate", id] : ["global"], value))

  /**
   * Get an intelligent update by its ID.
   *
   * @param {string} id An update ID.
   * @returns {object} The intelligent update.
   */
  const getUpdateByID = useCallback(
    (id) => {
      if (isArray(id)) {
        return map(getUpdateByID, id)
      }

      if (!updatesByID[id]) {
        return null
      }

      return {
        ...updatesByID[id],
        // Include the active state for the update.
        state: getUpdateState(id),
        // Filter to selected targets and denormalize.
        targets: intersection(selectedTargetIDs, updatesByID[id].targets).map(
          (id) => targetsByID[id]
        ),
      }
    },
    [getUpdateState, selectedTargetIDs, updatesByID, targetsByID]
  )

  /** The list ids for updates that have not been resolved. */
  const incompleteUpdateIDs = useMemo(
    () => allUpdateIDs.filter((id) => getUpdateState(id) !== states.COMPLETE),
    [allUpdateIDs, getUpdateState]
  )

  /** The list ids for updates that a ready to be actioned. */
  const readyUpdateIDs = useMemo(
    () => allUpdateIDs.filter((id) => getUpdateState(id) === states.READY),
    [allUpdateIDs, getUpdateState]
  )

  /** A list of updates to be resolved. */
  const updates = useMemo(
    () => getUpdateByID(incompleteUpdateIDs),
    [incompleteUpdateIDs, getUpdateByID]
  )

  /** The list of all available targets. */
  const targets = useMemo(
    () => map((id) => targetsByID[id], allTargetIDs),
    [targetsByID, allTargetIDs]
  )

  /**
   * Apply an update to the selected targets.
   *
   * If a single update is not specified, the all updates will be applied in bulk.
   *
   * @param {string?} id A specific update to apply.
   * @returns {Promise<void>}
   */
  const apply = async (id) => {
    const updateIDs = id ? [id] : readyUpdateIDs
    const targetCourseIDs = selectedTargetIDs

    try {
      setUpdateState(states.APPLYING, id)
      await applyUpdates({
        courseId: courseID,
        updateIds: updateIDs,
        targetCourseIds: targetCourseIDs,
      })
      setUpdateState(states.COMPLETE, id)
    } catch {
      setUpdateState(states.READY, id)
      enqueueSnackbar("Could not apply update.", { variant: "error" })
    }
  }

  /**
   * Ignore an update for the selected targets.
   *
   * If a single update is not specified, the all updates will be ignored in bulk.
   *
   * @param {string?} id A specific update to apply.
   * @returns {Promise<void>}
   */
  const ignore = async (id) => {
    const updateIDs = id ? [id] : readyUpdateIDs

    try {
      setUpdateState(states.DISMISSING, id)
      await applyUpdates({
        courseId: courseID,
        updateIds: updateIDs,
        targetCourseIds: [],
      })
      setUpdateState(states.COMPLETE, id)
    } catch {
      setUpdateState(states.READY, id)
      enqueueSnackbar("Could not ignore update.", { variant: "error" })
    }
  }

  /** Apply all updates. */
  const applyAll = () => apply()

  /** Ignore all updates. */
  const ignoreAll = () => ignore()

  return {
    updates,
    state: state.global,

    apply,
    applyAll,
    ignore,
    ignoreAll,

    targets,
    selectedTargetIDs,
    selectTargets: setSelectedTargetIDs,
  }
}

export default useIntelligentUpdates
