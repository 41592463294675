import styled from "@emotion/styled"
import { Card, Tooltip } from "@mui/material"
import { AddCircle } from "@mui/icons-material"

export const StyledCard = styled(Card)(({ theme }) => {
  return {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.lexLightGrey,
    width: theme.spacing(28),
    height: theme.spacing(36),
    "&:hover": {
      boxShadow: theme.shadows[3],
    },
    flexDirection: "column",
    marginRight: theme.spacing(2),
  }
})

/**
 *
 * @param root0
 * @param root0.onClick
 * @param root0.tooltip
 */
const CreateCourseTile = ({ onClick, tooltip }) => {
  return (
    // <Grid item xs={6} md={4} lg={3} xl={2}>
    <Tooltip arrow placement="top" title={tooltip}>
      <StyledCard elevation={0} onClick={onClick}>
        <AddCircle
          sx={{
            /**
             *
             * @param theme
             */
            color: (theme) => theme.palette.primary.dark,
          }}
          fontSize="large"
        />
      </StyledCard>
    </Tooltip>
    // </Grid>
  )
}

export default CreateCourseTile
