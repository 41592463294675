/* eslint-disable jsx-a11y/media-has-caption */
import { flushSync, render } from "react-dom"
import { uuidv4 } from "../../utilities/domUtils"

/**
 * Create basic audio element to be displayed in Froala editor
 * @param title - Title of audio
 * @param url - URL of audio source
 */
const createAudioHTML = (title: string, url: string) => {
  const div = document.createElement("div")

  const uuid = uuidv4()

  flushSync(() => {
    render(
      <div
        id={`audio-${uuid}`}
        contentEditable="false"
        draggable="true"
        className="audio-clickable fr-draggable fr-deletable"
      >
        <span className="audio-title">{title}</span>
        <audio className="audio-url" preload="metadata" controls src={url} />
      </div>,
      div
    )
  })

  const html = div.innerHTML
  return html
}

export default createAudioHTML
