import axiosGlobal from "./axios"

/**
 * @typedef CreateTemplateResponse
 *
 * @property {Object} data
 * @property {string} data.smart_template_html
 * @property {boolean} data.user_modified
 * @property {string} data.message user-friendly message to be displayed in the template control
 */

/**
 * Fetch template HTML for the given template.
 * e.g. exercise, demonstration, test_question

 * @param {string} template_type "demonstration", "exercise", "learning_objectives", "section", "test_question", "rewrite"
 * @param {string} course_html html of entire course
 * @param {string | null} content_section_id id of content section that template is for (null for rewrite)
 * @param {string | null} template_section_id id of existing template section if for update
 * @param {Object} options for template generation that are specific to the template type
 * @param {Object} [axios]
 * @return {Promise<CreateTemplateResponse>}
 *
 */
const createTemplate = (
  template_type,
  course_html,
  content_section_id,
  template_section_id,
  options = {},
  axios = axiosGlobal
) => {
  return axios.post("/templates/", {
    template_type,
    course_html,
    content_section_id,
    template_section_id,
    ...options,
  })
}

export default createTemplate
