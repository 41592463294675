import DirectoryItem from "./DirectoryItem"
import { RENAME_FOLDER, UPLOAD_IMAGE } from "./DirectoryItemActionDialogs"
import { Item } from "../../../../homeNavigation/types"
import ExitDirectoryControl from "./ExitDirectoryControl"
import ItemList from "../../../itemList/ItemList"
import { Box, Button, Typography } from "@mui/material"
import useCoursesDisplay from "../../../../welcome/hooks/useCoursesDisplay"
import ListItemControlWrapper from "../../../itemList/ListItemControlWrapper"
import ItemSkeleton from "./DirectoryItemSkeleton"
import { DirectoryItemListProps } from "../types"

/**
 * Item List for displaying courses and folders
 * @param props - Component props
 */
const DirectoryItemList = (props: DirectoryItemListProps) => {
  const {
    items: totalItems,
    query,
    loading,
    directory,
    handleExitDirectory,
    setDialogAction,
    isDragging,
    displaySearchResults,
  } = props

  const { visibleListItems: items, showMore } = useCoursesDisplay(
    totalItems,
    query
  )

  const showMoreButtonVisible = items.length < totalItems.length

  // When triggering loading skeletons, have it be the same number as however many visible courses there were before for consistency
  const numSkeletons = items.length > 0 ? items.length : 6

  const withinFolder = directory !== null
  const emptyDirectory = !loading && items.length === 0
  const showBackButton = !loading && withinFolder

  return (
    <ItemList>
      {!loading ? (
        <>
          {showBackButton && (
            <ExitDirectoryControl
              onClick={handleExitDirectory}
              isDragging={isDragging}
            />
          )}
          {items.map((item: any) => (
            <DirectoryItem
              key={item.id}
              item={item}
              onRenameFolder={(item: Item) => {
                setDialogAction({
                  name: RENAME_FOLDER,
                  targetItems: [item],
                })
              }}
              onUploadImage={(item: Item) => {
                setDialogAction({
                  name: UPLOAD_IMAGE,
                  targetItems: [item],
                })
              }}
              {...props}
            />
          ))}
          {showMoreButtonVisible && (
            <ListItemControlWrapper>
              <Button onClick={showMore} variant="text">
                Load more
              </Button>
            </ListItemControlWrapper>
          )}
        </>
      ) : (
        [...Array(numSkeletons)].map((i, index) => <ItemSkeleton key={index} />)
      )}
      {emptyDirectory && (
        <Box
          marginTop={16}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body2">
            {displaySearchResults
              ? "No courses match your search."
              : withinFolder
              ? "This folder contains no courses."
              : "You have not created any courses yet. Click Add Course to get started."}
          </Typography>
        </Box>
      )}
    </ItemList>
  )
}

export default DirectoryItemList
