import { useState } from "react"
import * as api from "../api"
import useCourses from "./useCourses"
import { useSnackbar } from "notistack"

const HELPER_TEXT_DEFAULT =
  "Email address of the LEAi user who will receive this course."
const HELPER_TEXT_INVALID = "This email address is not valid."

export const useGiveCourse = () => {
  const [emailValid, setEmailValid] = useState(false)
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [helperText, setHelperText] = useState(HELPER_TEXT_DEFAULT)
  const { enqueueSnackbar } = useSnackbar()

  const { updateCourses, documents: courseOptions } = useCourses()

  /**
   * When email input loses focus, verify if the email is valid with a request to the backend
   */
  const checkValidEmail = (emailInput) => {
    if (emailInput === email || emailInput === "") {
      // If we exited focus without changing the email input, or if no input, we do not need to re-verify
      return
    }
    setEmail(emailInput)
    setIsLoading(true)
    api.verifyEmail(emailInput).then((data) => {
      const { verified } = data
      setIsLoading(false)
      setEmailValid(verified)
      setHelperText(verified ? HELPER_TEXT_DEFAULT : HELPER_TEXT_INVALID)
    })
  }

  const giveCourse = (course_id) => {
    if (!emailValid) {
      return
    }

    api.giveCourse(email, course_id).then(({ data }) => {
      const { transferred } = data
      if (transferred) {
        enqueueSnackbar(`Transferred ownership of this course to ${email}.`, {
          variant: "success",
        })
        updateCourses()
        // reset email and valid state
        setEmail("")
        setEmailValid(false)
      } else {
        enqueueSnackbar(
          `Unable to transfer ownership of this course. Please try again, contact LearnExperts if the problem persists`,
          {
            variant: "error",
          }
        )
      }
    })
  }

  return {
    email,
    isLoading,
    emailValid,
    checkValidEmail,
    giveCourse,
    helperText,
    courseOptions,
  }
}
