import { useAuthenticator } from "@aws-amplify/ui-react"
import { Alert } from "@mui/material"
import { motion } from "framer-motion"

import { translate } from "@aws-amplify/ui-react/node_modules/@aws-amplify/ui"
import { AnimatePresence } from "framer-motion"

/**
 * Displays authentication error messages reported from Cognito.
 */
export function RemoteErrorMessage() {
  const { error } = useAuthenticator((context) => [context.error])

  return (
    <AnimatePresence exitBeforeEnter>
      {error ? (
        <motion.div
          layoutId="error"
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
        >
          <Alert color="error">{translate(error)}</Alert>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default RemoteErrorMessage
