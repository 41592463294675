import { gateway } from "./axios"
import { IMPORT_API_ENDPOINT } from "./importEndpoint"

/**
 * Get headers for upload item
 * @param jobId - job we are working with
 * @param sourceId - id of the upload source
 */
const fetchImportSourceHeadings = async (jobId: string, sourceId: string) => {
  console.log("---fetchImportSourceHeadings get", jobId)
  const resp = await gateway.get<any>(
    `${IMPORT_API_ENDPOINT}${jobId}/headings`,
    {
      baseURL: "/",
      params: {
        source_id: sourceId,
      },
    }
  )

  return resp.data
}

export default fetchImportSourceHeadings
