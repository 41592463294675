import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import createLearningPath from "../api/createLearningPath"
import deleteLearningPath from "../api/deleteLearningPath"
import editLearningPath from "../api/editLearningPath"
import addCourseToLearningPath from "../api/addCourseToLearningPath"
import removeCourseFromLearningPath from "../api/removeCourseFromLearningPath"
import getLearningPathById from "../api/getLearningPathById"
import fetchLearningPaths from "../api/fetchLearningPaths"
import shiftCourseInLearningPath from "../api/shiftCourseInLearningPath"
import getTargetIndex from "../utils/getTargetIndex"
import fetchLearningPathTemplates from "../api/fetchLearningPathTemplates"
import { useFlag } from "../../../utilities/feature-management"
import { arrayMove } from "@dnd-kit/sortable"
import {
  addVirtualCourseToPath,
  removeVirtualCourseFromPath,
  reorderVirtualCourseInPath,
} from "../utils/virtualItemUtilities"

/**
 * Hook to handle logic for Learning Paths and their associated courses.
 */
const useLearningPaths = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [result, setResult] = useState({
    learningPaths: [],
    error: null,
  })
  const [pathTemplates, setPathTemplates] = useState([])
  const [sortBy, setSortBy] = useState(() => {
    const sortMethod = window.localStorage.getItem("pathSortMethod")
    return sortMethod ?? "last_update"
  })

  const useTemplatedLearningPaths = useFlag("rollout-templated-learning-paths")

  const history = useHistory()

  /**.
   * Create a new, empty Learning Path
   *
   * @param arg - Title and/or template ID parameters for creating path
   */
  const createPath = (arg) => {
    setIsLoading(true)
    return createLearningPath(arg)
      .then((res) => {
        updateAllLearningPaths().then(setData)
      })
      .catch(setError)
  }

  /**
   * Retrieve all available templates to create a Learning Path with
   */
  const listLearningPathTemplates = () => {
    return fetchLearningPathTemplates()
  }

  /**.
   * Fully delete an entire Learning Path
   *
   * @param path_id.path_id
   * @param path_id - ID of Learning Path to delete
   */
  const deletePath = ({ path_id }) => {
    setIsLoading(true)
    return deleteLearningPath({ pathId: path_id })
      .then((res) => {
        updateAllLearningPaths().then(setData)
      })
      .catch(setError)
  }

  /**.
   * Updated a Learning Path with a new title
   *
   * @param path- Learning Path to rename
   * @param path-.path_id
   * @param title - New name of Learning Path
   */
  const renamePath = ({ path_id }, title) => {
    setPathLoading(path_id, true)

    return editLearningPath({ pathId: path_id, title })
      .then(() => updateLearningPath(path_id))
      .catch(setError)
  }

  /**
   * Add either an existing course to the path, or redirect to Course Creation screen.
   * @param path - Path to add course to.
   * @param course - Course to be added to path.
   */
  const addToPath = (path, course) => {
    const { path_id } = path
    if (!course) {
      history.push("/create-course", {
        learningPath: path_id,
      })
    } else {
      setPathLoading(path_id, true)
      setResult((prev) => addVirtualCourseToPath(prev, path, course))
      return addCourseToLearningPath({
        courseId: course.id,
        pathId: path_id,
      })
        .then(() => updateLearningPath(path_id))
        .catch((e) => {
          setPathLoading(path_id, false)
          setError(e)
        })
    }
  }

  /**
   * Remove a single course from a given Learning Path
   *
   * @param path - Path to remove course from
   * @param course - Course to be removed from the path
   */
  const removeFromPath = (path, course) => {
    const { path_id } = path
    setPathLoading(path_id, true)
    setResult((prev) => removeVirtualCourseFromPath(prev, path, course))
    return removeCourseFromLearningPath({
      pathId: path_id,
      courseId: course.id,
    })
      .then((res) => updateLearningPath(path_id))
      .catch((e) => {
        setPathLoading(path_id, false)
        setError(e)
      })
  }

  /**
   * Shift a course left or right within a Learning Path's sequence.
   *
   * @param path - Learning Path to perform shift on.
   * @param course - Course to shift within the path.
   * @param direction - Whether to move course in front or behind sibling.
   */
  const shiftInPath = (path, course, direction) => {
    const { id: courseId } = course
    const { courses, path_id: pathId } = path

    const targetIndex = getTargetIndex(courses, courseId, direction)

    if (targetIndex === null) {
      return
    }

    /**
     *  If the target index is the very last element of the array, we will want
     *  to provide the ID as null so the backend will move it to the end of the
     *  list. Otherwise, just get the ID of the target to put the course in front of.
     */
    const targetId =
      targetIndex === courses.length ? null : courses[targetIndex].id

    setPathLoading(pathId, true)
    return shiftCourseInLearningPath({ targetId, courseId, pathId })
      .then((res) => updateLearningPath(pathId))
      .catch((e) => {
        setPathLoading(pathId, false)
        setError(e)
      })
  }

  /**
   * Reorder a course within a learning path, based on the parameters provided
   * from a drag and drop event
   * @param path - Learning path to be reordered
   * @param active - Course that was dragged to a new position
   * @param over - Course that the active course was dropped overtop of during the drag operation
   */
  const reorderInPath = (path, active, over) => {
    const { courses, path_id: pathId } = path

    const ids = courses.map((course) => course.id)

    const activeIndex = ids.indexOf(active.id)
    const overIndex = ids.indexOf(over.id)
    const isMovingUp = activeIndex > overIndex

    const reorderedIDs = arrayMove(ids, activeIndex, overIndex)

    const targetId = isMovingUp
      ? ids[overIndex] // If we are reordering upwards, the "over" course is the target
      : overIndex < ids.length - 1
      ? ids[overIndex + 1] // If reordering downwards, the target is one further than the over index
      : null // If dragged to end of path, the target value is null

    setResult((prev) => reorderVirtualCourseInPath(prev, path, reorderedIDs))
    return shiftCourseInLearningPath({ targetId, courseId: active.id, pathId })
      .then((res) => updateLearningPath(pathId))
      .catch((e) => {
        setPathLoading(pathId, false)
        setError(e)
      })
  }

  /**
   * Retrieve all Learning Paths for a given user.
   * @type {function(): Promise<AxiosResponse<*>>}
   */
  const updateAllLearningPaths = useCallback(() => {
    return fetchLearningPaths().then((res) => {
      setIsLoading(false)
      return res
    })
  }, [])

  /**
   * Toggle the loading state of a single Learning Path, due to an operation
   * performed on it.
   * @type {(function(*, *): void)|*}
   */
  const setPathLoading = useCallback(
    (id, loading) => {
      setData(
        result.learningPaths.map((item) =>
          item.path_id === id ? { ...item, loading } : item
        )
      )
    },
    [result.learningPaths]
  )

  /**
   * Refresh a single Learning Path to reflect most recent changes enacted on it.
   * @type {function(*=): Promise<void>}
   */
  const updateLearningPath = useCallback(
    (id) => {
      return getLearningPathById({ pathId: id }).then((res) =>
        setData(
          result.learningPaths.map((item) =>
            item.path_id === id ? { ...res, loading: false } : item
          )
        )
      )
    },
    [result]
  )

  /**
   * Update the state with the most recent Learning Paths.
   * @param learningPaths - Updated paths following a change.
   */
  const setData = (learningPaths) =>
    setResult((result) => ({
      ...result,
      learningPaths,
      error: null,
    }))

  /**
   * Update with some kind of error that occurred.
   * @param error - Error object following request.
   */
  const setError = (error) =>
    setResult((result) => ({ ...result, error, loading: false }))

  /**
   * Upon mounting, retrieve all Learning Paths.
   */
  useEffect(() => {
    /**
     * Once the create from template feature flag is on, retrieve list of available templates
     */
    if (useTemplatedLearningPaths) {
      listLearningPathTemplates().then((templates) => {
        setPathTemplates(templates)
      })
    }
    updateAllLearningPaths().then(setData)
  }, [useTemplatedLearningPaths, updateAllLearningPaths])

  /**
   * Update state and local storage for sort option
   * @param option - Sort option
   */
  const handleSelectSortOption = (option) => {
    window.localStorage.setItem("pathSortMethod", option)
    setSortBy(option)
  }

  return {
    createPath,
    deletePath,
    renamePath,
    addToPath,
    removeFromPath,
    shiftInPath,
    reorderInPath,
    result,
    isLoading,
    pathTemplates,
    sortBy,
    handleSelectSortOption,
  }
}

export default useLearningPaths
