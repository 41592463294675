import { createInsertVideoDialog } from "../features/videoInsertion/InsertVideoDialog"
import createIFrame from "../features/videoInsertion/utils/createIFrame"
import { selectAfterText } from "../utilities/domUtils"
import Froalaeditor from "froala-editor"

// Define icon for our custom video insert button
Froalaeditor.DefineIcon("showVideoDialog", {
  SVG_KEY: "insertVideo",
})

/**
 * Register custom command to show Video Insert dialog instead of the built-in Froala one.
 * @param displayReactElement - Function to render edit Dialog box
 */
const registerVideoCommands = (displayReactElement: any) => {
  Froalaeditor.RegisterCommand("showVideoDialog", {
    title: "Insert Video",
    icon: "showVideoDialog",
    focus: true,
    undo: true,
    callback: function () {
      const selection = this.selection.get() as any
      const anchorElement = selection.anchorNode
      anchorElement.innerHTML = "<br/>"

      displayReactElement((onClose: () => void) =>
        createInsertVideoDialog((url) => {
          onClose()
          if (url) {
            const videoIFrame = createIFrame(url)

            // Reselect the text to keep cursor in place
            selectAfterText(anchorElement, this)
            anchorElement.insertAdjacentHTML("beforebegin", videoIFrame)

            const firstChild = anchorElement.firstChild

            // Remove the initial linebreak once the video is inserted
            if (firstChild && firstChild.tagName === "BR") {
              anchorElement.removeChild(firstChild)
            }
          }
        })
      )
    },
  })
}

export default registerVideoCommands
