import axiosInstance from "../../../api/axios"

/**
 * Send request to update the Learning Management map for course export options
 * @param courseID - ID of course to update
 * @param arg - Map of attributes to update
 */
const updateLearningManagement = (courseID: string, arg: any) => {
  return axiosInstance.put(`/export/${courseID}/learning_management`, arg)
}

export default updateLearningManagement
