import { useState, useEffect } from "react"
import { findRangeForLocation } from "../utilities/remarkUtils"
import { scrollIntoView } from "../utilities/domUtils"
import { createPortal } from "react-dom"
import { Box } from "@mui/material"
import { useStableCallback } from "./useStableCallback"

/** Creates a function called highlightLocation that can be called
 * with a location (see comment documentation) and it will scroll
 * to that location and highlight it briefly.
 * Returns { highlightLocation, highlightElement }
 * highlightLocation is a function that can be called with a location to scroll and highlight
 * highlightElement is the element that is highlighted. Include in the React tree.
 */
export function useHighlightLocation() {
  const [highlightedRange, setHighlightedRange] = useState(null)

  /** Briefly highlights a location and scrolls to it */
  const highlightLocation = useStableCallback((location) => {
    const { range, matchElement } =
      findRangeForLocation(
        location,
        document.getElementsByClassName("fr-element")[0]
      ) ?? {}

    if (!range) {
      return
    }

    let targetHighlight
    let sel = window.getSelection()
    let targetRectangle = range.getBoundingClientRect()

    sel.removeAllRanges()

    if (targetRectangle.x !== 0 && targetRectangle.y !== 0) {
      /**
       * If we have computed a valid range, move cursor to that location
       */
      sel.addRange(range)
      sel.collapseToStart()
      targetHighlight = range
    } else {
      /**
       * Edge case for Test Questions which whose range's bounding rectangle
       * is 0, 0 due to it being a non-editable element. We are still able to
       * determine the location of the element and scroll there by using matchElement.
       */
      targetRectangle = matchElement.getBoundingClientRect()
      targetHighlight = matchElement
    }

    scrollIntoView(
      targetRectangle,
      document.getElementsByClassName("fr-wrapper")[0],
      { alignToTop: true }
    )
    setHighlightedRange(targetHighlight)
  })

  useEffect(() => {
    // Remove highlight after a short delay
    if (highlightedRange) {
      const timeout = setTimeout(() => {
        setHighlightedRange(null)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [highlightedRange])

  const highlightElement = (
    <RangeHighlight
      range={highlightedRange}
      scrollElement={document.getElementsByClassName("fr-wrapper")[0]}
    />
  )

  return {
    highlightLocation,
    highlightElement,
  }
}

/** Draw a highlights on top of the editor */
function RangeHighlight(props) {
  const { range, scrollElement } = props

  if (!range) {
    return null
  }

  const rects = range.getClientRects()

  // Create series of overlay rectangles to show highlight
  const overlays = []
  for (let i = 0; i < rects?.length; i++) {
    const rect = rects[i]

    // Check that doesn't overlap with previous rectangles (for some reason, sometimes the rects are overlapping)
    let overlaps = false
    for (let prev = 0; prev < i; prev++) {
      const prevRect = rects[prev]
      if (
        rect.top < prevRect.bottom &&
        rect.bottom > prevRect.top &&
        rect.left < prevRect.right &&
        rect.right > prevRect.left
      ) {
        overlaps = true
      }
    }
    if (overlaps) {
      continue
    }

    overlays.push(
      <Box
        key={i}
        sx={{
          position: "absolute",
          top:
            rect.top -
            scrollElement.getBoundingClientRect().top +
            scrollElement.scrollTop,
          left: rect.left - scrollElement.getBoundingClientRect().left,
          width: rect.width,
          height: rect.height,
          backgroundColor: "primary.dark",
          mixBlendMode: "multiply",
          opacity: 0.5,
          pointerEvents: "none",
          zIndex: 10000,
        }}
      />
    )
  }

  return createPortal(
    <div data-cy="highlight" key="highlights">
      {overlays}
    </div>,
    scrollElement
  )
}
