import { ChevronRight, KeyboardArrowDown } from "@mui/icons-material"
import { Box, styled } from "@mui/material"
import React, { useCallback } from "react"
import Nestable from "react-nestable"

import useOutlineDragScroll from "../../hooks/useOutlineDragScroll"
import { isContainedBySection } from "./hooks/useHeaderOperationsAccess"
import useOutlineItems from "./hooks/useOutlineItems"
import OutlineItem from "./OutlineItem"
import applyReorder from "./utils/applyReorder"

/**
 * @typedef {import("./utils/getOutline").OutlineItem} OutlineItem
 * @typedef {{ $el: Element[]}} EditorInterface
 *
 * @typedef {{
 *  editor: EditorInterface
 * }} OutlineProps
 *
 * @typedef {{
 *  isCollapsed: boolean,
 * }} NestableRenderCollapseIconProps
 *
 * @typedef {(props: NestableRenderCollapseIconProps) => JSX.Element} NestableRenderCollapseIcon
 *
 * @typedef {{
 *  item: OutlineItem,
 *  collapseIcon: JSX.Element,
 *  index: number,
 *  depth: number,
 * }} NestableRenderItemProps
 *
 * @typedef {(props: NestableRenderItemProps) => JSX.Element} NestableRenderItem
 */

/**
 * Indicates the current collapsed state of an outline item.
 * @type {(props: { isCollapsed: boolean}) => JSX.Element}
 */
const CollapseIcon = (props) => {
  const { isCollapsed } = props
  const Icon = isCollapsed ? ChevronRight : KeyboardArrowDown
  return <Icon />
}

/**
 * Root element in the outline.
 *
 * Wraps the Nestable implementation.
 */
const OutlineRoot = styled(Nestable, { name: "OutlineRoot" })(({ theme }) => ({
  padding: theme.spacing(2),
  whiteSpace: "nowrap",
  "ol.nestable-list": {
    marginTop: 0,
    paddingLeft: "12px",
  },
  "& > ol.nestable-list": {
    paddingLeft: "24px",
  },
  ".nestable-item": {
    marginTop: 0,
  },
  ".icon-container": {
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    width: "24px",
    height: "24px",
    fontSize: "1.4em",
    marginLeft: "-24px",
    cursor: "pointer",
    svg: {
      fontSize: "inherit",
    },
  },
  fontSize: theme.typography.body2.fontSize,
}))

OutlineRoot.defaultProps = {
  /**
   * The default function for rendering every item.
   * @type {NestableRenderItem}
   */
  renderItem: ({ item, collapseIcon }) => (
    <OutlineItem item={item} collapseIcon={collapseIcon} />
  ),
  /**
   * The default function for rendering collapse icon.
   * @type {NestableRenderCollapseIcon}
   */
  renderCollapseIcon: ({ isCollapsed }) => (
    <CollapseIcon isCollapsed={isCollapsed} />
  ),
}

/**
 * Displays the outline for a given document.
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const Outline = React.forwardRef((props, ref) => {
  const { editor, readOnly, updateDocument, ...otherProps } = props
  const view = editor?.$el?.[0]
  const { mouseExited, mouseEntered } = useOutlineDragScroll()
  const outline = useOutlineItems(view)

  return (
    <Box height="100%" display="flex" flexDirection="column" {...otherProps}>
      <Box
        id="course-outline"
        overflow="auto"
        fontSize="12px"
        flexShrink={1}
        onMouseLeave={mouseExited}
        onMouseEnter={mouseEntered}
      >
        <OutlineRoot
          items={outline}
          ref={ref}
          {...useReorderOperation(view, updateDocument, readOnly)}
        />
      </Box>
    </Box>
  )
})

export default React.memo(Outline)

/**
 * Create the callbacks for handling Nestable reorder operations.
 *
 * @param {Element} container
 *  The element containing the document sections.
 * @param {Function} updateDocument
 *  A function that will "commit" any changes to the document.
 * @param {boolean} readOnly
 *  When true, reorder operations are disabled.
 */
const useReorderOperation = (container, updateDocument, readOnly) => {
  /**
   * Handle a reorder triggered by nestable.
   * @type {(outline: OutlineItem[], item: OutlineItem) => void}
   */
  const onChange = useCallback(
    (outline, item) => {
      if (readOnly) {
        return
      }

      applyReorder(outline, item, container)
      updateDocument(container.innerHTML, { triggerSave: true })
    },
    [container, updateDocument, readOnly]
  )

  /**
   * Confirm that a given Nestable item is allowed to be reordered.
   * @type {(item: OutlineItem) => boolean}
   */
  const confirmChange = useCallback(
    (item) => !readOnly && !isContainedBySection(item.$el),
    [readOnly]
  )

  return { onChange, confirmChange }
}
