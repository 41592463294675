import axiosInstance from "../../../api/axios"

/**
 * Remove any overrides applied to course to retain default global branding
 * @param course_id - ID of course to remove overrides from
 */
const removeCourseBrandingOverrides = (course_id: string) => {
  return axiosInstance.delete(`/export/${course_id}/branding`)
}
export default removeCourseBrandingOverrides
