import config from "../../../config"
import axios from "axios"

/**
 * Retrieve list of available fonts from Google
 */
const fetchGoogleFonts = () => {
  const apiCreds = config.googleFontsApiCredentials
  const apiKey = apiCreds.apiKey

  return axios
    .get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`)
    .then((response) => response.data)
}

export default fetchGoogleFonts
