import React, { useEffect, useState } from "react"
import { getTitle } from "../../utilities/transformers"
import debounce from "lodash/fp/debounce"
import PageLayout from "../../components/layouts/PageLayout"
import CoursesToolBar from "../../components/widgets/CoursesToolBar"
import TrashedCoursesList from "./TrashedCoursesList"
import TrashedCoursesGrid from "./TrashedCoursesGrid"
import useTrash from "./hooks/useTrash"
import CoursesError from "../../components/widgets/CoursesError"
import ConfirmDialog from "../../components/widgets/ConfirmDialog"
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material"
import ConfirmDeleteImage from "../../assets/images/confirm-delete.svg"
import { Item } from "../homeNavigation/types"
import { FolderOpen, Description } from "@mui/icons-material"
const SEARCH_DEBOUNCE_TIME = 200

/**
 * filter documents function
 * @param query query
 * @param documents documents
 */
export const filterDocuments = (query: string, documents: any[]) => {
  if (!query) {
    return documents
  }
  return documents.filter((doc: { title: any }) => {
    return getTitle(doc.title).toLowerCase().includes(query.toLowerCase())
  })
}

/**
 * The trashscreen function
 * @param props props
 * @param props.onSearchInputChange onSearchInputChange
 * @param props.toggleView toggleView
 * @param props.breadcrumbs breadcrumbs
 */
const TrashScreen = (props: {
  onSearchInputChange?: ((text: any) => void) | undefined
  toggleView?: (() => void) | undefined
  breadcrumbs?: never[] | undefined
}) => {
  const [query, setQuery] = useState("")
  const [isGridView, setIsGridView] = useState(true)
  const [confirmingDelete, setConfirmingDelete] = useState<Item[] | null>(null) // null or a list of item objects

  // calls custom hook to retrieve both the current documents, and a callback to delete a document by ID or to duplicate a course
  const { documents, error, deleteCourses, restoreCourses, isLoading } =
    useTrash()

  const filteredDocuments = filterDocuments(query, documents || [])

  const {
    onSearchInputChange: onSearchInputChangeProp = (
      text: React.SetStateAction<string>
    ) => setQuery(text),
    toggleView: toggleViewProp = (isGridView: boolean) =>
      setIsGridView(!isGridView),
    breadcrumbs = [],
  } = props

  useEffect(() => {
    let viewLayout = window.localStorage.getItem("viewLayout")
    if (viewLayout) {
      setIsGridView(viewLayout === "grid")
    }
  }, [])

  /**
   * toggle view function
   */
  const toggleView = () => {
    if (isGridView) {
      window.localStorage.setItem("viewLayout", "list")
    } else {
      window.localStorage.setItem("viewLayout", "grid")
    }
    setIsGridView(!isGridView)
    toggleViewProp(isGridView)
  }

  /**
   * calls the deleteCourse callback function returned by the useCourses hook
   * @param course course
   */
  const onDelete = (course: Item) => {
    setConfirmingDelete([course])
  }
  /**
   * onDeleteConfirmed
   * @param items - Items to delete
   */
  const onDeleteConfirmed = async (items: Item[]) => {
    await deleteCourses(items.map((item) => item.id))
  }

  /**
   * onRestore
   * @param course course
   * @param course.id id
   */
  const onRestore = async (course: { id: any }) => {
    await restoreCourses([course.id])
  }

  const onSearchInputChange = debounce(
    SEARCH_DEBOUNCE_TIME,
    (query: React.SetStateAction<string>) => {
      setQuery(query)
      onSearchInputChangeProp(query)
    }
  )

  const hasError = error != null
  const isEmpty = error == null && isLoading === false && documents.length === 0
  const showGrid = error == null && !isEmpty && isGridView
  const showList = error == null && !isEmpty && !isGridView
  const pageLayoutProps = {
    fixed: true,
    maxWidth: "xl",
    breadcrumbs: [...breadcrumbs, { label: "Trash" }],
  }

  return (
    <>
      <PageLayout {...pageLayoutProps}>
        <Typography variant="h4" component="h1" gutterBottom>
          Trash
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1">
            View and manage deleted courses.
          </Typography>
        </Box>
        {!isEmpty && (
          <CoursesToolBar
            onSearchInputChange={onSearchInputChange}
            toggleView={toggleView}
            isGridView={isGridView}
          />
        )}
        {hasError && <CoursesError error={error} />}
        {showGrid && (
          <TrashedCoursesGrid
            isLoading={isLoading}
            filteredDocuments={filteredDocuments}
            onDelete={onDelete}
            onRestore={onRestore}
          />
        )}
        {showList && (
          <TrashedCoursesList
            isLoading={isLoading}
            filteredDocuments={filteredDocuments}
            onDelete={onDelete}
            onRestore={onRestore}
          />
        )}
      </PageLayout>
      {confirmingDelete && (
        <ConfirmDeletedDialog
          confirmingDelete={confirmingDelete}
          setConfirmingDelete={setConfirmingDelete}
          onDeleteConfirmed={onDeleteConfirmed}
        />
      )}
    </>
  )
}

/**
 * ConfirmDeletedDialog
 * @param props props
 * @param props.confirmingDelete confirmingDelete
 * @param props.setConfirmingDelete setConfirmingDelete
 * @param props.onDeleteConfirmed onDeleteConfirmed
 */
export const ConfirmDeletedDialog = (props: {
  confirmingDelete: Item[]
  setConfirmingDelete: any
  onDeleteConfirmed: any
}) => {
  const { confirmingDelete, setConfirmingDelete, onDeleteConfirmed } = props

  return (
    <ConfirmDialog
      title={"Confirm Delete Course"}
      message={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            component="img"
            sx={{ width: "300px", alignSelf: "center", mb: 4 }}
            src={ConfirmDeleteImage}
            alt="You can't undo this action"
          />
          {confirmingDelete.length === 1 && (
            <Typography variant="body1" align="justify">
              {`Permanently delete the selected item "${confirmingDelete[0].title}"? You can't undo this action.`}
            </Typography>
          )}
          {confirmingDelete.length > 1 && (
            <>
              <Typography variant="body1" align="justify">
                {`Permanently delete the following items? You can't undo this action.`}
              </Typography>
              <List
                dense
                sx={{
                  position: "relative",
                  overflow: "auto",
                  maxHeight: "12rem",
                }}
              >
                {confirmingDelete.map((item: Item) => {
                  return (
                    <ListItem>
                      <ListItemIcon
                        sx={(theme: any) => ({
                          minWidth: theme.spacing(4),
                        })}
                      >
                        {item.item_type === "folder" ? (
                          <FolderOpen fontSize="small" />
                        ) : (
                          <Description fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText>{item.title}</ListItemText>
                    </ListItem>
                  )
                })}
              </List>
            </>
          )}
        </Box>
      }
      cancelText={"Cancel"}
      confirmText={"Delete"}
      onCancel={(e) => {
        e.preventDefault()
        setConfirmingDelete(null)
      }}
      onConfirm={async (e) => {
        e.preventDefault()
        onDeleteConfirmed(confirmingDelete)
        setConfirmingDelete(null)
      }}
    />
  )
}

TrashScreen.propTypes = {
  /**
   * Called to retrieve a set of uploaded courses for a user.
   */
}

export default TrashScreen
