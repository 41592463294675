import { useEffect, useState } from "react"
import { checkLms } from "../../../api"
import { ExportFormat, FORMAT_OPTIONS } from "../utils/contants"
import { useFlag } from "../../../utilities/feature-management"

export type FormatOption = Readonly<{
  value: ExportFormat
  label: string
}>

/**
 * Provides the list of available export formats.
 */
const useFormatOptions = () => {
  const rolloutEpubExport = useFlag("rollout-epub-export")
  const rolloutWebExport = useFlag("rollout-web-export")

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isScormCloudLmsAvailable, setIsScormCloudLmsAvailable] =
    useState(false)
  const [isSkilljarLmsAvailable, setIsSkilljarLmsAvailable] = useState(false)

  useEffect(() => {
    /**
     * Ask the server which LMS's are active for the user
     */
    const fetchLmsData = async () => {
      try {
        const [isSkilljarLmsAvailable, isScormCloudLmsAvailable] =
          await Promise.all([
            checkLms("lms-skilljar"),
            checkLms("lms-scormcloud"),
          ])

        setIsSkilljarLmsAvailable(isSkilljarLmsAvailable)
        setIsScormCloudLmsAvailable(isScormCloudLmsAvailable)
        setLoading(false)
      } catch (error: any) {
        console.error(error)
        setLoading(false)
        setError(error)
      }
    }

    fetchLmsData()
  }, [])

  /**
   * Filter out unavailable format options
   * @param options - output format options
   */
  const filterOptions = (options: FormatOption): boolean => {
    if (options.value === "lms-scormcloud") {
      return isScormCloudLmsAvailable
    } else if (options.value === "lms-skilljar") {
      return isSkilljarLmsAvailable
    } else if (options.value === "scorm-html") {
      return rolloutWebExport === true
    } else if (options.value === "epub") {
      return rolloutEpubExport === true
    } else {
      return true
    }
  }

  const formatOptions: ReadonlyArray<FormatOption> =
    FORMAT_OPTIONS.filter(filterOptions)

  return { formatOptions, loading, error }
}

export default useFormatOptions
