import React from "react"
import { createContext, useContext } from "react"
import * as defaultAPI from "../api"

/**
 * A context for accessing an API instance.
 */
export const APIContext = createContext()

/**
 * Provide an API instance for components to access.
 */
export const APIProvider = (props) => {
  const { api = defaultAPI, ...otherProps } = props

  return <APIContext.Provider {...otherProps} value={api} />
}

/**
 * Use the API instance associated with the current API context.
 */
export const useAPI = () => useContext(APIContext)
