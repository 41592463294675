import {
  Alert,
  AlertTitle,
  Box,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material"
import { IInaccessibleElements } from "../../features/export/utils/checkForInaccessibleElements"
import { WarningAmber } from "@mui/icons-material"

interface AccessibilityWarningProps {
  elements?: IInaccessibleElements[] | null
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
  disablePortal?: boolean
}

const AlertTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} leaveDelay={200} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "none",
  },
})

/**
 * Alert to be placed in context with items that don't meet accessibility standards
 * @param props - Component props
 * @param props.elements - List of inaccessible elements
 * @param props.placement - Tooltip positioning
 * @param props.disablePortal - Enable true if using within shadow DOM
 */
const AccessibilityWarning = ({
  elements,
  placement = "top",
  disablePortal,
}: AccessibilityWarningProps) => (
  <AlertTooltip
    placement={placement}
    PopperProps={{
      disablePortal,
    }}
    title={
      <Alert
        severity="warning"
        color="info"
        sx={{ width: disablePortal ? "100%" : 500 }} // Apply larger fixed width to alert when not contrained by disablePortal
      >
        <>
          <AlertTitle>Accessibility</AlertTitle>
          {elements && elements.length > 0 ? (
            <>
              There are some components in this course that do not have
              accessibility support. If you have users that require
              accessibility features, remove these components from course.
              <ul>
                {elements.map((element) => (
                  <li>{element.label}</li>
                ))}
              </ul>
            </>
          ) : (
            "This component is not optimized for accessibility. "
          )}
          <a
            target="_blank"
            href="/docs/Accessibility-Compliance-2024-02-07.pdf"
          >
            Read more on accessibility here.
          </a>
        </>
      </Alert>
    }
  >
    <Box display="flex" alignItems="center">
      <WarningAmber color="warning" />
    </Box>
  </AlertTooltip>
)

export default AccessibilityWarning
