import { Box } from "@mui/material"
import { formatDate } from "../../utilities/transformers"

/**
 * Displays the current build details.
 */
const BuildMeta = () => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "max-content 1fr",
        alignItems: "baseline",
        columnGap: 2,
        padding: 2,
      }}
    >
      <span>Version:</span>
      <code>{process.env.REACT_APP_BUILD_VERSION}</code>

      <span>Build Time:</span>
      <code>{formatDate(process.env.REACT_APP_BUILD_TIMESTAMP)}</code>

      <span>Git Commit:</span>
      <code>{process.env.REACT_APP_GIT_COMMIT}</code>

      <span>Git Branch:</span>
      <code>{process.env.REACT_APP_GIT_BRANCH}</code>
    </Box>
  )
}

export default BuildMeta
