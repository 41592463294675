import axiosInstance, { gateway } from "../../../api/axios"
import { Target, Update } from "../types"

export type FetchUpdatesResponse = {
  tenantId: string
  courseId: string
  courseVersion?: string
  updates: Update[]
  targets: Target[]
}

/**
 * Fetches the identified intelligent updates for a course.
 * @param courseId The course ID.
 */
export default function fetchUpdates(
  courseId: string
): Promise<FetchUpdatesResponse> {
  return gateway
    .get(`/intelligent-updates/${courseId}`)
    .then(({ data }) => data)
    .then(normalize)
}

/**
 * Fetches the identified intelligent updates for a course.
 * @param courseId The course ID.
 */
export function fetchUpdatesLegacy(courseId: string) {
  return axiosInstance
    .get("/intelligent_updates", {
      params: { course: courseId },
    })
    .then(({ data }) => data)
    .then(normalize)
}

/**
 * Normalize the response data.
 * @param data The response data.
 */
function normalize(data: any): FetchUpdatesResponse {
  return {
    tenantId: data.tenant_id,
    courseId: data.course_id,
    courseVersion: data.version_id,
    updates: data.updates,
    targets: data.targets,
  }
}
