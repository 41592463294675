import axiosGlobal from "./axios"

/**
 * Receives a course ID representing the course to transfer, and an email for the recipient of the course
 *
 * @param {string} email
 * @param {string} course_id
 * @param {object} config
 * @returns {Promise<unknown>|Promise<AxiosResponse<any>>}
 */
const giveCourse = (email, course_id, config = {}) => {
  const { axios = axiosGlobal } = config
  return axios.put(`/courses/${course_id}/give`, { email })
}

export default giveCourse
