import axiosInstance from "../../../api/axios"

type FetchFoldersAndChildrenArg = {
  item_ids: string[]
}

/**
 * Given a set of folder IDs, find all their children
 * @param arg - Contains list of item IDs of folders to retrieve
 */
const fetchFoldersAndChildren = (arg: FetchFoldersAndChildrenArg) =>
  axiosInstance.post("/folders/children", arg).then((response) => response.data)

export default fetchFoldersAndChildren
