import { ItemsState } from "../types"

/**
 * Modify existing items list for a given folder by removing the moved items from the state's list.
 * @param prev - Current item list from state
 * @param item_ids - List of item IDs we are moving
 * @param folder - The current folder the items were moved from
 */
const moveVirtualItems = (
  prev: ItemsState,
  item_ids: string[],
  folder: string
) => {
  // Create an updated list of IDs in the current folder, excluding the IDs we are moving
  const updatedList = [
    ...prev.ids[folder].filter((id) => !item_ids.includes(id)),
  ]

  // Return state updated with IDs removed from list
  return {
    ...prev,
    ids: {
      ...prev.ids,
      [folder]: updatedList,
    },
  }
}

export default moveVirtualItems
