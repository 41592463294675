import { useEffect, useLayoutEffect, useState } from "react"

/**
 * Hook to fit text within a div using a binary search
 */
export function useFitText(): {
  /** New font size */
  fontSize: string
  /** Ref to attach to the div */
  ref: (elem: HTMLDivElement | null) => void
} {
  /** Current font size as a percentage */
  const [fontSize, setFontSize] = useState(100)

  /** Amount to decrease/increase font */
  const [change, setChange] = useState(40)

  /** Increment to re-do font sizing */
  const [increment, setIncrement] = useState(0)

  /** Ref to the div */
  const [div, setDiv] = useState<HTMLDivElement | null>(null)

  // Listen to the div size for changes
  useEffect(() => {
    if (!div) return

    /** Resets the update process */
    function updateFontSize() {
      setIncrement((prev) => prev + 1)
      setFontSize(100)
      setChange(40)
    }

    // Recalculate the font size when the div size changes
    const observer = new ResizeObserver(updateFontSize)
    observer.observe(div)
    return () => observer.disconnect()
  }, [div, div?.innerHTML])

  // Updates the font size one iteration
  useLayoutEffect(() => {
    if (!div) return

    // If the div is hidden, stop
    if (!div.offsetHeight || !div.scrollHeight) {
      return
    }

    // Check if the text is overflowing
    const isOverflowing = div.scrollHeight > div.offsetHeight

    // If not overflowing and the font size is already the max, stop
    if (!isOverflowing && fontSize >= 100) {
      return
    }

    // If overflowing and the font size is already the min, stop
    if (isOverflowing && fontSize < 6) {
      return
    }

    // Stop if we can't change the font size anymore
    if (change < 2) {
      // If the text is overflowing, decrease the font size back to last value
      if (isOverflowing) {
        setFontSize((prev) => prev - change * 2)
      }
      return
    }

    // If the text is overflowing, decrease the font size
    if (isOverflowing) {
      setFontSize((prev) => prev - change)
    }
    // If the text is not overflowing, increase the font size if we can
    else if (fontSize + change <= 100) {
      setFontSize((prev) => prev + change)
    }

    // Decrease the amount we change the font size by
    setChange((prev) => prev / 2)
  }, [div, change, fontSize, increment])

  return { fontSize: `${fontSize}%`, ref: setDiv }
}
