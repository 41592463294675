import axiosInstance from "./axios"

/**
 * Add a comment to a course.
 *
 * @returns {Promise<Comment>}
 */
export const editCommentReply = (arg) => {
  const { text, id, courseId, commentId, as } = arg

  return axiosInstance
    .put(
      `/courses/${courseId}/comments/${commentId}/replies/${id}`,
      {
        text,
      },
      { params: { email: as?.email } }
    )
    .then((response) => ({ ...response.data, courseId, commentId }))
}

export default editCommentReply
