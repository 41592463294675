import { Item } from "../../../../homeNavigation/types"
import AddToPathDialog from "../../../../learningPaths/dialogs/AddToPathDialog"
import DeleteLearningPathDialog from "../../../../learningPaths/dialogs/DeleteLearningPathDialog"
import RenameLearningPathDialog from "../../../../learningPaths/dialogs/RenameLearningPathDialog"

/**
 * Component to mount all different dialogs for learning path interactions
 * @param props - Component props
 */
const LearningPathActionDialogs = (props: any) => {
  const {
    path,
    deletePath,
    renamePath,
    addToPath,
    deleteDialogVisible,
    setDeleteDialogVisible,
    renameDialogVisible,
    setRenameDialogVisible,
    addDialogVisible,
    setAddDialogVisible,
    setAnchorEl,
  } = props

  return (
    <>
      <DeleteLearningPathDialog
        path={path}
        open={deleteDialogVisible}
        onConfirm={(e: any) => {
          e.preventDefault()
          deletePath(path)
          setAnchorEl(null)
          setDeleteDialogVisible(false)
        }}
        onCancel={(e: any) => {
          e.preventDefault()
          setAnchorEl(null)
          setDeleteDialogVisible(false)
        }}
      />
      <RenameLearningPathDialog
        path={path}
        open={renameDialogVisible}
        onConfirm={(name: string) => {
          renamePath(path, name)
          setAnchorEl(null)
          setRenameDialogVisible(false)
        }}
        onCancel={() => {
          setAnchorEl(null)
          setRenameDialogVisible(false)
        }}
      />
      <AddToPathDialog
        path={path}
        open={addDialogVisible}
        onConfirm={(course: Item) => {
          addToPath(path, course)
          setAddDialogVisible(false)
        }}
        onCancel={(e: any) => {
          e.preventDefault()
          setAnchorEl(null)
          setAddDialogVisible(false)
        }}
      />
    </>
  )
}

export default LearningPathActionDialogs
