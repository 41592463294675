import React from "react"
import { parseQueryString } from "../utilities/queryString"
import EditorScreen from "../components/screens/EditorScreen"
import { useHistory } from "react-router-dom"
import { TransformationSummary } from "../components/layouts/TransformationSummaryTemplate"
import ShareCourseDialog from "../components/screens/ShareCourseDialog"

/** Page that displays the main editor */
export function FroalaEditorPage(props) {
  const { location } = props

  const courseId = parseQueryString(location.search).course
  const history = useHistory()

  const showTransformSummary =
    location?.state?.openTransformationSummary || false

  const showApplyTransformation = location?.state?.applyTemplates || false

  return (
    <>
      <EditorScreen courseId={courseId} />
      <TransformationSummary
        open={showTransformSummary}
        onClose={() =>
          history.replace({
            ...location,
            state: { openTransformationSummary: false },
          })
        }
        showApplyTransformation={showApplyTransformation}
      />
      <ShareCourseDialog
        course={courseId}
        open={location?.state?.share ?? false}
        onClose={() =>
          history.replace({
            ...location,
            state: { share: false },
          })
        }
      />
    </>
  )
}
