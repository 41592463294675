import * as api from "../../api"
import { createAsyncThunk } from "@reduxjs/toolkit"

/**
 * Create action thunk to upload a course document.
 *
 * @see https://redux-toolkit.js.org/api/createAsyncThunk
 */
const uploadCourseDocument = createAsyncThunk(
  "jobs/uploadCourseDocument",
  async (options) => {
    return api.uploadCourseDocument(options)
  }
)

export default uploadCourseDocument
