import axiosInstance from "../../../api/axios"

/**
 * Retrieve branding config on a course-level. Response includes all
 * global branding along with course-level overrides.
 * @param course_id - ID of course to fetch branding for
 */
const fetchCourseBranding = (course_id: string) => {
  return axiosInstance
    .get(`/courses/${course_id}/branding`)
    .then((response) => response.data)
}

export default fetchCourseBranding
