import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
} from "@reduxjs/toolkit"
import retry from "async-retry"
import { isNil } from "lodash"
import { AppState } from "../.."
import axios from "../../../api/axios"
import {
  Detection,
  DetectionRule,
  RemarksState,
  Transformation,
} from "../remarks"
import remarksAdaptor from "../remarksAdaptor"
import normalizeDetection from "../utils/normalizeDetection"
import { isSectionDetection } from "../../../utilities/remarkUtils"

const DETECTIONS_ENDPOINT =
  process.env.REACT_APP_DEV_DETECTIONS_ENDPOINT == null
    ? "/api/detections/"
    : process.env.REACT_APP_DEV_DETECTIONS_ENDPOINT

type RequestData = {
  html: string
  offset: number
  timeout: number
}

const fetchDetectionsProgress = createAction(
  "detections/fetch/progress",
  (
    payload: { detections: Detection[]; lastCompletedPath: number[] | null },
    meta: { requestId: string }
  ) => {
    return { payload, meta }
  }
)

/** Detections as returned from the server */
export interface ServerDetectionData {
  detections: ServerDetection[]
  last_completed_path: number[] | null
  next_offset: number | null
}

/** Single detection as returned from the server */
export interface ServerDetection {
  /** Unique identifier for the detection */
  id: string

  /** Identifier for the detection without its location in the document. */
  detection_key: string

  /** The specific rule that triggered this detection */
  rule: DetectionRule

  /** Text of the detection, or the text of the entire block item. This is text, not HTML.
   * If searching in HTML (in order to do a replacement while keeping the formatting),
   * be sure to convert common entities such as apostrophe to text first.
   */
  text: string

  /** The type of HTML element where the detection occurred (e.g., 'p', 'h2') */
  type: string

  /** Array of indices representing the path to the block in the document structure.
   * Blocks that are nested (e.g. table - tbody - tr - td) have multiple array elements.
   * If the second block element is a table with a single cell, the path would be [1, 0, 0, 0].
   */
  path: number[]

  /** Number of blocks in the selection (for detections that apply to a range of blocks).
   * Only applicable for "interactive_component" detections currently.
   */
  num_blocks?: number

  /** Optional field for applying corrections to the detected issue */
  transformation?: Transformation

  /** Start index of the detection within the block item's text or null
   * if the detection is block-level or multi-block.
   */
  start_index: number | null

  /** Indicates if the detection is potentially of lower quality */
  lower_quality?: boolean

  /** Indicates if the detection was cached */
  cache_hit?: boolean

  /** @deprecated First element of the path */
  number?: number
}

export const fetchDetections = createAsyncThunk<
  Detection[],
  { html: string; readOnly: boolean },
  { state: AppState }
>(
  "detections/fetch",
  async ({ html, readOnly }, context): Promise<Detection[]> => {
    let nextOffset: number | null = 0
    const detections: Detection[] = []

    // Don't proceed with fetching detections if we are viewing as readOnly
    if (readOnly) {
      return []
    }

    const { dispatch, requestId, getState } = context

    let retries = 0
    while (
      retries < 3 &&
      !isNil(nextOffset) &&
      getState().remarks.activeDetectionsRequest === requestId
    ) {
      const { data }: { data: ServerDetectionData } =
        await makeFetchDetectionsRequest({
          html,
          offset: nextOffset,
          timeout: nextOffset === 0 ? 1 : 5,
        })

      const hasAdvanced = data.next_offset !== nextOffset
      retries = !hasAdvanced ? retries + 1 : 0

      const lastCompletedPath: number[] | null =
        data.last_completed_path ?? null
      nextOffset = data.next_offset
      const responseDetections = normalizeDetection(data.detections)

      detections.push(...responseDetections)

      dispatch(
        fetchDetectionsProgress(
          { detections, lastCompletedPath },
          { requestId }
        )
      )
    }

    return detections
  }
)

/**
 * Build reducer cases for the handling fetched detections.
 *
 * @param builder A redux case builder.
 */
export const buildCasesForFetchDetections = (
  builder: ActionReducerMapBuilder<RemarksState>
) => {
  builder.addCase(fetchDetections.pending, (state, action) => {
    state.activeDetectionsRequest = action.meta.requestId
  })
  builder.addCase(fetchDetectionsProgress, (state, action) => {
    const isActiveRequest =
      state.activeDetectionsRequest === action.meta.requestId

    if (!isActiveRequest) {
      return
    }
    const {
      payload: { detections, lastCompletedPath },
    } = action

    const idsToRemove = selectStaleDetectionIdsForBatch(
      state,
      detections,
      lastCompletedPath
    )

    remarksAdaptor.removeMany(state, idsToRemove)
    remarksAdaptor.addMany(state, detections)
    if (action.payload.detections.length > 0) {
      state.detectionsLoading = false
    }
  })
  builder.addCase(fetchDetections.fulfilled, (state, action) => {
    if (state.activeDetectionsRequest === action.meta.requestId) {
      const idsToRemove = selectStaleDetectionIds(state, action.payload)
      remarksAdaptor.removeMany(state, idsToRemove)
      state.status = "fulfilled"
      state.detectionsLoading = false
    }
  })
}

/**
 * Request detections for the given html.
 * @param data The request data.
 */
const makeFetchDetectionsRequest = (data: RequestData) => {
  return retry(
    () => {
      return axios.post<any>(DETECTIONS_ENDPOINT, data, { baseURL: "/" })
    },
    { retries: 5 }
  )
}

/**
 * Select any remaining stale detections.
 *
 * @param state The current state.
 * @param newDetections A complete set of fetched detections.
 */
const selectStaleDetectionIds = (
  state: RemarksState,
  newDetections: Detection[]
) => {
  const freshIds = new Set(newDetections.map(({ id }) => id))

  const staleDetections = remarksAdaptor
    .getSelectors()
    .selectAll(state)
    .filter(
      (remark) =>
        remark?.remarkType === "detection" &&
        !isSectionDetection(remark) &&
        !freshIds.has(remark.id)
    )

  return staleDetections.map(remarksAdaptor.selectId)
}

/**
 * Select any stale detections given a new set of detections.
 *
 * @param state The current state.
 * @param newDetections The detection batch.
 * @param lastCompletedPath The path of the latest fully complete block.
 */
const selectStaleDetectionIdsForBatch = (
  state: RemarksState,
  newDetections: Detection[],
  lastCompletedPath: number[] | null
) => {
  if (!newDetections.length) {
    return []
  }

  const newIds = new Set(newDetections.map(({ id }) => id))
  const pathMax =
    lastCompletedPath ??
    newDetections
      .map(({ location: { path } }) => path)
      .reduce((a, b) => (comparePaths(b, a) === 1 ? b : a))

  const staleDetections = remarksAdaptor
    .getSelectors()
    .selectAll(state)
    .filter(
      (remark) =>
        remark?.remarkType === "detection" &&
        !isSectionDetection(remark) &&
        comparePaths(remark.location.path, pathMax) !== 1 &&
        !newIds.has(remark.id)
    )

  return staleDetections.map(remarksAdaptor.selectId)
}

/**
 * Deterine the relative sort order of two values.
 *
 * @param a - The first value.
 * @param b - The second value.
 * @returns -1 if a < b, 1 if a > b, 0 if a === b.
 */
const compare = (a: number, b: number) => (a < b ? -1 : a > b ? 1 : 0)

/**
 * Deterine the relative sort order of two paths.
 *
 * @param a - The first path.
 * @param b - The second path.
 * @returns -1 if a < b, 1 if a > b, 0 if a === b.
 */
const comparePaths = (a: number[], b: number[]): 1 | 0 | -1 => {
  if (!a.length && !b.length) {
    return 0
  }

  const [aHead = -1, ...aRest] = a
  const [bHead = -1, ...bRest] = b

  return compare(aHead, bHead) || comparePaths(aRest ?? [], bRest ?? [])
}
