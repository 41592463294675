import { Box } from "@mui/material"
import { motion } from "framer-motion"

/**
 * Renders the LearnExperts brand-mark.
 */
function BrandMark() {
  return (
    <a href="https://learnexperts.ai" target="_blank" rel="noreferrer">
      <Box
        component={motion.img}
        layout="position"
        sx={{ userSelect: "none" }}
        src="/lex-icon-wordmark-557x202.png"
        alt="Learn Experts Logo"
        height="80px"
      />
    </a>
  )
}

export default BrandMark
