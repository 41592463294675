import { DateRange } from "@mui/icons-material"
import { alpha, Button, Theme } from "@mui/material"
import { DateTime } from "luxon"

const getDateString = (isoDate: any) =>
  isoDate != null ? DateTime.fromISO(isoDate).toLocaleString() : null

const transformDateValue = (
  filterSince: any,
  filterBefore: any,
  datePresetTitle: any
) => {
  if (datePresetTitle !== "") {
    return datePresetTitle.replace("_", " ").toLowerCase()
  }

  const startDate = getDateString(filterSince)
  const endDate = getDateString(filterBefore)

  return startDate && endDate ? `${startDate} - ${endDate}` : "Date"
}

const DateSelectionControl = (props: any) => {
  const { onClick, filterSince, filterBefore, datePresetTitle } = props

  return (
    <Button
      sx={(theme: Theme) => ({
        height: "32px",
        color: theme.scheme.onSecondaryContainer,
        backgroundColor: theme.scheme.secondaryContainer,
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: alpha(theme.scheme.surfaceTint, 0.08),
        },
        textTransform: "capitalize",
      })}
      startIcon={<DateRange />}
      aria-haspopup="true"
      onClick={onClick}
    >
      {transformDateValue(filterSince, filterBefore, datePresetTitle)}
    </Button>
  )
}

export default DateSelectionControl
