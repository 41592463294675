import React from "react"
import { Button } from "@mui/material"
import { noop } from "lodash/fp"
import { useSnackbar } from "notistack"
import { Link } from "react-router-dom"

const DEFAULT_BUTTON_PROPS = {
  size: "small",
}

/**
 * A control displayed as part of a notification.
 */
const NotificationAction = (props) => {
  const {
    notificationKey,
    label,
    onClick: onClickProp = noop,
    routeTo,
    ...otherProps
  } = props
  const { closeSnackbar } = useSnackbar()

  const onClick = (e) => {
    closeSnackbar(notificationKey)
    onClickProp(e)
  }

  const buttonProps = {
    ...DEFAULT_BUTTON_PROPS,
    ...otherProps,
    ...(routeTo && { component: Link, to: routeTo }),
    onClick,
  }

  return <Button {...buttonProps}>{label}</Button>
}

export default NotificationAction
