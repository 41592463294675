import { useTheme } from "@mui/material"
import {
  getOr,
  mapValues,
  partial,
  update,
  compose,
  isEqual,
  set,
} from "lodash/fp"
import { useEffect, useState } from "react"

/**
 * Controls the state for multiple drawers.
 *
 * There is separate state for mobile and desktop drawers (i.e. Material's
 * "temporary" and "permanent" drawers). When the screen drops below the
 * specific breakpoint defined for a drawer, the drawer swaps to using the
 * mobile state.
 *
 * @param {*} options
 * @returns
 */
const useDrawerState = (options = {}) => {
  const breakpoints = useBreakpoints()
  const [state, setState] = useState({
    desktop: {},
  })

  /** Select the desktop breakpoint for the specified drawer. */
  const selectDesktopBreakpoint = (drawer) =>
    options?.[drawer]?.permanentAbove ?? "md"

  /** Check if a drawer should be using mobile state. */
  const isMobile = (drawer) => !breakpoints[selectDesktopBreakpoint(drawer)]

  /** Determine if a drawer is currently open. */
  const isOpen = (drawer, s = state) =>
    isMobile(drawer)
      ? isOpenMobile(drawer, s.mobile)
      : isOpenDesktop(drawer, s.desktop)

  /**
   * Perform and update to drawer state.
   *
   * @param {string} drawer
   * @param {function} updateMobile
   * @param {function} updateDesktop
   * @returns The new drawer state.
   */
  const updateDrawer = (drawer, updateMobile, updateDesktop) =>
    setState((state) =>
      isMobile(drawer)
        ? update(["mobile"], partial(updateMobile, [drawer]), state)
        : compose(
            update(["mobile"], closeMobile),
            update(["desktop"], partial(updateDesktop, [drawer]))
          )(state)
    )

  /** Toggle the state of the specified drawer. */
  const toggle = (drawer) => updateDrawer(drawer, toggleMobile, toggleDesktop)

  /** Open the specified drawer. */
  const open = (drawer) => updateDrawer(drawer, openMobile, openDesktop)

  /** Close the specified drawer. */
  const close = (drawer) => updateDrawer(drawer, closeMobile, closeDesktop)

  return {
    isOpen,
    open,
    close,
    toggle,
    drawerProps: (drawer) => ({
      permanentAbove: selectDesktopBreakpoint(drawer),
      openMobile: isOpenMobile(drawer, state.mobile),
      open: isOpen(drawer),
      onClose: () => close(drawer),
      isMobile: isMobile(drawer),
    }),
  }
}

export default useDrawerState

// -- Operators for mobile drawer state

const isOpenMobile = (drawer, state) => state === drawer
const openMobile = (drawer) => drawer
const closeMobile = () => null
const toggleMobile = (drawer, state) =>
  isOpenMobile(drawer, state) ? closeMobile() : openMobile(drawer)

// -- Operators for desktop drawer state

const isOpenDesktop = (drawer, state) => getOr(true, [drawer], state)
const openDesktop = (drawer, state) => set([drawer], true, state)
const closeDesktop = (drawer, state) => set([drawer], false, state)
const toggleDesktop = (drawer, state) =>
  set([drawer], !isOpenDesktop(drawer, state), state)

/**
 * Determines if we've passed the minimum threshold for each defined breakpoint.
 *
 * @returns {{
 *    xl: boolean,
 *    lg: boolean,
 *    md: boolean,
 *    sm: boolean,
 *    xs: boolean,
 * }}
 */
export const useBreakpoints = () => {
  const theme = useTheme()
  const [breakpoints, setBreakpoints] = useState({})

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth

      setBreakpoints((state) => {
        const nextState = mapValues(
          (breakpoint) => breakpoint < width,
          theme.breakpoints.values
        )

        return isEqual(state, nextState) ? state : nextState
      })
    }

    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [theme.breakpoints.values])

  return breakpoints
}
