import { Box, Button, TextField, Typography } from "@mui/material"
import { ReactNode, useEffect } from "react"
import { Autocomplete } from "@mui/lab"
import { IGoogleFont } from "./hooks/useBranding"
import ListBoxComponent from "./ListBoxComponent"

/**
 * Component for uploading and previewing fonts
 * @param props - Component props
 */
const FontUploadBox = (props: any) => {
  const {
    div,
    branding,
    variant = "heading",
    onPropertyChange,
    title,
    googleFonts,
    recentFonts,
    updateRecentFonts,
  } = props

  const selectedFont = branding[variant]
  const { url, name } = selectedFont

  /**
   * Upon mounting, check to see if we have an existing font file URL loaded into
   * the state. If so, create a custom font face using that file, load it into
   * the document, and apply that font style to the preview text, which will
   * be set to the the font's generated name in the state.
   */
  useEffect(() => {
    if (url) {
      const font = new FontFace(name, `url(${url})`)
      document.fonts.add(font)
    }
  }, [name, url])

  /**
   * Handle file upload of font. Create a URL to display it to the user and
   * store the file object itself in the branding.changes object in the state.
   *
   * @param event - File input event
   */
  const onAdd = (event: any) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const fontUrl = URL.createObjectURL(file)
      // For the text content of the font preview, grab whatever name has been given to the font file
      const fontName = file.name.split(".")[0]
      onPropertyChange(variant, { url: fontUrl, name: fontName }, file)
    }
  }

  const fontOptions = recentFonts[variant].concat(googleFonts)

  return (
    <>
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
      <Box display="flex">
        <Autocomplete
          fullWidth
          sx={{
            "& input": {
              font: `1rem "${selectedFont.name}"`,
            },
          }}
          ListboxComponent={ListBoxComponent}
          onChange={(event: any, newValue: IGoogleFont) => {
            if (newValue == null) {
              onPropertyChange(variant, { url: null, name: null })
            } else {
              updateRecentFonts(newValue, variant)
              onPropertyChange(variant, newValue)
            }
          }}
          value={selectedFont}
          renderInput={(params) => (
            <TextField data-cy="font-upload-text" {...params} />
          )}
          getOptionLabel={(option) => {
            if (!option.name) {
              return ""
            }
            return option.name
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderOption={(props, option, state: any) =>
            [props, option, state.index] as ReactNode
          }
          options={fontOptions}
        />
        <Button component="label">
          <input
            data-cy="font-upload-button"
            hidden
            type="file"
            accept=".ttf,.otf,.woff2,.woff"
            onChange={onAdd}
          />
          Upload
        </Button>
      </Box>
    </>
  )
}

export default FontUploadBox
