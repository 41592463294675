import React from "react"
import { useHistory } from "react-router-dom"
import CoursesScreen from "../components/screens/CoursesScreen"
import ShareCourseDialog from "../components/screens/ShareCourseDialog"

const CoursesPageNew = ({ location }) => {
  const history = useHistory()

  return (
    <>
      <CoursesScreen />
      <ShareCourseDialog
        course={location?.state?.share}
        open={!!location?.state?.share}
        onClose={() => history.replace({ ...location, state: { share: null } })}
      />
    </>
  )
}

export default CoursesPageNew
