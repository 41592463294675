import {
  Box,
  CardContent,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { AddComment, MoreVert, Send } from "@mui/icons-material"
import { isoStringToRelativeTime } from "../../../utilities/transformers"
import CommentReply from "./CommentReply"
import Button from "@mui/material/Button"
import styled from "@emotion/styled"
import { useState } from "react"
import useComment from "../../../hooks/useComment"
import EditComment from "./EditComment"
import { useSelector } from "react-redux"
import selectCommentsUser from "../../../store/remarks/selectors/selectCommentsUser"

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => {
  const colorProp =
    theme.palette?.[color]?.main ?? theme.palette?.[color] ?? color
  return {
    marginTop: theme.spacing(1),
    "& label.Mui-focused": {
      color: colorProp,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colorProp,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colorProp,
      },
      "&:hover fieldset": {
        borderColor: colorProp,
      },
      "&.Mui-focused fieldset": {
        borderColor: colorProp,
      },
    },
  }
})

export const CommentEmail = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  maxWidth: "20ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "default",
  color: theme.palette?.[color]?.main ?? theme.palette?.[color] ?? color,
}))

export const ResolveButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor:
      theme.palette?.[color]?.light ?? theme.palette?.[color] ?? color,
  },
}))

/**
 *
 * @param {string} commentId
 * @param {string} colorProp
 * @param {boolean} readOnly
 * @returns HTML
 */
const Comment = ({
  comment: commentId,
  color: colorProp = "addition",
  readOnly,
}) => {
  const {
    text,
    resolve,
    resolvedOn,
    replies,
    createdOn,
    createdBy,
    reply,
    deleteComment,
  } = useComment(commentId)

  const isResolved = resolvedOn != null

  const [isEditMode, setEditMode] = useState(false)

  const currentUser = useSelector(selectCommentsUser)?.email
  const authorEmail = createdBy?.email

  const [anchorEl, setAnchorEl] = useState(null)
  const [replyText, setReplyText] = useState("")
  const menuOpen = Boolean(anchorEl)
  const openMenu = (event) => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const color = isResolved ? "neutral" : colorProp

  /**
   * Allow deletion and editing of comment if the user is the owner of the course
   * or their guest email matches the author of the comment.
   * */
  const showCommentActions =
    !readOnly || (currentUser != null && currentUser === authorEmail)

  const editClicked = (e) => {
    e.preventDefault()
    setEditMode(true)
  }

  const replyClicked = (e) => {
    e.preventDefault()
    reply(replyText).then(() => {
      setReplyText("")
    })
  }

  return (
    <CardContent data-cy="comment">
      <Stack direction="row" alignItems="center" gap={1}>
        <AddComment fontSize="small" color={color} />
        <Tooltip title={authorEmail ?? ""}>
          <CommentEmail variant="body1" color={color}>
            {authorEmail}
          </CommentEmail>
        </Tooltip>
        {showCommentActions && (
          <>
            <Tooltip title="Comment actions">
              <IconButton
                sx={{ marginLeft: "auto" }}
                size="small"
                onClick={openMenu}
              >
                <MoreVert fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Menu
              open={menuOpen}
              onClose={closeMenu}
              onClick={closeMenu}
              id="comment-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={editClicked}>Edit</MenuItem>
              <MenuItem onClick={deleteComment}>Delete</MenuItem>
            </Menu>
          </>
        )}
      </Stack>
      <Typography gutterBottom variant="body2">
        Added {isoStringToRelativeTime(createdOn)}
      </Typography>
      {text && text !== "" && !isEditMode ? (
        <Typography
          gutterBottom
          variant="body2"
          sx={{
            overflow: "hidden",
          }}
        >
          "{text}"
        </Typography>
      ) : (
        <EditComment
          commentId={commentId}
          color={color}
          setEditMode={setEditMode}
        />
      )}
      {replies?.map((reply) => (
        <CommentReply key={reply.id} reply={reply} />
      ))}
      {!isResolved && !isEditMode && (
        <StyledTextField
          label="Reply"
          data-cy="reply-comment"
          variant="standard"
          fullWidth
          multiline
          color={color}
          onClick={(e) => e.stopPropagation()}
          onInput={(e) => {
            e.preventDefault()
            setReplyText(e.target.value)
          }}
          value={replyText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-cy="submit-reply"
                  onClick={replyClicked}
                  size="small"
                >
                  <Send fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      {!isResolved && !isEditMode && !readOnly && (
        <Box paddingTop={2} display="flex" justifyContent="flex-end">
          <ResolveButton
            data-cy="resolve-comment"
            onClick={() => resolve(commentId)}
            color={color}
            variant="text"
          >
            Resolve
          </ResolveButton>
        </Box>
      )}
    </CardContent>
  )
}

export default Comment
