import { MoveUp } from "@mui/icons-material"
import React from "react"

/**
 * File to keep custom MUI icons in for reuse
 */

export const GiveCourseIcon = ({ sx, ...otherProps }) => (
  <MoveUp {...otherProps} sx={{ ...sx, transform: "rotate(90deg)" }} />
)
