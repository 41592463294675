import axiosInstance from "./axios"

/**
 * @typedef {{
 *   id: string
 *   courseId: string
 *   text: string
 *   as?: { email: string}
 * }} EditCommentArg
 * @typedef {{}} CommentData
 */

/**
 * Edit a course comment.
 *
 * @param {EditCommentArg} arg
 * @returns {Promise<CommentData>}
 */
const editComment = (arg) => {
  const { id, courseId, text, as } = arg

  return axiosInstance
    .put(
      `/courses/${courseId}/comments/${id}`,
      { text },
      { params: { email: as?.email } }
    )
    .then((response) => response.data)
}

export default editComment
