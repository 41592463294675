import axiosInstance from "../../../api/axios"

/**
 * API call to upload custom scorm image for a course
 *
 * @param course_id - the course
 * @param image - the image file
 * @param imageType - the type of image file "start-page" or "book-cover"
 */
const uploadCourseExportImage = (
  course_id: string,
  image: File,
  imageType: string = "start-page"
) => {
  const data = new FormData()
  data.append("image", image)
  data.append("type", imageType)

  return axiosInstance
    .put(`/courses/${course_id}/export_image`, data)
    .then((response) => response.data)
}

export default uploadCourseExportImage
