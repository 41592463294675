import { FormEvent } from "react"
import { useHistory } from "react-router"
import { attemptFederatedSignIn } from "../../utils/authentication"
import { EmailField } from "../fields"
import SubmitButton from "../shared/SubmitButton"
import Form from "./Form"

type FormElement = HTMLFormElement & {
  readonly elements: {
    username: HTMLInputElement
  }
}

/**
 * Displays a form for confirming the username.
 *
 * Once confirmed, we attempt a federated sign-in. If there is an SSO integrated
 * for the domain, the user will be redirected to their sign-in flow. Otherwise,
 * the users are brought to our conventional sign-in.
 */
export function ConfirmUsername() {
  const history = useHistory()

  /**
   * Handle form submission.
   * @param e The form event.
   */
  const handleSubmit = (e: FormEvent<FormElement>) => {
    e.preventDefault()

    const username = e.currentTarget.elements.username.value

    attemptFederatedSignIn(username).catch(() => {
      history.replace({
        state: { requiresPassword: true },
      })
    })
  }

  return (
    <Form layoutId="signin-form" method="post" onSubmit={handleSubmit}>
      <EmailField />
      <Form.ActionBar layout="position">
        <SubmitButton label="Next" sx={{ flexGrow: 1 }} />
      </Form.ActionBar>
    </Form>
  )
}

export default ConfirmUsername
