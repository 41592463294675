import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { SortKey } from "./hooks/useCoursesDisplay"

export interface SortOption {
  label: string
  value: string
}

export interface SortSelectProps {
  options: SortOption[]
  sortBy: SortKey
  handleSelectSortOption: (sortBy: string) => void
}

/**
 * Component for selecting courses sort method
 *
 * @param props - Component props
 */
const SortSelect = (props: SortSelectProps) => {
  const { options, sortBy, handleSelectSortOption } = props

  return (
    <FormControl sx={{ minWidth: 220 }} size="small">
      <Select
        id="sort-select"
        onChange={(e: SelectChangeEvent) =>
          handleSelectSortOption(e.target.value as string)
        }
        value={sortBy}
      >
        {options.map((option: SortOption) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SortSelect
