import { useSelector } from "react-redux"
import { selectIsLoadingRemarks, selectRemarkIds } from "../store"
import { isEqual } from "lodash"

/**
 * Access the current list of course remarks.
 *
 * @example
 * ```js
 * {
 *   ids: [
 *     { remarkType: "comment", id: "951ef324-5bc4-43b7-9f9c-bd0926a8a7e4" },
 *     { remarkType: "comment", id: "85703bb8-82e6-40db-a8c0-3c02e8364a70" },
 *     { remarkType: "detection", id: "123ae35b-dc72-4c7d-b103-1ce9e2c5ec16" },
 *   ],
 *   loading: false,
 * }
 * ```
 */
const useRemarks = () => useSelector(selectRemarks, isEqual)

export default useRemarks

// Loading is true if both detections or section detections are loading
// since they are loaded concurrently
const selectRemarks = (state) => ({
  ids: selectRemarkIds(state),
  loading:
    selectIsLoadingRemarks(state) ||
    (state.remarks.detectionsLoading && state.remarks.sectionDetectionsLoading),
})
