import { useMemo, useContext } from "react"
import { useSelector } from "react-redux"
import { selectDetectionById, useAppDispatch } from "../store"
import {
  removeDetection,
  updateDetection,
  toggleOptionsVisible,
} from "../store/remarks"
import { scrollEditorTo } from "../utilities/domUtils"
import { dismiss, restore } from "../store/remarks/actions/reviewDetections"
import { findRangeForLocation } from "../utilities/remarkUtils"
import { EditorScreenContext } from "../components/screens/EditorScreenContext"
import { transformSelection } from "../features/transform/transformSelection"

export const useDetectionValue = (id) =>
  useSelector((state) => selectDetectionById(state, id))

export const useDetectionDispatch = (id) => {
  const dispatch = useAppDispatch()
  const editorScreenContext = useContext(EditorScreenContext)

  return useMemo(
    () => ({
      apply: (optionIndex) => {
        dispatch((dispatch, getState) => {
          applyTransform(
            selectDetectionById(getState(), id),
            optionIndex,
            editorScreenContext,
            () => {
              dispatch(removeDetection({ id }))
            }
          )
        })
      },
      dismiss: () => dispatch(dismiss({ id })),
      restore: () => dispatch(restore({ id })),
      updateSelectedIndex: (selectedIndex) =>
        dispatch((dispatch) => {
          dispatch(
            updateDetection({
              id: id,
              selectedIndex: selectedIndex,
            })
          )
        }),
      toggleOptionsVisible: () => dispatch(toggleOptionsVisible({ id })),
    }),
    [dispatch, id]
  )
}

const useDetection = (id) => ({
  ...useDetectionValue(id),
  ...useDetectionDispatch(id),
})

export default useDetection

/**
 * Highlight the text of a detection and transform it appropriately
 * @param detection - Data of detection to be transformed
 * @param optionIndex - Index of the option to be applied
 * @param editorScreenContext - Context for components within the EditorScreen
 * @param onComplete - Function to call when transformation is complete
 */
const applyTransform = (
  detection,
  optionIndex,
  editorScreenContext,
  onComplete
) => {
  const editor = editorScreenContext.editor
  const displayReactElement = editorScreenContext.displayReactElement
  const editorContainer = editorScreenContext.editorContainer
  const option = detection.transformation.options[optionIndex]

  if (!editor) {
    return
  }

  const result = findRangeForLocation(detection.location, editorContainer)
  if (!result) {
    console.log("Couldn't find detection element")
    return
  }
  const { range, matchElement } = result

  // Scroll to detection element
  scrollEditorTo(matchElement)

  if (detection.transformation.type === "text") {
    let success = false
    if (["ol", "ul"].includes(matchElement.tagName.toLowerCase())) {
      for (let child of matchElement.children) {
        success = runTextTransformation(
          child,
          detection,
          option.replacement_text
        )
        if (success) {
          break
        }
      }
    } else {
      success = runTextTransformation(
        matchElement,
        detection,
        option.replacement_text
      )
    }
    if (!success) {
      console.log("Couldn't perform transformation")
      return
    }
    onComplete()
  } else if (detection.transformation.type === "interactive_component") {
    transformSelection({
      selection: range,
      displayReactElement,
      editorContainer,
      editor,
      transformType: option.component,
      onClose: (completed) => {
        if (completed) {
          onComplete()
        }
      },
    })
  } else {
    console.log("invalid transformation type")
    return
  }

  editor.undo.saveStep()
}

function runTextTransformation(node, detection, replacement) {
  let new_html = node.innerHTML.replace(detection.text, replacement)
  if (new_html === node.innerHTML) {
    // Couldn't perform normal transformation, need to look in styled tags for match
    let new_text = node.textContent
    new_text = new_text.replace(detection.text, replacement)
    if (new_text === node.textContent) {
      return false
    } else {
      node.innerHTML = new_text
    }
  } else {
    node.innerHTML = new_html
  }
  return true
}
