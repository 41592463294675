import { ArrowBack } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { StyledCard } from "./CourseTile"
import { FolderBackButtonProps } from "../listLayout/FolderBackButton"
import { useDroppable } from "@dnd-kit/core"

/**
 * Component for rendering back button when within folder (tile view)
 * @param props - Component props
 */
const FolderBackButtonTile = (props: FolderBackButtonProps) => {
  const { onClick, isDragging } = props
  const { isOver, setNodeRef } = useDroppable({
    id: "__ALL__",
    data: {
      title: "All Courses",
      id: "__ALL__",
    },
  })
  return (
    <StyledCard
      ref={setNodeRef}
      sx={(theme) => ({
        cursor: "pointer",
        // Highlight add shadow when droppable item is overtop
        boxShadow: isOver
          ? `0px 0px 20px ${theme.palette.primary.main}`
          : undefined,
        alignItems: "center",
        justifyContent: "center",
      })}
      onClick={onClick}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <ArrowBack fontSize="small" />
        <Typography variant="body2">
          {!isDragging ? "Back" : "All Courses"}
        </Typography>
      </Box>
    </StyledCard>
  )
}

export default FolderBackButtonTile
