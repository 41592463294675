import { Box, Stack, Typography, Divider, Collapse } from "@mui/material"
import Button from "@mui/material/Button"
import { Delete, DriveFileMove, PostAdd } from "@mui/icons-material"
import pluralize from "../outline/utils/pluralize"
import CoursesViewToggle from "./CoursesViewToggle"
import CoursesList from "../homeNavigation/components/listLayout/CoursesList"
import useCoursesDisplay from "./hooks/useCoursesDisplay"
import { map } from "lodash/fp"
import CoursesSortSelect from "./SortSelect"
import { sortOptions } from "./hooks/useCoursesDisplay"
import FolderNavBar from "../homeNavigation/components/FolderNavBar"
import CoursesGrid from "../homeNavigation/components/gridLayout/CoursesGrid"
import { useMemo } from "react"

/**
 * Component for display all owned courses count, along with the create course button.
 *
 * @param props - Component props
 */
const CoursesDisplay = (props) => {
  const {
    courses: totalCourses,
    query,
    onTrash,
    onMove,
    onCreateFolder,
    hasError,
    checkedItems,
    setCheckedItems,
    currentFolder,
    sortBy,
    handleCreateCourseClicked,
    handleExitDirectory,
    handleSelectSortOption,
    foldersEnabled,
    displaySearchResults,
  } = props

  const {
    courses,
    visibleTiles,
    visibleListItems,
    showMore,
    toggleView,
    isGridView,
  } = useCoursesDisplay(totalCourses, query)

  const showGrid = isGridView && !hasError
  const showList = !isGridView && !hasError

  /**
   * Flag to see if any of the checked items are folders
   */
  const foldersSelected = useMemo(
    () =>
      checkedItems?.some((id) => {
        const course = courses.find((course) => course.id === id)
        return course && course.item_type === "folder"
      }),
    [checkedItems, courses]
  )

  /**
   * Update the array of checked courses based on check/unchecking
   * @param event - Checkbox change event
   * @param id - ID of course that is been checked/unchecked
   */
  const showMoreButtonVisible =
    (showGrid && visibleTiles.length < courses.length) ||
    (showList && visibleListItems.length < courses.length)

  const showMoreButton = (
    <>
      {showMoreButtonVisible && (
        <Box
          height={(theme) => theme.spacing(8)}
          padding={1}
          display="flex"
          justifyContent="center"
        >
          <Button size="small" variant="text" onClick={showMore}>
            Show More
          </Button>
        </Box>
      )}
    </>
  )

  return (
    <>
      <Stack gap={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Courses</Typography>
          <Stack direction="row" gap={1}>
            {foldersEnabled && (
              <Button
                disabled={currentFolder != null}
                variant="outlined"
                color="primary"
                onClick={onCreateFolder}
              >
                Add Folder
              </Button>
            )}
            <Button
              startIcon={<PostAdd />}
              variant="contained"
              color="primary"
              data-cy="create-course-button"
              onClick={handleCreateCourseClicked}
            >
              Add Course
            </Button>
          </Stack>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={2}>
            <CoursesViewToggle
              isGridView={isGridView}
              toggleView={toggleView}
            />
            <CoursesSortSelect
              options={sortOptions}
              sortBy={sortBy}
              handleSelectSortOption={handleSelectSortOption}
            />
          </Box>
        </Box>
        <Collapse in={checkedItems.length > 0}>
          <Box
            display="flex"
            marginBottom={1}
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" gap={1}>
              <Button
                size="small"
                disabled={foldersSelected}
                onClick={() => onMove(checkedItems)}
                startIcon={<DriveFileMove />}
                variant="outlined"
              >
                Move {pluralize("item", checkedItems.length, true)} to a folder
              </Button>
              <Button
                size="small"
                onClick={() => onTrash(checkedItems)}
                startIcon={<Delete />}
                variant="outlined"
              >
                Move {pluralize("item", checkedItems.length, true)} to Trash
              </Button>
            </Stack>

            <Stack direction="row">
              <Button
                size="small"
                onClick={() => {
                  setCheckedItems(map("id", courses))
                }}
              >
                Select All
              </Button>
              <Button size="small" onClick={() => setCheckedItems([])}>
                Deselect All
              </Button>
            </Stack>
          </Box>
        </Collapse>
      </Stack>

      <FolderNavBar
        displaySearchResults={displaySearchResults}
        folder={currentFolder}
        handleBackClicked={handleExitDirectory}
        items={courses}
      />
      {showGrid && (
        <CoursesGrid
          items={visibleTiles}
          showMoreButton={showMoreButton}
          {...props}
        />
      )}
      {showList && (
        <CoursesList
          items={visibleListItems}
          showMoreButton={showMoreButton}
          {...props}
        />
      )}
      <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
    </>
  )
}

export default CoursesDisplay
