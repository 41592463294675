import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
} from "@reduxjs/toolkit"
import retry from "async-retry"
import { isNil } from "lodash"
import { AppState } from "../.."
import axios from "../../../api/axios"
import { Detection, RemarksState } from "../remarks"
import remarksAdaptor from "../remarksAdaptor"
import normalizeDetection from "../utils/normalizeDetection"
import { ServerDetectionData } from "./fetchDetections"
import { isSectionDetection } from "../../../utilities/remarkUtils"

const DETECTIONS_ENDPOINT =
  process.env.REACT_APP_DEV_DETECTIONS_ENDPOINT == null
    ? "/api/detections/"
    : process.env.REACT_APP_DEV_DETECTIONS_ENDPOINT

type RequestData = {
  html: string
  offset: number
  timeout: number
}

const fetchSectionDetectionsProgress = createAction(
  "section_detections/fetch/progress",
  (
    payload: { detections: Detection[]; lastCompletedPath: number[] | null },
    meta: { requestId: string }
  ) => {
    return { payload, meta }
  }
)

export const fetchSectionDetections = createAsyncThunk<
  Detection[],
  { html: string; readOnly: boolean },
  { state: AppState }
>(
  "section_detections/fetch",
  async ({ html, readOnly }, context): Promise<Detection[]> => {
    let nextOffset: number | null = 0
    const detections: Detection[] = []

    // Don't proceed with fetching detections if we are viewing as readOnly
    if (readOnly) {
      return []
    }

    const { dispatch, requestId, getState } = context

    let retries = 0
    while (
      retries < 3 &&
      !isNil(nextOffset) &&
      getState().remarks.activeSectionDetectionsRequest === requestId
    ) {
      const { data }: { data: ServerDetectionData } =
        await makeFetchSectionDetectionsRequest({
          html,
          offset: nextOffset,
          timeout: 10,
        })

      const hasAdvanced = data.next_offset !== nextOffset
      retries = !hasAdvanced ? retries + 1 : 0

      const lastCompletedPath: number[] | null =
        data.last_completed_path ?? null
      nextOffset = data.next_offset
      const responseDetections = normalizeDetection(data.detections)

      detections.push(...responseDetections)

      dispatch(
        fetchSectionDetectionsProgress(
          { detections, lastCompletedPath },
          { requestId }
        )
      )
    }

    return detections
  }
)

/**
 * Build reducer cases for the handling fetched detections.
 *
 * @param builder A redux case builder.
 */
export const buildCasesForFetchSectionDetections = (
  builder: ActionReducerMapBuilder<RemarksState>
) => {
  builder.addCase(fetchSectionDetections.pending, (state, action) => {
    state.activeSectionDetectionsRequest = action.meta.requestId
  })
  builder.addCase(fetchSectionDetectionsProgress, (state, action) => {
    const isActiveRequest =
      state.activeSectionDetectionsRequest === action.meta.requestId

    if (!isActiveRequest) {
      return
    }
    const {
      payload: { detections, lastCompletedPath },
    } = action

    const idsToRemove = selectStaleDetectionIdsForBatch(
      state,
      detections,
      lastCompletedPath
    )

    remarksAdaptor.removeMany(state, idsToRemove)
    remarksAdaptor.addMany(state, detections)
    if (action.payload.detections.length > 0) {
      state.sectionDetectionsLoading = false
    }
  })
  builder.addCase(fetchSectionDetections.fulfilled, (state, action) => {
    if (state.activeSectionDetectionsRequest === action.meta.requestId) {
      remarksAdaptor.removeMany(
        state,
        selectStaleDetectionIds(state, action.payload)
      )
      state.status = "fulfilled"
      state.sectionDetectionsLoading = false
    }
  })
}

/**
 * Request section-based detections for the given html.
 * @param data The request data.
 */
const makeFetchSectionDetectionsRequest = (data: RequestData) => {
  return retry(
    () => {
      return axios.post<any>(DETECTIONS_ENDPOINT + "section_detections", data, {
        baseURL: "/",
      })
    },
    { retries: 5 }
  )
}

/**
 * Select any remaining stale detections.
 *
 * @param state The current state.
 * @param newDetections A complete set of fetched detections.
 */
const selectStaleDetectionIds = (
  state: RemarksState,
  newDetections: Detection[]
) => {
  const freshIds = new Set(newDetections.map(({ id }) => id))

  const staleDetections = remarksAdaptor
    .getSelectors()
    .selectAll(state)
    .filter(
      (remark) =>
        remark?.remarkType === "detection" &&
        isSectionDetection(remark) &&
        !freshIds.has(remark.id)
    )

  return staleDetections.map(remarksAdaptor.selectId)
}

/**
 * Select any stale detections given a new set of detections.
 *
 * @param state The current state.
 * @param newDetections The detection batch.
 * @param lastCompletedPath The path of the latest fully complete block.
 */
const selectStaleDetectionIdsForBatch = (
  state: RemarksState,
  newDetections: Detection[],
  lastCompletedPath: number[] | null
) => {
  if (!newDetections.length) {
    return []
  }

  const newIds = new Set(newDetections.map(({ id }) => id))
  const pathMax =
    lastCompletedPath ??
    newDetections
      .map(({ location: { path } }) => path)
      .reduce((a, b) => (comparePaths(b, a) === 1 ? b : a))

  const staleDetections = remarksAdaptor
    .getSelectors()
    .selectAll(state)
    .filter(
      (remark) =>
        remark?.remarkType === "detection" &&
        isSectionDetection(remark) &&
        comparePaths(remark.location.path, pathMax) !== 1 &&
        !newIds.has(remark.id)
    )

  return staleDetections.map(remarksAdaptor.selectId)
}

/**
 * Deterine the relative sort order of two values.
 *
 * @param a - The first value.
 * @param b - The second value.
 * @returns -1 if a < b, 1 if a > b, 0 if a === b.
 */
const compare = (a: number, b: number) => (a < b ? -1 : a > b ? 1 : 0)

/**
 * Deterine the relative sort order of two paths.
 *
 * @param a - The first path.
 * @param b - The second path.
 * @returns -1 if a < b, 1 if a > b, 0 if a === b.
 */
const comparePaths = (a: number[], b: number[]): 1 | 0 | -1 => {
  if (!a.length && !b.length) {
    return 0
  }

  const [aHead = -1, ...aRest] = a
  const [bHead = -1, ...bRest] = b

  return compare(aHead, bHead) || comparePaths(aRest ?? [], bRest ?? [])
}
