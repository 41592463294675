import MicrolearningScreen from "../features/microlearning/MicrolearningScreen"

/**
 * Container wrapping Micro-Learning screen and legacy form.
 * @param props - Component props
 */
const MicrolearningPage = (props) => {
  return <MicrolearningScreen {...props} />
}

export default MicrolearningPage
