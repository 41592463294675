import { gateway } from "./axios"
import { IMPORT_API_ENDPOINT } from "./importEndpoint"

/**
 * Get import jobs from the import service
 * @param since - date time
 */
const fetchImportJobs = async (since: any) => {
  if (since == null) {
    const sinceDate = new Date()
    sinceDate.setDate(sinceDate.getDate() - 1)
    since = sinceDate.toISOString()
  }
  try {
    const resp = await gateway.get<any>(IMPORT_API_ENDPOINT, {
      baseURL: "/",
      params: { since },
    })
    return resp
  } catch (err) {
    console.error(err)
    return null
  }
}

export default fetchImportJobs
