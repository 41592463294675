import styled from "@emotion/styled"
import Button from "@mui/material/Button"
import { AddComment } from "@mui/icons-material"

const StyledButton = styled(
  Button,
  {}
)(({ variant }) => ({
  ...(variant === "contained" && {
    color: "white",
  }),
}))

const AddCommentButton = (props) => {
  return (
    <StyledButton
      color="addition"
      {...props}
      startIcon={<AddComment fontSize="small" />}
    >
      Add Comment
    </StyledButton>
  )
}

export default AddCommentButton
