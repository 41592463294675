import { createAsyncThunk } from "@reduxjs/toolkit"
import { selectCommentById } from "../.."
import remarksAdaptor from "../remarksAdaptor"
import selectCommentId from "../selectors/selectCommentId"
import selectCurrentCourse from "../selectors/selectCurrentCourse"
import selectServerId from "../selectors/selectServerId"
import normalizeComment from "../utils/normalizeComments"
import selectGuestEmail from "../selectors/selectGuestEmail"

/**
 * @typedef {import("../remarks").Comment} Comment
 * @typedef {{
 *   extra: {
 *     api: {
 *       editComment: ({ courseId: string, id: string, text: string }) => { id: string }
 *     }
 *   }
 *   state: import("../..").AppState
 *   rejectedMeta: { original: Comment }
 *   rejectValue: Error
 * }} ThunkApiConfig
 * @typedef {{ id: string, text: string }} ThunkArg
 */

/**
 * Builds an action for editing the text of a comment.
 *
 * @type {import("@reduxjs/toolkit").AsyncThunk<Comment, ThunkArg, ThunkApiConfig>}
 */
const editComment = createAsyncThunk("comments/edit", async (arg, context) => {
  const {
    getState,
    extra: { api },
    rejectWithValue,
  } = context

  const state = getState()

  const courseId = selectCurrentCourse(state.remarks)
  const id = selectServerId(arg.id)
  const text = arg.text

  const guestEmail = selectGuestEmail(state.remarks)
  const guest = guestEmail ? { email: guestEmail } : null

  const original = selectCommentById(state, id)

  try {
    return normalizeComment(
      await api.editComment({ courseId, id, text, as: guest })
    )
  } catch (e) {
    return rejectWithValue(e, { original })
  }
})

export default editComment

/**
 * Builds the reducer cases for editting a comment.
 *
 * @param {import("@reduxjs/toolkit").ActionReducerMapBuilder<import("../remarks").RemarksState>} builder
 */
export const buildCasesForEditComment = (builder) => {
  builder
    .addCase(editComment.pending, (state, action) => {
      const { id, text } = action.meta.arg

      remarksAdaptor.updateOne(state, {
        id: selectCommentId(id),
        changes: { text },
      })
    })
    .addCase(editComment.rejected, (state, action) => {
      remarksAdaptor.setOne(state, action.meta.original)
    })
}
