import { useAuthenticator } from "@aws-amplify/ui-react"
import { useMemo } from "react"
import { useLocation } from "react-router"
import {
  ConfirmResetPassword,
  ConfirmUsername,
  ForceNewPassword,
  ResetPassword,
  SignInWithPassword,
} from "./forms"

/**
 * Retrieve the component for a given authentication route.
 * @param route An authenication route.
 * @param requiresPassword Indicates whether password authentication is required.
 */
const getRouteComponent = (route: string, requiresPassword: boolean) => {
  switch (route) {
    case "authenticated":
    case "idle":
    case "setup":
    case "transition":
    case "verifyUser":
    case "confirmVerifyUser":
    case "confirmSignUp":
    case "confirmSignIn":
    case "setupTOTP":
      return RenderNothing
    case "signUp":
    case "signIn":
      return requiresPassword ? SignInWithPassword : ConfirmUsername
    case "forceNewPassword":
      return ForceNewPassword
    case "resetPassword":
      return ResetPassword
    case "confirmResetPassword":
      return ConfirmResetPassword

    default:
      // eslint-disable-next-line no-console
      console.warn(
        `Unhandled Authenticator route - please open an issue: ${route}`
      )
      return RenderNothing
  }
}

type LocationState = { requiresPassword?: boolean }

/**
 * Renders the current authentication screen.
 */
export function Router() {
  const { route } = useAuthenticator(({ route }) => [route])
  const location = useLocation<LocationState>()
  const requiresPassword = location.state?.requiresPassword ?? false

  const Component = useMemo(
    () => getRouteComponent(route, requiresPassword),
    [route, requiresPassword]
  )

  return <Component key={route} />
}

export default Router

/**
 * Render nothing.
 */
function RenderNothing(): JSX.Element {
  // @ts-ignore
  return null
}
