import axiosInstance from "../../../api/axios"

/**
 *
 * @param arg
 * @returns {Promise<AxiosResponse<any>>}
 */
const editLearningPath = (arg) => {
  const { pathId, title } = arg

  return axiosInstance
    .put(`/paths/${pathId}`, { title })
    .then((response) => response.data)
}

export default editLearningPath
