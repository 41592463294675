import { useCallback, useEffect, useState } from "react"
import {
  getContentSectionHeading,
  getSectionElements,
  stripTemporaryElements,
} from "../utilities/smartTemplates"
import * as api from "../api"
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import ReplayIcon from "@mui/icons-material/Replay"
import { find, values, flatten, isEmpty, slice, keys } from "lodash"
import {
  QuestionComponent,
  QuestionData,
  questionCustomElement,
} from "./questionCustomElement"
import { AddableItem } from "./AddableItem"
import AccessibilityWarning from "../components/atoms/AccessibilityWarning"

interface Question {
  id: string
  html: string
  data: QuestionData
}

interface Questions {
  [key: string]: Question[]
}

const questionEnum = {
  MULTIPLE_CHOICE: "multiple-choice-question",
  TRUE_FALSE: "true-false-question",
  FILL_IN_BLANK: "fill-in-the-blank-question",
  MULTIPLE_CHOICE_SCENARIO_BASED: "multiple-choice-scenario-based",
  MATCHING_QUESTION: "matching-question",
}

const allFilters: string[] = values(questionEnum)

const questionTypes = [
  {
    component: questionEnum.MULTIPLE_CHOICE,
    label: "Multiple Choice",
  },
  {
    component: questionEnum.TRUE_FALSE,
    label: "True/False",
  },
  {
    component: questionEnum.FILL_IN_BLANK,
    label: "Fill-in-the-blank",
  },
  {
    component: questionEnum.MULTIPLE_CHOICE_SCENARIO_BASED,
    label: "Scenario-based Multiple Choice",
  },
  {
    component: questionEnum.MATCHING_QUESTION,
    label: "Matching Question",
  },
]

/**
 * Sort the collection of HTML elements into subsets based on question type
 * @param questionElements - Array of all questions in HTML form
 */
const sortQuestions = (questionElements: HTMLElement[]) => {
  let result = {
    [questionEnum.MULTIPLE_CHOICE]: [],
    [questionEnum.TRUE_FALSE]: [],
    [questionEnum.FILL_IN_BLANK]: [],
    [questionEnum.MULTIPLE_CHOICE_SCENARIO_BASED]: [],
    [questionEnum.MATCHING_QUESTION]: [],
  } as Questions

  questionElements.forEach((element) => {
    const component = element.dataset.component as
      | "multiple-choice-question"
      | "true-false-question"
      | "fill-in-the-blank-question"
      | "matching-question"

    /**
     * Special case for scenario-based questions. If a multiple choice question has more than 1 sentence,
     * consider it scenario-based and sort it into that category. This only affects filtering, they are
     * otherwise treated the same as normal multiple choice questions.
     */
    if (result[component]) {
      const questionType =
        component === "multiple-choice-question" &&
        element.innerText.includes(". ")
          ? questionEnum.MULTIPLE_CHOICE_SCENARIO_BASED
          : component

      result[questionType].push({
        id: element.dataset.generatedAs as string,
        html: element.outerHTML,
        data: questionCustomElement.getDataFromElement(element),
      })
    }
  })
  return result
}

/**
 * Construct an object containing subsets of questions, based on the question
 * type filters applied and the selection index.
 *
 * @param questions - Object containing all questions, separated by type
 * @param filters - Array of strings containing selected question type filters
 * @param selectionIndex - The "page" that is currently being viewed for the questions.
 */
const filterQuestions = (
  questions: Questions,
  filters: string[],
  selectionIndex: number
) => {
  let result = {} as Questions

  if (isEmpty(questions)) {
    return result
  }

  // Determine how many questions of each type to display, based on number of filters
  const numQuestionsPerCategory = Math.floor(
    (questionTypes.length * 2) / filters.length
  )

  /**
   * Populate the results object with a set of questions, based on the applied filters and
   * current selectionIndex.
   */
  filters.forEach((questionType) => {
    const questionSubset = questions[questionType]

    if (!questionSubset) {
      return
    }

    const startIndex =
      (selectionIndex * numQuestionsPerCategory) % questionSubset.length

    result[questionType] = slice(
      questionSubset,
      startIndex,
      startIndex + numQuestionsPerCategory
    )
  })
  return result
}

/**
 * Component for rendering dynamic sets of test questions.
 *
 * @param props - Component props
 * @param props.editor - Froala editor object
 * @param props.element - Element to create test questions for
 * @param props.onClose - Callback to close panel
 * @param props.withStyles - Function to style controls based on MUI theme
 */
const TestQuestionPanel = (props: {
  editor: any
  element: HTMLElement
  onClose: () => void
  withStyles: (children: React.ReactElement) => React.ReactElement
}) => {
  const { editor, element, onClose, withStyles } = props

  // True if loading
  const [loading, setLoading] = useState(false)

  // Server message
  const [message, setMessage] = useState("")

  const [error, setError] = useState<string | null>(null)

  // IDs of questions that have been added to editor
  const [addedIDs, setAddedIDs] = useState<string[]>([])

  // List of applied filters for different question types
  const [filters, setFilters] = useState<string[]>(allFilters)

  // Acts as pagination for test questions, so user can cycle through different questions
  const [selectionIndex, setSelectionIndex] = useState(0)

  // Object containing all fetched questions, separated by question type
  const [questions, setQuestions] = useState<Questions>({})

  const isAllSelected = filters.length === allFilters.length

  /**
   * Fetch test questions from backend
   */
  const generateItems = useCallback(async () => {
    try {
      setLoading(true)
      setError(null)

      // Get template heading
      const smartTemplateId = element.dataset.smartTemplateId!
      const smartTemplateHeading = document.getElementById(smartTemplateId)!

      // Get content section heading
      const contentSectionHeading =
        getContentSectionHeading(smartTemplateHeading)!

      let courseHtml = stripTemporaryElements(editor.html.get())

      const data = await api.createTemplate(
        "test_question",
        courseHtml,
        contentSectionHeading.id,
        null
      )

      // Extract message from server response
      setMessage(data.data.message ?? "")

      // Reset added indexes
      setAddedIDs([])

      // Get HTML
      const html = data.data.smart_template_html

      // Parse HTML to extract items
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, "text/html")

      const elements = [...doc.querySelectorAll("section")]

      setQuestions(sortQuestions(elements))
    } catch (err) {
      console.log(err)
      setError(
        `There was an error generating Test Questions. Please try again later, and contact LearnExperts if the problem persists.`
      )
    } finally {
      setLoading(false)
    }
  }, [editor.html, element.dataset.smartTemplateId])

  useEffect(() => {
    generateItems()
  }, [generateItems])

  /** Add item to document below control
   * @param item - The item to add.
   */
  function handleAdd(item: Question) {
    // Get template heading
    const smartTemplateId = element.dataset.smartTemplateId!
    const smartTemplateHeading = document.getElementById(smartTemplateId)!

    // Get existing smart template section elements
    const existingSectionElements = getSectionElements(smartTemplateHeading)

    // Add to end of last element in section
    const lastSectionElement =
      existingSectionElements[existingSectionElements.length - 1]
    lastSectionElement.insertAdjacentHTML("afterend", item.html)

    // Remove blank line if it is the last existing element
    const lastExistingElement =
      existingSectionElements[existingSectionElements.length - 1]
    if (lastExistingElement && lastExistingElement.innerHTML === "<br>") {
      lastExistingElement.remove()
    }

    // Remove blank line if it is the first existing element after the content section heading
    const firstExistingElement = existingSectionElements[1]
    if (
      firstExistingElement &&
      firstExistingElement !== lastExistingElement &&
      firstExistingElement.innerHTML === "<br>"
    ) {
      firstExistingElement.remove()
    }

    // Add to list of added indexes
    setAddedIDs((addedIDs) => [...addedIDs, item.id])

    // Save undo step in editor
    editor.undo.saveStep()
  }

  const filteredQuestions = filterQuestions(
    questions,
    filters,
    selectionIndex
  ) as Questions

  const questionList = flatten(values(filteredQuestions))
  const hasQuestions = questionList.length > 0

  return withStyles(
    <Stack
      borderRadius={2}
      sx={{
        /**
         * Set background colour to shade of theme grey
         * @param theme - MUI theme
         */
        backgroundColor: (theme) => theme.palette.grey[100],
        overflow: "hidden",
      }}
    >
      <Box
        key="content"
        sx={{
          /**
           * Set height of scrollable content window to MUI based units
           * @param theme - MUI theme
           */
          height: (theme) => theme.spacing(40),
          overflow: "auto",
        }}
      >
        {loading ? (
          <Stack
            gap={3}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
            <Typography variant="body2">
              Generating Test Questions...
            </Typography>
          </Stack>
        ) : error ? (
          <Stack
            gap={2}
            padding={2}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography variant="body2">{error}</Typography>
            <Button
              variant="text"
              onClick={async () => {
                await generateItems()
              }}
              startIcon={<ReplayIcon />}
            >
              Retry
            </Button>
          </Stack>
        ) : filters.length > 0 ? (
          <List>
            {keys(filteredQuestions).map((questionType) => {
              const title = find(
                questionTypes,
                (o) => o.component === questionType
              )?.label
              const questionSubset = filteredQuestions[questionType]

              const isMatching = questionType === questionEnum.MATCHING_QUESTION

              return (
                <>
                  <ListItem>
                    <ListItemText>
                      <Typography variant="body2" fontWeight={700}>
                        {title}
                      </Typography>
                    </ListItemText>
                    {isMatching && (
                      <AccessibilityWarning
                        placement="left-end"
                        disablePortal
                      />
                    )}
                  </ListItem>
                  {questionSubset.map((item: Question) => {
                    return (
                      <AddableItem
                        key={item.id}
                        added={addedIDs.includes(item.id)}
                        onAdd={handleAdd.bind(null, item)}
                      >
                        <QuestionComponent data={item.data} />
                      </AddableItem>
                    )
                  })}
                </>
              )
            })}
          </List>
        ) : (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <Typography variant="body2">
              Select at least 1 question type to view generated Test Questions.
            </Typography>
          </Stack>
        )}
      </Box>
      <Box
        sx={{
          /**
           * Set background to be shade of MUI grey
           * @param theme - MUI theme
           */
          backgroundColor: (theme) => theme.palette.grey[200],
        }}
      >
        {message !== "" && !loading && (
          <Alert severity="warning">{message}</Alert>
        )}
        {loading || error ? (
          <Box display="flex" justifyContent="flex-end" padding={2}>
            <Button
              onClick={() => {
                onClose()
              }}
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between" padding={2}>
            <Stack direction="row" gap={1}>
              <FormControl sx={{ width: 200 }} size="small">
                <InputLabel id="question-type-filter">Question Type</InputLabel>
                <Select
                  multiple
                  labelId="question-type-filter"
                  value={filters}
                  renderValue={(selected) => {
                    if (selected.length === allFilters.length) {
                      return "All Question Types"
                    }

                    return selected
                      .map(
                        (questionType) =>
                          find(
                            questionTypes,
                            (o) => o.component === questionType
                          )?.label
                      )
                      .join(", ")
                  }}
                  onChange={(e: any) => {
                    const { value }: { value: string[] } = e.target

                    // Condition if "Select/Deselect All" was checked
                    if (value[value.length - 1] === "all") {
                      setFilters((prev) =>
                        prev.length === allFilters.length ? [] : allFilters
                      )
                      return
                    }
                    setFilters(value)
                  }}
                  input={<OutlinedInput label="Question Type" />}
                  MenuProps={{
                    disablePortal: true,
                  }}
                  inputProps={{ "aria-label": "Test Question Filters" }}
                >
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          filters.length > 0 &&
                          filters.length < allFilters.length
                        }
                      />
                    </ListItemIcon>
                    {isAllSelected ? "Deselect All" : "Select All"}
                  </MenuItem>
                  {questionTypes.map(({ component, label }) => {
                    return (
                      <MenuItem key={component} value={component}>
                        <ListItemIcon>
                          <Checkbox checked={filters.indexOf(component) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={label} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row">
              <Button
                disabled={!hasQuestions}
                variant="text"
                onClick={() => {
                  questionList.forEach((item) => {
                    if (!addedIDs.includes(item.id)) {
                      handleAdd(item)
                    }
                  })
                }}
                startIcon={<AddIcon />}
              >
                Add All
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setSelectionIndex((prev) => prev + 1)
                }}
                startIcon={<ReplayIcon />}
              >
                Regenerate
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  onClose()
                }}
                startIcon={<CloseIcon />}
              >
                Close
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default TestQuestionPanel
