import exportMicroLearning from "../api/exportMicroLearning"
import waitForJob from "./waitForJob"
import { microlearningAsyncStatusEnum } from "../utils/constants"
import * as api from "../../../api"

/**
 * Start the download for the generated microlearning file
 *
 * Note that this download requires signed cookies
 * for AWS Cloudfront origin authentication
 * (which are updated in the api call)
 *
 * @param {*} url - the file URL
 */
const downloadURL = async (url: string) => {
  await api.downloadFile(url)
}

/**
 * Submit the async job to build the files for microlearning and return a URl
 * for download
 * @param data - All options for how the microlearning is assembled
 */
const submitMicroLearningExport = async (data: any) => {
  const jobId = await exportMicroLearning(data).then(
    ({ data }) => data.async_id
  )

  const microLearningResponse: any = await waitForJob(jobId, {
    completeStatus: microlearningAsyncStatusEnum.COMPLETE,
    failedStatus: microlearningAsyncStatusEnum.FAILED_NO_RETRY,
  })

  const { export_url } = microLearningResponse
  return await downloadURL(export_url)
}

export default submitMicroLearningExport
