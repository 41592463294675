import * as api from "../../../api"
import { useFlag } from "../../../utilities/feature-management"

/**
 * Use the new course import API if the flag is on
 */
function useCourseImportApi() {
  const rolloutServerlessImportAPI = useFlag("rollout-serverless-import-api")

  const importApi = createApi(rolloutServerlessImportAPI === true)

  return { ...importApi }
}

/**
 * Use the new course import API if the flag is on
 * @param rolloutServerlessImportAPI - feature flag
 */
const createApi = (rolloutServerlessImportAPI: boolean) => {
  if (rolloutServerlessImportAPI) {
    return {
      cancelImport: api.cancelImportJob,
    }
  } else {
    return {
      cancelImport: api.cancelCourseCreation,
    }
  }
}

export default useCourseImportApi
