import axiosGlobal from "./axios"

const verifyEmail = (email, config = {}) => {
  const { axios = axiosGlobal } = config

  return axios
    .get(`/verify-email`, {
      params: { email: email },
    })
    .then(({ data }) => data)
}

export default verifyEmail
