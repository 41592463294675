import { List, ListItem, ListItemText, Skeleton, styled } from "@mui/material"
import { memo } from "react"
import useDocumentStats from "./hooks/useDocumentStats"
import formatDuration from "./utils/formatDuration"
import pluralize from "./utils/pluralize"

const DEFAULT_INCLUDED_STATS = ["text", "media", "duration"]

/**
 * @typedef {object} DocumentSummaryProps
 * @property {Element?} container
 * @property {Element?} section
 * @property {includedStats}
 */

/**
 * Displays the reading stats for a document or document section.
 *
 * @type {(props: DocumentSummaryProps) => JSX.Element}
 */
const DocumentSummary = (props) => {
  const {
    container,
    section,
    includeStats: includedStats = DEFAULT_INCLUDED_STATS,
    ...otherProps
  } = props

  const caption = section?.innerText ?? ""
  const {
    sectionCount,
    wordCount,
    videoCount,
    imageCount,
    questionCount,
    duration,
  } = useDocumentStats(container, section)

  const showTextStats = includedStats.includes("text")
  const showMediaStats = includedStats.includes("media")
  const showDurationStat = includedStats.includes("duration")

  return (
    <DocumentSummaryRoot {...otherProps}>
      {caption && <figcaption>{caption}</figcaption>}
      <List dense disablePadding>
        {showTextStats && (
          <>
            <Stat
              value={[
                pluralize("Word", wordCount, true),
                pluralize("Section", sectionCount, true),
              ].join(" — ")}
            />
            <Stat value={pluralize("Question", questionCount, true)} />
          </>
        )}
        {showMediaStats && (
          <Stat
            value={
              [
                videoCount && pluralize("Video", videoCount, true),
                imageCount && pluralize("Image", imageCount, true),
              ]
                .filter(Boolean)
                .join(" — ") || "No media"
            }
          />
        )}
        {showDurationStat && (
          <Stat
            sx={{ span: { fontWeight: section ? 300 : 600 } }}
            value={
              duration == null ? (
                <Skeleton />
              ) : (
                formatDuration(duration.as("minutes"))
              )
            }
          />
        )}
      </List>
    </DocumentSummaryRoot>
  )
}

export default memo(DocumentSummary)

/**
 * Root container for the DocumentSummary.
 */
const DocumentSummaryRoot = styled("figure")(({ theme }) => ({
  margin: 0,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: "block",
  fontSize: theme.typography.body2.fontSize,
  figcaption: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  },
}))

/**
 * @typedef {Object} StatProps
 * @property {string} value Some statistic of the document.
 */

/**
 * Displays a document statistic.
 *
 * @type {(props: StatProps) => JSX.Element}
 */
const Stat = (props) => {
  const { value, ...otherProps } = props

  return (
    <ListItem disableGutters disablePadding {...otherProps}>
      <ListItemText primary={value} sx={{ textAlign: "center" }} />
    </ListItem>
  )
}
