import axiosInstance from "../../../api/axios"

/**
 *
 * @param arg
 */
const getLearningPathById = (arg) => {
  const { pathId } = arg

  return axiosInstance.get(`/paths/${pathId}`).then((response) => response.data)
}

export default getLearningPathById
