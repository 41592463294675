import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import { getTranslatedPreview } from "../../api"
import CloseIcon from "@mui/icons-material/Close"
import Editor from "../../components/widgets/Editor"
import { CustomElements } from "../../custom-elements/CustomElements"
import { questionCustomElement } from "../../custom-elements/questionCustomElement"
import { flipCardGridCustomElement } from "../../custom-elements/flipCardGridCustomElement"
import { labeledImageCustomElement } from "../../custom-elements/labeledImageCustomElement"
import { smartTemplateControlCustomElement } from "../../custom-elements/smart_templates/smartTemplateControlCustomElement"
import { tabsCustomElement } from "../../custom-elements/tabsCustomElement"
import { categoriesCustomElement } from "../../custom-elements/categoriesCustomElement"
import { processCustomElement } from "../../custom-elements/processCustomElement"
import { styledListCustomElement } from "../../custom-elements/StyledList/styledListCustomElement"
import { calloutBoxCustomElement } from "../../custom-elements/calloutBoxCustomElement"
import { useAuth } from "../../contexts/authorization"
import { useStableCallback } from "../../hooks/useStableCallback"

export interface TranslationPreviewModalProps {
  onClose: () => void
  courseId: string
  courseHtml: string
  language: string
}

/**
 * Translation preview modal.
 * @param props - The props for the component.
 * @returns The component.
 */
export function TranslationPreviewModal(props: TranslationPreviewModalProps) {
  const { onClose, courseId, courseHtml, language } = props

  const [translatedContent, setTranslatedContent] = useState<string | null>(
    null
  )
  const [editor, setEditor] = useState<any>(null)
  const [editorWrapper, setEditorWrapper] = useState<HTMLDivElement | null>(
    null
  )

  const cancelledRef = useRef(false)

  useEffect(() => {
    return () => {
      cancelledRef.current = true
    }
  }, [])

  const {
    user: { id: tenantId },
  } = useAuth() as any

  const fetchContent = useStableCallback(async () => {
    try {
      while (!cancelledRef.current) {
        const content = await getTranslatedPreview(
          tenantId,
          courseId,
          courseHtml,
          language
        )
        if (content) {
          setTranslatedContent(content)
          break
        }
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    } catch (error) {
      alert("Failed to fetch translated course: " + error)
      onClose()
    }
  })

  useEffect(() => {
    fetchContent()
  }, [fetchContent])

  const customElementConfigs = [
    questionCustomElement,
    flipCardGridCustomElement,
    labeledImageCustomElement,
    smartTemplateControlCustomElement,
    tabsCustomElement,
    categoriesCustomElement,
    processCustomElement,
    styledListCustomElement,
    calloutBoxCustomElement,
  ]

  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Preview</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          flexDirection="column"
          maxWidth="800px"
          margin="0 auto"
        >
          {translatedContent !== null ? (
            <div
              ref={setEditorWrapper}
              style={{ flex: "1 1 auto", height: "100%", display: "flex" }}
            >
              <Editor
                // @ts-ignore
                sx={{ flex: "1 1 auto" }}
                model={translatedContent}
                readOnly={true}
                onModelChange={() => {}}
                onInitialize={() => {}}
                onDestroy={() => {}}
                ref={setEditor}
              />
              {editor && editorWrapper && (
                <CustomElements
                  editor={editor}
                  editorDiv={editorWrapper}
                  configs={customElementConfigs}
                  readOnly={true}
                />
              )}
            </div>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                gap: 2,
              }}
            >
              <CircularProgress size={60} thickness={4} />
              <Typography variant="h6" align="center">
                Translating your content...
              </Typography>
              <Typography variant="body1" align="center" color="text.secondary">
                This may take several minutes. Please wait.
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
