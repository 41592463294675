import PropTypes from "prop-types"
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material"
import { styled } from "@mui/system"

/** Root in Breadcrumbs. */
const BreadcrumbsRoot = styled(MuiBreadcrumbs)(({ theme }) => ({
  "& .MuiBreadcrumbs-li:last-child": {
    color: theme.palette.text.primary,
  },
}))

/** Link elements in Breadcrumbs. */
const BreadcrumbsLink = (props) => {
  const { href, label, ...otherProps } = props

  const Component = !!href ? Link : Typography
  const linkProps = href && { href, underline: "hover" }
  const rootProps = {
    children: label,
    color: "inherit",
    ...linkProps,
    ...otherProps,
  }

  return <Component {...rootProps} />
}

const keyForBreadcrumb = (breadcrumb) => breadcrumb.label

/**
 * Display breadcrumb trail for quickly navigating home.
 *
 * @example
 * ```js
 * <Breadcrumbs
 *   breadcrumbs={[
 *     { label: "Courses", href: "/"},
 *     { label: "FAQ" },
 *   ]}
 * />
 * ```
 */
const Breadcrumbs = (props) => {
  const { breadcrumbs = [], ...otherProps } = props

  const isEmpty = !breadcrumbs || !breadcrumbs.length

  return isEmpty ? null : (
    <BreadcrumbsRoot aria-label="breadcrumb" {...otherProps}>
      {breadcrumbs.map((breadcrumb) => (
        <BreadcrumbsLink key={keyForBreadcrumb(breadcrumb)} {...breadcrumb} />
      ))}
    </BreadcrumbsRoot>
  )
}

Breadcrumbs.propTypes = {
  /**
   * An optional list of navigation breadcrumbs to display.
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * A label for the breadcrumb location.
       */
      label: PropTypes.string.isRequired,
      /**
       * A URL for the breadcrumb location.
       *
       * Can be excluded to display a non-navigable crumb.
       * */
      href: PropTypes.string,
    })
  ),
}

export default Breadcrumbs
