import { useMemo } from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import detectionsConfig from "../../../config/detections"
import useRemarksFilter from "../../../hooks/useRemarksFilter"
import { useFlag } from "../../../utilities/feature-management"

/**
 * Control for filtering remarks by type.
 */
const RemarksTypeFilter = () => {
  const { type, filterByType } = useRemarksFilter()
  const onChange = (event) => filterByType(event.target.value)

  const interactiveComponentsEnabled = useFlag(
    "rollout-interactive-component-detections"
  )

  const typeFilterOptions = useMemo(() => {
    const options = [
      { value: "__all", label: "All" },
      { value: "comment", label: "Comment" },
    ]

    for (const [key, detection] of Object.entries(
      detectionsConfig.detections
    )) {
      if (interactiveComponentsEnabled || key !== "interactive_component") {
        options.push({
          value: `detection/${key}`,
          label: detection.title,
        })
      }
    }

    return options
  }, [interactiveComponentsEnabled])

  return (
    <FormControl variant="standard">
      <InputLabel>Type</InputLabel>
      <Select value={type} label="Filter" onChange={onChange}>
        {typeFilterOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RemarksTypeFilter
