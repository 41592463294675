import { createAsyncThunk } from "@reduxjs/toolkit"
import { DateTime } from "luxon"
import remarksAdaptor from "../remarksAdaptor"
import selectCommentId from "../selectors/selectCommentId"
import selectCurrentCourse from "../selectors/selectCurrentCourse"
import selectGuestEmail from "../selectors/selectGuestEmail"
import selectServerId from "../selectors/selectServerId"

/**
 * @typedef {import("../remarks").Comment} Comment
 *
 *
 * @typedef {{
 *   extra: {
 *     api: import("../../types").API
 *   }
 *   state: import("../..").AppState
 *   rejectedMeta: { original: Comment }
 *   rejectValue: Error
 * }} ThunkApiConfig
 * @typedef {{ id: string }} ThunkArg
 */

/**
 * Builds an action for resolving a comment.
 *
 * @type {import("@reduxjs/toolkit").AsyncThunk<void, ThunkArg, ThunkApiConfig>}
 */
export const resolveComment = createAsyncThunk(
  "comments/resolve",
  async (arg, context) => {
    const {
      getState,
      extra: { api },
    } = context

    const state = getState()

    const courseId = selectCurrentCourse(state.remarks)
    const id = selectServerId(arg.id)
    const guestEmail = selectGuestEmail(state.remarks)
    const guest = guestEmail ? { email: guestEmail } : undefined

    return api.resolveComment({ courseId, id, as: guest })
  }
)

export default resolveComment

/**
 * Builds the reducer cases for deleting a comment.
 *
 * @param {import("@reduxjs/toolkit").ActionReducerMapBuilder<import("../remarks").RemarksState>} builder
 */
export const buildCasesForResolveComment = (builder) => {
  builder
    .addCase(resolveComment.pending, (state, action) => {
      const { id } = action.meta.arg
      remarksAdaptor.updateOne(state, {
        id: selectCommentId(id),
        changes: {
          resolvedOn: DateTime.now().toISO(),
          resolveBy: { email: "" },
        },
      })
    })
    .addCase(resolveComment.fulfilled, (state, action) => {
      remarksAdaptor.updateOne(state, {
        id: selectCommentId(action.payload.id),
        changes: action.payload.changes,
      })
    })
    .addCase(resolveComment.rejected, (state, action) => {
      const { id } = action.meta.arg
      remarksAdaptor.updateOne(state, {
        id: selectCommentId(id),
        changes: {
          resolvedBy: null,
          resolvedOn: null,
        },
      })
    })
}
