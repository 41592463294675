import React, { useState } from "react"
import { DeleteForever, RestoreFromTrash, MoreHoriz } from "@mui/icons-material"
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material"

/**
 * TrashedCourseContextMenu function
 *
 * @param props props
 * @param props.document docuement
 * @param props.deleteCourse deleteCourse
 * @param props.restoreCourse restoreCourse
 */
const TrashedCourseContextMenu = (props: {
  document: any
  deleteCourse: any
  restoreCourse: any
}) => {
  const { document, deleteCourse, restoreCourse } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  /**
   * handleMenu function
   * @param event menu action event
   */
  const handleMenu = (event: any) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  /**
   * handleClose function
   * @param event close action event
   */
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <Box sx={{ cursor: "pointer" }} onClick={(e) => e.preventDefault()}>
      <Tooltip placement="top" arrow title="Course Actions">
        <IconButton onClick={handleMenu}>
          <MoreHoriz />
        </IconButton>
      </Tooltip>
      <Menu
        open={open}
        onClick={(e) => e.stopPropagation()}
        onClose={handleClose}
        anchorEl={anchorEl}
        data-cy="course-actions"
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            restoreCourse(document)
          }}
        >
          <ListItemIcon>
            <RestoreFromTrash fontSize="small" />
          </ListItemIcon>
          <Typography>Restore</Typography>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            deleteCourse(document)
          }}
        >
          <ListItemIcon>
            <DeleteForever fontSize="small" />
          </ListItemIcon>
          <Typography>Delete Forever</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default TrashedCourseContextMenu
