import { createContext, memo, useContext } from "react"
import useActiveSectionImpl from "../hooks/useActiveSection"

/** @type {import("react").Context<?Element>} */
export const ActiveSectionContext = createContext(null)

/**
 * @typedef {{ $el: Element[] }} EditorInterface
 *
 * @typedef {{
 *  editor: ?EditorInterface
 * }} ActiveSectionProviderProps
 */

/**
 * Provide a context for the active section of a given document.
 *
 * @param {ActiveSectionProviderProps} props
 * @returns {JSX.Element}
 */
const ActiveSectionProvider = (props) => {
  const { editor, ...otherProps } = props

  return (
    <ActiveSectionContext.Provider
      {...otherProps}
      value={useActiveSectionImpl(editor?.$el?.[0])}
    />
  )
}

export default memo(ActiveSectionProvider)

/**
 * Get the active section for the context document.
 *
 * Any time the cursor is moved the active section will be reevaluated.
 *
 * @returns {?Element}
 */
export const useActiveSection = () => useContext(ActiveSectionContext)
