import { Box, Skeleton, Typography } from "@mui/material"
import CourseContextMenu from "../../../welcome/CourseContextMenu"
import CourseTile from "./CourseTile"
import CreateCourseTile from "../../../welcome/CreateCourseTile"
import FolderBackButtonTile from "./FolderBackButtonTile"

/**
 * Placeholder skeleton for when the courses are loading
 */
const LoadingSkeleton = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      data-cy="loading-skeleton"
      sx={(theme) => ({
        width: theme.spacing(24),
        height: theme.spacing(36),
      })}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: "100%",
          height: "50%",
        }}
      />
      <Box marginTop={1}>
        <Skeleton variant="text" sx={{ width: "30%" }} />
        <Skeleton variant="text" />
        <Skeleton variant="text" sx={{ width: "50%" }} />
        <Skeleton variant="text" sx={{ marginTop: 2, width: "100%" }} />
      </Box>
    </Box>
  )
}

/**
 * Display component for courses in Grid layout
 * @param props - Component props
 */
const CoursesGrid = (props: any) => {
  const {
    items,
    loading,
    currentFolder,
    handleExitDirectory,
    handleItemSelected,
    handleCreateCourseClicked,
    onTrash,
    onRenameFolder,
    onDuplicate,
    onUploadImage,
    onMove,
    showMoreButton,
    displaySearchResults,
  } = props

  const emptySearchResults =
    !loading && items.length === 0 && displaySearchResults

  return (
    <>
      <Box display="flex" flexWrap="wrap" gap={3} data-cy="courses-display">
        {!loading ? (
          <>
            {currentFolder != null && (
              <FolderBackButtonTile
                isDragging={props.isDragging}
                onClick={handleExitDirectory}
              />
            )}
            {items.map((item: any) => (
              <CourseTile
                key={item.id}
                handleCourseSelected={handleItemSelected}
                item={item}
                {...props}
                menu={
                  <CourseContextMenu
                    document={item}
                    onTrash={onTrash}
                    onDuplicate={onDuplicate}
                    onUploadImage={onUploadImage}
                    onMove={onMove}
                    onRenameFolder={onRenameFolder}
                  />
                }
              />
            ))}
            {!displaySearchResults && (
              <CreateCourseTile
                tooltip="Add Course"
                onClick={handleCreateCourseClicked}
              />
            )}
          </>
        ) : (
          [...Array(items.length > 0 ? items.length : 6)].map((i, index) => (
            <LoadingSkeleton key={index} />
          ))
        )}
      </Box>
      {emptySearchResults && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={(theme) => theme.spacing(36)}
        >
          <Typography variant="body2">No courses match your search.</Typography>
        </Box>
      )}
      {showMoreButton}
    </>
  )
}

export default CoursesGrid
