import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import retry from "async-retry"

/**
 * Upload import file with retry
 * @param uploadUrl - signed cdn url
 * @param file - file object to upload
 * @param options - request config
 */
const uploadImportFileWithRetry = async (
  uploadUrl: string,
  file: File,
  options: AxiosRequestConfig
): Promise<AxiosResponse<any>> => {
  return await retry(
    async () => {
      try {
        const response = await axios.put(uploadUrl, file, options)
        console.log("Upload response:", response)
        if (response.status !== 200) {
          throw new Error(`Upload failed with status: ${response.status}`)
        }
        return response // Return response if successful
      } catch (error) {
        console.error("Upload error:", error)

        throw error // Rethrow the error to trigger a retry
      }
    },
    {
      retries: 3, // Number of retries
      minTimeout: 1000, // Minimum delay between retries in milliseconds
    }
  )
}

export default uploadImportFileWithRetry
