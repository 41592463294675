import { createSlice } from "@reduxjs/toolkit"

/**
 * @template T
 * @typedef {import("@reduxjs/toolkit").PayloadAction<T>} PayloadAction<T>
 */

/**
 * @typedef {{ id: string, email: string }} User
 * @typedef {{ currentUser: User }} AuthState
 */

/**
 * The initialState for the authentication state.
 * @type {AuthState}
 */
export const initialState = { currentUser: null }

/**
 * Reducer and actions for authentication state.
 */
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /**
     * Set the current authenticated user.
     *
     * @param {AuthState} state
     * @param {PayloadAction<User>} action
     */
    setUser: (state, action) => {
      state.currentUser = action.payload
    },
  },
})

export default authSlice

/** The authentication state reducer. */
export const reducer = authSlice.reducer

export const { setUser } = authSlice.actions
