import { useState } from "react"
import { CustomElements } from "../CustomElements"
import { flipCardGridCustomElement } from "../flipCardGridCustomElement"
import { tabsCustomElement } from "../tabsCustomElement"
import { labeledImageCustomElement } from "../labeledImageCustomElement"
import { categoriesCustomElement } from "../categoriesCustomElement"
import { processCustomElement } from "../processCustomElement"
import { calloutBoxCustomElement } from "../calloutBoxCustomElement"
import { styledListCustomElement } from "../StyledList/styledListCustomElement"
import { questionCustomElement } from "../questionCustomElement"

/**
 * Previews HTML as it will look in the editor with custom elements
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.html The HTML to preview.
 * @param props.includeCustomElements True if should include custom elements. Not necessary
 * if the component is already in the editor tree.
 */
export function PreviewHtmlContent(props: { editor: any; html: string }) {
  const { editor, html } = props

  // Content div
  const [contentDiv, setContentDiv] = useState<HTMLDivElement | null>(null)

  return (
    <div>
      {contentDiv != null && (
        // Only include custom elements that are preserved in rewrite
        <CustomElements
          configs={[
            flipCardGridCustomElement,
            tabsCustomElement,
            labeledImageCustomElement,
            categoriesCustomElement,
            processCustomElement,
            calloutBoxCustomElement,
            styledListCustomElement,
            questionCustomElement,
          ]}
          editor={editor}
          editorDiv={contentDiv}
          readOnly={true}
        />
      )}
      <div
        ref={setContentDiv}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
        style={{
          // Froala styles
          fontFamily: "sans-serif",
          color: "#414141",
          fontSize: "14px",
          lineHeight: 2,
          boxSizing: "border-box",
          textAlign: "left",
        }}
      />
    </div>
  )
}
