import { useEffect, useMemo, useState } from "react"
import fetchCourseBranding from "../api/fetchCourseBranding"
import muiTheme from "../../../themes/theme"
import { IBranding } from "./useBranding"
import { createTheme } from "@mui/material"

/**
 * Create an object containing course-level theming overrides.
 * @param branding -
 */
const getCourseTheme = (branding: IBranding | null) => ({
  courseTheme: {
    colour: branding?.colour ?? muiTheme.palette.primary.main,
  },
})

/**
 * Hook for creating theme based on branding config retrieved from backend.
 * Generated theme object is memoized so this will only occur once for course.
 * @param courseID - ID of course to obtain branding for
 */
const useCourseTheme = (courseID: string) => {
  const [branding, setBranding] = useState<IBranding | null>(null)
  useEffect(() => {
    if (!courseID) {
      return
    }
    fetchCourseBranding(courseID).then(setBranding)
  }, [courseID])

  const theme = useMemo(
    () =>
      branding
        ? createTheme({
            ...muiTheme,
            ...getCourseTheme(branding),
          })
        : muiTheme,
    [branding]
  )

  return {
    theme,
  }
}

export default useCourseTheme
