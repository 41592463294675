import { getScrollPane } from "../../../utilities/domUtils"

/**
 * Get the bounding rect for a given selection range.
 *
 * To correct for collapsed ranges giving inconsistent bounding rects, a
 * zero-width space is added while measuring them and removed once it is no
 * longer required.
 *
 * @param {Range} range
 */
const getRangeBoundingRect = (range) => {
  if (!range) {
    return null
  }

  const isCollapsed = range.collapsed
  if (isCollapsed) {
    range.insertNode(document.createTextNode("&#8203;"))
  }

  const rect = range.getBoundingClientRect?.()

  if (isCollapsed) {
    range.deleteContents()
  }

  return rect
}

/**
 * Scroll a container to bring the current selection into view.
 *
 * @param {Element} $container
 */
const scrollToSelection = ($container) => {
  const selection = window.getSelection()

  if (!selection || selection.rangeCount < 1) {
    return
  }

  const containerRect = $container?.getBoundingClientRect()
  const selectionRect = getRangeBoundingRect(selection.getRangeAt(0))

  if (!containerRect || !selectionRect) {
    return
  }

  if (
    selectionRect.top < containerRect.top ||
    selectionRect.bottom > containerRect.bottom
  ) {
    const amount =
      selectionRect.bottom -
      containerRect.bottom +
      (containerRect.bottom - containerRect.top) / 2 +
      20

    $container.scrollBy({ top: amount, behavior: "smooth" })
  }
}

/**
 * Move cursor to a given element.
 *
 * The cursor is positioned at the start of the element. Any previous selection
 * is removed.
 *
 * @param {Element} $element
 * @param {object} options
 * @param {boolean} options.scroll
 *  When enabled, scroll to the selection after updating it. Defaults to true.
 */
const moveCursorTo = ($element, options = {}) => {
  const { scroll = true } = options

  window.getSelection().setBaseAndExtent($element, 0, $element, 0)
  $element.closest('[contenteditable="true"]')?.focus()

  if (scroll) {
    scrollToSelection(getScrollPane($element))
  }
}

export default moveCursorTo
