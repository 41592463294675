import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Stack,
} from "@mui/material"
import { useMemo, useState } from "react"
import Button from "@mui/material/Button"
import { AddRoad, InfoOutlined } from "@mui/icons-material"
import useLearningPaths from "./hooks/useLearningPaths"
import LearningPath from "./LearningPath"
import CreateLearningPathDialog from "./dialogs/CreateLearningPathDialog"
import CreateFromTemplateDialog from "./dialogs/CreateFromTemplateDialog"
import ExampleDialog from "./dialogs/ExampleDialog"
import filterLearningPaths from "./utils/filterLearningPaths"
import { useFlag } from "../../utilities/feature-management"
import SortSelect from "../welcome/SortSelect"
import { sortList, sortOptions } from "../welcome/hooks/useCoursesDisplay"

/**
 * Component to render all Learning Paths along with functionality to create them
 * @param props - Component props
 * @param props.query - Optional text search query we will filter visible paths by
 */
const LearningPathsDisplay = ({ query }) => {
  const {
    createPath,
    deletePath,
    renamePath,
    addToPath,
    removeFromPath,
    shiftInPath,
    isLoading,
    pathTemplates,
    result: { learningPaths, error },
    sortBy,
    handleSelectSortOption,
  } = useLearningPaths()

  const [createEmptyDialogVisible, setCreateEmptyDialogVisible] =
    useState(false)
  const [createFromTemplateDialogVisible, setCreateFromTemplateDialogVisible] =
    useState(false)
  const [exampleDialogVisible, setExampleDialogVisible] = useState(false)

  const sortedPaths = useMemo(
    () => sortList(sortBy, learningPaths),
    [learningPaths, sortBy]
  )
  const filteredPaths = filterLearningPaths(sortedPaths, query)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const useTemplatedLearningPaths = useFlag("rollout-templated-learning-paths")

  const isEmpty = !isLoading && learningPaths?.length === 0
  const noResults =
    query &&
    !isLoading &&
    learningPaths?.length > 0 &&
    filteredPaths?.length === 0

  return (
    <>
      <Box>
        <Stack gap={1}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h4">Learning Paths</Typography>
              <Tooltip title="Learning Path Example">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault()
                    setExampleDialogVisible(true)
                  }}
                >
                  <InfoOutlined color="primary" />
                </IconButton>
              </Tooltip>
            </Box>
            <Button
              startIcon={<AddRoad />}
              color="primary"
              variant="contained"
              onClick={(e) => {
                e.preventDefault()
                // Only provide the menu option to create from template if the feature flag is toggled
                if (useTemplatedLearningPaths) {
                  setAnchorEl(e.currentTarget)
                } else {
                  setCreateEmptyDialogVisible(true)
                }
              }}
            >
              Add Learning Path
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  e.preventDefault()
                  setCreateEmptyDialogVisible(true)
                  setAnchorEl(null)
                }}
              >
                Create Empty Learning Path
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.preventDefault()
                  setCreateFromTemplateDialogVisible(true)
                  setAnchorEl(null)
                }}
              >
                Create Learning Path from Template
              </MenuItem>
            </Menu>
          </Box>
          {!isEmpty && (
            <Box>
              <SortSelect
                options={sortOptions}
                sortBy={sortBy}
                handleSelectSortOption={handleSelectSortOption}
              />
            </Box>
          )}
        </Stack>
        {isEmpty || noResults ? (
          <Box
            sx={{
              /**
               * Use MUI theme spacing to determine size
               * @param theme - MUI theme
               */
              height: (theme) => theme.spacing(12),
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2" gutterBottom>
              {noResults
                ? `No Learning Paths containing "${query}"`
                : `You don't have any Learning Paths yet. Click "Add Learning Path"to get started."`}
            </Typography>
          </Box>
        ) : (
          filteredPaths.map((path) => (
            <LearningPath
              error={error}
              deletePath={deletePath}
              renamePath={renamePath}
              addToPath={addToPath}
              removeFromPath={removeFromPath}
              shiftInPath={shiftInPath}
              key={path.path_id}
              path={path}
            />
          ))
        )}
      </Box>
      <CreateLearningPathDialog
        open={createEmptyDialogVisible}
        onConfirm={(name) => {
          createPath({ title: name })
          setCreateEmptyDialogVisible(false)
        }}
        onCancel={() => {
          setCreateEmptyDialogVisible(false)
        }}
      />
      <CreateFromTemplateDialog
        open={createFromTemplateDialogVisible}
        pathTemplates={pathTemplates}
        onConfirm={(template) => {
          createPath({ templateId: template.id })
          setCreateFromTemplateDialogVisible(false)
        }}
        onCancel={() => {
          setCreateFromTemplateDialogVisible(false)
        }}
      />
      <ExampleDialog
        open={exampleDialogVisible}
        onCancel={(e) => {
          e.preventDefault()
          setExampleDialogVisible(false)
        }}
      />
    </>
  )
}

export default LearningPathsDisplay
