import { clamp } from "lodash"
import { join, takeWhile } from "lodash/fp"

/**
 * Navigate to next element, regardless of section depth
 * @param {Element} $element - Current element we want to find the subsequent one of
 * @param container - Containing parent that we wish to traverse.
 */
export const nextElement = ($element, container) => {
  if ($element.tagName === "SECTION") {
    return $element.firstChild
  }

  while (
    !$element.nextElementSibling &&
    $element.parentElement &&
    $element.parentElement !== container
  ) {
    $element = $element.parentElement
  }

  return $element.nextElementSibling ?? null
}

const HEADING_TAGS = ["H1", "H2", "H3", "H4", "H5", "H6"]
/**
 * Check if a given element is a heading
 * @param value - Element to check
 */
const isHeading = (value) =>
  value instanceof Element && HEADING_TAGS.includes(value.tagName)

/**
 * Checks if the given element is a heading of greater or equal size to the
 * provided heading. This is typically used to denote the end of a given section
 * that started at the $heading element.
 *
 * @param $element - Element to check
 * @param $heading - Heading element to compare against
 * @returns {false|*|boolean}
 */
const isEndHeading = ($element, $heading) =>
  $element !== $heading &&
  isHeading($element) &&
  $element.tagName <= $heading.tagName

/**
 * Continue to exit outside of section element wrappers until we are at outside of them
 *
 * @param $element - Starting element that may be wrapped in a section
 * @returns {*}
 */
const exitSection = ($element) => {
  while ($element.parentElement.tagName === "SECTION") {
    $element = $element.parentElement
  }
  return $element
}

/**
 * Alter the depth of the provided tag by the given amount
 *
 * @param tag - Tag of header element, in format H{index}
 * @param amount - Number of positions to move header rank
 * @returns {string} - Tag of shifted header element
 */
const shiftHeadingTag = (tag, amount = 1) => {
  const index = HEADING_TAGS.indexOf(tag)
  if (index === -1) {
    throw new Error(`Given "${tag}" is not a heading tag`)
  }
  const newIndex = clamp(index + amount, 0, 5)
  return HEADING_TAGS[newIndex]
}

/**
 * Generic function to traverse DOM elements and perform some kind of logic based on the callback.
 * Ends if we reach end of DOM or the callback evaluates to true.
 *
 * @param {Element} $element - Element we will start the traversal on
 * @param {($element: Element) => any} callback - Logic to execute for each element traversed. Returns true to end the walk.
 * @param options - Options to control the walk. shallow
 * @param options.shallow - Only traverse siblings of $element
 * @param options.container - Container to traverse. Defaults to document.body
 */
const walkDom = ($element, callback, options = {}) => {
  const { shallow, container = document.body } = options
  while ($element) {
    // Copy a reference to the next element
    const $nextElement = shallow
      ? $element.nextElementSibling ?? null
      : nextElement($element, container)

    // perform a callback and determine if we are done traversing
    const endWalk = callback($element)

    if (endWalk) {
      return
    }

    // if we haven't ended the walk, move on to the next one
    $element = $nextElement
  }
}

/**
 * Replace the text content of an existing heading
 * @param $heading - Element to replace
 * @param text - New name provided by user
 */
export const rename = ($heading, text) => {
  $heading.innerHTML = text
  $heading.name = text
}

/**
 * Inserts a heading directly before the provided element. If provided element is
 * first element in a <section>, inserted heading is positioned directly outside
 * and in front of it.
 *
 * @param $heading - Element to insert before
 * @param text - Text to insert
 */
export const addHeadingBefore = ($heading, text) => {
  let newElement = document.createElement($heading.tagName)
  newElement.innerHTML = text

  const $prevElement = $heading.previousElementSibling

  let $target = $heading

  // if we're in a section and the previous element is null, exit the section
  if ($prevElement === null) {
    $target = exitSection($heading)
  }
  $target.before(newElement)
}

/**
 * Inserts a heading directly in front of the provided element's next heading sibling
 * of equal or greater size. If the provided element is the final heading in a <section>,
 * inserted heading is positioned directly outside and after the section.
 *
 * @param $heading - Element to insert after
 * @param text - Text to insert
 * @param options - Options to control the insertion
 * @param options.subheading - If true, insert a subheading of the provided element
 * @param options.tag - If provided, insert a heading of the given tag
 */
export const addHeadingAfter = ($heading, text, options = {}) => {
  const { subheading = false } = options
  // Determine the Header size of our new element.
  // If it's an adjacent insertion, make it the same size as the selected header
  // If it's a subheader insertion, make it one size less than the selected header
  const tag =
    options.tag ??
    (subheading ? shiftHeadingTag($heading.tagName) : $heading.tagName)

  walkDom(
    $heading,
    ($element) => {
      // Check the next sibling node. If it has the same rank or higher or our initial header, or if it's null, we have located the position to insert
      const $nextElement = $element.nextElementSibling
      if (
        $nextElement === null ||
        (isHeading($nextElement) && $nextElement.tagName <= $heading.tagName)
      ) {
        // we have located location to insert the new header, then exit
        let $newElement = document.createElement(tag)
        $newElement.innerHTML = text

        // if we're at the end of a section, exit to higher one so it's inserted after the section
        // This will only apply for a header of the same level
        let $target = $element
        if ($nextElement === null && !subheading) {
          $target = exitSection($element)
        }

        $target.after($newElement)
        return true
      }
    },
    { shallow: true }
  )
}

/**
 * Removes provided heading and all child elements from the DOM.
 *
 * @param $heading - Heading to remove
 * @param options - Options to control the removal
 * @param options.container - Container to traverse. Defaults to document.body
 */
export const removeSection = ($heading, options = {}) => {
  walkDom(
    $heading,
    ($element) => {
      if (isEndHeading($element, $heading)) {
        // if we locate another header with a equal or higher rank, return true which exits walkDom
        // indicating we are done
        return true
      }

      $element.remove()
    },
    { ...options, shallow: true }
  )
}

/**
 * Alters heading depth of provided element and all of its child elements.
 *
 * @param $heading - Heading to shift
 * @param options - Options to control the shift
 * @param options.shiftAmount - Amount to shift the heading. Defaults to 1
 */
export const shiftSection = ($heading, options = {}) => {
  const { shiftAmount = 1 } = options
  walkDom(
    $heading,
    ($element) => {
      if (!isHeading($element)) {
        // if it's not a heading, return false to walkDom so it keeps traversing
        return false
      }

      if (isEndHeading($element, $heading)) {
        // if we locate another header with a equal or higher rank, return true which exits walkDom
        // indicating we are done
        return true
      }

      // Reconstruct the header with a modified tagName
      let newTag = shiftHeadingTag($element.tagName, shiftAmount)
      let $newElement = document.createElement(newTag)
      let attributes = [...$element.attributes]

      // Copy over all attributes to our new element
      attributes.forEach((attr) =>
        $newElement.setAttribute(attr.nodeName, attr.nodeValue)
      )

      $newElement.innerHTML = $element.innerHTML
      $element.replaceWith($newElement)
    },
    options
  )
}

/**
 * Shift a section's headings relative to the logical parent and sibling headings.
 *
 * The section's headings will be promoted/demoted such that the root section
 * heading will be the next level up from the parent heading or the same
 * level as the next sibling heading, whichever is higher.
 *
 * @param {Element} sectionHeading - The root heading of the section to shift
 * @param {Element?} nextSiblingHeading - The next sibling heading of the section
 * @param {Element?} parentHeading - The parent heading of the section
 */
export const shiftSectionRelativeTo = (
  sectionHeading,
  nextSiblingHeading,
  parentHeading
) => {
  const minimumChildLevel = HEADING_TAGS.indexOf(parentHeading?.tagName) + 1
  const minimumSiblingLevel = HEADING_TAGS.indexOf(nextSiblingHeading?.tagName)
  const minimumLevel = Math.max(minimumChildLevel, minimumSiblingLevel)

  const sectionLevel = HEADING_TAGS.indexOf(sectionHeading.tagName)

  const levelOffset = minimumLevel - sectionLevel

  shiftSection(sectionHeading, { shiftAmount: levelOffset })
}

/**
 * Find all of the root elements with a logical content section.
 *
 * A content section start with a given heading and includes all sub-headings
 * thereafter up until the next heading with equal or lower heading level.
 *
 * @param {Element} heading The heading element of the section.
 * @param {Element} container The root container element.
 * @returns {Element[]}
 */
export const findContentSectionElements = (heading, container) => {
  const section = []

  // Ensure the start element is a direct child of the container.
  let startElement = getSectionStart(heading, container)

  // Create a selector for the end of the content section. This selector will
  // find any heading element will equal or lower level.
  const endSelector = join(
    ",",
    takeWhile((tag) => tag <= heading.tagName, HEADING_TAGS)
  )
  /**
   * Check if an element is the end of the section.
   * @param element The element to check.
   */
  const isEnd = (element) =>
    element !== startElement &&
    element instanceof Element &&
    (element.matches(endSelector) || element.querySelector(endSelector))

  // Collect each root element in the section.
  let element = startElement
  while (element && !isEnd(element)) {
    section.push(element)
    element = element.nextSibling
  }

  return section
}

/**
 * Find the section start for a given element.
 *
 * The section start is typically a heading. If the element is in a template
 * section, the section element is used instead.
 *
 * @param {Element} element - The element to find the section start for.
 * @param {Element} container - The root container element.
 * @returns {Element?}
 */
export const getSectionStart = (element, container) => {
  let prev
  // Traverse the document backwarks until we find the heading associated with
  // the element.
  while (
    !isHeading(element) &&
    (prev = element?.previousElementSibling ?? element?.parentElement) &&
    prev !== container
  ) {
    element = prev
  }

  // If the heading is wrapped by another element (e.g. a section element) we'll
  // return the wrapper element instead.
  while (element && element.parentElement !== container) {
    element = element.parentElement
  }

  return element
}
