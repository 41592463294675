import { getTitle } from "../../../utilities/transformers"

/**
 * Filter visible Learning Path courses by a search query match to their titles.
 * If a path contains no courses matching the query, don't display it at all.
 *
 * @param learningPaths - Full list of unfiltered Learning Paths
 * @param query - String search query criteria
 */
const filterLearningPaths = (learningPaths, query) => {
  if (!query) {
    return learningPaths
  }

  let filteredList = []

  for (const path of learningPaths) {
    // Create deep copy of path in order to maintain its properties while updating its courses array
    let newPath = structuredClone(path)

    // If the path name matches the query, add the entire path
    if (newPath.title.toLowerCase().includes(query.toLowerCase())) {
      filteredList.push(newPath)
      continue
    }

    newPath.courses = path.courses.filter((doc) => {
      return getTitle(doc.title).toLowerCase().includes(query.toLowerCase())
    })

    // If any courses in the path match the search query, add it to the list of filtered paths
    if (newPath.courses.length > 0) {
      filteredList.push(newPath)
    }
  }

  return filteredList
}

export default filterLearningPaths
