import { Theme, Typography } from "@mui/material"
import StyledPaper from "../../../welcome/StyledPaper"
import BlogPostContainer from "./BlogPostContainer"

export type BlogPostData = {
  label: string
  title: string
  action: string
  graphic?: string
}

type BlogPostProps = {
  post: BlogPostData
  [key: string]: any
}

/**
 * Component to display a clickable card navigating to a blog
 * @param props - Component props
 */
const BlogPost = (props: BlogPostProps) => {
  const { post, ...otherProps } = props
  const { label, title, action, graphic } = post

  return (
    <BlogPostContainer {...otherProps}>
      <StyledPaper
        elevation={0}
        sx={(theme: Theme) => ({
          cursor: "pointer",
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow: `0px 4px 10px ${theme.palette.lexGrey}`,
          },
          ...(graphic && {
            background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("img/posts/${graphic}")`,
            backgroundSize: "cover",
            color: theme.palette.lexWhite,
          }),
        })}
        onClick={() => {
          // @ts-ignore
          window.open(action, "_blank").focus()
        }}
      >
        <Typography fontSize="12px" fontWeight={400}>
          {label}
        </Typography>
        <Typography fontSize="16px" fontWeight={500}>
          {title}
        </Typography>
      </StyledPaper>
    </BlogPostContainer>
  )
}

export default BlogPost
