import { Auth } from "@aws-amplify/auth"
import { providers } from "../constants"
import { last } from "lodash"

/**
 * Attempt a federated authentication.
 * @param username The account email or username.
 */
export async function attemptFederatedSignIn(username: string) {
  const customProvider = await getAuthenticationProvider(username)
  Auth.federatedSignIn({ customProvider })
}

/**
 * Get the federated provider for a giver user.
 * @param username The account email or username.
 *
 * TODO: Retrieve providers from the server.
 */
export async function getAuthenticationProvider(username: string) {
  const domain = last(username.split("@"))

  if (!domain) {
    throw new Error("Unable to determine domain.")
  }

  const provider = providers[domain]
  if (!provider) {
    throw new Error("Unable to identify federated provider.")
  }

  return provider
}
