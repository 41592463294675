/**
 * Registers a custom Froala plugin for displaying popups for editing audio elements
 * @param editor - Current froala editor instance
 */
const customAudioPlugin = function (editor: any) {
  let id: string | null = null

  /**
   * Initialize custom popup
   */
  function initPopup() {
    var popup_buttons = ""
    if (editor.opts.popupButtons.length > 1) {
      popup_buttons += '<div class="fr-buttons">'
      popup_buttons += editor.button.buildList(editor.opts.popupButtons)
      popup_buttons += "</div>"
    }
    var template = {
      buttons: popup_buttons,
      custom_layer: "",
    }
    var $popup = editor.popups.create("audio.popup", template)
    return $popup
  }

  /**
   * Display popup
   * @param clickEvent - Click event that is triggering the popup display
   */
  function showPopup(clickEvent: any) {
    id = clickEvent.target.closest(".audio-clickable").id

    var $popup = editor.popups.get("audio.popup")
    if (!$popup) $popup = initPopup()
    editor.popups.setContainer("audio.popup", editor.$sc)

    var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]')
    var left = clickEvent.pageX
    var top = clickEvent.pageY
    editor.popups.show("audio.popup", left, top, $btn.outerHeight())
  }

  /**
   * Hide the popup
   */
  function hidePopup() {
    editor.popups.hide("audio.popup")
  }

  // Methods visible outside the plugin.
  return {
    getCurrentID() {
      return id
    },
    showPopup: showPopup,
    hidePopup: hidePopup,
  }
}

export default customAudioPlugin
