import onCreateAudio from "../features/insertAudio/onCreateAudio"
import { createInsertAudioDialog } from "../features/insertAudio/InsertAudioDialog"
import Froalaeditor from "froala-editor"
import customAudioPlugin from "./customPlugins/customAudioPlugin"

/**
 * Define icons used for audio popup menu
 */
Froalaeditor.DefineIcon("deleteElement", {
  NAME: "remove",
  SVG_KEY: "remove",
})

Froalaeditor.DefineIcon("replaceElement", {
  NAME: "exchange",
  SVG_KEY: "replaceImage",
})

/**
 * Need to manually provide SVG path to icon for Froala to parse it
 */
Froalaeditor.DefineIcon("showAudioDialog", {
  NAME: "audio",
  PATH: "M3 9v6h4l5 5V4L7 9zm7-.17v6.34L7.83 13H5v-2h2.83zM16.5 12c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02M14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77",
})

/**
 * Add audio popup to list of popup templates
 */
Froalaeditor.POPUP_TEMPLATES["audio.popup"] = "[_BUTTONS_][_CUSTOM_LAYER_]"

/**
 * Define buttons used in popups
 */
Object.assign(Froalaeditor.DEFAULTS, {
  popupButtons: ["editElement", "deleteElement"],
})

/**
 * Load the custom audio plugin into Froala's list of plugins
 */
Froalaeditor.PLUGINS.audio = customAudioPlugin

/**
 * Set up additional custom commands for audio insertion and editing with the froala editor
 * @param displayReactElement - Function to render edit Dialog Box
 */
const registerAudioCommands = (displayReactElement: any) => {
  Froalaeditor.RegisterCommand("deleteElement", {
    title: "Remove",
    undo: false,
    icon: "deleteElement",

    focus: false,
    callback: function () {
      const id = this.audio.getCurrentID()
      const audioElement = this.el.querySelector(`#${id}`)
      audioElement.remove()
      this.popups.hideAll()
      this.undo.saveStep()
    },
  })

  Froalaeditor.RegisterCommand("editElement", {
    title: "Replace",
    undo: false,
    icon: "replaceElement",
    focus: false,
    callback: function (cmd, val) {
      const id = this.audio.getCurrentID()

      const audioElement = this.el.querySelector(`#${id}`)

      let titleElement = audioElement.querySelector(".audio-title")
      let urlElement = audioElement.querySelector(".audio-url")

      const title = titleElement.innerText || ""
      const url = urlElement.src

      displayReactElement((onClose: () => void) =>
        createInsertAudioDialog(
          (data) => {
            if (data) {
              console.log(data)
              titleElement.textContent = data.title
              urlElement.src = data.url
              this.popups.hideAll()
            }
            onClose()
          },
          {
            title: title,
            url: url,
          }
        )
      )
    },
  })

  Froalaeditor.RegisterCommand("showAudioDialog", {
    title: "Insert Audio",
    icon: "showAudioDialog",
    focus: true,
    undo: true,
    callback: function () {
      const selection = this.selection.get() as any
      const anchorElement = selection.anchorNode
      anchorElement.innerHTML = "<br/>"

      displayReactElement((onClose: () => void) =>
        onCreateAudio(anchorElement, onClose, this)
      )
    },
  })
}

export default registerAudioCommands
