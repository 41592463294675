import axiosGlobal from "./axios"
import fetchImportJobs from "./fetchImportJobs"

/**
 * @typedef {object} FetchAsyncJobsOptions
 * @property {boolean} rolloutServerlessImportAPI
 * @property {string} since
 * @property {import("axios").AxiosInstance} axios
 */

/**
 * fetchAsyncJobs
 * @param {FetchAsyncJobsOptions} options - see FetchAsyncJobsOptions
 */
const fetchAsyncJobs = (options = {}) => {
  const { rolloutServerlessImportAPI, since, axios = axiosGlobal } = options

  const async_job = axios.get("/jobs/", {
    "axios-retry": { retries: 0 },
    params: {
      since,
      period: 300, // 5 minutes
    },
  })
  const jobs_requests = [async_job]

  if (rolloutServerlessImportAPI) {
    jobs_requests.push(fetchImportJobs(since))
  }

  return Promise.all(jobs_requests).then(
    ([jobsResponse, importJobsResponse]) => {
      let importJobsData = []
      if (importJobsResponse != null) {
        importJobsData = importJobsResponse.data
      }

      return jobsResponse.data.concat(importJobsData)
    }
  )
}

export default fetchAsyncJobs
