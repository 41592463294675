import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Box,
  Typography,
  Skeleton,
} from "@mui/material"
import CoursesListItem, { CoursesListItemBase } from "./CoursesListItem"
import { Item } from "../../types"
import FolderBackButton from "./FolderBackButton"

/**
 * Placeholder skeleton for when list is loading
 */
export const CoursesListSkeleton = () => (
  <TableRow>
    <CoursesListItemBase
      icon={<Skeleton width={20} height={20} variant="circular" />}
      title={<Skeleton width={200} variant="text" />}
      lastUpdated={<Skeleton width={200} variant="text" />}
    />
  </TableRow>
)

type CoursesListProps = {
  /** List of directory items to display */
  items: Item[]

  /** When true, we do not have data for the current folder yet */
  loading: boolean

  /** Title of current folder, or null if in root directory */
  currentFolder: string | null

  /** Callback for exiting folder **/
  handleExitDirectory: () => void | null

  /** When available, render button to display more content when clicked */
  showMoreButton: React.ReactNode

  /** Current directory that the viewed items are in */
  directory: string | null

  /** Handle drag end */
  isDragging: boolean

  /** Flag indicating if we currently are searching by query */
  displaySearchResults: boolean
}

/**
 * Component for rendering courses as individual list items
 * @param props - Component props
 */
const CoursesList = (props: CoursesListProps) => {
  const {
    items,
    loading,
    directory,
    handleExitDirectory,
    showMoreButton,
    isDragging,
    displaySearchResults,
  } = props

  const withinFolder = directory !== null

  // When triggering loading skeletons, have it be the same number as however many visible courses there were before for consistency
  const numSkeletons = items.length > 0 ? items.length : 6

  const emptyDirectory = !loading && items.length === 0

  const showBackButton = !loading && withinFolder

  return (
    <TableContainer
      sx={(theme) => ({
        minHeight: theme.spacing(8 * 6),
        td: { border: "none" },
        overflowX: "hidden",
      })}
      component={Paper}
      variant="outlined"
    >
      <Table aria-label="Courses Table">
        <TableBody sx={(theme) => ({ tr: { height: theme.spacing(8) } })}>
          {!loading ? (
            <>
              {showBackButton && (
                <FolderBackButton
                  isDragging={isDragging}
                  onClick={handleExitDirectory}
                />
              )}
              {items.map((item: any) => (
                <CoursesListItem key={item.id} item={item} {...props} />
              ))}
            </>
          ) : (
            [...Array(numSkeletons)].map((i, index) => (
              <CoursesListSkeleton key={index} />
            ))
          )}
        </TableBody>
      </Table>
      {emptyDirectory && (
        <Box
          marginTop={16}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body2">
            {displaySearchResults
              ? "No courses match your search."
              : withinFolder
              ? "This folder contains no courses."
              : "You have not created any courses yet. Click Add Course to get started."}
          </Typography>
        </Box>
      )}
      {showMoreButton}
    </TableContainer>
  )
}

export default CoursesList
