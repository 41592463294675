import { createRef, useState } from "react"

//constants for expand / collapse of the header outline
export const HEADERS_COLLAPSE_ALL = 0
export const HEADERS_EXPAND_ALL = 1

/**
 * Hook for handling behavior of collapsing and expanding the course outline.
 *
 * @returns {{hasExpandAll: boolean, hasCollapseAll: boolean, outlineRef: React.RefObject<unknown>, handleCollapse: handleCollapse}}
 */
const useOutlineCollapse = () => {
  const outlineRef = createRef()

  const [hasExpandAll, setHasExpandAll] = useState(false)
  const [hasCollapseAll, setHasCollapseAll] = useState(true)
  /**
   * Expand or collapse the entire outline
   * @param {*} collapseCase HEADERS_COLLAPSE_ALL or HEADERS_EXPAND_ALL
   */
  const handleCollapse = (collapseCase) => {
    if (outlineRef.current) {
      switch (collapseCase) {
        case HEADERS_COLLAPSE_ALL:
          outlineRef.current.collapse("ALL")
          setHasCollapseAll(false)
          setHasExpandAll(true)
          break
        case HEADERS_EXPAND_ALL:
          outlineRef.current.collapse("NONE")
          setHasCollapseAll(true)
          setHasExpandAll(false)
          break
        default:
        //do nothing
      }
    }
  }

  return {
    handleCollapse,
    hasExpandAll,
    hasCollapseAll,
    outlineRef,
  }
}

export default useOutlineCollapse
