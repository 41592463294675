import axiosInstance from "../../../api/axios"

type UploadThumbnailArg = {
  image: File
  id: string
}

/**
 * API call to upload custom thumbnail image for a course
 *
 * @param arg - Object containing file and course ID
 */
const uploadCourseThumbnail = (arg: UploadThumbnailArg) => {
  const { image, id } = arg
  const data = new FormData()
  data.append("image", image)

  return axiosInstance
    .put(`/courses/${id}/thumbnail`, data)
    .then((response) => response.data)
}

export default uploadCourseThumbnail
