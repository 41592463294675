import ConfirmDialog from "../../../components/widgets/ConfirmDialog"
import { Box, Typography } from "@mui/material"
import ConfirmDeleteImage from "../../../assets/images/confirm-delete.svg"
import React from "react"

const RemoveFromPathDialog = (props) => {
  const { path, open, course, onConfirm, onCancel } = props

  return (
    <ConfirmDialog
      open={open}
      title={`Remove From This Learning Path?`}
      message={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            component="img"
            sx={{ width: "300px", alignSelf: "center", mb: 4 }}
            src={ConfirmDeleteImage}
            alt="You can't undo this action"
          />
          <Typography variant="body1" align="justify">
            This action removes the course {course.title} from {path.title}. You
            can always add it back later.
          </Typography>
        </Box>
      }
      cancelText={"Cancel"}
      confirmText={"Remove"}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onClose={onCancel}
    />
  )
}

export default RemoveFromPathDialog
