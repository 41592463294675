import { CustomElementConfig, RenderViewOptions } from "../CustomElements"
import { StandardSmartTemplateCustomComponent } from "./StandardSmartTemplateCustomComponent"
import { styled } from "@mui/material"
import { H2SmartTemplateCustomComponent } from "./H2SmartTemplateCustomComponent"

/** Data representation of smart template control state. */
export interface SmartTemplateControlData {
  /** True to open panel immediately */
  openPanelImmediately: boolean
}

/*
 * Custom element that represents a smart template control.
 */
export const smartTemplateControlCustomElement: CustomElementConfig<SmartTemplateControlData> =
  {
    // Smart template controls all have data-smart-template-control attribute
    selector: "[data-smart-template-control]",
    /**
     * Extract data from the light-DOM representation of the custom element
     * @param element The element to extract data from
     */
    getDataFromElement: (element) => {
      return {
        openPanelImmediately: element.dataset.openPanelImmediately === "true",
      }
    },
    /**
     * Update the light-DOM representation of the custom element from the data
     * @param element The element to update
     * @param data The data to update the element with
     */
    updateElementFromData: (element, data) => {
      element.dataset.openPanelImmediately =
        data.openPanelImmediately.toString()
    },
    /**
     * Render the custom element in the editor
     * @param options Options for rendering the custom element
     * @returns The rendered custom element
     * @see CustomElementConfig
     */
    renderView: (options: RenderViewOptions<SmartTemplateControlData>) => {
      const {
        data,
        onDataChange,
        editor,
        editorDiv,
        element,
        withStyles,
        readOnly,
      } = options
      return (
        <SmartTemplateCustomComponent
          editor={editor}
          editorDiv={editorDiv}
          data={data}
          onDataChange={onDataChange}
          element={element}
          withStyles={withStyles}
          readOnly={readOnly}
        />
      )
    },
  }

/**
 * Renders smart template
 * @param props See below.
 * @param props.editor The Froala editor.
 * @param props.editorDiv The Froala editor div.
 * @param props.element Root element of component in light DOM.
 * @param props.data The data to render
 * @param props.onDataChange Callback to update the data.
 * @param props.withStyles Function to wrap children in styles.
 * @param props.readOnly Is this in read-only state.
 */
function SmartTemplateCustomComponent(props: {
  editor: any
  editorDiv: HTMLDivElement
  element: HTMLElement
  data: SmartTemplateControlData
  onDataChange?: (data: SmartTemplateControlData) => void
  withStyles: (children: React.ReactElement) => React.ReactElement
  readOnly: boolean
}) {
  // If readonly, no component
  if (props.readOnly) {
    return null
  }

  // Determine template type
  const templateType = props.element.dataset.smartTemplateControl!

  if (templateType === "demonstration") {
    return (
      <StandardSmartTemplateCustomComponent
        {...props}
        templateType={templateType}
        namePlural="Demonstrations"
      />
    )
  } else if (templateType === "exercise") {
    return (
      <StandardSmartTemplateCustomComponent
        {...props}
        templateType={templateType}
        namePlural="Exercises"
      />
    )
  } else if (templateType === "test_question") {
    return (
      <StandardSmartTemplateCustomComponent
        {...props}
        templateType={templateType}
        namePlural="Test Questions"
      />
    )
  } else if (templateType === "learning_objectives") {
    return (
      <StandardSmartTemplateCustomComponent
        {...props}
        templateType={templateType}
        namePlural="Learning Objectives"
      />
    )
  } else if (templateType === "rewrite") {
    return <H2SmartTemplateCustomComponent {...props} />
  }

  return null
}

/** Container for smart template panels */
export const SmartTemplatePanelContainer = styled("div")(({ theme }) => ({
  borderRadius: 5,
  backgroundColor: theme.palette.grey[100],
}))
