const features = {
  /**
   * Enable change tracking in the the Froala editor.
   *
   * @createdBy Cameron Elvidge
   * @createdAt Feb 11, 2022
   * @expires Mar 11, 2022
   */
  "track-changes": process.env.REACT_APP_FEATURE_TRACK_CHANGES === "true",

  /**
   * Use server-side generated cookies for CDN access.
   *
   * @createdBy Cameron Elvidge
   * @createdAt Feb 25, 2022
   * @expires Mar 25, 2022
   */
  "server-side-cookies":
    process.env.REACT_APP_FEATURE_SERVER_SIDE_COOKIES === "true",

  /**
   * Enable LearnExperts Branding Prototype
   *
   * @createdBy David Dewar
   * @createdAt May 12, 2022
   * @expires June 15, 2022
   */
  "brand-ux": process.env.REACT_APP_BRAND === "true",

  /**
   * Enable Instructions Text Detection in UI
   * There is a corresponging ENABLE_INSTRUCTIONS_DETECTOR feature flag on the server
   *
   * @createdBy David Dewar
   * @createdAt July 14, 2022
   * @expires September the 25th, 2022
   */
  "enable-intructions-detector":
    process.env.REACT_APP_ENABLE_INSTRUCTIONS_DETECTOR === "true",

  /**
   * Enable audio file upload
   *
   * @createdBy Clayton Grassick
   */
  "audio-file-upload": process.env.REACT_APP_AUDIO_FILE_UPLOAD === "true",

  /**
   * Enable true/false questions
   * @createdBy Clayton Grassick
   */
  "true-false-questions": process.env.REACT_APP_TRUE_FALSE_QUESTIONS === "true",

  /**
   * Enable fill in the blank questions
   * @createdBy Clayton Grassick
   */
  "fill-in-the-blank-questions":
    process.env.REACT_APP_FILL_IN_THE_BLANK_QUESTIONS === "true",
}

export default features
