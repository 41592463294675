import { Button, CircularProgress } from "@mui/material"
import React, { useRef, useState } from "react"
import { uploadTranslationSheet } from "../../api"
import { useAuth } from "../../contexts/authorization"
import FileUploadIcon from "@mui/icons-material/FileUpload"

/**
 * Uploads a translation sheet for a course in a given language.
 * @param props Props for the component.
 * @param props.courseId - The ID of the course.
 * @param props.language - The language to upload the translation sheet for.
 * @returns React component
 */
export function UploadTranslationSheetButton(props: {
  courseId: string
  language: string
}) {
  const { courseId, language } = props
  const [isUploading, setIsUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const {
    user: { id: tenantId },
  } = useAuth() as any

  /**
   * Handles the upload of the translation sheet.
   * @param event - The change event.
   */
  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    setIsUploading(true)
    try {
      await uploadTranslationSheet(tenantId, courseId, language, file)
      alert("Translation sheet uploaded successfully")
    } catch (error) {
      alert("Failed to upload translation sheet")
    } finally {
      setIsUploading(false)
      if (fileInputRef.current) {
        fileInputRef.current.value = ""
      }
    }
  }

  /**
   * Handles the button click.
   */
  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUpload}
        style={{ display: "none" }}
        accept=".xlsx"
      />
      <Button
        variant="outlined"
        onClick={handleButtonClick}
        disabled={isUploading}
        startIcon={<FileUploadIcon />}
      >
        {isUploading ? (
          <>
            <CircularProgress size={24} color="inherit" />
            Uploading...
          </>
        ) : (
          "Upload Translation Sheet"
        )}
      </Button>
    </div>
  )
}
