import { Button, Grid, Paper, Stack, Theme, Typography } from "@mui/material"
import Diff from "../../../../../components/atoms/Diff"
import { hasContent } from "../../../../../components/screens/HistoryDetailsScreen"

const DIFF_PREVIEW_LIMIT = 1 // Number of diff previews to show when viewing inline with all records

type HTMLDiffProps = {
  details: any
  onSelectRecord: () => void
  inlineView?: boolean
}

/**
 * Component for rendering HTML diffs side by side in a responsive layout
 * @param props - Component props
 */
const HTMLDiff = (props: HTMLDiffProps) => {
  const { details, inlineView, onSelectRecord } = props

  const { htmlPreviews } = details

  const previews = htmlPreviews
    ? inlineView
      ? htmlPreviews.slice(0, DIFF_PREVIEW_LIMIT)
      : htmlPreviews
    : []

  const remainingDiffsCount = htmlPreviews.length - DIFF_PREVIEW_LIMIT

  return (
    <>
      {previews.filter(hasContent).map((item: any, index: number) => (
        <Grid item key={index} sx={{ marginTop: "16px" }} xs={12}>
          <Paper
            elevation={0}
            sx={(theme: Theme) => ({
              width: "100%",
              borderRadius: "8px",
              border: inlineView
                ? "none"
                : `1px solid ${theme.scheme.outlineVariant}`,
            })}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                lg={6}
                sx={(theme: Theme) => ({
                  padding: theme.spacing(2),
                  /**
                   * Depending on the layout of the diff, position a border on the bottom or right of the "Before" version.
                   */
                  borderBottom: {
                    xs: `1px solid ${theme.scheme.outlineVariant}`,
                    lg: "none",
                  },
                  borderRight: {
                    xs: "none",
                    lg: `1px solid ${theme.scheme.outlineVariant}`,
                  },
                })}
              >
                <Diff changes={item.before_html} />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                sx={(theme: Theme) => ({
                  padding: theme.spacing(2),
                })}
              >
                <Diff changes={item.after_html} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
      {inlineView && remainingDiffsCount > 0 && (
        <Grid item xs={12}>
          <Stack width="100%" alignItems="center" marginTop={2}>
            <Typography variant="bodySmall" gutterBottom>
              This action has {remainingDiffsCount} more diffs to display.
            </Typography>
            <Button onClick={onSelectRecord} variant="text">
              View Details
            </Button>
          </Stack>
        </Grid>
      )}
    </>
  )
}

export default HTMLDiff
