import { fade } from "../../utils/animations"
import EmailField from "../fields/EmailField"
import { useFormHandlers } from "../hooks/useFormHandlers"
import { BackToSignIn, RemoteErrorMessage, SubmitButton } from "../shared"
import Form from "./Form"

/** Displays the form for requesting a password reset. */
export function ResetPassword() {
  const { handleChange, handleSubmit } = useFormHandlers()

  return (
    <Form method="post" onChange={handleChange} onSubmit={handleSubmit}>
      <EmailField />
      <RemoteErrorMessage />
      <Form.ActionBar>
        <SubmitButton label="Send code" />
        <BackToSignIn {...fade} />
      </Form.ActionBar>
    </Form>
  )
}
