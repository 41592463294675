import { useEffect, useState } from "react"

interface IImageUploadState {
  thumbnail: string | null
  file: File | null
}

/**
 * Functionality for uploading custom thumbnail for course tiles
 * @param imageUrl - URL of custom image for course
 */
const useCourseTileImageUpload = (imageUrl: string) => {
  const [image, setImage] = useState<IImageUploadState>({
    thumbnail: null,
    file: null,
  })

  // on mounting, set the initial custom image
  useEffect(() => {
    setImage({
      thumbnail: imageUrl,
      file: null,
    })
  }, [imageUrl])
  return {
    image,
    setImage,
  }
}

export default useCourseTileImageUpload
