import { Stack, Typography, Paper, Theme } from "@mui/material"

/**
 * Wrapper component for displaying list content
 * @param props - Component props
 */
const ListContainer = (props: any) => {
  const { title, controls, searchBar = null, children } = props

  return (
    <Stack gap={2} flex="1 1 0">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="56px"
      >
        <Typography variant="h4" sx={{ flex: "1 1 0" }}>
          {title}
        </Typography>
        {searchBar}
      </Stack>
      <Paper
        elevation={0}
        sx={(theme: Theme) => ({
          display: "flex",
          flexDirection: "column",
          flex: "1 1 0",
          backgroundColor: theme.scheme.surfaceContainerLow,
          borderRadius: "20px",
        })}
      >
        <Stack
          minHeight="88px"
          direction="row"
          gap={1}
          padding={3}
          alignItems="center"
        >
          {controls}
        </Stack>
        {children}
      </Paper>
    </Stack>
  )
}

export default ListContainer
