import {
  InputAdornment,
  TextField,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material"
import { Clear, SearchOutlined } from "@mui/icons-material"
import { useState } from "react"

/**
 * Component for a text input Course Search bar
 * @param props - Component props
 * @param props.onSearchInputChange - Callback when text is inputted into search
 * @param props.displaySearchResults - Flag indicating if there is an active search
 * @param props.onClearSearch - Callback to clear search and return to normal view
 */
const CoursesSearchBar = ({
  onSearchInputChange,
  displaySearchResults,
  onClearSearch,
  ...otherProps
}) => {
  const [input, setInput] = useState("")

  return (
    <TextField
      variant="outlined"
      data-cy="courses-search-input"
      placeholder="Search"
      onChange={(e) => {
        const value = e.target.value
        onSearchInputChange(value)
        setInput(value)
      }}
      value={input}
      fullWidth
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="end">
              {displaySearchResults ? (
                <Tooltip arrow placement="top" title="Clear Search">
                  <IconButton
                    sx={{ margin: 0 }} // Setting margin to 0 lines up both Input Adornments
                    aria-label="Clear Search"
                    onClick={() => {
                      setInput("")
                      onClearSearch()
                    }}
                    edge="end"
                  >
                    <Clear />
                  </IconButton>
                </Tooltip>
              ) : (
                <Stack justifyContent="center" padding="8px">
                  <SearchOutlined />
                </Stack>
              )}
            </InputAdornment>
          </>
        ),
      }}
      {...otherProps}
    />
  )
}

export default CoursesSearchBar
