export interface IInaccessibleElements {
  label: string
  component: string
}

const inaccessibleElements = [
  { component: "matching-question", label: "Matching Questions" },
  { component: "categories", label: "Categories" },
]

/**
 * Check if a course's HTML possesses any elements that pose accessibility issues
 * @param htmlString - Assembled HTML of course
 */
const checkForInaccessibleElements = (htmlString: string) => {
  const html = document.createElement("html")
  html.innerHTML = htmlString

  const foundElements: IInaccessibleElements[] = []

  inaccessibleElements.forEach((element) => {
    const result = html.querySelector(`[data-component="${element.component}"]`)
    if (result) {
      foundElements.push(element)
    }
  })

  return foundElements
}

export default checkForInaccessibleElements
