/** The audio mime-types currently accepted for course creation. */
export const acceptedMimeTypes = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ], // docx files
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ], // pptx files

  "application/vnd.google-apps.document": [".docx"], // google docs
  "application/vnd.google-apps.presentation": [".pptx"], // google slides

  "text/html": [".html"], // html files
  "text/markdown": [".md", ".markdown"], // markdown files

  "application/zip": [".zip"], // zipped html files
  "application/x-zip-compressed": [".zip"], // zipped html files
}

/** The audio mime-types currently accepted for course creation. */
export const audioMimeTypes: { [mimeType: string]: string[] } = {
  "audio/aac": [".aac"], // .aac
  "audio/mp4": [".m4a"], // .m4a
  "audio/x-m4a": [".m4a"], // .m4a
  "audio/mpeg": [".mp3"], // .mp3
  "audio/wav": [".wav"], // .wav
  "audio/x-wav": [".wav"], // .wav
  "video/mp4": [".mp4"], // .mp4
  "video/x-m4v": [".m4v"], // .m4v
  "video/x-matroska": [".mkv"], // .mkv
  "video/x-msvideo": [".avi"], // .avi
  "video/x-ms-wmv": [".wmv"], // .wmv
  "video/quicktime": [".mov"], // .mov
  "video/mpeg": [".mpeg"], // .mpeg
}

export const imageMimeTypes: { [mimeType: string]: string[] } = {
  "image/jpeg": [".jpg", ".jpeg"], // jpg/jpeg files
  "image/png": [".png"], // png files
  "image/gif": [".gif"], // gif files
  "image/webp": [".webp"], // webp files
  "image/svg+xml": [".svg"], // svg files
}

/** The mime-types currently accepted for rewriting documents. */
export const rewriteMimeTypes: string[] = [
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx files
  "application/vnd.google-apps.presentation", // google slides
]

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  /**
   * A title displayed for the web app.
   */
  title: process.env.REACT_APP_DOCUMENT_TITLE ?? "LEAi™",
  froalaLicense:
    "yDC5hH4J4C7A8A5E4C4g1JWSDBCQG1ZGDf1C1d2JXDAAOZWJhE5B4E4H3F2H3A7A4E5A4==",

  documentUpload: {
    /** The document mime-types currently accepted for course creation. */
    acceptedMimeTypes,
  },

  googleApiCredentials: {
    // Client ID and API key from the Developer Console
    clientId: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  },

  googleFontsApiCredentials: {
    apiKey: process.env.REACT_APP_GOOGLE_FONTS_API_KEY,
  },
  mixpanelApiCredentials: {
    apiKey: process.env.REACT_APP_MIXPANEL_API_KEY,
  },
}

export default config
