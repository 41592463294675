import { useMemo } from "react"
import { useAppDispatch } from "../store"
import {
  fromCourseResponse,
  pending,
  setGuestEmail,
  refreshComments,
} from "../store/remarks"
import { fetchDetections } from "../store/remarks/actions/fetchDetections"
import { fetchSectionDetections } from "../store/remarks/actions/fetchSectionDetections"
import { debounce } from "lodash"
import { stripTemporaryElements } from "../utilities/smartTemplates"
import { useFlag } from "../utilities/feature-management"

/**
 * Use the set of action dispatchers for manipulating remarks.
 */
const useRemarksDispatch = () => {
  const dispatch = useAppDispatch()

  const interactiveComponentsEnabled = useFlag(
    "rollout-interactive-component-detections"
  )

  return useMemo(() => {
    const fetchDetectionsDebounced = debounce(
      (html, readOnly) => {
        // Strip temporary elements before dispatching
        const cleanedHtml = stripTemporaryElements(html)

        dispatch(fetchDetections({ html: cleanedHtml, readOnly }))
        if (interactiveComponentsEnabled) {
          dispatch(fetchSectionDetections({ html: cleanedHtml, readOnly }))
        }
      },
      1000,
      { maxWait: 3000 }
    )

    return {
      /**
       * Reset the remarks state pending new course data.
       *
       * @param {string} courseId
       */
      pending: (courseId) => {
        dispatch(pending({ courseId }))
      },
      /**
       * Sets the guest email if a user is unauthenticated
       */
      setGuestEmail: (email) => {
        dispatch(setGuestEmail({ email }))
      },
      /**
       * Update the remarks state from a given course response.
       */
      fromCourseResponse: (response) => {
        dispatch(
          fromCourseResponse({
            data: response.data,
            status: response.status,
          })
        )
      },

      refreshComments: (response) => {
        dispatch(refreshComments())
      },

      /**
       * Fetch updated detections for the given html.
       */
      fetchDetections: fetchDetectionsDebounced,
    }
  }, [dispatch])
}

export default useRemarksDispatch
