import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Autocomplete,
  Box,
  LinearProgress,
  Typography,
  Grid,
  InputAdornment,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Button,
} from "@mui/material"
import { SearchOutlined } from "@mui/icons-material"
import PageLayout from "../layouts/PageLayout"
import useAuditHistory from "../../hooks/useAuditHistory"
import { formatDate } from "../../utilities/transformers"
import CustomDateRangePicker from "../widgets/CustomDateRangePicker"
import { DateTime } from "luxon"

export const backBreadcrumbs = (params) => {
  if (params.back === "home") {
    return [
      {
        label: "Courses",
        href: "/",
      },
    ]
  } else if (params.back === "edit" && params.courseId != null) {
    return [
      { label: "Courses", href: "/" },
      {
        label: `'${params.title}'`,
        href: `/editor?course=${params.courseId}`,
      },
    ]
  } else {
    return [
      {
        label: "Courses",
        href: "/",
      },
      { label: "Tools", href: "/tools" },
    ]
  }
}

function HistoryScreen(props) {
  const history = useHistory()
  const [whoInput, setWhoInput] = useState("")
  const [actionInput, setActionInput] = useState("")
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)

  const {
    records,
    filters,
    isLoadingOptions,
    isLoadingHistory,
    backParams,
    options,
    isFiltered,
    searchInput,
    setSearchInput,
    courseInput,
    setCourseInput,
    onFilterChange,
    onSearchInputChange,
    filterRecords,
  } = useAuditHistory()

  //navigate to the audit trail detail page with historyLogId
  const handleHistoryDetail = (historyLogId, params) => {
    if (historyLogId) {
      const backList = backBreadcrumbs(params)
      history.push({
        pathname: "/history-details",
        search: `?id=${historyLogId}`,
        breadcrumbs: backList,
        prevURL: props.location.search,
      })
    }
  }

  const onClearFilters = () => {
    setSearchInput("")
    setWhoInput("")
    setActionInput("")
    setCourseInput("")
    onFilterChange(null)
  }

  const onClickDateControl = () => {
    setShowDateRangePicker(true)
  }

  const onDateRangePicked = (startDate, endDate) => {
    setShowDateRangePicker(false)
    onFilterChange({
      page: 0,
      filterSince: startDate,
      filterBefore: endDate,
    })
  }

  return (
    <>
      <PageLayout
        fixed
        maxWidth="xl"
        breadcrumbs={[...backBreadcrumbs(backParams), { label: "History" }]}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          History
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1">
            View and filter all user and system actions.
          </Typography>
          <Button
            color="primary"
            disabled={isFiltered}
            onClick={(e) => {
              e.preventDefault()
              onClearFilters()
            }}
          >
            Clear Filters
          </Button>
        </Box>

        {isLoadingOptions ? (
          <LinearProgress color="primary" />
        ) : (
          <>
            <Box height="8px">
              {isLoadingHistory && <LinearProgress color="primary" />}
            </Box>
            <Box sx={{ mt: 1, mb: 2 }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  let input = e.target[0].value
                  setSearchInput(input)
                  onFilterChange({ search: input })
                }}
                noValidate
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      placeholder="Search what happened"
                      value={searchInput}
                      onInput={(e) => {
                        e.preventDefault()
                        setSearchInput(e.target.value)
                        onSearchInputChange(e.target.value)
                      }}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                onFilterChange({ search: searchInput }, false)
                              }
                            >
                              <SearchOutlined />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>

            <Box>
              <TableContainer component={Paper} style={{ overflow: "visible" }}>
                <Table aria-label="sticky table">
                  <TableHead
                    sx={(theme) => ({
                      fontSize: "1rem",
                      backgroundColor: theme.palette.lexWhite,
                    })}
                  >
                    <TableRow>
                      <TableCell>
                        <Autocomplete
                          disablePortal
                          id="actor"
                          value={filters.filterActor}
                          inputValue={whoInput}
                          onInputChange={(event, newValue) =>
                            setWhoInput(newValue)
                          }
                          onChange={(event, newValue) =>
                            onFilterChange({ filterActor: newValue })
                          }
                          options={options["filterActor"]}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Who" />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          disablePortal
                          id="action"
                          value={filters.filterAction}
                          inputValue={actionInput}
                          onChange={(event, newValue) =>
                            onFilterChange({ page: 0, filterAction: newValue })
                          }
                          onInputChange={(event, newValue) =>
                            setActionInput(newValue)
                          }
                          options={options["filterAction"]}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Action" />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          disablePortal
                          //selected course
                          value={filters.filterCourse}
                          onChange={(event, newValue) => {
                            onFilterChange({ page: 0, filterCourse: newValue })
                          }}
                          //user input
                          inputValue={courseInput}
                          onInputChange={(event, newValue) => {
                            setCourseInput(newValue)
                          }}
                          id="combo-box-demo"
                          options={options["filterCourse"]}
                          isOptionEqualToValue={(option, value) => {
                            //LD-1062
                            if (!value) {
                              //value is undefined, we don't match anything because there are no undefined options
                              return true
                            }
                            if (option && option.title === value.title) {
                              //found a match
                              return true
                            }
                            //no match found
                            return false
                          }}
                          getOptionLabel={(option) =>
                            option.title ? option.title : ""
                          }
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Course" />
                          )}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ maxWidth: "10em" }}
                        onClick={onClickDateControl}
                      >
                        <TextField
                          label="Start Date"
                          value={
                            filters.filterSince != null
                              ? DateTime.fromISO(
                                  filters.filterSince
                                ).toLocaleString()
                              : ""
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{ flexGrow: "1" }}
                        />
                      </TableCell>
                      <TableCell>to</TableCell>
                      <TableCell
                        sx={{ maxWidth: "10em" }}
                        onClick={onClickDateControl}
                      >
                        <TextField
                          label="End Date"
                          value={
                            filters.filterBefore != null
                              ? DateTime.fromISO(
                                  filters.filterBefore
                                ).toLocaleString()
                              : ""
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{ flexGrow: "1" }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterRecords().map((row) => {
                      return (
                        <TableRow
                          hover
                          sx={{
                            "& td": {
                              fontWeight: 400,
                            },
                          }}
                          style={{ cursor: "pointer" }}
                          role="checkbox"
                          tabIndex={-1}
                          key={row.history_id}
                          onClick={() => {
                            handleHistoryDetail(row.history_id, backParams)
                          }}
                        >
                          <TableCell>{row.username}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.course_title}</TableCell>
                          <TableCell align="right" colSpan={3}>
                            {formatDate(row.recorded_at)}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={records.length}
                rowsPerPage={filters.limit != null ? filters.limit : 20}
                page={filters.page != null ? filters.page : 0}
                onPageChange={(event, newPage) =>
                  onFilterChange({ page: newPage })
                }
                onRowsPerPageChange={(event) =>
                  onFilterChange({
                    limit: parseInt(event.target.value),
                    page: 0,
                  })
                }
              />
            </Box>
          </>
        )}
      </PageLayout>
      <CustomDateRangePicker
        start={filters.filterSince}
        end={filters.filterBefore}
        showDialog={showDateRangePicker}
        onCancel={() => setShowDateRangePicker(false)}
        onDateRangePicked={onDateRangePicked}
      />
    </>
  )
}

export default HistoryScreen
