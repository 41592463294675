import { MotionProps as FramerMotionProps } from "framer-motion"

type AnimationProps = Pick<
  FramerMotionProps,
  "initial" | "animate" | "exit" | "variants"
>

/**
 * Properties for fading motion components in and out.
 */
export const fade: AnimationProps = {
  initial: "hidden",
  animate: "visible",
  exit: "hidden",

  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
}
