import { Typography } from "@mui/material"
import React from "react"
import StyledPaper from "./StyledPaper"

/**
 * Component for displaying "Today's Posts" links with a title, label, and
 * accompanying background image
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LinkCard = (props) => {
  const { post } = props

  const { label, title, action, graphic } = post
  return (
    <StyledPaper
      graphic={graphic}
      elevation={0}
      sx={(theme) => ({
        justifyContent: "center",
        cursor: "pointer",
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
          boxShadow: `0px 4px 10px ${theme.palette.lexGrey}`,
        },
      })}
      onClick={() => window.open(action, "_blank").focus()}
    >
      <Typography fontWeight={500} variant="body2">
        {label}
      </Typography>
      <Typography gutterBottom variant="h5">
        {title}
      </Typography>
    </StyledPaper>
  )
}
export default LinkCard
