import { Stack, TablePagination, Theme } from "@mui/material"
import useAuditHistory from "../../../../hooks/useAuditHistory"
import SearchBar from "../../components/SearchBar"
import ListContainer from "../../itemList/ListContainer"
import PageLayout from "../../layouts/PageLayout"
import { useState } from "react"
import { useHistory } from "react-router"
import CustomDateRangePicker from "../../../../components/widgets/CustomDateRangePicker"
import ActionLog from "./components/ActionLog"
import ActionLogFilters from "./components/ActionLogFilters"
import DateSelectionControl from "../../components/DateSelectionControl"
import { backBreadcrumbs } from "../../../../components/screens/HistoryScreen"

/**
 * Component for Audit page
 * @param props - Components
 * @param props.location - Router location
 */
const AuditPage = (props: { location: any }) => {
  const { location } = props

  const history: any = useHistory()

  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [datePresetTitle, setDatePresetTitle] = useState("")

  const {
    records,
    filters,
    isLoadingHistory,
    backParams,
    options,
    isFiltered,
    searchInput,
    setSearchInput,
    courseInput,
    setCourseInput,
    onFilterChange,
    onSearchInputChange,
    filterRecords,
    initialLoad,
  } = useAuditHistory()

  /**
   * When audit record is selected, redirect to dedicated page to display additional details
   * @param id - ID of record selected
   */
  const onSelectRecord = (id: string) => {
    const backList = backBreadcrumbs(backParams)

    history.push({
      pathname: "/history-details",
      search: `?id=${id}`,
      breadcrumbs: backList,
      prevURL: props.location.search,
    })
  }

  return (
    <PageLayout navRail location={location}>
      <ListContainer
        title="History"
        searchBar={
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="end"
            flex="2 1 0"
          >
            <DateSelectionControl
              onClick={() => setShowDateRangePicker(true)}
              filterSince={filters.filterSince}
              filterBefore={filters.filterBefore}
              datePresetTitle={datePresetTitle}
            />
            <SearchBar
              displaySearchResults={searchInput !== ""}
              onSearchInputChange={(query) => {
                setSearchInput(query)
                onSearchInputChange(query)
              }}
              onClearSearch={() => setSearchInput("")}
            />
          </Stack>
        }
        controls={
          <ActionLogFilters
            {...{
              filters,
              onFilterChange,
              options,
              courseInput,
              setCourseInput,
              isFiltered,
              setSearchInput,
              initialLoad,
              isLoadingHistory,
              setDatePresetTitle,
            }}
          />
        }
      >
        <Stack
          width="100%"
          sx={(theme: Theme) => ({
            backgroundColor: theme.scheme.onPrimary,
            overflowX: "hidden",
            overflow: "auto",
            flex: "1 1 0",
          })}
        >
          <ActionLog
            records={filterRecords()}
            initialLoad={initialLoad}
            onSelectRecord={onSelectRecord}
          />
        </Stack>
        <Stack direction="row" width="100%" justifyContent="end" paddingX={2}>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={records.length}
            rowsPerPage={filters.limit != null ? filters.limit : 20}
            page={filters.page != null ? filters.page : 0}
            onPageChange={(event, newPage) => onFilterChange({ page: newPage })}
            onRowsPerPageChange={(event) =>
              onFilterChange({
                limit: parseInt(event.target.value),
                page: 0,
              })
            }
          />
        </Stack>
      </ListContainer>
      <CustomDateRangePicker
        start={filters.filterSince}
        end={filters.filterBefore}
        showDialog={showDateRangePicker}
        onCancel={() => setShowDateRangePicker(false)}
        onDateRangePicked={(startDate: any, endDate: any, preset: any) => {
          if (preset != null) {
            setDatePresetTitle(preset)
          }
          setShowDateRangePicker(false)
          onFilterChange({
            page: 0,
            filterSince: startDate,
            filterBefore: endDate,
          })
        }}
      />
    </PageLayout>
  )
}

export default AuditPage
