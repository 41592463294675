import { useDroppable } from "@dnd-kit/core"
import { StyledCard } from "./gridLayout/CourseTile"
import { WrapperProps } from "../types"
import { TableRow } from "@mui/material"

/**
 * Droppable wrapper for items of type folder
 * @param props - Component props
 */
const FolderWrapper = (props: WrapperProps) => {
  const { item, children, variant, ...otherProps } = props
  const { isOver, setNodeRef } = useDroppable({
    id: item.id,
    data: item,
  })

  const Component = variant === "tile" ? StyledCard : TableRow

  const wrapperProps = {
    ...(variant !== "tile" ? { hover: true } : {}),
  }

  return (
    <Component
      data-cy="This-is-a-folder"
      ref={setNodeRef}
      sx={(theme) => ({
        cursor: "pointer",
        // Highlight add shadow when droppable item is overtop
        boxShadow:
          variant === "tile" && isOver
            ? `0px 0px 20px ${theme.palette.primary.main}`
            : undefined,
        backgroundColor:
          variant === "list" && isOver
            ? theme.palette.primary.light
            : undefined,
      })}
      {...otherProps}
      {...wrapperProps}
    >
      {children}
    </Component>
  )
}

export default FolderWrapper
