import { Card, Container, GlobalStyles } from "@mui/material"
import { motion } from "framer-motion"
import { PropsWithChildren } from "react"

const globalStyles = (
  <GlobalStyles
    styles={{
      // Apply a fixed background image to the page.
      html: {
        "::before": {
          zIndex: -1,
          position: "fixed",
          inset: 0,
          content: '""',
          backgroundImage: 'url("/background.jpg")',
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        },
      },
      body: {
        background: "none",
      },
    }}
  />
)

/**
 * Layout for the authentication screens.
 * @param props Component props.
 */
export function Layout(props: PropsWithChildren<{}>) {
  const { children } = props

  return (
    <>
      {globalStyles}
      <Container
        maxWidth="sm"
        fixed
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 4,
          paddingY: 8,
        }}
      >
        <Card
          layout
          component={motion.div}
          elevation={1}
          sx={{
            p: 4,
            gap: 6,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 8,
          }}
        >
          {children}
        </Card>
      </Container>
    </>
  )
}

export default Layout
