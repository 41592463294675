import remarksAdaptor from "../remarksAdaptor"

/**
 * Select the remark ID given the canonical ID of a comment.
 *
 * @param {string} id
 */
const selectCommentId = (id) =>
  remarksAdaptor.selectId({ id, remarkType: "comment" })

export default selectCommentId
