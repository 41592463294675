import { TableRow, TableCell, Button } from "@mui/material"
import { useDroppable } from "@dnd-kit/core"
import { ArrowBack, ArrowUpward } from "@mui/icons-material"

export type FolderBackButtonProps = {
  onClick: (e: any) => void
  isDragging: boolean
}

/**
 * Component for rendering back button when within folder
 * @param props - Component props
 */
const FolderBackButton = (props: FolderBackButtonProps) => {
  const { onClick, isDragging } = props
  const { isOver, setNodeRef } = useDroppable({
    id: "__ALL__",
    data: {
      title: "All Courses",
      id: "__ALL__",
    },
  })

  return (
    <TableRow
      ref={setNodeRef}
      hover
      sx={(theme) => ({
        cursor: "pointer",
        backgroundColor: isOver ? theme.palette.primary.light : undefined,
      })}
      onClick={onClick}
    >
      <TableCell padding="checkbox" />
      <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
        <Button
          size="small"
          variant="text"
          startIcon={!isDragging ? <ArrowBack /> : <ArrowUpward />}
        >
          {!isDragging ? "Back" : "All Courses"}
        </Button>
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  )
}
export default FolderBackButton
