import axiosGlobal from "./axios"

/**
 * Download from a URL for course creation.
 *
 * @param url The url to download.
 * @param axios The axios instance to use for the upload.
 *
 * @returns The async job for the upload process.
 */
const fetchDocumentFromUrl = (
  url: string,
  axios = axiosGlobal
): Promise<string> => {
  const data = new FormData()

  data.append("url", url)

  return axios.post("/documents/download", data).then(({ data }) => data)
}

export default fetchDocumentFromUrl
