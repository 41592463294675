/**
 * Ensure provided value falls within range (Legacy)
 * @param val - Value to check validity
 * @param defaultVal - Value to return if provided value is invalid
 * @param min - Lower limit for valid integer
 * @param max - Higher limit for valid integer
 */
const validateInt = (val: any, defaultVal: any, min: number, max: number) => {
  let intVal = parseInt(val)
  if (isNaN(intVal)) {
    return defaultVal
  } else if (max != null && intVal > max) {
    return max
  } else if (min != null && intVal < min) {
    return min
  } else {
    return intVal
  }
}

export default validateInt
