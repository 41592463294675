import previewMicroLearning from "../api/previewMicroLearning"
import waitForJob from "./waitForJob"
import { microlearningAsyncStatusEnum } from "../utils/constants"

/**
 * Generate micro-learning and return HTML for preview
 * @param data - Configuration settings for micro-learning
 */
const getMicroLearningPreview = async (data: any) => {
  const response = await previewMicroLearning(data)
  if (response.status === 200) {
    /**
     * Cached preview was retrieved
     */
    return response.data
  } else if (response.status === 202) {
    /**
     * No cached preview exists for configuration, so wait on async job to create
     * it then re-fetch it
     */
    const jobId = response.data.async_id

    await waitForJob(jobId, {
      completeStatus: microlearningAsyncStatusEnum.COMPLETE,
      failedStatus: microlearningAsyncStatusEnum.FAILED_NO_RETRY,
      interval: 1000,
    })
    return await previewMicroLearning(data).then(({ data }) => data)
  }
}

export default getMicroLearningPreview
