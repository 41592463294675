import { Item } from "../types"

/**
 * Build toast message following a move of items
 * @param items - Items to move
 * @param destination - Folder to move items to
 */
const getMovedCompleteMessage = (items: Item[], destination: Item) => {
  const numItems = items.length
  const movedTitle =
    numItems === 1 ? `course "${items[0].title}"` : `${numItems} items`
  const destinationTitle = destination?.title ?? "All Courses"
  const message = `Moved ${movedTitle} to folder "${destinationTitle}"`

  return message
}

export default getMovedCompleteMessage
