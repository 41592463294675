import {
  Delete,
  Edit,
  CheckCircle,
  Info,
  Dangerous,
  Warning,
  Lightbulb,
  Help,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignJustify,
} from "@mui/icons-material"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import { useState } from "react"
import { CustomElementConfig } from "./CustomElements"
import { minimalFroalaConfig } from "./minimalFroalaConfig"
import FroalaEditor from "react-froala-wysiwyg"

/** The color of the callout box
 * Can be one of the standard colors or a custom color
 */
type Color = "primary" | "error" | "success" | "info" | "warning" | "custom"

/** The icon of the callout box */
type Icon = "info" | "warning" | "error" | "success" | "tip" | "question"

/** The alignment of the callout box */
type Alignment = "left" | "center" | "right" | "justify"

/** Data for a callout */
export interface CalloutBoxData {
  /** Title of the callout */
  title: string

  /** Icon of the callout */
  icon: Icon

  /** Color of the highlight */
  color: Color

  /** Custom color in format #RRGGBB if color is "custom". Ignored otherwise */
  customColor?: string

  /** Alignment of the callout */
  alignment: Alignment

  /** HTML content of the callout */
  content: string
}

/*
 * This is a custom element that renders a callout box.
 *
 * data-component="callout-box"
 *
 * data-icon: "info" | "warning" | "error" | "success"
 *
 * data-color: "primary" | "error" | "success" | "info" | "warning" | "custom"
 *
 * data-custom-color: "#RRGGBB" (only used if data-color is "custom")
 *
 * data-title: The title of the callout box
 *
 * data-alignment: "left" | "center" | "right" | "justify"
 *
 * Sample HTML:
 * ```html
 *  <section data-component="callout-box" data-icon="info" data-color="primary" data-alignment="left" data-title="Important Notice">
 *    <p>This is the content of the callout box, providing more detailed information or context to the user.</p>
 *  </section>
 * ```
 */
export const calloutBoxCustomElement: CustomElementConfig<CalloutBoxData> = {
  selector: "[data-component='callout-box']",
  /**
   * Extracts CalloutBoxData from a DOM element.
   * @param el - The DOM element
   */
  getDataFromElement: (el: HTMLElement): CalloutBoxData => {
    const title = el.getAttribute("data-title") || ""
    const icon = el.getAttribute("data-icon") as Icon
    const color = (el.getAttribute("data-color") as Color) || "primary"
    const customColor = el.getAttribute("data-custom-color") || undefined
    const alignment = el.getAttribute("data-alignment") as Alignment
    const contents = el.innerHTML || ""
    return { title, icon, color, customColor, alignment, content: contents }
  },

  /**
   * Updates a DOM element based on CalloutBoxData.
   * @param el - The DOM element
   * @param data - The CalloutBoxData
   */
  updateElementFromData: (el: HTMLElement, data: CalloutBoxData) => {
    el.setAttribute("data-title", data.title)
    el.setAttribute("data-icon", data.icon)
    el.setAttribute("data-color", data.color)
    if (data.customColor) {
      el.setAttribute("data-custom-color", data.customColor)
    } else {
      el.removeAttribute("data-custom-color")
    }
    el.setAttribute("data-alignment", data.alignment)
    el.innerHTML = data.content
  },
  renderView: ({ data, onDataChange, element, withStyles, readOnly }) => {
    return (
      <CalloutBoxCustomComponent
        data={data}
        onDataChange={onDataChange}
        element={element}
        withStyles={withStyles}
        readOnly={readOnly}
      />
    )
  },
}

/** Props for CalloutBoxCustomComponent */
interface CalloutBoxCustomComponentProps {
  /** Root element of component in light DOM */
  element: HTMLElement
  /** Data for the callout box */
  data: CalloutBoxData
  /** Callback to update the data */
  onDataChange?: (data: CalloutBoxData) => void
  /** Function to wrap children in styles */
  withStyles: (children: React.ReactElement) => React.ReactElement
  readOnly: boolean
}

/** Create icon based on data
 * @param icon The icon to create
 * @param color The color of the icon
 * @param customColor The custom color of the icon
 * @returns The icon
 */
function createIcon(icon: Icon, color: Color, customColor: string | undefined) {
  const colorProp = color === "custom" ? undefined : color
  switch (icon) {
    case "info":
      return <Info color={colorProp} htmlColor={customColor} />
    case "warning":
      return <Warning color={colorProp} htmlColor={customColor} />
    case "error":
      return <Dangerous color={colorProp} htmlColor={customColor} />
    case "success":
      return <CheckCircle color={colorProp} htmlColor={customColor} />
    case "tip":
      return <Lightbulb color={colorProp} htmlColor={customColor} />
    case "question":
      return <Help color={colorProp} htmlColor={customColor} />
  }
  return null
}

/** Renders a callout box
 * @param props Props
 */
function CalloutBoxCustomComponent(props: CalloutBoxCustomComponentProps) {
  const { data, onDataChange, readOnly } = props
  const [editorOpen, setEditorOpen] = useState(false)

  return (
    <>
      <style>{`
      .callout-box-content p {
        margin: 0;
      }

      .callout-box {
        position: relative;
      }
  
      .callout-box .buttons {
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
        z-index: 1;
      }

      .callout-box:hover .buttons {
        visibility: visible;
      }      

      `}</style>
      {props.withStyles(
        <div className="callout-box" style={{}}>
          {!readOnly && (
            <div key="buttons" className="buttons">
              <IconButton
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.8) !important" }}
                onClick={() => setEditorOpen(true)}
              >
                <Edit />
              </IconButton>
              <IconButton
                sx={{ backgroundColor: "rgba(255, 255, 255, 0.8) !important" }}
                onClick={() => {
                  props.element.remove()
                }}
              >
                <Delete />
              </IconButton>
            </div>
          )}
          <CalloutBoxDisplay data={data} />
        </div>
      )}
      {editorOpen && (
        <CalloutBoxDialog
          initialData={data}
          onCancel={() => setEditorOpen(false)}
          onSave={(data) => {
            onDataChange!(data)
            setEditorOpen(false)
          }}
        />
      )}
    </>
  )
}

/** Props for CalloutBoxDisplay */
interface CalloutBoxDisplayProps {
  data: CalloutBoxData
}

/** Renders a callout box
 * @param props Props
 * @param props.data The data for the callout box
 */
function CalloutBoxDisplay(props: CalloutBoxDisplayProps) {
  const { data } = props

  return (
    <Paper
      elevation={2}
      sx={(theme) => ({
        maxWidth: data.alignment === "justify" ? "100%" : "600px",
        marginLeft:
          data.alignment === "right" || data.alignment === "center"
            ? "auto"
            : 0,
        marginRight:
          data.alignment === "left" || data.alignment === "center" ? "auto" : 0,
        p: 2,
        borderTopWidth: 10,
        borderTopStyle: "solid",
        borderTopColor:
          data.color === "custom"
            ? data.customColor
            : theme.palette[data.color]?.main || theme.palette.primary.main,
      })}
    >
      <Stack direction="row" spacing={1} alignItems="start">
        <Box style={{ marginTop: 2 }}>
          {createIcon(data.icon, data.color, data.customColor)}
        </Box>
        <Stack spacing={0}>
          <div>
            <b>{props.data.title}</b>
          </div>
          <div
            className="callout-box-content"
            dangerouslySetInnerHTML={{ __html: props.data.content }}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}

/** Create dialog to edit a callout box
 * @param props Props
 * @param props.initialData The initial data to edit
 * @param props.onSave Called when the dialog is saved
 * @param props.onCancel Called when the dialog is cancelled
 */
function CalloutBoxDialog(props: {
  /** The initial data to edit */
  initialData: CalloutBoxData
  /** Called when the dialog is cancelled */
  onCancel: () => void
  /** Called when the dialog is saved */
  onSave: (data: CalloutBoxData) => void
}) {
  const { initialData, onCancel, onSave } = props

  const [data, setData] = useState<CalloutBoxData>(initialData)

  const colorMenuItems: { value: Color; label: string }[] = [
    { value: "primary", label: "Primary" },
    { value: "error", label: "Error" },
    { value: "success", label: "Success" },
    { value: "info", label: "Info" },
    { value: "warning", label: "Warning" },
    { value: "custom", label: "Custom" },
  ]

  const iconMenuItems: { value: Icon; label: string }[] = [
    { value: "info", label: "Info" },
    { value: "warning", label: "Warning" },
    { value: "error", label: "Error" },
    { value: "success", label: "Success" },
    { value: "tip", label: "Tip" },
    { value: "question", label: "Question" },
  ]

  /** Renders component to edit html */
  function renderHtmlEditor() {
    const model = data.content
    /** Handle change to model
     * @param model The new model
     */
    const onModelChange = (model: string) => {
      setData({ ...data, content: model })
    }

    return (
      <FroalaEditor
        tag="textarea"
        config={minimalFroalaConfig}
        model={model}
        onModelChange={onModelChange}
      />
    )
  }

  // Disable enforce focus to allow Froala image size editing to work
  return (
    <Dialog open={true} maxWidth="lg" fullWidth disableEnforceFocus>
      <DialogTitle>Edit Labels</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            sx={{ mt: 1 }}
          >
            <FormControl fullWidth>
              <InputLabel>Icon</InputLabel>
              <Select
                value={data.icon}
                label="Icon"
                onChange={(e) =>
                  setData({
                    ...data,
                    icon: e.target.value as CalloutBoxData["icon"],
                  })
                }
              >
                {iconMenuItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Box display="flex" alignItems="center" gap={2}>
                      {createIcon(item.value, data.color, data.customColor)}
                      {item.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Color</InputLabel>
              <Select
                value={data.color}
                label="Color"
                onChange={(e) => {
                  if (e.target.value === "custom") {
                    setData({
                      ...data,
                      color: e.target.value as Color,
                      customColor: data.customColor || "#666666",
                    })
                  } else {
                    setData({
                      ...data,
                      color: e.target.value as Color,
                    })
                  }
                }}
              >
                {colorMenuItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Box display="flex" alignItems="center">
                      <Box
                        width={18}
                        height={18}
                        marginRight={2}
                        borderRadius="50%"
                        sx={(theme) => {
                          const colorCode =
                            item.value === "custom"
                              ? data.customColor || "#666666"
                              : theme.palette[item.value]?.main ||
                                theme.palette.primary.main
                          return {
                            backgroundColor: colorCode,
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                          }
                        }}
                      />
                      {item.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {data.color === "custom" && (
              <TextField
                fullWidth
                label="Custom Color e.g. #3A7BCD"
                value={data.customColor}
                onChange={(e) =>
                  setData({ ...data, customColor: e.target.value })
                }
              />
            )}
            <ToggleButtonGroup
              value={data.alignment}
              exclusive
              onChange={(e, value) => {
                if (value) {
                  setData({ ...data, alignment: value })
                }
              }}
              size="small"
            >
              <ToggleButton value="left" aria-label="left aligned">
                <FormatAlignLeft /> Left
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered">
                <FormatAlignCenter /> Center
              </ToggleButton>
              <ToggleButton value="right" aria-label="right aligned">
                <FormatAlignRight /> Right
              </ToggleButton>
              <ToggleButton value="justify" aria-label="justified">
                <FormatAlignJustify /> Justify
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <TextField
            label="Title"
            fullWidth
            margin="dense"
            value={data.title}
            onChange={(e) => setData({ ...data, title: e.target.value })}
          />
          {renderHtmlEditor()}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSave(data)}
          disabled={false}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * Create dialog to add a callout box
 * @param onClose Called when the dialog is closed with html if created or null if cancelled
 */
export function createAddCalloutBoxDialog(
  /** Called when the dialog is closed with html if created or null if cancelled */
  onClose: (html: string | null) => void
) {
  return (
    <CalloutBoxDialog
      onSave={(data) => {
        // Create a new section element for the callout box
        const section = document.createElement("section")
        section.dataset.component = "callout-box"
        // Assuming a similar updateElementFromData function exists for callout boxes
        calloutBoxCustomElement.updateElementFromData(section, data)

        onClose(section.outerHTML)
      }}
      onCancel={() => onClose(null)}
      initialData={{
        title: "",
        content: "",
        icon: "info",
        color: "primary",
        alignment: "left",
      }}
    />
  )
}
