import createStageSelector from "./createStageSelector"

/** The list of reported stages performed during a document upload. */
const UPLOAD_STAGES = {
  failed: {
    caption:
      "The document could not be processed, please try again, contact LearnExperts if the problem persists",
    error: true,
  },
  uploaded: { caption: "Converting Documents" },
  creating_course: {
    caption: "Creating Course",
  },
  converting_documents: {
    caption: "Converting Documents",
  },
  template_transformation: {
    caption: "Applying Template",
  },
  running_detections: {
    caption: "Running Detections",
  },
}

const createUploadStageSelector = (inputSelector) =>
  createStageSelector(inputSelector, UPLOAD_STAGES)

export default createUploadStageSelector
