import { useCallback, useEffect, useState } from "react"
import fetchBranding from "../../../api/fetchBranding"
import updateBranding from "../api/updateBranding"
import { useSnackbar } from "notistack"
import muiTheme from "../../../themes/theme"
import fetchGoogleFonts from "../api/fetchGoogleFonts"

export const defaultColour = muiTheme.palette.primary.main

export const defaultBranding = {
  logo: null,
  brandMark: null,
  colour: null,
  mainColor: null,
  backgroundColor: null,
  heading: {
    name: null,
    url: null,
  },
  body: {
    name: null,
    url: null,
  },
  size: "normal",
  styles: [],
  editorFonts: [],
  changes: {},
}

export interface IGoogleFont {
  name: string
  url: string
}

interface IRecentFonts {
  heading: IGoogleFont[]
  body: IGoogleFont[]
}

interface IBrandingStyle {
  fontSize: string
  lineHeight: string
}
interface IBrandingNamedStyle {
  name: string
  style: IBrandingStyle
}

export interface IEditorFont {
  id: string
  name: string
  url: string
}

export interface IBranding {
  logo: string | null
  brandMark: string | null
  colour: string | null
  mainColor: string | null
  backgroundColor: string | null
  heading: {
    name: string | null
    url: string | null
  }
  body: {
    name: string | null
    url: string | null
  }
  size: string
  styles: IBrandingNamedStyle[] | null
  editorFonts: IEditorFont[]
  changes: any
}

/**
 * Hook for handling functionality for branding configuration retrieval and
 * changes.
 */
const useBranding = () => {
  /**
   * State containing all current branding configuration settings,
   * along with a branding.changes object which tracks any modifications made
   * to the settings. On a save, the branding.changes object passes all its
   * parameters to be persisted on the server.
   */
  const [branding, setBranding] = useState<IBranding>(defaultBranding)

  const [recentFonts, setRecentFonts] = useState<IRecentFonts>({
    heading: [],
    body: [],
  })

  const [googleFonts, setGoogleFonts] = useState<IGoogleFont[]>([])
  const [loading, setLoading] = useState(true)
  const { enqueueSnackbar } = useSnackbar()

  /**
   * Reusable function for overwriting a certain piece of branding state.
   * Updates both the visible change to the configuration and also adds the
   * change to the branding.changes object, which is used in the saveBranding
   * function.
   * @param key - Name of branding setting that is being changed
   * @param value - New value to update the branding state with
   * @param changeValue - Value to be placed with the key in the branding.changes object. Defaults to "value" argument.
   */
  const onPropertyChange = useCallback(
    (key: string, value: any, changeValue: any) => {
      setBranding((prev: any) => ({
        ...prev,
        [key]: value,
        changes: {
          ...prev.changes,
          [key]: changeValue ?? value,
        },
      }))
    },
    []
  )

  /**
   * Insert the newly selected font at the end of the recent list,
   * set the bottom property for styling, and trim the list so it only
   * shows 3.
   *
   * @param selectedFont - Newly selected font object
   * @param variant - Either "heading" or "body"
   */
  const updateRecentFonts = useCallback(
    (selectedFont: IGoogleFont, variant: "heading" | "body") => {
      setRecentFonts((prev: IRecentFonts) => ({
        ...prev,
        [variant]: [
          ...prev[variant]
            .map((font: IGoogleFont) => ({
              ...font,
              bottom: null,
            }))
            .filter((font: any) => font.name !== selectedFont.name),
          { ...selectedFont, bottom: true },
        ].slice(-3),
      }))
    },
    []
  )

  /**
   * Pass all unsaved changes in an object to be sent as a request to
   * the server
   * @param e - Click event from submitting changes
   */
  const saveBranding = useCallback(
    (e: any) => {
      e.preventDefault()
      return updateBranding({ changes: branding.changes, recentFonts }).then(
        () => {
          setBranding((prev) => ({
            ...prev,
            changes: {},
          }))
          enqueueSnackbar("Branding changes saved.", { variant: "success" })
        }
      )
    },
    [branding.changes, enqueueSnackbar, recentFonts]
  )

  /**
   * Fetch the branding configuration for the user
   */
  const getGlobalBranding = useCallback(() => {
    setLoading(true)
    return fetchBranding()
      .then((data) => {
        setLoading(false)
        const {
          colour,
          main_colour,
          background_colour,
          logo,
          brand_mark: brandMark,
          size = "normal",
          styles = [],
          heading_url,
          heading_name,
          body_url,
          body_name,
          heading_recent,
          body_recent,
          editor_fonts,
        } = data

        setRecentFonts({
          heading: heading_recent ?? [],
          body: body_recent ?? [],
        })

        setBranding({
          changes: {},
          colour,
          mainColor: main_colour,
          backgroundColor: background_colour,
          logo,
          brandMark,
          size,
          styles,
          heading: {
            name: heading_name,
            url: heading_url,
          },
          body: {
            name: body_name,
            url: body_url,
          },
          editorFonts: editor_fonts ?? [],
        })

        return data
      })
      .catch((error) => {
        console.log(error)
        enqueueSnackbar(
          "Unable to retrieve branding settings. Please try again later.",
          { variant: "error" }
        )
        setLoading(false)
      })
  }, [enqueueSnackbar])

  // Fetch branding configuration for user
  useEffect(() => {
    getGlobalBranding()
  }, [getGlobalBranding])

  // Fetch Google fonts from API and load them into the browser
  useEffect(() => {
    setLoading(true)
    fetchGoogleFonts()
      .then((data) => {
        setLoading(false)
        if (data.items.length) {
          const totalList = data.items.map(
            ({ family, files, variants }: any) => {
              // Default variant can vary in name, so we check for "regular", then "400", then the first option
              const defaultVariantName =
                variants.find((variant: string) =>
                  variant.includes("regular")
                ) ||
                variants.find((variant: string) => variant.includes("400")) ||
                variants[0]

              const url = files[defaultVariantName]
              const name = `${family}`
              const font = new FontFace(name, `url(${url})`)
              document.fonts.add(font)

              return {
                name: name,
                url: url,
              }
            }
          )

          setGoogleFonts(totalList)
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      })
  }, [])

  /**
   * Flag to determine if any unsaved changes have been applied to the branding
   * configuration.
   */
  const pendingChanges = Object.keys(branding.changes).length

  return {
    saveBranding,
    pendingChanges,
    branding,
    setBranding,
    loading,
    onPropertyChange,
    getGlobalBranding,
    googleFonts,
    recentFonts,
    updateRecentFonts,
  }
}

export default useBranding
