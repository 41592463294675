import { IconButton, Tooltip, ListItemButton } from "@mui/material"
import { getTitle, getLastUpdated } from "../../../../../utilities/transformers"
import { DragIndicator, LinkOff } from "@mui/icons-material"
import ListItemContent from "../../../itemList/ListItemContent"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Item } from "../../../../homeNavigation/types"

type LearningPathItemProps = {
  item: Item
  onRemove: () => void
  onClick: () => void
}

/**
 * Renders a single course within a learning path
 * @param props - Component props
 */
const LearningPathItem = (props: LearningPathItemProps) => {
  const { item, onRemove, onClick } = props

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id, data: item, transition: null })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const { last_update } = item

  return (
    <ListItemButton
      disableRipple
      ref={setNodeRef}
      style={style}
      onClick={onClick}
      divider
    >
      <ListItemContent
        avatar={
          <IconButton
            size="small"
            sx={{
              cursor: "grab",
            }}
            {...attributes}
            {...listeners}
          >
            <DragIndicator />
          </IconButton>
        }
        primaryText={getTitle(item.title)}
        secondaryText={getLastUpdated(last_update)}
        control={
          <Tooltip title="Remove from Learning Path">
            <IconButton
              onClick={(event: any) => {
                event.stopPropagation()
                onRemove()
              }}
            >
              <LinkOff />
            </IconButton>
          </Tooltip>
        }
      />
    </ListItemButton>
  )
}

export default LearningPathItem
