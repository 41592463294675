import axiosInstance from "../../../api/axios"

type CreateHTMLFromCourseSectionsArg = {
  courseID: string
  checkedSections: string[]
  useFullCourse: boolean
}

/**
 * Send request to API to create an HTML string from the provided course and section IDs.
 * @param arg - Parameters for request to be sent
 */
const createHTMLFromCourseSections = (arg: CreateHTMLFromCourseSectionsArg) => {
  const { courseID, checkedSections, useFullCourse = false } = arg
  return axiosInstance
    .post(`/courses/${courseID}/html_from_sections`, {
      sections: checkedSections,
      useFullCourse,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
}

export default createHTMLFromCourseSections
