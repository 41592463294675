import { createSelector, createStructuredSelector } from "reselect"
import createUploadStageSelector from "./createUploadStageSelector"
import { selectJobById } from "./jobsEntityAdaptor"

const createUploadSelector = (id) => {
  const selectJob = (state) => selectJobById(state, id)
  const selectStage = createUploadStageSelector(selectJob)
  const selectIsComplete = createSelector(
    selectJob,
    (job) => job?.status === "complete"
  )

  return createStructuredSelector({
    upload: selectJob,
    stage: selectStage,
    isComplete: selectIsComplete,
  })
}

export default createUploadSelector
