function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(";").shift()
}

async function refreshCookies(options = {}) {
  const { max_age: maxAgeOption, axios } = options

  // check expiry of current cookies
  let cookie_expiry = parseInt(getCookie("CloudFront-Expiry"))
  const now = Math.round(Date.now() / 1000)
  if (cookie_expiry - now > 3600) {
    // cookies are still valid, don't refresh them
    return
  }

  let cookies = await axios.get("/get_auth_cookies")
  const expiry = cookies.data.expiry
  const domain = cookies.data.domain
  const max_age = maxAgeOption ?? expiry - now

  document.cookie = `CloudFront-Expiry=${expiry};max-age=${max_age};SameSite=strict;`
  for (let [name, value] of Object.entries(cookies.data.cookies)) {
    document.cookie = `${name}=${value};domain=${domain};max-age=${max_age};SameSite=strict;`
  }
}

export default refreshCookies
