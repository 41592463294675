import React from "react"
import { styled, Typography } from "@mui/material"

const Footer = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(2),
  paddingTop: theme.spacing(6),
}))

const PageFooter = (props) => {
  return (
    <Footer variant="body2" component="footer" {...props}>
      {props.children}
    </Footer>
  )
}

export default PageFooter
