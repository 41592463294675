import axiosInstance from "../../../api/axios"

const addCourseToLearningPath = (arg) => {
  const { courseId, pathId } = arg

  return axiosInstance
    .post(`/paths/${pathId}/courses`, {
      courseId,
    })
    .then((response) => response.data)
}

export default addCourseToLearningPath
