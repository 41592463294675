import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import PropTypes from "prop-types"
import { isObject, mapValues, union, without } from "lodash/fp"
import withPreventDefault from "../../utilities/withPreventDefault"
import { useCallback, useMemo } from "react"

/**
 * Display a placeholder checkbox preview.
 *
 * @see https://mui.com/components/skeleton/
 */
const SkeletonCheckbox = (props) => {
  const { width } = props
  return (
    <FormControlLabel
      sx={{
        "& .MuiFormControlLabel-label": {
          width: "100%",
          maxWidth: width,
        },
      }}
      control={<Checkbox defaultChecked disabled />}
      label={<Skeleton variant="text" />}
    />
  )
}

const calculateGridTemplateColumns = (columns) => {
  if (isObject(columns)) {
    return mapValues(calculateGridTemplateColumns, { xs: 1, ...columns })
  }

  return `[start] repeat(${columns}, minmax(0, 1fr)) [end]`
}

/**
 * Display a list of checkbox for a given set of options.
 */
const CheckboxSelect = (props) => {
  const {
    options,
    selection,
    loading,
    onSelectionChange,
    getKey,
    getLabel,
    getTooltip,
    columns,
  } = props

  const isSelected = (option) => selection.some((key) => getKey(option) === key)

  const selectAll = () => onSelectionChange(options.map(getKey))
  const deselectAll = () => onSelectionChange([])

  const onChange = useCallback(
    (event) => {
      const name = event.target.name
      const checked = event.target.checked

      onSelectionChange(
        checked ? union([name], selection) : without([name], selection)
      )
    },
    [selection, onSelectionChange]
  )

  const isEmpty = !loading && !options.length
  const gridTemplateColumns = useMemo(
    () => calculateGridTemplateColumns(columns),
    [columns]
  )

  return (
    <Box>
      <FormGroup sx={{ display: "grid", gridTemplateColumns }}>
        {isEmpty && (
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            padding={2}
            gridColumn="start / end"
          >
            No options available.
          </Typography>
        )}
        {loading && (
          <>
            <SkeletonCheckbox width={200} />
            <SkeletonCheckbox width={240} />
          </>
        )}
        {options.map((option) => (
          <FormControlLabel
            control={<Checkbox name={getKey(option)} onChange={onChange} />}
            key={getKey(option)}
            disableTypography
            label={
              getTooltip(option) ? (
                <Tooltip title={getTooltip(option)} enterDelay={700}>
                  <Typography noWrap>{getLabel(option)}</Typography>
                </Tooltip>
              ) : (
                <Typography noWrap>{getLabel(option)}</Typography>
              )
            }
            checked={isSelected(option)}
          />
        ))}
      </FormGroup>
      <Stack direction="row" justifyContent="center" spacing={1}>
        <Button
          size="small"
          disabled={loading || isEmpty}
          onClick={withPreventDefault(selectAll)}
        >
          Select All
        </Button>
        <Button
          size="small"
          disabled={loading || isEmpty}
          onClick={withPreventDefault(deselectAll)}
        >
          Deselect All
        </Button>
      </Stack>
    </Box>
  )
}

CheckboxSelect.propTypes = {
  /** A list of options to display. */
  options: PropTypes.arrayOf(PropTypes.object),
  /** The list of keys representing the current selection. */
  selection: PropTypes.arrayOf(PropTypes.string),
  /** When loading is true, the options will display as a skeleton. */
  loading: PropTypes.bool,
  /**
   * Called whenever the selection would change.
   * @param {string[]} selection The update list of option keys.
   */
  onSelectionChange: PropTypes.func,
  /**
   * Returns the key for an option.
   * @param {any} option An option.
   * @returns {string} The option key.
   */
  getKey: PropTypes.func,
  /**
   * Returns the label for an option.
   * @param {any} option An option.
   * @returns {string} The label to display for the option.
   */
  getLabel: PropTypes.func,
  /**
   * Returns the tooltip for an option.
   * @param {any} option An option.
   * @returns {string} The tooltip to display for the option.
   */
  getTooltip: PropTypes.func,
  /**
   * The number of columns in which to layout the options.
   *
   * You can specify a different number of columns for each breakpoint. For any
   * omitted breakpoints, the columns for the next smallest breakpoint will be
   * used.
   *
   * @example
   * ```js
   * <CheckboxSelect columns={{ xs: 1, sm: 3, md: 4, lg: 6}}
   * ```
   */
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
    }),
  ]),
}

CheckboxSelect.defaultProps = {
  options: [],
  selection: [],
  loading: false,
  onSelectionChange: () => {},
  getKey: ({ key }) => key,
  getLabel: ({ label }) => label,
  getTooltip: () => null,
  columns: 1,
}

export default CheckboxSelect
