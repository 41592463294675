import { scrollIntoView } from "../../utilities/domUtils"
import { Alert, Box, Stack, styled } from "@mui/material"

/** Container that displays a floating popover with a footer
 *
 * @param props - Props for the popover container
 * @param props.footer - Footer content
 * @param props.children - Content to display in the popover
 * @param props.editorWrapper - Wrapper element to scroll into view
 * @param props.message - Message to display in the popover
 */
export function PopoverContainer(props: {
  footer: React.ReactNode
  children: React.ReactNode
  editorWrapper: HTMLElement
  message?: string | null
}) {
  const { footer, children, editorWrapper, message } = props

  return (
    <PopoverContainerDiv
      ref={(elem) => {
        if (elem) {
          scrollIntoView(elem.getBoundingClientRect(), editorWrapper)
        }
      }}
    >
      <Stack gap={1}>
        <Box key="content" sx={{ p: 2, pb: 1 }}>
          {children}
        </Box>
        {message && <Alert severity="warning">{message}</Alert>}
        <Stack
          key="footer"
          sx={{
            /**
             * Set background to be shade of MUI grey
             * @param theme - MUI theme
             */
            backgroundColor: (theme) => theme.palette.grey[200],
            p: 1,
            gap: 1,
          }}
        >
          {footer}
        </Stack>
      </Stack>
    </PopoverContainerDiv>
  )
}

/** Container for smart template panels */
const PopoverContainerDiv = styled("div")(({ theme }) => ({
  borderRadius: 5,
  backgroundColor: theme.palette.grey[200],
  borderColor: theme.palette.grey[300],
  borderWidth: 1,
  borderStyle: "solid",
  boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",
}))
