import { Button, ButtonProps } from "@mui/material"
import { AnimatePresence, motion } from "framer-motion"
import { fade } from "../../utils/animations"

type SubmitButtonProps = { label?: string } & ButtonProps<typeof motion.button>

/**
 * Renders a submit button.
 * @param props The component props.
 */
export function SubmitButton(props: SubmitButtonProps) {
  const { label = "Submit", ...otherProps } = props

  return (
    <Button
      component={motion.button}
      transition={{
        layout: { when: "beforeChildren" },
      }}
      layoutId="submit"
      type="submit"
      variant="contained"
      style={{
        // Hard-coded for framer-motion (see: https://www.framer.com/motion/layout-animations/###border-radius-or-box-shadows-are-behaving-strangely)
        borderRadius: "26px",
      }}
      {...otherProps}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.span key={label} layout="position" {...fade}>
          {label}
        </motion.span>
      </AnimatePresence>
    </Button>
  )
}

export default SubmitButton
