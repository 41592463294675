import { BLOCK_ELEMENTS, isBlockElementType } from "../../utilities/domUtils"
import { useFlag } from "../../utilities/feature-management"

/**
 * Checks if any of the transform to component flags are enabled
 * @returns True if any of the transform to component flags are enabled
 */
export function useTransformToComponentEnabled() {
  const enableFlipCards = useFlag(
    "rollout-transform-text-into-interactions-flip-cards"
  )
  const enableTabs = useFlag("rollout-transform-text-into-interactions-tabs")
  const enableProcess = useFlag(
    "rollout-transform-text-into-interactions-process"
  )
  const enableStyledList = useFlag(
    "rollout-transform-text-into-interactions-styled-list"
  )
  const enableCalloutBox = useFlag(
    "rollout-transform-text-into-interactions-callout-box"
  )
  const enableCategories = useFlag(
    "rollout-transform-text-into-interactions-categories"
  )

  const someComponentTransformEnabled =
    enableFlipCards ||
    enableTabs ||
    enableProcess ||
    enableStyledList ||
    enableCalloutBox ||
    enableCategories

  return someComponentTransformEnabled
}

/**
 * Types of interactive components that can be transformed into.
 */
export type InteractiveComponentType =
  | "flip-card-grid"
  | "process"
  | "tabs"
  | "styled-list"
  | "callout-box"
  | "categories"
  | "labeled-image"

/**
 * Corrects the range to ensure it is within a single block element and expands it to complete sentences if necessary.
 * @param range - The range to correct
 * @param editorContainer - The editor container
 * @returns The corrected range
 */
export function correctRange(range: Range, editorContainer: HTMLElement) {
  const { startElement, endElement } = getStartAndEndElements(range)

  // Find nearest block element to both start and end elements
  const startBlock = startElement.closest(
    BLOCK_ELEMENTS.join(",")
  ) as HTMLElement
  const endBlock = endElement.closest(BLOCK_ELEMENTS.join(",")) as HTMLElement

  // Determine if range spans multiple blocks
  const spansMultipleBlocks = startBlock !== endBlock

  // If spans multiple blocks, get root block for start and end block
  // This is to prevent, for example, selecting two TDs or two LIs. It must be a root block element, e.g. TABLE or UL.
  if (spansMultipleBlocks) {
    const startRootBlock = getRootBlock(startBlock, editorContainer)
    const endRootBlock = getRootBlock(endBlock, editorContainer)
    if (startRootBlock && endRootBlock) {
      range = range.cloneRange()
      range.setStartBefore(startRootBlock)
      range.setEndAfter(endRootBlock)
      return range
    }
  }

  // Otherwise, expand range to complete sentences
  range = expandRangeToCompleteSentences(range)
  return range
}

/**
 * Gets the start and end elements of a range. These elements are the
 * closest to the start and end offsets of the range.
 * @param range - The range to get the start and end elements from
 * @returns The start and end elements which are the elements nearest
 * to the start and the end respectively
 */
export function getStartAndEndElements(range: Range) {
  /** Given a container node and an offset, get the closest element to the offset.
   * @param container - The container node
   * @param offset - The offset within the container
   * @returns The closest element to the offset
   */
  function closestElement(container: Node, offset: number) {
    // If text, get the parent element
    if (container.nodeType === Node.TEXT_NODE) {
      return container.parentElement as HTMLElement
    }

    // If element and offset position is an element, use that
    if (container.nodeType === Node.ELEMENT_NODE) {
      if (
        container.childNodes[Math.min(offset, container.childNodes.length - 1)]
          .nodeType === Node.ELEMENT_NODE
      ) {
        return container.childNodes[
          Math.min(offset, container.childNodes.length - 1)
        ] as HTMLElement
      }

      // Otherwise use container
      return container as HTMLElement
    }

    // Otherwise, get the parent element
    return container.parentElement as HTMLElement
  }

  return {
    startElement: closestElement(range.startContainer, range.startOffset),
    endElement: closestElement(range.endContainer, range.endOffset),
  }
}

/**
 * Get the root block for a block element
 * @param block - The block element
 * @param editorContainer - The editor container
 * @returns The root block
 */
export function getRootBlock(block: HTMLElement, editorContainer: HTMLElement) {
  let rootBlock = block
  while (rootBlock && rootBlock.parentElement !== editorContainer) {
    rootBlock = rootBlock.parentElement as HTMLElement
  }
  return rootBlock
}

/**
 * Expands the range to complete sentences
 * @param range - The range to expand
 * @returns The expanded range
 */
export function expandRangeToCompleteSentences(range: Range) {
  // Find the parent block element of the common ancestor container
  const blockParent =
    range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE
      ? (range.commonAncestorContainer as HTMLElement)
      : range.commonAncestorContainer.parentElement

  if (!blockParent) return range

  let blockElement: HTMLElement | null = blockParent
  while (blockElement && !isBlockElementType(blockElement)) {
    blockElement = blockElement.parentElement
  }

  if (!blockElement || !blockElement.textContent) return range

  // Get start offset in characters of the range
  let startOffset = getOffsetInCharacters(
    blockParent,
    range.startContainer,
    range.startOffset
  )
  let endOffset = getOffsetInCharacters(
    blockParent,
    range.endContainer,
    range.endOffset
  )

  // Find all sentence boundaries in the text content
  const textContent = blockElement.textContent
  const sentenceBoundaryRegex = /([.?!])\s/g

  const sentenceBoundaries = Array.from(
    textContent.matchAll(sentenceBoundaryRegex)
  ).map((match) => match.index! + 2)

  // Be sure that beginning and end are part of sentence boundaries
  if (sentenceBoundaries.length === 0 || sentenceBoundaries[0] !== 0) {
    sentenceBoundaries.unshift(0)
  }
  if (
    sentenceBoundaries.length === 0 ||
    sentenceBoundaries[sentenceBoundaries.length - 1] !== textContent.length
  ) {
    sentenceBoundaries.push(textContent.length)
  }

  // Make start and end offset be the closest sentence boundaries
  for (let i = sentenceBoundaries.length - 1; i >= 0; i--) {
    if (sentenceBoundaries[i] <= startOffset) {
      startOffset = sentenceBoundaries[i]
      break
    }
  }
  for (let i = 0; i < sentenceBoundaries.length; i++) {
    if (sentenceBoundaries[i] >= endOffset) {
      endOffset = sentenceBoundaries[i]
      break
    }
  }

  // Find the actual text nodes that correspond to the new start and end
  return createRangeFromOffsets(blockElement, startOffset, endOffset)
}

/**
 * Get the offset in characters of a node + offset within a container
 * @param container - The container node
 * @param node - The node to get the offset of
 * @param offset - The offset within the node
 * @returns The offset in characters
 */
export function getOffsetInCharacters(
  container: Node,
  node: Node,
  offset: number
) {
  let offsetInCharacters = 0
  const walker = document.createTreeWalker(
    container,
    NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
    null
  )

  if (node.nodeType === Node.TEXT_NODE) {
    // Walk tree to find the text node, adding the length of any text nodes along the way
    let currentNode: Node | null = walker.currentNode
    while (currentNode && currentNode !== node) {
      if (currentNode.nodeType === Node.TEXT_NODE) {
        offsetInCharacters += (currentNode as Text).length
      }
      currentNode = walker.nextNode()
    }
    if (currentNode) {
      offsetInCharacters += offset
    }
    return offsetInCharacters
  } else {
    // Walk tree to find the element node, adding the length of any text nodes along the way
    let currentNode: Node | null = walker.currentNode
    while (currentNode && currentNode !== node.childNodes[offset]) {
      if (currentNode.nodeType === Node.TEXT_NODE) {
        offsetInCharacters += (currentNode as Text).length
      }
      currentNode = walker.nextNode()
    }
    return offsetInCharacters
  }
}

/**
 * Creates a range from offsets within a block element
 * @param blockElement - The block element
 * @param start - The start offset
 * @param end - The end offset
 * @returns The range
 */
export function createRangeFromOffsets(
  blockElement: HTMLElement,
  start: number,
  end: number
) {
  // Find the actual text nodes that correspond to the new start and end
  const walker = document.createTreeWalker(
    blockElement,
    NodeFilter.SHOW_TEXT,
    null
  )
  let node
  let currentPos = 0
  let newRange = document.createRange()

  while ((node = walker.nextNode())) {
    const nodeContent = node.textContent
    if (!nodeContent) continue

    if (currentPos + nodeContent.length >= start) {
      newRange.setStart(node, start - currentPos)
      break
    }
    currentPos += nodeContent.length
  }

  currentPos = 0
  walker.currentNode = blockElement // Reset walker to start

  while ((node = walker.nextNode())) {
    const nodeContent = node.textContent
    if (!nodeContent) continue

    if (currentPos + nodeContent.length >= end) {
      newRange.setEnd(node, end - currentPos)
      break
    }
    currentPos += nodeContent.length
  }

  return newRange
}

/** Replace the contents of a range with the specified HTML that is a block
 * element like a section. Works by extracting out the range and putting
 * the new block in the middle of the range, making sure that there are two root blocks,
 * one before and one after.
 *
 * @param range - The range object representing the portion of the document to replace.
 * @param html - The HTML content to replace the range with.
 * @param editorContainer - The container of the editor, the fr-element, not the fr-wrapper
 */
export function replaceRangeWithBlock(
  range: Range,
  html: string,
  editorContainer: HTMLElement
) {
  // Create a temporary container for the HTML content.
  const tempDiv = document.createElement("div")
  tempDiv.innerHTML = html

  // Use a DocumentFragment to insert the new nodes.
  const fragment = document.createDocumentFragment()
  while (tempDiv.firstChild) {
    // Append each child to the fragment.
    fragment.appendChild(tempDiv.firstChild)
  }

  // Get the root block of the end element
  const { endElement } = getStartAndEndElements(range)
  const endBlock = getRootBlock(endElement, editorContainer)

  // Place new content after the end block
  const insertBefore = endBlock.nextSibling

  // Create afterRange from the end of the range to the end of the end block
  const afterRange = range.cloneRange()
  afterRange.collapse(false)
  afterRange.setEndAfter(endBlock)

  // Keep the after range
  const afterRangeContents = afterRange.extractContents()

  // Extract out the range
  range.deleteContents()

  // Put new content after the start block
  editorContainer.insertBefore(fragment, insertBefore)

  // Put back after the start block
  editorContainer.insertBefore(afterRangeContents, insertBefore)
}

/**
 * Extracts the HTML content within a specified range.
 *
 * @param range - The range object representing the portion of the document to extract.
 * @returns The HTML content within the specified range.
 */
export function getRangeHTML(range: Range): string {
  const container = document.createElement("div")
  container.appendChild(range.cloneContents())
  return container.innerHTML
}

/** Replace the contents of a range with the specified HTML.
 * @param range - The range object representing the portion of the document to replace.
 * @param html - The HTML content to replace the range with.
 * @param editorContainer - The container of the editor, the fr-element, not the fr-wrapper
 */
export function replaceRangeContents(
  range: Range,
  html: string,
  editorContainer: HTMLElement
) {
  // Create a temporary container for the HTML content.
  const tempDiv = document.createElement("div")
  tempDiv.innerHTML = html

  // If a partially selected block, unwrap inserted block element if it's a single element
  // That is, if the inserted block is <p>some text</p>, unwrap to "some text" and place it in
  if (
    isPartialBlockSelection(range, editorContainer) &&
    tempDiv.childElementCount <= 1
  ) {
    if (
      tempDiv.childElementCount === 1 &&
      tempDiv.firstElementChild?.tagName === "P"
    ) {
      // Unwrap block element
      tempDiv.innerHTML =
        (tempDiv.firstChild as HTMLElement).innerHTML.trim() + " "
    }

    // Use a DocumentFragment to insert the new nodes.
    const fragment = document.createDocumentFragment()
    while (tempDiv.firstChild) {
      // Append each child to the fragment.
      fragment.appendChild(tempDiv.firstChild)
    }

    // Clear the existing contents of the range.
    range.deleteContents()

    // Insert the fragment into the range.
    range.insertNode(fragment)
    return
  }

  // Insert as a root block as it can't be spliced into the middle of a block
  replaceRangeWithBlock(range, html, editorContainer)
}

/**
 * Determine if a range only partially selects a single block
 * @param range - The range to check
 * @param editorContainer - The container of the editor, the fr-element, not the fr-wrapper
 * @returns True if the range only partially selects a single block
 */
export function isPartialBlockSelection(
  range: Range,
  editorContainer: HTMLElement
) {
  const { startElement, endElement } = getStartAndEndElements(range)
  const startBlock = getRootBlock(startElement, editorContainer)
  const endBlock = getRootBlock(endElement, editorContainer)

  // If two different blocks are selected, it's not a partial block selection
  if (startBlock !== endBlock) {
    return false
  }

  // Create range to start of block
  const startBlockRange = range.cloneRange()
  startBlockRange.collapse(true)
  startBlockRange.setStart(startBlock, 0)

  // Create range to end of block
  const endBlockRange = range.cloneRange()
  endBlockRange.collapse(false)
  endBlockRange.setEnd(endBlock, endBlock.childNodes.length)

  // Not fully selected if either block is not empty
  if (!endBlockRange.collapsed || !startBlockRange.collapsed) {
    return true
  }

  return false
}
