import FilterListIcon from "@mui/icons-material/FilterList"
import { Box, Collapse, Divider, Stack, Typography } from "@mui/material"
import { useState } from "react"
import Drawer from "../../atoms/Drawer"
import IconButton from "../../atoms/IconButton"
import LearnAdvisorLogo from "./LearnAdvisorLogo"
import RemarkList, { RemarkFilterCount } from "./RemarkList"
import RemarksResolutionFilter from "./RemarksResolutionFilter"
import RemarksTypeFilter from "./RemarksTypeFilter"

/**
 * A drawer encapulating the LearnAdvisor feature.
 */
const LearnAdvisorDrawer = (props) => {
  const { drawerProps, onToggle, readOnly, ...otherProps } = props

  const [showFilters, setShowFilters] = useState(false)

  return (
    <Drawer
      onToggle={onToggle}
      name="Detections"
      {...drawerProps}
      {...otherProps}
      toolbar={
        <>
          {!readOnly ? (
            <>
              <LearnAdvisorLogo />
              <Box sx={{ flexGrow: 1 }}>
                <RemarkFilterCount />
              </Box>
              <IconButton
                title="Filters"
                Icon={FilterListIcon}
                edge="end"
                color={showFilters ? "primary" : "default"}
                onClick={() => setShowFilters((showFilters) => !showFilters)}
              />
            </>
          ) : (
            <>
              <Typography fontWeight="bold" variant="body">
                Comments
              </Typography>
            </>
          )}
        </>
      }
    >
      <Collapse in={showFilters} sx={{ flexShrink: 0 }}>
        <Stack paddingX={4} paddingY={2} gap={2}>
          <RemarksTypeFilter />
          <RemarksResolutionFilter />
        </Stack>
        <Divider />
      </Collapse>
      <RemarkList readOnly={readOnly} />
    </Drawer>
  )
}

export default LearnAdvisorDrawer
