import { useMemo } from "react"
import { useGuestEmailDispatch } from "../components/widgets/GuestEmailDialog/useGuestEmail"
import { useAppDispatch } from "../store"
import {
  deleteComment,
  deleteCommentReply,
  editComment,
  editCommentReply,
  replyToComment,
  resolveComment,
  showPendingComment,
  hidePendingComment,
} from "../store/remarks"
import addCommentAction from "../store/remarks/actions/addComment"
import selectIsAuthorizedToComment from "../store/remarks/selectors/selectIsAuthorizedToComment"

/**
 * @typedef {{ path: number[], offset: number | null, tag: string,  text: string }} Location
 * @typedef {Element | Location} ElementOrLocation
 * @typedef {import("../store/remarks/remarks").Comment} Comment
 */

/**
 * Supplies a set of action dispatchers for manipulating comment.
 */
export const useCommentsDispatch = () => {
  const dispatch = useAppDispatch()
  const { promptForEmail } = useGuestEmailDispatch()

  return useMemo(() => {
    /**
     * Confirm user has associated email before dispatching action.
     *
     * @param {*} action
     * @param options
     * @returns
     */
    const confirmAuthThenDispatch = (action, options = {}) => {
      return dispatch((dispatch, getState) => {
        if (!selectIsAuthorizedToComment(getState())) {
          const { dispatchAfterAuth } = options
          promptForEmail(dispatchAfterAuth && { pendingAction: action })
          return
        }

        return dispatch(action)
      })
    }

    return {
      /**
       * Add a comment to the current course document.
       *
       * The comment will have the include the given message and may be associated
       * with a given document location. The location can be inferred from a given
       * DOM element.
       *
       * @example
       * ```jsx
       * const Control = () => {
       *   const inputRef = useRef()
       *   const { addComment } = useCommentsDispatch()
       *
       *   const onSubmit = (e) => {
       *     e.preventDefault()
       *     addComment(inputRef.current.value)
       *     inputRef.current.value = ""
       *    }
       *
       *   return (
       *     <form onSubmit={onSubmit}>
       *       <TextField inputRef={inputRef} />
       *       <Button type="submit">Add Comment</Button>
       *     </form>
       *   )
       * }
       * ```
       *
       * @type {(message: string, location?: Element | Location) => Promise<Comment | null>}
       */
      addComment: async (message) => {
        confirmAuthThenDispatch(addCommentAction({ text: message }))
      },
      /**
       * Display a pending comment for the given document location.
       */
      showPendingComment: (location = null) => {
        confirmAuthThenDispatch(
          showPendingComment({ visible: true, location }),
          { dispatchAfterAuth: true }
        )
      },
      /**
       * Update the text for the specified comment.
       *
       * @param {string} id
       * @param {string} text
       *
       */
      editComment: (id, text) => {
        confirmAuthThenDispatch(editComment({ id, text }))
      },
      /**
       * Delete a given comment from the current course.
       * @param {string} id
       */
      deleteComment: (id) => {
        confirmAuthThenDispatch(deleteComment({ id }))
      },
      /**
       * Resolve a given comment for the current course.
       * @param {*} id
       */
      resolveComment: (id) => {
        confirmAuthThenDispatch(resolveComment({ id }))
      },
      /**
       * Add a reply to the specified comment.
       * @param {string} id
       * @param {string} text
       */
      replyToComment: (id, text) => {
        return confirmAuthThenDispatch(replyToComment({ id, text }))
      },

      /**
       * Edit a comment reply.
       *
       * @param {string} commentId
       * @param {string} id
       * @param {string} text
       */
      editCommentReply: (commentId, id, text) => {
        confirmAuthThenDispatch(editCommentReply({ commentId, id, text }))
      },

      /**
       * Delete a comment reply.
       *
       * @param {string} commentId
       * @param {string} id
       */
      deleteCommentReply: (commentId, id) => {
        confirmAuthThenDispatch(deleteCommentReply({ commentId, id }))
      },

      /**
       * Discard the active pending comment.
       */
      discardPendingComment: () => {
        dispatch(hidePendingComment({ visible: false, location: null }))
      },
    }
  }, [dispatch, promptForEmail])
}

export default useCommentsDispatch
