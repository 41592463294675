import axiosGlobal from "./axios"

/**
 * Create a zip with an export file, a completion certificate and a word description
 * @param courseId - the course
 * @param exportUrl - the exported zip
 * @param axios - axios
 */
const createExportZip = async (
  courseId: string,
  exportUrl: string,
  axios = axiosGlobal
): Promise<string> => {
  const response = await axios.get(
    `/export/${courseId}/description?export_file_url=${encodeURIComponent(
      exportUrl
    )}`
  )
  return response.data.export_url
}

export default createExportZip
