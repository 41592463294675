import { Grid, Stack, Typography } from "@mui/material"

type LabelValuePairProps = {
  label: string
  value: string
}

/**
 * Displays a label and accompanying data for a given history record
 * @param props - Component props
 */
const LabelValuePair = (props: LabelValuePairProps) => {
  const { label, value } = props

  return (
    <Grid item xs={12} lg={6}>
      <Stack direction="row">
        <Typography
          sx={{
            fontWeight: "bold",
            width: "16ch",
            flexShrink: 0,
          }}
          variant="bodySmall"
        >
          {label}:
        </Typography>

        <Typography
          sx={{
            flexShrink: 1,
          }}
          display="inline-block"
          variant="bodySmall"
        >
          {value}
        </Typography>
      </Stack>
    </Grid>
  )
}

export default LabelValuePair
