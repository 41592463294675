import { useLDClient } from "launchdarkly-react-client-sdk"
import { useAuth } from "../../contexts/authorization"
import { useEffect } from "react"

/**
 * Configures the flag provider with the current user context.
 */
function useIdentify() {
  const { user } = useAuth() as {
    user?: { id: string; name: string; email: string }
  }
  const client = useLDClient()

  useEffect(() => {
    if (!client) {
      return
    }

    if (!user) {
      client.identify({ kind: "user", anonymous: true })
      return
    }

    client.identify({
      kind: "user",
      key: user.id,
      name: user.name,
      email: user.email,
    })
  }, [user, client])
}

export default useIdentify
