import {
  Badge,
  Tooltip,
  IconButton as MuiIconButton,
  alpha,
} from "@mui/material"

/**
 * IconButton displays a button control labelled with an icon.
 */
export const IconButton = (props) => {
  const {
    title,
    badge,
    IconComponent,
    Icon = IconComponent,
    onClick,
    href,
    toggled,
    children,
    ...otherProps
  } = props
  const buttonProps = { onClick, href, ...otherProps }

  return (
    <Tooltip title={title}>
      <MuiIconButton
        color="primary"
        {...buttonProps}
        sx={(theme) => ({
          ...(toggled && {
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
            border: "1px solid rgba(0, 0, 0, 0.08)",
            "&:hover, &:focus": {
              backgroundColor: alpha(theme.palette.primary.main, 0.16),
            },
          }),
        })}
      >
        <Badge
          badgeContent={badge}
          sx={(theme) => ({
            ".MuiBadge-badge": {
              background: theme.palette.secondary.main,
              color: theme.palette.common.white,
            },
          })}
        >
          {children}
          {Icon && <Icon />}
        </Badge>
      </MuiIconButton>
    </Tooltip>
  )
}

export default IconButton
