import { Box, Collapse, Divider, Stack } from "@mui/material"
import { memo } from "react"
import { useActiveSection } from "./context/ActiveSectionProvider"
import DocumentSummary from "./DocumentSummary"

import wordmark from "../../assets/images/LearnAnalyzer-wordmark.svg"

/**
 * @typedef {{ $el: Element[] }} FroalaEditorInterface
 *  A Froala editor instance exposing the document container element.
 *
 * @typedef {Object} LearnAnalyzerProps
 * @property {FroalaEditorInterface} editor A Froala editor instance.
 */

/**
 * Displays reading stats for the current editor and section.
 *
 * @type {(props: LearnAnalyzerProps) => JSX.Element}
 */
const LearnAnalyzer = (props) => {
  const { editor } = props

  const container = editor?.$el?.[0]
  const section = useActiveSection()

  return (
    <Stack data-cy="learn-analyzer" padding={2}>
      <LearnAnalyzerWordMark />
      <Collapse in={!!section}>
        <DocumentSummary container={container} section={section} />
        <Divider />
      </Collapse>
      <DocumentSummary
        data-cy="total-summary"
        container={container}
        includeStats={["text", "duration"]}
      />
    </Stack>
  )
}

export default memo(LearnAnalyzer)

/**
 * Wordmark for the LearnAnalyzer section.
 */
const LearnAnalyzerWordMark = () => {
  return (
    <Box>
      <img
        src={wordmark}
        alt="learnanalyzer wordmark"
        style={{ height: "1.5rem" }}
        className="component_wordmark"
      />
    </Box>
  )
}
