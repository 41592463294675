import { ListItem, Skeleton } from "@mui/material"
import ListItemContent from "../../../itemList/ListItemContent"

/**
 * Skeleton component to appear while loading a learning path
 */
const LearningPathSkeleton = () => {
  return (
    <ListItem divider>
      <ListItemContent
        avatar={
          <Skeleton
            sx={{ borderRadius: "100%" }}
            variant="rounded"
            height={56}
            width={56}
          />
        }
        primaryText={<Skeleton width={340} />}
      />
    </ListItem>
  )
}

export default LearningPathSkeleton
