import { useEffect } from "react"
import { useSnackbar } from "notistack"
import axios from "../api/axios"

/** The message displayed during an service outage. */
const ERROR_MESSAGE = "We are experiencing issues connecting to the server."

/** Configuration for the outage notification.*/
const SNACKBAR_CONFIG = {
  key: "leai/network-error",
  variant: "error",
  preventDuplicate: true,
  persist: true,
}

/** Number of consecutive failed requests before notifying the user.  */
const NETWORK_FAILURE_THRESHOLD = 3

/** Duration to show the notification after the last reported error .*/
const FAILURE_NOTICE_DURATION = 60000 // 1 min

/**
 *
 * The useNetworkError hook sets up axios interceptors to monitor whether we can successfully
 * connect to the backend. It will trigger a persistent Notification for the duration of the outage,
 * and clear it when the connection is back online
 *
 */
const useNetworkError = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  // Upon mounting, set up the axios interceptors to watch responses from the server
  useEffect(() => {
    let errorCount = 0
    let lastError = null
    let timeout = undefined

    /**
     * Handle a successful request.
     * @param response The response data.
     */
    const onConnectionSuccess = (response) => {
      // We successfully connected to the server, so remove indicators of network error
      errorCount = 0
      closeSnackbar(SNACKBAR_CONFIG.key)
      clearTimeout(timeout)
      return response
    }

    /**
     * Handle a request failure.
     * @param err The error data.
     */
    const onConnectionError = (err) => {
      // Ignore repeated error instances
      if (err === lastError) {
        return Promise.reject(err)
      }

      lastError = err

      // if there is no response from the server, or the response is a 500 error, we set the error indicator
      const isNetworkError = !err.response || err.response?.status >= 500

      if (isNetworkError) {
        errorCount += 1
      }

      if (errorCount >= NETWORK_FAILURE_THRESHOLD) {
        enqueueSnackbar(ERROR_MESSAGE, SNACKBAR_CONFIG)
        clearTimeout(timeout)
        timeout = setTimeout(
          () => closeSnackbar(SNACKBAR_CONFIG.key),
          FAILURE_NOTICE_DURATION
        )
      }

      return Promise.reject(err)
    }

    const responseInterceptor = axios.interceptors.response.use(
      onConnectionSuccess,
      onConnectionError
    )
    return () => {
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [enqueueSnackbar, closeSnackbar])
}

export default useNetworkError
