import { createAsyncThunk } from "@reduxjs/toolkit"
import { without } from "lodash/fp"
import remarksAdaptor from "../remarksAdaptor"
import selectCurrentCourse from "../selectors/selectCurrentCourse"
import normalizeComments from "../utils/normalizeComments"

/**
 * @typedef {{
 *   extra: {
 *     api: import("../../types").API
 *   }
 *   state: import("../..").AppState
 * }} ThunkApiConfig
 * @typedef {{ id: string }} ThunkArg
 */

/**
 * Refresh comments
 *
 */
export const refreshComments = createAsyncThunk(
  "comments/refresh",
  async (arg, context) => {
    const {
      getState,
      extra: { api },
    } = context

    const state = getState()

    const courseId = selectCurrentCourse(state.remarks)

    return await api
      .getComments(courseId)
      .then(({ data }) => {
        return data
      })
      .then(normalizeComments)
  }
)

export default refreshComments

/**
 * Builds the reducer cases for deleting a comment.
 *
 * @param {import("@reduxjs/toolkit").ActionReducerMapBuilder<import("../remarks").RemarksState>} builder
 */
export const buildCasesForRefreshComments = (builder) => {
  builder.addCase(refreshComments.fulfilled, (state, action) => {
    remarksAdaptor.upsertMany(state, action.payload)

    const oldIds = remarksAdaptor
      .getSelectors()
      .selectIds(state)
      .filter((id) => id.startsWith("comment#"))
    const newIds = action.payload.map(remarksAdaptor.selectId)

    const staleIds = without(newIds, oldIds)

    remarksAdaptor.removeMany(state, staleIds)
  })
}
