import { Close } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  useMediaQuery,
} from "@mui/material"
import CopyToClipboardField from "../atoms/CopyToClipboardField"
import { forwardRef } from "react"
import { useTheme, Theme } from "@mui/material/styles"

interface LmsExportDialogProps {
  courseTitle: string
  url: string
  open: boolean
  showLmsDeleteWarning: boolean
  onClose: () => void
}
/**
 * A modal presenting the scorm URL for a course exported to Skilljar
 *
 * @param props The props
 * @param props.courseTitle Title of course
 * @param props.url  - link to skilljar artifact
 * @param props.open - dialog state
 * @param props.onClose - callback on close
 * @param props.showLmsDeleteWarning - show Delete Warning

 */
const LmsExportDialog = ({
  courseTitle,
  url,
  showLmsDeleteWarning,
  open,
  onClose,
}: LmsExportDialogProps) => {
  const fullScreen = useFullscreenDialog()

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      scroll={fullScreen ? "paper" : "body"}
      keepMounted
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>
        Skilljar Link
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>{courseTitle}</DialogContentText>
        <DialogContentText sx={{ mb: 1 }}>
          Use this link to view your course in Skilljar
        </DialogContentText>
        <CopyToClipboardField
          value={url}
          fullWidth
          helperText="You must sign in to the Skilljar dashboard'"
        />

        {showLmsDeleteWarning && (
          <DialogContentText sx={{ mt: 1, mb: 1 }}>
            Some lessons have been removed from the course. This must be
            completed manually from the Skilljar dashboard.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

LmsExportDialog.defaultProps = {
  open: false,
}

export default LmsExportDialog

/**
 * Show as full-screen on sm displays
 */
const useFullscreenDialog = () => {
  const theme: Theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

const Transition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
