import { useBreakpoints } from "../../../hooks/useDrawerState"
import { useCallback, useMemo, useState } from "react"
import { getTitle } from "../../../utilities/transformers"
import { SortOption } from "../SortSelect"

/**
 * Setting the initial row count to 4 will yield an initial view of 20 list items
 * and approximately 20 tiles on most viewports, as requested by LD-2123.
 */
const DEFAULT_INITIAL_ROW_COUNT = 4

/**
 * Configuration for number of rows to display on screen based on viewport size.
 */
const TILE_LAYOUTS = [
  {
    breakpoint: "xs",
    tiles: 2,
  },
  {
    breakpoint: "sm",
    tiles: 2,
  },
  {
    breakpoint: "md",
    tiles: 3,
  },
  {
    breakpoint: "lg",
    tiles: 4,
  },
  {
    breakpoint: "xl",
    tiles: 6,
  },
]

type Breakpoint = {
  xl: boolean
  lg: boolean
  md: boolean
  sm: boolean
  xs: boolean
}

export const sortOptions = [
  {
    label: "Sort by modified date",
    value: "last_update",
  },
  {
    label: "Sort A - Z",
    value: "a_z",
  },
  {
    label: "Sort Z - A",
    value: "z_a",
  },
] as SortOption[]

export type SortKey = typeof sortOptions[number]["value"]

/**
 * Filter documents list by a query string
 * @param query - User inputted query string to search by
 * @param courses - Initial list of courses to be filtered
 */
const filterCourses = (query: string, courses: any[]) => {
  if (!query) {
    return courses
  }
  return courses.filter((course) => {
    return getTitle(course.title).toLowerCase().includes(query.toLowerCase())
  })
}

/**
 * Sort data  based on chosen ordering method
 * @param sortBy - String value of selected sort options
 * @param list - Total list of either courses or learning paths
 */
export const sortList = (sortBy: string, list: any) => {
  if (sortBy === "last_update") {
    /**
     * By default, documents fetched from the server are in order of last updated, so no
     * sort routine needs to be applied. This will have to change if we migrate to indexing
     * on the backend.
     */
    return list
  }

  return list.toSorted(
    (a: any, b: any) =>
      (sortBy === "a_z" ? 1 : -1) * a.title.localeCompare(b.title)
  )
}

/**
 * Hook to expose logic for rendering a given collection of courses to the
 * home screen. Includes functionality for searching, sorting, toggling view,
 * and displaying tiles.
 *
 * @param items - All directory items
 * @param query - Search query
 */
const useCoursesDisplay = (items: any, query: string) => {
  const breakpoints = useBreakpoints()

  const [visibleRows, setVisibleRows] = useState(DEFAULT_INITIAL_ROW_COUNT)

  const [isGridView, setIsGridView] = useState(() => {
    const viewLayout = window.localStorage.getItem("viewLayout")
    return viewLayout ? viewLayout === "grid" : true
  })

  /**
   * Swap between grid and list views. Initial view is set by localstorage item.
   */
  const toggleView = () => {
    if (isGridView) {
      window.localStorage.setItem("viewLayout", "list")
    } else {
      window.localStorage.setItem("viewLayout", "grid")
    }
    setIsGridView(!isGridView)
  }

  /**
   * Examine the current breakpoints set on the viewport, find the largest one
   * for the current screen width, and return the appropriate number of tiles
   * per row.
   */
  const getItemsPerRow = useCallback(() => {
    const currentLayoutSize = TILE_LAYOUTS.filter(
      (layout) => breakpoints[layout.breakpoint as keyof Breakpoint]
    ).slice(-1)[0]

    return currentLayoutSize?.tiles ?? 0
  }, [breakpoints])

  /**
   * Perform filter via search query on the courses sorted in order
   */
  const filteredItems = useMemo(
    () => filterCourses(query, items || []),
    [query, items]
  )

  /**
   * Increment the number of rows/list items visible
   */
  const showMore = () => {
    setVisibleRows((prev) => prev + 1 * DEFAULT_INITIAL_ROW_COUNT)
  }

  const visibleTiles = filteredItems.slice(
    0,
    getItemsPerRow() * visibleRows - 1
  )
  const visibleListItems = filteredItems.slice(0, 5 * visibleRows)

  return {
    courses: filteredItems,
    visibleTiles,
    visibleListItems,
    showMore,
    toggleView,
    isGridView,
  }
}

export default useCoursesDisplay
