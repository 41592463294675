import { styled } from "@mui/material"
import src from "../../../assets/img/learn_advisor.svg"

const Logo = styled("img")({ height: "1.4em", objectFit: "contain" })

/**
 * A logo for the LearnAdvisor feature.
 */
const LearnAdvisorLogo = (props) => {
  return <Logo src={src} alt="Learn Advisor" {...props} />
}

export default LearnAdvisorLogo
