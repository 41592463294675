import React, { useState } from "react"
import { Box, Card, Grid, Typography } from "@mui/material"
import { getTrashed, getTitle } from "../../utilities/transformers"
import TrashedCourseContextMenu from "./TrashedCourseContextMenu"
import { Description } from "@mui/icons-material"
import { styled } from "@mui/system"

const RootCard = styled(Card)(({ theme }) => {
  return {
    display: "flex",
    height: theme.spacing(24),
    "&:hover": {
      boxShadow: `0px 4px 10px ${theme.palette.lexGrey}`,
    },
  }
})

const VisualBox = styled(Box)(({ theme }) => {
  return {
    minWidth: "40%",
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    display: "flex",
  }
})

const DocumentDescription = styled(Description)(({ theme }) => {
  return {
    left: 0,
    right: 0,
    margin: "auto",
    textAlign: "center",
    position: "absolute",
    top: "45%",
    color: theme.palette.lexWhite,
    transform: "scale(4)",
  }
})

const DetailsBox = styled(Box)(({ theme }) => {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
  }
})

const DocumentNameTypo = styled(Typography)(({ theme }) => {
  return {
    fontSize: "1.125rem",
    textDecoration: "none",
    fontWeight: 500,
    color: theme.palette.lexBlack,
    wordBreak: "break-word",
    overflow: "hidden",
  }
})

const ControlsBox = styled(Box)(({ theme }) => {
  return {
    marginTop: "auto",
    marginLeft: "auto",
    display: "flex",
  }
})

/**
 * TrashedCoursesGridItem function
 * @param props props
 * @param props.document document
 * @param props.onDelete onDelete
 * @param props.onRestore onRestore
 */
const TrashedCoursesGridItem = (props: {
  document: any
  onDelete: any
  onRestore: any
}) => {
  const { document, onDelete, onRestore } = props
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <RootCard
        data-cy="courses-grid-item"
        onMouseLeave={() => setIsHovered(false)}
        onMouseEnter={() => setIsHovered(true)}
      >
        <VisualBox>
          <DocumentDescription />
        </VisualBox>
        <DetailsBox>
          <DocumentNameTypo gutterBottom>
            {getTitle(document.title)}
          </DocumentNameTypo>
          <div>
            <Typography variant="body2">
              <>{getTrashed(document.trash_date)} </>
            </Typography>
          </div>
          <ControlsBox>
            <TrashedCourseContextMenu
              document={document}
              deleteCourse={onDelete}
              restoreCourse={onRestore}
            />
          </ControlsBox>
        </DetailsBox>
      </RootCard>
    </Grid>
  )
}

export default TrashedCoursesGridItem
