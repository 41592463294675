import { map, without } from "lodash/fp"
import { CourseCancelUploadDialog } from "../../../../../components/widgets/CourseCancelUploadControl"
import ConfirmTrashDialog from "../../../../homeNavigation/components/ConfirmTrashDialog"
import FolderTitleDialog from "../../../../homeNavigation/components/FolderTitleDialog"
import MoveItemDialog from "../../../../homeNavigation/components/MoveItemDialog"
import AddCourseImageDialog from "../../../../welcome/AddCourseImageDialog"
import { Item } from "../../../../homeNavigation/types"
import useCourseActions from "../../../../homeNavigation/hooks/useCourseActions"
import useCourseImportApi from "../../../../createCourse/hooks/useCourseImportApi"
import { DialogActionName, DirectoryItemActionDialogsProps } from "../types"

export const {
  TRASH,
  CANCEL_UPLOAD,
  UPLOAD_IMAGE,
  MOVE_COURSE,
  CREATE_FOLDER,
  RENAME_FOLDER,
} = DialogActionName

/**
 * Component to mount all different dialogs for course item interactions
 * @param props - Component props
 */
const DirectoryItemActionDialogs = (props: DirectoryItemActionDialogsProps) => {
  const {
    dialogAction,
    setCheckedItems,
    refreshItems,
    refreshLastUpdatedCourse,
    directory,
    handleMoveItems,
    handleCreateDirectory,
    handleRenameDirectory,
    onClose,
  } = props

  const name = dialogAction?.name ?? null
  const targetItems = dialogAction?.targetItems ?? []

  const { moveCoursesToTrash, saveCustomCourseImage } =
    useCourseActions(refreshItems)

  const { cancelImport } = useCourseImportApi()

  switch (name) {
    case TRASH:
      return (
        <ConfirmTrashDialog
          itemsToTrash={targetItems}
          setConfirmingTrash={onClose} // Whenver this callback is invoked we want to unmount dialog
          onTrashConfirmed={async (courses) => {
            const ids = map("id", courses)
            await moveCoursesToTrash(ids)
            setCheckedItems((prev: any) => without(ids, prev))
            onClose()

            // Deleting a course could end up what is the most recently updated available, so refresh
            refreshLastUpdatedCourse()
          }}
        />
      )
    case CANCEL_UPLOAD:
      return (
        <CourseCancelUploadDialog
          document={targetItems[0]}
          onConfirmCancelUpload={async (item: Item) => {
            onClose()
            if (item != null) {
              await cancelImport(item.id)
            }
          }}
        />
      )
    case UPLOAD_IMAGE:
      return (
        <AddCourseImageDialog
          course={targetItems[0]}
          open={true}
          onClose={onClose}
          saveCustomCourseImage={(course: Item, image: any) => {
            saveCustomCourseImage(course.id, image).then(() => {
              onClose()
            })
          }}
        />
      )
    case MOVE_COURSE:
      return (
        <MoveItemDialog
          itemsToMove={targetItems}
          onConfirm={(items: any, destination: any) => {
            handleMoveItems(items, destination)
            onClose()
            setCheckedItems([])
          }}
          currentDirectory={directory}
          onCancel={onClose}
        />
      )
    case CREATE_FOLDER:
      return (
        <FolderTitleDialog
          onConfirm={(title: any) => {
            handleCreateDirectory(title)
            onClose()
          }}
          onCancel={onClose}
          titleText="Create Folder"
          contentText="Enter a name for the new folder"
          confirmText="Create"
          initialText=""
        />
      )
    case RENAME_FOLDER:
      return (
        <FolderTitleDialog
          onConfirm={(newTitle) => {
            handleRenameDirectory(targetItems[0].id, newTitle)
            onClose()
          }}
          onCancel={onClose}
          titleText="Rename Folder"
          contentText="Enter a new name for the folder"
          confirmText="Rename"
          initialText={targetItems[0].title}
        />
      )
    default:
      return null
  }
}

export default DirectoryItemActionDialogs
