import axiosInstance from "../../../api/axios"

/**
 * Send request to get the most recently edited course
 */
const fetchLastUpdatedCourse = () =>
  axiosInstance
    .get("/courses/last_updated")
    .then((response) => (response.status === 200 ? response.data : null))

export default fetchLastUpdatedCourse
