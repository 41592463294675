import { Typography } from "@mui/material"
import React from "react"
import ErrorMessage from "./ErrorMessage"

const CoursesError = ({ error }) => {
  const genericError = error.response == null
  const subtitle = genericError
    ? "We are having some trouble connecting to the server."
    : "We weren't able to retrieve your courses. Here's some more info on what happened."

  let additionalInfo

  if (!genericError) {
    const { description, code, name } = error.response.data

    additionalInfo = (
      <>
        {code != null && name != null && (
          <Typography variant="body2">
            {code} {name}
          </Typography>
        )}
        <Typography variant="body2">{description}</Typography>
      </>
    )
  }

  return (
    <ErrorMessage title="Looks like something went wrong." subtitle={subtitle}>
      {additionalInfo}
    </ErrorMessage>
  )
}

export default CoursesError
