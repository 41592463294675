import {
  Box,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import React, { useCallback } from "react"
import { OrganizationStrategy } from "./utils/constants"

/**
 * CheckboxSelect component props
 */
export interface CheckboxSelectProps {
  organizeBy: OrganizationStrategy
  options: { key: string; label: string | null; duration: any }[]
  loading: boolean

  selection: string[]
  onSelectionChange: (selection: string[]) => void

  /** Index of the preview option the selected options */
  previewIndex: number
  onPreviewIndexChange: (index: number) => void
}

/**
 * Component for rendering checkbox options in the Micro-Learning form
 *
 * @param props - Component props
 */
const CheckboxSelect = (props: CheckboxSelectProps) => {
  const {
    organizeBy,
    options,
    selection,
    loading,
    onSelectionChange,
    previewIndex,
    onPreviewIndexChange,
  } = props

  /**
   * Compare input to see if its value is included in selection
   * @param option - Checkbox option
   */
  const isSelected = (option: any) => {
    return (
      organizeBy === OrganizationStrategy.FullDocument ||
      selection.some((key: any) => option.key === key)
    )
  }

  /**
   * When a checkbox is selected, update the selection array to maintain the
   * order set by the full options list. This is required for displaying the
   * paginated previews in the correct order.
   */
  const onChange = useCallback(
    (option) => {
      const name = option.key
      const checked = selection.indexOf(name) === -1

      onSelectionChange(
        options.reduce((filtered: any, o: any) => {
          if (checked) {
            if (selection.includes(o.key) || o.key === name) {
              filtered.push(o.key)
            }
          } else {
            if (selection.includes(o.key) && o.key !== name) {
              filtered.push(o.key)
            }
          }

          return filtered
        }, [])
      )
    },
    [options, selection, onSelectionChange]
  )

  return (
    <>
      {loading ? (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <List sx={{ width: "100%" }}>
          {options.map((option) => {
            const labelId = `checkbox-list-label-${option.label}`

            return (
              <ListItem
                key={option.key}
                secondaryAction={
                  <Checkbox
                    edge="start"
                    checked={isSelected(option)}
                    onChange={() => onChange(option)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                }
                disablePadding
              >
                <ListItemButton
                  disabled={!isSelected(option)}
                  role={undefined}
                  onClick={() => {
                    // Determine the index of the preview option (including only selected options)
                    const previewIndex = options
                      .filter((o) => isSelected(o))
                      .findIndex((o) => o.key === option.key)

                    onPreviewIndexChange(previewIndex)
                  }}
                  selected={
                    previewIndex ===
                    options
                      .filter((o) => isSelected(o))
                      .findIndex((o) => o.key === option.key)
                  }
                  dense
                >
                  <ListItemText>
                    <ListItemText
                      primary={option.label}
                      secondary={option.duration ?? null}
                    />
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      )}
    </>
  )
}

export default CheckboxSelect
