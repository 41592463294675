import { useEffect, useState } from "react"
import { findTemplateTypesInSection } from "../../../utilities/smartTemplates"

export const isContainedBySection = ($element) => {
  while ($element) {
    if ($element.tagName === "SECTION") {
      return true
    }
    $element = $element.parentElement
  }
  return false
}

/** Get the set of operations that are available for a particular section
 * hasTemplates is a list of the smart templates types that a section has
 * e.g. ["demonstration", "exercise"]
 */
const useHeaderOperationsAccess = (activeSection) => {
  const [canPromote, setCanPromote] = useState(false)
  const [canDemote, setCanDemote] = useState(false)
  const [canInsert, setCanInsert] = useState(false)
  const [canInsertSubHeading, setCanInsertSubHeading] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const [canRename, setCanRename] = useState(false)
  const [canHaveTemplate, setCanHaveTemplate] = useState(false)
  const [hasTemplates, setHasTemplates] = useState([])

  // Update allowed actions
  useEffect(() => {
    setCanPromote(activeSection != null && !isContainedBySection(activeSection))
    setCanDemote(activeSection != null && !isContainedBySection(activeSection))
    setCanInsert(activeSection != null)
    setCanDelete(activeSection != null)
    setCanRename(activeSection != null)
    setCanInsertSubHeading(activeSection != null)

    // True if not a smart template section
    setCanHaveTemplate(activeSection != null && !activeSection.dataset.template)

    // Populate this with any templates currently in it
    setHasTemplates(findTemplateTypesInSection(activeSection))
  }, [activeSection])

  return {
    canPromote,
    canDemote,
    canInsert,
    canInsertSubHeading,
    canDelete,
    canRename,
    canHaveTemplate,
    hasTemplates,
  }
}

export default useHeaderOperationsAccess
