import { FormControl, FormControlLabel, Stack, Switch } from "@mui/material"
import { usePublicShare } from "../../contexts/sharing"
import CopyToClipboardField from "../atoms/CopyToClipboardField"

/**
 * A control for configuring public access to a course.
 *
 * The users can toggle public access to a document on and off. When the public
 * access is enabled, a field with a URL to te public access is enabled so the
 * user can copy and share it.
 */
const PublicAccessControl = (props) => {
  const { isPublic, toggleIsPublic, publicLink } = usePublicShare()

  return (
    <Stack {...props}>
      <FormControl margin="normal" fullWidth>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={isPublic}
              onChange={toggleIsPublic}
            />
          }
          label="Public Access"
        />
      </FormControl>
      <CopyToClipboardField
        value={publicLink}
        disabled={!isPublic}
        fullWidth
        helperText="Anyone on the internet with this link will be able to view and comment on the
          course."
      />
    </Stack>
  )
}

export default PublicAccessControl
