import { Theme } from "@mui/material"
import { merge } from "lodash/fp"

/**
 * Extends the base theme with authentication specific theme-overrides.
 * @param theme A material theme.
 */
export function extendTheme(theme: Theme): Theme {
  return merge(theme, {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            borderRadius: "28px",
            paddingInline: 16,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
          },
        },
      },
    },
  })
}
