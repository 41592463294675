import { useDraggable, useDroppable } from "@dnd-kit/core"
import { ListItemButton, Theme } from "@mui/material"
import { Item } from "../../../../homeNavigation/types"

type ItemWrapperProps = {
  item: Item
  children: any
  onClick: (event: any) => void
  [key: string]: any // Additional props
}

/**
 * Draggable wrapper for items of type course
 * @param props - Component props
 */
export const CourseWrapper = (props: ItemWrapperProps) => {
  const { item, children, ...otherProps } = props

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: item.id,
      data: item,
    })

  // Transform style to display dragging
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined

  return (
    <ListItemButton
      disableRipple
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      {...otherProps}
      style={style}
      sx={(theme: any) => ({
        zIndex: isDragging ? theme.zIndex.modal : 0,
        opacity: isDragging ? 0.5 : 1,
        transition: "opacity 0.5s ease-in-out",
        animation: isDragging ? "pulse 1s infinite alternate" : "none",
        "@keyframes pulse": {
          "0%": { opacity: 0.5 },
          "100%": { opacity: 1 },
        },
      })}
    >
      {children}
    </ListItemButton>
  )
}

/**
 * Droppable wrapper for items of type folder
 * @param props - Component props
 */
export const FolderWrapper = (props: ItemWrapperProps) => {
  const { item, children, ...otherProps } = props
  const { isOver, setNodeRef } = useDroppable({
    id: item.id,
    data: item,
  })

  return (
    <ListItemButton
      disableRipple
      ref={setNodeRef}
      sx={(theme: Theme) => ({
        cursor: "pointer",
        backgroundColor: isOver
          ? theme.scheme.primaryContainer
          : theme.scheme.onPrimary,
      })}
      {...otherProps}
    >
      {children}
    </ListItemButton>
  )
}
