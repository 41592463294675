import ConfirmDialog from "../../../components/widgets/ConfirmDialog"
import { Box, Typography } from "@mui/material"
import ConfirmDeleteImage from "../../../assets/images/confirm-delete.svg"
import React from "react"

const DeleteLearningPathDialog = (props) => {
  const { path, open, onConfirm, onCancel } = props

  return (
    <ConfirmDialog
      open={open}
      title={"Delete This Learning Path?"}
      message={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            component="img"
            sx={{ width: "300px", alignSelf: "center", mb: 4 }}
            src={ConfirmDeleteImage}
            alt="You can't undo this action"
          />
          <Typography variant="body1" align="justify">
            Delete the selected course "{path.title}"? You can't undo this
            action.
          </Typography>
        </Box>
      }
      cancelText={"Cancel"}
      confirmText={"Delete"}
      onClose={onCancel}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  )
}

export default DeleteLearningPathDialog
