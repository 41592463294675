import config from "../config"

/** Configuration for a small Froala editor with minimal features. */
export const minimalFroalaConfig = {
  key: config.froalaLicense,
  height: 250,
  toolbarBottom: false,
  attribution: false,
  htmlUntouched: false,
  pluginsEnabled: [
    "colors",
    "lineHeight",
    "link",
    "lists",
    "paragraphFormat",
    "paragraphStyle",
  ],
  // Prevent img tags from being pasted
  pasteDeniedTags: ["img"],
  // Exclude &quot; &#39; &lang; &rang; to match LearnExperts standard
  entities:
    "&iexcl;&cent;&pound;&curren;&yen;&brvbar;&sect;&uml;&copy;&ordf;&laquo;&not;&shy;&reg;&macr;&deg;&plusmn;&sup2;&sup3;&acute;&micro;&para;&middot;&cedil;&sup1;&ordm;&raquo;&frac14;&frac12;&frac34;&iquest;&Agrave;&Aacute;&Acirc;&Atilde;&Auml;&Aring;&AElig;&Ccedil;&Egrave;&Eacute;&Ecirc;&Euml;&Igrave;&Iacute;&Icirc;&Iuml;&ETH;&Ntilde;&Ograve;&Oacute;&Ocirc;&Otilde;&Ouml;&times;&Oslash;&Ugrave;&Uacute;&Ucirc;&Uuml;&Yacute;&THORN;&szlig;&agrave;&aacute;&acirc;&atilde;&auml;&aring;&aelig;&ccedil;&egrave;&eacute;&ecirc;&euml;&igrave;&iacute;&icirc;&iuml;&eth;&ntilde;&ograve;&oacute;&ocirc;&otilde;&ouml;&divide;&oslash;&ugrave;&uacute;&ucirc;&uuml;&yacute;&thorn;&yuml;&OElig;&oelig;&Scaron;&scaron;&Yuml;&fnof;&circ;&tilde;&Alpha;&Beta;&Gamma;&Delta;&Epsilon;&Zeta;&Eta;&Theta;&Iota;&Kappa;&Lambda;&Mu;&Nu;&Xi;&Omicron;&Pi;&Rho;&Sigma;&Tau;&Upsilon;&Phi;&Chi;&Psi;&Omega;&alpha;&beta;&gamma;&delta;&epsilon;&zeta;&eta;&theta;&iota;&kappa;&lambda;&mu;&nu;&xi;&omicron;&pi;&rho;&sigmaf;&sigma;&tau;&upsilon;&phi;&chi;&psi;&omega;&thetasym;&upsih;&piv;&ensp;&emsp;&thinsp;&zwnj;&zwj;&lrm;&rlm;&ndash;&mdash;&lsquo;&rsquo;&sbquo;&ldquo;&rdquo;&bdquo;&dagger;&Dagger;&bull;&hellip;&permil;&prime;&Prime;&lsaquo;&rsaquo;&oline;&frasl;&euro;&image;&weierp;&real;&trade;&alefsym;&larr;&uarr;&rarr;&darr;&harr;&crarr;&lArr;&uArr;&rArr;&dArr;&hArr;&forall;&part;&exist;&empty;&nabla;&isin;&notin;&ni;&prod;&sum;&minus;&lowast;&radic;&prop;&infin;&ang;&and;&or;&cap;&cup;&int;&there4;&sim;&cong;&asymp;&ne;&equiv;&le;&ge;&sub;&sup;&nsub;&sube;&supe;&oplus;&otimes;&perp;&sdot;&lceil;&rceil;&lfloor;&rfloor;&loz;&spades;&clubs;&hearts;&diams;",
  shortcutsEnabled: [
    "show",
    "bold",
    "italic",
    "underline",
    "indent",
    "outdent",
    "undo",
    "redo",
    "createLink",
  ],
  toolbarButtons: {
    moreText: {
      buttons: [
        "undo",
        "redo",
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "textColor",
        "backgroundColor",
        "clearFormatting",
      ],
      buttonsVisible: 13,
    },
    moreParagraph: {
      buttons: [
        "formatOL",
        "formatUL",
        "alignLeft",
        "alignCenter",
        "alignRight",
        "alignJustify",
        "lineHeight",
        "outdent",
        "indent",
        "quote",
      ],
      buttonsVisible: 13,
    },
    moreRich: {
      buttons: ["insertLink"],
      buttonsVisible: 1,
    },
  },
}
