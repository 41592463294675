/**
 * @template T
 * @typedef {() => Promise<T>} Action
 */

/**
 * Create a queue for promise-based actions.
 *
 * Each action supplied to the queue will only be invoked, onces all actions
 * before it have been resolved.
 */
const createPromiseQueue = () => {
  const tasks = []
  let isRunning = false

  /** Invoke the next queued action. */
  const startNext = () => {
    if (isRunning) {
      return false
    }

    let task = tasks.shift()
    if (!task) {
      return false
    }

    task()
    return true
  }

  return {
    /**
     * Queue an action to be resolved.
     *
     * @template T
     * @param {Action<T>} action The action.
     * @returns {Promise<T>} The result from the action.
     */
    add: (action) => {
      return new Promise((resolve, reject) => {
        tasks.push(async () => {
          isRunning = true
          try {
            const result = await action()
            resolve(result)
          } catch (error) {
            reject(error)
          }
          isRunning = false
          startNext()
        })
        startNext()
      })
    },
  }
}

export default createPromiseQueue
