import React, { useTheme } from "@emotion/react"
import { Close } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  useMediaQuery,
} from "@mui/material"
import CopyToClipboardField from "../atoms/CopyToClipboardField"
import PropTypes from "prop-types"
import { forwardRef } from "react"

/**
 * A modal presenting the learner URL for a course
 *
 */

const LearnerLinkDialog = (props) => {
  const fullScreen = useFullscreenDialog()
  const { courseTitle, url, open, onClose } = props

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
      scroll={fullScreen ? "paper" : "body"}
      keepMounted
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        ".MuiDialog-paperFullScreen": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>
        Public Learner Link
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>{courseTitle}</DialogContentText>
        <DialogContentText sx={{ mb: 1 }}>
          Send this link to learners. They can use it to launch your course.
        </DialogContentText>
        <CopyToClipboardField
          value={url}
          fullWidth
          helperText="Anyone on the internet with this link will be able to launch the
          course."
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

LearnerLinkDialog.propTypes = {
  courseTitle: PropTypes.string,
  url: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

LearnerLinkDialog.defaultProps = {
  open: false,
}

export default LearnerLinkDialog

const useFullscreenDialog = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
