import { useDropzone } from "react-dropzone"
import { UploadFileCollection } from "../utilities/fileUpload"
import useUploadMimeTypes from "../hooks/useUploadMimeTypes"

/**
 * Use dropzone state for handling course document uploads.
 *
 * @param props - onAccepted callback
 * @returns {import("react-dropzone").DropzoneState}
 */
const useCourseDropzone = (props) => {
  const { onAccepted } = props
  const { allowedMimeTypes } = useUploadMimeTypes()

  /**
   * On drop in file drop area
   * @param acceptedFiles - list of ok files
   * @param rejectedFiles - list of rejected files
   */
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    rejectedFiles = rejectedFiles.map((reject) => reject.file)

    const fileCollection = new UploadFileCollection(
      acceptedFiles,
      rejectedFiles
    )

    onAccepted(fileCollection.toJson())
  }

  const dropzone = useDropzone({
    accept: allowedMimeTypes(),
    multiple: true,
    noClick: true, // Disable clicking anywhere in the dropzone to open file selector.
    noKeyboard: true, // Disable keyboard events on the dropzone to open the file selector.
    onDrop,
  })

  // Will fallback to legacy behaviour when the UX refresh is not enabled.
  const open = dropzone.open

  return { ...dropzone, open }
}

export default useCourseDropzone
