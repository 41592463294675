import { AwsRum, AwsRumConfig } from "aws-rum-web"
let awsRum: AwsRum

try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    identityPoolId: "ca-central-1:fab38ee4-2a65-44a4-9a7a-7076c2bc73d6",
    endpoint: "https://dataplane.rum.ca-central-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  }

  const APPLICATION_ID: string = "d25b4613-5554-4ebb-8e2f-733f322c3937"
  const APPLICATION_VERSION: string = "1.0.0"
  const APPLICATION_REGION: string = "ca-central-1"

  awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  )
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const addUserMetadata = (user_id: string) => {
  if (typeof awsRum == null) {
    return false
  }
  awsRum.addSessionAttributes({
    lex_user_id: user_id,
  })
  return true
}

export { addUserMetadata }
