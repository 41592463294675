import { Stack, Typography } from "@mui/material"
import { ReactNode } from "react"
import WelcomeImage from "./WelcomeImage"

type WelcomeCardLayoutProps = {
  title?: string | null
  children: ReactNode
  actions?: ReactNode | null
}

/**
 * Layout for arranging dynamic message alongside welcome image
 * @param props - Component props
 */
const WelcomeCardLayout = (props: WelcomeCardLayoutProps) => {
  const { children, title, actions } = props

  return (
    <Stack direction="row" height="100%" gap={2}>
      <WelcomeImage />
      <Stack height="100%" padding="16px" flex="1 1 50%">
        {title && (
          <Typography gutterBottom variant="bodyLarge" fontWeight={500}>
            {title}
          </Typography>
        )}
        {children}
        {actions && (
          <Stack
            marginTop="auto"
            gap={2}
            direction="row"
            justifyContent="flex-end"
          >
            {actions}
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default WelcomeCardLayout
