import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import CoursesViewToggle from "../../features/welcome/CoursesViewToggle"
import CoursesSearchBar from "../../features/welcome/CoursesSearchBar"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    display: "flex",
  },
}))

/**
 * Legacy component for toolbar and courses view toggle together
 * @param props - Component props
 */
const CoursesToolBar = (props) => {
  const classes = useStyles()

  const { onSearchInputChange, isGridView, toggleView } = props

  return (
    <div className={classes.root}>
      <CoursesSearchBar onSearchInputChange={onSearchInputChange} />
      <CoursesViewToggle isGridView={isGridView} toggleView={toggleView} />
    </div>
  )
}

export default CoursesToolBar
