import axiosInstance from "./axios"

/**
 * @typedef AddCommentArg
 *
 * @property {string} courseId
 * @property {string} text
 * @property {object} [location]
 * @property {{ email: string}} [as]
 */

/**
 * Add a comment to a course.
 *
 * @param {AddCommentArg} arg
 * @returns {Promise<Comment>}
 */
const addComment = (arg) => {
  const { text, location, courseId, as } = arg

  return axiosInstance
    .post(
      `/courses/${courseId}/comments`,
      {
        text,
        location,
      },
      { params: { email: as?.email } }
    )
    .then((response) => response.data)
}

export default addComment
