import { useCallback, useEffect, useState } from "react"
import { unionBy } from "lodash"
import { useAPI } from "../../../contexts/api"
import useUploadCompletions from "../../../hooks/useUploadCompletions"
import hardDeleteItems from "../api/hardDeleteItems"
import restoreItems from "../api/restoreItems"

/**
 * useTrash hooks
 */
const useTrash = () => {
  const api = useAPI()
  const fetchTrashedCourses = useCallback(
    () => api.getTrashedCourses().then((res) => res.data),
    [api]
  )

  const [result, setResult] = useState({
    documents: [],
    error: null,
  })
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Delete a single course
   * @param ids - List of course IDs to delete
   */
  const deleteCourses = async (ids) => {
    setIsLoading(true)
    return hardDeleteItems(ids)
      .then((res) => {
        setIsLoading(false)
        return updateCourses()
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error)
      })
  }

  /**
   * Restore a single course
   * @param ids -
   */
  const restoreCourses = async (ids) => {
    setIsLoading(true)
    return restoreItems(ids)
      .then((res) => {
        setIsLoading(false)
        return updateCourses()
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error)
      })
  }

  /**
   * Set the result data
   * @param documents documents for result
   */
  const setData = (documents) => {
    return setResult((result) => ({ ...result, documents, error: null }))
  }

  /**
   * Set error result
   * @param error the error result
   */
  const setError = (error) => setResult((result) => ({ ...result, error }))

  const updateCourses = useCallback(() => {
    setIsLoading(true)
    return fetchTrashedCourses()
      .then((data) => {
        console.log(data)
        setIsLoading(false)
        return setData(data)
      })
      .catch((error) => {
        // If there's a network error, pass the retrieval function to the global retry function in the context
        setIsLoading(false)
        return setError(error)
      })
  }, [fetchTrashedCourses])

  const mergeDocuments = useCallback(
    (documents) => {
      return setResult((result) => ({
        ...result,
        documents: unionBy(
          documents,
          result?.documents || [],
          (document) => document.id
        ),
      }))
    },
    [setResult]
  )

  useEffect(() => {
    updateCourses()
  }, [updateCourses])

  useUploadCompletions((uploads) => {
    mergeDocuments(
      uploads.map((upload) => ({
        id: upload.data.course_id,
        title: upload.data.title,
        isLoading: false,
        status: "Completed",
        last_update: upload.data.last_update,
      }))
    )
  })

  return {
    ...result,
    documents: [...result.documents],
    deleteCourses,
    restoreCourses,
    isLoading,
    updateCourses,
  }
}

export default useTrash
