import detectionsConfig from "../../../config/detections"

/**
 * Get the description for a given detection rule.
 *
 * @param {*} detection
 * @returns {?string}
 */
const getDetectionDescription = (detection) =>
  detectionsConfig.detections?.[detection.rule]?.description ?? null

export default getDetectionDescription
