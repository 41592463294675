import { Box, Typography } from "@mui/material"
import { Item } from "../../../../homeNavigation/types"
import ItemList from "../../../itemList/ItemList"
import ItemSkeleton from "../../coursesPage/components/DirectoryItemSkeleton"
import TrashedItem from "./TrashedItem"

type TrashedItemListProps = {
  isLoading: boolean
  items: Item[]
  checkedItems: string[]
  setCheckedItems: React.Dispatch<React.SetStateAction<string[]>>
  displaySearchResults: boolean
  showDeleteConfirmation: (item: Item) => void
  restoreCourse: (id: string) => void
  [key: string]: any
}

/**
 * Displays list of all items trashed
 * @param props - Component props
 */
const TrashedItemList = (props: TrashedItemListProps) => {
  const {
    isLoading: loading,
    displaySearchResults,
    query,
    items,
    ...otherProps
  } = props

  // When triggering loading skeletons, have it be the same number as however many visible courses there were before for consistency
  const numSkeletons = items.length > 0 ? items.length : 6
  const emptyTrashList = !loading && items.length === 0

  return (
    <ItemList>
      {!loading
        ? items.map((item: Item) => <TrashedItem item={item} {...otherProps} />)
        : [...Array(numSkeletons)].map((i, index) => (
            <ItemSkeleton key={index} />
          ))}
      {emptyTrashList && (
        <Box
          marginTop={16}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body2">
            {displaySearchResults
              ? "No items match your search."
              : "Items moved to the trash will appear here."}
          </Typography>
        </Box>
      )}
    </ItemList>
  )
}

export default TrashedItemList
