import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import PageLayout from "../layouts/PageLayout"
import PublicAccessControl from "../widgets/PublicAccessControl"
import PropTypes from "prop-types"
import { ShareSettingsProvider } from "../../contexts/sharing"
import makeShareSettingsHref from "../../utilities/makeShareSettingsURL"
import { find } from "lodash"

/**
 * A screen presenting the share settings for a course.
 *
 * When open, the user can select a course to configure. When selected, the
 * specific share configuration for the course become accessible. At the moment,
 * a course can be configured to be public accessible by URL.
 */
const ShareCourseScreen = (props) => {
  const {
    breadcrumbs = [],
    courseOptions,
    course,
    ShareSettingsProvider,
  } = props

  return (
    <ShareSettingsProvider course={find(courseOptions, { id: course })}>
      <PageLayout
        fixed
        maxWidth="md"
        breadcrumbs={[...breadcrumbs, { label: "Share" }]}
      >
        <Typography variant="h3" component="h1">
          Share
        </Typography>
        <Typography variant="body" gutterBottom>
          Decide who can view and comment on your courses.
        </Typography>
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel>Course</InputLabel>
          <Select label="Course" value={course ?? ""}>
            {courseOptions.map((course) => (
              <MenuItem
                key={course.id}
                value={course.id}
                href={makeShareSettingsHref(course)}
              >
                {course.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {course && <PublicAccessControl />}
      </PageLayout>
    </ShareSettingsProvider>
  )
}

ShareCourseScreen.propTypes = {
  /**
   * Breadcrumbs for navigating back to previous pages.
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ),
  /**
   * A set of courses the user can select to configure.
   */
  courseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      course_id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * The selected course.
   */
  course: PropTypes.string,
  /**
   * Provides a context for controlling the share settings for course.
   *
   * The default implementation is provided, but may be overridden for testing
   * purposes.
   */
  ShareSettingsProvider: PropTypes.func,
}

ShareCourseScreen.defaultProps = {
  breadcrumbs: [],
  ShareSettingsProvider: ShareSettingsProvider,
}

export default ShareCourseScreen
