import {
  Button,
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { IBranding, IEditorFont } from "../../../branding/hooks/useBranding"
import { Delete } from "@mui/icons-material"
import { useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import loadFontFace from "../../../branding/utils/loadFontFace"

const CUSTOM_FONT_LIMIT = 3 // Limit custom user fonts

/**
 * Component for allowing users to upload their own custom fonts to be used in
 * the editor
 * @param props - Component props
 */
const EditorCustomFontsControl = (props: any) => {
  const { setBranding, branding } = props

  const fonts = branding.editorFonts

  useEffect(() => {
    if (fonts) {
      fonts.map(async (font: IEditorFont) => {
        await loadFontFace(font)
      })
    }
  }, [fonts])

  /**
   * Handle file upload of font. Create a URL to display it to the user and
   * store the file object itself in the branding.changes object in the state.
   *
   * @param event - File input event
   */
  const onAdd = (event: any) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const url = URL.createObjectURL(file)
      // For the text content of the font preview, grab whatever name has been given to the font file
      const name = file.name.split(".")[0]
      const id = uuidv4()
      const font = new FontFace(name, `url(${url})`)
      document.fonts.add(font)

      setBranding((prev: IBranding) => ({
        ...prev,
        editorFonts: [
          ...prev.editorFonts,
          {
            id,
            url,
            name,
          },
        ],
        changes: {
          ...prev.changes,
          addedEditorFonts: [...(prev.changes.addedEditorFonts ?? []), id],
          [id]: file,
        },
      }))
    }
  }

  /**
   * Handle the removal of a custom editor font.Slightly different logic based on whether
   * the font was already committed to the database
   * @param font - Font to remove from list
   */
  const onRemove = (font: IEditorFont) => {
    // Check if font was recently added by looking for it in newEditorFonts
    const wasAddedBeforeSave = branding.changes.addedEditorFonts?.includes(
      font.id
    )

    setBranding((prev: IBranding) => ({
      ...prev,
      // Remove font from UI
      editorFonts: prev.editorFonts.filter((f) => f.url !== font.url),

      changes: {
        ...prev.changes,

        // If the font was added and no save has occurred, simply remove it from the list
        ...(wasAddedBeforeSave
          ? {
              addedEditorFonts: prev.changes.addedEditorFonts.filter(
                (addedFont: string) => addedFont !== font.id
              ),
            }
          : // Otherwise, it was a previously saved font being removed, so append it to the removedEditorFonts list
            {
              removedEditorFonts: [
                ...(prev.changes.removedEditorFonts ?? []),
                font.id,
              ],
            }),
      },
    }))
  }

  return (
    <Stack>
      <Typography gutterBottom variant="titleMedium">
        Custom Editor Fonts
      </Typography>

      <List>
        {fonts.map((font: IEditorFont) => (
          <ListItem
            secondaryAction={
              <Tooltip title="Remove">
                <IconButton
                  edge="end"
                  onClick={(e: any) => {
                    e.preventDefault()
                    onRemove(font)
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            }
          >
            <Typography sx={{ font: `1rem "${font.name}"` }}>
              {font.name}
            </Typography>
          </ListItem>
        ))}
        {fonts.length < CUSTOM_FONT_LIMIT && (
          <ListItem>
            <Stack
              width="100%"
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button variant="text" component="label">
                <input
                  hidden
                  type="file"
                  accept=".ttf,.otf,.woff2,.woff"
                  onChange={onAdd}
                />
                Add Font
              </Button>
            </Stack>
          </ListItem>
        )}
      </List>
    </Stack>
  )
}

export default EditorCustomFontsControl
