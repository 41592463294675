import { useCallback, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch } from "../../../store"
import { setGuestEmail } from "../../../store/remarks"

const STATE_KEY = "prompt-guest"

/**
 * Access the guest email state dispatch.
 */
export const useGuestEmailDispatch = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const setPrompt = useCallback(
    (value, options = {}) => {
      const { pendingAction } = options
      const { replace, location } = history
      replace({
        ...location,

        state: {
          ...location?.state,
          [STATE_KEY]: value,
          pendingAction,
        },
      })
    },
    [history]
  )

  return useMemo(
    () => ({
      /**
       * Prompt a guest user for their email.
       */
      promptForEmail: (options) => setPrompt(true, options),
      /**
       * Dismiss the prompt for a user's email
       */
      dismissPromptForEmail: () => setPrompt(false),
      /**
       * Sets the guest email if a user is unauthenticated
       * @param {string} email
       */
      setGuestEmail: (email) => {
        dispatch(setGuestEmail({ email }))
      },
      /**
       * After a valid guest email has been submitted, optionally execute an action
       */
      dispatchPendingAction: () => {
        const action = history.location?.state?.pendingAction
        if (!action) {
          return
        }
        dispatch(action)
      },
    }),
    [history, setPrompt, dispatch]
  )
}

/**
 * Access the guest email state.
 */
export const useGuestEmail = () => {
  const history = useHistory()

  return {
    /**
     * Flag for if the email prompt should be display.
     * @type {boolean}
     */
    open: history.location?.state?.[STATE_KEY] ?? false,
    ...useGuestEmailDispatch(),
  }
}

export default useGuestEmail
