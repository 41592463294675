import { useEffect, useMemo } from "react"
import useRemarks from "./useRemarks"
import { findRangeForLocation } from "../utilities/remarkUtils"
import { useCommentsDispatch } from "./useCommentsDispatch"
import { debounce } from "lodash/fp"
import { useFlag } from "../utilities/feature-management"

interface LocationRange {
  range: Range | null
  matchElement: Node | null
}

// Debounce cleanup to allow for editing between passes
const CLEANUP_DEBOUNCE_TIME = 5000

/**
 * Hook for listening to HTML changes and removing comments if their anchor has been deleted
 * @param html - Current HTML of the editor
 */
const useCommentListener = (html: string) => {
  const disableCleanup =
    useFlag("kill-switch-disable-comments-clean-up") ?? true

  const { ids: remarks } = useRemarks()
  const { deleteComment } = useCommentsDispatch()

  const cleanUpComments = useMemo(
    () =>
      debounce(CLEANUP_DEBOUNCE_TIME, () => {
        remarks.forEach((remark) => {
          if (remark.remarkType === "comment" && remark.location) {
            /**
             * If unable to locate the anchor element for the given location,
             * the comment is considered orphaned and can be deleted
             */
            const result = findRangeForLocation(
              remark.location,
              document.getElementsByClassName("fr-element")[0] as HTMLElement
            ) as LocationRange

            if (result == null && remark.id) {
              deleteComment(remark.id)
            }
          }
        })
      }),
    [remarks, deleteComment]
  )

  useEffect(() => {
    if (disableCleanup) {
      return
    }

    cleanUpComments()
  }, [cleanUpComments, html, disableCleanup])
}

export default useCommentListener
