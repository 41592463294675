import HelpOutline from "@mui/icons-material/HelpOutline"
import Logout from "@mui/icons-material/Logout"
import BuildCircle from "@mui/icons-material/BuildCircle"
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material"
import { useState } from "react"
import { useAuth } from "../../contexts/authorization"
import { Grading, PrivacyTip } from "@mui/icons-material"
import terms from "../../assets/docs/Terms_and_Conditions_2023.pdf"

const isProduction = process.env.NODE_ENV === "production"

/**
 * AccountMenu presents account-related navigation.
 * @param props - Sizing and positioning configuration.
 */
const AccountMenu = (props) => {
  const { size = 24, ...otherProps } = props

  const { user, signOut } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return user ? (
    <>
      <Tooltip title="Account">
        <IconButton
          onClick={handleClick}
          color="primary"
          {...otherProps}
          sx={{
            ".MuiAvatar-root": {
              backgroundColor: "tertiary.main",
              color: "#FFFFFF",
            },
          }}
        >
          <Avatar
            sx={{
              width: size,
              height: size,
              fontSize: size * 0.5,
              textTransform: "uppercase",
            }}
          >
            {user?.initials}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ListItem
          sx={{
            color: "text.secondary",
            cursor: "default",
          }}
        >
          {user.email}
        </ListItem>
        <MenuItem href="/tools">
          <ListItemIcon>
            <BuildCircle fontSize="small" />
          </ListItemIcon>
          Tools
        </MenuItem>
        <MenuItem href="help" target="_blank">
          <ListItemIcon>
            <HelpOutline fontSize="small" />
          </ListItemIcon>
          Help Hub
        </MenuItem>
        <MenuItem href={terms} target="_blank">
          <ListItemIcon>
            <Grading fontSize="small" />
          </ListItemIcon>
          Terms
        </MenuItem>
        {isProduction && (
          <MenuItem
            href={{ pathname: "https://learnexperts.ai/privacy-policy" }}
            target="_blank"
          >
            <ListItemIcon>
              <PrivacyTip fontSize="small" />
            </ListItemIcon>
            Privacy Policy
          </MenuItem>
        )}
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  ) : null
}

export default AccountMenu
