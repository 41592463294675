import { DialogContentText, TextField, Box } from "@mui/material"
import React, { useState } from "react"
import ConfirmDialog from "../../../components/widgets/ConfirmDialog"

/**
 *
 * @param props
 */
const RenameLearningPathDialog = (props) => {
  const { path, open, onConfirm, onCancel } = props
  const [newPathName, setNewPathName] = useState(path.title)

  return (
    <ConfirmDialog
      open={open}
      title="Rename Learning Path"
      message={
        <Box>
          <DialogContentText>
            Enter a new name for the Learning Path
          </DialogContentText>
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            value={newPathName}
            onInput={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setNewPathName(e.target.value)
            }}
          />
        </Box>
      }
      cancelText={"Cancel"}
      confirmText={"Rename"}
      onCancel={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onCancel()
        setNewPathName("")
      }}
      onConfirm={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onConfirm(newPathName)
        setNewPathName("")
      }}
      onClose={onCancel}
    />
  )
}

export default RenameLearningPathDialog
