import {
  Card,
  CardContent,
  CircularProgress,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Fade,
  Checkbox,
} from "@mui/material"
import {
  getTitle,
  isoStringToRelativeTime,
} from "../../../../utilities/transformers"
import { useState } from "react"
import styled from "@emotion/styled"
import {
  Cancel,
  Description,
  DragHandle,
  Edit,
  FolderOpen,
} from "@mui/icons-material"
import formatDuration from "../../../outline/utils/formatDuration"
import useUploadByID from "../../../../hooks/useUploadByID"
import CourseWrapper from "../CourseWrapper"
import FolderWrapper from "../FolderWrapper"

/**
 * Parse the current upload stage into a string
 * @param stage - Upload stage of document
 */
const formatStageCaption = (stage) => {
  if (stage.current == null || stage.total == null || stage.total === 1) {
    return stage.caption
  } else {
    return `Stage ${stage.current} of ${stage.total}: ${stage.caption}`
  }
}

/**
 * Transform the last updated date into a friendly string
 * @param date - ISO string representation of date
 */
const formatLastUpdated = (date) => {
  if (date == null) {
    return ""
  }
  return isoStringToRelativeTime(date)
}

export const StyledCard = styled(Card)(({ theme }) => {
  return {
    cursor: "pointer",
    width: theme.spacing(28),
    display: "flex",
    height: theme.spacing(36),
    "&:hover": {
      boxShadow: theme.shadows[3],
    },
    flexDirection: "column",
    position: "relative",
  }
})

const iconStyles = {
  textAlign: "center",
  fontSize: "4.5rem",
}

const DocumentIcon = styled(Description)(({ theme }) => ({
  ...iconStyles,
  color: theme.palette.primary.dark,
}))
const FolderIcon = styled(FolderOpen)(({ theme }) => ({
  ...iconStyles,
  color: theme.palette.lexGrey,
}))

/**
 * Button component for cancelling an upload-in-progress course tile
 * @param props - Component props
 * @param props.onClick - Callback when button is clicked
 */
const CancelUploadButton = ({ onClick }) => {
  return (
    <Tooltip placement="top" arrow title="Cancel upload">
      <IconButton onClick={onClick} size="large">
        <Cancel />
      </IconButton>
    </Tooltip>
  )
}

/**
 * Tile component to display clickable course
 *
 * @param props - Component props
 */
const CourseTile = (props) => {
  const {
    item,
    menu,
    handleCourseChecked,
    checkedItems = [],
    onCancelUpload,
    handleItemSelected,
    displaySearchResults,
    folderTitleMap = {},
  } = props

  const [isHovered, setIsHovered] = useState(false)
  const {
    isLoading,
    duration_minutes,
    is_template,
    image_url,
    custom_image_url,
    item_type,
    parent_directory,
  } = item
  const { stage } = useUploadByID(item.id)

  const isFolder = item_type === "folder"
  const checked = checkedItems ? checkedItems.includes(item.id) : false
  const isInSelectionMode = checkedItems.length > 0

  /**
   * URL to display defaults to user-uploaded, if it exists.
   * If not, fall back on URL of first image in document.
   */
  const url = custom_image_url ?? image_url

  const showCheckbox = checkedItems && (isHovered || isInSelectionMode)

  const Wrapper = isFolder ? FolderWrapper : CourseWrapper

  const parentFolderTitle = folderTitleMap[parent_directory]

  return (
    <Wrapper
      item={item}
      onClick={(e) => handleItemSelected(e, item)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      variant="tile"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
          position: "absolute",
          width: "100%",
          padding: 0.5,
          zIndex: 3,
        }}
        onClick={(e) => e.preventDefault()}
      >
        <Fade in={showCheckbox}>
          <Checkbox
            color="default"
            onClick={(e) => e.stopPropagation()}
            checked={checked}
            onChange={(e) => handleCourseChecked(e, item.id)}
            inputProps={{ "aria-label": "Select Course" }}
          />
        </Fade>
        <Fade in={isHovered}>
          <IconButton
            disableRipple
            fontSize="small"
            sx={{
              cursor: "grab",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <DragHandle />
          </IconButton>
        </Fade>

        <Fade in={!isFolder && isHovered}>
          <Box>
            {isLoading ? <CancelUploadButton onClick={onCancelUpload} /> : menu}
          </Box>
        </Fade>
      </Box>
      <Box flex="0 0 50%" position="relative">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            /**
             * Style background to match product theme
             * @param theme - MUI theme
             */
            backgroundColor: (theme) => theme.palette.primary.light,
          }}
        >
          {isFolder ? (
            <FolderIcon />
          ) : !isLoading ? (
            <DocumentIcon />
          ) : (
            <CircularProgress />
          )}
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${url})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        />
      </Box>
      <CardContent sx={{ height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{ height: "100%" }}
        >
          <Box>
            <Typography fontWeight={500} variant="body2" color="primary">
              {isFolder ? "Folder" : is_template ? "Template" : "Course"}
            </Typography>
            {displaySearchResults && parentFolderTitle && (
              <Typography variant="body2">{parentFolderTitle}</Typography>
            )}
            <Typography
              data-cy-id={item.id}
              fontWeight="500"
              variant="body1"
              overflow="hidden"
              sx={{
                wordBreak: "break-word",
              }}
              gutterBottom
            >
              {getTitle(item.title)}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
          >
            {!isFolder &&
              (!isLoading ? (
                <Box display="flex" alignItems="center">
                  <Edit fontSize="small" />
                  <Typography marginLeft={0.5} variant="body2">
                    {formatLastUpdated(item.last_update)}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body2">
                  {stage && formatStageCaption(stage)}
                </Typography>
              ))}
            {duration_minutes && (
              <Typography variant="body2">
                {formatDuration(duration_minutes)}
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Wrapper>
  )
}

export default CourseTile
