import { useRef } from "react"
import graphicOne from "../../assets/images/error_images_1.svg"
import graphicTwo from "../../assets/images/error_images_2.svg"
import graphicThree from "../../assets/images/error_images_3.svg"
import graphicFour from "../../assets/images/error_images_4.svg"
import graphicFive from "../../assets/images/error_images_5.svg"

export const ERROR_GRAPHICS = [
  graphicOne,
  graphicTwo,
  graphicThree,
  graphicFour,
  graphicFive,
]

/** Selects a random graphic to display. */
const useRandomGraphic = () => {
  return useRef(randomFrom(ERROR_GRAPHICS)).current
}

/**
 * Randomly selected an item from a collection.
 *
 * @param {any[]} coll A collection of items.
 */
const randomFrom = (coll) => {
  const index = Math.floor(Math.random() * coll.length)
  return coll[index]
}

export default useRandomGraphic
