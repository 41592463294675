import axios from "../../../api/axios"

/**
 * Send request to server to initiate Micro-Learning export
 * @param arg - Object containing request parameters of Micro-Learning options
 */
const exportMicroLearning = (arg: any) => {
  const {
    courseId,
    organizeBy,
    condensePercent,
    checkedOptions,
    optionList,
    format,
  } = arg

  return axios.post("/microlearning/export", {
    courseId,
    organizeBy,
    condensePercent,
    checkedOptions,
    optionList,
    format,
  })
}

export default exportMicroLearning
