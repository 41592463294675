import styled from "@mui/material/styles/styled"
import audioElementStyle from "../../../features/insertAudio/audioElementStyle"
import withEditorBranding from "./utils/withEditorBranding"
import withListOptionStyles from "./utils/withListOptionStyles"

/**
 * Wrapper supplies style overrides to the editor.
 *
 * Unfortunatly, the FroalaEditor component does not offer an interface for
 * injecting styles or classes.
 */
const EditorWrapper = styled("div", { name: "Editor", slot: "Wrapper" })(
  (props: any) => {
    const { theme, branding } = props

    return {
      flex: "1 1 auto",
      ...withListOptionStyles(theme),
      ...audioElementStyle,
      ".fr-box": {
        height: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        minWidth: "100px",
      },
      ".fr-wrapper": {
        flex: "1 1 auto",
      },

      ".fr-wrapper .fr-element ": {
        // Need to ovverride low-level froala spacing styles
        paddingLeft: `${theme.spacing(8)} !important`,
        paddingRight: `${theme.spacing(8)} !important`,
        ...withEditorBranding(branding),
      },
      // Enable text selection in read-only mode.
      ".fr-element.fr-disabled": {
        userSelect: "initial",
      },
      ".fr-wrapper img": {
        // Override the aspect ratio to allow images to be cropped correctly
        aspectRatio: "auto !important",
      },
      ".fr-box td ": {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      ".fr-code": {
        height: "100% !important",
      },
      ".fr-selection span": {
        width: "100px !important",
      },
      ".highlighted": {
        backgroundColor: theme.palette.primary.light,
        borderRadius: theme.shape.borderRadius,
      },
      ".placeholder": {
        color: theme.palette.primary.dark,
      },
      ".hidden-text": {
        display: "none",
      },
      "section[data-template] p.placeholder, section[data-template] p.template-concepts-title, section[data-template] ul.keep-placeholder":
        {
          display: "none",
        },
      "table.centered": {
        /**
         * Table needs to override Froala-calculated margin if it has the ".centered" class
         */
        marginLeft: "auto !important",
        marginRight: "auto !important",
      },
      "table[data-component='borderless-table'] td": {
        border: "1px dashed lightgray",
        width: "50% !important", // Enforces equal column width for element
      },
      "table.no-borders td": {
        border: "1px solid transparent",
      },
      "table.no-borders": {
        "&:hover": {
          td: {
            border: "1px dashed lightgray",
          },
        },
      },
      ".fr-toolbar": {
        border: "0",
      },
      ".fr-box.fr-basic .fr-wrapper": {
        border: "0",
      },
      ".fr-second-toolbar": {
        display: "none",
      },
    }
  }
)

export default EditorWrapper
