import { useCallback, useEffect, useState } from "react"
import * as api from "../../../api"
import getDocumentStats from "../../outline/utils/getDocumentStats"
import formatDuration from "../../outline/utils/formatDuration"
import { Option } from "./useMicrolearning"
import { HEADING_LEVELS } from "../utils/constants"

/**
 * Hook for selecting sections from a document based on heading level.
 *
 * @param courseId - ID of course to retrieve sections for
 */
const useGetSections = (courseId: string | undefined) => {
  const [fullDocument, setFullDocument] = useState<Option>({
    label: "",
    key: "",
    duration: null,
    data: null,
  })
  const [container, setContainer] = useState<Element>()
  const [isLoading, setIsLoading] = useState(false)
  const [availableHeadings, setAvailableHeadings] = useState<any[]>([])

  /**
   * Upon mounting, fetch the course's HTML, calculate the duration, and
   * update the state accordingly.
   */
  useEffect(() => {
    if (!courseId) {
      return
    }
    setIsLoading(true)

    api.loadCourse(courseId).then((response) => {
      let el = document.createElement("body")
      el.innerHTML = response.data.assembled_html
      setContainer(el)

      setAvailableHeadings(
        HEADING_LEVELS.filter((heading) => {
          return !!el.querySelector(heading.value)
        })
      )

      /**
       * Asynchronously fetch the duration and update the state when complete
       */

      const { duration } = getDocumentStats(el)
      duration?.then((result: any) => {
        const { values } = result
        setFullDocument({
          key: courseId,
          label: response.data.title,
          duration: formatDuration(values.minutes),
          data: null,
        })
        setIsLoading(false)
      })
    })
  }, [courseId])

  /**
   * Find all headings of a given level, calculate the duration for each
   * respective section, return the list
   *
   * @param level - Heading level, H1 through H6
   */
  const fetchSections = useCallback(
    (level: string) => {
      if (!container) {
        return Promise.resolve(null)
      }
      const headings = Array.from(
        container.querySelectorAll(`:scope > ${level}`)
      )

      return Promise.all(
        headings.map((heading) => {
          const { duration } = getDocumentStats(container, heading)

          return duration?.then((result: any) => {
            const { values } = result
            return {
              key: heading.id,
              label: heading?.textContent,
              duration: formatDuration(values.minutes),
            }
          })
        })
      )
    },
    [container]
  )

  return {
    fullDocument,
    availableHeadings,
    fetchSections,
    isLoadingSections: isLoading,
  }
}

export default useGetSections
