import { useState } from "react"

import {
  Checkbox,
  CircularProgress,
  Fade,
  IconButton,
  Stack,
  TableCell,
  Typography,
} from "@mui/material"
import { Description, DragIndicator, FolderOpen } from "@mui/icons-material"
import { getLastUpdated, getTitle } from "../../../../utilities/transformers"
import CourseContextMenu from "../../../welcome/CourseContextMenu"
import Box from "@mui/material/Box"
import useUploadByID from "../../../../hooks/useUploadByID"
import { CourseCancelUploadIconButton } from "../../../../components/widgets/CourseCancelUploadControl"
import CourseWrapper from "../CourseWrapper"
import FolderWrapper from "../FolderWrapper"

/**
 * Build message for current stage of upload process
 * @param stage - Object denoting current stage of upload async job
 */
const formatStageCaption = (stage: any) => {
  if (stage.current == null || stage.total == null || stage.total === 1) {
    return stage.caption
  } else {
    return `Stage ${stage.current} of ${stage.total}: ${stage.caption}`
  }
}

type CourseListItemBaseProps = {
  checkbox?: JSX.Element | null
  icon: JSX.Element
  title: JSX.Element
  lastUpdated: JSX.Element | boolean
  control?: JSX.Element | null
}

/**
 * Component for rendering items in Courses List
 *
 * @param props - Component props
 */
export const CoursesListItemBase = (props: CourseListItemBaseProps) => {
  const { checkbox = null, icon, title, lastUpdated, control = null } = props

  return (
    <>
      <TableCell align="center" padding="checkbox">
        {checkbox}
      </TableCell>
      <TableCell sx={{ width: "50%" }}>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {icon}
          <Box marginLeft={2}>{title}</Box>
        </Box>
      </TableCell>
      <TableCell>{lastUpdated}</TableCell>
      <TableCell align="center" padding="checkbox">
        {control}
      </TableCell>
    </>
  )
}

/**
 * Component for selectable course when in List View
 * @param props - Component props
 */
const CoursesListItem = (props: any) => {
  const {
    item: document,
    onTrash,
    onDuplicate,
    checkedItems,
    handleCourseChecked,
    handleItemSelected,
    onCancelUpload,
    onUploadImage,
    onMove,
    onRenameFolder,
    displaySearchResults,
    folderTitleMap = {},
  } = props

  const { isLoading, item_type, parent_directory } = document

  const { stage } = useUploadByID(document.id)
  const [isHovered, setIsHovered] = useState(false)

  const isFolder = item_type === "folder"

  const isChecked = checkedItems.includes(document.id)
  const isInSelectionMode = checkedItems.length > 0

  const Wrapper = isFolder ? FolderWrapper : CourseWrapper
  const parentFolderTitle = folderTitleMap[parent_directory]

  return (
    <Wrapper
      item={document}
      onClick={(e: any) => handleItemSelected(e, document)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      variant="list"
    >
      <CoursesListItemBase
        checkbox={
          <Fade in={isHovered || isInSelectionMode}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <IconButton
                size="small"
                sx={{
                  cursor: "grab",
                  visibility: isHovered && !isFolder ? "visible" : "hidden",
                }}
              >
                <DragIndicator />
              </IconButton>
              <Checkbox
                color="default"
                onClick={(e) => e.stopPropagation()}
                checked={isChecked}
                onChange={(e) => handleCourseChecked(e, document.id)}
                inputProps={{ "aria-label": "Select Course" }}
              />
            </Stack>
          </Fade>
        }
        icon={
          isFolder ? (
            <FolderOpen
              sx={{
                /**
                 * Styling folder icon as grey to contrast with primary blue of courses
                 * @param theme - MUI theme
                 */
                color: (theme: any) => theme.palette.lexGrey,
              }}
              fontSize="small"
            />
          ) : !isLoading ? (
            <Description color="primary" fontSize="small" />
          ) : (
            <CircularProgress size={20} />
          )
        }
        title={
          !isLoading ? (
            <>
              {displaySearchResults && parentFolderTitle && (
                <Typography variant="body2">{parentFolderTitle}</Typography>
              )}
              <Typography
                sx={{
                  userSelect: "none",
                }}
                onClick={(e: any) => handleItemSelected(e, document)}
              >
                {getTitle(document.title)}
              </Typography>
            </>
          ) : (
            <>
              <Typography>{getTitle(document.title)}</Typography>
              <Typography variant="body2">
                {stage && formatStageCaption(stage)}
              </Typography>
            </>
          )
        }
        lastUpdated={
          !isLoading && (
            <Typography variant="body2">
              {getLastUpdated(document.last_update)}
            </Typography>
          )
        }
        control={
          <Box>
            {!isLoading ? (
              <CourseContextMenu
                document={document}
                onTrash={onTrash}
                onDuplicate={onDuplicate}
                isList
                onUploadImage={onUploadImage}
                onMove={onMove}
                onRenameFolder={onRenameFolder}
              />
            ) : (
              <CourseCancelUploadIconButton
                onCancel={() => onCancelUpload(document)}
              />
            )}
          </Box>
        }
      />
    </Wrapper>
  )
}

export default CoursesListItem
