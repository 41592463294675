import React from "react"
import { Redirect, useHistory, useLocation } from "react-router"
import CreationProgressScreen from "../components/screens/CreationProgressScreen"
import useUploadByID from "../hooks/useUploadByID"

const CreationProgressPage = () => {
  const location = useLocation()
  const { goBack } = useHistory()

  const id = location?.state?.id
  const applyTemplates = location?.state?.applyTemplates

  const { upload, stage, isComplete } = useUploadByID(id)

  return (
    <>
      {!id && <Redirect to={"/"} replace />}
      {isComplete && (
        <Redirect
          to={{
            pathname: "/editor",
            search: `?course=${upload?.data?.course_id}`,
            state: {
              //always show transformation summary dialog
              openTransformationSummary: true,
              //If applyTemplates is true, we want to show info about it to the user
              applyTemplates: applyTemplates,
            },
          }}
          replace
        />
      )}
      <CreationProgressScreen
        stage={stage}
        courseId={id}
        applyTemplates={applyTemplates}
        onExit={() => goBack()}
      />
    </>
  )
}

export default CreationProgressPage
