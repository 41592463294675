import { createSelector } from "@reduxjs/toolkit"
import remarksAdaptor from "./remarksAdaptor"
import selectDetectionById from "./selectors/selectDetectionById.ts"
import selectRemarksFilter from "./selectors/selectFilter"
import selectFilteredRemarks from "./selectors/selectFilteredRemarks"
import selectUnresolvedCount from "./selectors/selectUnresolvedCount"
import selectReviewStatus from "./selectors/selectReviewStatus"

/** Make a set of remark selectors scoped with the given state selector. */
export const getRemarkSelectors = (selectState) => {
  const { selectById: selectRemarkById } = remarksAdaptor.getSelectors()

  const selectId = (_, id) => id

  const selectCommentById = (state, id) =>
    selectRemarkById(
      state,
      remarksAdaptor.selectId({ remarkType: "comment", id })
    )

  const selectIsLoadingRemarks = (state) => state.status === "loading"

  if (!selectState) {
    return {
      selectReviewStatus,
      selectDetectionById,
      selectCommentById,
      selectIsLoadingRemarks,
      selectRemarksFilter,
      selectRemarkIds: selectFilteredRemarks,
      selectRemarkById,
      selectUnresolvedCount,
    }
  }

  return {
    selectReviewStatus: createSelector(
      [selectState, selectId],
      selectReviewStatus
    ),
    selectDetectionById: createSelector(
      [selectState, selectId],
      selectDetectionById
    ),
    selectIsLoadingRemarks: createSelector(selectState, selectIsLoadingRemarks),
    selectCommentById: createSelector(
      [selectState, selectId],
      selectCommentById
    ),
    selectRemarksFilter: createSelector(selectState, selectRemarksFilter),
    selectRemarkIds: createSelector(selectState, selectFilteredRemarks),
    selectRemarkById: createSelector(selectState, selectRemarkById),
    selectUnresolvedCount: createSelector(selectState, selectUnresolvedCount),
  }
}

export default getRemarkSelectors
