// export class DuplicateError extends Error {
//   constructor(message) {
//     super(message || "Course title cannot be the same as another course")
//     Object.setPrototypeOf(this, DuplicateError.prototype)
//   }
// }

// export class UntitledError extends Error {
//   constructor(message) {
//     super(message || "Course cannot be untitled")
//     Object.setPrototypeOf(this, UntitledError.prototype)
//   }
// }

export class OfflineError extends Error {
  constructor(message) {
    super(message || "The Selected Resource could not be reached")
    Object.setPrototypeOf(this, OfflineError.prototype)
  }
}
