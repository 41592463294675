import React from "react"

import { Fade, IconButton } from "@mui/material"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
import { styled } from "@mui/material"
import useScrollMenu from "./hooks/useScrollMenu"

const ArrowButton = styled(IconButton)(({ theme, visible }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  zIndex: 2,
  opacity: ".8",
  color: theme.palette.lexWhite,
  backgroundColor: theme.palette.lexGrey,
  "&:hover": {
    opacity: "1",
    color: theme.palette.lexWhite,
    backgroundColor: theme.palette.lexGrey,
  },
}))

/**
 *
 * @param root0
 * @param root0.visible
 * @param root0.onClick
 * @param root0.children
 */
const Arrow = ({ visible, onClick, children }) => {
  return (
    visible && (
      <Fade in={visible}>
        <ArrowButton onClick={onClick}>{children}</ArrowButton>
      </Fade>
    )
  )
}

/**
 *
 * @param root0
 * @param root0.hovered
 */
export const LeftArrow = ({ hovered }) => {
  const { active, scrollPrev } = useScrollMenu("left")

  return (
    <Arrow visible={active && hovered} onClick={() => scrollPrev()}>
      <ArrowBack />
    </Arrow>
  )
}

/**
 *
 * @param root0
 * @param root0.hovered
 */
export const RightArrow = ({ hovered }) => {
  const { active, scrollNext } = useScrollMenu("right")

  return (
    <Arrow visible={active && hovered} onClick={() => scrollNext()}>
      <ArrowForward />
    </Arrow>
  )
}
