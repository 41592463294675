import {
  useAuthenticator,
  Authenticator as AmplifyAuthenticator,
} from "@aws-amplify/ui-react"
import { AuthenticatorProps } from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator"
import { useEffect, useRef } from "react"
import Router from "./Router"
import { FormPersistenceProvider } from "./contexts/FormPersistenceProvider"

/**
 * Internal component for handling authentication flows.
 * @param props The component props.
 */
export function AuthenticatorInternal(props: AuthenticatorProps): JSX.Element {
  const { loginMechanisms, services } = props

  useAuthenticatorInitMachine({
    initialState: "signIn",
    loginMechanisms,
    services,
    formFields: {},
  })

  return <Router />
}

/**
 * Handles user authentication flows.
 * @param props The component props.
 */
export function Authenticator(props: AuthenticatorProps): JSX.Element {
  return (
    <FormPersistenceProvider>
      <AmplifyAuthenticator.Provider>
        <AuthenticatorInternal {...props} />
      </AmplifyAuthenticator.Provider>
    </FormPersistenceProvider>
  )
}

export default Authenticator

/**
 * Initialize the authentication state machine.
 * @param props The component props.
 */
function useAuthenticatorInitMachine({ ...data }) {
  const { _send, route } = useAuthenticator(({ route }) => [route])

  const hasInitialized = useRef(false)
  useEffect(() => {
    if (!hasInitialized.current && route === "setup") {
      _send({ type: "INIT", data })

      hasInitialized.current = true
    }
  }, [_send, route, data])
}
