import { List, Theme } from "@mui/material"
import { styled } from "@mui/styles"

type StyledListProps = {
  theme: Theme
}

export const ItemList = styled(List)(({ theme }: StyledListProps) => ({
  backgroundColor: theme.scheme.onPrimary,
  overflowX: "hidden",
  overflow: "auto",
  flex: "1 1 0",
  paddingTop: 0,
  paddingBottom: 0,
  borderBottomLeftRadius: "inherit",
  borderBottomRightRadius: "inherit",
}))

export default ItemList
