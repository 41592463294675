import {
  ActionReducerMapBuilder,
  Comparer,
  EntityAdapter,
  EntityState,
} from "@reduxjs/toolkit"
import { InteractiveComponentType } from "../../features/transform/transformUtils"

export type Comment = {
  remarkType: "comment"
  id: string
  courseId: string
  text: string
  replies: CommentReply[]
  location: DocumentLocation
  resolvedOn?: string
}

/** The type of rule that triggered a detection */
export type DetectionRule =
  | "task_based"
  | "first_person"
  | "passive_voice"
  | "interactive_component"

export type PendingComment = {
  remarkType: "pendingComment"
} & Omit<Comment, "remarkType">

export interface TextTransformationOption {
  /** Text to replace the detected content with */
  replacement_text?: string
}

/** Transformation which replaces text with a new text or HTML */
export interface TextTransformation {
  /** The type of transformation to apply */
  type: "text"
  /** Array of possible replacement options */
  options?: Array<TextTransformationOption>
}

export interface InteractiveTransformationOption {
  component: InteractiveComponentType
}

/** Transformation which creates an interactive component */
export interface InteractiveTransformation {
  type: "interactive_component"

  /** The type of interactive component to create */
  options: InteractiveTransformationOption[]
}

export type TransformationOption =
  | TextTransformationOption
  | InteractiveTransformationOption

const interactiveComponentNames: Record<InteractiveComponentType, string> = {
  "flip-card-grid": "Flip Card Grid",
  process: "Process",
  tabs: "Tabs",
  "styled-list": "Styled List",
  "callout-box": "Callout Box",
  categories: "Categories",
  "labeled-image": "Labeled Image",
}

/**
 * Get the label for a transformation option
 * @param transformation The transformation to get the label for
 * @param index The index of the option to get the label for
 * @returns The label for the transformation option
 */
export function getTransformationOptionLabel(
  transformation: Transformation,
  index: number
) {
  if (transformation.type === "text") {
    return transformation.options?.[index]?.replacement_text ?? ""
  } else if (transformation.type === "interactive_component") {
    return (
      interactiveComponentNames[transformation.options?.[index]?.component] ??
      ""
    )
  }
  return ""
}

export type Transformation = TextTransformation | InteractiveTransformation

export type Detection = {
  remarkType: "detection"

  /** Unique identifier for the detection */
  id: string

  /** The specific rule that triggered this detection */
  rule: DetectionRule

  /** Identifier for the detection without its location in the document.
   * This is the key field that is used to determine if a detection has been reviewed.
   */
  detectionKey?: string

  /** Text of the detection, or the text of the entire block item. This is text, not HTML.
   * If searching in HTML (in order to do a replacement while keeping the formatting),
   * be sure to convert common entities such as apostrophe to text first.
   */
  text: string

  /** Indicates if the detection has been reviewed */
  reviewed?: boolean

  /** Location of the detection in the document */
  location: DocumentLocation

  /** The type of HTML element where the detection occurred (e.g., 'p', 'h2')
   * @deprecated This should be derived from the location.tag
   */
  type: string

  /** The index of the selected option in the transformation options */
  selectedIndex: number | null

  /** Indicates if the transformation options are visible */
  optionsVisible: boolean

  /** Optional field for applying corrections to the detected issue */
  transformation?: Transformation
}

export type CommentReply = {
  id: string
  text: string
}

/** Location within the document */
export type DocumentLocation = {
  /** Array of indices representing the path to the block in the document structure */
  path: Path

  /** Start index of the remark within the block item. If an entire block is selected, this is null. */
  offset: number | null

  /** The type of HTML element where the remark occurs (e.g., 'p', 'h2') */
  tag: string

  /** Text of the remark, or the text of the entire block item. This is text, not HTML. */
  text: string

  /** Number of included blocks if the location contains a range of blocks.
   * Default is 1. Only used when offset is null.
   */
  numBlocks?: number
}

export type Remark = Comment | Detection | PendingComment

export type RemarksAdaptor = EntityAdapter<Remark>

export type RemarkId = {
  id: string
  remarkType: RemarkType
}

export type RemarkType = "comment" | "detection"

export type RemarksState = EntityState<Remark> & {
  /** TODO: is this for detections or comments? */
  status: "idle" | "loading" | "fulfilled" | "rejected"
  currentCourse?: string
  guestEmail?: string
  filter: {
    resolved: boolean
    type: string
  }
  pendingComment: {
    visible: boolean
    location: DocumentLocation | null
  }

  /** Whether detections are currently being loaded */
  detectionsLoading: boolean

  /** Whether section detections are currently being loaded */
  sectionDetectionsLoading: boolean

  /** Which detections have been reviewed, keyed by detection key */
  reviewedDetections: { [key: string]: boolean }

  /** The request id for the active detections request. Used to cancel the request if a new one is made. */
  activeDetectionsRequest?: string

  /** The request id for the active section detections request. Used to cancel the request if a new one is made. */
  activeSectionDetectionsRequest?: string
}

export type Path = number[]

export type PathComparer = Comparer<Path>

export type RemarkActionReducerMapBuilder =
  ActionReducerMapBuilder<RemarksState>
