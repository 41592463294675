import axiosInstance from "../../../api/axios"

interface getAdditionalSourceDocumentArg {
  courseID: string
  uploadID: string
}

/**
 * Fetch HTML of new source added to editor
 * @param arg - Course ID and upload ID used to locate new HTML in S3
 */
export const getAdditionalSourceDocument = (
  arg: getAdditionalSourceDocumentArg
) => {
  const { courseID, uploadID } = arg
  return axiosInstance
    .get(`/courses/${courseID}/documents/${uploadID}`)
    .then((response) => response.data)
}
