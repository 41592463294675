import { gateway } from "./axios"
import { IMPORT_API_ENDPOINT } from "./importEndpoint"

/**
 * Tell the import service all files have uploaded
 * @param jobId - job id
 */
const importFilesUploaded = async (jobId: string) => {
  const resp = await gateway.put<any>(`${IMPORT_API_ENDPOINT}${jobId}`, null, {
    baseURL: "/",
  })

  return resp
}

export default importFilesUploaded
