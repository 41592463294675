import { TableCell, TableHead, TableRow, Theme } from "@mui/material"

/**
 * Table heading for action log table
 */
const ActionLogHeadings = () => {
  return (
    <TableHead>
      <TableRow>
        {["Course", "Action", "Who", "When", null].map(
          (heading: string | null) => (
            <TableCell
              sx={(theme: Theme) => ({
                backgroundColor: theme.scheme.surfaceContainerLow,
                color: theme.scheme.onSurfaceVariant,
              })}
            >
              {heading}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

export default ActionLogHeadings
