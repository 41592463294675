import { createSlice } from "@reduxjs/toolkit"

type State = {
  remarksScrollLocked: boolean
  scrollPath: any
  currentSectionNumber: number | null
  currentSectionHeader: string | null
}

const initialState: State = {
  remarksScrollLocked: false,

  /** Path of current scroll position (indexes of elements of top element in view).
   * For example, if a table element was the second top-level element and its
   * 5th row was at the top of the viewport, the scrollPath would be [1,0,4,0]
   * for (second element -> tbody -> 5th tr -> td)
   */
  scrollPath: [0],
  /** Ordinal position of current section. Default to the top of the course */
  currentSectionNumber: 0,

  currentSectionHeader: null,
}

const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    /**
     * Update state with remarksScrollLocked flag
     * @param state - Current state
     * @param action - Redux action
     */
    setRemarksScrollLocked: (state, action) => {
      state.remarksScrollLocked = action.payload
    },
    /**
     * Update state with new scroll path based on selection
     * @param state - Current state
     * @param action - Redux action
     */
    setScrollPath: (state, action) => {
      state.scrollPath = action.payload
    },
    /**
     * Update state with new number of active section
     * @param state - Current state
     * @param action - Redux action
     */
    setCurrentSectionNumber: (state, action) => {
      state.currentSectionNumber = action.payload
    },
    /**
     * Update state with new heading element of active section
     * @param state - Current state
     * @param action - Redux action
     */
    setCurrentSectionHeader: (state, action) => {
      state.currentSectionHeader = action.payload
    },
  },
})

const { actions, reducer } = editorSlice

export const {
  setRemarksScrollLocked,
  setScrollPath,
  setCurrentSectionNumber,
  setCurrentSectionHeader,
} = actions

export default reducer
