import React from "react"
import { TableCell, TableRow, Typography } from "@mui/material"
import { Description } from "@mui/icons-material"
import { getTimeAgo, getDate, getTitle } from "../../utilities/transformers"
import TrashedCourseContextMenu from "./TrashedCourseContextMenu"
import Box from "@mui/material/Box"
import { styled } from "@mui/system"

const DataTableRow = styled(TableRow)(({ theme }) => {
  return {
    borderRadius: "4px",
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: `0px 1px 10px ${theme.palette.lexGrey}`,
    },
  }
})

const ContextMenuBox = styled(Box)(({ theme }) => {
  return {
    marginRight: theme.spacing(4),
  }
})

const DocumentTitleBox = styled(Box)(({ theme }) => {
  return {
    marginLeft: theme.spacing(2),
  }
})

/**
 * TrashedCoursesListItem function
 * @param props props
 * @param props.document document
 * @param props.onDelete onDelete
 * @param props.onRestore onRestore
 */
const TrashedCoursesListItem = (props: {
  document: any
  onDelete: any
  onRestore: any
}) => {
  const { document, onDelete, onRestore } = props

  return (
    <DataTableRow>
      <TableCell>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Description color="primary" fontSize="small" />
          <DocumentTitleBox>
            <Typography>{getTitle(document.title)}</Typography>
          </DocumentTitleBox>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>
            <>{getTimeAgo(document.trash_date)}</>
          </Typography>
          <ContextMenuBox>
            <TrashedCourseContextMenu
              document={document}
              deleteCourse={onDelete}
              restoreCourse={onRestore}
            />
          </ContextMenuBox>
        </Box>
      </TableCell>
    </DataTableRow>
  )
}

export default TrashedCoursesListItem
