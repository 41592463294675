import React from "react"
import { Typography } from "@mui/material"

interface FailedFileDetail {
  failedFileDetails?: [string, string][]
  failedFilename?: string
}

interface ErrorCaptionProps {
  stage: FailedFileDetail
}

/**
 * Show a file upload error caption
 * @param root0 - The parms
 * @param root0.stage - failure details
 */
const UploadErrorCaption: React.FC<ErrorCaptionProps> = ({ stage }) => {
  if (stage.failedFileDetails != null && stage.failedFileDetails.length > 0) {
    if (stage.failedFileDetails.length > 1) {
      return (
        <>
          <Typography variant="body2">
            The following documents could not be processed:
          </Typography>
          {stage.failedFileDetails.map((details, index) => (
            <Typography variant="body2" key={index}>
              {`"${details[0]}": ${details[1]}`}
            </Typography>
          ))}
        </>
      )
    } else {
      const failedFileDetails = stage.failedFileDetails[0]
      return (
        <Typography variant="body2">
          {`The document, "${failedFileDetails[0]}", could not be processed. ${failedFileDetails[1]}`}
        </Typography>
      )
    }
  } else if (stage.failedFilename == null || stage.failedFilename === "") {
    return (
      <Typography variant="body2">
        {`Your import could not be completed. Please try again, contact LearnExperts if the problem persists`}
      </Typography>
    )
  } else {
    return (
      <Typography variant="body2">
        {`The document, "${stage.failedFilename}", could not be processed. Please try again, contact LearnExperts if the problem persists`}
      </Typography>
    )
  }
}

export default UploadErrorCaption
