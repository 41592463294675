import React from "react"
import { isArray, isFunction, isObject, map, mapValues } from "lodash/fp"
import NotificationAction from "../components/atoms/NotificationAction"

/**
 * Build Notistack actions.
 */
const buildNotistackAction = (actions) => {
  return (key) => {
    return (
      <>
        {actions &&
          actions.map((action) => (
            <NotificationAction
              {...action}
              key={action.label}
              notificationKey={key}
            />
          ))}
      </>
    )
  }
}

/**
 * Build a Notistack notification from a given template.
 *
 * @param {*} template
 * @param {*} templateArgs
 */
const buildNotificationFromTemplate = (template, templateArgs) => {
  const resolveWithData = (value) => {
    const result = isFunction(value) ? value(...templateArgs) : value

    if (isArray(result)) {
      return map(resolveWithData, result)
    }

    if (isObject(result)) {
      return mapValues(resolveWithData, result)
    }

    return result
  }

  const { actions, ...otherProps } = resolveWithData(template)

  return {
    ...otherProps,
    action: buildNotistackAction(actions),
  }
}

export default buildNotificationFromTemplate
