import { useEffect, useState } from "react"
import fetchFoldersAndChildren from "../api/fetchFoldersAndChildren"

/**
 * Form the preview message based on what is being trashed
 * @param items - Items to show in preview
 * @param containsChildren - True if a folder with children is being trashed
 */
const createMessage = (items: any[], containsChildren: boolean) => {
  if (items.length === 1 && !containsChildren) {
    let title = items[0].title
    let itemType = items[0].item_type === "folder" ? "folder" : "course"

    return `Move the ${itemType} "${title}" to the trash?`
  } else if (items.length) {
    return (
      "Move the following items to the trash?" +
      (containsChildren
        ? " This will include all courses contained within the trashed folders."
        : "")
    )
  }
}

/**
 * Hook to obtain preview of what courses will be deleted
 * @param items - List of courses and folders to be deleted
 */
const useTrashItemsPreview = (items: any) => {
  const [trashPreviewItems, setTrashPreviewItems] = useState<any[]>([])

  useEffect(() => {
    const folderIds: string[] = []
    const courses: any[] = []

    items.forEach((item: any) => {
      if (item.item_type === "folder") {
        folderIds.push(item.id)
      } else if (item.item_type === "course") {
        courses.push(item)
      }
    })

    if (folderIds.length > 0) {
      fetchFoldersAndChildren({ item_ids: folderIds }).then((data) => {
        setTrashPreviewItems([...data, ...courses])
      })
    } else {
      setTrashPreviewItems(courses)
    }
  }, [items])

  const previewContainsChildren = trashPreviewItems.some(
    (item) => item.children?.length > 0
  )

  return {
    trashPreviewItems,
    previewContainsChildren,
    message: createMessage(trashPreviewItems, previewContainsChildren),
  }
}

export default useTrashItemsPreview
