import { Box } from "@mui/material"
import { ReactNode } from "react"

type AvatarWrapperProps = {
  variant: "circle" | "square"
  children: ReactNode
  image?: string | null
}

/**
 * Wrap an avatar's content in either a circle or rounded square
 * @param props - Component props
 */
const AvatarWrapper = (props: AvatarWrapperProps) => {
  const { variant, children, image } = props

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={variant === "circle" ? "100%" : "8px"}
      sx={(theme) => ({
        backgroundColor: theme.scheme.secondaryContainer,
        ...(image && {
          backgroundImage: image,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }),
      })}
    >
      {children}
    </Box>
  )
}

export default AvatarWrapper
