import { Box, ListItem, ListItemIcon, useTheme } from "@mui/material"
import { Circle, CheckBox } from "@mui/icons-material"
import { StyledListItemData } from "./styledListCustomElement"

interface StyledListItemProps {
  listType: "bullet" | "checkbox" | "number"
  listSize: "small" | "medium" | "large"
  index: number
  listItem: StyledListItemData
}

/**
 * Render bullet depending on list type configuration
 * @param listType - Type of bullet to render
 * @param listSize - Size of bullet to render
 * @param index - Position of item in list
 * @param colour - Colour of bullet to render
 */
const getBullet = (
  listType: "bullet" | "checkbox" | "number",
  listSize: "small" | "medium" | "large",
  index: number,
  colour: string | null
) => {
  switch (listType) {
    case "bullet":
      return <Circle fontSize={listSize} sx={{ color: colour }} />
    case "checkbox":
      return <CheckBox fontSize={listSize} sx={{ color: colour }} />
    case "number":
      return (
        <CircleNumber number={index + 1} color={colour} fontSize={listSize} />
      )
    default:
      break
  }
}

/**
 * Put a number in a circle, size according to font size
 * @param root0 - the params
 * @param root0.number - number to show
 * @param root0.fontSize - size of circle
 * @param root0.color - optional color
 */
const CircleNumber = ({
  number,
  color,
  fontSize,
}: {
  number: number
  color: string | null
  fontSize: "small" | "medium" | "large"
}) => (
  <Box
    sx={{
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      bgcolor: color || "primary.main",
      color: "white",
      padding: "0.19em",
      lineHeight: 1,
      aspectRatio: "1 / 1",
      fontSize: fontSize,
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "1em",
        height: "1em",
      }}
    >
      {number}
    </Box>
  </Box>
)

/**
 * Component for individual Styled List item
 * @param props - Props for list item component
 */
const StyledListItem = (props: StyledListItemProps) => {
  const theme = useTheme() as any
  const { listSize, listType, index, listItem } = props

  return (
    <ListItem
      sx={{
        paddingLeft: 0,
      }}
      alignItems="center"
      key={index}
    >
      <ListItemIcon sx={{ mt: 0, fontSize: "inherit" }}>
        {getBullet(listType, listSize, index, theme.courseTheme?.colour)}
      </ListItemIcon>
      <Box
        sx={{
          "& *": {
            mt: "0",
            mb: "0",
          },
        }}
        dangerouslySetInnerHTML={{
          __html: listItem.html,
        }}
      />
    </ListItem>
  )
}

export default StyledListItem
