import React from "react"
import PageLayout from "../layouts/PageLayout"
import { Box, Card, Button, Grid, Typography } from "@mui/material"
import {
  ArrowForwardIos,
  Delete,
  History,
  FindReplace,
  Share,
  ColorLens,
  Translate,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import { Link, useLocation } from "react-router-dom"
import { useAuth } from "../../contexts/authorization"
import { GiveCourseIcon } from "../../assets/icons/customIcons"
import { useFlag } from "../../utilities/feature-management"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    cursor: "pointer",
    display: "flex",
    "&:hover": {
      boxShadow: `0px 4px 10px ${theme.palette.lexGrey}`,
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  visual: {
    backgroundColor: theme.palette.lexLightGrey,
  },
  info: {},
}))

const ToolsItem = ({ title, description, button, icon, url }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card
        component={Link}
        to={url}
        className={classes.root}
        style={{ textDecoration: "none" }}
      >
        <Box width="100%" display="flex" flexDirection="column">
          <Box className={classes.visual} display="flex">
            <Box
              padding={3}
              margin="auto"
              display="flex"
              alignItems="center"
              className={classes.icon}
            >
              {icon}
            </Box>
          </Box>
          <Box
            padding={2}
            className={classes.info}
            display="flex"
            flexGrow={1}
            flexDirection="column"
          >
            <Box marginBottom={2}>
              <Typography gutterBottom fontSize="1.5em" fontWeight={600}>
                {title}
              </Typography>
              <Typography variant="body2">{description}</Typography>
            </Box>
            <Box marginTop="auto" display="flex" justifyContent="center">
              <Button
                variant="contained"
                fullWidth
                endIcon={<ArrowForwardIos sx={{ marginBottom: ".20em" }} />}
              >
                {button}
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  )
}

/**
 * The ToolsScreen function
 */
const ToolsScreen = () => {
  const { user, signOut } = useAuth()
  const location = useLocation()
  const breadcrumbs = location?.state?.breadcrumbs ?? [
    { label: "Courses", href: "/" },
  ]

  const rolloutTranslation = useFlag("rollout-translate-courses-on-export")

  return (
    <PageLayout
      breadcrumbs={[...breadcrumbs, { label: "Tools" }]}
      fixed
      maxWidth="xl"
    >
      <Box marginBottom={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Tools
        </Typography>
        {user && (
          <Box display="flex" alignItems="baseline">
            <Typography variant="body1">
              Logged in as <b>{user.email}.</b> &nbsp;
            </Typography>
            <Typography
              onClick={signOut}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              variant="body1"
            >
              Sign out
            </Typography>
          </Box>
        )}
      </Box>

      <Grid container spacing={4}>
        <ToolsItem
          icon={<History sx={{ fontSize: "6em" }} />}
          title="History"
          button="History"
          description="View and filter all user and system actions"
          url="/history?page=0&limit=20&back=tools"
        />

        <ToolsItem
          icon={<FindReplace sx={{ fontSize: "6em" }} />}
          title="Global Find and Replace"
          button="Global Find and Replace"
          description="Change text quickly across all your courses"
          url="/find"
        />

        <ToolsItem
          icon={<Share sx={{ fontSize: "6em" }} />}
          title="Share"
          button="Share"
          description="Turn on and off public comments for a course"
          url="/share"
        />
        <ToolsItem
          icon={<GiveCourseIcon sx={{ fontSize: "6em" }} />}
          title="Give Course"
          button="Give Course"
          description="Change the owner of your course to another user"
          url="/give"
        />
        <ToolsItem
          icon={<Delete sx={{ fontSize: "6em" }} />}
          title="Trash"
          button="Trash"
          description="Manage courses that have been moved to the trash"
          url="/trash"
        />
        <ToolsItem
          icon={<ColorLens sx={{ fontSize: "6em" }} />}
          title="Customize Branding"
          button="Customize Branding"
          description="Configure fonts and colour palettes for your exported courses"
          url="/branding"
        />
        {rolloutTranslation && (
          <ToolsItem
            icon={<Translate sx={{ fontSize: "6em" }} />}
            title="User-Defined Translations"
            button="User-Defined Translations"
            description="Manage translations for exporting content in different languages"
            url="/translation-rules"
          />
        )}
      </Grid>
    </PageLayout>
  )
}

export default ToolsScreen
