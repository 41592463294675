import { Button, CircularProgress } from "@mui/material"
import { useState } from "react"
import { downloadTranslationSheet } from "../../api"
import { useAuth } from "../../contexts/authorization"
import FileDownloadIcon from "@mui/icons-material/FileDownload"

/**
 * Downloads a translation sheet for a course in a given language, retrying until it is completed.
 * @param props Props for the component.
 * @param props.courseId - The ID of the course.
 * @param props.language - The language to download the translation sheet for.
 * @param props.courseHtml - The HTML content of the course.
 * @param props.summaryHtml - The HTML content of the summary.
 * @returns React component
 */
export function DownloadTranslationSheetButton(props: {
  courseId: string
  language: string
  courseHtml: string
  summaryHtml: string
}) {
  const { courseId, language, courseHtml, summaryHtml } = props
  const [isDownloading, setIsDownloading] = useState(false)

  const {
    user: { id: tenantId },
  } = useAuth() as any

  /**
   * Handles the download of the translation sheet.
   */
  const handleDownload = async () => {
    setIsDownloading(true)
    try {
      while (true) {
        const completed = await downloadTranslationSheet(
          tenantId,
          courseId,
          language,
          courseHtml,
          summaryHtml
        )
        if (completed) {
          break
        }
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    } catch (error) {
      alert("Failed to download translation sheet")
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleDownload}
        disabled={isDownloading || !courseHtml}
        startIcon={
          isDownloading ? <CircularProgress size={24} /> : <FileDownloadIcon />
        }
      >
        Download Translation Sheet
      </Button>
    </div>
  )
}
