import { useContext } from "react"
import ShareSettingsContext from "./ShareSettingsContext"
import ShareSettingsDispatchContext from "./ShareSettingsDispatchContext"

export const useShareSettings = () => useContext(ShareSettingsContext)

export const useShareSettingsDispatch = () =>
  useContext(ShareSettingsDispatchContext)

/**
 * @typedef {object} PublicShareState
 * @property {boolean} isPublic
 *  Indicates whether the context course is currently shared publicly.
 * @property {string} publicLink
 *  A URL for accessing the public page for a course.
 * @property {() => void} toggleIsPublic
 *  Toggles whether the current context course should be publicly accessible.
 */

/**
 * Access controls for configuring whether a course is publicly shared.
 *
 * @returns {PublicShareState}
 */
export const usePublicShare = () => {
  const { isPublic, publicLink } = useShareSettings()
  const { toggleIsPublic } = useShareSettingsDispatch()

  return {
    isPublic,
    publicLink,
    toggleIsPublic,
  }
}

export default usePublicShare
